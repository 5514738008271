/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import lodash from "lodash";
import React from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import IconSpeaker from "../../../../public/assets/icons/IconSpeaker";
import { LANG_VOICE_DEFAULT } from "../utils/constant";

const VoicesComponents = ({ title }: any) => {
  const { speak, voices } = useSpeechSynthesis();
  const handleTextToSpeech = () => {
    const voice = voices.find((item) => item?.lang === LANG_VOICE_DEFAULT);
    speak({ text: title, voice, rate: 0.7 });
  };

  const handleTextToSpeechDebounce = lodash.debounce(() => {
    handleTextToSpeech()
  }, 500);

  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        handleTextToSpeechDebounce()
      }}
      className="focus:outline-green"
    >
      <IconSpeaker />
    </button>
  );
};
export default VoicesComponents;
