/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CXModal from "../../../common/components/CXModal";
import { commonActions } from "../../../common/redux/actions";
import { TCommonState } from "../../../common/utils/type";
import { LIST_CLASSES } from "../constants";

const ModalChooseLevel = ({ open, handleClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { listGrades, grade } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const handleGetLevel = () => {
    dispatch(commonActions.handleGetGrade());
  };
  const handleChooseLevel = (gradeSlug: string) => {
    dispatch(commonActions.handleChooseGrade(gradeSlug));
    handleClose();
  };
  React.useEffect(() => {
    if (open) handleGetLevel();
  }, [open]);
  return (
    <CXModal
      open={open}
      handleClose={handleClose}
      minHeight="400px"
      title={intl.formatMessage({ id: "GradeSelection.title" })}
      isShowBtnClose={false}
    >
      <div
        className="grid grid-cols-3 justify-items-center items-center
       md:gap-y-[44px] sm:gap-y-6"
      >
        {listGrades?.map((item) => (
          <button
            type="button"
            key={item?.slug}
            className={`w-full hover:bg-greenBland1 py-2 rounded-md${grade === item?.slug && "bg-greenBland1"}`}
            onClick={() => handleChooseLevel(item?.slug)}
          >
            <span className="flex justify-center w-full">
              {LIST_CLASSES.find((ele) => ele?.slug === item?.slug)?.icon}
            </span>
          </button>
        ))}
      </div>
    </CXModal>
  );
};
export default ModalChooseLevel;
