/* eslint-disable react/require-default-props */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Popup } from "reactjs-popup";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import LockIcon from "../../../../public/assets/icons/LockIcon";
import { TTheme } from "../../../utils/configs/setupTheme";
import IconMenuColumn from "../../../../public/assets/icons/IconMenuColumn";
import { IconCopyLarge } from "../../../../public/assets/icons/IconCopy";
import { IconFlagSmall } from "../../../../public/assets/icons/IconFlagReport";
import { toastSuccess } from "../../common/components/reactToastify";
import VoicesComponents from "../../common/components/VoicesComponent";
import { TCommonState } from "../../common/utils/type";

const renderCardInfo = ({ ele }) => {
  return (
    <div className="flex items-center flex-row justify-center mr-1 gap-2">
      <div
        className="flex flex-col gap-y-1"
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden"
        }}
      >
        <span className="line-clamp-1 text-left my-[6px] text-[15px] mb-2">{ele?.title}</span>
        {ele?.card?.pronunciation_general && (
          <div className="w-full text-black text-left">
            <span className="text-xs text-left line-clamp-1">{ele?.card?.pronunciation_general}</span>
          </div>
        )}
        {ele?.card?.pronunciation_on && (
          <div className="w-full text-black text-left">
            <span className="text-xs text-left line-clamp-1">
              <FormattedMessage id="card.kanji.on" values={{ pronunciation_on: ele?.card?.pronunciation_on }} />
            </span>
          </div>
        )}
        {ele?.card?.pronunciation_kun && (
          <div className="w-full text-black text-left">
            <span className="text-xs text-left line-clamp-1">
              <FormattedMessage id="card.kanji.kun" values={{ pronunciation_kun: ele?.card?.pronunciation_kun }} />
            </span>
          </div>
        )}
        <div className="w-full text-black text-left">
          <span className="text-xs text-left line-clamp-1">{ele?.card?.main_meaning}</span>
        </div>
      </div>
    </div>
  );
};

type TProps = {
  ele: any;
  // eslint-disable-next-line no-unused-vars
  isShowLock: (access_level: any) => boolean;
  handleOpenModalReport: (ele: any) => void;
  className?: string;
  blockLessonLeftSide?: boolean;
};

const SubjectTypeCard = (props: TProps) => {
  const { ele, isShowLock, handleOpenModalReport, className, blockLessonLeftSide = false } = props;
  const intl = useIntl();
  const dataUser = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const [openMenu, setOpenMenu] = React.useState(false);
  const matches820 = useMediaQuery("(max-width:820px)");
  const handleClose = () => {
    setOpenMenu(false);
  };
  const handleCoppie = () => {
    toastSuccess(intl.formatMessage({ id: "message.copy.success" }));
  };
  const theme: TTheme = useTheme();
  return (
    <div
      className={`flex flex-row items-start justify-between ${className ? className : "h-[120px]"}`}
      style={{
        border: `1px solid ${theme.palette.primary.gray}`,
        borderRadius: 16,
        padding: 8,
        maxHeight: 120
      }}
    >
      {blockLessonLeftSide ? (
        <a href={ele?.direct_link} className="flex w-full mr-1 gap-2">
          {renderCardInfo({ ele })}
        </a>
      ) : (
        renderCardInfo({ ele })
      )}
      <div className="mt-1.5 flex items-center gap-x-3">
        {!isShowLock(ele?.access_level) ? (
          <button type="button" className="w-4 h-4">
            <LockIcon />
          </button>
        ) : null}
        <VoicesComponents title={ele?.title} blockLessonLeftSide={blockLessonLeftSide} />
        {dataUser?.member?.pub_id && (
          <Popup
            trigger={(_open) => (
              <button type="button" onClick={() => setOpenMenu(true)}>
                <IconMenuColumn />
              </button>
            )}
            open={openMenu}
            onClose={handleClose}
            onOpen={() => setOpenMenu(true)}
            position={!blockLessonLeftSide && matches820 ? "bottom right" : "bottom center"}
          >
            {(close: () => Element) => {
              return (
                <div className="flex flex-col gap-y-2 text-black1">
                  <CopyToClipboard
                    text={`${ele.title} \n ${
                      ele?.card?.pronunciation_general ? `${ele?.card?.pronunciation_general} \n` : ""
                    }${
                      ele?.card?.pronunciation_on
                        ? `${intl.formatMessage(
                            { id: "card.kanji.on" },
                            { pronunciation_on: ele?.card?.pronunciation_on }
                          )} \n`
                        : ""
                    }${
                      ele?.card?.pronunciation_kun
                        ? `${intl.formatMessage(
                            { id: "card.kanji.kun" },
                            { pronunciation_kun: ele?.card?.pronunciation_kun }
                          )} \n`
                        : ""
                    }${ele?.card?.main_meaning ? ele?.card?.main_meaning : ""}`}
                    onCopy={() => {
                      handleCoppie();
                      close();
                    }}
                  >
                    <button
                      type="button"
                      className="w-full hover:bg-primary50 rounded-md px-2 py-2 flex items-center flex-row gap-x-4"
                    >
                      <IconCopyLarge /> <FormattedMessage id="Arena.menuCopy.copy" />
                    </button>
                  </CopyToClipboard>
                  <button
                    type="button"
                    className="w-full hover:bg-primary50 rounded-md px-2 py-2 flex items-center flex-row gap-x-4"
                    onClick={() => {
                      handleOpenModalReport(ele);
                      setOpenMenu(false);
                    }}
                  >
                    <IconFlagSmall />
                    <FormattedMessage id="title.report.quiz" />
                  </button>
                </div>
              );
            }}
          </Popup>
        )}
      </div>
    </div>
  );
};
export default SubjectTypeCard;
