/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import React from "react";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import { facebookLink, youtubeLink } from "../../../../utils/constants/env";
import { isEmpty } from "../../../../utils/helpers/helpers";

export function FacebookElement({
  width = "298",
  height = "168",
  className
}: {
  width?: string;
  height?: string;
  className?: string;
}) {
  const intl = useIntl();
  return (
    <Box className={`${className} rounded-[12px]`}>
      <iframe
        title={intl.formatMessage({ id: "common.appName" })}
        src={facebookLink}
        width={width || "298"}
        height={height || "166"}
        style={{
          border: "none",
          overflow: "hidden",
          borderRadius: "16px",
          backgroundColor: "white"
        }}
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      />
    </Box>
  );
}

export function YoutubeElement() {
  const intl = useIntl();
  if (isEmpty(youtubeLink)) return null;

  return (
    <Box className="mt-4">
      <iframe
        src={youtubeLink}
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={intl.formatMessage({ id: "common.appName" })}
        style={{
          width: "298px",
          height: "166px",
          borderRadius: 16,
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))"
        }}
      />
    </Box>
  );
}
