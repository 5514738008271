/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import { Box, Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import GooglePlay from "../../../../../public/assets/icons/GooglePlay";
import Logo1 from "../../../../../public/assets/icons/Logo1";
import MobileAppStore from "../../../../../public/assets/icons/MobileAppStore";
import { TTheme } from "../../../../utils/configs/setupTheme";
import { appAppleStoreLink, appGoogleStoreLink, isBoCongThuongVerified } from "../../../../utils/constants/env";
import { genSignature, getNonce } from "../../../../utils/helpers/helpers";
import { accountActions } from "../../../account/redux/actions";
import { commonActions } from "../../../common/redux/actions";
import { TCommonState } from "../../../common/utils/type";
import { LIST_CLASSES } from "../constants";

const linkStyle = {
  margin: 10,
  textDecoration: "none",
  color: "white",
  fontWeight: 600
};
const AppLink = ({ xs, matches1100, matches770 }: any) => {
  const theme: TTheme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();

  const { userInfos } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);

  const handleUpdateGrade = (gradeVal) => {
    const dataUpdateProfilePre: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade_slug: gradeVal
    };
    const dataUpdateProfile = {
      ...dataUpdateProfilePre,
      signature: genSignature(dataUpdateProfilePre)
    };
    dispatch(
      accountActions.handleUpdateInfoAccount(dataUpdateProfile, () => {
        dispatch(accountActions.handleClearNotify());
      })
    );
  };

  return (
    <Grid
      item
      xs={xs}
      style={{
        paddingLeft: matches770 ? 6 : 0,
        marginTop: matches770 ? 0 : 10
      }}
      className="sm:mb-[25px] lg:mb-[0] sm:max-w-full flex flex-col gap-y-8"
    >
      <Box className="flex sm:flex-col md:flex-col sm:justify-between lg:justify-start gap-2">
        <Box display="flex" alignItems="center">
          <Logo1 />
          <span
            style={{
              marginLeft: 8,
              fontSize: 33,
              fontWeight: 700,
              lineHeight: "43.65px",
              color: theme.palette.primary.main
            }}
          >
            <FormattedMessage id="common.appName" />
          </span>
        </Box>
      </Box>
      <Box className="flex flex-wrap gap-x-8 gap-y-2">
        {LIST_CLASSES.map((classItem) => (
          <Box key={classItem.slug}>
            <Button
              className="normal-case hover:underline decoration-white p-0 min-w-0"
              onClick={() => {
                dispatch(commonActions.handleChooseGrade(classItem.slug));
                if (userInfos?.member) {
                  handleUpdateGrade(classItem.slug);
                }
                router.push("/");
              }}
            >
              <Typography className="font-semibold text-white text-[15px]">{classItem.name}</Typography>
            </Button>
          </Box>
        ))}
      </Box>
      <Box>
        <Typography variant="h6" style={{ marginBottom: 5, fontWeight: "600", fontSize: "18px" }}>
          <FormattedMessage id="Download_app" />
        </Typography>
        <Box className="flex sm:flex-row md:flex-row lg:flex-row gap-2">
          <Link href={appGoogleStoreLink || ""}>
            <a target="_blank">
              <GooglePlay className="md:w-[125px] lg:w-[135px] sm:w-[100px]" />
            </a>
          </Link>
          <Link
            href={appAppleStoreLink || ""}
            style={{
              marginLeft: matches1100 ? 16 : 0,
              marginTop: !matches1100 ? (matches770 ? 8 : 0) : 0
            }}
          >
            <a target="_blank">
              <MobileAppStore className="md:w-[115px] lg:w-[120px] sm:w-[90px]" />
            </a>
          </Link>
        </Box>
      </Box>
    </Grid>
  );
};
const ConnectWithUs = ({ xs }: any) => {
  return (
    <Grid item xs={xs} className="sm:mb-[25px] lg:mb-[0] text-[15px] sm:max-w-full">
      <Box display="flex" flexDirection="column">
        <span style={{ fontWeight: "bold", marginBottom: "15px" }} className="sm:mb-[10px]">
          <FormattedMessage id="Contact_with_us" />
        </span>
        <span style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <FormattedMessage id="Hot_line" />
        </span>
        <span style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <FormattedMessage id="Contact_note_1" />
        </span>
        <span>
          <FormattedMessage id="Contact_note_2" />
        </span>
      </Box>
    </Grid>
  );
};
const CompanyInfo = ({ xs }: any) => {
  return (
    <Grid item xs={xs} className="md:mt-[15px] lg:mt-[0] lg:ml-[0] text-[15px] md:ml-[6px]">
      <Box display="flex" flexDirection="column">
        <span style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <FormattedMessage id="Compay_name" />
        </span>
        <span style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <FormattedMessage id="GPKD" />
        </span>
        <span style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <FormattedMessage id="Address" />
        </span>
      </Box>
      {isBoCongThuongVerified && (
        <a href="http://online.gov.vn/Home/WebDetails/97400">
          <Image src="http://online.gov.vn/Content/EndUser/LogoCCDVSaleNoti/logoSaleNoti.png" width={124} height={50} />
        </a>
      )}
    </Grid>
  );
};
const Policy = ({ xs }) => {
  return (
    <Grid item xs={xs} className="md:flex md:justify-around lg:block sm:mb-[25px] lg:mb-[0] text-[15px] sm:max-w-full">
      <div className="flex flex-col" color="#273148">
        <span style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <Link href="/hinh-thuc-thanh-toan">
            <a className="cursor-pointe hover:underline">
              <FormattedMessage id="footer.policy.payments" />
            </a>
          </Link>
        </span>
        <div style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <Link href="/chinh-sach-doi-tra">
            <a className="cursor-pointe hover:underline">
              <FormattedMessage id="footer.policy.refund" />
            </a>
          </Link>
        </div>
        <div style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <Link href="/dieu-khoan-su-dung" style={linkStyle}>
            <a className="cursor-pointe hover:underline">
              <FormattedMessage id="footer.policy.termsofuse" />
            </a>
          </Link>
        </div>
        <div style={{ marginBottom: "15px" }} className="sm:mb-[10px]">
          <Link
            href="/chinh-sach-bao-mat"
            style={linkStyle}
            // onClick={() => window.scrollTo(0, 0)}
          >
            <a className="cursor-pointe hover:underline">
              <FormattedMessage id="footer.policy.privacy" />
            </a>
          </Link>
        </div>
        <div>
          <Link
            href="/phuong-thuc-giao-nhan"
            style={linkStyle}
            // onClick={() => window.scrollTo(0, 0)}
          >
            <a className="cursor-pointer hover:underline">
              <FormattedMessage id="footer.delivery.method" />
            </a>
          </Link>
        </div>
      </div>
    </Grid>
  );
};

function DefaultFooter() {
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const matches770 = useMediaQuery("(min-width:770px)");
  const matches412 = useMediaQuery("(max-width:412px)");
  const theme: TTheme = useTheme();
  return (
    <footer className="z-[50] font-footer mt-[30px]">
      <Box
        style={{
          height: "max-content",
          color: "white",
          background: theme.palette.primary.backgroundFooter,
          padding: matches1100 ? "30px 27px 15px" : matches412 ? "20px 16px" : "30px 16px"
        }}
      >
        {matches1100 ? (
          <Grid container>
            <AppLink xs={3.5} matches1100={matches1100} matches770={matches770} />
            <ConnectWithUs xs={2.5} />
            <Policy xs={2} />
            <CompanyInfo xs={4} />
          </Grid>
        ) : matches412 ? (
          <Grid container>
            <AppLink xs={12} matches1100={matches1100} matches770={matches770} />
            <ConnectWithUs xs={12} />
            <Policy xs={12} />
            <CompanyInfo xs={12} />
          </Grid>
        ) : (
          <Grid container>
            <div className="flex w-full md:flex-row sm:flex-col">
              <AppLink xs={5} matches1100={matches1100} matches770={matches770} />
              <ConnectWithUs xs={3.5} />
              <Policy xs={3.5} />
            </div>
            <CompanyInfo xs={12} />
          </Grid>
        )}
        <span className="block mt-[20px]">
          <Divider className="bg-gray1 h-[1px]" />
        </span>
        <div className="text-center text-[12px] text-white py-[15px] flex justify-between sm:flex-col sm:text-left md:flex-row">
          <span color="#273148" className="sm:mb-[10px] lg:mb-[0] ">
            <FormattedMessage id="Foot_title" />
          </span>
          <span className="flex justify-center text-white sm:justify-start">
            <FormattedMessage id="Coppy_right" />
          </span>
        </div>
      </Box>
    </footer>
  );
}

export default React.memo(DefaultFooter);
