import React from "react";

export default function icDiamond() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13.964L15.3824 25H16.6166L27.999 13.964V12.7673L22.3078 7.24931L21.6907 7H10.3083L9.69119 7.24931L4 12.7673V13.964ZM15.9995 23.205L5.85135 13.3657L10.6683 8.66205H21.3307L26.1476 13.3657L15.9995 23.205ZM15.9995 10.3573H20.6107L23.7135 13.3657L15.9995 20.6787V10.3573Z"
        fill="#D82332"
      />
    </svg>
  );
}
