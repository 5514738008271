import React from "react";

const IconDiamondAdminRoom = ({ width, height }: any) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7.61096L7.69143 13.3333H8.30857L14 7.61096V6.99046L11.1543 4.12927L10.8457 4H5.15429L4.84571 4.12927L2 6.99046V7.61096ZM8 12.4026L2.92571 7.30071L5.33429 4.8618H10.6657L13.0743 7.30071L8 12.4026ZM8 5.74084H10.3057L11.8571 7.30071L8 11.0926V5.74084Z"
        fill="url(#paint0_linear_1444_29252)"
      />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="url(#paint1_linear_1444_29252)" />
      <defs>
        <linearGradient
          id="paint0_linear_1444_29252"
          x1="12.8372"
          y1="4"
          x2="7.53677"
          y2="14.5599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF777D" />
          <stop offset="1" stopColor="#BC051E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1444_29252"
          x1="14.4496"
          y1="-3.7222e-08"
          x2="4.12403"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF777D" />
          <stop offset="1" stopColor="#BC051E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default IconDiamondAdminRoom;
