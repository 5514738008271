import { Divider, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useStore } from "react-redux";
import IconDiamond from "../../../../public/assets/icons/IconDiamond";
import AlertDialog from "../../common/dialog/AlertDialog";
import ModalGiftDiamond from "../../account/components/ModalGiftDiamond";
import {
  AddFollowIcon24,
  DeleteFollowIcon,
  RemoveFriendIcon
} from "../../../../public/assets/icons/RelationshipFriend";

export function MenuActionFriend({
  anchorEl,
  invitationId,
  memberHid,
  displayName,
  handleCloseMenu,
  relationship,
  handleAcceptFriend,
  handleDeclineFriendRequest,
  handleDeleteInvitation,
  handleDeleteFollow,
  handleRemoveFriend
}) {
  const store = useStore();
  const intl = useIntl();
  const [openDialogConfirmDeleteFriend, setOpenDialogConfirmDeleteFriend] = useState("");
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
            width: "170px",
            display: "flex",
            justifyContent: "center"
          }
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {relationship === "friend.relationship.pendingYourConfirm" && (
          <div>
            <MenuItem
              onClick={() => {
                handleAcceptFriend(store, invitationId, memberHid);
                handleCloseMenu();
              }}
            >
              <span className="text-[14px]">
                <FormattedMessage id="friend.menu.confirm" />
              </span>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleDeclineFriendRequest(store, invitationId, memberHid);
                handleCloseMenu();
              }}
            >
              <span className="text-[14px]">
                <FormattedMessage id="friend.menu.unPendingYourConfirm" />
              </span>
            </MenuItem>
          </div>
        )}
        {relationship === "friend.relationship.following" && (
          <MenuItem
            onClick={() => {
              handleDeleteFollow(store, memberHid);
              handleCloseMenu();
            }}
          >
            <span className="text-[14px]">
              <FormattedMessage id="friend.menu.unFollow" />
            </span>
          </MenuItem>
        )}
        {relationship === "friend.relationship.pendingConfirm" && (
          <MenuItem
            onClick={() => {
              handleDeleteInvitation(store, memberHid);
              handleCloseMenu();
            }}
          >
            <span className="text-[14px]">
              <FormattedMessage id="friend.menu.unPendingConfirm" />
            </span>
          </MenuItem>
        )}
        {relationship === "friend.relationship.friend" && (
          <MenuItem
            onClick={() => {
              setOpenDialogConfirmDeleteFriend(displayName);
              handleCloseMenu();
            }}
          >
            <span className="text-[14px]">
              <FormattedMessage id="friend.menu.unFriend" />
            </span>
          </MenuItem>
        )}
      </Menu>
      <AlertDialog
        open={!!openDialogConfirmDeleteFriend}
        setOpen={() => setOpenDialogConfirmDeleteFriend("")}
        title={intl.formatMessage({ id: "friend.confirm.unFriend.title" }, { name: openDialogConfirmDeleteFriend })}
        content={intl.formatMessage(
          { id: "friend.confirm.unFriend.description" },
          { name: openDialogConfirmDeleteFriend }
        )}
        triggerFunc={() => {
          setOpenDialogConfirmDeleteFriend("");
          handleRemoveFriend(store, memberHid);
        }}
        buttonOkKey="Setting.deleteTitle"
      />
    </>
  );
}

export function MenuListFriend({
  anchorEl,
  memberHid,
  displayName,
  handleCloseMenu,
  isFollowing,
  isFriend,
  handleDeleteFollow,
  handleFollowFriend,
  handleRemoveFriend
}) {
  const store = useStore();
  const intl = useIntl();
  const [openModalGiftDiamond, setOpenModalGiftDiamond] = React.useState(false);
  const [openDialogConfirmDeleteFriend, setOpenDialogConfirmDeleteFriend] = useState("");
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
            width: "170px",
            display: "flex",
            justifyContent: "center"
          }
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 10,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            setOpenModalGiftDiamond(true);
          }}
        >
          <IconDiamond width={22} height={18} />
          <span className="text-[14px] ml-2">
            <FormattedMessage id="friend.giveDiamond" />
          </span>
        </MenuItem>
        {isFriend && (
          <MenuItem
            onClick={() => {
              setOpenDialogConfirmDeleteFriend(displayName);
              handleCloseMenu();
            }}
            className="ml-[-2px]"
          >
            <RemoveFriendIcon />
            <span className="text-[14px] ml-2">
              <FormattedMessage id="friend.menu.unFriend" />
            </span>
          </MenuItem>
        )}
        {isFollowing ? (
          <MenuItem
            onClick={() => {
              handleDeleteFollow(store, memberHid);
              handleCloseMenu();
            }}
            className="ml-[-2px]"
          >
            <DeleteFollowIcon />
            <span className="text-[14px] ml-2">
              <FormattedMessage id="friend.menu.unFollow" />
            </span>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleFollowFriend(store, memberHid);
              handleCloseMenu();
            }}
            className="ml-[-2px]"
          >
            <AddFollowIcon24 />
            <span className="text-[14px] ml-2">
              <FormattedMessage id="friend.menu.follow" />
            </span>
          </MenuItem>
        )}
      </Menu>
      <AlertDialog
        open={!!openDialogConfirmDeleteFriend}
        setOpen={() => setOpenDialogConfirmDeleteFriend("")}
        title={intl.formatMessage({ id: "friend.confirm.unFriend.title" }, { name: openDialogConfirmDeleteFriend })}
        content={intl.formatMessage(
          { id: "friend.confirm.unFriend.description" },
          { name: openDialogConfirmDeleteFriend }
        )}
        triggerFunc={() => {
          setOpenDialogConfirmDeleteFriend("");
          handleRemoveFriend(store, memberHid);
        }}
        buttonOkKey="Setting.deleteTitle"
        customButton="bg-[#B00020] w-[149px] h-[44px]"
      />
      {openModalGiftDiamond && (
        <ModalGiftDiamond
          open={openModalGiftDiamond}
          handleClose={() => setOpenModalGiftDiamond(false)}
          memberHid={memberHid}
        />
      )}
    </>
  );
}
