import { Button } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import ArrowLeft from "../../../../public/assets/icons/ArrowLeft";
import { debounceButton } from "../../common/utils/constant";

export default function SubHeaderFriendPage({
  title,
  subTitle,
  handleSearchLocation = () => {},
  isShowButtonSearchLocation = false
}) {
  const router = useRouter();
  return (
    <div className="flex items-center justify-between mb-2 w-full sm:ml-[-4px] md:ml-0">
      <div className="flex gap-1">
        <button type="button" onClick={() => router.push({ query: {} })}>
          <ArrowLeft />
        </button>
        <div className="flex flex-col">
          <span className="text-[15px] font-semibold">{title}</span>
          {subTitle && (
            <span className="text-[12px] font-normal">{subTitle}</span>
          )}
        </div>
      </div>
      {isShowButtonSearchLocation && (
        <Button
          className="bg-primary rounded-[29px] text-[10px] text-white normal-case"
          onClick={() => debounceButton(handleSearchLocation)}
        >
          <FormattedMessage id="friend.searchLocation" />
        </Button>
      )}
    </div>
  );
}
