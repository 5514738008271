import React from "react";

export default function IconCopy({ width = "16", height = "16", fill = "none" }: any) {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="16" y="16" width="16" height="16" rx="4" transform="rotate(-180 16 16)" fill="#E5E5E5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.60923 12.2004L11.7983 12.2001C12.1297 12.2 12.3984 12.4686 12.3984 12.8C12.3985 13.1313 12.1299 13.4 11.7985 13.4001L9.60932 13.4004L9.60923 13.4004L6.55809 13.4004C5.22941 13.4004 4.14336 13.1424 3.40358 12.4131C2.66166 11.6818 2.39843 10.6057 2.39843 9.29147L2.39844 6.28382L2.39844 4.00039C2.39844 3.66902 2.66706 3.40039 2.99844 3.40039C3.32981 3.40039 3.59844 3.66902 3.59844 4.00039L3.59844 6.28382L3.59843 9.29147C3.59843 10.4836 3.84373 11.162 4.24599 11.5585C4.65039 11.9571 5.34416 12.2004 6.55809 12.2004L9.60914 12.2004L9.60923 12.2004Z"
        fill="#666666"
      />
      <path
        d="M4.59844 8.12019L4.59844 5.48019C4.59844 3.28019 5.47844 2.4002 7.67844 2.4002L10.3184 2.4002C12.5184 2.4002 13.3984 3.2802 13.3984 5.4802L13.3984 8.1202C13.3984 10.3202 12.5184 11.2002 10.3184 11.2002L7.67844 11.2002C5.47844 11.2002 4.59844 10.3202 4.59844 8.12019Z"
        stroke="#666666"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const IconCopyLarge = ({ width, height }: any) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3184 19.6504L19.1991 19.6498C19.6133 19.6497 19.9492 19.9855 19.9492 20.3997C19.9493 20.8139 19.6135 21.1497 19.1993 21.1498L15.3185 21.1504L15.3184 21.1504L9.90952 21.1504C7.58412 21.1504 5.76137 20.6968 4.5376 19.4905C3.31116 18.2815 2.84921 16.4779 2.84922 14.18L2.84922 8.84829L2.84922 4.80039C2.84922 4.38618 3.185 4.05039 3.59922 4.05039C4.01343 4.05039 4.34922 4.38618 4.34922 4.80039L4.34922 8.84829L4.34922 14.18C4.34922 16.3253 4.78875 17.6318 5.59062 18.4223C6.39515 19.2153 7.72756 19.6504 9.90952 19.6504L15.3182 19.6504L15.3184 19.6504Z"
        fill="#666666"
      />
      <path
        d="M6.00156 12.54L6.00156 7.86C6.00156 3.96 7.56156 2.4 11.4616 2.4L16.1416 2.4C20.0416 2.4 21.6016 3.96 21.6016 7.86L21.6016 12.54C21.6016 16.44 20.0416 18 16.1416 18L11.4616 18C7.56156 18 6.00156 16.44 6.00156 12.54Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconCopyAccountManagement = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6693 8.5987V11.3987C10.6693 13.732 9.73594 14.6654 7.4026 14.6654H4.6026C2.26927 14.6654 1.33594 13.732 1.33594 11.3987V8.5987C1.33594 6.26536 2.26927 5.33203 4.6026 5.33203H7.4026C9.73594 5.33203 10.6693 6.26536 10.6693 8.5987Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6693 4.5987V7.3987C14.6693 9.73203 13.7359 10.6654 11.4026 10.6654H10.6693V8.5987C10.6693 6.26536 9.73594 5.33203 7.4026 5.33203H5.33594V4.5987C5.33594 2.26536 6.26927 1.33203 8.6026 1.33203H11.4026C13.7359 1.33203 14.6693 2.26536 14.6693 4.5987Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
