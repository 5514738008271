import React from "react";

const IconDiamondRoomCard = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.11096L5.69143 9.83333H6.30857L12 4.11096V3.49046L9.15429 0.629271L8.84571 0.5H3.15429L2.84571 0.629271L0 3.49046V4.11096ZM6 8.90259L0.925714 3.80071L3.33429 1.3618H8.66571L11.0743 3.80071L6 8.90259ZM6 2.24084H8.30571L9.85714 3.80071L6 7.59264V2.24084Z"
        fill="url(#paint0_linear_1454_51639)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1454_51639"
          x1="10.8372"
          y1="0.5"
          x2="5.53677"
          y2="11.0599"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF777D" />
          <stop offset="1" stopColor="#BC051E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconDiamondRoomCard;
