/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
import Divider from "@mui/material/Divider";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setCookie } from "cookies-next";
import { genSignature, getNonce } from "../../../../utils/helpers/helpers";
import CXModal from "../../../common/components/CXModal";
import { commonActions } from "../../../common/redux/actions";
import { TCommonState } from "../../../common/utils/type";
import { LIST_LANGUAGE } from "../constants";

const ModalChooseLanguage = ({ open, handleClose, required }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { listLanguages, userLanguage } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const handleChooseLanguage = (code) => {
    dispatch(commonActions?.handleOpenModalChooseLanguage(false));
    handleClose();
    dispatch(commonActions?.handleSaveUserLanguage(code));
    const data = {
      user_language: code,
      timestamp: moment().unix(),
      nonce: getNonce()
    };
    const dataPost = {
      ...data,
      signature: genSignature(data)
    };
    dispatch(commonActions?.handleUpdateLanguageUser(dataPost));
    dispatch(commonActions.handleGetUserInfo());
    setCookie("userLanguage", JSON.stringify(code));
  };
  React.useEffect(() => {
    if (listLanguages?.length === 0) {
      dispatch(commonActions.handleGetLanguageOptions());
    }
  }, []);

  return (
    <CXModal
      open={open}
      handleClose={handleClose}
      title={intl.formatMessage({ id: "choose.language" })}
      isShowDivider
      minHeight="340px"
      maxWidth="md:max-w-[560px]"
      isShowBtnClose={!required}
      chooseLanguage
      padding= "73px"
    >
      <div className="flex flex-col">
        {listLanguages?.length > 0 &&
          listLanguages?.map((item, index) => (
            <>
            <button
              type="button"
              key={index}
              className={`flex items-center gap-x-4 capitalize py-5 px-6 mt-[-1px] ${
                userLanguage === item?.code ? "bg-primary100" : "hover:bg-gray"
              }`}
              onClick={() => handleChooseLanguage(item?.code)}
            >
              <span>{LIST_LANGUAGE?.find((ele) => ele?.value === item?.code)?.icon}</span>
              <span className="font-medium">{intl.formatMessage({ id: item?.title })}</span>
            </button>
            {index === listLanguages?.length -1 ? null : <Divider className="mx-6" />}
            </>
          ))}
      </div>
    </CXModal>
  );
};
export default ModalChooseLanguage;
