/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-empty-interface */
import Box from "@mui/material/Box";
import React from "react";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Badge, useScrollTrigger } from "@mui/material";
import { isEmpty, LIST_BLOCK_URL } from "../../../../utils/constants/constants";
import DirectAppResponsive from "../../../common/components/DirectAppResponsive";
import DrawerHeader from "./DrawerLeftSide";
import { LIST_MENU } from "../constants";
import { commonActions } from "../../../common/redux/actions";
import IconAnonymous from "../../../../../public/assets/icons/IconAnonymous";
import NotificationDropdown from "../../../common/Notification/NotificationDropDown";
import IconNotification from "../../../../../public/assets/icons/IconNotification";
import Breadcrumb from "../../../common/components/BreadCrumb";
import IconSearch from "../../../../../public/assets/icons/IconSearch";
import SelectGrade from "../components/SelectGrade";
import { UserCircle, UserCircleActive } from "../../../../../public/assets/icons/UserCircle";

interface IResponsiveHeaderProps {
  isNewMember: boolean;
  isHomePage: boolean;
  userInfo: any;
  handleOpenModalImage: () => void;
}

const ResponsiveHeader: React.FunctionComponent<IResponsiveHeaderProps> = (props) => {
  const { isNewMember, userInfo, handleOpenModalImage, isHomePage } = props;
  const [isFocus, setFocus] = React.useState(false);
  const userClass = useSelector((state: { commonNew: any }) => state.commonNew.grade);
  const scrollTrigger = useScrollTrigger({ threshold: 20 });
  const router = useRouter();
  const isNotLogin = isEmpty(userInfo?.member);
  const dispatch = useDispatch();
  const checkPageActive = (page: any) => {
    if (page.key === "common.bottombar.arena") {
      return router.asPath.includes("/thi-online");
    } else if (page.key === "Question_and_Answer") {
      return router?.pathname?.includes("/hoi-dap");
    } else if (page.key === "common.bottombar.gift") {
      return router?.pathname?.includes("/qua-tang");
    }
    return router.asPath === page.link;
  };

  return (
    <AppBar position="fixed" style={{ boxShadow: "unset" }} className="bg-primary">
      {!scrollTrigger && (
        <div className="sm:flex sm:flex-row w-screen text-black bg-grayBackground">
          <DirectAppResponsive />
        </div>
      )}
      <div className="flex justify-between gap-4 bg-primary py-2 items-center">
        <div className="flex">
          <button type="button" className="sm:mx-2 relative">
            {userInfo.member ? (
              <DrawerHeader
                member={userInfo.member}
                isNewMember={isNewMember}
                handleOpenModalImage={handleOpenModalImage}
              />
            ) : (
              <Box>
                <button
                  type="button"
                  onClick={() => dispatch(commonActions.handleOpenModalLogin(true))}
                  className="sm:mt-2"
                >
                  <IconAnonymous width="32" height="32" />
                </button>
              </Box>
            )}
          </button>
          <button type="button" className="flex items-center justify-start gap-2 ml-2" onClick={() => router.push("/")}>
            <span className="text-white text-[18px] font-bold">
              <FormattedMessage id="Title.jlpt" />
            </span>
          </button>
        </div>
        <div className="flex items-center gap-x-[10px] pr-1">
          <Box>
            <SelectGrade />
          </Box>
          {router?.pathname !== "/tim-kiem" && (
            <button onClick={() => router.push("/tim-kiem")} type="button" disabled={router?.pathname === "/tim-kiem"}>
              <IconSearch stroke="white" />
            </button>
          )}
          <Box
            className="text-white rounded-full"
            onClick={() => {
              if (!isEmpty(userInfo?.member)) {
                setFocus(!isFocus);
              } else {
                dispatch(commonActions.handleOpenModalLogin(true));
              }
            }}
          >
            <Badge
              // badgeContent={<Typography variant="caption" style={{ color: WHITE }}></Typography>}
              color="secondary"
            >
              {/* <NotificationsIcon /> */}
              <IconNotification />
              {isFocus && <NotificationDropdown setFocus={setFocus} isFocus={isFocus} />}
            </Badge>
          </Box>
        </div>
      </div>
      <div
        className="px-1"
        style={{
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
          minHeight: "32px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <div className="flex flex-row flex-nowrap w-full items-center sm:justify-between md:justify-center px-1 sm:px-[0]">
          {LIST_MENU.map((page, index) => (
            <button
              type="button"
              key={index}
              onClick={() => {
                if (isNotLogin && LIST_BLOCK_URL.some((v) => page.link.includes(v))) {
                } else if (index !== 4) {
                  if (page.key === "common.bottombar.arena") {
                    router.push(`/${userClass}/thi-online?tab=phong-thi`);
                  } else {
                    router.push(page.link);
                  }
                } else {
                  // #gift
                  router.push(page.link);
                }
              }}
            >
              {/* eslint-disable-next-line  */}
              <span className="mx-auto sm:flex sm:justify-center w-10">
                {checkPageActive(page) ? page?.iconMobileActive : page?.iconMobile}
              </span>
            </button>
          ))}
          <button
            type="button"
            onClick={() =>
              userInfo.member ? router.push(`/tai-khoan`) : dispatch(commonActions.handleOpenModalLogin(true))
            }
            className="pr-1"
          >
            {router.asPath.includes("/tai-khoan") ? <UserCircleActive /> : <UserCircle />}
          </button>
        </div>
      </div>
      {!isHomePage && (
        <div className="truncate w-screen bg-white px-2 py-1">
          <Breadcrumb />
        </div>
      )}
    </AppBar>
  );
};

export default ResponsiveHeader;
