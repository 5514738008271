import React from "react";

export default function IconDevice() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7V17C19 21 18 22 14 22H8C4 22 3 21 3 17V7C3 3 4 2 8 2H14C18 2 19 3 19 7Z"
        stroke="#F34D53"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 5.5H9"
        stroke="#F34D53"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9992 19.1C11.8553 19.1 12.5492 18.406 12.5492 17.55C12.5492 16.694 11.8553 16 10.9992 16C10.1432 16 9.44922 16.694 9.44922 17.55C9.44922 18.406 10.1432 19.1 10.9992 19.1Z"
        stroke="#F34D53"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
