/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
import { Avatar, Badge } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import IconAccepted from "../../../../public/assets/icons/IconAccepted";
import IconStar from "../../../../public/assets/icons/IconStar";
import ToolTipStar from "../../common/components/ToolTipStar";
import { TCommonState } from "../../common/utils/type";
import { SHOW_MENU_CONFIG, STATUS_POST, showGradeWithSlug } from "../utils/constants";
import { TPackages, TPost } from "../utils/types";
import MenuEditPost from "./MenuEditPost";
import { IconDiamond16 } from "../../../../public/assets/icons/IconDiamond";

type TProps = {
  postItem: TPost;
  // eslint-disable-next-line no-unused-vars
  time: (x: number) => { intlKey: string | undefined; value: number | undefined };
  packages: TPackages[];
  type?: string;
  packageDetail?: TPackages;
  page: string;
  subjectType?: any;
  isHomePage?: boolean;
};
const PostItemHeader = (props: TProps) => {
  const userInfo = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const router = useRouter();
  const { postItem, time, packages, type, packageDetail, page, subjectType, isHomePage } = props;
  const checkShowMenu = () => {
    if (postItem?.created_by_member?.member_hid === userInfo.member?.pub_id) {
      if (postItem?.status === STATUS_POST.OPEN) {
        if (postItem.edited_times < 1) {
          return [
            SHOW_MENU_CONFIG.SHOW_BUTTON,
            SHOW_MENU_CONFIG.SHOW_EDIT,
            SHOW_MENU_CONFIG.SHOW_DELETE,
            SHOW_MENU_CONFIG.SHOW_CLOSE
          ];
        }
        return [SHOW_MENU_CONFIG.SHOW_BUTTON, SHOW_MENU_CONFIG.SHOW_CLOSE, SHOW_MENU_CONFIG.SHOW_DELETE];
      }
      return [SHOW_MENU_CONFIG.DONT_SHOW];
    }
    if (postItem?.created_by_member?.member_hid !== userInfo.member?.pub_id) {
      if (postItem?.report_available) {
        return [SHOW_MENU_CONFIG.SHOW_BUTTON, SHOW_MENU_CONFIG.SHOW_COMPLAIN];
      }
      return [SHOW_MENU_CONFIG.DONT_SHOW];
    }
    return [SHOW_MENU_CONFIG.DONT_SHOW];
  };

  return (
    <div className="md:w-full sm:w-screen">
      <div className="px-2 w-full">
        <div className="flex flex-row md:justify-between sm:justify-start items-center w-full">
          <div className="flex justify-start items-center gap-4 md:w-[80%] lg:w-[90%] sm:w-[90%]">
            <Badge>
              <button
                type="button"
                onClick={() => {
                  if (postItem?.is_created_by_admin || isHomePage) {
                  } else {
                    router.push(`/tai-khoan/${postItem?.created_by_member?.member_hid}`);
                  }
                }}
              >
                <Avatar src={postItem?.created_by_member?.photo_url} />
              </button>
            </Badge>
            <div className="flex flex-col justify-start overflow-hidden">
              <span className=" flex flex-row items-center w-full">
                <div className="flex items-center md:w-[90%] xl:w-max sm:w-4/5 font-semibold  text-[15px]">
                  <span
                    className="sm:line-clamp-1 hover:cursor-pointer hover:underline sm:w-full"
                    onClick={() => {
                      if (postItem.is_created_by_admin || isHomePage) {
                      } else {
                        router.push(`/tai-khoan/${postItem?.created_by_member?.member_hid}`);
                      }
                    }}
                  >
                    {postItem?.created_by_member?.display_name}
                  </span>
                </div>
              </span>
              <div className="flex flex-row items-center flex-nowrap sm:text-xs sm2:gap-x-3 sm:gap-x-2 text-gray400 w-full">
                <span className="line-clamp-1 sm:text-xs sm:w-max">
                  {postItem?.created_at && (
                    <FormattedMessage
                      id={time(postItem?.created_at).intlKey}
                      values={{ num: time(postItem?.created_at).value }}
                    />
                  )}
                </span>
                {postItem?.fee > 0 && (
                  <span className="flex items-center sm:text-xs text-gray400">
                    {postItem?.fee}
                    <IconDiamond16 />
                  </span>
                )}
                <span className="text-gray400 select-all sm:text-xs sm:w-max line-clamp-1">
                  {type
                    ? packageDetail?.display_title
                    : showGradeWithSlug(packages, postItem?.subject_type_id, postItem?.grade_id)}
                </span>
                {postItem?.star > 0 && (
                  <span className="flex items-center text-xs ">
                    <span>{postItem?.star}</span>
                    <ToolTipStar star={postItem?.activate_times_bonus_star}>
                      <IconStar />
                    </ToolTipStar>
                  </span>
                )}
              </div>
            </div>
          </div>
          {page !== "homepage" && (
            <div className="flex gap-4 pr-2">
              <span>{postItem?.is_accepted ? <IconAccepted /> : null}</span>
              {userInfo?.member?.pub_id && checkShowMenu().includes(SHOW_MENU_CONFIG.SHOW_BUTTON) && (
                <MenuEditPost postItem={postItem} userInfo={userInfo} page={page} subjectType={subjectType} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default PostItemHeader;
