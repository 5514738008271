import { Box } from "@mui/material";
import ModalImage from "react-modal-image";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import RoomCard from "./RoomCard";

export function RoomPreviewCard({ room, roomsData, contentText, contentImages }) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const textSplit = contentText?.content?.split(urlRegex);

  const directLink = () => {
    const roomId = textSplit[1].split("/");
    return roomId[roomId.length - 1];
  };
  const grade = () => {
    const roomId = textSplit[1].split("/");
    return roomId[3];
  };
  return (
    <Box>
      <Box className="border-[1px] border-gray rounded-[15px] py-2 sm2:px-2 mt-2 w-full">
        <div className="px-3 text-left">
          <span>{textSplit[0]}</span>
          <Link href={`/${grade()}/thi-online/phong-thi/${directLink()}`}>
            <button type="button" className="text-primary hover:underline text-left text-xs">
              {textSplit[1]}
            </button>
          </Link>
          <p className="text-xs line-clamp-1">{textSplit[textSplit.length - 1]}</p>
        </div>

        <div className="px-[10px] 2xl:w-2/3 sm:w-full">
          <RoomCard room={room} roomsData={roomsData} index={0} handleDirectDetail={() => {}} isPreview hideStatus />
        </div>
        <div className="flex justify-end">
          <Link href={`/${grade()}/thi-online/phong-thi/${directLink()}`}>
            <button type="button" className="text-primary underline underline-offset-4 px-3 text-xs">
              <FormattedMessage id="ask.post.shareRoom.join" />
            </button>
          </Link>
        </div>
      </Box>
      {contentImages?.length > 0 && (
        <div className="md:flex-row sm:flex-col flex gap-4 justify-center w-full items-center mt-3 md:flex-nowrap">
          {contentImages.map((e) => (
            <div className="" key={e.post_content_id}>
              <ModalImage
                small={e.content}
                large={e.content}
                alt="jlptup.com"
                hideDownload
                hideZoom
                className="lg:w-[20vw] md:w-[35vw] sm:w-[40vw] rounded-lg mb-4"
              />
            </div>
          ))}
        </div>
      )}
    </Box>
  );
}

export const RoomPreviewCardSmall = ({ roomData, href, isHomePage = false }) => {
  return (
    <Link href={href}>
      <a href={href} rel="noreferrer" target="_blank">
        <button
          type="button"
          className={`px-4 py-3 ${
            isHomePage ? "min-w-[250px]" : "sm:w-[70vw] sm2:w-[50vw] md:min-w-[300px] max-w-[400px] md:w-[auto]"
          }   border-[1px] border-primary rounded-[16px] text-[13px] font-medium flex flex-col gap-y-3`}
        >
          <div className="flex text-left line-clamp-1 text-ellipsis">
            <FormattedMessage id="ask.shareRoom.title" values={{ title: roomData?.title }} />
          </div>
          <div className="flex gap-2 flex-row justify-between w-full">
            <div className="text-left line-clamp-1 w-1/2">
              <FormattedMessage id="ask.shareRoom.subject" values={{ subject: roomData?.subject_title }} />
            </div>
            <div className="line-clamp-1">
              <FormattedMessage id="ask.shareRoom.type" values={{ type: roomData?.room_type?.title }} />
            </div>
          </div>
        </button>
      </a>
    </Link>
  );
};
