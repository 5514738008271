import { Box } from "@mui/material";
import React, { memo } from "react";
import CountUp from "react-countup";
import { FormattedMessage } from "react-intl";

const DURATION_COUNT_UP = 3;
const INCREASE_RATE_CORRECT = 90;
const ACHIEVED_GOALS = 85;
const EXAM_STUDY_MATERIAL = 50000;
const TEACHERS_AND_STUDENTS_USE = 10000;

const Introduce = () => {
  return (
    <Box className="bgIntroduceBox px-4 py-6 rounded-2xl bg-white h-[476px]">
      <h2 className="text-red1 text-[18px] leading-[22px] text-center uppercase mb-10">
        <FormattedMessage id="introduce.title" />
      </h2>
      <div className="flex flex-col">
        <div className="mb-6 text-[13px] leading-[16px] text-black1 min-h-[52px]">
          <span className="text-transparent text-[24px] bg-clip-text bg-primary">
            <CountUp start={0} end={INCREASE_RATE_CORRECT} duration={DURATION_COUNT_UP} suffix="%" />
          </span>
          <FormattedMessage id="introduce.increaseRateCorrect" />
        </div>
        <div className="mb-6 text-[13px] leading-[16px] text-black1">
          <span className="text-transparent text-[24px] bg-clip-text bg-primary">
            <CountUp start={0} end={ACHIEVED_GOALS} duration={DURATION_COUNT_UP} suffix="%" />
          </span>
          <FormattedMessage id="introduce.achievedGoals" />
        </div>
        <div className="mb-6 text-[13px] leading-[16px] text-black1 min-h-[36px]">
          <span className="text-transparent text-[24px] bg-clip-text bg-primary">
            <CountUp start={0} end={EXAM_STUDY_MATERIAL} duration={DURATION_COUNT_UP} separator="." suffix="+" />
          </span>
          <FormattedMessage id="introduce.examStudyMaterial" />
        </div>
        <div className="mb-6 text-[13px] leading-[16px] text-black1">
          <span className="text-transparent text-[24px] bg-clip-text bg-primary">
            <CountUp start={0} end={TEACHERS_AND_STUDENTS_USE} duration={DURATION_COUNT_UP} separator="." suffix="+" />
          </span>
          <FormattedMessage id="introduce.teachersAndStudentsUse" />
        </div>
        <div className="mb-6 text-[13px] leading-[24px] text-black1 text-center">
          <FormattedMessage id="introduce.practiceProficiently" />
          <br />
          <span className="text-transparent text-[24px] bg-clip-text bg-primary">
            <FormattedMessage id="introduce.fourSkills" />
          </span>
        </div>
      </div>
    </Box>
  );
};

export default memo(Introduce);
