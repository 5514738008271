import React from "react";

export const FriendIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5026 9.9974C12.8038 9.9974 14.6693 8.13192 14.6693 5.83073C14.6693 3.52954 12.8038 1.66406 10.5026 1.66406C8.20142 1.66406 6.33594 3.52954 6.33594 5.83073C6.33594 8.13192 8.20142 9.9974 10.5026 9.9974Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.34375 18.3333C3.34375 15.1083 6.55208 12.5 10.5021 12.5C11.3021 12.5 12.0771 12.6083 12.8021 12.8083"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8307 14.9974C18.8307 15.6224 18.6557 16.2141 18.3474 16.7141C18.1724 17.0141 17.9474 17.2807 17.6891 17.4974C17.1057 18.0224 16.3391 18.3307 15.4974 18.3307C14.2807 18.3307 13.2224 17.6807 12.6474 16.7141C12.3391 16.2141 12.1641 15.6224 12.1641 14.9974C12.1641 13.9474 12.6474 13.0057 13.4141 12.3974C13.9891 11.9391 14.7141 11.6641 15.4974 11.6641C17.3391 11.6641 18.8307 13.1557 18.8307 14.9974Z"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1953 14.9963L15.0203 15.8213L16.7953 14.1797"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PendingConfirmIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0026 9.9974C12.3038 9.9974 14.1693 8.13192 14.1693 5.83073C14.1693 3.52954 12.3038 1.66406 10.0026 1.66406C7.70142 1.66406 5.83594 3.52954 5.83594 5.83073C5.83594 8.13192 7.70142 9.9974 10.0026 9.9974Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.84375 18.3333C2.84375 15.1083 6.05208 12.5 10.0021 12.5C10.8021 12.5 11.5771 12.6083 12.3021 12.8083"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3307 14.9974C18.3307 15.2641 18.2974 15.5224 18.2307 15.7724C18.1557 16.1057 18.0224 16.4307 17.8474 16.7141C17.2724 17.6807 16.2141 18.3307 14.9974 18.3307C14.1391 18.3307 13.3641 18.0057 12.7807 17.4724C12.5307 17.2557 12.3141 16.9974 12.1474 16.7141C11.8391 16.2141 11.6641 15.6224 11.6641 14.9974C11.6641 14.0974 12.0224 13.2724 12.6057 12.6724C13.2141 12.0474 14.0641 11.6641 14.9974 11.6641C15.9807 11.6641 16.8724 12.0891 17.4724 12.7724C18.0057 13.3641 18.3307 14.1474 18.3307 14.9974Z"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2411 14.9859H13.7578M16.2411 14.9859L14.9995 16.6693M16.2411 14.9859L14.9995 13.3359"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const PendingYourConfirmIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5026 9.9974C12.8038 9.9974 14.6693 8.13192 14.6693 5.83073C14.6693 3.52954 12.8038 1.66406 10.5026 1.66406C8.20142 1.66406 6.33594 3.52954 6.33594 5.83073C6.33594 8.13192 8.20142 9.9974 10.5026 9.9974Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.34375 18.3333C3.34375 15.1083 6.55208 12.5 10.5021 12.5C11.3021 12.5 12.0771 12.6083 12.8021 12.8083"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8307 14.9974C18.8307 15.2641 18.7974 15.5224 18.7307 15.7724C18.6557 16.1057 18.5224 16.4307 18.3474 16.7141C17.7724 17.6807 16.7141 18.3307 15.4974 18.3307C14.6391 18.3307 13.8641 18.0057 13.2807 17.4724C13.0307 17.2557 12.8141 16.9974 12.6474 16.7141C12.3391 16.2141 12.1641 15.6224 12.1641 14.9974C12.1641 14.0974 12.5224 13.2724 13.1057 12.6724C13.7141 12.0474 14.5641 11.6641 15.4974 11.6641C16.4807 11.6641 17.3724 12.0891 17.9724 12.7724C18.5057 13.3641 18.8307 14.1474 18.8307 14.9974Z"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7417 14.9859H14.2583M15.5 13.3359L14.25 15.0026L15.5 16.6693"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const AddFriendIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5026 9.9974C12.8038 9.9974 14.6693 8.13192 14.6693 5.83073C14.6693 3.52954 12.8038 1.66406 10.5026 1.66406C8.20142 1.66406 6.33594 3.52954 6.33594 5.83073C6.33594 8.13192 8.20142 9.9974 10.5026 9.9974Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.34375 18.3333C3.34375 15.1083 6.55208 12.5 10.5021 12.5C11.3021 12.5 12.0771 12.6083 12.8021 12.8083"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8307 14.9974C18.8307 15.2641 18.7974 15.5224 18.7307 15.7724C18.6557 16.1057 18.5224 16.4307 18.3474 16.7141C17.7724 17.6807 16.7141 18.3307 15.4974 18.3307C14.6391 18.3307 13.8641 18.0057 13.2807 17.4724C13.0307 17.2557 12.8141 16.9974 12.6474 16.7141C12.3391 16.2141 12.1641 15.6224 12.1641 14.9974C12.1641 14.0974 12.5224 13.2724 13.1057 12.6724C13.7141 12.0474 14.5641 11.6641 15.4974 11.6641C16.4807 11.6641 17.3724 12.0891 17.9724 12.7724C18.5057 13.3641 18.8307 14.1474 18.8307 14.9974Z"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7411 14.9844H14.2578"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 13.7656V16.2573"
        stroke="white"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const FollowingIcon = ({ stroke = "white" }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_5969_21767" fill="white">
        <path d="M2.64568 3.15019C2.89928 3.01026 3.19083 2.93814 3.48712 2.94204H16.5033C16.802 2.93827 17.0959 3.0111 17.3516 3.15227C17.2428 2.86548 17.0386 2.61686 16.7676 2.4411C16.4966 2.26534 16.1722 2.17122 15.8397 2.17188H4.15298C3.82157 2.17194 3.49839 2.26616 3.22829 2.44146C2.9582 2.61677 2.75457 2.86446 2.64568 3.15019Z" />
      </mask>
      <path
        d="M3.48712 2.94204V4.24204H3.47858L3.47004 4.24192L3.48712 2.94204ZM16.5033 2.94204L16.5197 4.24193L16.5115 4.24204H16.5033V2.94204ZM17.3516 3.15227L18.5671 2.69127L19.8226 6.00184L16.7231 4.29029L17.3516 3.15227ZM15.8397 2.17188L15.8423 3.47188H15.8397V2.17188ZM4.15298 2.17188L4.15275 0.871879H4.15298V2.17188ZM2.01765 2.01195C2.47335 1.76052 2.98794 1.63536 3.50421 1.64215L3.47004 4.24192C3.39371 4.24092 3.32522 4.26 3.27371 4.28842L2.01765 2.01195ZM3.48712 1.64204H16.5033V4.24204H3.48712V1.64204ZM16.4869 1.64214C17.0055 1.63559 17.5224 1.76154 17.98 2.01425L16.7231 4.29029C16.6695 4.26066 16.5985 4.24094 16.5197 4.24193L16.4869 1.64214ZM16.136 3.61326C16.1308 3.59941 16.1124 3.56563 16.0603 3.53184L17.4749 1.35036C17.9649 1.66809 18.3548 2.13156 18.5671 2.69127L16.136 3.61326ZM16.0603 3.53184C16.0071 3.49731 15.9306 3.4717 15.8423 3.47188L15.8371 0.871881C16.4137 0.87074 16.9861 1.03337 17.4749 1.35036L16.0603 3.53184ZM15.8397 3.47188H4.15298V0.871879H15.8397V3.47188ZM4.15321 3.47188C4.06516 3.47189 3.98907 3.4975 3.93605 3.53191L2.52054 1.35101C3.00771 1.03482 3.57797 0.87198 4.15275 0.871879L4.15321 3.47188ZM3.93605 3.53191C3.88415 3.5656 3.86575 3.59926 3.86045 3.61315L1.43091 2.68722C1.6434 2.12967 2.03225 1.66793 2.52054 1.35101L3.93605 3.53191Z"
        fill={stroke || "white"}
        mask="url(#path-1-inside-1_5969_21767)"
      />
      <mask id="path-3-inside-2_5969_21767" fill="white">
        <path d="M1.66146 4.72954C1.85036 4.58 2.07768 4.47168 2.32455 4.41357C2.46853 4.37893 2.61719 4.36111 2.76661 4.36057H17.2277C17.3764 4.36032 17.5244 4.37816 17.6674 4.41357C17.9138 4.47116 18.1405 4.57957 18.3281 4.72954C18.2636 4.39686 18.0653 4.09475 17.7683 3.87682C17.4713 3.65888 17.0948 3.53922 16.7057 3.53906H3.28392C2.8948 3.53922 2.51832 3.65888 2.2213 3.87682C1.92429 4.09475 1.72594 4.39686 1.66146 4.72954Z" />
      </mask>
      <path
        d="M1.66146 4.72954L2.46834 5.74882L-0.282379 7.92634L0.38521 4.48216L1.66146 4.72954ZM2.32455 4.41357L2.62866 5.67752L2.6224 5.67899L2.32455 4.41357ZM2.76661 4.36057L2.76196 3.06057H2.76661V4.36057ZM17.2277 4.36057L17.2299 5.66057H17.2277V4.36057ZM17.6674 4.41357L17.3715 5.67946L17.3632 5.67751L17.3549 5.67545L17.6674 4.41357ZM18.3281 4.72954L19.6044 4.48216L20.2768 7.95125L17.5165 5.74503L18.3281 4.72954ZM16.7057 3.53906V2.23906L16.7062 2.23906L16.7057 3.53906ZM3.28392 3.53906L3.2834 2.23906H3.28392V3.53906ZM2.2213 3.87682L2.99036 4.92494L2.2213 3.87682ZM0.854572 3.71026C1.2009 3.4361 1.60415 3.24761 2.0267 3.14816L2.6224 5.67899C2.5512 5.69575 2.49982 5.7239 2.46834 5.74882L0.854572 3.71026ZM2.02044 3.14964C2.26338 3.09119 2.51258 3.06147 2.76196 3.06058L2.77126 5.66056C2.72181 5.66074 2.67367 5.66667 2.62866 5.6775L2.02044 3.14964ZM2.76661 3.06057H17.2277V5.66057H2.76661V3.06057ZM17.2255 3.06057C17.4792 3.06015 17.733 3.09054 17.9799 3.15169L17.3549 5.67545C17.3158 5.66578 17.2735 5.6605 17.2299 5.66057L17.2255 3.06057ZM17.9632 3.14769C18.3878 3.2469 18.7929 3.43681 19.1398 3.71405L17.5165 5.74503C17.4881 5.72232 17.4398 5.69543 17.3715 5.67946L17.9632 3.14769ZM17.0519 4.97692C17.0553 4.99471 17.0575 4.96767 16.9992 4.92494L18.5373 2.8287C19.0731 3.22183 19.472 3.79901 19.6044 4.48216L17.0519 4.97692ZM16.9992 4.92494C16.9381 4.88007 16.8344 4.83911 16.7051 4.83906L16.7062 2.23906C17.3552 2.23932 18.0044 2.43769 18.5373 2.8287L16.9992 4.92494ZM16.7057 4.83906H3.28392V2.23906H16.7057V4.83906ZM3.28443 4.83906C3.1552 4.83911 3.05151 4.88007 2.99036 4.92494L1.45225 2.8287C1.98514 2.43769 2.63439 2.23932 3.2834 2.23906L3.28443 4.83906ZM2.99036 4.92494C2.93212 4.96767 2.93425 4.99471 2.9377 4.97692L0.38521 4.48216C0.517626 3.79902 0.916456 3.22183 1.45225 2.8287L2.99036 4.92494Z"
        fill={stroke || "white"}
        mask="url(#path-3-inside-2_5969_21767)"
      />
      <path
        d="M1.66146 7.06163V16.6835C1.66146 17.0976 1.82426 17.4948 2.11405 17.7877C2.40384 18.0806 2.79688 18.2451 3.20671 18.2451H16.7829C17.1927 18.2451 17.5857 18.0806 17.8755 17.7877C18.1653 17.4948 18.3281 17.0976 18.3281 16.6835V7.06163C18.3276 6.84317 18.2818 6.62724 18.1936 6.4278C18.1054 6.22837 17.9767 6.04985 17.816 5.90379C17.6398 5.73967 17.427 5.62103 17.1957 5.55801C17.0614 5.51925 16.9225 5.49973 16.7829 5.5H3.20671C3.06801 5.50026 2.92996 5.51901 2.79611 5.55578C2.56257 5.61936 2.34756 5.73872 2.16918 5.90379C2.00923 6.05025 1.88142 6.22894 1.79397 6.42836C1.70652 6.62778 1.66138 6.8435 1.66146 7.06163V7.06163Z"
        stroke={stroke || "white"}
        strokeWidth="1.3"
      />
      <path
        d="M7.12491 11.1693C6.87175 10.9148 6.4602 10.9137 6.20568 11.1669C5.95116 11.42 5.95005 11.8316 6.20321 12.0861L7.12491 11.1693ZM9.18943 14.1667L8.72858 14.6251C8.85061 14.7477 9.01651 14.8167 9.18955 14.8167C9.36259 14.8166 9.52847 14.7476 9.65045 14.6249L9.18943 14.1667ZM13.7917 10.4582C14.0448 10.2036 14.0436 9.79204 13.7889 9.53898C13.5343 9.28591 13.1228 9.28717 12.8697 9.54179L13.7917 10.4582ZM6.20321 12.0861L8.72858 14.6251L9.65028 13.7083L7.12491 11.1693L6.20321 12.0861ZM9.65045 14.6249L13.7917 10.4582L12.8697 9.54179L8.72841 13.7085L9.65045 14.6249Z"
        fill={stroke || "white"}
      />
    </svg>
  );
};
export const AddFollowIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_1544_6891" fill="white">
        <path d="M2.64959 3.15019C2.90319 3.01026 3.19473 2.93814 3.49103 2.94204H16.5072C16.8059 2.93827 17.0998 3.0111 17.3555 3.15227C17.2467 2.86548 17.0426 2.61686 16.7715 2.4411C16.5005 2.26534 16.1761 2.17122 15.8436 2.17188H4.15689C3.82547 2.17194 3.50229 2.26616 3.2322 2.44146C2.9621 2.61677 2.75848 2.86446 2.64959 3.15019Z" />
      </mask>
      <path
        d="M3.49103 2.94204V4.44204H3.48117L3.47131 4.44191L3.49103 2.94204ZM16.5072 2.94204L16.5261 4.44192L16.5166 4.44204H16.5072V2.94204ZM17.3555 3.15227L18.758 2.62035L20.2067 6.44023L16.6304 4.46537L17.3555 3.15227ZM15.8436 2.17188L15.8466 3.67188H15.8436V2.17188ZM4.15689 2.17188L4.15662 0.671878H4.15689V2.17188ZM1.92494 1.83684C2.41173 1.56825 2.96063 1.43493 3.51075 1.44216L3.47131 4.44191C3.42883 4.44135 3.39465 4.45227 3.37423 4.46353L1.92494 1.83684ZM3.49103 1.44204H16.5072V4.44204H3.49103V1.44204ZM16.4883 1.44215C17.0407 1.43518 17.5919 1.5693 18.0806 1.83917L16.6304 4.46537C16.6078 4.4529 16.5711 4.44135 16.5261 4.44192L16.4883 1.44215ZM15.9529 3.68418C15.9636 3.71232 15.9738 3.7116 15.9554 3.69965L17.5876 1.18255C18.1113 1.52212 18.5298 2.01865 18.758 2.62035L15.9529 3.68418ZM15.9554 3.69965C15.9356 3.68685 15.8974 3.67177 15.8466 3.67188L15.8406 0.671881C16.4548 0.670666 17.0653 0.843837 17.5876 1.18255L15.9554 3.69965ZM15.8436 3.67188H4.15689V0.671878H15.8436V3.67188ZM4.15715 3.67188C4.10654 3.67189 4.06847 3.68693 4.04884 3.69967L2.41556 1.18325C2.93612 0.845378 3.5444 0.671987 4.15662 0.671878L4.15715 3.67188ZM4.04884 3.69967C4.0305 3.71157 4.0406 3.7123 4.05124 3.68438L1.24793 2.61599C1.47636 2.01663 1.8937 1.52196 2.41556 1.18325L4.04884 3.69967Z"
        fill="#D82332"
        mask="url(#path-1-inside-1_1544_6891)"
      />
      <path
        d="M2.20947 3.88951L2.33234 4.41153L2.20716 3.89126C1.92233 4.10783 1.73215 4.40318 1.66927 4.72759L2.20947 3.88951ZM2.20947 3.88951C2.21597 3.88459 2.22252 3.8797 2.22912 3.87486C2.39018 3.75668 2.57461 3.6674 2.77175 3.6104C2.77264 3.61015 2.77353 3.60989 2.77442 3.60963C2.7756 3.60929 2.77678 3.60896 2.77796 3.60862H3.29173V3.53711M2.20947 3.88951L3.29173 3.53711M3.29173 3.53711L3.29176 3.60862H16.7134L16.7135 3.53711M3.29173 3.53711H16.7135M16.7135 3.53711V3.60862H17.2272C17.0627 3.56167 16.8894 3.53718 16.7135 3.53711ZM17.2355 3.61099C17.4319 3.66801 17.6156 3.75709 17.7761 3.87486C17.7831 3.88002 17.7901 3.88522 17.797 3.89047L17.6752 4.41162M17.2355 3.61099L16.7135 4.28711C16.8546 4.2872 16.9888 4.31312 17.1081 4.35862H17.2355M17.2355 3.61099V4.35862M17.2355 3.61099V4.35862M17.6752 4.41162L17.8031 3.89513C18.0851 4.11134 18.2734 4.4051 18.3359 4.72759C18.1483 4.57762 17.9216 4.46921 17.6752 4.41162ZM17.6752 4.41162C17.5899 4.3905 17.5028 4.37563 17.4149 4.36712C17.3554 4.36136 17.2955 4.35852 17.2355 4.35862M2.89713 4.35862C3.01645 4.31312 3.15059 4.2872 3.29173 4.28711L2.89713 4.35862ZM2.89713 4.35862H2.77442H2.89713ZM16.7132 4.28711H3.29203H16.7132Z"
        stroke="#D82332"
        strokeWidth="1.5"
      />
      <path
        d="M1.66927 7.05968V16.6815C1.66927 17.0957 1.83207 17.4929 2.12186 17.7858C2.41166 18.0786 2.8047 18.2431 3.21452 18.2431H16.7907C17.2005 18.2431 17.5936 18.0786 17.8833 17.7858C18.1731 17.4929 18.3359 17.0957 18.3359 16.6815V7.05968C18.3355 6.84122 18.2896 6.62529 18.2014 6.42585C18.1132 6.22641 17.9845 6.0479 17.8238 5.90184C17.6476 5.73772 17.4348 5.61908 17.2035 5.55605C17.0692 5.5173 16.9303 5.49777 16.7907 5.49805H3.21452C3.07583 5.4983 2.93777 5.51706 2.80393 5.55382C2.57038 5.61741 2.35538 5.73676 2.177 5.90184C2.01705 6.0483 1.88923 6.22699 1.80178 6.42641C1.71433 6.62583 1.66919 6.84155 1.66927 7.05968V7.05968Z"
        stroke="#D82332"
        strokeWidth="1.5"
      />
      <path
        d="M9.25 8.43945C9.25 8.02524 9.58579 7.68945 10 7.68945C10.4142 7.68945 10.75 8.02524 10.75 8.43945H9.25ZM10.75 14.9671C10.75 15.3813 10.4142 15.7171 10 15.7171C9.58579 15.7171 9.25 15.3813 9.25 14.9671H10.75ZM10.75 8.43945V14.9671H9.25V8.43945H10.75Z"
        fill="#D82332"
      />
      <path
        d="M6.66589 12.4551C6.25168 12.4551 5.91589 12.1193 5.91589 11.7051C5.91589 11.2909 6.25168 10.9551 6.66589 10.9551V12.4551ZM13.3359 10.9551C13.7502 10.9551 14.0859 11.2909 14.0859 11.7051C14.0859 12.1193 13.7502 12.4551 13.3359 12.4551V10.9551ZM6.66589 10.9551H13.3359V12.4551H6.66589V10.9551Z"
        fill="#D82332"
      />
    </svg>
  );
};

export const RemoveFriendIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.40625 22C3.40625 18.13 7.25625 15 11.9962 15C12.9562 15 13.8863 15.13 14.7563 15.37"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0319 16.9375L16.9219 19.0475"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9453 16.9609L19.0653 19.0709"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DeleteFollowIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_4420_73647" fill="white">
        <path d="M3.79716 4.09141C4.08008 3.93531 4.40533 3.85485 4.73588 3.85919H19.2568C19.5901 3.85499 19.9179 3.93625 20.2031 4.09373C20.0818 3.77379 19.854 3.49643 19.5517 3.30035C19.2493 3.10427 18.8874 2.99927 18.5165 3H5.47871C5.10899 3.00007 4.74845 3.10518 4.44713 3.30075C4.1458 3.49632 3.91864 3.77266 3.79716 4.09141Z" />
      </mask>
      <path
        d="M4.73588 3.85919V5.35919H4.72602L4.71616 5.35907L4.73588 3.85919ZM19.2568 3.85919L19.2757 5.35908L19.2662 5.35919H19.2568V3.85919ZM20.2031 4.09373L21.6056 3.56181L23.0544 7.38169L19.478 5.40683L20.2031 4.09373ZM18.5165 3L18.5195 4.5H18.5165V3ZM5.47871 3L5.47845 1.5H5.47871V3ZM3.07251 2.77806C3.58862 2.4933 4.17122 2.35164 4.7556 2.35932L4.71616 5.35907C4.63943 5.35806 4.57154 5.37732 4.52181 5.40476L3.07251 2.77806ZM4.73588 2.35919H19.2568V5.35919H4.73588V2.35919ZM19.2378 2.35931C19.8248 2.35191 20.41 2.49445 20.9282 2.78063L19.478 5.40683C19.4259 5.37805 19.3553 5.35807 19.2757 5.35908L19.2378 2.35931ZM18.8006 4.62565C18.7987 4.62063 18.7853 4.59117 18.7355 4.5589L20.3678 2.0418C20.9228 2.40169 21.3649 2.92696 21.6056 3.56181L18.8006 4.62565ZM18.7355 4.5589C18.6845 4.52578 18.6087 4.49982 18.5195 4.5L18.5135 1.50001C19.1661 1.49872 19.8141 1.68277 20.3678 2.0418L18.7355 4.5589ZM18.5165 4.5H5.47871V1.5H18.5165V4.5ZM5.47898 4.5C5.39006 4.50002 5.31462 4.52596 5.26376 4.55897L3.63049 2.04254C4.18227 1.6844 4.82791 1.50012 5.47845 1.5L5.47898 4.5ZM5.26376 4.55897C5.21421 4.59113 5.20076 4.62049 5.19882 4.6256L2.39551 3.55721C2.63652 2.92482 3.0774 2.40152 3.63049 2.04254L5.26376 4.55897Z"
        fill="#666666"
        mask="url(#path-1-inside-1_4420_73647)"
      />
      <mask id="path-3-inside-2_4420_73647" fill="white">
        <path d="M2.70345 5.85154C2.91419 5.68471 3.16779 5.56387 3.4432 5.49905C3.60382 5.4604 3.76967 5.44051 3.93637 5.43992H20.0692C20.2351 5.43964 20.4002 5.45954 20.5597 5.49905C20.8346 5.5633 21.0875 5.68423 21.2969 5.85154C21.2249 5.48041 21.0037 5.14337 20.6723 4.90024C20.341 4.65711 19.921 4.52361 19.4868 4.52344H4.51347C4.07937 4.52361 3.65937 4.65711 3.32802 4.90024C2.99667 5.14337 2.77539 5.48041 2.70345 5.85154Z" />
      </mask>
      <path
        d="M2.70345 5.85154L3.63447 7.02764L0.460562 9.54016L1.23086 5.56611L2.70345 5.85154ZM3.4432 5.49905L3.7941 6.95744L3.78688 6.95914L3.4432 5.49905ZM3.93637 5.43992L3.931 3.93992H3.93637V5.43992ZM20.0692 5.43992L20.0717 6.93992H20.0692V5.43992ZM20.5597 5.49905L20.2184 6.95968L20.2088 6.95744L20.1992 6.95506L20.5597 5.49905ZM21.2969 5.85154L22.7695 5.56611L23.5453 9.5689L20.3604 7.02326L21.2969 5.85154ZM19.4868 4.52344V3.02344L19.4874 3.02344L19.4868 4.52344ZM4.51347 4.52344L4.51288 3.02344H4.51347V4.52344ZM1.77243 4.67544C2.16481 4.36483 2.62141 4.15149 3.09952 4.03895L3.78688 6.95914C3.71416 6.97626 3.66357 7.0046 3.63447 7.02764L1.77243 4.67544ZM3.0923 4.04067C3.36712 3.97454 3.64896 3.94094 3.931 3.93993L3.94173 6.93991C3.89038 6.94009 3.84052 6.94626 3.7941 6.95743L3.0923 4.04067ZM3.93637 3.93992H20.0692V6.93992H3.93637V3.93992ZM20.0667 3.93992C20.3538 3.93944 20.6409 3.97383 20.9203 4.04303L20.1992 6.95506C20.1596 6.94526 20.1164 6.93984 20.0717 6.93992L20.0667 3.93992ZM20.9011 4.03841C21.3815 4.15069 21.8403 4.36567 22.2334 4.67982L20.3604 7.02326C20.3347 7.00279 20.2878 6.9759 20.2184 6.95968L20.9011 4.03841ZM19.8243 6.13697C19.8271 6.1514 19.8312 6.15598 19.828 6.15113C19.8245 6.14592 19.8126 6.1299 19.7849 6.10961L21.5597 3.69087C22.1665 4.13615 22.6191 4.79058 22.7695 5.56611L19.8243 6.13697ZM19.7849 6.10961C19.7257 6.06617 19.6205 6.02349 19.4863 6.02344L19.4874 3.02344C20.2214 3.02373 20.9562 3.24804 21.5597 3.69087L19.7849 6.10961ZM19.4868 6.02344H4.51347V3.02344H19.4868V6.02344ZM4.51407 6.02344C4.37984 6.02349 4.27459 6.06617 4.21539 6.10961L2.44065 3.69087C3.04416 3.24804 3.7789 3.02373 4.51288 3.02344L4.51407 6.02344ZM4.21539 6.10961C4.18773 6.1299 4.17578 6.14592 4.17236 6.15113C4.16917 6.15598 4.17325 6.1514 4.17604 6.13697L1.23086 5.56611C1.38118 4.79058 1.83378 4.13615 2.44065 3.69087L4.21539 6.10961Z"
        fill="#666666"
        mask="url(#path-3-inside-2_4420_73647)"
      />
      <path
        d="M2.70345 8.4531V19.1873C2.70345 19.6493 2.88507 20.0925 3.20837 20.4192C3.53166 20.7459 3.97014 20.9294 4.42734 20.9294H19.573C20.0302 20.9294 20.4687 20.7459 20.792 20.4192C21.1153 20.0925 21.2969 19.6493 21.2969 19.1873V8.4531C21.2963 8.20939 21.2452 7.9685 21.1468 7.746C21.0484 7.52351 20.9049 7.32435 20.7255 7.16141C20.529 6.97831 20.2916 6.84596 20.0335 6.77565C19.8837 6.73242 19.7287 6.71063 19.573 6.71094H4.42734C4.27261 6.71122 4.11859 6.73214 3.96928 6.77316C3.70874 6.8441 3.46887 6.97725 3.26987 7.16141C3.09143 7.3248 2.94884 7.52415 2.85128 7.74662C2.75372 7.9691 2.70336 8.20976 2.70345 8.4531V8.4531Z"
        stroke="#666666"
        strokeWidth="1.5"
      />
      <path d="M14.5781 11.0625L9.42881 16.2118" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9.37113 11.0039L14.6328 16.2656" stroke="#666666" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export const AddFollowIcon24 = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_8680_135195" fill="white">
        <path d="M3.17325 3.78335C3.47757 3.61544 3.82742 3.52889 4.18298 3.53357H19.8024C20.1609 3.52904 20.5136 3.61645 20.8203 3.78585C20.6898 3.44171 20.4448 3.14336 20.1196 2.93245C19.7943 2.72153 19.405 2.60859 19.0061 2.60938H4.98201C4.58432 2.60945 4.1965 2.72251 3.87239 2.93288C3.54827 3.14324 3.30392 3.44048 3.17325 3.78335V3.78335Z" />
      </mask>
      <path
        d="M4.18298 3.53357V5.03357H4.17312L4.16327 5.03344L4.18298 3.53357ZM19.8024 3.53357L19.8213 5.03345L19.8118 5.03357H19.8024V3.53357ZM20.8203 3.78585L22.2228 3.25393L23.6716 7.07381L20.0952 5.09895L20.8203 3.78585ZM19.0061 2.60938L19.009 4.10938H19.0061V2.60938ZM4.98201 2.60938L4.98175 1.10938H4.98201V2.60938ZM2.4486 2.47C2.98611 2.17343 3.59332 2.02569 4.2027 2.0337L4.16327 5.03344C4.06153 5.0321 3.96903 5.05745 3.8979 5.0967L2.4486 2.47ZM4.18298 2.03357H19.8024V5.03357H4.18298V2.03357ZM19.7834 2.03369C20.3957 2.02596 21.0056 2.17465 21.5454 2.47274L20.0952 5.09895C20.0215 5.05825 19.9261 5.03213 19.8213 5.03345L19.7834 2.03369ZM19.4178 4.31776C19.4067 4.28854 19.3761 4.2381 19.3034 4.19099L20.9357 1.6739C21.5135 2.04862 21.9729 2.59487 22.2228 3.25393L19.4178 4.31776ZM19.3034 4.19099C19.2295 4.14304 19.1263 4.10914 19.009 4.10938L19.0031 1.10938C19.6838 1.10804 20.3591 1.30003 20.9357 1.6739L19.3034 4.19099ZM19.0061 4.10938H4.98201V1.10938H19.0061V4.10938ZM4.98228 4.10938C4.86539 4.1094 4.76267 4.14329 4.68902 4.19109L3.05575 1.67466C3.63033 1.30173 4.30324 1.1095 4.98175 1.10938L4.98228 4.10938ZM4.68902 4.19109C4.61667 4.23805 4.58604 4.28832 4.57491 4.31754L1.7716 3.24915C2.0218 2.59264 2.47987 2.04844 3.05575 1.67466L4.68902 4.19109Z"
        fill="#475467"
        mask="url(#path-1-inside-1_8680_135195)"
      />
      <mask id="path-3-inside-2_8680_135195" fill="white">
        <path d="M2 5.67857C2.22668 5.49913 2.49947 5.36914 2.79571 5.29941C2.96848 5.25784 3.14688 5.23645 3.32618 5.23581H20.6795C20.8579 5.23551 21.0355 5.25692 21.2071 5.29941C21.5028 5.36852 21.7748 5.49861 22 5.67857C21.9226 5.27936 21.6846 4.91683 21.3282 4.6553C20.9718 4.39378 20.52 4.25019 20.053 4.25H3.94695C3.48 4.25019 3.02824 4.39378 2.67182 4.6553C2.3154 4.91683 2.07738 5.27936 2 5.67857Z" />
      </mask>
      <path
        d="M2 5.67857L2.93102 6.85467L-0.242889 9.36719L0.527407 5.39314L2 5.67857ZM2.79571 5.29941L3.14661 6.75781L3.13939 6.75951L2.79571 5.29941ZM3.32618 5.23581L3.32082 3.73581H3.32618V5.23581ZM20.6795 5.23581L20.682 6.73581H20.6795V5.23581ZM21.2071 5.29941L20.8657 6.76005L20.8561 6.7578L20.8465 6.75543L21.2071 5.29941ZM22 5.67857L23.4726 5.39314L24.2485 9.39593L21.0635 6.8503L22 5.67857ZM20.053 4.25V2.75L20.0536 2.75L20.053 4.25ZM3.94695 4.25L3.94635 2.75H3.94695V4.25ZM1.06898 4.50248C1.4773 4.17924 1.95309 3.95676 2.45203 3.83932L3.13939 6.75951C3.04584 6.78153 2.97606 6.81901 2.93102 6.85467L1.06898 4.50248ZM2.44481 3.84103C2.73178 3.77199 3.02617 3.73688 3.32082 3.73582L3.33155 6.7358C3.26759 6.73603 3.20518 6.7437 3.14661 6.75779L2.44481 3.84103ZM3.32618 3.73581H20.6795V6.73581H3.32618V3.73581ZM20.6769 3.73581C20.9766 3.73531 21.2762 3.7712 21.5677 3.8434L20.8465 6.75543C20.7949 6.74263 20.7392 6.73571 20.682 6.73581L20.6769 3.73581ZM21.5485 3.83878C22.0497 3.95591 22.5276 4.18005 22.9365 4.50685L21.0635 6.8503C21.022 6.81717 20.9559 6.78113 20.8657 6.76005L21.5485 3.83878ZM20.5274 5.96401C20.5274 5.96378 20.527 5.96176 20.5253 5.95781C20.5237 5.9538 20.5205 5.94726 20.5148 5.93855C20.5034 5.92116 20.481 5.89416 20.4408 5.86467L22.2156 3.44594C22.8475 3.90961 23.3168 4.58953 23.4726 5.39314L20.5274 5.96401ZM20.4408 5.86467C20.3565 5.80284 20.2195 5.75007 20.0524 5.75L20.0536 2.75C20.8205 2.75031 21.587 2.98472 22.2156 3.44594L20.4408 5.86467ZM20.053 5.75H3.94695V2.75H20.053V5.75ZM3.94755 5.75C3.78047 5.75007 3.64345 5.80284 3.55919 5.86467L1.78444 3.44594C2.41302 2.98472 3.17953 2.75031 3.94635 2.75L3.94755 5.75ZM3.55919 5.86467C3.519 5.89416 3.49659 5.92116 3.48517 5.93854C3.47945 5.94726 3.47631 5.9538 3.47466 5.95781C3.47303 5.96176 3.47263 5.96378 3.47259 5.96401L0.527407 5.39314C0.683168 4.58954 1.15251 3.90961 1.78444 3.44594L3.55919 5.86467Z"
        fill="#475467"
        mask="url(#path-3-inside-2_8680_135195)"
      />
      <path
        d="M2 8.47552V20.0217C2 20.5187 2.19536 20.9954 2.54311 21.3468C2.89086 21.6982 3.36251 21.8957 3.8543 21.8957H20.1457C20.6375 21.8957 21.1091 21.6982 21.4569 21.3468C21.8046 20.9954 22 20.5187 22 20.0217V8.47552C21.9994 8.21337 21.9444 7.95426 21.8386 7.71493C21.7327 7.4756 21.5783 7.26138 21.3854 7.08612C21.174 6.88917 20.9187 6.7468 20.6411 6.67117C20.48 6.62467 20.3132 6.60124 20.1457 6.60157H3.8543C3.68787 6.60187 3.5222 6.62437 3.36159 6.66849C3.08134 6.7448 2.82333 6.88802 2.60927 7.08612C2.41733 7.26186 2.26395 7.47629 2.15901 7.7156C2.05407 7.9549 1.99991 8.21377 2 8.47552V8.47552Z"
        stroke="#475467"
        strokeWidth="1.5"
      />
      <path d="M12 10.1328V17.9659" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.99594 14.0469H16" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
