/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { TCommonState } from "../utils/type";
import { subjectActions } from "../../subjects/redux/actions";
import { TypeInitSubjectReducer } from "../../subjects/utils/type";
import ArrowRight from "../../../../public/assets/icons/ArrowRight";

function ListSubjectsLessonPage() {
  const [showItem, setShowItem] = React.useState(0);
  const theme = useTheme();
  const router = useRouter();
  const dispatch = useDispatch();
  const arraySubjects = useSelector((state: { commonNew: TCommonState }) => state.commonNew.arraySubject);
  const { listSubject, lesson, action } = useSelector((state: { subject: TypeInitSubjectReducer }) => state.subject);
  const fetchTopics = () => {
    if (listSubject.length > 0) {
      dispatch(
        subjectActions.handleGetListTopic({
          subjectId: listSubject?.find(
            (item) => item?.subject?.slug === router?.query?.subjectsId && item?.type === "lesson"
          )?.slug
        })
      );
    }
  };
  const fetchSubjectsList = () => {
    dispatch(subjectActions.handleGetListSubject(router?.query?.subjectsId));
  };
  useEffect(() => {
    if (action.type === "GET_LIST_SUBJECTS_SUCCESS") {
      fetchTopics();
    }
  }, [action?.type]);
  useEffect(() => {
    const indexSlug = lesson?.items?.findIndex((ele: any) => {
      return ele?.items.findIndex((v) => v.slug === router?.query?.topicId) > -1;
    });
    setShowItem(indexSlug);
  }, [router?.query?.topicId, lesson]);

  useEffect(() => {
    if (!router?.query?.subjectsId) return;
    fetchSubjectsList();
  }, [router?.query?.subjectsId]);
  return (
    <Box className="box-cover-left md:w-[250px] lg:w-[298px] min-h-[120px]">
      <span className="flex text-title">
        <FormattedMessage id="Arena.subject" />
        &nbsp;
        {arraySubjects.find((ele) => router?.query?.subjectsId?.includes(ele?.slug))?.title ||
          arraySubjects.find((ele) => router?.query?.subjectsId?.includes(ele?.slug?.replace("tieng", "mon")))?.title}
      </span>
      <Box display="flex" flexDirection="column">
        {lesson?.items?.map((ele: any, index: number) => (
          <Box
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
            className="text-blackQuiz text-[15px] font-normal"
          >
            <Box width="100%">
              <Box
                onClick={() => {
                  setShowItem(showItem === index ? -1 : index);
                }}
                className="flex justify-between py-2"
              >
                <span
                  style={{
                    cursor: "pointer",
                    textAlign: "left",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    color: ele?.items.find((v) => v.slug === router?.query?.topicId)
                      ? theme.palette.primary.main
                      : "black"
                  }}
                >
                  {ele.title}
                </span>
                <button
                  type="button"
                  onClick={() => {
                    setShowItem(showItem === index ? -1 : index);
                  }}
                >
                  <ArrowRight />
                </button>
              </Box>
              {showItem === index &&
                ele?.items?.map((el, idx) => (
                  <button
                    type="button"
                    key={idx}
                    onClick={() => {
                      router.push(`/${router?.query.grade}/${router?.query?.subjectsId}/ly-thuyet/${el.slug}`);
                      const scrolled = document.documentElement.scrollTop;
                      if (scrolled > 300) {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth"
                        });
                      } else if (scrolled <= 300) {
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: 16,
                      textAlign: "left",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      color: router?.query.topicId === el.slug ? theme.palette.primary.main : "black"
                    }}
                    className="hover:underline"
                  >
                    {idx + 1}. {el.title}
                  </button>
                ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default ListSubjectsLessonPage;
