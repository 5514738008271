/* eslint-disable no-unsafe-optional-chaining */
import moment from "moment";
import { TPackages } from "./types";

export const QUESTION_PAGE_QUERY = "thao-luan";
export const RANKING_PAGE_QUERY = "xep-hang";
export const GUIDE_PAGE_QUERY = "huong-dan";
export const SETTINGS_PAGE_QUERY = "cai-dat";

export const headerQuestion = [
  {
    id: 1,
    name: "header.question",
    link: "hoi-dap/thao-luan",
    tab: QUESTION_PAGE_QUERY
  },
  {
    id: 2,
    name: "header.ranking",
    link: "hoi-dap/xep-hang",
    tab: RANKING_PAGE_QUERY
  },
  {
    id: 3,
    name: "header.guide",
    link: "hoi-dap/huong-dan",
    tab: GUIDE_PAGE_QUERY
  },
  {
    id: 4,
    name: "header.settings",
    link: "hoi-dap/cai-dat",
    tab: SETTINGS_PAGE_QUERY
  }
];

export const groupButton = [
  {
    id: 1,
    name: "button.all",
    link: "hoi-dap/thao-luan",
    tab: "all"
  },
  {
    id: 2,
    name: "button.saved",
    link: "hoi-dap/thao-luan",
    tab: "saved_post"
  },
  {
    id: 3,
    name: "button.my",
    link: "hoi-dap/thao-luan",
    tab: "my_post"
  }
];
export const cutText = (text: string) => {
  const arrText = text?.split("-");
  return arrText && arrText[arrText.length - 1];
};
export const showGradeWithSlug = (packagesList: TPackages[], packageId: number, gradeId) => {
  return packagesList?.find((item: TPackages) => item?.id === packageId && item?.parent_id === gradeId)?.display_title;
};
export const handleSubtractTimeCreated = (
  createdAt: number
): {
  value: number | undefined;
  intlKey: string | undefined;
} => {
  const minute = Number(moment().diff(moment.unix(createdAt), "minutes"));
  const hour = Number(moment().diff(moment.unix(createdAt), "hours"));
  const day = Number(moment().diff(moment.unix(createdAt), "day"));
  const week = Number(moment().diff(moment.unix(createdAt), "week"));
  const month = Number(moment().diff(moment.unix(createdAt), "month"));
  let time: { value: number | undefined; intlKey: string | undefined } = { value: undefined, intlKey: undefined };
  if (minute < 60 && hour === 0 && day === 0 && month === 0) {
    time = {
      value: minute,
      intlKey: "common.timeFromNow.minute"
    };
  }
  if (hour < 24 && hour > 0 && day === 0 && month === 0) {
    time = {
      value: hour,
      intlKey: "common.timeFromNow.hour"
    };
  }
  if (day <= 30 && day > 0 && month === 0) {
    time = {
      value: day,
      intlKey: "common.timeFromNow.day"
    };
  }
  if (week <= 4 && week > 0 && month === 0) {
    time = {
      value: week,
      intlKey: "common.timeFromNow.week"
    };
  }
  if (month <= 12 && month > 0) {
    time = {
      value: month,
      intlKey: "common.timeFromNow.month"
    };
  }
  return time;
};
export const listFilterDateQA = [
  {
    title: "QA.rank_lifetime",
    value: "lifetime"
  },
  {
    title: "QA.rank_weekly",
    value: "weekly"
  },
  {
    title: "QA.rank_monthly",
    value: "monthly"
  }
];

export const listFilter = [
  {
    name: "filter.haveBonus",
    value: "bonus"
  },
  {
    name: "filter.noBonus",
    value: "no_bonus"
  },
  {
    name: "filter.answer",
    value: "answer"
  },
  {
    name: "filter.noAnswer",
    value: "no_answer"
  },
  {
    name: "filter.accepted",
    value: "accepted"
  },
  {
    name: "filter.notAccepted",
    value: "not_accepted"
  }
];

export const STATUS_POST = {
  OPEN: "open",
  CLOSE: "closed"
};

export const menuComplain = [
  {
    name: "menu.complain.spam",
    value: "spam"
  },
  {
    name: "menu.complain.rule",
    value: "rule"
  },
  {
    name: "menu.complain.vandal",
    value: "vandal"
  }
];
export const parseGrade = (grade: string) => {
  const arrGrade = grade?.split("-");
  return `Lớp ${arrGrade[arrGrade.length - 1]}`;
};

export const filterGrade = (arr, grade: string) => {
  return arr.filter((item) => item.slug === grade);
};

export const ASK_MAX_NUM_IMAGE_UPLOAD = 2;
export const ASK_MAX_LENGTH_TITLE = 128;
export const ASK_MAX_LENGTH_REPORT = 128;
export const ASK_MAX_LENGTH_REPORT_QUIZ = 256;
export const ASK_MAX_LENGTH_CONTENT = 512;
export const ASK_POST_CONTENT_TYPE_HTML = "html";
export const ASK_POST_CONTENT_TYPE_IMAGE = "image";
export const ERROR_CODE = {
  CODE_NOT_ENOUGH_DIAMOND: "EBADND",
  CODE_EDIT_ONLY_ONE: "ENAEOO",
  CODE_REPORT_ONLY_ONE: "ENAROO",
  CODE_INVALID_DATA: "EBADSE",
  CODE_NOT_FOUND_ASK_POST: "ENFASP",
  CODE_SAVE_POST_FAILED: "ENFINT",
  CODE_NOT_ACCEPTABLE_POST_CLOSED: "ENAPCL"
};
export const TYPE_COMMENT = {
  COMMENT: 0,
  ANSWER: 1
};

export const TYPE_VOTE = {
  COMMENT: "comment",
  ANSWER: "answer"
};

export const SHOW_MENU_CONFIG = {
  SHOW_BUTTON: "SHOW_BUTTON",
  SHOW_COMPLAIN: "SHOW_COMPLAIN",
  SHOW_EDIT: "SHOW_EDIT",
  SHOW_DELETE: "SHOW_DELETE",
  DONT_SHOW: "DONT_SHOW",
  SHOW_CLOSE: "SHOW_CLOSE"
};
export const POST_STATUS = {
  OPEN: "open",
  CLOSE: "closed"
};

export const TYPE_SHOW_INPUT_COMMENT = {
  SHOW_INPUT: 0,
  SHOW_TEXT_CLOSED: 1,
  SHOW_TEXT_DONT_COMMENT: 2,
  DONT_SHOW: 3
};

export const LIST_TYPE_NOTIFICATION_RELOAD_PAGE = [
  "HANDLE_COMMENT_SUCCESS",
  "HANDLE_COMPLAIN_SUCCESS",
  "EDIT_COMMENT_SUCCESS",
  "ACCEPT_COMMENT_SUCCESS",
  "DELETE_COMMENT_SUCCESS",
  "HANDLE_CLOSE_POST_SUCCESS",
  "HANDLE_DELETE_POST_FAILED",
  "HANDLE_SAVE_POST_SUCCESS"
];

export const LIST_TYPE_NOTIFICATION_RELOAD_LIST_POST = [
  "EDIT_POST_SUCCESS",
  "HANDLE_CLOSE_POST_SUCCESS",
  "HANDLE_DELETE_POST_SUCCESS",
  "HANDLE_COMPLAIN_SUCCESS",
  "HANDLE_SAVE_POST_SUCCESS"
];
export const LIST_ERROR_CODE_RELOAD_LIST_POST = ["ENFASP", "ENAPCL", "ENAPHC", "ENFINT"];
export const LIST_ERROR_CODE_RELOAD_DETAIL_POST = ["ENAPCL", "ENFACO", "ENACIA"];

export const LIMIT_POSTS = 50;

export const wrapText = (s) => s?.replace(/(?![^\n]{1,32}$)([^\n]{1,32})\s/g, "$1\n");

export const LIST_TYPE_NOTIFY_DONT_SHOW_DIALOG = ["EDIT_POST_FAILED", "CREATE_POST_FAILED", "GET_DETAIL_POST_FAILED"];

export const MAX_IMAGE = 3;
export const ACCEPT_IMAGE_TYPE = ["image/png", "image/jpg", "image/jpeg"];
