/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
// import { useIntl } from "react-intl";
import { TAccountState } from "../../account/utils/types";
import { TArenaState } from "../../arena/utils/types";
import { TCommonState, TListSubjects } from "../utils/type";

export function convertBreadcrumbTitle(string: string) {
  return string.replace(/-/g, " ").replace(/oe/g, "ö").replace(/ae/g, "ä").replace(/ue/g, "ü").toLowerCase();
}

export default function useBreadcrumb() {
  const router = useRouter();
  const [breadcrumbs, setBreadcrumbs] = useState<any>(null);
  const { infoAccount } = useSelector((state: { account: TAccountState }) => state.account);
  const { grade, arraySubject } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const { roomDetail } = useSelector((state: { arenaRoom: TArenaState }) => state.arenaRoom);
  const asPath = router.asPath;
  const pathName = router?.pathname.split("/");
  // const intl = useIntl();
  useEffect(() => {
    if (router) {
      const originLinkPath: any = asPath.split("?")[0].split("/");
      originLinkPath.shift();

      const linkPath = originLinkPath?.filter((e) => !!e);
      const indexAccount = pathName.indexOf("[memberHid]");
      const indexRoom = pathName.indexOf("[roomHid]");
      const indexSubject = pathName.indexOf("[subjectsId]");
      const indexTopic = pathName.indexOf("[topicId]");
      const indexPost = pathName.indexOf("[postId]");
      const indexNews = pathName.indexOf("[newsId]");
      const indexGifts = pathName.indexOf("[giftId]");
      if (indexRoom !== -1) {
        linkPath[indexRoom - 1] = "";
      }
      if (indexTopic !== -1) {
        linkPath[indexTopic - 1] = "";
      }
      if (indexAccount !== -1) {
        linkPath[indexAccount - 1] = "";
      }
      if (indexPost !== -1) {
        linkPath[indexPost - 1] = "";
      }
      if (indexNews !== -1) {
        linkPath[indexNews - 1] = "";
      }
      if (indexGifts !== -1) {
        linkPath[indexGifts - 1] = "";
      }
      if (indexSubject !== -1) {
        linkPath[indexSubject - 1] = linkPath[indexSubject - 1].split("-lop-")[0];
      }
      const subjects = arraySubject?.find((item) => linkPath[indexSubject - 1]?.includes(item?.slug)) as TListSubjects;
      const pathArray = linkPath
        ?.filter((item) => item !== "")
        .map((path, i) => {
          return {
            breadcrumb: path?.split("?")[0]?.replace("lich-su-va-dia-li", "lich-su-va-dia-ly")?.includes(subjects?.slug)
              ? subjects?.title
              : path.includes("-lop-")
              ? path.split("-lop-")[0]
              : path,
            href: `/${
              originLinkPath.slice(0, i + 1).join("/") === grade ? "" : originLinkPath.slice(0, i + 1).join("/")
            }`
          };
        });
      setBreadcrumbs(pathArray?.filter((item) => item?.href !== "/")?.filter((item) => item?.breadcrumb !== ""));
    }
  }, [infoAccount, roomDetail, grade, arraySubject]);
  return breadcrumbs;
}
