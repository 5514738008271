/* eslint-disable no-unsafe-optional-chaining */
import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from "lodash";
import Link from "next/link";
import React, { memo, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { TTheme } from "../../../utils/configs/setupTheme";
import SubjectTypeCard from "../../subjects/components/SubjectTypeCard";
import { SHOW_MAXIMUM_EXERCISE_DONE_IN_DAILY_REPORT_DIALOG, isShowLock, REPORT_QUIZ } from "../utils/constant";
import { TCommonState } from "../utils/type";
import CustomSlider from "./CustomSlider";
import { ExamsCard } from "./Elements";
import ModalComplain from "../../questionAndAnswer/components/ModalComplain";

const TAB = {
  EXERCISE_DONE: "EXERCISE_DONE",
  LESSON_LEARNED: "LESSON_LEARNED"
};

type SubjectsInfoBlockProps = {
  examsList: any[];
  cardList: any[];
  practicesList: any[];
};

const SubjectsInfoBlock = ({ examsList, cardList, practicesList }: SubjectsInfoBlockProps) => {
  const theme: TTheme = useTheme();
  const intl = useIntl();
  const matches768 = useMediaQuery("(min-width:768px)");

  const dataUser = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const [tab, setTab] = useState<string>(TAB.EXERCISE_DONE);
  const [cardId, setCardId] = React.useState<number>(0);
  const [openModalReport, setOpenModalReport] = React.useState(false);

  const exerciseDoneList = useMemo(() => {
    return [...examsList, ...practicesList];
  }, [examsList, practicesList]);

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  const handleOpenModalReport = (ele) => {
    setCardId(ele?.topic_id);
    setOpenModalReport(true);
  };

  const handleCloseModalReport = () => {
    setOpenModalReport(false);
  };

  useEffect(() => {
    if (!exerciseDoneList.length) setTab(TAB.LESSON_LEARNED);
  }, [exerciseDoneList]);

  return (
    <Box className="flex flex-col gap-4">
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        sx={{
          height: 36,
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.main
          },
          "& .MuiTab-root": {
            color: theme.palette.primary.gray400
          },
          "& .MuiTab-root.Mui-selected": {
            color: theme.palette.primary.main
          }
        }}
      >
        <Tab
          label={intl.formatMessage({ id: "Profile.exerciseDone" })}
          className={`normal-case text-[15px] font-semibold ${!exerciseDoneList?.length && "hidden"}`}
          value={TAB.EXERCISE_DONE}
        />
        <Tab
          label={intl.formatMessage({ id: "Lesson_learned" })}
          className={`normal-case text-[15px] font-semibold ${!cardList?.length && "hidden"}`}
          value={TAB.LESSON_LEARNED}
        />
      </Tabs>
      <Box>
        <CustomSlider
          isDots={false}
          autoplay={false}
          className="w-full"
          centerMode={matches768 && (tab === TAB.EXERCISE_DONE ? exerciseDoneList.length > 1 : cardList.length > 1)}
        >
          {(() => {
            switch (tab) {
              case TAB.EXERCISE_DONE:
                return exerciseDoneList
                  ?.slice(0, SHOW_MAXIMUM_EXERCISE_DONE_IN_DAILY_REPORT_DIALOG)
                  ?.map((ele: any, index: number) => (
                    <Link
                      key={index}
                      style={{ textDecoration: "unset", color: "black" }}
                      href={ele.direct_link.replace("/lop/", "/")}
                    >
                      <a
                        href={ele?.direct_link?.replace("/lop/", "/")}
                        target="_blank"
                        rel="noreferrer"
                        className="inline-block w-full max-w-[360px] border border-gray rounded-2xl p-2 md:mx-1.5"
                        onClick={() =>
                          eventTracking("select_home_done_lesson", {
                            id: ele?.subject_id,
                            title: ele?.title,
                            grade_slug: ele?.grade_slug
                          })
                        }
                      >
                        <ExamsCard ele={ele} />
                      </a>
                    </Link>
                  ));
              case TAB.LESSON_LEARNED:
                return cardList
                  ?.slice(0, SHOW_MAXIMUM_EXERCISE_DONE_IN_DAILY_REPORT_DIALOG)
                  ?.map((ele: any, idx: number) => (
                    <Box
                      key={idx}
                      className="inline-block w-full max-w-[360px] border border-gray rounded-2xl p-3 md:mx-1.5"
                    >
                      <SubjectTypeCard
                        ele={ele}
                        isShowLock={(accessLevel) => isShowLock(accessLevel, dataUser)}
                        handleOpenModalReport={handleOpenModalReport}
                        blockLessonLeftSide
                        className="h-auto p-0 border-0 rounded-0"
                      />
                    </Box>
                  ));
              default:
                return "";
            }
          })()}
        </CustomSlider>
        {openModalReport && (
          <ModalComplain
            open={openModalReport}
            handleClose={handleCloseModalReport}
            title={<FormattedMessage id="title.report.quiz" />}
            subtitle={<FormattedMessage id="subtitle.report.card" />}
            type={REPORT_QUIZ}
            topic={{ id: cardId }}
            openTextInput
          />
        )}
      </Box>
    </Box>
  );
};

const checkEqualProps = (prevProps, nextProps) => {
  return (
    isEqual(prevProps?.examsList, nextProps?.examsList) &&
    isEqual(prevProps?.cardList, nextProps?.cardList) &&
    isEqual(prevProps?.practicesList, nextProps?.practicesList)
  );
};

export default memo(SubjectsInfoBlock, checkEqualProps);
