import React from "react";

export default function TransHistory() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.28516 14.4287H14.428"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.28516 12.7144H14.428"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8574 12.7144V17.0001"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7143 8L11.8571 12.7143L8 8"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.55 12.2941C2.55 12.5426 2.75147 12.7441 3 12.7441C3.24853 12.7441 3.45 12.5426 3.45 12.2941H2.55ZM10 3.29406L9.8816 2.85992L10 3.29406ZM21 9.29406L20.3678 4.13651L16.2173 7.26277L21 9.29406ZM3.45 12.2941C3.45 9.74891 4.88294 5.15606 10.1184 3.7282L9.8816 2.85992C4.11705 4.43207 2.55 9.50587 2.55 12.2941H3.45ZM10.1184 3.7282C13.6255 2.77173 16.3596 4.44298 18.2407 6.37271L18.8852 5.74449C16.8959 3.70376 13.8411 1.78003 9.8816 2.85992L10.1184 3.7282Z"
        fill="#666666"
      />
      <path
        d="M21.45 12C21.45 11.7515 21.2485 11.55 21 11.55C20.7515 11.55 20.55 11.7515 20.55 12L21.45 12ZM14 20.7152L14.1149 21.1503L14 20.7152ZM3 14.9051L3.72555 20.0503L7.81869 16.8494L3 14.9051ZM20.55 12C20.55 14.4508 19.1251 18.8963 13.8851 20.2802L14.1149 21.1503C19.8749 19.6291 21.45 14.7138 21.45 12L20.55 12ZM13.8851 20.2802C10.4049 21.1993 7.68713 19.617 5.80999 17.774L5.17947 18.4162C7.17198 20.3725 10.2023 22.1836 14.1149 21.1503L13.8851 20.2802Z"
        fill="#666666"
      />
    </svg>
  );
}
