/* eslint-disable react/require-default-props */
import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";
import { TTheme } from "../../../utils/configs/setupTheme";

export function CXCircularProgress({
  height = "30vh",
}: { height?: string | undefined } = {}) {
  const theme: TTheme = useTheme();
  return (
    <Box className="circular-progress sm:w-screen md:w-full" height={height}>
      <CircularProgress sx={{ color: theme.palette.primary.main }} disableShrink />
    </Box>
  );
}
