/* eslint-disable jsx-a11y/control-has-associated-label */
import { Box } from "@mui/material";
import React from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { FormattedMessage } from "react-intl";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import UploadImageIcon from "../../../../public/assets/icons/UploadImageIcon";
import XCircleBlack from "../../../../public/assets/icons/XCircleBlack";
import { ACCEPT_TYPE_IMAGE, MAX_FILE_SIZE_IMAGE } from "../utils/constant";

type TypeUploadImages = {
  values: ImageListType;
  // eslint-disable-next-line no-unused-vars
  onChange: (a: any, b: any) => void;
  maxNumber: number;
};

export default function UploadImages({ values, onChange, maxNumber }: TypeUploadImages) {
  return (
    <div>
      <ImageUploading
        acceptType={ACCEPT_TYPE_IMAGE}
        multiple
        value={values}
        onChange={onChange}
        maxNumber={maxNumber}
        maxFileSize={MAX_FILE_SIZE_IMAGE}
      >
        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps, errors }) => (
          <Box className="border-[1px] border-grayPlaceholder rounded-md py-2 my-4" {...dragProps}>
            <div className="flex flex-nowrap justify-center gap-3 px-3 py-1">
              {imageList?.map((image, index) => (
                <div
                  key={index}
                  className={`${
                    imageList.length > 1 ? "w-full" : "w-[45%]"
                  } file:rounded-md border-[1px] border-gray relative`}
                >
                  <img src={image?.dataURL} alt="" />
                  <button type="button" className="absolute top-0 right-0" onClick={() => onImageRemove(index)}>
                    <XCircleBlack />
                  </button>
                </div>
              ))}
            </div>
            {imageList?.length < maxNumber && (
              <Box className="flex flex-col py-4 items-center justify-center" onClick={onImageUpload}>
                <button className="flex justify-center" type="button" style={isDragging ? { color: "red" } : undefined}>
                  <UploadImageIcon />
                </button>
                <div className="text-[13px]">
                  <span className="text-primary">
                    <FormattedMessage id="ask.createPost.uploadImageColor" />
                  </span>{" "}
                  <span>
                    <FormattedMessage id="ask.createPost.uploadImage" />
                  </span>
                </div>
                <span className="text-[12px] text-gray1">
                  <FormattedMessage id="ask.createPost.uploadImageHelperText" />
                </span>
                <span>
                  {errors && (
                    <div className="text-[12px] text-red mt-1 items-center flex justify-center gap-1">
                      <ReportGmailerrorredOutlinedIcon fontSize="small" />
                      {errors.maxNumber && (
                        <span>
                          <FormattedMessage id="ask.createPost.errorMaxNum" values={{ num: maxNumber }} />
                        </span>
                      )}
                      {errors.acceptType && (
                        <span>
                          <FormattedMessage id="ask.createPost.errorAcceptType" />
                        </span>
                      )}
                      {errors.maxFileSize && (
                        <span>
                          <FormattedMessage id="ask.createPost.errorMaxSize" />
                        </span>
                      )}
                    </div>
                  )}
                </span>
              </Box>
            )}
          </Box>
        )}
      </ImageUploading>
    </div>
  );
}
