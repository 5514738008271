import { NextSeo } from "next-seo";
import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import DefaultLayout from "./DefaultLayout/DefaultLayout";
import { appMetaDescription, domainUrl, googleAdsClient } from "../../../utils/constants/env";

type TPageProps = {
  title?: string;
  description?: string;
  imageUrl?: string;
  ogTitle?: string;
  ogDescription?: string;
};

// eslint-disable-next-line react/require-default-props
const Layout: React.FC<{ children: any; pageProps?: TPageProps }> = ({ children, pageProps }) => {
  const router = useRouter();
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <script
          async
          src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${googleAdsClient}`}
          crossOrigin="anonymous"
        />
      </Head>
      <NextSeo
        additionalLinkTags={[
          {
            rel: "apple-touch-icon",
            href: "/logo192.png"
          },
          {
            rel: "manifest",
            href: "/manifest.json"
          }
        ]}
        additionalMetaTags={[
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          },
          {
            name: "utf-8",
            content: ""
          },
          {
            name: "fb:app_id",
            content: "814303952618405"
          },
          {
            name: "al:android:package",
            content: "vn.qsoft.jlptup"
          },
          {
            name: "al:android:url",
            content: "https://play.google.com/store/apps/details?id=vn.qsoft.jlptup"
          },
          {
            name: "al:android:app_name",
            content: "JlptUp"
          },
          {
            name: "al:ios:url",
            content: "https://apps.apple.com/vn/app/id1579917080#?platform=iphone"
          },
          {
            name: "al:ios:app_store_id",
            content: "1579917080"
          },
          {
            name: "al:ios:app_name",
            content: "JlptUp"
          }
        ]}
        title={pageProps?.title || "Jlpt Up - Học, Thi Đấu online"}
        description={pageProps?.description || appMetaDescription}
        canonical={`${domainUrl}${router?.asPath}`}
        openGraph={{
          url: `${domainUrl}${router?.asPath.slice(1, router?.asPath?.length)}`,
          title: pageProps?.title || "Jlpt Up - Học, Thi Đấu online",
          description: pageProps?.description || appMetaDescription,
          images: [
            {
              url: `${domainUrl}assets/images/graph_chinhxac.png`,
              secureUrl: `${domainUrl}assets/images/graph_chinhxac.png`,
              width: 1200,
              height: 630,
              alt: "JlptUp"
            }
          ]
        }}
      />
      <main
        className="relative flex-grow sm:overflow-y-auto"
        style={{
          minHeight: "-webkit-fill-available",
          WebkitOverflowScrolling: "touch"
        }}
      >
        <DefaultLayout>{children}</DefaultLayout>
      </main>
    </>
  );
};

export default Layout;
