/* eslint-disable react/jsx-filename-extension */
import { Box, Divider, Link } from "@mui/material";
// import { Link } from 'react-router-dom';
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { ExamsCard } from "./Elements";
import { SHOW_MAXIMUM_EXAMS } from "../utils/constant";

function Exams({ examsList }) {
  const examsListRender = useMemo(() => {
    return examsList?.slice(0, SHOW_MAXIMUM_EXAMS);
  }, [examsList]);

  return (
    <Box className="box-cover-left md:w-[250px] lg:w-[298px]">
      <Box
        style={{
          display: "flex"
        }}
        className="title-sub"
      >
        <FormattedMessage id="Profile.exerciseDone" />
      </Box>
      <Box style={{ marginTop: 12, width: "100%" }} display="flex" flexDirection="column">
        {examsListRender.map((ele, index) => (
          <Link
            key={`${index}`}
            style={{ textDecoration: "unset", color: "black" }}
            href={ele.direct_link.replace("/lop/", "/")}
          >
            <ExamsCard ele={ele} />
            {examsListRender.length - 1 !== index && <Divider className="my-3" />}
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default Exams;
