/* eslint-disable no-else-return */
import * as React from "react";
import {
  AddFriendIcon,
  FollowingIcon,
  FriendIcon,
  PendingConfirmIcon,
  PendingYourConfirmIcon
} from "../../../../public/assets/icons/RelationshipFriend";

export type TListRelationshipMapping = {
  id: string;
  className: string;
  icon: any;
}[];

export const listRelationshipMapping: TListRelationshipMapping = [
  {
    id: "friend.relationship.friend",
    className: "bg-gray1",
    icon: <FriendIcon />
  },
  {
    id: "friend.relationship.add",
    className: "bg-primary",
    icon: <AddFriendIcon />
  },
  {
    id: "friend.relationship.following",
    className: "bg-gray1",
    icon: <FollowingIcon />
  },
  {
    id: "friend.relationship.pendingConfirm",
    className: "bg-gray1",
    icon: <PendingConfirmIcon />
  },
  {
    id: "friend.relationship.pendingYourConfirm",
    className: "bg-primary",
    icon: <PendingYourConfirmIcon />
  }
];

export const ERROR_CODE = {
  NOT_ACCEPTABLE_INVITE_ONLY_ONE: "EAIONE",
  NOT_ACCEPTABLE_HAS_BEEN_FRIENDS: "ENAHBF",
  NOT_ACCEPTABLE_RECEIVED_INVITATION: "ENARIN",
  NOT_FOUND_MEMBER: "ENFMEM",
  NOT_ACCEPTABLE_DELETE_INVITE_NOT_EXIST: "ENADNE",
  NOT_ACCEPTABLE_DELETE_INVITE_IS_ACCEPTED: "ENADIA",
  NOT_ACCEPTABLE_FOLLOW_ONLY_ONE: "EAFONE",
  NOT_ACCEPTABLE_FOLLOW_YOUR_SELF: "ENAFYS",
  NOT_ACCEPTABLE_UNFOLLOW_NOT_EXIST: "ENAUFS",
  NOT_ACCEPTABLE_NO_INVITATION: "ENANIN",
  NOT_ACCEPTABLE_REMOVE_FRIEND_NOT_FRIEND: "ENAREF",
  NOT_ACCEPTABLE_ON_OFF_NOTIFICATION_NOT_FOLLOWER: "EOONNF",
  NOT_ACCEPTABLE_INVITATION_BEFORE: "ENAEIN",
  CODE_NOT_ACCEPTABLE_FOLLOW_ONLY_ONE: "EAFONE"
};

export const LIST_ERROR_CODE_UPDATE_SUGGEST_FRIEND = [
  ERROR_CODE.NOT_ACCEPTABLE_DELETE_INVITE_NOT_EXIST,
  ERROR_CODE.NOT_ACCEPTABLE_DELETE_INVITE_IS_ACCEPTED,
  ERROR_CODE.NOT_ACCEPTABLE_UNFOLLOW_NOT_EXIST,
  ERROR_CODE.NOT_FOUND_MEMBER,
  ERROR_CODE.NOT_ACCEPTABLE_INVITE_ONLY_ONE,
  ERROR_CODE.NOT_ACCEPTABLE_HAS_BEEN_FRIENDS,
  ERROR_CODE.NOT_ACCEPTABLE_RECEIVED_INVITATION,
  ERROR_CODE.NOT_ACCEPTABLE_REMOVE_FRIEND_NOT_FRIEND,
  ERROR_CODE.NOT_ACCEPTABLE_NO_INVITATION,
  ERROR_CODE.NOT_ACCEPTABLE_INVITATION_BEFORE
];
export const LIST_ERROR_CODE_UPDATE_SEARCH_FRIEND = [
  ERROR_CODE.NOT_ACCEPTABLE_DELETE_INVITE_NOT_EXIST,
  ERROR_CODE.NOT_ACCEPTABLE_DELETE_INVITE_IS_ACCEPTED,
  ERROR_CODE.NOT_ACCEPTABLE_UNFOLLOW_NOT_EXIST,
  ERROR_CODE.NOT_FOUND_MEMBER,
  ERROR_CODE.NOT_ACCEPTABLE_INVITE_ONLY_ONE,
  ERROR_CODE.NOT_ACCEPTABLE_HAS_BEEN_FRIENDS,
  ERROR_CODE.NOT_ACCEPTABLE_RECEIVED_INVITATION,
  ERROR_CODE.NOT_ACCEPTABLE_REMOVE_FRIEND_NOT_FRIEND,
  ERROR_CODE.NOT_ACCEPTABLE_NO_INVITATION,
  ERROR_CODE.NOT_ACCEPTABLE_INVITATION_BEFORE
];
export const LIST_ERROR_CODE_UPDATE_INVITATION_FRIEND = [
  ERROR_CODE.NOT_ACCEPTABLE_NO_INVITATION
];
export const LIST_ERROR_CODE_UPDATE_ACCOUNT_INFO = [
  ERROR_CODE.NOT_ACCEPTABLE_DELETE_INVITE_NOT_EXIST,
  ERROR_CODE.NOT_ACCEPTABLE_DELETE_INVITE_IS_ACCEPTED,
  ERROR_CODE.NOT_ACCEPTABLE_UNFOLLOW_NOT_EXIST,
  ERROR_CODE.NOT_FOUND_MEMBER,
  ERROR_CODE.NOT_ACCEPTABLE_INVITE_ONLY_ONE,
  ERROR_CODE.NOT_ACCEPTABLE_HAS_BEEN_FRIENDS,
  ERROR_CODE.NOT_ACCEPTABLE_RECEIVED_INVITATION,
  ERROR_CODE.NOT_ACCEPTABLE_REMOVE_FRIEND_NOT_FRIEND,
  ERROR_CODE.NOT_ACCEPTABLE_NO_INVITATION,
  ERROR_CODE.NOT_ACCEPTABLE_INVITATION_BEFORE,
  ERROR_CODE.CODE_NOT_ACCEPTABLE_FOLLOW_ONLY_ONE
];
export const LIST_ERROR_CODE_UPDATE_LIST_FRIEND = [
  ERROR_CODE.NOT_ACCEPTABLE_UNFOLLOW_NOT_EXIST,
  ERROR_CODE.NOT_FOUND_MEMBER,
  ERROR_CODE.NOT_ACCEPTABLE_REMOVE_FRIEND_NOT_FRIEND
];
export const LIST_ERROR_UPDATE_UI_LEFT_FRIEND = [
  ERROR_CODE.CODE_NOT_ACCEPTABLE_FOLLOW_ONLY_ONE,
  ERROR_CODE.NOT_ACCEPTABLE_DELETE_INVITE_IS_ACCEPTED
];

export const LIMIT_SEARCH_FRIEND = 20;
export const LIST_ACTION_CHECK_UPDATE_UI = [
  "DELETE_FOLLOW_SUCCESS",
  "REMOVE_FRIEND_SUCCESS",
  "DECLINE_FRIEND_REQUEST_SUCCESS",
  "INVITE_FRIEND_SUCCESS",
  "DELETE_INVITATION_SUCCESS",
  "ACCEPT_FRIEND_SUCCESS",
  "FOLLOW_FRIEND_SUCCESS"
];

export const getRelationshipButtonId = (relationship) => {
  if (relationship?.is_friend) {
    return "friend.relationship.friend";
  } else if (relationship?.is_following) {
    return "friend.relationship.following";
  } else if (relationship?.pending_your_confirm) {
    return "friend.relationship.pendingYourConfirm";
  } else if (relationship?.pending_confirm) {
    return "friend.relationship.pendingConfirm";
  } else {
    return "friend.relationship.add";
  }
};
export const getRelationshipButtonIdFriend = (relationship) => {
  if (relationship?.is_friend) {
    return "friend.relationship.friend";
  } else if (relationship?.pending_your_confirm) {
    return "friend.relationship.pendingYourConfirm";
  } else if (relationship?.pending_confirm) {
    return "friend.relationship.pendingConfirm";
  }
  return "friend.relationship.add";
};
export const getRelationshipButtonIdFollow = (relationship) => {
  if (relationship?.is_following) {
    return "friend.relationship.following";
  }
  return "friend.relationship.follow";
};


export const INVITATION_PAGE_QUERY = "loi-moi-ket-ban";
export const SEARCH_FRIEND_PAGE_QUERY = "ban-be-quanh-day";
export const FRIEND_PAGE_QUERY = "ban-be";
export const FOLLOW_PAGE_QUERY = "theo-doi";

export const MenuPageFriend = [
  {
    id: 1,
    intlId: "friend.invitation",
    query: INVITATION_PAGE_QUERY
  },
  {
    id: 2,
    intlId: "friend.suggestedFriend",
    query: SEARCH_FRIEND_PAGE_QUERY
  },
  {
    id: 3,
    intlId: "friend.friend",
    query: FRIEND_PAGE_QUERY
  },
  {
    id: 4,
    intlId: "friend.follow",
    query: FOLLOW_PAGE_QUERY
  }
];