import { MenuItem, Select, useTheme } from "@mui/material";
import moment from "moment";
import { useRouter } from "next/router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TTheme } from "../../../../utils/configs/setupTheme";
import { genSignature, getNonce } from "../../../../utils/helpers/helpers";
import { arenaRoomActions } from "../../../arena/redux/actions";
import { commonActions } from "../../../common/redux/actions";
import { BASE_LANGUAGES } from "../../../common/utils/constant";
import { TCommonState } from "../../../common/utils/type";
import { accountActions } from "../../../account/redux/actions";
import { LIST_CLASSES } from "../constants";

export default function SelectGrade() {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    userLanguage,
    userInfos,
    grade: userClass
  } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);

  const handleUpdateProfile = (gradeVal) => {
    const dataUpdateProfilePre: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      grade_slug: gradeVal
    };
    const dataUpdateProfile = {
      ...dataUpdateProfilePre,
      signature: genSignature(dataUpdateProfilePre)
    };
    dispatch(
      accountActions.handleUpdateInfoAccount(dataUpdateProfile, () => dispatch(accountActions.handleClearNotify()))
    );
  };

  const handleUpdateGrade = (event: any) => {
    const gradeChoose: string = event?.target?.value;
    const dataPost = {
      grade: gradeChoose,
      user_language:
        userLanguage || BASE_LANGUAGES.includes(navigator.language?.split("-")[0])
          ? navigator.language?.split("-")[0]
          : BASE_LANGUAGES[0]
    };
    if (router?.pathname !== "/") {
      dispatch(commonActions.handleGetTop(dataPost));
    } else {
      router.replace(router.asPath);
    }
    dispatch(commonActions.handleChooseGrade(gradeChoose));
    dispatch(arenaRoomActions?.handleChooseSubjectFilter([]));
    if (
      router?.query.topicId ||
      router?.query.subjectsId ||
      (router?.asPath?.includes("/thi-online") && router?.asPath?.includes("/de-thi"))
    ) {
      // practice, lesson, exam, subject -> home
      router.push("/");
    } else if (!!router?.query.roomHid && router?.asPath?.includes("/thi-online")) {
      // detail, analysis room -> list room
      router.push(`/${gradeChoose}/thi-online?tab=phong-thi`);
    } else if (router.pathname === "/hoi-dap/[postId]") {
      router.push("/hoi-dap");
    } else if (
      router?.asPath === "/" ||
      router?.asPath?.includes("/thong-ke") ||
      (router?.asPath?.includes("/thi-online") && router.query?.tab === "xep-hang")
    ) {
      // pass
    } else {
      setTimeout(() => {
        router.push(`${router?.asPath?.replace(/\/cap-do-n[12345]/, `/${gradeChoose}`)}`);
      }, 500);
      // router.reload();
    }
    if (userInfos?.member) {
      handleUpdateProfile(gradeChoose);
    }
  };
  const theme: TTheme = useTheme();
  return (
    <Select
      variant="outlined"
      sx={{
        width: 73,
        height: 32,
        border: "2px solid #FFFFFF",
        borderRadius: 16,
        color: "white",
        "& .MuiSvgIcon-root": {
          color: "white"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none"
        },
        "& fieldset": {
          border: "none"
        }
      }}
      renderValue={(selected) => (
        <span className="text-white">{LIST_CLASSES.find((e) => e.slug === selected)?.name}</span>
      )}
      value={userClass}
      onChange={handleUpdateGrade}
    >
      {LIST_CLASSES.map((item, idx) => (
        <MenuItem
          key={item.slug}
          value={item.slug}
          className="w-[80px] py-1 flex justify-center hover:bg-primary50 focus:bg-primary100"
          sx={{
            borderBottom: idx !== LIST_CLASSES.length - 1 ? `1px solid ${theme.palette.primary.gray}` : "none"
          }}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
}
