/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton, styled } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { getAuth, signOut } from "firebase/auth";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect } from "react";
import TranslateIcon from "@mui/icons-material/Translate";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector, useStore } from "react-redux";
import Popup from "reactjs-popup";
import { setCookie } from "cookies-next";
import { useRouter } from "next/router";
import { commonActions } from "../../common/redux/actions";
import { signOutApi } from "../../auth/utils/authAPI";
import IconCopy from "../../../../public/assets/icons/IconCopy";
import IconDevice from "../../../../public/assets/icons/IconDevice";
import IconNoti from "../../../../public/assets/icons/IconNoti";
import IconTrash from "../../../../public/assets/icons/IconTrash";
import SignOut2 from "../../../../public/assets/icons/SignOut2";
import AlertDialog from "../../common/dialog/AlertDialog";
import { genSignature, getNonce, isEmpty } from "../../../utils/helpers/helpers";
import { accountActions } from "../redux/actions";
import { accountServices } from "../redux/services";
import { toastSuccess } from "../../common/components/reactToastify";
import CXModal from "../../common/components/CXModal";
import { TCommonState } from "../../common/utils/type";
import { listLanguage } from "../utils/constant";
import { LIST_LANGUAGE } from "../../Default/layout/constants";
import { friendActions } from "../../friends/redux/actions";

const BlueSwitch = styled(Switch)(() => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#D82332",
    // '&:hover': {
    //   backgroundColor: '#1A73E8',
    // },
    marginRight: "0"
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#FFCCD3"
  }
}));

export default function SetupDialog({ setOpen, open, setOpenAlertDialog }: any) {
  const intl = useIntl();
  const dataUserInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew).userInfos;
  const isNotLogin = isEmpty(dataUserInfos?.member);
  const dispatch = useDispatch();
  const store = useStore();
  const router = useRouter();
  const [checked, setChecked] = React.useState(true);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [openPopupChooseLanguage, setOpenPopupChooseLanguage] = React.useState(false);
  const { userLanguage, listLanguages } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  // TODO get notification flag to set checked
  const handleChange = () => {
    setChecked(!checked);
    const dataConfigPre = {
      flag: checked ? 0 : 1,
      timestamp: moment().unix(),
      nonce: getNonce()
    };
    const dataConfig = {
      ...dataConfigPre,
      signature: genSignature(dataConfigPre)
    };
    dispatch(accountActions.handleUpdateConfigNotifications(dataConfig));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const dataPostPre = {
        timestamp: moment().unix(),
        nonce: getNonce(),
        uid: dataUserInfos?.member?.pub_id
      };
      const dataPost = {
        ...dataPostPre,
        signature: genSignature(dataPostPre)
      };
      const response = await accountServices.deleteAccount(dataPost);
      handleClose();
      if (response?.data) {
        dispatch(commonActions.addUserInfos({ ...dataUserInfos, member: null }));
        dispatch(commonActions.addFireBaseInfos({}));
        dispatch(commonActions.handleSaveAccessToken(""));
        dispatch(commonActions.handleSaveIdToken(""));
        dispatch(commonActions.handleSaveProviderId(""));
        if (typeof window !== "undefined") {
          window.location.href = "/";
        }
      }
    } catch (error) {
      setOpenAlertDialog(error);
    }
  };
  useEffect(() => {
    if (listLanguages?.length === 0) {
      dispatch(commonActions.handleGetLanguageOptions());
    }
  }, [listLanguages]);
  const handleChooseLanguage = (code) => {
    dispatch(commonActions?.handleOpenModalChooseLanguage(false));
    handleClose();
    dispatch(commonActions?.handleSaveUserLanguage(code));
    const data = {
      user_language: code,
      timestamp: moment().unix(),
      nonce: getNonce()
    };
    const dataPost = {
      ...data,
      signature: genSignature(data)
    };
    dispatch(commonActions?.handleUpdateLanguageUser(dataPost));
    dispatch(commonActions.handleGetUserInfo());
  };

  const logout = () => {
    // dispatch(commonActions.handleSaveAccessToken(token));
    dispatch(friendActions.handleCleanListFriend());
    dispatch(commonActions.handleLogOut());
  };

  const actionsSignOut = async () => {
    const paramsSignatureSignOut = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      uid: dataUserInfos?.member?.pub_id
    };
    const params = {
      ...paramsSignatureSignOut,
      signature: genSignature(paramsSignatureSignOut)
    };
    const auth = getAuth();
    try {
      const response = await signOutApi(store, params);
      if (response?.data) {
        signOut(auth)
          .then(() => {
            logout();
            setCookie("accessToken", JSON.stringify(response?.data?.data?.token?.access_token));
            setCookie("userInfo", JSON.stringify(response?.data?.data));
            dispatch(commonActions.handleOpenModalLogin(false));
            dispatch(commonActions.addUserInfos(response?.data?.data));
            if (router?.asPath !== "/") {
              router.push("/");
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            dispatch(commonActions.handleOpenModalLogin(false));
            logout();
            if (router?.asPath !== "/") {
              router.push("/");
            }
            // An error happened.
          });
      }
    } catch (err) {
      dispatch({
        type: "CLEAR_TOKEN"
      });
    }
    handleClose();
    dispatch(accountActions.handleCleanAccountState());
  };

  return (
    <CXModal open={open} title={<FormattedMessage id="Profile.generalSetting" />} handleClose={handleClose}>
      <DialogContent className="blackQuiz" style={{ padding: "0 24px" }}>
        <DialogContentText className="center-col" style={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: 56,
              borderBottom: "1px solid #E5E5E5"
            }}
          >
            <Box display="flex" alignItems="center" className="blackQuiz">
              <div style={{ width: 24 }}>
                <IconNoti />
              </div>

              <Typography variant="body1" style={{ marginLeft: 8, color: "#212121", fontSize: "15px" }}>
                <FormattedMessage id="Setting.notification" />
              </Typography>
            </Box>
            <div>
              <BlueSwitch
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                checked={checked}
                color="secondary"
                className="w-[49px]"
              />
            </div>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: 56,
              borderBottom: "1px solid #E5E5E5"
            }}
          >
            <Box display="flex" alignItems="center" my={2}>
              <div
                style={{
                  width: 24
                }}
              >
                <IconDevice />
              </div>

              <Typography variant="body1" style={{ marginLeft: 8, color: "#212121", fontSize: "15px" }}>
                <FormattedMessage id="Setting.deviceCode" />
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography
                variant="body1"
                style={{
                  color: "#212121",
                  fontSize: "13px",
                  fontWeight: "500"
                }}
              >
                {dataUserInfos.device?.pub_id}
              </Typography>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(dataUserInfos?.device?.pub_id);
                  toastSuccess(intl.formatMessage({ id: "message.copy.success" }));
                }}
                style={{ padding: 0, marginLeft: "5px" }}
              >
                <IconCopy />
              </IconButton>
            </Box>
          </Box>
          <div className="flex items-center w-full gap-x-2 h-14 border-b border-b-gray justify-between">
            <div className="flex gap-x-2">
              <TranslateIcon style={{ color: "#F34D53" }} />
              <span className="text-black1 text-[15px] ">
                <FormattedMessage id="Setting.language" />
              </span>
            </div>
            <div>
              <Popup
                position="bottom right"
                trigger={
                  <button
                    type="button"
                    className="text-[13px] font-medium text-black1 flex flex-row items-center"
                    onClick={() => setOpenPopupChooseLanguage(true)}
                  >
                    <FormattedMessage id={listLanguage[userLanguage]} />
                    <span
                      style={{
                        transform: !openPopupChooseLanguage ? "rotate(-90deg)" : ""
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </span>
                  </button>
                }
                open={openPopupChooseLanguage}
                onClose={() => setOpenPopupChooseLanguage(false)}
                onOpen={() => setOpenPopupChooseLanguage(true)}
                contentStyle={{
                  zIndex: 9999
                }}
              >
                {(close: any) => (
                  <div className="flex flex-col items-start justify-start gap-2 px-2">
                    {listLanguages?.map((item) => (
                      <button
                        key={item?.id}
                        type="button"
                        className="flex items-center gap-2 justify-between w-full p-1"
                        onClick={() => {
                          close();
                          handleChooseLanguage(item?.code);
                        }}
                      >
                        <span>{LIST_LANGUAGE?.find((ele) => ele?.value === item?.code)?.icon}</span>
                        <span className="font-medium">{intl.formatMessage({ id: item?.title })}</span>
                      </button>
                    ))}
                  </div>
                )}
              </Popup>
            </div>
          </div>
          {!isNotLogin && (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 56,
                width: "100%",
                borderBottom: "1px solid #E5E5E5"
              }}
              onClick={() => {
                setLogoutDialog(true);
              }}
            >
              <Box display="flex" alignItems="center">
                <SignOut2 />
                <Typography variant="body1" style={{ marginLeft: 8, color: "#212121", fontSize: "15px" }}>
                  <FormattedMessage id="Profile.logout" />
                </Typography>
              </Box>
            </Box>
          )}
          {!isNotLogin && (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 56,
                width: "100%"
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                onClick={() => {
                  setDeleteDialog(true);
                }}
              >
                <div style={{ width: 24 }}>
                  <IconTrash />
                </div>

                <Typography variant="body1" style={{ marginLeft: 8, color: "#212121", fontSize: "15px" }}>
                  <FormattedMessage id="IDS_Delete_Account" />
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContentText>
      </DialogContent>
      {logoutDialog && (
        <AlertDialog
          open={logoutDialog}
          setOpen={setLogoutDialog}
          title={intl.formatMessage({ id: "common.confirm" })}
          contentKey="Setting.logoutDesc"
          triggerFunc={() => {
            actionsSignOut();
          }}
          buttonOkKey=""
        />
      )}
      {deleteDialog && (
        <AlertDialog
          open={deleteDialog}
          setOpen={setDeleteDialog}
          title={intl.formatMessage({ id: "common.bottombar.notification" })}
          contentKey="Setting.deleteAccountDesc"
          triggerFunc={() => {
            handleSubmit();
          }}
          buttonOkKey=""
        />
      )}
    </CXModal>
  );
}
