import { Button } from "@mui/material";
import moment from "moment";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import CXModal from "../../common/components/CXModal";
import { accountActions } from "../redux/actions";
import { TAccountState } from "../utils/types";

const ModalGiftDiamond = ({ open, handleClose, memberHid }: any) => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const intl = useIntl();
  const { infoAccount, notify } = useSelector(
    (state: { account: TAccountState }) => state.account
  );
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    if (Number(result) > 100) {
      setValue(result.replace(/^0+/, ""));
      setError(intl.formatMessage({ id: "account.gift.diamond.error" }));
    } else {
      setValue(result.replace(/^0+/, ""));
      setError("");
    }
  };
  const genRakeDiamond = (num: number) => {
    if (num <= 10) {
      return 1;
    }
    if (num > 10 && num <= 30) {
      return 2;
    }
    if (num > 30 && num <= 50) {
      return 3;
    }
    if (num > 50 && num <= 100) {
      return 5;
    }
    return null;
  };
  const handleGiftDiamond = () => {
    const params = {
      amount: Number(value),
      timestamp: moment().unix(),
      nonce: getNonce(),
      member_hid: memberHid || infoAccount?.pub_id
    };
    const dataPost = {
      ...params,
      signature: genSignature(params)
    };
    dispatch(accountActions.handleGiftDiamond(dataPost));
    handleClose();
  };
  React.useEffect(() => {
    if (notify.type === "GIFT_DIAMOND_SUCCESS") {
      setValue("");
    }
  }, [notify.type]);
  return (
    <CXModal
      title={intl.formatMessage({
        id: "account.gift.diamond.title"
      })}
      open={open}
      handleClose={handleClose}
      minHeight="40vh"
    >
      <div>
        <input
          type="text"
          onChange={handleChange}
          className={`w-full border border-gray px-2 py-2 rounded-[16px] bg-gray4  placeholder:text-[12px] text-center placeholder:text-start ${
            error.length > 0 ? "outline-red" : "outline-primary200"
          }`}
          value={value}
          placeholder={intl.formatMessage({
            id: "account.gift.diamond.placeholder"
          })}
          maxLength={3}
        />
        <div className={`text-right ${Number(value) > 0 ? "visible" : "invisible"}`}>
          {error.length > 0 ? (
            <span className="text-[12px] text-red">{error}</span>
          ) : (
            <span className="text-[12px] text-primary">
              <FormattedMessage
                id="account.gift.cutrake"
                values={{ diamond: genRakeDiamond(Number(value)) }}
              />
            </span>
          )}
        </div>
        <div className="flex flex-col text-gray400 text-[12px] my-2">
          <span>
            <FormattedMessage id="account.gift.content.one" />
          </span>
          <span>
            <FormattedMessage id="account.gift.content.two" />
          </span>
          <span>
            <FormattedMessage id="account.gift.content.three" />
          </span>
          <span>
            <FormattedMessage id="account.gift.content.four" />
          </span>
        </div>
        <div className="flex justify-center gap-8 flex-row items-center my-4 font-medium">
          <button
            type="button"
            className="text-primary text-[13px]"
            onClick={handleClose}
          >
            <FormattedMessage id="common.cancel" />
          </button>
          <Button
            className={`text-white rounded-[8px] normal-case font-medium text-[13px] w-[149px] h-[44px] ${
              value.length === 0 ? "bg-primary100" : "bg-primary"
            }`}
            disabled={value.length === 0}
            onClick={handleGiftDiamond}
          >
            <FormattedMessage id="common.notification.confirm" />
          </Button>
        </div>
      </div>
    </CXModal>
  );
};
export default ModalGiftDiamond;
