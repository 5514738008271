/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import { Avatar, Badge, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { setCookie } from "cookies-next";
import { getAuth, signOut } from "firebase/auth";
import moment from "moment";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector, useStore } from "react-redux";
import IconAnonymous from "../../../../../public/assets/icons/IconAnonymous";
import IconNotification from "../../../../../public/assets/icons/IconNotification";
import IconSearch from "../../../../../public/assets/icons/IconSearch";
import LargeLogo from "../../../../../public/assets/icons/LargeLogo";
import Play from "../../../../../public/assets/icons/Play";
import SettingsIcon from "../../../../../public/assets/icons/SettingsIcon";
import SignOut from "../../../../../public/assets/icons/SignOut";
import TransHistory from "../../../../../public/assets/icons/TransHistory";
import UserCircleGear from "../../../../../public/assets/icons/UserCircleGear";
import UserCircleMenu from "../../../../../public/assets/icons/UserCircleMenu";
import { ROUTES } from "../../../../utils/configs/router";
import { TTheme } from "../../../../utils/configs/setupTheme";
import { WHITE } from "../../../../utils/configs/theme/colors";
import { LIST_BLOCK_URL, LIST_NON_BLOCK_URL } from "../../../../utils/constants/constants";
import { genSignature, getNonce, isEmpty } from "../../../../utils/helpers/helpers";
import SetupDialog from "../../../account/components/SetupDialog";
import { accountActions } from "../../../account/redux/actions";
import { signOutApi } from "../../../auth/utils/authAPI";
import NotificationDropdown from "../../../common/Notification/NotificationDropDown";
import { toastSuccess } from "../../../common/components/reactToastify";
import {
  LOCATION_PERMISSION_DENIED,
  LOCATION_PERMISSION_UNDEFINE,
  getQueryFilterListPost
} from "../../../common/utils/constant";
import AlertDialog from "../../../common/dialog/AlertDialog";
import { commonActions } from "../../../common/redux/actions";
import { TCommonState } from "../../../common/utils/type";
import { friendActions } from "../../../friends/redux/actions";
import MenuSuggest from "../components/MenuSuggest";
import SelectGrade from "../components/SelectGrade";
import { LIST_LANGUAGE, LIST_MENU } from "../constants";

// declare const window: any;
const HOME_PAGE = "/";

const MenuInfo = ({ anchor, id, transformOrigin, open, onClose, className, children }) => {
  return (
    <Menu
      anchorEl={anchor}
      id={id}
      keepMounted
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      className={className}
      style={{
        top: "-3px"
        // left: "2px"
      }}
    >
      {children}
    </Menu>
  );
};

const MenuItemInfo = ({ onClick, children }) => {
  return <MenuItem onClick={onClick}>{children}</MenuItem>;
};

function DefaultHeader() {
  const store = useStore();
  const router = useRouter();
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme: TTheme = useTheme();
  const dataUserInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const isNotLogin = isEmpty(dataUserInfos?.member);
  const dataFireBaseInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.fireBaseInfos);
  const userClass = useSelector((state: { commonNew: any }) => state.commonNew.grade);
  const { action, notify, directUrl, userLanguage } = useSelector(
    (state: { commonNew: TCommonState }) => state.commonNew
  );
  const matches1285 = useMediaQuery("(min-width:1285px)");
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isFocus, setFocus] = React.useState(false);
  const [logoutDialog, setLogoutDialog] = React.useState(false);
  const [nextUrl, setNextUrl] = React.useState("");
  const [openSetupDialog, setOpenSetupDialog] = React.useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event: any) => {
    event.preventDefault();
    if (isEmpty(dataUserInfos?.member)) {
      dispatch(commonActions.handleOpenModalLogin(true));
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const logout = () => {
    dispatch(friendActions.handleCleanListFriend());
    dispatch(commonActions.handleLogOut());
  };

  const actionsSignOut = async () => {
    const paramsSignatureSignOut = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      uid: dataUserInfos?.member?.pub_id
    };
    const params = {
      ...paramsSignatureSignOut,
      signature: genSignature(paramsSignatureSignOut)
    };
    const auth = getAuth();
    try {
      const response = await signOutApi(store, params);
      if (response?.data) {
        signOut(auth)
          .then(() => {
            logout();
            setCookie("accessToken", JSON.stringify(response?.data?.data?.token?.access_token));
            setCookie("userInfo", JSON.stringify(response?.data?.data));
            dispatch(commonActions.handleOpenModalLogin(false));
            dispatch(commonActions.addUserInfos(response?.data?.data));
            handleMenuClose();
            if (router?.asPath !== "/") {
              router.push("/");
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            dispatch(commonActions.handleOpenModalLogin(false));
            handleMenuClose();
            logout();
            if (router?.asPath !== "/") {
              router.push("/");
            }
            // An error happened.
          });
      }
    } catch (err) {
      dispatch({
        type: "CLEAR_TOKEN"
      });
    }
    dispatch(accountActions.handleCleanAccountState());
  };

  const menuId = "primary-search-account-menu";
  const checkPageActive = (page: any) => {
    if (page.key === "common.bottombar.arena") {
      return router?.pathname.includes("/thi-online");
    } else if (page.key === "Question_and_Answer") {
      return router?.pathname?.includes("/hoi-dap");
    } else if (page.key === "common.bottombar.gift") {
      return router?.pathname?.includes("/qua-tang");
    }
    return router?.pathname === page.link;
  };

  const queryFilterListPost = getQueryFilterListPost(store);

  useEffect(() => {
    if (action?.type === "LOGIN_SUCCESS") {
      if (nextUrl) {
        router.push(nextUrl);
      } else if (dataUserInfos?.member && router?.pathname !== "/" && !LIST_NON_BLOCK_URL.includes(router?.pathname)) {
        router.reload();
      } else if (router?.pathname === ROUTES.subjects.path) {
        if (isEmpty(directUrl)) {
          dispatch(commonActions.handleSaveLocationPermission(LOCATION_PERMISSION_DENIED));
          router
            ?.push("/")
            .then(() => dispatch(commonActions.handleSaveLocationPermission(LOCATION_PERMISSION_UNDEFINE)));
        } else {
          // chưa đăng nhập và click vào topic => đăng nhập, direct sang bài quiz
          dispatch(commonActions.handleDirectUrl(""));
        }
      }
      if (notify?.message && router?.pathname !== ROUTES.subjects.path) {
        toastSuccess(notify?.message);
        dispatch(commonActions.deleteNotify());
      }
    }
  }, [action, dataUserInfos]);

  useEffect(() => {
    if (dataUserInfos.member) {
      dispatch(commonActions.handleSaveUserLanguage(dataUserInfos.member.user_language));
      if (router.pathname === "/hoi-dap/[postId]") {
        router.replace(router.asPath);
      }
    }
  }, [dataUserInfos]);

  useEffect(() => {
    if (!isEmpty(dataFireBaseInfos)) {
      dispatch(commonActions.handleOpenModalLogin(false));
      if (!!nextUrl && nextUrl !== HOME_PAGE) {
        router.push(nextUrl);
      }
    }
  }, [dataFireBaseInfos]);

  return (
    <AppBar position="fixed" style={{ boxShadow: "unset", background: "white" }}>
      <Box
        style={{ height: 56, boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)" }}
        alignItems="center"
        className="md:flex md:px-6 lg:pl-[32px] lg:pr-[40px] md:justify-between bg-primary lg:justify-between w-full md:gap-[76px] xl:gap-[64px] 2xl:gap-[70px]"
      >
        <Box className="flex items-center ml-[1px] gap-x-6">
          <button type="button" onClick={() => router.push("/")}>
            <LargeLogo />
          </button>
          <SelectGrade />
        </Box>
        <Box width="100%" alignItems="center" className="md:flex justify-between items-center shrink">
          {LIST_MENU.map((page, index) => (
            <button
              type="button"
              key={index}
              onClick={() => {
                if (isNotLogin && LIST_BLOCK_URL.some((v) => page.link.includes(v))) {
                  if (page.key === "common.bottombar.arena") {
                    setNextUrl(`/${userClass}/thi-online?tab=phong-thi`);
                  } else {
                    dispatch(commonActions.handleOpenModalLogin(true));
                    setNextUrl(page.link);
                  }
                } else if (index !== 4) {
                  if (page.key === "common.bottombar.arena") {
                    router.push(`/${userClass}/thi-online?tab=phong-thi`);
                  } else if (page.key === "Question_and_Answer" && queryFilterListPost?.is_filter !== 0) {
                    router.push({
                      pathname: "/hoi-dap",
                      query: { ...queryFilterListPost, tab: "thao-luan", category: "all" }
                    });
                  } else {
                    router.push(page.link);
                  }
                } else {
                  // #gift
                  router.push(page.link);
                }
              }}
              style={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.primary.gray,
                fontWeight: "400"
              }}
              className={`${checkPageActive(page) ? "text-white my-2 font-[600]" : "my-2"}  text-[15px]`}
            >
              {/* eslint-disable-next-line  */}
              {matches1285 ? (
                <>
                  <span style={{ marginRight: "5px" }}>{checkPageActive(page) ? page?.iconActive : page?.icon}</span>
                  <FormattedMessage id={page?.key} />
                </>
              ) : checkPageActive(page) ? (
                page?.iconActive
              ) : (
                page?.icon
              )}
            </button>
          ))}
        </Box>
        <Box className="flex flex-row items-center">
          {matches1100 ? (
            <MenuSuggest />
          ) : (
            <button
              type="button"
              onClick={() => router.push(ROUTES.timkiem.path)}
              disabled={router?.pathname === ROUTES.timkiem.path}
            >
              <IconSearch stroke="white" />
            </button>
          )}
          <div className="flex items-center">
            <Box
              style={{
                color: WHITE,
                width: 20,
                marginLeft: "20px"
                // marginRight: "8px"
              }}
              onClick={() => {
                if (!isEmpty(dataUserInfos?.member)) {
                  setFocus(!isFocus);
                } else {
                  dispatch(commonActions.handleOpenModalLogin(true));
                }
              }}
              mx={1}
            >
              <Badge
                // badgeContent={<Typography variant="caption" style={{ color: WHITE }}></Typography>}
                color="secondary"
              >
                {/* <NotificationsIcon /> */}
                <IconNotification className="cursor-pointer" />
                {isFocus && <NotificationDropdown setFocus={setFocus} isFocus={isFocus} />}
              </Badge>
            </Box>
            <IconButton
              disableFocusRipple
              disableRipple
              size="large"
              edge="end"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              style={{ color: "WHITE " }}
              className=" lg:hidden md:block"
            >
              <Avatar alt={dataUserInfos.member?.name} src={dataUserInfos.member?.avatar} />
            </IconButton>

            {matches1285 && (
              <IconButton
                disableFocusRipple
                disableRipple
                size="large"
                edge="end"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                style={{ color: "WHITE " }}
              >
                {dataUserInfos?.member ? (
                  <Avatar alt={dataUserInfos.member?.name} src={dataUserInfos.member?.avatar} />
                ) : (
                  <IconAnonymous width="40" height="40" />
                )}
                <span
                  style={{
                    color: theme.palette.primary.black1,
                    fontSize: "13px"
                  }}
                  className={`text-white line-clamp-1 ${
                    dataUserInfos?.member ? "truncate max-w-[130px] text-left mr-[11px] pl-2" : "pl-2 w-[75px]"
                  }`}
                >
                  {dataUserInfos.member?.display_name ? (
                    dataUserInfos.member?.display_name
                  ) : dataUserInfos?.member?.name ? (
                    dataUserInfos?.member?.name
                  ) : (
                    <FormattedMessage id="Profile.guestTitle" />
                  )}
                </span>
                {!isEmpty(dataUserInfos.member?.display_name) && <Play fill="white" />}
              </IconButton>
            )}
            <button
              type="button"
              className="ml-4"
              onClick={() => dispatch(commonActions?.handleOpenModalChooseLanguage(true))}
            >
              {LIST_LANGUAGE.find((item) => item?.value === userLanguage)?.iconSmall || LIST_LANGUAGE[0].iconSmall}
            </button>
          </div>
        </Box>
      </Box>
      {isMenuOpen && (
        <MenuInfo
          anchor={anchorEl}
          id={menuId}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          className="text-black w-full"
        >
          <Link href={ROUTES.taikhoan.edit.path}>
            <MenuItemInfo
              onClick={() => {
                router.push(`${ROUTES.taikhoan.edit.path}`);
              }}
            >
              <Box display="flex" alignItems="center" className="text-black">
                <UserCircleMenu />
                <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                  <FormattedMessage id="User_info" />
                </Typography>
              </Box>
            </MenuItemInfo>
          </Link>
          <MenuItemInfo
            onClick={() => {
              router.push(`${ROUTES.taikhoan.path}`);
            }}
          >
            <Box display="flex" alignItems="center" className="text-black">
              <UserCircleGear />
              <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                <FormattedMessage id="Account_management" />
              </Typography>
            </Box>
          </MenuItemInfo>
          <MenuItemInfo
            onClick={() => {
              router.push(`${ROUTES.taikhoan.diamondhistory.path}`);
            }}
          >
            <Box display="flex" alignItems="center" className="text-black">
              <TransHistory />
              <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                <FormattedMessage id="Trans_history" />
              </Typography>
            </Box>
          </MenuItemInfo>
          <MenuItemInfo
            onClick={() => {
              setOpenSetupDialog(true);
              handleMenuClose();
            }}
          >
            <Box display="flex" alignItems="center" className="text-black">
              <SettingsIcon />
              <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                <FormattedMessage id="Setting.title" />
              </Typography>
            </Box>
          </MenuItemInfo>
          {!isNotLogin && (
            <MenuItemInfo
              onClick={() => {
                setLogoutDialog(true);
              }}
            >
              <Box display="flex" alignItems="center" className="text-black">
                <SignOut />
                <Typography variant="subtitle2" style={{ marginLeft: 12 }}>
                  <FormattedMessage id="Profile.logout" />
                </Typography>
              </Box>
            </MenuItemInfo>
          )}
        </MenuInfo>
      )}
      {logoutDialog && (
        <AlertDialog
          open={logoutDialog}
          setOpen={setLogoutDialog}
          title={intl.formatMessage({ id: "common.notification.confirm" })}
          contentKey="Setting.logoutDesc"
          triggerFunc={() => {
            actionsSignOut();
          }}
          isDisabledHandleClose={undefined}
          buttonOkKey={undefined}
        />
      )}
      {openSetupDialog && (
        <SetupDialog
          open={openSetupDialog}
          setOpen={setOpenSetupDialog}
          setOpenAlertDialog={() => dispatch(accountActions.handleClearNotify())}
        />
      )}
    </AppBar>
  );
}

export default React.memo(DefaultHeader);
