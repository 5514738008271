/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { FormattedMessage } from "react-intl";

const ReadMore = ({ children }) => {
  const text = children;
  return (
    <div className="inline w-full">
      <span
        className="sm:line-clamp-3 md:line-clamp-none md:inline whitespace-pre-wrap"
        dangerouslySetInnerHTML={{ __html: text.length > 300 ? text.slice(0, 300) : text }}
      >
        {/* {text.length > 300 ? text.slice(0, 300) : text} */}
      </span>
      {text.length > 300 && (
        <span className="text-black1 cursor-pointer hover:underline ml-1 ">
          <FormattedMessage id="ask.post.readmore" />
        </span>
      )}
    </div>
  );
};
export default ReadMore;
