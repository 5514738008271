/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Box, useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import lodash from "lodash";
import SearchInput from "../../common/components/SearchInput";
import { friendActions } from "../redux/actions";
import {
  FOLLOW_PAGE_QUERY,
  FRIEND_PAGE_QUERY,
  INVITATION_PAGE_QUERY,
  LIMIT_SEARCH_FRIEND,
  MenuPageFriend,
  SEARCH_FRIEND_PAGE_QUERY
} from "../utils/constant";
import ArrowRight from "../../../../public/assets/icons/ArrowRight";
import Breadcrumb from "../../common/components/BreadCrumb";
import ListFriendView from "./ListFriendView";
import InvitationView from "./InvitationView";
import SearchFriendView from "./SearchFriendView";
import { TFriendState } from "../utils/types";
import { LOCATION_PERMISSION_ACCEPTED, LOCATION_PERMISSION_DENIED, removeAccents } from "../../common/utils/constant";
import AlertDialog from "../../common/dialog/AlertDialog";
import { TCommonState } from "../../common/utils/type";
import { commonActions } from "../../common/redux/actions";

export default function FriendLeftSide({ page, hiddenSearch }: any) {
  const intl = useIntl();
  const router = useRouter();
  const dispatch = useDispatch();
  const [listSearchFriendLocal, setListSearchFriendLocal] = useState<any[]>([]);
  const [isShowDefaultData, setIsShowDefaultData] = useState(true);
  const [openDialogLocation, setOpenDialogLocation] = useState(false);
  const matches768 = useMediaQuery("(min-width:768px)");
  const { locationPermission } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const { searchKey, listFriend, listInvitation, listFriendSearch } = useSelector(
    (state: { friend: TFriendState }) => state.friend
  );
  const handleSearchLocation = lodash.debounce(() => {
    if (locationPermission === LOCATION_PERMISSION_ACCEPTED) {
      dispatch(friendActions.handleSetIsLoading(true));
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (location) => {
            if (location) {
              setIsShowDefaultData(false);
              const params = {
                long: location?.coords?.longitude,
                lat: location?.coords?.latitude,
                limit: LIMIT_SEARCH_FRIEND
              };
              dispatch(friendActions.handleSearchFriend(params));
            }
          },
          () => {
            dispatch(friendActions.handleSetIsLoading(false));
          }
        );
      }
    } else {
      setOpenDialogLocation(true);
    }
  }, 500);
  const handleSearchFriendAPI = (key: string) => {
    if (key.trim().length === 0) return;
    const params = {
      search_key: key.trim(),
      limit: LIMIT_SEARCH_FRIEND
    };
    dispatch(friendActions.handleSearchFriend(params));
  };
  const searchFriendFunc = (list) => {
    const searchKeyTrim = searchKey?.trim();
    if (list?.find((e) => e.member_hid === searchKeyTrim)) {
      return [list?.find((e) => e?.member_hid === searchKeyTrim)];
    }
    return list?.filter(
      (e) =>
        removeAccents(e?.name)?.toLowerCase().includes(removeAccents(searchKeyTrim)?.toLowerCase()) ||
        removeAccents(e?.nickname)?.includes(removeAccents(searchKeyTrim)?.toLowerCase())
    );
  };
  const handleSearchFriendLocal = (listFriendToSearch) => {
    if (listFriendToSearch?.length > 0) {
      setListSearchFriendLocal(searchFriendFunc(listFriendToSearch));
    }
  };
  const handleClickMenu = (query) => {
    router.push({ query: { p: query } });
  };
  const handleSubmitSearch = () => {
    if (page === FRIEND_PAGE_QUERY) {
      handleSearchFriendLocal(listFriend?.list_friend);
    } else if (page === FOLLOW_PAGE_QUERY) {
      handleSearchFriendLocal(listFriend?.list_following);
    } else if (page === INVITATION_PAGE_QUERY) {
      handleSearchFriendLocal(listInvitation);
    } else {
      handleSearchFriendAPI(searchKey);
    }
  };
  useEffect(() => {
    if (!page && listFriendSearch?.length > 0) {
      router.push({ query: { p: SEARCH_FRIEND_PAGE_QUERY } });
    }
  }, [listFriendSearch]);
  useEffect(() => {
    if (openDialogLocation) {
      handleSearchLocation();
    }
  }, [openDialogLocation]);
  return (
    <>
      <div className="lg:w-[330px] md:w-[282px] flex flex-col md:px-[16px] sm:px-2 py-[12px] md:pt-0 sm:bg-white md:bg-grayBackground">
        {matches768 && (
          <Box className="lg:w-[340px] md:w-[290px] absolute top-[-30px] truncate">
            <Breadcrumb />
          </Box>
        )}
        <span className="text-title mb-3">
          <FormattedMessage id="IDS.friend" />
        </span>
        {!hiddenSearch && (
          <div className="w-full mb-4">
            <SearchInput
              value={searchKey}
              placeholder={intl.formatMessage({
                id: "friend.search.placeholder"
              })}
              onChange={(e) => {
                dispatch(friendActions.handleSetSearchKeyFriend(e));
                if (e?.length === 0) {
                  setIsShowDefaultData(true);
                }
              }}
              onSubmit={() => {
                handleSubmitSearch();
                setIsShowDefaultData(false);
              }}
            />
          </div>
        )}
        <div>
          {page === FRIEND_PAGE_QUERY || page === FOLLOW_PAGE_QUERY ? (
            <ListFriendView
              isShowDefaultData={isShowDefaultData}
              listSearchFriend={listSearchFriendLocal}
              isFriend={page === FRIEND_PAGE_QUERY}
            />
          ) : page === INVITATION_PAGE_QUERY ? (
            <InvitationView isShowDefaultData={isShowDefaultData} listSearchFriend={listSearchFriendLocal} />
          ) : page === SEARCH_FRIEND_PAGE_QUERY ? (
            <SearchFriendView isShowDefaultData={isShowDefaultData} handleSearchLocation={handleSearchLocation} />
          ) : (
            MenuPageFriend?.map((item) => (
              <div key={item.id} className="flex justify-between py-2 text-[15px]">
                <button
                  type="button"
                  className="hover:underline"
                  onClick={() => {
                    handleClickMenu(item.query);
                  }}
                >
                  <FormattedMessage id={item.intlId} />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleClickMenu(item.query);
                  }}
                >
                  <ArrowRight />
                </button>
              </div>
            ))
          )}
        </div>
      </div>
      {openDialogLocation && locationPermission !== LOCATION_PERMISSION_ACCEPTED && (
        <AlertDialog
          open={openDialogLocation && locationPermission !== LOCATION_PERMISSION_ACCEPTED}
          setOpen={() => {
            setOpenDialogLocation(false);
          }}
          title={intl.formatMessage({ id: "Setting.notification" })}
          content={intl.formatMessage({ id: "Profile.location.description" })}
          triggerFunc={() => {
            dispatch(commonActions.handleSaveLocationPermission(LOCATION_PERMISSION_ACCEPTED));
            setOpenDialogLocation(false);
            handleSearchLocation();
          }}
          cancelFunc={() => {
            dispatch(commonActions.handleSaveLocationPermission(LOCATION_PERMISSION_DENIED));
            setOpenDialogLocation(false);
          }}
          buttonOkKey="common.next"
        />
      )}
    </>
  );
}
