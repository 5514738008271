/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-filename-extension */
import { Box, Divider } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import ModalComplain from "../../questionAndAnswer/components/ModalComplain";
import { REPORT_QUIZ, isShowLock } from "../utils/constant";
import SubjectTypeCard from "../../subjects/components/SubjectTypeCard";
import { TCommonState } from "../utils/type";

type TProps = {
  cardList: any;
};

const Lessons = (props: TProps) => {
  const { cardList } = props;
  const dataUser = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const [openModalReport, setOpenModalReport] = React.useState(false);
  const [cardId, setCardId] = React.useState<number>(0);
  const handleOpenModalReport = (ele) => {
    setCardId(ele?.topic_id);
    setOpenModalReport(true);
  };
  const handleCloseModalReport = () => {
    setOpenModalReport(false);
  };

  return (
    <Box className="box-cover-left md:w-[250px] lg:w-[298px]">
      <Box
        style={{
          display: "flex"
        }}
        className="title-sub"
      >
        <FormattedMessage id="Lesson_learned" />
      </Box>
      <Box style={{ marginTop: 12 }} display="flex" flexDirection="column">
        {cardList?.slice(0, 5).map((ele: any, idx: number) => (
          <>
            <SubjectTypeCard
              ele={ele}
              isShowLock={(accessLevel) => isShowLock(accessLevel, dataUser)}
              handleOpenModalReport={handleOpenModalReport}
              blockLessonLeftSide
              className="h-auto p-0 border-0 rounded-0"
            />
            {idx !== cardList?.length - 1 && idx !== 4 && (
              <div className="py-[12px]">
                <Divider sx={{ borderBottomWidth: 1 }} />
              </div>
            )}
          </>
        ))}
      </Box>
      {openModalReport && (
        <ModalComplain
          open={openModalReport}
          handleClose={handleCloseModalReport}
          title={<FormattedMessage id="title.report.quiz" />}
          subtitle={<FormattedMessage id="subtitle.report.card" />}
          type={REPORT_QUIZ}
          topic={{ id: cardId }}
          openTextInput
        />
      )}
    </Box>
  );
};

export default Lessons;
