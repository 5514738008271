/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { uniqBy } from "lodash";
import router from "next/router";
import React, { useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import IconSearch from "../../../../../public/assets/icons/IconSearch";
import { IconX } from "../../../../../public/assets/icons/XCircle";
import { isEmpty } from "../../../../utils/constants/constants";
import { commonActions } from "../../../common/redux/actions";
import { TCommonState } from "../../../common/utils/type";

const MenuSuggest = () => {
  const intl = useIntl();
  const [textSearch, setTextSearch] = React.useState<string>("");
  const { listKeywordSearched } = useSelector((store: { commonNew: TCommonState }) => store.commonNew);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleSearch = (currentTextSearch = "") => {
    const keyword = currentTextSearch || textSearch;

    if (!isEmpty(keyword.trim())) {
      // dispatch(searchAction.handleChooseTab(TOPIC_OPENING_VIEW_TYPE_TAB.CARD));
      // dispatch(commonActions.handleSearch(textSearch));
      router
        .push({
          pathname: "/tim-kiem",
          query: {
            search: keyword
          }
        })
        .then(() => dispatch(commonActions.handleAddKeywordsFormListSearched(keyword)));
    }
  };

  return (
    <div className="relative">
      <Popup
        trigger={
          <div
            className="md:ml-[20px] md:w-[228px] lg:h-[32px] sm:w-full bg-white border border-gray pr-10"
            style={{
              padding: "4px 12px",
              borderRadius: "20px",
              display: "flex"
            }}
          >
            <input
              type="text"
              placeholder={intl.formatMessage({ id: "search.placeholder" })}
              style={{
                outline: "none",
                fontSize: "12px",
                width: "100%"
              }}
              className="placeholder:text-xs text-black select-all"
              value={textSearch}
              onChange={(e) => setTextSearch(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  handleSearch();
                  inputRef.current?.blur();
                }
              }}
              ref={inputRef}
              id="search_input"
            />
          </div>
        }
        position="bottom center"
        on="focus"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{
          padding: listKeywordSearched.length ? 20 : 0,
          border: "none",
          top: 56,
          zIndex: "1000",
          width: "265px",
          position: "fixed"
        }}
        arrow={false}
        className="search"
      >
        {(close: () => void) =>
          !!listKeywordSearched.length && (
            <>
              <div className="pb-4">
                <span className="text-[15px] font-semibold">
                  <FormattedMessage id="search.suggest.title" />
                </span>
              </div>
              {uniqBy(listKeywordSearched, (e: any) => e)
                ?.slice(0, 5)
                ?.filter((keywordSearched) => keywordSearched !== "")
                ?.map((keywordSearched, index) => (
                  <div
                    key={index}
                    tabIndex="-1"
                    className="flex justify-between items-center mb-3"
                    onFocus={(e) => e.stopPropagation()}
                  >
                    <div
                      role="button"
                      onClick={(e) => {
                        close();
                        setTextSearch(keywordSearched);
                        handleSearch(keywordSearched);
                      }}
                      className="w-[80%] text-left"
                      onFocus={(e) => e.stopPropagation()}
                      tabIndex="-2"
                    >
                      <span className="w-full line-clamp-2 text-[15px] outline-none">{keywordSearched}</span>
                    </div>
                    <div
                      role="button"
                      tabIndex="-3"
                      type="button"
                      onClick={() => {
                        dispatch(commonActions.handleDeleteKeywordsFromList(keywordSearched));
                        inputRef.current?.focus();
                      }}
                      onFocus={(e) => e.stopPropagation()}
                    >
                      <IconX />
                    </div>
                  </div>
                ))}
            </>
          )
        }
      </Popup>
      <button
        type="button"
        className="absolute right-4 top-1/2 -translate-y-1/2"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          handleSearch();
        }}
      >
        <IconSearch />
      </button>
    </div>
  );
};

export default MenuSuggest;
