import { ACTION_TYPES } from "./actionTypes";

const handleGetListTopic = (params: any) => {
  return {
    type: ACTION_TYPES.GET_LIST_TOPIC,
    params
  };
};
const handleGetListSubject = (params: any) => {
  return {
    type: ACTION_TYPES.GET_LIST_SUBJECTS,
    params
  };
};
const handleCleanNotify = () => {
  return {
    type: ACTION_TYPES.CLEAN_NOTIFY
  };
};
const handleOpenModalEarnDiamond = (params: boolean) => {
  return {
    type: ACTION_TYPES.HANDLE_CHANGE_OPEN_MODAL_EARN_DIAMOND,
    params
  };
};
const handleChoosePage = (page: number) => {
  return {
    type: ACTION_TYPES.HANDLE_CHOOSE_PAGE,
    params: page
  };
};
const handleChangeTabSubject = (params) => {
  return {
    type: ACTION_TYPES.HANDLE_CHANGE_TAB,
    params
  };
};
const handleGetListCard = (params: any, callback = () => {}) => {
  return {
    type: ACTION_TYPES.GET_LIST_CARD,
    params,
    callback
  };
};
export const subjectActions = {
  handleGetListTopic,
  handleGetListSubject,
  handleCleanNotify,
  handleOpenModalEarnDiamond,
  handleChoosePage,
  handleChangeTabSubject,
  handleGetListCard
};
