import React from "react";

export default function Logo1({ width, height }: any) {
  return (
    <svg
      width={width || "56"}
      height={height || "56"}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1497_13346)">
        <path
          d="M28 0C12.5346 0 0 12.5332 0 27.9968C0 28.834 0.0386473 29.6649 0.109501 30.4828C0.650564 36.6914 3.22705 42.3269 7.16908 46.7064C8.55395 48.2521 10.1127 49.6432 11.8132 50.8476C14.9308 53.0631 18.5314 54.6539 22.4155 55.4397C24.219 55.8068 26.087 56 28 56C43.4654 56 56 43.4604 56 27.9968C56 12.5332 43.4654 0 28 0ZM34.5958 9.14549C34.467 9.20989 34.3188 9.24209 34.1707 9.24209C33.591 9.24209 33.1208 8.77194 33.1208 8.1923C33.1208 7.6835 33.4815 7.25842 33.9646 7.16182C34.029 7.14894 34.0998 7.1425 34.1707 7.1425C34.7504 7.1425 35.2206 7.61265 35.2206 8.1923C35.2206 8.62381 34.963 8.99091 34.5958 9.14549Z"
          fill="#D82232"
        />
        <path
          d="M35.382 5.13379C29.5398 5.13379 24.9215 10.1638 24.9215 10.1638C24.9215 10.1638 25.4883 11.0526 25.4883 12.1088C25.4883 13.1651 24.3546 15.0521 24.2773 15.1809C24.4384 15.1294 26.9827 14.2406 28.0004 12.3536H29.3015C30.1904 12.3536 37.6493 11.3811 40.7347 5.94529C40.7347 5.94529 39.5173 5.13379 35.382 5.13379ZM34.1711 9.24282C33.5914 9.24282 33.1211 8.77266 33.1211 8.19302C33.1211 7.68422 33.4819 7.25915 33.9649 7.16254C34.0294 7.14966 34.1002 7.14322 34.1711 7.14322C34.7508 7.14322 35.221 7.61338 35.221 8.19302C35.221 8.62453 34.9633 8.99164 34.5962 9.14621C34.4674 9.21061 34.3192 9.24282 34.1711 9.24282Z"
          fill="white"
        />
        <path
          d="M26.1594 7.99143C26.1594 7.99143 25.3091 7.50195 24.3752 7.50195C23.4412 7.50195 21.8438 7.99143 21.8438 7.99143C21.8438 7.99143 23.8534 8.6226 24.2785 9.51138C24.2785 9.51138 25.0064 8.45514 26.1658 7.99143H26.1594Z"
          fill="white"
        />
        <path
          d="M7.56641 37.8119C7.56641 43.7758 9.50521 47.9685 11.8112 50.8474C14.9287 53.063 18.5294 54.6538 22.4134 55.4395C18.0914 52.6121 12.8353 47.2472 12.8353 37.8892C12.8353 22.1229 24.0881 12.6618 24.2685 12.5137C18.349 15.1092 7.56641 23.134 7.56641 37.8055V37.8119Z"
          fill="#BC2026"
        />
        <path
          d="M23.7293 10.8076C23.7293 10.8076 7.2849 14.2597 0.109375 30.4833C0.656879 36.6919 3.22693 42.3273 7.16896 46.7069C8.56026 48.2526 10.1126 49.6437 11.8131 50.8481C9.50712 47.9692 7.56831 43.77 7.56831 37.8126C7.56831 23.1411 18.3509 15.1099 24.2704 12.5208L23.7293 10.8141V10.8076Z"
          fill="white"
        />
        <path
          d="M25.3863 36.9942C24.9419 37.4386 24.3364 37.664 23.5763 37.664H19.0095C18.6874 37.664 18.4362 37.5674 18.243 37.3742C18.0497 37.1809 17.9531 36.9233 17.9531 36.6077C17.9531 36.2922 18.0497 36.041 18.2494 35.8542C18.4427 35.661 18.7003 35.5708 19.0159 35.5708H23.8146C23.8146 35.5708 23.8275 34.4502 23.8146 32.2024V28.9629H21.9596C21.805 28.9629 21.6568 28.9371 21.528 28.8856C21.3992 28.8341 21.2897 28.7632 21.1931 28.6666C21.0964 28.57 21.0256 28.4605 20.9741 28.3382C20.9225 28.2093 20.8968 28.0741 20.8968 27.9195C20.8968 27.6039 20.9934 27.3528 21.1931 27.1531C21.3927 26.9534 21.6439 26.8633 21.9596 26.8633H24.9161C25.0514 26.8633 25.1866 26.889 25.309 26.947C25.4378 27.005 25.5473 27.0823 25.6504 27.1724C25.7534 27.2626 25.8307 27.3785 25.8887 27.5009C25.9467 27.6233 25.9789 27.7585 25.9789 27.9002V35.3003C25.9789 36.0345 25.7792 36.6013 25.3863 36.9942Z"
          fill="white"
        />
        <path
          d="M28.5838 28.029V35.6481H32.4807C32.6353 35.6481 32.7706 35.6739 32.8994 35.7254C33.0282 35.7769 33.1377 35.8478 33.2279 35.9379C33.3181 36.0281 33.389 36.1311 33.4469 36.2599C33.4985 36.3888 33.5242 36.524 33.5242 36.6786C33.5242 37.3355 33.17 37.6575 32.4679 37.6575H28.6611C27.1861 37.6575 26.4453 36.9169 26.4453 35.442V28.1256C26.4453 27.2819 26.8189 26.8633 27.5661 26.8633C27.7078 26.8633 27.8431 26.8955 27.9654 26.9534C28.0878 27.0114 28.1973 27.0951 28.2875 27.1982C28.3777 27.3012 28.4485 27.4236 28.5065 27.5653C28.558 27.707 28.5838 27.8616 28.5838 28.029Z"
          fill="white"
        />
        <path
          d="M39.6011 33.3159H36.1164V36.6134C36.1164 36.929 36.0134 37.1801 35.8137 37.3734C35.614 37.5601 35.3499 37.6567 35.0407 37.6567C34.7316 37.6567 34.4675 37.5601 34.2742 37.3605C34.081 37.1673 33.9844 36.9161 33.9844 36.6134V29.2004C33.9844 28.5435 34.2163 27.9831 34.6865 27.5323C35.1567 27.075 35.7171 26.8496 36.3676 26.8496H39.6076C40.271 26.8496 40.8378 27.075 41.3145 27.5323C41.7783 27.9831 42.0166 28.537 42.0166 29.2004V30.9393C42.0166 31.6091 41.7847 32.1695 41.3145 32.6203C40.8378 33.084 40.271 33.3159 39.6076 33.3159H39.6011ZM36.1164 31.2098H39.8523V28.9363H36.1164V31.2098Z"
          fill="white"
        />
        <path
          d="M44.8262 26.8682H49.4059C49.7216 26.8682 49.9792 26.9648 50.1725 27.1644C50.3721 27.3576 50.4687 27.6153 50.4687 27.9308C50.4687 28.0854 50.443 28.2207 50.3915 28.3495C50.3399 28.4783 50.2626 28.5878 50.166 28.6779C50.0694 28.7681 49.9535 28.8454 49.8246 28.8969C49.6958 28.9484 49.5541 28.9742 49.3995 28.9742L47.5187 28.9613V36.6448C47.5187 36.7994 47.4929 36.9411 47.4414 37.0699C47.3898 37.1987 47.3125 37.3082 47.2224 37.3984C47.1322 37.4885 47.0227 37.5594 46.8939 37.6109C46.765 37.6624 46.6233 37.6882 46.4687 37.6882C46.1467 37.6882 45.889 37.5916 45.6894 37.3919C45.6314 37.334 45.5799 37.2438 45.5283 37.1214C45.4768 36.999 45.4317 36.838 45.3931 36.6448V28.9806L44.8456 28.9678L43.5251 28.9806C43.3705 28.9806 43.2288 28.9549 43.1 28.9034C42.9712 28.8518 42.8617 28.781 42.765 28.6844C42.6684 28.5878 42.5976 28.4783 42.546 28.3559C42.4945 28.2271 42.4688 28.0919 42.4688 27.9373C42.4688 27.6217 42.5654 27.3705 42.765 27.1709C42.9583 26.9777 43.2159 26.8746 43.5316 26.8746H44.8262V26.8682Z"
          fill="white"
        />
        <path
          d="M25.5391 10.9864C25.4489 10.5098 25.3458 9.78848 25.4618 9.62747C25.6228 9.46646 26.2412 8.8997 27.1107 8.24277C27.1365 8.81597 27.2975 9.76272 27.3877 10.5678C27.4457 10.9864 27.8128 11.1925 28.6695 11.0895C29.5262 10.98 30.0351 10.5935 29.932 10.1491C29.6937 9.09935 29.3716 7.56652 29.3909 6.78722C30.37 6.2591 31.0012 6.00792 32.1156 5.62793C32.3281 6.61976 32.7082 8.32005 33.0173 9.47934C33.2879 10.5034 31.407 11.9589 28.8885 12.0877C26.4086 12.2165 25.6035 11.3986 25.5326 10.9929L25.5391 10.9864Z"
          fill="#BC2026"
        />
        <path
          d="M37.5692 5.62213C36.2552 5.63501 35.4372 5.73806 33.7109 6.2533C33.7882 6.57532 33.8784 6.87802 33.9686 7.1614C34.033 7.14852 34.1039 7.14208 34.1747 7.14208C34.7544 7.14208 35.2246 7.61224 35.2246 8.19188C35.2246 8.62339 34.967 8.9905 34.5998 9.14507C34.7158 9.53794 34.909 10.4074 34.9734 10.8647C36.0942 10.3494 36.6675 10.0081 37.2729 9.59591C37.2729 9.27388 37.2536 8.87457 37.1956 8.51391C37.1956 8.51391 37.6916 8.27561 38.3615 8.00511C39.0314 7.73461 39.8559 6.82006 39.7915 6.37567C39.7206 5.85399 38.9541 5.60281 37.5628 5.62213H37.5692ZM37.6401 7.21937C37.3953 7.30954 37.2665 7.36106 36.996 7.45767C36.8929 7.05192 36.822 6.83938 36.6803 6.42075C36.9509 6.35634 37.0861 6.32414 37.3438 6.27262C38.091 6.11161 38.5225 6.19533 38.6127 6.44651C38.7029 6.70413 38.3679 6.95531 37.6401 7.21937Z"
          fill="#BC2026"
        />
        <path
          d="M34.1758 9.40209C33.5059 9.40209 32.9648 8.86109 32.9648 8.19128C32.9648 7.61808 33.3771 7.11572 33.9375 7.00623C34.0148 6.99335 34.0921 6.98047 34.1758 6.98047C34.8457 6.98047 35.3867 7.52147 35.3867 8.19128C35.3867 8.67432 35.1033 9.10583 34.6653 9.2926C34.5236 9.36345 34.3561 9.40209 34.1758 9.40209Z"
          fill="#BF3737"
        />
        <path
          d="M34.1728 7.14234C34.7525 7.14234 35.2227 7.61249 35.2227 8.19214C35.2227 8.62365 34.965 8.99076 34.5979 9.14533C34.4691 9.20973 34.3209 9.24193 34.1728 9.24193C33.5931 9.24193 33.1228 8.77178 33.1228 8.19214C33.1228 7.68334 33.4836 7.25827 33.9666 7.16166C34.0311 7.14878 34.1019 7.14234 34.1728 7.14234ZM34.1728 6.82031C34.0826 6.82031 33.986 6.82675 33.9022 6.84607C33.2646 6.97488 32.8008 7.54165 32.8008 8.19214C32.8008 8.94567 33.4191 9.56396 34.1728 9.56396C34.3724 9.56396 34.5721 9.51887 34.7396 9.43515C35.2227 9.23549 35.5447 8.74602 35.5447 8.19214C35.5447 7.4386 34.9264 6.82031 34.1728 6.82031Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1497_13346">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
