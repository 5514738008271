/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React from "react";
import ReactAudioPlayer from "react-audio-player";
import { FormattedMessage } from "react-intl";
import IconClock from "../../../../public/assets/icons/IconClock";
import IconUnlimited from "../../../../public/assets/icons/IconUnlimited";
import { IconDiamond10 } from "../../../../public/assets/icons/IconDiamond";
import IconQuestion from "../../../../public/assets/icons/IconQuestion";
import IconSubject from "../../../../public/assets/icons/IconSubject";
import { isEmpty } from "../../../utils/helpers/helpers";
import { TTheme } from "../../../utils/configs/setupTheme";

export const ColorButton = styled(Button)(() => ({
  color: "white",
  background: "linear-gradient(180deg, #FEDF3F 0%, #F7871D 100%)",
  "&:hover": {
    background: "linear-gradient(180deg, #FEDF3F 0%, #F7871D 100%)"
  },
  "&:disabled": {
    background: "#9E9E9E",
    color: "#FFFFFF"
  },
  borderRadius: 32,
  padding: "4px 8px"
}));

export const ColorButtonOutlined = styled(Button)(() => ({
  color: "linear-gradient(180deg, #FEDF3F 0%, #F7871D 100%)",
  background: "white",
  border: "1px solid #FEDF3F",
  "&:disabled": {
    background: "#9E9E9E",
    color: "#FFFFFF"
  },
  borderRadius: 32,
  padding: "4px 8px"
}));

export const ColorButtonOutlinedGrade = styled(Button)(() => ({
  color: "#212121",
  background: "white",
  "&:hover": {
    background: "white"
  },
  border: "1px solid #E5E5E5",
  "&:disabled": {
    background: "#9E9E9E",
    color: "#FFFFFF"
  },
  borderRadius: 32,
  padding: "8px 12px"
}));

export function FacebookCircularProgress({ value, size, thickness, color, sx }: any) {
  const theme: TTheme = useTheme();
  return (
    <Box sx={sx}>
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (t) => t.palette.grey[t.palette.mode === "light" ? 200 : 800]
          }}
          size={size}
          thickness={thickness || 4}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          sx={{
            color: color || theme.palette.primary.main,
            position: "absolute",
            left: 0
          }}
          value={value}
          size={size}
          thickness={thickness || 4}
        />
      </Box>
    </Box>
  );
}

// eslint-disable-next-line
export const ExamsCard = ({ ele }: any) => {
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const theme: TTheme = useTheme();
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mr={matches1100 ? 1 : 0.5}
        >
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <FacebookCircularProgress
              size={32}
              variant="determinate"
              value={Math.round((ele.correct_quiz / ele.total) * 100)}
              small="true"
              color={theme.palette.primary.main}
            />
            <Box
              sx={{
                top: -5,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round((ele.correct_quiz / ele.total) * 100)}`}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              // backgroundColor: "#9E9E9E",
              width: 42,
              height: 16,
              // borderRadius: 8,
              fontSize: 10
            }}
            className="text-gray400"
          >
            <span className="flex justify-center items-center mt-[1px] ml-[1px]">
              <FormattedMessage id="Times" values={{ num: ele.num_of_times }} />
            </span>
          </Box>
        </Box>
        <Box style={{ lineHeight: "18px" }} className="w-full">
          <span className="text-[15px] hover:underline">{ele.title}</span>
          <Box display="flex" alignItems="center" justifyContent="space-between" className="pt-1">
            <Box className="flex justify-between gap-x-1">
              <Box
                display="flex"
                alignItems="center"
                style={{
                  color: theme.palette.primary.gray400,
                  fontSize: 12,
                  marginRight: 4,
                  maxWidth: 24,
                  height: 18
                }}
              >
                <IconDiamond10 fill={theme.palette.primary.main} />
                &nbsp;
                {ele.price}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{
                  color: theme.palette.primary.gray400,
                  fontSize: 12,
                  marginRight: 4,
                  gap: 2
                }}
              >
                <IconClock />
                {ele?.time < 0 ? (
                  <IconUnlimited />
                ) : (
                  <FormattedMessage id="num.minute" values={{ minute: ele?.time }} />
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                columnGap={0.25}
                style={{
                  color: theme.palette.primary.gray400,
                  fontSize: 12,
                  marginRight: 4
                }}
              >
                <IconQuestion />
                <FormattedMessage id="num.question" values={{ num: ele.total }} />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" style={{ color: theme.palette.primary.gray400, fontSize: 12 }}>
              {matches1100
                ? moment.unix(ele.last_modified).format("DD/MM/YY")
                : moment.unix(ele.last_modified).format("DD/MM")}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// eslint-disable-next-line
export const ExercisedCard = ({ ele }: any) => {
  const theme: TTheme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        border: `1px solid ${theme.palette.primary.gray}`,
        borderRadius: "6px",
        padding: 12
      }}
    >
      <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" mr={2}>
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <FacebookCircularProgress
            size={48}
            value={Math.round((ele?.correct_quiz / ele?.total) * 100)}
            color={theme.palette.primary.main}
          />
          <Box
            sx={{
              top: -6,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 15
            }}
          >
            {`${Math.round((ele?.correct_quiz / ele?.total) * 100)}`}
          </Box>
        </Box>
        <span className="text-[12px] text-gray1">
          <FormattedMessage id="Times" values={{ num: ele?.num_of_times }} />
        </span>
        <Box display="flex" alignItems="center" style={{ color: theme.palette.primary.gray3, fontSize: 12 }}>
          {moment.unix(ele?.last_modified).format("DD/MM/YY")}
        </Box>
      </Box>
      <Box style={{ lineHeight: "18px" }} className="w-full">
        <Typography
          className="line-clamp-2"
          variant="body1"
          style={{
            display: "-webkit-box",
            overflow: "hidden"
            // lineHeight: "25.6px"
          }}
        >
          {ele?.title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          style={{
            color: theme.palette.primary.gray3,
            fontSize: 12
          }}
        >
          <Typography
            variant="body1"
            style={{
              padding: "4px 8px",
              borderRadius: "15px",
              backgroundColor: "#AEA6F0",
              color: "white",
              fontSize: "10px",
              fontWeight: "400"
            }}
          >
            {ele?.learning_program?.title}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" className="w-full text-[12px] gap-x-4 mt-1">
          <Box display="flex" alignItems="center" gap="2px">
            <IconSubject />
            &nbsp;
            <span className="line-clamp-1">{ele?.subject_title}</span>
          </Box>
          <Box display="flex" alignItems="center" gap="2px">
            <IconClock width={16} height={16} />
            &nbsp;{" "}
            {ele?.time < 0 ? (
              <IconUnlimited width={16} height={16} />
            ) : (
              <FormattedMessage id="num.minute" values={{ minute: ele?.time }} />
            )}
          </Box>
          <Box display="flex" alignItems="center" gap="2px">
            <IconQuestion width={16} height={16} />
            &nbsp; <FormattedMessage id="num.question" values={{ num: ele?.total }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// eslint-disable-next-line
export const ShowBasicDataContent = ({ ele }: any) => {
  if (isEmpty(ele)) return null;
  switch (ele?.content_type) {
    case "image":
      return (
        <Box display="flex" justifyContent="center">
          <img
            src={ele?.content}
            alt=""
            style={{
              maxWidth: 496,
              maxHeight: 200,
              width: "100%",
              height: "100%",
              objectFit: "contain"
            }}
          />
        </Box>
      );
    case "audio":
      return (
        <Box my={1} display="flex" justifyContent="center">
          <ReactAudioPlayer src={ele?.content} controls />
        </Box>
      );
    default:
      return (
        <Box display="flex" alignItems="baseline">
          {ele?.content && (
            // eslint-disable-next-line
            <div dangerouslySetInnerHTML={{ __html: ele?.content }} />
          )}
        </Box>
      );
  }
};
