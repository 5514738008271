/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */

import { Badge, Avatar, Divider, useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useRouter } from "next/router";
import CXModal from "../../common/components/CXModal";
import IconDiamond from "../../../../public/assets/icons/IconDiamond";
import { TCommonState } from "../../common/utils/type";
import CXTextArena from "../../common/components/CXTextArena";
import UploadImages from "../../common/components/UploadImages";
import {
  ASK_MAX_NUM_IMAGE_UPLOAD,
  ASK_MAX_LENGTH_CONTENT,
  ASK_MAX_LENGTH_TITLE,
  ASK_POST_CONTENT_TYPE_HTML,
  ASK_POST_CONTENT_TYPE_IMAGE,
  ERROR_CODE
} from "../utils/constants";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import { questionAndAnswerAction } from "../redux/actions";
import { getFormData } from "../../common/utils/constant";
import AlertDialog from "../../common/dialog/AlertDialog";
import { arenaRoomActions } from "../../arena/redux/actions";
import { IQuestionAndAnswer, TCreationInfoPost, TPost } from "../utils/types";
import ModalEarnDiamond from "../../common/components/ModalEarnDiamond";
import { commonActions } from "../../common/redux/actions";
import { TTheme } from "../../../utils/configs/setupTheme";
import { toastSuccess } from "../../common/components/reactToastify";

type TModalCreatePost = {
  open: boolean;
  handleClose: () => void;
  title: string;
  userInfo: any;
  postInfo?: TPost;
  roomData?: any;
  subjectType?: any;
};

const initialDataCreate = (creationInfo: TCreationInfoPost, gradeUser, postInfo, roomData, subjectType) => {
  const gradeId = creationInfo.packages.find((item) => item.slug === gradeUser)?.id;
  return {
    diamond: postInfo ? postInfo?.fee : creationInfo?.fee[1],
    gradeId: roomData
      ? creationInfo?.packages?.find((ele) => ele.slug === gradeUser)?.id
      : postInfo
      ? subjectType?.parent_id || postInfo?.grade_id
      : creationInfo?.packages?.find((e) => e.slug === gradeUser)?.id,
    subjectTypeId: roomData
      ? roomData.package_id
      : postInfo
      ? postInfo?.subject_type_id
      : creationInfo?.packages?.filter((e) => e?.parent_id === gradeId)[0]?.id,
    title: postInfo ? postInfo.post_title : "",
    contentText: roomData
      ? roomData.content
      : postInfo
      ? postInfo.post_content.find((e) => e.content_type === ASK_POST_CONTENT_TYPE_HTML)?.content
      : "",
    contentImages: postInfo
      ? postInfo.post_content
          .filter((e) => e.content_type === ASK_POST_CONTENT_TYPE_IMAGE)
          ?.map((item) => {
            return {
              dataURL: item.content,
              imageUid: item.image_uid
            };
          })
      : []
  };
};
const ModalCreatePost = ({ open, handleClose, title, userInfo, postInfo, roomData, subjectType }: TModalCreatePost) => {
  const intl = useIntl();
  const router = useRouter();
  const dispatch = useDispatch();
  const theme: TTheme = useTheme();
  const { notification, action } = useSelector(
    (state: { questionAndAnswer: IQuestionAndAnswer }) => state.questionAndAnswer
  );
  const creationInfo = useSelector(
    (state: { questionAndAnswer: IQuestionAndAnswer }) => state.questionAndAnswer.creationInfoPost
  );

  const [anchorDiamond, setAnchorDiamond] = React.useState<null | HTMLElement>(null);

  const [anchorGrade, setAnchorGrade] = React.useState<null | HTMLElement>(null);
  const [anchorSubject, setAnchorSubject] = React.useState<null | HTMLElement>(null);
  const [openAlertDialogViewAds, setOpenAlertDialogViewAds] = React.useState(false);
  const gradeUser = useSelector((state: { commonNew: TCommonState }) => state.commonNew.grade);
  const [parentId, setParentId] = React.useState(creationInfo?.packages?.find((item) => item.slug === gradeUser)?.id);
  const dataUser = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const [dataCreatePost, setDataCreatePost] = React.useState<any>(
    initialDataCreate(creationInfo, gradeUser, postInfo, roomData, subjectType)
  );
  const handleCreatePost = () => {
    if (action === "CREATE_POST") return;
    const dataPostPre: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      title: dataCreatePost.title.trim().replace(/\s\s+/g, " "),
      grade_id: dataCreatePost.gradeId,
      subject_type_id: dataCreatePost.subjectTypeId,
      fee: dataCreatePost.diamond
    };
    const dataPost = getFormData({
      ...dataPostPre,
      signature: genSignature(dataPostPre),
      content_image: dataCreatePost.contentImages?.map((item) => item.file),
      content_text: dataCreatePost.contentText.trim().replace(/\s\s+/g, " ")
    });
    dispatch(questionAndAnswerAction.handleCreatePost(dataPost));
  };
  const oldPostContentText = postInfo?.post_content.find((e) => e.content_type === ASK_POST_CONTENT_TYPE_HTML)?.content;
  const oldPostContentImages = postInfo?.post_content?.filter(
    (e) => e.content_type === ASK_POST_CONTENT_TYPE_IMAGE
  ) as any;
  const handleEditPost = () => {
    if (action === "EDIT_POST") return;
    const dataPatchPre: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      post_id: postInfo?.post_id
    };
    if (postInfo?.post_title !== dataCreatePost.title) {
      dataPatchPre.title = dataCreatePost.title.trim().replace(/\s\s+/g, " ");
    }
    if (subjectType?.parent_id !== dataCreatePost.gradeId) {
      dataPatchPre.grade_id = dataCreatePost.gradeId;
    }
    if (postInfo?.subject_type_id !== dataCreatePost.subjectTypeId) {
      dataPatchPre.subject_type_id = dataCreatePost.subjectTypeId;
    }
    if (postInfo?.fee !== dataCreatePost.diamond) {
      dataPatchPre.fee = dataCreatePost.diamond;
    }
    const dataPatch: any = {
      ...dataPatchPre,
      signature: genSignature(dataPatchPre)
    };
    if (oldPostContentText !== dataCreatePost.contentText) {
      dataPatch.content_text = dataCreatePost.contentText.trim().replace(/\s\s+/g, " ");
    }
    if (
      oldPostContentImages?.length !== dataCreatePost.contentImages?.length ||
      dataCreatePost.contentImages.some((e) => !!e.file)
    ) {
      const listUid = dataCreatePost.contentImages.map((ele) => ele.imageUid);
      dataPatch.old_image_uid = oldPostContentImages
        .filter((e) => !listUid.includes(e.image_uid))
        ?.map((el) => el.image_uid)
        .toString();
      dataPatch.content_image = dataCreatePost.contentImages.filter((e) => !e.imageUid)?.map((el) => el.file);
    }
    dispatch(
      questionAndAnswerAction.handleEditPost(getFormData(dataPatch), postInfo?.post_id, () =>
        router.replace(router.asPath)
      )
    );
  };

  const handleOpenMenuDiamond = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDiamond(event.currentTarget);
  };
  const handleOpenMenuGrade = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorGrade(event.currentTarget);
  };
  const handleOpenMenuSubject = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSubject(event.currentTarget);
  };
  const handleCloseMenuDiamond = () => {
    setAnchorDiamond(null);
  };
  const handleChooseDiamond = (d: number) => {
    setDataCreatePost((prev) => ({ ...prev, diamond: d }));
    handleCloseMenuDiamond();
  };
  const handleCloseMenuGrade = () => {
    setAnchorGrade(null);
  };
  const handleChooseGrade = (gradeId: number) => {
    handleCloseMenuGrade();
    // set lai subject
    const sbjId = creationInfo?.packages?.filter((e) => e.parent_id === gradeId)[0]?.id;
    setDataCreatePost((prev) => ({ ...prev, gradeId, subjectTypeId: sbjId }));
  };
  const handleCloseMenuSubject = () => {
    setAnchorSubject(null);
  };
  const handleChooseSubject = (subjectTypeId: number, parent) => {
    setDataCreatePost((prev) => ({ ...prev, subjectTypeId }));
    setParentId(parent);
    handleCloseMenuSubject();
  };
  const handleOnChangeTitle = (e) => {
    setDataCreatePost((prev) => ({
      ...prev,
      title: e.target.value.slice(0, ASK_MAX_LENGTH_TITLE + 1)
    }));
  };
  const handleOnChangeContentText = (e) => {
    setDataCreatePost((prev) => ({
      ...prev,
      contentText: e.target.value.slice(0, ASK_MAX_LENGTH_CONTENT + 1)
    }));
  };
  // eslint-disable-next-line no-unused-vars
  const handleOnChangeImages = (imageList, addUpdateIndex) => {
    setDataCreatePost((prev) => ({ ...prev, contentImages: imageList }));
  };
  const handleCloseModal = () => {
    setDataCreatePost(initialDataCreate(creationInfo, gradeUser, postInfo, roomData, subjectType));
    handleClose();
    if (roomData) {
      dispatch(arenaRoomActions.handleShareRoom(null));
    }
  };
  const handleClickSuggestedText = (suggestText) => {
    setDataCreatePost((prev) => ({
      ...prev,
      contentText: `${prev.contentText} ${suggestText}`.slice(0, ASK_MAX_LENGTH_CONTENT)
    }));
  };
  const getPostDetail = () => {
    if (router.query.postId) {
      router.replace(router.asPath);
    }
  };
  const handleGetListPost = (userClass?: any) => {
    const dataPost = {
      last_interacted: "",
      limit: 50,
      grade_id: userClass,
      category: "all"
    };
    dispatch(questionAndAnswerAction.handleGetListPost(dataPost));
  };
  const checkTitleAvailable = dataCreatePost.title?.length > 0 && dataCreatePost.title?.length <= ASK_MAX_LENGTH_TITLE;
  const checkContentAvailable =
    (dataCreatePost.contentText?.length > 0 && dataCreatePost.contentText?.length <= ASK_MAX_LENGTH_CONTENT) ||
    (dataCreatePost.contentImages?.length > 0 && dataCreatePost.contentImages?.length <= ASK_MAX_NUM_IMAGE_UPLOAD);
  const enableButtonCreate = checkTitleAvailable && checkContentAvailable;

  const enableButtonEdit =
    (postInfo?.post_title !== dataCreatePost.title && enableButtonCreate) ||
    (oldPostContentText !== dataCreatePost.contentText && enableButtonCreate) ||
    oldPostContentImages?.length !== dataCreatePost.contentImages?.length ||
    (postInfo?.fee !== dataCreatePost.diamond && enableButtonCreate) ||
    (postInfo?.subject_type_id !== dataCreatePost.subjectTypeId && enableButtonCreate) ||
    (dataCreatePost.contentImages.some((e) => !!e.file) && checkTitleAvailable);

  React.useEffect(() => {
    if (notification?.type === "CREATE_POST_SUCCESS") {
      toastSuccess(
        notification?.message,
        () => {
          dispatch(questionAndAnswerAction.handleCloseNotify());
          dispatch(commonActions.handleSaveIsUpdateInfo(true));
          handleCloseModal();
          router.push(`/hoi-dap/${notification?.postId}`);
        },
        () => {},
        500
      );
    } else if (notification?.type === "EDIT_POST_SUCCESS") {
      handleCloseModal();
      if (router?.query?.postId) {
        getPostDetail();
      } else {
        handleGetListPost(router?.query?.grade);
      }
    }
    if (notification?.errorCode === ERROR_CODE.CODE_NOT_ENOUGH_DIAMOND) {
      if (
        dataCreatePost?.diamond - (dataUser?.member?.diamond as number) <= 5 &&
        dataCreatePost?.diamond - (dataUser?.member?.diamond as number) >= 0
      ) {
        setOpenAlertDialogViewAds(true);
        dispatch(questionAndAnswerAction.handleCloseNotify());
        dispatch(commonActions.handleSaveIsUpdateInfo(true));
      }
    }
  }, [notification]);

  React.useEffect(() => {
    dispatch(questionAndAnswerAction.handleGetCreationInfo());
  }, [open]);

  React.useEffect(() => {
    if (!parentId) {
      setParentId(creationInfo?.packages?.find((item) => item.slug === gradeUser)?.id);
    }
  }, [creationInfo]);

  return (
    <CXModal title={title} open={open} handleClose={handleCloseModal} subtitle="">
      <div className="flex gap-4 items-center">
        <Badge>
          <Avatar src={userInfo?.member?.avatar} />
        </Badge>
        <span className="line-clamp-1 text-ellipsis">{userInfo?.member?.display_name}</span>
      </div>
      <div>
        <div className="flex md:gap-2 sm:gap-0 items-center justify-start">
          <span className="sm:text-[15px] font-normal">
            <FormattedMessage id="ask.createPost.bonus" />
          </span>
          <div>
            <Button
              id="basic-button"
              aria-controls={Boolean(anchorDiamond) ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorDiamond) ? "true" : undefined}
              onClick={handleOpenMenuDiamond}
              className="flex items-center justify-center gap-1 text-black font-normal text-[15px]"
            >
              <IconDiamond fill={theme.palette.primary.main} />{" "}
              <span className="ml-[2px] ">{dataCreatePost?.diamond} </span>
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorDiamond}
              open={Boolean(anchorDiamond)}
              onClose={handleCloseMenuDiamond}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
            >
              {creationInfo.fee.map((item) => (
                <MenuItem
                  onClick={() => handleChooseDiamond(item)}
                  disableRipple
                  key={item}
                  className="flex gap-2 items-center text-[15px]"
                  disabled={!!postInfo && postInfo?.fee > item}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div>
            <Button
              id="basic-button"
              aria-controls={Boolean(anchorGrade) ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorGrade) ? "true" : undefined}
              onClick={handleOpenMenuGrade}
              className="flex items-center justify-center gap-1 capitalize text-black font-normal text-[15px]"
            >
              {creationInfo.packages?.find((e) => e.id === dataCreatePost.gradeId)?.title}
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorGrade}
              open={Boolean(anchorGrade)}
              onClose={handleCloseMenuGrade}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
            >
              {creationInfo.packages
                ?.filter((e) => !e.parent_id)
                ?.map((item) => (
                  <MenuItem
                    onClick={() => handleChooseGrade(item.id)}
                    disableRipple
                    key={item.id}
                    className="flex gap-4 items-center text-[15px]"
                  >
                    {item?.title}
                  </MenuItem>
                ))}
            </Menu>
          </div>
          <div>
            <Button
              id="basic-button"
              aria-controls={Boolean(anchorSubject) ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorSubject) ? "true" : undefined}
              onClick={handleOpenMenuSubject}
              className="flex items-center justify-center gap-1 capitalize text-black font-normal text-[15px]"
            >
              {
                creationInfo.packages?.find((e) => e.id === dataCreatePost.subjectTypeId && e.parent_id === parentId)
                  ?.title
              }
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorSubject}
              open={Boolean(anchorSubject)}
              onClose={handleCloseMenuSubject}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}
            >
              {creationInfo.packages
                ?.filter((e) => e.parent_id === dataCreatePost?.gradeId)
                ?.map((item) => (
                  <MenuItem
                    onClick={() => handleChooseSubject(item.id, item.parent_id)}
                    disableRipple
                    key={item.id}
                    className="flex gap-4 items-center text-[15px]"
                  >
                    {item?.title}
                  </MenuItem>
                ))}
            </Menu>
          </div>
        </div>
        <div className="mt-[-10px] mb-[10px]">
          <span className="text-[10px] text-green1">
            <FormattedMessage id="ask.createPost.suggestDiamond" />
          </span>
        </div>
      </div>
      <div>
        <div>
          <input
            placeholder={intl.formatMessage({
              id: "ask.createPost.placeholderTitle"
            })}
            value={dataCreatePost.title}
            onChange={handleOnChangeTitle}
            className="border border-grayPlaceholder w-full h-10 px-4 outline-primary200 rounded-md"
            maxLength={ASK_MAX_LENGTH_TITLE}
          />
          <div className="flex justify-end px-2">
            <span
              className={`${dataCreatePost.title.length > ASK_MAX_LENGTH_TITLE ? "text-red" : "text-gray1"}  text-sm`}
            >
              {dataCreatePost.title.length}/{ASK_MAX_LENGTH_TITLE}
            </span>
          </div>
        </div>
        <div>
          <CXTextArena
            maxLength={ASK_MAX_LENGTH_CONTENT}
            content={dataCreatePost.contentText}
            onChangeText={handleOnChangeContentText}
            placeholder={intl.formatMessage({
              id: "ask.createPost.placeholderContent"
            })}
            className="text-sm"
          />
        </div>
        <div>
          <UploadImages
            onChange={handleOnChangeImages}
            values={dataCreatePost.contentImages}
            maxNumber={ASK_MAX_NUM_IMAGE_UPLOAD}
          />
        </div>
        <Divider />
        <div className="mt-4">
          <span className="text-[15px] font-semibold">
            <FormattedMessage id="ask.createPost.suggest" />
          </span>
          <div className="mt-2">
            {creationInfo?.list_suggested_text?.map((item, idx) => (
              <button
                type="button"
                className="flex hover-shadow text-[15px] text-left"
                key={idx}
                onClick={() => handleClickSuggestedText(item)}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            className="text-white w-[142px] p-[10px] h-[44px] mt-4 mb-2 rounded-[8px] normal-case text-[13px] font-medium"
            style={{
              background: (postInfo ? !enableButtonEdit : !enableButtonCreate)
                ? theme.palette.primary.main100
                : theme.palette.primary.main
            }}
            disabled={postInfo ? !enableButtonEdit : !enableButtonCreate}
            onClick={postInfo ? handleEditPost : handleCreatePost}
          >
            {postInfo ? <FormattedMessage id="common.save" /> : <FormattedMessage id="ask.createPost" />}
          </Button>
        </div>
      </div>
      {notification?.isOpenDialog &&
        (notification?.errorCode !== ERROR_CODE.CODE_NOT_ENOUGH_DIAMOND ||
          (notification?.errorCode === ERROR_CODE.CODE_NOT_ENOUGH_DIAMOND &&
            dataCreatePost?.diamond - (dataUser?.member?.diamond as number) > 5)) && (
          <AlertDialog
            open={notification?.isOpenDialog}
            setOpen={() => {}}
            content={`${notification?.message}(${notification?.errorCode})`}
            title={intl.formatMessage({ id: "Dialog.common.title" })}
            buttonOkKey="common.ok"
            triggerFunc={() => {
              if (notification?.errorCode === ERROR_CODE.CODE_NOT_ENOUGH_DIAMOND) {
                if (dataCreatePost?.diamond - (dataUser?.member?.diamond as number) > 5) {
                  router.push("/kim-cuong");
                }
              } else if (
                notification.errorCode === ERROR_CODE.CODE_EDIT_ONLY_ONE ||
                notification?.errorCode === ERROR_CODE.CODE_NOT_ACCEPTABLE_POST_CLOSED
              ) {
                setDataCreatePost(initialDataCreate(creationInfo, gradeUser, "", roomData, {}));
                if (router?.query?.postId) {
                  getPostDetail();
                } else {
                  handleGetListPost(router?.query?.grade);
                }
                handleClose();
              } else if (notification?.errorCode === ERROR_CODE.CODE_NOT_FOUND_ASK_POST) {
                handleClose();
                setDataCreatePost(initialDataCreate(creationInfo, gradeUser, "", roomData, {}));
                if (router?.query?.postId) {
                  router.push("/hoi-dap?tab=thao-luan");
                  handleGetListPost(router?.query?.grade);
                } else {
                  handleGetListPost(router?.query?.grade);
                }
              }
              dispatch(questionAndAnswerAction.handleCloseNotify());
            }}
            isHideCancel
          />
        )}
      <ModalEarnDiamond
        openAlertDialogViewAds={openAlertDialogViewAds}
        setOpenAlertDialogViewAds={setOpenAlertDialogViewAds}
        triggerFunc={() => {
          if (postInfo?.post_id) {
            handleEditPost();
          } else handleCreatePost();
        }}
      />
    </CXModal>
  );
};
export default ModalCreatePost;
