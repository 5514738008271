/* eslint-disable react/require-default-props */
import React from "react";

export const IconHeathy = ({ width, height }: any) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5141 17.3443C10.2307 17.4443 9.76406 17.4443 9.48073 17.3443C7.06406 16.5193 1.66406 13.0776 1.66406 7.24427C1.66406 4.66927 3.73906 2.58594 6.2974 2.58594C7.81406 2.58594 9.15573 3.31927 9.9974 4.4526C10.8391 3.31927 12.1891 2.58594 13.6974 2.58594C16.2557 2.58594 18.3307 4.66927 18.3307 7.24427C18.3307 13.0776 12.9307 16.5193 10.5141 17.3443Z"
        fill="#D82332"
      />
    </svg>
  );
};
const IconHeath = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5141 17.3443C10.2307 17.4443 9.76406 17.4443 9.48073 17.3443C7.06406 16.5193 1.66406 13.0776 1.66406 7.24427C1.66406 4.66927 3.73906 2.58594 6.2974 2.58594C7.81406 2.58594 9.15573 3.31927 9.9974 4.4526C10.8391 3.31927 12.1891 2.58594 13.6974 2.58594C16.2557 2.58594 18.3307 4.66927 18.3307 7.24427C18.3307 13.0776 12.9307 16.5193 10.5141 17.3443Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconHeath;

export const IconHeath16 = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.41203 11.8731C7.18536 11.9531 6.81203 11.9531 6.58536 11.8731C4.65203 11.2131 0.332031 8.45974 0.332031 3.79307C0.332031 1.73307 1.99203 0.0664062 4.0387 0.0664062C5.25203 0.0664062 6.32536 0.653073 6.9987 1.55974C7.67203 0.653073 8.75203 0.0664062 9.9587 0.0664062C12.0054 0.0664063 13.6654 1.73307 13.6654 3.79307C13.6654 8.45974 9.34536 11.2131 7.41203 11.8731Z"
        fill="#D82332"
      />
    </svg>
  );
};
