/* eslint-disable react-hooks/exhaustive-deps */
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { useRouter } from "next/router";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "../../../../public/assets/icons/CloseIcon";
import IconMenuColumn from "../../../../public/assets/icons/IconMenuColumn";
import IconTrashOutLine from "../../../../public/assets/icons/IconTrashOutLine";
import PencilIcon from "../../../../public/assets/icons/PencilIcon";
import WarningIcon from "../../../../public/assets/icons/WarningIcon";
import { genSignature, getNonce } from "../../../utils/helpers/helpers";
import MenuCustom from "../../common/components/MenuCustom";
import ModalConfirm from "../../common/components/ModalConfirm";
import { commonActions } from "../../common/redux/actions";
import { questionAndAnswerAction } from "../redux/actions";
import { SHOW_MENU_CONFIG } from "../utils/constants";
import { IQuestionAndAnswer, TPost } from "../utils/types";
import ModalComplain from "./ModalComplain";
import ModalCreatePost from "./ModalCreatePost";

export default function MenuEditPost({
  postItem,
  userInfo,
  page,
  subjectType
}: {
  postItem: TPost;
  userInfo: any;
  page: string;
  subjectType: any;
}) {
  const router = useRouter();
  const [openClosePost, setOpenClosePost] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openComplainModal, setOpenComplainModal] = React.useState(false);
  const [isOpenModalEdit, setIsOpenModalEdit] = React.useState(false);
  const isOpenModalEditDetail = useSelector(
    (state: { questionAndAnswer: IQuestionAndAnswer }) => state.questionAndAnswer?.isOpenModalEdit
  );
  const intl = useIntl();
  const dispatch = useDispatch();
  const handleOpenModalClosePost = () => {
    setOpenClosePost(true);
  };
  const handleOpenModalDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseModalClosePost = () => {
    setOpenClosePost(false);
  };
  const handleCloseModalDelete = () => {
    setOpenDelete(false);
  };
  const handleOpenComplainModal = () => {
    if (typeof userInfo?.member?.pub_id === "undefined") {
      dispatch(commonActions.handleOpenModalLogin(true));
    } else setOpenComplainModal(true);
  };
  const handleCloseComplainModal = () => {
    setOpenComplainModal(false);
  };
  const handleConfirmClosePost = () => {
    const dataPost = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      post_id: postItem?.post_id
    };
    const params = {
      ...dataPost,
      signature: genSignature(dataPost)
    };
    dispatch(questionAndAnswerAction.handleClosePost(params, () => router.replace(router.asPath)));
  };
  const handleConfirmDeletePost = () => {
    const dataPost = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      post_id: postItem?.post_id
    };
    const params = {
      ...dataPost,
      signature: genSignature(dataPost)
    };
    dispatch(questionAndAnswerAction.handleDeletePost(params));
  };
  const checkShowOption = () => {
    if (postItem?.created_by_member?.member_hid === userInfo.member?.pub_id) {
      if (postItem.edited_times < 1) {
        return [SHOW_MENU_CONFIG.SHOW_EDIT, SHOW_MENU_CONFIG.SHOW_DELETE, SHOW_MENU_CONFIG.SHOW_CLOSE];
      }
      return [SHOW_MENU_CONFIG.SHOW_DELETE, SHOW_MENU_CONFIG.SHOW_CLOSE];
    }
    if (
      userInfo?.member?.pub_id &&
      postItem.report_available &&
      postItem?.created_by_member?.member_hid !== userInfo.member?.pub_id
    ) {
      return [SHOW_MENU_CONFIG.SHOW_COMPLAIN];
    }
    return [SHOW_MENU_CONFIG.DONT_SHOW];
  };
  const handleOpenModalEditPost = () => {
    if (page === "listPost") {
      setIsOpenModalEdit(true);
    }
    if (page === "detailPost") {
      dispatch(questionAndAnswerAction.handleOpenModalEditPost(true));
    }
    // handleCloseComplainModal();
  };
  return (
    <>
      <div>
        <MenuCustom icon={<IconMenuColumn />} className="flex flex-col items-center justify-cente">
          <div>
            {checkShowOption().includes(SHOW_MENU_CONFIG.SHOW_EDIT) && postItem?.num_of_comment < 1 && (
              <MenuItem
                onClick={handleOpenModalEditPost}
                disableRipple
                className="flex gap-4 flex-nowrap justify-start items-center text-[15px] text-black"
              >
                <span className="w-[25px]">
                  <PencilIcon />
                </span>
                <FormattedMessage id="ask.edit" />
              </MenuItem>
            )}
            {checkShowOption().includes(SHOW_MENU_CONFIG.SHOW_CLOSE) && (
              <MenuItem
                onClick={handleOpenModalClosePost}
                disableRipple
                className="flex gap-4 flex-nowrap justify-start items-center text-[15px] text-black"
              >
                <CloseIcon />
                <FormattedMessage id="ask.closeAsk" />
              </MenuItem>
            )}
            {postItem.num_of_comment === 0 && checkShowOption().includes(SHOW_MENU_CONFIG.SHOW_DELETE) && (
              <MenuItem
                onClick={handleOpenModalDelete}
                disableRipple
                className="flex gap-4 flex-nowrap justify-start items-center text-[15px] text-black"
              >
                <IconTrashOutLine />

                <FormattedMessage id="ask.delete" />
              </MenuItem>
            )}
          </div>
          {checkShowOption().includes(SHOW_MENU_CONFIG.SHOW_COMPLAIN) && (
            <MenuItem
              onClick={handleOpenComplainModal}
              disableRipple
              className="flex gap-4 flex-nowrap justify-start items-center text-[15px] text-black"
            >
              <WarningIcon />
              <FormattedMessage id="ask.complain" />
            </MenuItem>
          )}
        </MenuCustom>
        {openClosePost && (
          <ModalConfirm
            handleClose={handleCloseModalClosePost}
            open={openClosePost}
            title="ask.closeAsk"
            content="ask.closeAskConfirm"
            onCancel={handleCloseModalClosePost}
            onConfirm={() => {
              handleConfirmClosePost();
              handleCloseModalClosePost();
            }}
            textConfirm="ask.close"
            styles={{
              width: 97,
              height: 32
            }}
          />
        )}
        {openDelete && (
          <ModalConfirm
            handleClose={handleCloseModalDelete}
            open={openDelete}
            title="ask.deleteAsk"
            content="ask.deleteAskConfirm"
            onCancel={handleCloseModalDelete}
            onConfirm={() => {
              handleConfirmDeletePost();
              handleCloseModalDelete();
            }}
            textConfirm="ask.delete"
            styles={undefined}
          />
        )}
        {openComplainModal && (
          <ModalComplain
            open={openComplainModal}
            handleClose={handleCloseComplainModal}
            title={intl.formatMessage({ id: "ask.complain" })}
            postItem={postItem}
            type="post"
          />
        )}
      </div>
      {isOpenModalEditDetail && page === "detailPost" && (
        <ModalCreatePost
          open={isOpenModalEditDetail}
          handleClose={() => dispatch(questionAndAnswerAction.handleOpenModalEditPost(false))}
          title={intl.formatMessage({ id: "ask.editPost" })}
          userInfo={userInfo}
          postInfo={postItem}
          subjectType={subjectType}
        />
      )}
      {isOpenModalEdit && page === "listPost" && (
        <ModalCreatePost
          open={isOpenModalEdit}
          handleClose={() => setIsOpenModalEdit(false)}
          title={intl.formatMessage({ id: "ask.editPost" })}
          userInfo={userInfo}
          postInfo={postItem}
          subjectType={subjectType}
        />
      )}
    </>
  );
}
