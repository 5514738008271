/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import GoogleAd from "../../../common/components/GoogleAd";
import Ranking from "../../../common/components/Ranking";
import { ROUTES } from "../../../../utils/configs/router";
import { FacebookElement, YoutubeElement } from "../components/Provider";
import { arenaRoomActions } from "../../../arena/redux/actions";
import GoogleAdsScroll from "../../../common/components/GoogleAdsScroll";
import { TArenaState } from "../../../arena/utils/types";
import Introduce from "../components/Introduce";

const LIST_PAGE_SHOW_YOUTUBE_FACEBOOK_BLOCK = [
  ROUTES.taikhoan.diamondhistory.path,
  ROUTES.taikhoan.baidalam.path,
  ROUTES.taikhoan.edit.path,
  ROUTES.taikhoan.path,
  ROUTES.taikhoan.chedoxem.path,
  ROUTES.taikhoan.badgestotal.path,
  ROUTES.thongke.path,
  ROUTES.chagrepoint.path,
  ROUTES.friend.path,
  ROUTES.giftItem.path,
  ROUTES.giftItem.detail.path,
  ROUTES.giftItem.quacuatoi.path,
  ROUTES.timkiem.path
];

function DefaultRightSide({ mainContentHeight }) {
  const location = useRouter();
  const dispatch = useDispatch();
  const providerRef = useRef<any>(null);
  const rightSideRef = useRef<any>(null);
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const listRanking = useSelector((state: { arenaRoom: TArenaState }) => state.arenaRoom.listRanking);
  const isArena = location?.pathname.includes("/thi-online/");
  const pageShowAds =
    LIST_PAGE_SHOW_YOUTUBE_FACEBOOK_BLOCK.includes(location?.pathname) ||
    isArena ||
    location?.pathname.includes("/hoi-dap/");

  const doNotShowFacebook = isArena && location?.pathname.includes("/de-thi");

  const isHomePage = location?.pathname === "/";
  const isShowRanking = location?.query?.grade && location?.query?.subjectsId;
  const lessonPage = location?.pathname?.includes("/ly-thuyet/");

  const fetchListRanking = () => {
    const params = {
      grade: location?.query.subjectsId
    };
    dispatch(arenaRoomActions.handleGetGoldBoard(params));
  };

  useEffect(() => {
    if (location?.query.subjectsId && isShowRanking) {
      fetchListRanking();
    }
  }, [location?.query.subjectsId]);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: 298
      }}
      className="text-black gap-y-4"
      ref={rightSideRef}
    >
      <Box ref={providerRef} className="flex flex-col gap-y-4">
        {isShowRanking && listRanking?.length > 0 && (
          <Box>
            <Ranking listRanking={listRanking} />
          </Box>
        )}
        {/* <Box className="w-[298px] h-[180px]" id="googleAds">
          <GoogleAd
            slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_TITLE}
            style={{ display: "", width: 298, height: 180 }}
          />
        </Box> */}
        {isHomePage && <Introduce />}
        {!doNotShowFacebook && pageShowAds && (
          <Box justifyContent="center" className="sm:hidden sm2:block">
            <FacebookElement />
            <YoutubeElement />
          </Box>
        )}
      </Box>
      {(isHomePage || pageShowAds || lessonPage) &&
        (isHomePage ? (
          <GoogleAdsScroll
            mainContentHeight={mainContentHeight}
            frontBoxHeight={providerRef?.current?.offsetHeight}
            googleAdsHeight={720}
            className="max-h-[720px]"
          />
        ) : (
          <Box className="max-h-[720px]" id="googleAds">
            <GoogleAd
              style={{
                display: "block",
                height: 720,
                width: !matches1100 ? 250 : 298
              }}
              slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_BANNER}
            />
          </Box>
        ))}
    </Box>
  );
}

export default React.memo(DefaultRightSide);
