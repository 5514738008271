import React from "react";

const IconMessage = ({ stroke = "#666666" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66184 10.9991C1.96297 9.82096 1.71822 8.42823 1.97355 7.08242C2.22888 5.73661 2.96672 4.5303 4.04852 3.69001C5.13033 2.84972 6.48168 2.43324 7.84882 2.5188C9.21596 2.60435 10.5048 3.18604 11.4735 4.15464C12.4421 5.12325 13.0238 6.41213 13.1093 7.77927C13.1949 9.14641 12.7784 10.4978 11.9381 11.5796C11.0978 12.6614 9.89152 13.3992 8.54572 13.6546C7.19991 13.9099 5.80718 13.6651 4.62905 12.9663L4.62907 12.9662L2.6863 13.5213C2.60592 13.5443 2.52086 13.5453 2.43994 13.5244C2.35902 13.5034 2.28518 13.4612 2.22607 13.402C2.16696 13.3429 2.12473 13.2691 2.10376 13.1882C2.08279 13.1073 2.08384 13.0222 2.10681 12.9418L2.66189 10.999L2.66184 10.9991Z"
        stroke={stroke || "#666666"}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.19141 13.7411C7.46793 14.5261 7.9151 15.2401 8.50071 15.8314C9.08633 16.4228 9.79581 16.877 10.5781 17.1612C11.3603 17.4454 12.1959 17.5526 13.0245 17.4751C13.8532 17.3975 14.6543 17.1371 15.3703 16.7127L15.3702 16.7127L17.313 17.2677C17.3934 17.2907 17.4784 17.2918 17.5594 17.2708C17.6403 17.2498 17.7141 17.2076 17.7732 17.1485C17.8323 17.0894 17.8746 17.0155 17.8955 16.9346C17.9165 16.8537 17.9155 16.7686 17.8925 16.6883L17.3374 14.7455L17.3375 14.7455C17.8307 13.9138 18.1012 12.9689 18.1229 12.0022C18.1446 11.0354 17.9168 10.0794 17.4614 9.22636C17.0061 8.37329 16.3386 7.65197 15.5233 7.132C14.708 6.61202 13.7725 6.31095 12.8069 6.25781"
        stroke={stroke || "#666666"}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMessage;
