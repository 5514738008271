/* eslint-disable no-else-return */
import * as React from "react";
import {
  FriendIcon,
  AddFriendIcon,
  PendingYourConfirmIcon,
  PendingConfirmIcon,
  FollowingIcon,
  AddFollowIcon
} from "../../../../public/assets/icons/RelationshipFriend";
import IconCompat from "../../../../public/assets/icons/IconCompat";
import IconCup from "../../../../public/assets/icons/IconCup";
import IconMessage from "../../../../public/assets/icons/IconMessage";
import UserCircleIcon from "../../../../public/assets/icons/UserCircleIcon";
import { data } from "./data";

export type TListRelationshipMapping = {
  id: string;
  className: string;
  classNameText: string;
  icon: any;
}[];

export const tabsListFriend = [
  {
    tab: "friend",
    id: "friend.tab.friend"
  },
  {
    tab: "following",
    id: "friend.tab.following"
  }
];
export const listRelationshipMappingAccount: TListRelationshipMapping = [
  {
    id: "friend.relationship.friend",
    className: "bg-gray1",
    classNameText: "text-white",
    icon: <FriendIcon />
  },
  {
    id: "friend.relationship.add",
    className: "bg-primary",
    classNameText: "text-white",
    icon: <AddFriendIcon />
  },
  {
    id: "friend.relationship.following",
    className: "border border-primary200",
    classNameText: "text-primary",
    icon: <FollowingIcon stroke="#D82332" />
  },
  {
    id: "friend.relationship.pendingConfirm",
    className: "bg-gray1",
    classNameText: "text-white",
    icon: <PendingConfirmIcon />
  },
  {
    id: "friend.relationship.pendingYourConfirm",
    className: "bg-primary",
    classNameText: "text-white",
    icon: <PendingYourConfirmIcon />
  },
  {
    id: "friend.relationship.follow",
    className: "border-primary200",
    classNameText: "text-primary",
    icon: <AddFollowIcon />
  }
];

export const getDistricts = (city) => {
  const dataCity = data.filter((item) => item.name === city);
  return dataCity[0]?.districts;
};

export const getSubDistrict = (city, district) => {
  const dataDistrict = getDistricts(city);
  return dataDistrict?.filter((item) => item.name === district)[0]?.wards;
};

export const listBtnAccountInfoPage = [
  {
    title: "account.btn.info",
    width: 152,
    icon: <UserCircleIcon />,
    tab: "info",
    iconActive: <UserCircleIcon stroke="white" />
  },
  {
    title: "account.btn.achievements",
    width: 158,
    icon: <IconCup />,
    tab: "achievements",
    iconActive: <IconCup stroke="white" />
  },
  {
    title: "common.bottombar.arena",
    width: 108,
    icon: <IconCompat />,
    tab: "compat",
    iconActive: <IconCompat stroke="white" />
  },
  {
    title: "Question_and_Answer",
    width: 94,
    icon: <IconMessage />,
    tab: "answer",
    iconActive: <IconMessage stroke="white" />
  }
];

export const GENDER_UNDISCLOSED = -1;
export const GENDER_FEMALE = 0;
export const GENDER_MALE = 1;
export const GENDER_OTHER = 2;

export const getGenderByNumber = (num: number) => {
  if (num === GENDER_MALE) {
    return "Gender_option.male";
  }
  if (num === GENDER_FEMALE) {
    return "Gender_option.female";
  }
  if (num === GENDER_OTHER) {
    return "Gender_option.other";
  }
  return "Gender_option.undisclosed";
};

export const getBirthDay = (date: string) => {
  const year = date?.slice(0, 4);
  const month = date?.slice(4, 6);
  const day = date?.slice(6, 8);
  return `${day}/${month}/${year}`;
};

export const compareTwoGrade = (slug: string, gradeVN: string) => {
  const slugArr = slug.split("-");
  const gradeVNArr = gradeVN.toLocaleLowerCase().split(" ");
  return slugArr[slugArr.length - 1] === gradeVNArr[gradeVNArr.length - 1];
};

export const USE_NICKNAME = 1;
export const USE_NAME = 0;
export const LIST_ERROR_CODE_GIFT_DIAMOND = ["ETMTIM", "EOLGDM", "EOLGDO", "ELPGDI", "ENAGFY"];

export const listLanguage = {
  vi: "Setting.language_options.vn",
  en: "Setting.language_options.en",
  ja: "Setting.language_options.ja"
};

export const LAST_DAY_DAILY_CHECKIN = 7;

export const SHOW_MAXIMUM_EXERCISE = 5;
export const SHOW_MAXIMUM_GIFT_CODE = 10;
export const SHOW_MAXIMUM_CARDS = 10;
export const SHOW_MAXIMUM_FRIENDS = 10;
export const SHOW_MAXIMUM_ROOMS = 10;

export const SUBJECT_STATUS = {
  PROGRESS: "progress",
  DONE: "done"
};

export const LIST_ACTION_FRIEND_UPDATE_UI = [
  "GIFT_DIAMOND_SUCCESS",
  "REMOVE_FRIEND_SUCCESS",
  "DELETE_FOLLOW_SUCCESS",
  "FOLLOW_FRIEND_SUCCESS"
];

export const LIST_ERROR_CODE_RELOAD_MISSIONS = ["ENACLM"];
