import React from "react";

const IconPinned = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.9987 11.334L8.66536 10.0007V6.57265C9.8127 6.27465 10.6654 5.23932 10.6654 4.00065C10.6654 2.52998 9.46936 1.33398 7.9987 1.33398C6.52803 1.33398 5.33203 2.52998 5.33203 4.00065C5.33203 5.23932 6.1847 6.27465 7.33203 6.57265V10.0007L7.9987 11.334ZM6.66536 4.00065C6.66536 3.26532 7.26336 2.66732 7.9987 2.66732C8.73403 2.66732 9.33203 3.26532 9.33203 4.00065C9.33203 4.73598 8.73403 5.33398 7.9987 5.33398C7.26336 5.33398 6.66536 4.73598 6.66536 4.00065Z"
        fill="#666666"
      />
      <path
        d="M10.8434 7.04168L10.488 8.32702C12.2154 8.80435 13.332 9.72235 13.332 10.6663C13.332 11.9277 11.142 13.333 7.9987 13.333C4.85536 13.333 2.66536 11.9277 2.66536 10.6663C2.66536 9.72235 3.78203 8.80435 5.51003 8.32635L5.1547 7.04102C2.7967 7.69302 1.33203 9.08168 1.33203 10.6663C1.33203 12.909 4.2607 14.6663 7.9987 14.6663C11.7367 14.6663 14.6654 12.909 14.6654 10.6663C14.6654 9.08168 13.2007 7.69302 10.8434 7.04168Z"
        fill="#666666"
      />
    </svg>
  );
};

export default IconPinned;
