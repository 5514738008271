/* eslint-disable react/require-default-props */
import React from "react";

export default function IconQuestion({
  width,
  height
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.332 13.3337H8.66536H6.9987C7.33203 13.3337 5.69869 13.3337 5.69869 13.3337H4.66536C2.66536 13.3337 1.33203 12.0004 1.33203 10.0004V6.00033C1.33203 4.00033 2.66536 2.66699 4.66536 2.66699H11.332C13.332 2.66699 14.6654 4.00033 14.6654 6.00033V10.0004C14.6654 12.0004 13.332 13.3337 11.332 13.3337Z"
        stroke="#F79009"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00118 8.62012V8.48014C8.00118 8.02681 8.2812 7.7868 8.5612 7.59347C8.83453 7.4068 9.10783 7.16681 9.10783 6.72681C9.10783 6.11347 8.61451 5.62012 8.00118 5.62012C7.38785 5.62012 6.89453 6.11347 6.89453 6.72681"
        stroke="#F79009"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9983 10.2132H8.0043"
        stroke="#F79009"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
