/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import AccountInfo from "../../../common/components/AccountInfo";
import Exams from "../../../common/components/Exams";
import Lessons from "../../../common/components/Lessons";
import ListSubjects from "../../../common/components/ListSubjects";
import ListSubjectsLessonPage from "../../../common/components/ListSubjectsLessonPage";
import { PUBLIC_LAYOUT, ROUTES } from "../../../../utils/configs/router";
import { commonActions } from "../../../common/redux/actions";
import { TCommonState } from "../../../common/utils/type";
import ListPageDiamond from "../components/ListPageDiamond";
import GoogleAdsScroll from "../../../common/components/GoogleAdsScroll";
import GoogleAd from "../../../common/components/GoogleAd";
import FriendLeftSide from "../../../friends/components/FriendLeftSide";

const LIST_SHOW_ACCOUNT_CARD = [ROUTES.giftItem.path, ROUTES.giftItem.detail.path, ROUTES.giftItem.quacuatoi.path];

const LIST_SHOW_GOOGLE_AD = [
  ROUTES.taikhoan.diamondhistory.path,
  ROUTES.taikhoan.baidalam.path,
  ROUTES.taikhoan.edit.path,
  ROUTES.taikhoan.path,
  ROUTES.taikhoan.badgestotal.path
];
const LIST_SHOW_LIST_SUBJECT = [
  ROUTES.taikhoan.edit.path,
  ROUTES.taikhoan.path,
  ROUTES.taikhoan.chedoxem.path,
  ROUTES.taikhoan.badgestotal.path,
  ROUTES.subjects.path,
  ROUTES.thongke.path,
  ROUTES.topics.baitap.path,
  ROUTES.topics.dethi.path,
  ROUTES.taikhoan.baidalam.path,
  "/tin-tuc",
  "/tin-tuc/[newsId]",
  ROUTES.timkiem.path,
  ROUTES.topics.hantu.path,
  ROUTES.topics.maucau.path
];

type TProps = {
  handleOpenModalImage: () => void;
  // eslint-disable-next-line react/require-default-props
  mainContentHeight?: any;
};

function DefaultLeftSide({ handleOpenModalImage, mainContentHeight }: TProps) {
  const { learningHistory, grade } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const dataUserInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const dispatch = useDispatch();
  const router = useRouter();
  const isHomePage = router?.pathname === "/";
  const isShowAccountCard = LIST_SHOW_ACCOUNT_CARD.includes(router?.pathname);
  const refBox = useRef<any>(null);
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const fetchExamLessonHistoryList = () => {
    dispatch(commonActions.handleGetLearningHistory());
  };
  const isShowListSubjectsDetail = router?.pathname.includes("/ly-thuyet/");
  const isShowListSubjects =
    LIST_SHOW_LIST_SUBJECT.includes(router?.pathname) ||
    router?.pathname.includes("/thi-online") ||
    router?.pathname.includes("/hoi-dap") ||
    router?.pathname?.includes("/the");
  const isDoNotShowGoogleAd = LIST_SHOW_GOOGLE_AD?.includes(router?.pathname);

  const cardList = learningHistory?.cards?.items?.filter((item) => item?.grade_slug === grade);
  const examsList = learningHistory?.exams?.items?.filter((item) => item?.grade_slug === grade);

  const isHideExamsHistory = router.pathname === ROUTES.taikhoan.baidalam.path;
  const isDiamondPage = router.pathname.includes(ROUTES.chagrepoint.path);
  const isFriendPage = router.pathname.includes(ROUTES.friend.path);
  const isPublicPage = PUBLIC_LAYOUT.map((e) => e.path)?.includes(router.asPath.replace(/^\/|\/$/g, "")); // remove splash trong router

  useEffect(() => {
    if (!!dataUserInfos?.member && !isDiamondPage && !isFriendPage && !isPublicPage) {
      const timeoutId = setTimeout(() => {
        fetchExamLessonHistoryList();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        top: 20
      }}
      className="text-black md:h-full sm:h-full static mb-6"
    >
      {isFriendPage && <FriendLeftSide hiddenSearch />}
      {isDiamondPage && <ListPageDiamond />}
      <div className="z-[999]" ref={refBox as any}>
        {(isHomePage || isShowAccountCard) && (
          <Box className="z-10">
            <AccountInfo handleOpenModalImage={handleOpenModalImage} />
            <div className="py-[16px] w-full">
              <Divider sx={{ borderBottomWidth: 3 }} />
            </div>
          </Box>
        )}
        {isShowListSubjects && (
          <Box className="z-10">
            <ListSubjects />
            <div className="py-[16px]">
              <Divider sx={{ borderBottomWidth: 3 }} />
            </div>
          </Box>
        )}
        {isShowListSubjectsDetail && (
          <Box className="z-10">
            <ListSubjectsLessonPage />
          </Box>
        )}
        {!!dataUserInfos?.member && cardList?.length > 0 && !isDiamondPage && !isFriendPage && (
          <Box className="z-10">
            <Lessons cardList={cardList} />
            <div className="py-[16px]">
              <Divider sx={{ borderBottomWidth: 3 }} />
            </div>
          </Box>
        )}
        {!isHideExamsHistory && !!dataUserInfos?.member && examsList?.length > 0 && !isDiamondPage && !isFriendPage && (
          <Box className="z-10">
            <Exams examsList={examsList} />
          </Box>
        )}
      </div>
      {!isDoNotShowGoogleAd &&
        !isDiamondPage &&
        (isHomePage ? (
          <GoogleAdsScroll
            mainContentHeight={mainContentHeight}
            frontBoxHeight={refBox?.current?.offsetHeight}
            googleAdsHeight={842}
            className="max-h-[842px] z-[1] flex justify-center my-2"
          />
        ) : (
          <Box className="max-h-[842px] z-[1] flex justify-center my-2" id="googleAds">
            <GoogleAd
              style={{
                display: "block",
                height: 842,
                width: !matches1100 ? 250 : 298
              }}
              slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_BANNER}
            />
          </Box>
        ))}
    </div>
  );
}
export default React.memo(DefaultLeftSide);
