import React from 'react';
import SearchIcon from '../../../../public/assets/icons/SearchIcon';

export default function SearchInput({ placeholder, onSubmit, onChange, value }) {
  const handlePressEnter = (event) => {
    if (event?.key === 'Enter') {
      onSubmit();
    }
  };
  return (
    <div className="flex relative">
      <input
        value={value}
        placeholder={placeholder}
        className="rounded-[20px] border-[1px] border-gray sm:w-full sm2:w-[240px] xl:w-[350px] h-[34px] px-3 py-1 outline-primary200"
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handlePressEnter}
      />
      <button
        type="button"
        className="absolute right-3 top-1"
        onClick={onSubmit}
      >
        <SearchIcon />
      </button>
    </div>
  );
}
