import React from "react";

const LockIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7.5" fill="white" stroke="#B00020" />
      <g clipPath="url(#clip0_4180_84888)">
        <path
          d="M10.7283 6.61194V6.05816C10.7283 5.33618 10.4415 4.64377 9.93098 4.13326C9.42046 3.62274 8.72805 3.33594 8.00608 3.33594C7.2841 3.33594 6.59169 3.62274 6.08117 4.13326C5.57066 4.64377 5.28385 5.33618 5.28385 6.05816V6.61194C4.93749 6.7631 4.64269 7.01192 4.43549 7.32796C4.2283 7.64401 4.11768 8.01358 4.11719 8.39149V10.7248C4.11781 11.2403 4.32286 11.7346 4.68738 12.0991C5.0519 12.4636 5.54612 12.6687 6.06163 12.6693H9.95052C10.466 12.6687 10.9602 12.4636 11.3248 12.0991C11.6893 11.7346 11.8943 11.2403 11.895 10.7248V8.39149C11.8945 8.01358 11.7839 7.64401 11.5767 7.32796C11.3695 7.01192 11.0747 6.7631 10.7283 6.61194ZM6.06163 6.05816C6.06163 5.54246 6.26649 5.04788 6.63115 4.68323C6.9958 4.31858 7.49038 4.11372 8.00608 4.11372C8.52178 4.11372 9.01635 4.31858 9.38101 4.68323C9.74566 5.04788 9.95052 5.54246 9.95052 6.05816V6.44705H6.06163V6.05816ZM11.1172 10.7248C11.1172 11.0342 10.9943 11.331 10.7755 11.5498C10.5567 11.7686 10.2599 11.8915 9.95052 11.8915H6.06163C5.75221 11.8915 5.45547 11.7686 5.23667 11.5498C5.01788 11.331 4.89497 11.0342 4.89497 10.7248V8.39149C4.89497 8.08207 5.01788 7.78533 5.23667 7.56653C5.45547 7.34774 5.75221 7.22483 6.06163 7.22483H9.95052C10.2599 7.22483 10.5567 7.34774 10.7755 7.56653C10.9943 7.78533 11.1172 8.08207 11.1172 8.39149V10.7248Z"
          fill="#B00020"
        />
        <path
          d="M8.00608 8.78125C7.90294 8.78125 7.80402 8.82222 7.73109 8.89515C7.65816 8.96808 7.61719 9.067 7.61719 9.17014V9.94792C7.61719 10.0511 7.65816 10.15 7.73109 10.2229C7.80402 10.2958 7.90294 10.3368 8.00608 10.3368C8.10922 10.3368 8.20814 10.2958 8.28107 10.2229C8.354 10.15 8.39497 10.0511 8.39497 9.94792V9.17014C8.39497 9.067 8.354 8.96808 8.28107 8.89515C8.20814 8.82222 8.10922 8.78125 8.00608 8.78125Z"
          fill="#B00020"
        />
      </g>
      <defs>
        <clipPath id="clip0_4180_84888">
          <rect
            width="9.33333"
            height="9.33333"
            fill="white"
            transform="translate(3.33594 3.33594)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LockIcon;
