import React from "react";

export const IconPremiumSmall = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.4229" cy="10" r="9.5" fill="#D82332" stroke="white" />
      <path
        d="M12.772 13.4897H8.07197C7.86197 13.4897 7.62697 13.3247 7.55697 13.1247L5.48697 7.33469C5.19197 6.50469 5.53697 6.24969 6.24697 6.75969L8.19697 8.15469C8.52197 8.37969 8.89197 8.26469 9.03197 7.89969L9.91197 5.55469C10.192 4.80469 10.657 4.80469 10.937 5.55469L11.817 7.89969C11.957 8.26469 12.327 8.37969 12.647 8.15469L14.477 6.84969C15.257 6.28969 15.632 6.57469 15.312 7.47969L13.292 13.1347C13.217 13.3247 12.982 13.4897 12.772 13.4897Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.67188 15H13.1719" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.17188 11H11.6719" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const IconPremium24 = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" fill="url(#paint0_linear_8050_134459)" stroke="white" />
      <path
        d="M14.8141 16.1853H9.17406C8.92206 16.1853 8.64006 15.9873 8.55606 15.7473L6.07206 8.79928C5.71806 7.80328 6.13206 7.49728 6.98406 8.10928L9.32406 9.78328C9.71406 10.0533 10.1581 9.91528 10.3261 9.47728L11.3821 6.66328C11.7181 5.76328 12.2761 5.76328 12.6121 6.66328L13.6681 9.47728C13.8361 9.91528 14.2801 10.0533 14.6641 9.78328L16.8601 8.21728C17.7961 7.54528 18.2461 7.88728 17.8621 8.97328L15.4381 15.7593C15.3481 15.9873 15.0661 16.1853 14.8141 16.1853Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.69531 17.9961H15.2953" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 13.1953H13.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_8050_134459" x1="12" y1="0" x2="12" y2="24" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D82332" />
          <stop offset="1" stopColor="#D82332" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IconPremium = ({ width, height }: any) => {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill="#D82332" stroke="white" />
      <path
        d="M19.7586 21.5843H12.2386C11.9026 21.5843 11.5266 21.3203 11.4146 21.0003L8.1026 11.7363C7.6306 10.4083 8.1826 10.0003 9.3186 10.8163L12.4386 13.0483C12.9586 13.4083 13.5506 13.2243 13.7746 12.6403L15.1826 8.88828C15.6306 7.68828 16.3746 7.68828 16.8226 8.88828L18.2306 12.6403C18.4546 13.2243 19.0466 13.4083 19.5586 13.0483L22.4866 10.9603C23.7346 10.0643 24.3346 10.5203 23.8226 11.9683L20.5906 21.0163C20.4706 21.3203 20.0946 21.5843 19.7586 21.5843Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.5977 24H20.3977" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 17.6006H18" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconPremium;
