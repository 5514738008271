import React from "react";

const IconCup = ({ stroke = "#666666", width = "20", height = "20" }) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.125 13.75V15.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.95703 18.3359H14.2904V17.5026C14.2904 16.5859 13.5404 15.8359 12.6237 15.8359H7.6237C6.70703 15.8359 5.95703 16.5859 5.95703 17.5026V18.3359V18.3359Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M5.125 18.3359H15.125"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0013 13.3307C6.7763 13.3307 4.16797 10.7224 4.16797 7.4974V4.9974C4.16797 3.15573 5.65964 1.66406 7.5013 1.66406H12.5013C14.343 1.66406 15.8346 3.15573 15.8346 4.9974V7.4974C15.8346 10.7224 13.2263 13.3307 10.0013 13.3307Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.55755 9.70833C3.93255 9.50833 3.38255 9.14167 2.94922 8.70833C2.19922 7.875 1.69922 6.875 1.69922 5.70833C1.69922 4.54167 2.61589 3.625 3.78255 3.625H4.32422C4.15755 4.00833 4.07422 4.43333 4.07422 4.875V7.375C4.07422 8.20833 4.24922 8.99167 4.55755 9.70833Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4414 9.70833C16.0664 9.50833 16.6164 9.14167 17.0497 8.70833C17.7997 7.875 18.2997 6.875 18.2997 5.70833C18.2997 4.54167 17.3831 3.625 16.2164 3.625H15.6747C15.8414 4.00833 15.9247 4.43333 15.9247 4.875V7.375C15.9247 8.20833 15.7497 8.99167 15.4414 9.70833Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCup;
