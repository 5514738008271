import React from "react";

export default function AddCircleOutlinedIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.835938 8C0.835937 6.94943 1.04286 5.90914 1.4449 4.93853C1.84694 3.96793 2.43621 3.08601 3.17908 2.34315C3.92195 1.60028 4.80387 1.011 5.77447 0.608964C6.74507 0.206926 7.78536 0 8.83594 0C9.88651 0 10.9268 0.206926 11.8974 0.608964C12.868 1.011 13.7499 1.60028 14.4928 2.34315C15.2357 3.08601 15.8249 3.96793 16.227 4.93853C16.629 5.90914 16.8359 6.94943 16.8359 8C16.8359 10.1217 15.9931 12.1566 14.4928 13.6569C12.9925 15.1571 10.9577 16 8.83594 16C6.71421 16 4.67937 15.1571 3.17908 13.6569C1.67879 12.1566 0.835938 10.1217 0.835938 8ZM8.83594 4C8.65913 4 8.48956 4.07024 8.36453 4.19526C8.23951 4.32029 8.16927 4.48986 8.16927 4.66667V7.33333H5.5026C5.32579 7.33333 5.15622 7.40357 5.0312 7.5286C4.90618 7.65362 4.83594 7.82319 4.83594 8C4.83594 8.17681 4.90618 8.34638 5.0312 8.47141C5.15622 8.59643 5.32579 8.66667 5.5026 8.66667H8.16927V11.3333C8.16927 11.5101 8.23951 11.6797 8.36453 11.8047C8.48956 11.9298 8.65913 12 8.83594 12C9.01275 12 9.18232 11.9298 9.30734 11.8047C9.43237 11.6797 9.5026 11.5101 9.5026 11.3333V8.66667H12.1693C12.3461 8.66667 12.5157 8.59643 12.6407 8.47141C12.7657 8.34638 12.8359 8.17681 12.8359 8C12.8359 7.82319 12.7657 7.65362 12.6407 7.5286C12.5157 7.40357 12.3461 7.33333 12.1693 7.33333H9.5026V4.66667C9.5026 4.48986 9.43237 4.32029 9.30734 4.19526C9.18232 4.07024 9.01275 4 8.83594 4Z"
        fill="#D82332"
      />
    </svg>
  );
}
