import React from "react";

export default function LargeLogo() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9948 39.6408C18.6786 39.6408 17.358 39.5078 16.0773 39.2463C13.3873 38.701 10.8834 37.6195 8.64545 36.0237C7.45777 35.1815 6.36317 34.2064 5.38821 33.1204C2.52539 29.9333 0.810351 26.0016 0.442527 21.7418C0.393779 21.1523 0.367189 20.5627 0.367189 19.9998C0.362758 9.17534 9.17281 0.363281 19.9948 0.363281C30.8168 0.363281 39.6224 9.17091 39.6224 19.9954C39.6224 30.8198 30.8168 39.6363 19.9948 39.6363V39.6408ZM24.2403 6.01488C24.2137 6.01488 24.1916 6.01488 24.1738 6.01931C24.0054 6.05477 23.8858 6.20105 23.8858 6.36949C23.8858 6.56452 24.0453 6.7241 24.2403 6.7241C24.2891 6.7241 24.3334 6.71523 24.3688 6.6975C24.5151 6.63544 24.5948 6.51133 24.5948 6.36949C24.5948 6.17445 24.4353 6.01488 24.2359 6.01488H24.2403Z"
        fill="white"
      />
      <path
        d="M19.9954 0.731383C30.6358 0.731383 39.2597 9.35727 39.2597 20C39.2597 30.6427 30.6358 39.2731 19.9954 39.2731C18.6793 39.2731 17.3941 39.1401 16.1532 38.8874C13.481 38.3466 11.0037 37.2518 8.8588 35.727C7.68885 34.898 6.6164 33.9406 5.6636 32.8768C2.95146 29.8626 1.17881 25.984 0.806554 21.711C0.757806 21.1481 0.731216 20.5762 0.731216 20C0.731216 9.35727 9.35514 0.731383 19.9954 0.731383ZM24.2409 7.0922C24.3429 7.0922 24.4448 7.07004 24.5334 7.02571C24.786 6.91933 24.9633 6.66667 24.9633 6.36968C24.9633 5.97075 24.6398 5.64716 24.2409 5.64716C24.1922 5.64716 24.1434 5.6516 24.0991 5.66046C23.7668 5.72695 23.5186 6.0195 23.5186 6.36968C23.5186 6.76862 23.8421 7.0922 24.2409 7.0922ZM19.9954 0C8.96959 0 0 8.97163 0 20C0 20.5807 0.0265897 21.1746 0.0797691 21.7731C0.456456 26.1126 2.20251 30.1197 5.12295 33.3644C6.1112 34.4681 7.22796 35.4654 8.4378 36.3209C10.7201 37.9433 13.2683 39.047 16.0114 39.6011C17.3143 39.867 18.6571 40 19.9999 40C31.0257 40 39.9953 31.0239 39.9953 19.9956C39.9953 8.9672 31.0213 0 19.9954 0Z"
        fill="#D82332"
      />
      <path
        d="M25.0737 4.26367C21.0542 4.26367 17.8768 7.72555 17.8768 7.72555C17.8768 7.72555 18.2667 8.33725 18.2667 9.0642C18.2667 9.79115 17.4868 11.0899 17.4336 11.1786C17.5444 11.1431 19.2949 10.5314 19.9951 9.23264H20.8903C21.5018 9.23264 26.6336 8.56332 28.7564 4.82218C28.7564 4.82218 27.9188 4.26367 25.0737 4.26367ZM24.2406 7.09169C23.8417 7.09169 23.5182 6.7681 23.5182 6.36917C23.5182 6.01899 23.7664 5.72644 24.0987 5.65995C24.1431 5.65108 24.1918 5.64665 24.2406 5.64665C24.6394 5.64665 24.9629 5.97023 24.9629 6.36917C24.9629 6.66615 24.7856 6.91881 24.533 7.0252C24.4444 7.06952 24.3425 7.09169 24.2406 7.09169Z"
        fill="#D82332"
      />
      <path
        d="M18.7273 6.23235C18.7273 6.23235 18.1423 5.89551 17.4996 5.89551C16.857 5.89551 15.7578 6.23235 15.7578 6.23235C15.7578 6.23235 17.1406 6.66669 17.4331 7.27833C17.4331 7.27833 17.934 6.55146 18.7318 6.23235H18.7273Z"
        fill="#D82332"
      />
      <path
        d="M5.9375 26.7551C5.9375 30.8597 7.27142 33.7453 8.85793 35.7267C11.0028 37.2515 13.4801 38.3464 16.1524 38.8872C13.1788 36.9413 9.56256 33.2489 9.56256 26.8083C9.56256 15.9572 17.3046 9.4457 17.4287 9.34375C13.356 11.1301 5.9375 16.6531 5.9375 26.7507V26.7551Z"
        fill="#BC051E"
      />
      <path
        d="M17.0593 8.16895C17.0593 8.16895 5.74541 10.5448 0.808594 21.7106C1.18528 25.9837 2.9535 29.8622 5.66564 32.8764C6.62287 33.9402 7.69089 34.8977 8.86084 35.7266C7.27432 33.7452 5.9404 30.8551 5.9404 26.7549C5.9404 16.6574 13.3589 11.1299 17.4316 9.34802L17.0593 8.17338V8.16895Z"
        fill="#F34D53"
      />
      <path
        d="M18.1961 26.1903C17.8903 26.4961 17.4738 26.6513 16.9508 26.6513H13.8088C13.5872 26.6513 13.4144 26.5848 13.2815 26.4518C13.1485 26.3188 13.082 26.1415 13.082 25.9243C13.082 25.7071 13.1485 25.5343 13.2859 25.4057C13.4188 25.2727 13.5961 25.2107 13.8132 25.2107H17.1148C17.1148 25.2107 17.1237 24.4394 17.1148 22.8924V20.6628H15.8385C15.7321 20.6628 15.6302 20.6451 15.5416 20.6096C15.4529 20.5742 15.3776 20.5254 15.3111 20.4589C15.2447 20.3924 15.1959 20.3171 15.1605 20.2328C15.125 20.1442 15.1073 20.0511 15.1073 19.9447C15.1073 19.7275 15.1738 19.5547 15.3111 19.4172C15.4485 19.2798 15.6213 19.2178 15.8385 19.2178H17.8726C17.9657 19.2178 18.0587 19.2355 18.1429 19.2754C18.2316 19.3153 18.3069 19.3685 18.3778 19.4305C18.4487 19.4926 18.5019 19.5724 18.5418 19.6566C18.5817 19.7408 18.6038 19.8339 18.6038 19.9314V25.0245C18.6038 25.5298 18.4664 25.9199 18.1961 26.1903Z"
        fill="#D82332"
      />
      <path
        d="M20.3971 20.0201V25.2639H23.0782C23.1846 25.2639 23.2776 25.2816 23.3663 25.3171C23.4549 25.3525 23.5302 25.4013 23.5923 25.4633C23.6543 25.5254 23.7031 25.5963 23.7429 25.685C23.7784 25.7736 23.7961 25.8667 23.7961 25.9731C23.7961 26.4252 23.5524 26.6469 23.0693 26.6469H20.4503C19.4354 26.6469 18.9258 26.1371 18.9258 25.122V20.0866C18.9258 19.5059 19.1828 19.2178 19.6969 19.2178C19.7944 19.2178 19.8874 19.2399 19.9716 19.2798C20.0558 19.3197 20.1312 19.3773 20.1932 19.4483C20.2553 19.5192 20.304 19.6034 20.3439 19.7009C20.3794 19.7984 20.3971 19.9048 20.3971 20.0201Z"
        fill="#D82332"
      />
      <path
        d="M27.9776 23.6613H25.5801V25.9308C25.5801 26.148 25.5092 26.3209 25.3719 26.4538C25.2345 26.5824 25.0528 26.6489 24.8401 26.6489C24.6273 26.6489 24.4457 26.5824 24.3127 26.445C24.1798 26.312 24.1133 26.1391 24.1133 25.9308V20.8288C24.1133 20.3767 24.2728 19.9911 24.5963 19.6808C24.9198 19.3661 25.3054 19.2109 25.753 19.2109H27.9821C28.4385 19.2109 28.8285 19.3661 29.1565 19.6808C29.4755 19.9911 29.6395 20.3723 29.6395 20.8288V22.0257C29.6395 22.4866 29.48 22.8723 29.1565 23.1826C28.8285 23.5017 28.4385 23.6613 27.9821 23.6613H27.9776ZM25.5801 22.2118H28.1505V20.6471H25.5801V22.2118Z"
        fill="#D82332"
      />
      <path
        d="M31.5712 19.2227H34.7221C34.9392 19.2227 35.1165 19.2891 35.2494 19.4266C35.3868 19.5595 35.4533 19.7368 35.4533 19.954C35.4533 20.0604 35.4356 20.1535 35.4001 20.2422C35.3646 20.3308 35.3115 20.4062 35.245 20.4682C35.1785 20.5303 35.0988 20.5835 35.0101 20.6189C34.9215 20.6544 34.824 20.6721 34.7176 20.6721L33.4236 20.6633V25.9514C33.4236 26.0578 33.4059 26.1553 33.3704 26.2439C33.335 26.3326 33.2818 26.4079 33.2197 26.47C33.1577 26.5321 33.0824 26.5808 32.9937 26.6163C32.9051 26.6517 32.8076 26.6695 32.7013 26.6695C32.4797 26.6695 32.3024 26.603 32.165 26.4656C32.1251 26.4257 32.0897 26.3636 32.0542 26.2794C32.0188 26.1952 31.9878 26.0844 31.9612 25.9514V20.6766L31.5845 20.6677L30.676 20.6766C30.5696 20.6766 30.4721 20.6588 30.3835 20.6234C30.2949 20.5879 30.2195 20.5391 30.1531 20.4727C30.0866 20.4062 30.0378 20.3308 30.0024 20.2466C29.9669 20.1579 29.9492 20.0649 29.9492 19.9585C29.9492 19.7413 30.0157 19.5684 30.1531 19.431C30.286 19.298 30.4633 19.2271 30.6804 19.2271H31.5712V19.2227Z"
        fill="#D82332"
      />
      <path
        d="M18.2996 8.29243C18.2376 7.96442 18.1667 7.46797 18.2465 7.35715C18.3572 7.24634 18.7827 6.85626 19.381 6.40414C19.3987 6.79864 19.5095 7.45024 19.5715 8.00431C19.6114 8.29243 19.864 8.43428 20.4534 8.36336C21.0428 8.288 21.3929 8.02204 21.322 7.71619C21.158 6.99368 20.9364 5.93871 20.9497 5.40236C21.6233 5.03889 22.0576 4.86602 22.8243 4.60449C22.9706 5.28712 23.232 6.45733 23.4447 7.2552C23.6309 7.95999 22.3368 8.96176 20.6041 9.05041C18.8979 9.13907 18.344 8.57612 18.2952 8.29687L18.2996 8.29243Z"
        fill="white"
      />
      <path
        d="M26.5731 4.60032C25.6689 4.60919 25.1059 4.68011 23.918 5.03472C23.9712 5.25635 24.0332 5.46468 24.0953 5.65972C24.1396 5.65085 24.1884 5.64642 24.2371 5.64642C24.6361 5.64642 24.9596 5.97 24.9596 6.36894C24.9596 6.66592 24.7823 6.91858 24.5297 7.02497C24.6095 7.29536 24.7424 7.89376 24.7868 8.20848C25.558 7.85387 25.9525 7.61894 26.3692 7.33525C26.3692 7.11362 26.3559 6.8388 26.316 6.59057C26.316 6.59057 26.6573 6.42656 27.1183 6.24039C27.5793 6.05422 28.1467 5.42479 28.1024 5.11894C28.0536 4.7599 27.5261 4.58702 26.5687 4.60032H26.5731ZM26.6219 5.69961C26.4534 5.76167 26.3648 5.79713 26.1786 5.86362C26.1077 5.58436 26.0589 5.43809 25.9614 5.14997C26.1476 5.10564 26.2407 5.08348 26.418 5.04802C26.9322 4.9372 27.2291 4.99483 27.2912 5.1677C27.3533 5.345 27.1228 5.51787 26.6219 5.69961Z"
        fill="white"
      />
      <path
        d="M24.2356 7.20163C23.7746 7.20163 23.4023 6.82933 23.4023 6.36839C23.4023 5.97393 23.686 5.62823 24.0716 5.55288C24.1248 5.54402 24.178 5.53516 24.2356 5.53516C24.6965 5.53516 25.0688 5.90745 25.0688 6.36839C25.0688 6.7008 24.8738 6.99775 24.5724 7.12628C24.4749 7.17504 24.3597 7.20163 24.2356 7.20163Z"
        fill="#BF3737"
      />
      <path
        d="M24.237 5.64739C24.6359 5.64739 24.9594 5.97093 24.9594 6.36982C24.9594 6.66677 24.7822 6.9194 24.5295 7.02577C24.4409 7.07009 24.3389 7.09225 24.237 7.09225C23.8381 7.09225 23.5146 6.76871 23.5146 6.36982C23.5146 6.01968 23.7628 5.72716 24.0952 5.66068C24.1395 5.65182 24.1883 5.64739 24.237 5.64739ZM24.237 5.42578C24.175 5.42578 24.1085 5.43021 24.0509 5.44351C23.6121 5.53215 23.293 5.92218 23.293 6.36982C23.293 6.88838 23.7185 7.31386 24.237 7.31386C24.3744 7.31386 24.5118 7.28283 24.627 7.22522C24.9594 7.08782 25.181 6.75098 25.181 6.36982C25.181 5.85126 24.7556 5.42578 24.237 5.42578Z"
        fill="white"
      />
      <path
        d="M17.1596 9.41091C17.5022 9.41091 17.7801 9.13311 17.7801 8.79042C17.7801 8.44773 17.5022 8.16992 17.1596 8.16992C16.8169 8.16992 16.5391 8.44773 16.5391 8.79042C16.5391 9.13311 16.8169 9.41091 17.1596 9.41091Z"
        fill="#D82332"
      />
      <path
        d="M6.03147 28.5684C6.03147 28.5684 6.52337 34.1136 10.37 36.6978C14.2167 39.282 10.0332 37.3494 10.0332 37.3494L5.19832 33.1428L4.54688 28.9806L6.02703 28.5684H6.03147Z"
        fill="#F34D53"
      />
    </svg>
  );
}
