import React from "react";

export default function IconSubject({ width, height }: any) {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00065 14.6663H10.0007C13.334 14.6663 14.6673 13.333 14.6673 9.99967V5.99967C14.6673 2.66634 13.334 1.33301 10.0007 1.33301H6.00065C2.66732 1.33301 1.33398 2.66634 1.33398 5.99967V9.99967C1.33398 13.333 2.66732 14.6663 6.00065 14.6663Z"
        stroke="#D82332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3327 1.62695V8.28029C11.3327 9.59362 10.3927 10.107 9.23935 9.41362L8.35935 8.88695C8.15935 8.76695 7.83935 8.76695 7.63935 8.88695L6.75935 9.41362C5.60602 10.1003 4.66602 9.59362 4.66602 8.28029V1.62695"
        stroke="#D82332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00065 14.6663H10.0007C13.334 14.6663 14.6673 13.333 14.6673 9.99967V5.99967C14.6673 2.66634 13.334 1.33301 10.0007 1.33301H6.00065C2.66732 1.33301 1.33398 2.66634 1.33398 5.99967V9.99967C1.33398 13.333 2.66732 14.6663 6.00065 14.6663Z"
        stroke="#D82332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3327 1.62695V8.28029C11.3327 9.59362 10.3927 10.107 9.23935 9.41362L8.35935 8.88695C8.15935 8.76695 7.83935 8.76695 7.63935 8.88695L6.75935 9.41362C5.60602 10.1003 4.66602 9.59362 4.66602 8.28029V1.62695"
        stroke="#D82332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
