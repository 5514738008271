import React from "react";

export const IconSaved = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0156 1.58984H5.98229C4.21562 1.58984 2.76562 3.03984 2.76562 4.80651V16.5482C2.76562 18.0482 3.84063 18.6815 5.15729 17.9565L9.22396 15.6982C9.65729 15.4565 10.3573 15.4565 10.7823 15.6982L14.849 17.9565C16.1656 18.6898 17.2406 18.0565 17.2406 16.5482V4.80651C17.2323 3.03984 15.7906 1.58984 14.0156 1.58984ZM13.0156 7.52318L9.68229 10.8565C9.55729 10.9815 9.39896 11.0398 9.24063 11.0398C9.08229 11.0398 8.92396 10.9815 8.79896 10.8565L7.54896 9.60651C7.30729 9.36484 7.30729 8.96484 7.54896 8.72318C7.79063 8.48151 8.19063 8.48151 8.43229 8.72318L9.24063 9.53151L12.1323 6.63984C12.374 6.39818 12.774 6.39818 13.0156 6.63984C13.2573 6.88151 13.2573 7.28151 13.0156 7.52318Z"
        fill="#D82332"
      />
    </svg>
  );
};
const IconSave = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0807 8.875H7.91406"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.84375V11.0104"
        stroke="#666666"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0156 1.66406H5.98229C4.20729 1.66406 2.76562 3.11406 2.76562 4.88073V16.6224C2.76562 18.1224 3.84063 18.7557 5.15729 18.0307L9.22396 15.7724C9.65729 15.5307 10.3573 15.5307 10.7823 15.7724L14.849 18.0307C16.1656 18.7641 17.2406 18.1307 17.2406 16.6224V4.88073C17.2323 3.11406 15.7906 1.66406 14.0156 1.66406Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconSave;
