import React from "react";

export default function IconDiamond({ width, height, className, fill }: Partial<any>) {
  return (
    <svg
      width={width || "18"}
      height={height || "14"}
      viewBox="0 0 18 14"
      fill={fill || "none"}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.33915L8.46108 13.8002H9.37855L17.8396 5.33915V4.42168L13.6091 0.191139L13.1504 0H4.68927L4.23054 0.191139L0 4.42168V5.33915ZM8.91982 12.424L1.3762 4.88041L4.95687 1.27426H12.8828L16.4634 4.88041L8.91982 12.424ZM8.91982 2.574H12.3476L14.654 4.88041L8.91982 10.4872V2.574Z"
        fill={fill || "gray"}
      />
    </svg>
  );
}

export function IconDiamond10({ width, height, className, fill }: any) {
  return (
    <svg
      width={width || "14"}
      height={height || "12"}
      viewBox="0 0 14 12"
      fill={fill || "none"}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 3.98199L5.73 9.5H6.27L11.25 3.98199V3.38366L8.76 0.624654L8.49 0.5H3.51L3.24 0.624654L0.75 3.38366V3.98199ZM6 8.60249L1.56 3.68283L3.6675 1.33102H8.3325L10.44 3.68283L6 8.60249ZM6 2.17867H8.0175L9.375 3.68283L6 7.33933V2.17867Z"
        fill={fill || "#D82332"}
      />
    </svg>
  );
}

export function IconDiamond16() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33203 7.29087L7.87189 13.8307H8.58103L15.1209 7.29087V6.58173L11.851 3.3118L11.4964 3.16406H4.95653L4.60196 3.3118L1.33203 6.58173V7.29087ZM8.22646 12.767L2.39574 6.9363L5.16336 4.14898H11.2896L14.0572 6.9363L8.22646 12.767ZM8.22646 5.1536H10.8759L12.6586 6.9363L8.22646 11.2699V5.1536Z"
        fill="#D82332"
      />
    </svg>
  );
}
