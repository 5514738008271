export const data = [
  {
    id: "01",
    name: "Thành phố Hà Nội",
    districts: [
      {
        id: "001",
        name: "Quận Ba Đình",
        wards: [
          {
            id: "00001",
            name: "Phường Phúc Xá",
            level: "Phường"
          },
          {
            id: "00004",
            name: "Phường Trúc Bạch",
            level: "Phường"
          },
          {
            id: "00006",
            name: "Phường Vĩnh Phúc",
            level: "Phường"
          },
          {
            id: "00007",
            name: "Phường Cống Vị",
            level: "Phường"
          },
          {
            id: "00008",
            name: "Phường Liễu Giai",
            level: "Phường"
          },
          {
            id: "00010",
            name: "Phường Nguyễn Trung Trực",
            level: "Phường"
          },
          {
            id: "00013",
            name: "Phường Quán Thánh",
            level: "Phường"
          },
          {
            id: "00016",
            name: "Phường Ngọc Hà",
            level: "Phường"
          },
          {
            id: "00019",
            name: "Phường Điện Biên",
            level: "Phường"
          },
          {
            id: "00022",
            name: "Phường Đội Cấn",
            level: "Phường"
          },
          {
            id: "00025",
            name: "Phường Ngọc Khánh",
            level: "Phường"
          },
          {
            id: "00028",
            name: "Phường Kim Mã",
            level: "Phường"
          },
          {
            id: "00031",
            name: "Phường Giảng Võ",
            level: "Phường"
          },
          {
            id: "00034",
            name: "Phường Thành Công",
            level: "Phường"
          }
        ]
      },
      {
        id: "002",
        name: "Quận Hoàn Kiếm",
        wards: [
          {
            id: "00037",
            name: "Phường Phúc Tân",
            level: "Phường"
          },
          {
            id: "00040",
            name: "Phường Đồng Xuân",
            level: "Phường"
          },
          {
            id: "00043",
            name: "Phường Hàng Mã",
            level: "Phường"
          },
          {
            id: "00046",
            name: "Phường Hàng Buồm",
            level: "Phường"
          },
          {
            id: "00049",
            name: "Phường Hàng Đào",
            level: "Phường"
          },
          {
            id: "00052",
            name: "Phường Hàng Bồ",
            level: "Phường"
          },
          {
            id: "00055",
            name: "Phường Cửa Đông",
            level: "Phường"
          },
          {
            id: "00058",
            name: "Phường Lý Thái Tổ",
            level: "Phường"
          },
          {
            id: "00061",
            name: "Phường Hàng Bạc",
            level: "Phường"
          },
          {
            id: "00064",
            name: "Phường Hàng Gai",
            level: "Phường"
          },
          {
            id: "00067",
            name: "Phường Chương Dương",
            level: "Phường"
          },
          {
            id: "00070",
            name: "Phường Hàng Trống",
            level: "Phường"
          },
          {
            id: "00073",
            name: "Phường Cửa Nam",
            level: "Phường"
          },
          {
            id: "00076",
            name: "Phường Hàng Bông",
            level: "Phường"
          },
          {
            id: "00079",
            name: "Phường Tràng Tiền",
            level: "Phường"
          },
          {
            id: "00082",
            name: "Phường Trần Hưng Đạo",
            level: "Phường"
          },
          {
            id: "00085",
            name: "Phường Phan Chu Trinh",
            level: "Phường"
          },
          {
            id: "00088",
            name: "Phường Hàng Bài",
            level: "Phường"
          }
        ]
      },
      {
        id: "003",
        name: "Quận Tây Hồ",
        wards: [
          {
            id: "00091",
            name: "Phường Phú Thượng",
            level: "Phường"
          },
          {
            id: "00094",
            name: "Phường Nhật Tân",
            level: "Phường"
          },
          {
            id: "00097",
            name: "Phường Tứ Liên",
            level: "Phường"
          },
          {
            id: "00100",
            name: "Phường Quảng An",
            level: "Phường"
          },
          {
            id: "00103",
            name: "Phường Xuân La",
            level: "Phường"
          },
          {
            id: "00106",
            name: "Phường Yên Phụ",
            level: "Phường"
          },
          {
            id: "00109",
            name: "Phường Bưởi",
            level: "Phường"
          },
          {
            id: "00112",
            name: "Phường Thụy Khuê",
            level: "Phường"
          }
        ]
      },
      {
        id: "004",
        name: "Quận Long Biên",
        wards: [
          {
            id: "00115",
            name: "Phường Thượng Thanh",
            level: "Phường"
          },
          {
            id: "00118",
            name: "Phường Ngọc Thụy",
            level: "Phường"
          },
          {
            id: "00121",
            name: "Phường Giang Biên",
            level: "Phường"
          },
          {
            id: "00124",
            name: "Phường Đức Giang",
            level: "Phường"
          },
          {
            id: "00127",
            name: "Phường Việt Hưng",
            level: "Phường"
          },
          {
            id: "00130",
            name: "Phường Gia Thụy",
            level: "Phường"
          },
          {
            id: "00133",
            name: "Phường Ngọc Lâm",
            level: "Phường"
          },
          {
            id: "00136",
            name: "Phường Phúc Lợi",
            level: "Phường"
          },
          {
            id: "00139",
            name: "Phường Bồ Đề",
            level: "Phường"
          },
          {
            id: "00142",
            name: "Phường Sài Đồng",
            level: "Phường"
          },
          {
            id: "00145",
            name: "Phường Long Biên",
            level: "Phường"
          },
          {
            id: "00148",
            name: "Phường Thạch Bàn",
            level: "Phường"
          },
          {
            id: "00151",
            name: "Phường Phúc Đồng",
            level: "Phường"
          },
          {
            id: "00154",
            name: "Phường Cự Khối",
            level: "Phường"
          }
        ]
      },
      {
        id: "005",
        name: "Quận Cầu Giấy",
        wards: [
          {
            id: "00157",
            name: "Phường Nghĩa Đô",
            level: "Phường"
          },
          {
            id: "00160",
            name: "Phường Nghĩa Tân",
            level: "Phường"
          },
          {
            id: "00163",
            name: "Phường Mai Dịch",
            level: "Phường"
          },
          {
            id: "00166",
            name: "Phường Dịch Vọng",
            level: "Phường"
          },
          {
            id: "00167",
            name: "Phường Dịch Vọng Hậu",
            level: "Phường"
          },
          {
            id: "00169",
            name: "Phường Quan Hoa",
            level: "Phường"
          },
          {
            id: "00172",
            name: "Phường Yên Hoà",
            level: "Phường"
          },
          {
            id: "00175",
            name: "Phường Trung Hoà",
            level: "Phường"
          }
        ]
      },
      {
        id: "006",
        name: "Quận Đống Đa",
        wards: [
          {
            id: "00178",
            name: "Phường Cát Linh",
            level: "Phường"
          },
          {
            id: "00181",
            name: "Phường Văn Miếu",
            level: "Phường"
          },
          {
            id: "00184",
            name: "Phường Quốc Tử Giám",
            level: "Phường"
          },
          {
            id: "00187",
            name: "Phường Láng Thượng",
            level: "Phường"
          },
          {
            id: "00190",
            name: "Phường Ô Chợ Dừa",
            level: "Phường"
          },
          {
            id: "00193",
            name: "Phường Văn Chương",
            level: "Phường"
          },
          {
            id: "00196",
            name: "Phường Hàng Bột",
            level: "Phường"
          },
          {
            id: "00199",
            name: "Phường Láng Hạ",
            level: "Phường"
          },
          {
            id: "00202",
            name: "Phường Khâm Thiên",
            level: "Phường"
          },
          {
            id: "00205",
            name: "Phường Thổ Quan",
            level: "Phường"
          },
          {
            id: "00208",
            name: "Phường Nam Đồng",
            level: "Phường"
          },
          {
            id: "00211",
            name: "Phường Trung Phụng",
            level: "Phường"
          },
          {
            id: "00214",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "00217",
            name: "Phường Trung Liệt",
            level: "Phường"
          },
          {
            id: "00220",
            name: "Phường Phương Liên",
            level: "Phường"
          },
          {
            id: "00223",
            name: "Phường Thịnh Quang",
            level: "Phường"
          },
          {
            id: "00226",
            name: "Phường Trung Tự",
            level: "Phường"
          },
          {
            id: "00229",
            name: "Phường Kim Liên",
            level: "Phường"
          },
          {
            id: "00232",
            name: "Phường Phương Mai",
            level: "Phường"
          },
          {
            id: "00235",
            name: "Phường Ngã Tư Sở",
            level: "Phường"
          },
          {
            id: "00238",
            name: "Phường Khương Thượng",
            level: "Phường"
          }
        ]
      },
      {
        id: "007",
        name: "Quận Hai Bà Trưng",
        wards: [
          {
            id: "00241",
            name: "Phường Nguyễn Du",
            level: "Phường"
          },
          {
            id: "00244",
            name: "Phường Bạch Đằng",
            level: "Phường"
          },
          {
            id: "00247",
            name: "Phường Phạm Đình Hổ",
            level: "Phường"
          },
          {
            id: "00256",
            name: "Phường Lê Đại Hành",
            level: "Phường"
          },
          {
            id: "00259",
            name: "Phường Đồng Nhân",
            level: "Phường"
          },
          {
            id: "00262",
            name: "Phường Phố Huế",
            level: "Phường"
          },
          {
            id: "00265",
            name: "Phường Đống Mác",
            level: "Phường"
          },
          {
            id: "00268",
            name: "Phường Thanh Lương",
            level: "Phường"
          },
          {
            id: "00271",
            name: "Phường Thanh Nhàn",
            level: "Phường"
          },
          {
            id: "00274",
            name: "Phường Cầu Dền",
            level: "Phường"
          },
          {
            id: "00277",
            name: "Phường Bách Khoa",
            level: "Phường"
          },
          {
            id: "00280",
            name: "Phường Đồng Tâm",
            level: "Phường"
          },
          {
            id: "00283",
            name: "Phường Vĩnh Tuy",
            level: "Phường"
          },
          {
            id: "00286",
            name: "Phường Bạch Mai",
            level: "Phường"
          },
          {
            id: "00289",
            name: "Phường Quỳnh Mai",
            level: "Phường"
          },
          {
            id: "00292",
            name: "Phường Quỳnh Lôi",
            level: "Phường"
          },
          {
            id: "00295",
            name: "Phường Minh Khai",
            level: "Phường"
          },
          {
            id: "00298",
            name: "Phường Trương Định",
            level: "Phường"
          }
        ]
      },
      {
        id: "008",
        name: "Quận Hoàng Mai",
        wards: [
          {
            id: "00301",
            name: "Phường Thanh Trì",
            level: "Phường"
          },
          {
            id: "00304",
            name: "Phường Vĩnh Hưng",
            level: "Phường"
          },
          {
            id: "00307",
            name: "Phường Định Công",
            level: "Phường"
          },
          {
            id: "00310",
            name: "Phường Mai Động",
            level: "Phường"
          },
          {
            id: "00313",
            name: "Phường Tương Mai",
            level: "Phường"
          },
          {
            id: "00316",
            name: "Phường Đại Kim",
            level: "Phường"
          },
          {
            id: "00319",
            name: "Phường Tân Mai",
            level: "Phường"
          },
          {
            id: "00322",
            name: "Phường Hoàng Văn Thụ",
            level: "Phường"
          },
          {
            id: "00325",
            name: "Phường Giáp Bát",
            level: "Phường"
          },
          {
            id: "00328",
            name: "Phường Lĩnh Nam",
            level: "Phường"
          },
          {
            id: "00331",
            name: "Phường Thịnh Liệt",
            level: "Phường"
          },
          {
            id: "00334",
            name: "Phường Trần Phú",
            level: "Phường"
          },
          {
            id: "00337",
            name: "Phường Hoàng Liệt",
            level: "Phường"
          },
          {
            id: "00340",
            name: "Phường Yên Sở",
            level: "Phường"
          }
        ]
      },
      {
        id: "009",
        name: "Quận Thanh Xuân",
        wards: [
          {
            id: "00343",
            name: "Phường Nhân Chính",
            level: "Phường"
          },
          {
            id: "00346",
            name: "Phường Thượng Đình",
            level: "Phường"
          },
          {
            id: "00349",
            name: "Phường Khương Trung",
            level: "Phường"
          },
          {
            id: "00352",
            name: "Phường Khương Mai",
            level: "Phường"
          },
          {
            id: "00355",
            name: "Phường Thanh Xuân Trung",
            level: "Phường"
          },
          {
            id: "00358",
            name: "Phường Phương Liệt",
            level: "Phường"
          },
          {
            id: "00361",
            name: "Phường Hạ Đình",
            level: "Phường"
          },
          {
            id: "00364",
            name: "Phường Khương Đình",
            level: "Phường"
          },
          {
            id: "00367",
            name: "Phường Thanh Xuân Bắc",
            level: "Phường"
          },
          {
            id: "00370",
            name: "Phường Thanh Xuân Nam",
            level: "Phường"
          },
          {
            id: "00373",
            name: "Phường Kim Giang",
            level: "Phường"
          }
        ]
      },
      {
        id: "016",
        name: "Huyện Sóc Sơn",
        wards: [
          {
            id: "00376",
            name: "Thị trấn Sóc Sơn",
            level: "Thị trấn"
          },
          {
            id: "00379",
            name: "Xã Bắc Sơn",
            level: "Xã"
          },
          {
            id: "00382",
            name: "Xã Minh Trí",
            level: "Xã"
          },
          {
            id: "00385",
            name: "Xã Hồng Kỳ",
            level: "Xã"
          },
          {
            id: "00388",
            name: "Xã Nam Sơn",
            level: "Xã"
          },
          {
            id: "00391",
            name: "Xã Trung Giã",
            level: "Xã"
          },
          {
            id: "00394",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "00397",
            name: "Xã Minh Phú",
            level: "Xã"
          },
          {
            id: "00400",
            name: "Xã Phù Linh",
            level: "Xã"
          },
          {
            id: "00403",
            name: "Xã Bắc Phú",
            level: "Xã"
          },
          {
            id: "00406",
            name: "Xã Tân Minh",
            level: "Xã"
          },
          {
            id: "00409",
            name: "Xã Quang Tiến",
            level: "Xã"
          },
          {
            id: "00412",
            name: "Xã Hiền Ninh",
            level: "Xã"
          },
          {
            id: "00415",
            name: "Xã Tân Dân",
            level: "Xã"
          },
          {
            id: "00418",
            name: "Xã Tiên Dược",
            level: "Xã"
          },
          {
            id: "00421",
            name: "Xã Việt Long",
            level: "Xã"
          },
          {
            id: "00424",
            name: "Xã Xuân Giang",
            level: "Xã"
          },
          {
            id: "00427",
            name: "Xã Mai Đình",
            level: "Xã"
          },
          {
            id: "00430",
            name: "Xã Đức Hoà",
            level: "Xã"
          },
          {
            id: "00433",
            name: "Xã Thanh Xuân",
            level: "Xã"
          },
          {
            id: "00436",
            name: "Xã Đông Xuân",
            level: "Xã"
          },
          {
            id: "00439",
            name: "Xã Kim Lũ",
            level: "Xã"
          },
          {
            id: "00442",
            name: "Xã Phú Cường",
            level: "Xã"
          },
          {
            id: "00445",
            name: "Xã Phú Minh",
            level: "Xã"
          },
          {
            id: "00448",
            name: "Xã Phù Lỗ",
            level: "Xã"
          },
          {
            id: "00451",
            name: "Xã Xuân Thu",
            level: "Xã"
          }
        ]
      },
      {
        id: "017",
        name: "Huyện Đông Anh",
        wards: [
          {
            id: "00454",
            name: "Thị trấn Đông Anh",
            level: "Thị trấn"
          },
          {
            id: "00457",
            name: "Xã Xuân Nộn",
            level: "Xã"
          },
          {
            id: "00460",
            name: "Xã Thuỵ Lâm",
            level: "Xã"
          },
          {
            id: "00463",
            name: "Xã Bắc Hồng",
            level: "Xã"
          },
          {
            id: "00466",
            name: "Xã Nguyên Khê",
            level: "Xã"
          },
          {
            id: "00469",
            name: "Xã Nam Hồng",
            level: "Xã"
          },
          {
            id: "00472",
            name: "Xã Tiên Dương",
            level: "Xã"
          },
          {
            id: "00475",
            name: "Xã Vân Hà",
            level: "Xã"
          },
          {
            id: "00478",
            name: "Xã Uy Nỗ",
            level: "Xã"
          },
          {
            id: "00481",
            name: "Xã Vân Nội",
            level: "Xã"
          },
          {
            id: "00484",
            name: "Xã Liên Hà",
            level: "Xã"
          },
          {
            id: "00487",
            name: "Xã Việt Hùng",
            level: "Xã"
          },
          {
            id: "00490",
            name: "Xã Kim Nỗ",
            level: "Xã"
          },
          {
            id: "00493",
            name: "Xã Kim Chung",
            level: "Xã"
          },
          {
            id: "00496",
            name: "Xã Dục Tú",
            level: "Xã"
          },
          {
            id: "00499",
            name: "Xã Đại Mạch",
            level: "Xã"
          },
          {
            id: "00502",
            name: "Xã Vĩnh Ngọc",
            level: "Xã"
          },
          {
            id: "00505",
            name: "Xã Cổ Loa",
            level: "Xã"
          },
          {
            id: "00508",
            name: "Xã Hải Bối",
            level: "Xã"
          },
          {
            id: "00511",
            name: "Xã Xuân Canh",
            level: "Xã"
          },
          {
            id: "00514",
            name: "Xã Võng La",
            level: "Xã"
          },
          {
            id: "00517",
            name: "Xã Tàm Xá",
            level: "Xã"
          },
          {
            id: "00520",
            name: "Xã Mai Lâm",
            level: "Xã"
          },
          {
            id: "00523",
            name: "Xã Đông Hội",
            level: "Xã"
          }
        ]
      },
      {
        id: "018",
        name: "Huyện Gia Lâm",
        wards: [
          {
            id: "00526",
            name: "Thị trấn Yên Viên",
            level: "Thị trấn"
          },
          {
            id: "00529",
            name: "Xã Yên Thường",
            level: "Xã"
          },
          {
            id: "00532",
            name: "Xã Yên Viên",
            level: "Xã"
          },
          {
            id: "00535",
            name: "Xã Ninh Hiệp",
            level: "Xã"
          },
          {
            id: "00538",
            name: "Xã Đình Xuyên",
            level: "Xã"
          },
          {
            id: "00541",
            name: "Xã Dương Hà",
            level: "Xã"
          },
          {
            id: "00544",
            name: "Xã Phù Đổng",
            level: "Xã"
          },
          {
            id: "00547",
            name: "Xã Trung Mầu",
            level: "Xã"
          },
          {
            id: "00550",
            name: "Xã Lệ Chi",
            level: "Xã"
          },
          {
            id: "00553",
            name: "Xã Cổ Bi",
            level: "Xã"
          },
          {
            id: "00556",
            name: "Xã Đặng Xá",
            level: "Xã"
          },
          {
            id: "00559",
            name: "Xã Phú Thị",
            level: "Xã"
          },
          {
            id: "00562",
            name: "Xã Kim Sơn",
            level: "Xã"
          },
          {
            id: "00565",
            name: "Thị trấn Trâu Quỳ",
            level: "Thị trấn"
          },
          {
            id: "00568",
            name: "Xã Dương Quang",
            level: "Xã"
          },
          {
            id: "00571",
            name: "Xã Dương Xá",
            level: "Xã"
          },
          {
            id: "00574",
            name: "Xã Đông Dư",
            level: "Xã"
          },
          {
            id: "00577",
            name: "Xã Đa Tốn",
            level: "Xã"
          },
          {
            id: "00580",
            name: "Xã Kiêu Kỵ",
            level: "Xã"
          },
          {
            id: "00583",
            name: "Xã Bát Tràng",
            level: "Xã"
          },
          {
            id: "00586",
            name: "Xã Kim Lan",
            level: "Xã"
          },
          {
            id: "00589",
            name: "Xã Văn Đức",
            level: "Xã"
          }
        ]
      },
      {
        id: "019",
        name: "Quận Nam Từ Liêm",
        wards: [
          {
            id: "00592",
            name: "Phường Cầu Diễn",
            level: "Phường"
          },
          {
            id: "00622",
            name: "Phường Xuân Phương",
            level: "Phường"
          },
          {
            id: "00623",
            name: "Phường Phương Canh",
            level: "Phường"
          },
          {
            id: "00625",
            name: "Phường Mỹ Đình 1",
            level: "Phường"
          },
          {
            id: "00626",
            name: "Phường Mỹ Đình 2",
            level: "Phường"
          },
          {
            id: "00628",
            name: "Phường Tây Mỗ",
            level: "Phường"
          },
          {
            id: "00631",
            name: "Phường Mễ Trì",
            level: "Phường"
          },
          {
            id: "00632",
            name: "Phường Phú Đô",
            level: "Phường"
          },
          {
            id: "00634",
            name: "Phường Đại Mỗ",
            level: "Phường"
          },
          {
            id: "00637",
            name: "Phường Trung Văn",
            level: "Phường"
          }
        ]
      },
      {
        id: "020",
        name: "Huyện Thanh Trì",
        wards: [
          {
            id: "00640",
            name: "Thị trấn Văn Điển",
            level: "Thị trấn"
          },
          {
            id: "00643",
            name: "Xã Tân Triều",
            level: "Xã"
          },
          {
            id: "00646",
            name: "Xã Thanh Liệt",
            level: "Xã"
          },
          {
            id: "00649",
            name: "Xã Tả Thanh Oai",
            level: "Xã"
          },
          {
            id: "00652",
            name: "Xã Hữu Hoà",
            level: "Xã"
          },
          {
            id: "00655",
            name: "Xã Tam Hiệp",
            level: "Xã"
          },
          {
            id: "00658",
            name: "Xã Tứ Hiệp",
            level: "Xã"
          },
          {
            id: "00661",
            name: "Xã Yên Mỹ",
            level: "Xã"
          },
          {
            id: "00664",
            name: "Xã Vĩnh Quỳnh",
            level: "Xã"
          },
          {
            id: "00667",
            name: "Xã Ngũ Hiệp",
            level: "Xã"
          },
          {
            id: "00670",
            name: "Xã Duyên Hà",
            level: "Xã"
          },
          {
            id: "00673",
            name: "Xã Ngọc Hồi",
            level: "Xã"
          },
          {
            id: "00676",
            name: "Xã Vạn Phúc",
            level: "Xã"
          },
          {
            id: "00679",
            name: "Xã Đại áng",
            level: "Xã"
          },
          {
            id: "00682",
            name: "Xã Liên Ninh",
            level: "Xã"
          },
          {
            id: "00685",
            name: "Xã Đông Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "021",
        name: "Quận Bắc Từ Liêm",
        wards: [
          {
            id: "00595",
            name: "Phường Thượng Cát",
            level: "Phường"
          },
          {
            id: "00598",
            name: "Phường Liên Mạc",
            level: "Phường"
          },
          {
            id: "00601",
            name: "Phường Đông Ngạc",
            level: "Phường"
          },
          {
            id: "00602",
            name: "Phường Đức Thắng",
            level: "Phường"
          },
          {
            id: "00604",
            name: "Phường Thụy Phương",
            level: "Phường"
          },
          {
            id: "00607",
            name: "Phường Tây Tựu",
            level: "Phường"
          },
          {
            id: "00610",
            name: "Phường Xuân Đỉnh",
            level: "Phường"
          },
          {
            id: "00611",
            name: "Phường Xuân Tảo",
            level: "Phường"
          },
          {
            id: "00613",
            name: "Phường Minh Khai",
            level: "Phường"
          },
          {
            id: "00616",
            name: "Phường Cổ Nhuế 1",
            level: "Phường"
          },
          {
            id: "00617",
            name: "Phường Cổ Nhuế 2",
            level: "Phường"
          },
          {
            id: "00619",
            name: "Phường Phú Diễn",
            level: "Phường"
          },
          {
            id: "00620",
            name: "Phường Phúc Diễn",
            level: "Phường"
          }
        ]
      },
      {
        id: "250",
        name: "Huyện Mê Linh",
        wards: [
          {
            id: "08973",
            name: "Thị trấn Chi Đông",
            level: "Thị trấn"
          },
          {
            id: "08974",
            name: "Xã Đại Thịnh",
            level: "Xã"
          },
          {
            id: "08977",
            name: "Xã Kim Hoa",
            level: "Xã"
          },
          {
            id: "08980",
            name: "Xã Thạch Đà",
            level: "Xã"
          },
          {
            id: "08983",
            name: "Xã Tiến Thắng",
            level: "Xã"
          },
          {
            id: "08986",
            name: "Xã Tự Lập",
            level: "Xã"
          },
          {
            id: "08989",
            name: "Thị trấn Quang Minh",
            level: "Thị trấn"
          },
          {
            id: "08992",
            name: "Xã Thanh Lâm",
            level: "Xã"
          },
          {
            id: "08995",
            name: "Xã Tam Đồng",
            level: "Xã"
          },
          {
            id: "08998",
            name: "Xã Liên Mạc",
            level: "Xã"
          },
          {
            id: "09001",
            name: "Xã Vạn Yên",
            level: "Xã"
          },
          {
            id: "09004",
            name: "Xã Chu Phan",
            level: "Xã"
          },
          {
            id: "09007",
            name: "Xã Tiến Thịnh",
            level: "Xã"
          },
          {
            id: "09010",
            name: "Xã Mê Linh",
            level: "Xã"
          },
          {
            id: "09013",
            name: "Xã Văn Khê",
            level: "Xã"
          },
          {
            id: "09016",
            name: "Xã Hoàng Kim",
            level: "Xã"
          },
          {
            id: "09019",
            name: "Xã Tiền Phong",
            level: "Xã"
          },
          {
            id: "09022",
            name: "Xã Tráng Việt",
            level: "Xã"
          }
        ]
      },
      {
        id: "268",
        name: "Quận Hà Đông",
        wards: [
          {
            id: "09538",
            name: "Phường Nguyễn Trãi",
            level: "Phường"
          },
          {
            id: "09541",
            name: "Phường Mộ Lao",
            level: "Phường"
          },
          {
            id: "09542",
            name: "Phường Văn Quán",
            level: "Phường"
          },
          {
            id: "09544",
            name: "Phường Vạn Phúc",
            level: "Phường"
          },
          {
            id: "09547",
            name: "Phường Yết Kiêu",
            level: "Phường"
          },
          {
            id: "09550",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "09551",
            name: "Phường La Khê",
            level: "Phường"
          },
          {
            id: "09552",
            name: "Phường Phú La",
            level: "Phường"
          },
          {
            id: "09553",
            name: "Phường Phúc La",
            level: "Phường"
          },
          {
            id: "09556",
            name: "Phường Hà Cầu",
            level: "Phường"
          },
          {
            id: "09562",
            name: "Phường Yên Nghĩa",
            level: "Phường"
          },
          {
            id: "09565",
            name: "Phường Kiến Hưng",
            level: "Phường"
          },
          {
            id: "09568",
            name: "Phường Phú Lãm",
            level: "Phường"
          },
          {
            id: "09571",
            name: "Phường Phú Lương",
            level: "Phường"
          },
          {
            id: "09886",
            name: "Phường Dương Nội",
            level: "Phường"
          },
          {
            id: "10117",
            name: "Phường Đồng Mai",
            level: "Phường"
          },
          {
            id: "10123",
            name: "Phường Biên Giang",
            level: "Phường"
          }
        ]
      },
      {
        id: "269",
        name: "Thị xã Sơn Tây",
        wards: [
          {
            id: "09574",
            name: "Phường Lê Lợi",
            level: "Phường"
          },
          {
            id: "09577",
            name: "Phường Phú Thịnh",
            level: "Phường"
          },
          {
            id: "09580",
            name: "Phường Ngô Quyền",
            level: "Phường"
          },
          {
            id: "09583",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "09586",
            name: "Phường Sơn Lộc",
            level: "Phường"
          },
          {
            id: "09589",
            name: "Phường Xuân Khanh",
            level: "Phường"
          },
          {
            id: "09592",
            name: "Xã Đường Lâm",
            level: "Xã"
          },
          {
            id: "09595",
            name: "Phường Viên Sơn",
            level: "Phường"
          },
          {
            id: "09598",
            name: "Xã Xuân Sơn",
            level: "Xã"
          },
          {
            id: "09601",
            name: "Phường Trung Hưng",
            level: "Phường"
          },
          {
            id: "09604",
            name: "Xã Thanh Mỹ",
            level: "Xã"
          },
          {
            id: "09607",
            name: "Phường Trung Sơn Trầm",
            level: "Phường"
          },
          {
            id: "09610",
            name: "Xã Kim Sơn",
            level: "Xã"
          },
          {
            id: "09613",
            name: "Xã Sơn Đông",
            level: "Xã"
          },
          {
            id: "09616",
            name: "Xã Cổ Đông",
            level: "Xã"
          }
        ]
      },
      {
        id: "271",
        name: "Huyện Ba Vì",
        wards: [
          {
            id: "09619",
            name: "Thị trấn Tây Đằng",
            level: "Thị trấn"
          },
          {
            id: "09625",
            name: "Xã Phú Cường",
            level: "Xã"
          },
          {
            id: "09628",
            name: "Xã Cổ Đô",
            level: "Xã"
          },
          {
            id: "09631",
            name: "Xã Tản Hồng",
            level: "Xã"
          },
          {
            id: "09634",
            name: "Xã Vạn Thắng",
            level: "Xã"
          },
          {
            id: "09637",
            name: "Xã Châu Sơn",
            level: "Xã"
          },
          {
            id: "09640",
            name: "Xã Phong Vân",
            level: "Xã"
          },
          {
            id: "09643",
            name: "Xã Phú Đông",
            level: "Xã"
          },
          {
            id: "09646",
            name: "Xã Phú Phương",
            level: "Xã"
          },
          {
            id: "09649",
            name: "Xã Phú Châu",
            level: "Xã"
          },
          {
            id: "09652",
            name: "Xã Thái Hòa",
            level: "Xã"
          },
          {
            id: "09655",
            name: "Xã Đồng Thái",
            level: "Xã"
          },
          {
            id: "09658",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "09661",
            name: "Xã Minh Châu",
            level: "Xã"
          },
          {
            id: "09664",
            name: "Xã Vật Lại",
            level: "Xã"
          },
          {
            id: "09667",
            name: "Xã Chu Minh",
            level: "Xã"
          },
          {
            id: "09670",
            name: "Xã Tòng Bạt",
            level: "Xã"
          },
          {
            id: "09673",
            name: "Xã Cẩm Lĩnh",
            level: "Xã"
          },
          {
            id: "09676",
            name: "Xã Sơn Đà",
            level: "Xã"
          },
          {
            id: "09679",
            name: "Xã Đông Quang",
            level: "Xã"
          },
          {
            id: "09682",
            name: "Xã Tiên Phong",
            level: "Xã"
          },
          {
            id: "09685",
            name: "Xã Thụy An",
            level: "Xã"
          },
          {
            id: "09688",
            name: "Xã Cam Thượng",
            level: "Xã"
          },
          {
            id: "09691",
            name: "Xã Thuần Mỹ",
            level: "Xã"
          },
          {
            id: "09694",
            name: "Xã Tản Lĩnh",
            level: "Xã"
          },
          {
            id: "09697",
            name: "Xã Ba Trại",
            level: "Xã"
          },
          {
            id: "09700",
            name: "Xã Minh Quang",
            level: "Xã"
          },
          {
            id: "09703",
            name: "Xã Ba Vì",
            level: "Xã"
          },
          {
            id: "09706",
            name: "Xã Vân Hòa",
            level: "Xã"
          },
          {
            id: "09709",
            name: "Xã Yên Bài",
            level: "Xã"
          },
          {
            id: "09712",
            name: "Xã Khánh Thượng",
            level: "Xã"
          }
        ]
      },
      {
        id: "272",
        name: "Huyện Phúc Thọ",
        wards: [
          {
            id: "09715",
            name: "Thị trấn Phúc Thọ",
            level: "Thị trấn"
          },
          {
            id: "09718",
            name: "Xã Vân Hà",
            level: "Xã"
          },
          {
            id: "09721",
            name: "Xã Vân Phúc",
            level: "Xã"
          },
          {
            id: "09724",
            name: "Xã Vân Nam",
            level: "Xã"
          },
          {
            id: "09727",
            name: "Xã Xuân Đình",
            level: "Xã"
          },
          {
            id: "09733",
            name: "Xã Sen Phương",
            level: "Xã"
          },
          {
            id: "09739",
            name: "Xã Võng Xuyên",
            level: "Xã"
          },
          {
            id: "09742",
            name: "Xã Thọ Lộc",
            level: "Xã"
          },
          {
            id: "09745",
            name: "Xã Long Xuyên",
            level: "Xã"
          },
          {
            id: "09748",
            name: "Xã Thượng Cốc",
            level: "Xã"
          },
          {
            id: "09751",
            name: "Xã Hát Môn",
            level: "Xã"
          },
          {
            id: "09754",
            name: "Xã Tích Giang",
            level: "Xã"
          },
          {
            id: "09757",
            name: "Xã Thanh Đa",
            level: "Xã"
          },
          {
            id: "09760",
            name: "Xã Trạch Mỹ Lộc",
            level: "Xã"
          },
          {
            id: "09763",
            name: "Xã Phúc Hòa",
            level: "Xã"
          },
          {
            id: "09766",
            name: "Xã Ngọc Tảo",
            level: "Xã"
          },
          {
            id: "09769",
            name: "Xã Phụng Thượng",
            level: "Xã"
          },
          {
            id: "09772",
            name: "Xã Tam Thuấn",
            level: "Xã"
          },
          {
            id: "09775",
            name: "Xã Tam Hiệp",
            level: "Xã"
          },
          {
            id: "09778",
            name: "Xã Hiệp Thuận",
            level: "Xã"
          },
          {
            id: "09781",
            name: "Xã Liên Hiệp",
            level: "Xã"
          }
        ]
      },
      {
        id: "273",
        name: "Huyện Đan Phượng",
        wards: [
          {
            id: "09784",
            name: "Thị trấn Phùng",
            level: "Thị trấn"
          },
          {
            id: "09787",
            name: "Xã Trung Châu",
            level: "Xã"
          },
          {
            id: "09790",
            name: "Xã Thọ An",
            level: "Xã"
          },
          {
            id: "09793",
            name: "Xã Thọ Xuân",
            level: "Xã"
          },
          {
            id: "09796",
            name: "Xã Hồng Hà",
            level: "Xã"
          },
          {
            id: "09799",
            name: "Xã Liên Hồng",
            level: "Xã"
          },
          {
            id: "09802",
            name: "Xã Liên Hà",
            level: "Xã"
          },
          {
            id: "09805",
            name: "Xã Hạ Mỗ",
            level: "Xã"
          },
          {
            id: "09808",
            name: "Xã Liên Trung",
            level: "Xã"
          },
          {
            id: "09811",
            name: "Xã Phương Đình",
            level: "Xã"
          },
          {
            id: "09814",
            name: "Xã Thượng Mỗ",
            level: "Xã"
          },
          {
            id: "09817",
            name: "Xã Tân Hội",
            level: "Xã"
          },
          {
            id: "09820",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "09823",
            name: "Xã Đan Phượng",
            level: "Xã"
          },
          {
            id: "09826",
            name: "Xã Đồng Tháp",
            level: "Xã"
          },
          {
            id: "09829",
            name: "Xã Song Phượng",
            level: "Xã"
          }
        ]
      },
      {
        id: "274",
        name: "Huyện Hoài Đức",
        wards: [
          {
            id: "09832",
            name: "Thị trấn Trạm Trôi",
            level: "Thị trấn"
          },
          {
            id: "09835",
            name: "Xã Đức Thượng",
            level: "Xã"
          },
          {
            id: "09838",
            name: "Xã Minh Khai",
            level: "Xã"
          },
          {
            id: "09841",
            name: "Xã Dương Liễu",
            level: "Xã"
          },
          {
            id: "09844",
            name: "Xã Di Trạch",
            level: "Xã"
          },
          {
            id: "09847",
            name: "Xã Đức Giang",
            level: "Xã"
          },
          {
            id: "09850",
            name: "Xã Cát Quế",
            level: "Xã"
          },
          {
            id: "09853",
            name: "Xã Kim Chung",
            level: "Xã"
          },
          {
            id: "09856",
            name: "Xã Yên Sở",
            level: "Xã"
          },
          {
            id: "09859",
            name: "Xã Sơn Đồng",
            level: "Xã"
          },
          {
            id: "09862",
            name: "Xã Vân Canh",
            level: "Xã"
          },
          {
            id: "09865",
            name: "Xã Đắc Sở",
            level: "Xã"
          },
          {
            id: "09868",
            name: "Xã Lại Yên",
            level: "Xã"
          },
          {
            id: "09871",
            name: "Xã Tiền Yên",
            level: "Xã"
          },
          {
            id: "09874",
            name: "Xã Song Phương",
            level: "Xã"
          },
          {
            id: "09877",
            name: "Xã An Khánh",
            level: "Xã"
          },
          {
            id: "09880",
            name: "Xã An Thượng",
            level: "Xã"
          },
          {
            id: "09883",
            name: "Xã Vân Côn",
            level: "Xã"
          },
          {
            id: "09889",
            name: "Xã La Phù",
            level: "Xã"
          },
          {
            id: "09892",
            name: "Xã Đông La",
            level: "Xã"
          }
        ]
      },
      {
        id: "275",
        name: "Huyện Quốc Oai",
        wards: [
          {
            id: "04939",
            name: "Xã Đông Xuân",
            level: "Xã"
          },
          {
            id: "09895",
            name: "Thị trấn Quốc Oai",
            level: "Thị trấn"
          },
          {
            id: "09898",
            name: "Xã Sài Sơn",
            level: "Xã"
          },
          {
            id: "09901",
            name: "Xã Phượng Cách",
            level: "Xã"
          },
          {
            id: "09904",
            name: "Xã Yên Sơn",
            level: "Xã"
          },
          {
            id: "09907",
            name: "Xã Ngọc Liệp",
            level: "Xã"
          },
          {
            id: "09910",
            name: "Xã Ngọc Mỹ",
            level: "Xã"
          },
          {
            id: "09913",
            name: "Xã Liệp Tuyết",
            level: "Xã"
          },
          {
            id: "09916",
            name: "Xã Thạch Thán",
            level: "Xã"
          },
          {
            id: "09919",
            name: "Xã Đồng Quang",
            level: "Xã"
          },
          {
            id: "09922",
            name: "Xã Phú Cát",
            level: "Xã"
          },
          {
            id: "09925",
            name: "Xã Tuyết Nghĩa",
            level: "Xã"
          },
          {
            id: "09928",
            name: "Xã Nghĩa Hương",
            level: "Xã"
          },
          {
            id: "09931",
            name: "Xã Cộng Hòa",
            level: "Xã"
          },
          {
            id: "09934",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "09937",
            name: "Xã Đại Thành",
            level: "Xã"
          },
          {
            id: "09940",
            name: "Xã Phú Mãn",
            level: "Xã"
          },
          {
            id: "09943",
            name: "Xã Cấn Hữu",
            level: "Xã"
          },
          {
            id: "09946",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "09949",
            name: "Xã Hòa Thạch",
            level: "Xã"
          },
          {
            id: "09952",
            name: "Xã Đông Yên",
            level: "Xã"
          }
        ]
      },
      {
        id: "276",
        name: "Huyện Thạch Thất",
        wards: [
          {
            id: "04927",
            name: "Xã Yên Trung",
            level: "Xã"
          },
          {
            id: "04930",
            name: "Xã Yên Bình",
            level: "Xã"
          },
          {
            id: "04936",
            name: "Xã Tiến Xuân",
            level: "Xã"
          },
          {
            id: "09955",
            name: "Thị trấn Liên Quan",
            level: "Thị trấn"
          },
          {
            id: "09958",
            name: "Xã Đại Đồng",
            level: "Xã"
          },
          {
            id: "09961",
            name: "Xã Cẩm Yên",
            level: "Xã"
          },
          {
            id: "09964",
            name: "Xã Lại Thượng",
            level: "Xã"
          },
          {
            id: "09967",
            name: "Xã Phú Kim",
            level: "Xã"
          },
          {
            id: "09970",
            name: "Xã Hương Ngải",
            level: "Xã"
          },
          {
            id: "09973",
            name: "Xã Canh Nậu",
            level: "Xã"
          },
          {
            id: "09976",
            name: "Xã Kim Quan",
            level: "Xã"
          },
          {
            id: "09979",
            name: "Xã Dị Nậu",
            level: "Xã"
          },
          {
            id: "09982",
            name: "Xã Bình Yên",
            level: "Xã"
          },
          {
            id: "09985",
            name: "Xã Chàng Sơn",
            level: "Xã"
          },
          {
            id: "09988",
            name: "Xã Thạch Hoà",
            level: "Xã"
          },
          {
            id: "09991",
            name: "Xã Cần Kiệm",
            level: "Xã"
          },
          {
            id: "09994",
            name: "Xã Hữu Bằng",
            level: "Xã"
          },
          {
            id: "09997",
            name: "Xã Phùng Xá",
            level: "Xã"
          },
          {
            id: "10000",
            name: "Xã Tân Xã",
            level: "Xã"
          },
          {
            id: "10003",
            name: "Xã Thạch Xá",
            level: "Xã"
          },
          {
            id: "10006",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "10009",
            name: "Xã Hạ Bằng",
            level: "Xã"
          },
          {
            id: "10012",
            name: "Xã Đồng Trúc",
            level: "Xã"
          }
        ]
      },
      {
        id: "277",
        name: "Huyện Chương Mỹ",
        wards: [
          {
            id: "10015",
            name: "Thị trấn Chúc Sơn",
            level: "Thị trấn"
          },
          {
            id: "10018",
            name: "Thị trấn Xuân Mai",
            level: "Thị trấn"
          },
          {
            id: "10021",
            name: "Xã Phụng Châu",
            level: "Xã"
          },
          {
            id: "10024",
            name: "Xã Tiên Phương",
            level: "Xã"
          },
          {
            id: "10027",
            name: "Xã Đông Sơn",
            level: "Xã"
          },
          {
            id: "10030",
            name: "Xã Đông Phương Yên",
            level: "Xã"
          },
          {
            id: "10033",
            name: "Xã Phú Nghĩa",
            level: "Xã"
          },
          {
            id: "10039",
            name: "Xã Trường Yên",
            level: "Xã"
          },
          {
            id: "10042",
            name: "Xã Ngọc Hòa",
            level: "Xã"
          },
          {
            id: "10045",
            name: "Xã Thủy Xuân Tiên",
            level: "Xã"
          },
          {
            id: "10048",
            name: "Xã Thanh Bình",
            level: "Xã"
          },
          {
            id: "10051",
            name: "Xã Trung Hòa",
            level: "Xã"
          },
          {
            id: "10054",
            name: "Xã Đại Yên",
            level: "Xã"
          },
          {
            id: "10057",
            name: "Xã Thụy Hương",
            level: "Xã"
          },
          {
            id: "10060",
            name: "Xã Tốt Động",
            level: "Xã"
          },
          {
            id: "10063",
            name: "Xã Lam Điền",
            level: "Xã"
          },
          {
            id: "10066",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "10069",
            name: "Xã Nam Phương Tiến",
            level: "Xã"
          },
          {
            id: "10072",
            name: "Xã Hợp Đồng",
            level: "Xã"
          },
          {
            id: "10075",
            name: "Xã Hoàng Văn Thụ",
            level: "Xã"
          },
          {
            id: "10078",
            name: "Xã Hoàng Diệu",
            level: "Xã"
          },
          {
            id: "10081",
            name: "Xã Hữu Văn",
            level: "Xã"
          },
          {
            id: "10084",
            name: "Xã Quảng Bị",
            level: "Xã"
          },
          {
            id: "10087",
            name: "Xã Mỹ Lương",
            level: "Xã"
          },
          {
            id: "10090",
            name: "Xã Thượng Vực",
            level: "Xã"
          },
          {
            id: "10093",
            name: "Xã Hồng Phong",
            level: "Xã"
          },
          {
            id: "10096",
            name: "Xã Đồng Phú",
            level: "Xã"
          },
          {
            id: "10099",
            name: "Xã Trần Phú",
            level: "Xã"
          },
          {
            id: "10102",
            name: "Xã Văn Võ",
            level: "Xã"
          },
          {
            id: "10105",
            name: "Xã Đồng Lạc",
            level: "Xã"
          },
          {
            id: "10108",
            name: "Xã Hòa Chính",
            level: "Xã"
          },
          {
            id: "10111",
            name: "Xã Phú Nam An",
            level: "Xã"
          }
        ]
      },
      {
        id: "278",
        name: "Huyện Thanh Oai",
        wards: [
          {
            id: "10114",
            name: "Thị trấn Kim Bài",
            level: "Thị trấn"
          },
          {
            id: "10120",
            name: "Xã Cự Khê",
            level: "Xã"
          },
          {
            id: "10126",
            name: "Xã Bích Hòa",
            level: "Xã"
          },
          {
            id: "10129",
            name: "Xã Mỹ Hưng",
            level: "Xã"
          },
          {
            id: "10132",
            name: "Xã Cao Viên",
            level: "Xã"
          },
          {
            id: "10135",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "10138",
            name: "Xã Tam Hưng",
            level: "Xã"
          },
          {
            id: "10141",
            name: "Xã Thanh Cao",
            level: "Xã"
          },
          {
            id: "10144",
            name: "Xã Thanh Thùy",
            level: "Xã"
          },
          {
            id: "10147",
            name: "Xã Thanh Mai",
            level: "Xã"
          },
          {
            id: "10150",
            name: "Xã Thanh Văn",
            level: "Xã"
          },
          {
            id: "10153",
            name: "Xã Đỗ Động",
            level: "Xã"
          },
          {
            id: "10156",
            name: "Xã Kim An",
            level: "Xã"
          },
          {
            id: "10159",
            name: "Xã Kim Thư",
            level: "Xã"
          },
          {
            id: "10162",
            name: "Xã Phương Trung",
            level: "Xã"
          },
          {
            id: "10165",
            name: "Xã Tân Ước",
            level: "Xã"
          },
          {
            id: "10168",
            name: "Xã Dân Hòa",
            level: "Xã"
          },
          {
            id: "10171",
            name: "Xã Liên Châu",
            level: "Xã"
          },
          {
            id: "10174",
            name: "Xã Cao Dương",
            level: "Xã"
          },
          {
            id: "10177",
            name: "Xã Xuân Dương",
            level: "Xã"
          },
          {
            id: "10180",
            name: "Xã Hồng Dương",
            level: "Xã"
          }
        ]
      },
      {
        id: "279",
        name: "Huyện Thường Tín",
        wards: [
          {
            id: "10183",
            name: "Thị trấn Thường Tín",
            level: "Thị trấn"
          },
          {
            id: "10186",
            name: "Xã Ninh Sở",
            level: "Xã"
          },
          {
            id: "10189",
            name: "Xã Nhị Khê",
            level: "Xã"
          },
          {
            id: "10192",
            name: "Xã Duyên Thái",
            level: "Xã"
          },
          {
            id: "10195",
            name: "Xã Khánh Hà",
            level: "Xã"
          },
          {
            id: "10198",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "10201",
            name: "Xã Văn Bình",
            level: "Xã"
          },
          {
            id: "10204",
            name: "Xã Hiền Giang",
            level: "Xã"
          },
          {
            id: "10207",
            name: "Xã Hồng Vân",
            level: "Xã"
          },
          {
            id: "10210",
            name: "Xã Vân Tảo",
            level: "Xã"
          },
          {
            id: "10213",
            name: "Xã Liên Phương",
            level: "Xã"
          },
          {
            id: "10216",
            name: "Xã Văn Phú",
            level: "Xã"
          },
          {
            id: "10219",
            name: "Xã Tự Nhiên",
            level: "Xã"
          },
          {
            id: "10222",
            name: "Xã Tiền Phong",
            level: "Xã"
          },
          {
            id: "10225",
            name: "Xã Hà Hồi",
            level: "Xã"
          },
          {
            id: "10228",
            name: "Xã Thư Phú",
            level: "Xã"
          },
          {
            id: "10231",
            name: "Xã Nguyễn Trãi",
            level: "Xã"
          },
          {
            id: "10234",
            name: "Xã Quất Động",
            level: "Xã"
          },
          {
            id: "10237",
            name: "Xã Chương Dương",
            level: "Xã"
          },
          {
            id: "10240",
            name: "Xã Tân Minh",
            level: "Xã"
          },
          {
            id: "10243",
            name: "Xã Lê Lợi",
            level: "Xã"
          },
          {
            id: "10246",
            name: "Xã Thắng Lợi",
            level: "Xã"
          },
          {
            id: "10249",
            name: "Xã Dũng Tiến",
            level: "Xã"
          },
          {
            id: "10252",
            name: "Xã Thống Nhất",
            level: "Xã"
          },
          {
            id: "10255",
            name: "Xã Nghiêm Xuyên",
            level: "Xã"
          },
          {
            id: "10258",
            name: "Xã Tô Hiệu",
            level: "Xã"
          },
          {
            id: "10261",
            name: "Xã Văn Tự",
            level: "Xã"
          },
          {
            id: "10264",
            name: "Xã Vạn Điểm",
            level: "Xã"
          },
          {
            id: "10267",
            name: "Xã Minh Cường",
            level: "Xã"
          }
        ]
      },
      {
        id: "280",
        name: "Huyện Phú Xuyên",
        wards: [
          {
            id: "10270",
            name: "Thị trấn Phú Minh",
            level: "Thị trấn"
          },
          {
            id: "10273",
            name: "Thị trấn Phú Xuyên",
            level: "Thị trấn"
          },
          {
            id: "10276",
            name: "Xã Hồng Minh",
            level: "Xã"
          },
          {
            id: "10279",
            name: "Xã Phượng Dực",
            level: "Xã"
          },
          {
            id: "10282",
            name: "Xã Nam Tiến",
            level: "Xã"
          },
          {
            id: "10288",
            name: "Xã Tri Trung",
            level: "Xã"
          },
          {
            id: "10291",
            name: "Xã Đại Thắng",
            level: "Xã"
          },
          {
            id: "10294",
            name: "Xã Phú Túc",
            level: "Xã"
          },
          {
            id: "10297",
            name: "Xã Văn Hoàng",
            level: "Xã"
          },
          {
            id: "10300",
            name: "Xã Hồng Thái",
            level: "Xã"
          },
          {
            id: "10303",
            name: "Xã Hoàng Long",
            level: "Xã"
          },
          {
            id: "10306",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "10309",
            name: "Xã Nam Phong",
            level: "Xã"
          },
          {
            id: "10312",
            name: "Xã Nam Triều",
            level: "Xã"
          },
          {
            id: "10315",
            name: "Xã Tân Dân",
            level: "Xã"
          },
          {
            id: "10318",
            name: "Xã Sơn Hà",
            level: "Xã"
          },
          {
            id: "10321",
            name: "Xã Chuyên Mỹ",
            level: "Xã"
          },
          {
            id: "10324",
            name: "Xã Khai Thái",
            level: "Xã"
          },
          {
            id: "10327",
            name: "Xã Phúc Tiến",
            level: "Xã"
          },
          {
            id: "10330",
            name: "Xã Vân Từ",
            level: "Xã"
          },
          {
            id: "10333",
            name: "Xã Tri Thủy",
            level: "Xã"
          },
          {
            id: "10336",
            name: "Xã Đại Xuyên",
            level: "Xã"
          },
          {
            id: "10339",
            name: "Xã Phú Yên",
            level: "Xã"
          },
          {
            id: "10342",
            name: "Xã Bạch Hạ",
            level: "Xã"
          },
          {
            id: "10345",
            name: "Xã Quang Lãng",
            level: "Xã"
          },
          {
            id: "10348",
            name: "Xã Châu Can",
            level: "Xã"
          },
          {
            id: "10351",
            name: "Xã Minh Tân",
            level: "Xã"
          }
        ]
      },
      {
        id: "281",
        name: "Huyện Ứng Hòa",
        wards: [
          {
            id: "10354",
            name: "Thị trấn Vân Đình",
            level: "Thị trấn"
          },
          {
            id: "10357",
            name: "Xã Viên An",
            level: "Xã"
          },
          {
            id: "10360",
            name: "Xã Viên Nội",
            level: "Xã"
          },
          {
            id: "10363",
            name: "Xã Hoa Sơn",
            level: "Xã"
          },
          {
            id: "10366",
            name: "Xã Quảng Phú Cầu",
            level: "Xã"
          },
          {
            id: "10369",
            name: "Xã Trường Thịnh",
            level: "Xã"
          },
          {
            id: "10372",
            name: "Xã Cao Thành",
            level: "Xã"
          },
          {
            id: "10375",
            name: "Xã Liên Bạt",
            level: "Xã"
          },
          {
            id: "10378",
            name: "Xã Sơn Công",
            level: "Xã"
          },
          {
            id: "10381",
            name: "Xã Đồng Tiến",
            level: "Xã"
          },
          {
            id: "10384",
            name: "Xã Phương Tú",
            level: "Xã"
          },
          {
            id: "10387",
            name: "Xã Trung Tú",
            level: "Xã"
          },
          {
            id: "10390",
            name: "Xã Đồng Tân",
            level: "Xã"
          },
          {
            id: "10393",
            name: "Xã Tảo Dương Văn",
            level: "Xã"
          },
          {
            id: "10396",
            name: "Xã Vạn Thái",
            level: "Xã"
          },
          {
            id: "10399",
            name: "Xã Minh Đức",
            level: "Xã"
          },
          {
            id: "10402",
            name: "Xã Hòa Lâm",
            level: "Xã"
          },
          {
            id: "10405",
            name: "Xã Hòa Xá",
            level: "Xã"
          },
          {
            id: "10408",
            name: "Xã Trầm Lộng",
            level: "Xã"
          },
          {
            id: "10411",
            name: "Xã Kim Đường",
            level: "Xã"
          },
          {
            id: "10414",
            name: "Xã Hòa Nam",
            level: "Xã"
          },
          {
            id: "10417",
            name: "Xã Hòa Phú",
            level: "Xã"
          },
          {
            id: "10420",
            name: "Xã Đội Bình",
            level: "Xã"
          },
          {
            id: "10423",
            name: "Xã Đại Hùng",
            level: "Xã"
          },
          {
            id: "10426",
            name: "Xã Đông Lỗ",
            level: "Xã"
          },
          {
            id: "10429",
            name: "Xã Phù Lưu",
            level: "Xã"
          },
          {
            id: "10432",
            name: "Xã Đại Cường",
            level: "Xã"
          },
          {
            id: "10435",
            name: "Xã Lưu Hoàng",
            level: "Xã"
          },
          {
            id: "10438",
            name: "Xã Hồng Quang",
            level: "Xã"
          }
        ]
      },
      {
        id: "282",
        name: "Huyện Mỹ Đức",
        wards: [
          {
            id: "10441",
            name: "Thị trấn Đại Nghĩa",
            level: "Thị trấn"
          },
          {
            id: "10444",
            name: "Xã Đồng Tâm",
            level: "Xã"
          },
          {
            id: "10447",
            name: "Xã Thượng Lâm",
            level: "Xã"
          },
          {
            id: "10450",
            name: "Xã Tuy Lai",
            level: "Xã"
          },
          {
            id: "10453",
            name: "Xã Phúc Lâm",
            level: "Xã"
          },
          {
            id: "10456",
            name: "Xã Mỹ Thành",
            level: "Xã"
          },
          {
            id: "10459",
            name: "Xã Bột Xuyên",
            level: "Xã"
          },
          {
            id: "10462",
            name: "Xã An Mỹ",
            level: "Xã"
          },
          {
            id: "10465",
            name: "Xã Hồng Sơn",
            level: "Xã"
          },
          {
            id: "10468",
            name: "Xã Lê Thanh",
            level: "Xã"
          },
          {
            id: "10471",
            name: "Xã Xuy Xá",
            level: "Xã"
          },
          {
            id: "10474",
            name: "Xã Phùng Xá",
            level: "Xã"
          },
          {
            id: "10477",
            name: "Xã Phù Lưu Tế",
            level: "Xã"
          },
          {
            id: "10480",
            name: "Xã Đại Hưng",
            level: "Xã"
          },
          {
            id: "10483",
            name: "Xã Vạn Kim",
            level: "Xã"
          },
          {
            id: "10486",
            name: "Xã Đốc Tín",
            level: "Xã"
          },
          {
            id: "10489",
            name: "Xã Hương Sơn",
            level: "Xã"
          },
          {
            id: "10492",
            name: "Xã Hùng Tiến",
            level: "Xã"
          },
          {
            id: "10495",
            name: "Xã An Tiến",
            level: "Xã"
          },
          {
            id: "10498",
            name: "Xã Hợp Tiến",
            level: "Xã"
          },
          {
            id: "10501",
            name: "Xã Hợp Thanh",
            level: "Xã"
          },
          {
            id: "10504",
            name: "Xã An Phú",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "02",
    name: "Tỉnh Hà Giang",
    districts: [
      {
        id: "024",
        name: "Thành phố Hà Giang",
        wards: [
          {
            id: "00688",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "00691",
            name: "Phường Trần Phú",
            level: "Phường"
          },
          {
            id: "00692",
            name: "Phường Ngọc Hà",
            level: "Phường"
          },
          {
            id: "00694",
            name: "Phường Nguyễn Trãi",
            level: "Phường"
          },
          {
            id: "00697",
            name: "Phường Minh Khai",
            level: "Phường"
          },
          {
            id: "00700",
            name: "Xã Ngọc Đường",
            level: "Xã"
          },
          {
            id: "00946",
            name: "Xã Phương Độ",
            level: "Xã"
          },
          {
            id: "00949",
            name: "Xã Phương Thiện",
            level: "Xã"
          }
        ]
      },
      {
        id: "026",
        name: "Huyện Đồng Văn",
        wards: [
          {
            id: "00712",
            name: "Thị trấn Phó Bảng",
            level: "Thị trấn"
          },
          {
            id: "00715",
            name: "Xã Lũng Cú",
            level: "Xã"
          },
          {
            id: "00718",
            name: "Xã Má Lé",
            level: "Xã"
          },
          {
            id: "00721",
            name: "Thị trấn Đồng Văn",
            level: "Thị trấn"
          },
          {
            id: "00724",
            name: "Xã Lũng Táo",
            level: "Xã"
          },
          {
            id: "00727",
            name: "Xã Phố Là",
            level: "Xã"
          },
          {
            id: "00730",
            name: "Xã Thài Phìn Tủng",
            level: "Xã"
          },
          {
            id: "00733",
            name: "Xã Sủng Là",
            level: "Xã"
          },
          {
            id: "00736",
            name: "Xã Xà Phìn",
            level: "Xã"
          },
          {
            id: "00739",
            name: "Xã Tả Phìn",
            level: "Xã"
          },
          {
            id: "00742",
            name: "Xã Tả Lủng",
            level: "Xã"
          },
          {
            id: "00745",
            name: "Xã Phố Cáo",
            level: "Xã"
          },
          {
            id: "00748",
            name: "Xã Sính Lủng",
            level: "Xã"
          },
          {
            id: "00751",
            name: "Xã Sảng Tủng",
            level: "Xã"
          },
          {
            id: "00754",
            name: "Xã Lũng Thầu",
            level: "Xã"
          },
          {
            id: "00757",
            name: "Xã Hố Quáng Phìn",
            level: "Xã"
          },
          {
            id: "00760",
            name: "Xã Vần Chải",
            level: "Xã"
          },
          {
            id: "00763",
            name: "Xã Lũng Phìn",
            level: "Xã"
          },
          {
            id: "00766",
            name: "Xã Sủng Trái",
            level: "Xã"
          }
        ]
      },
      {
        id: "027",
        name: "Huyện Mèo Vạc",
        wards: [
          {
            id: "00769",
            name: "Thị trấn Mèo Vạc",
            level: "Thị trấn"
          },
          {
            id: "00772",
            name: "Xã Thượng Phùng",
            level: "Xã"
          },
          {
            id: "00775",
            name: "Xã Pải Lủng",
            level: "Xã"
          },
          {
            id: "00778",
            name: "Xã Xín Cái",
            level: "Xã"
          },
          {
            id: "00781",
            name: "Xã Pả Vi",
            level: "Xã"
          },
          {
            id: "00784",
            name: "Xã Giàng Chu Phìn",
            level: "Xã"
          },
          {
            id: "00787",
            name: "Xã Sủng Trà",
            level: "Xã"
          },
          {
            id: "00790",
            name: "Xã Sủng Máng",
            level: "Xã"
          },
          {
            id: "00793",
            name: "Xã Sơn Vĩ",
            level: "Xã"
          },
          {
            id: "00796",
            name: "Xã Tả Lủng",
            level: "Xã"
          },
          {
            id: "00799",
            name: "Xã Cán Chu Phìn",
            level: "Xã"
          },
          {
            id: "00802",
            name: "Xã Lũng Pù",
            level: "Xã"
          },
          {
            id: "00805",
            name: "Xã Lũng Chinh",
            level: "Xã"
          },
          {
            id: "00808",
            name: "Xã Tát Ngà",
            level: "Xã"
          },
          {
            id: "00811",
            name: "Xã Nậm Ban",
            level: "Xã"
          },
          {
            id: "00814",
            name: "Xã Khâu Vai",
            level: "Xã"
          },
          {
            id: "00815",
            name: "Xã Niêm Tòng",
            level: "Xã"
          },
          {
            id: "00817",
            name: "Xã Niêm Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "028",
        name: "Huyện Yên Minh",
        wards: [
          {
            id: "00820",
            name: "Thị trấn Yên Minh",
            level: "Thị trấn"
          },
          {
            id: "00823",
            name: "Xã Thắng Mố",
            level: "Xã"
          },
          {
            id: "00826",
            name: "Xã Phú Lũng",
            level: "Xã"
          },
          {
            id: "00829",
            name: "Xã Sủng Tráng",
            level: "Xã"
          },
          {
            id: "00832",
            name: "Xã Bạch Đích",
            level: "Xã"
          },
          {
            id: "00835",
            name: "Xã Na Khê",
            level: "Xã"
          },
          {
            id: "00838",
            name: "Xã Sủng Thài",
            level: "Xã"
          },
          {
            id: "00841",
            name: "Xã Hữu Vinh",
            level: "Xã"
          },
          {
            id: "00844",
            name: "Xã Lao Và Chải",
            level: "Xã"
          },
          {
            id: "00847",
            name: "Xã Mậu Duệ",
            level: "Xã"
          },
          {
            id: "00850",
            name: "Xã Đông Minh",
            level: "Xã"
          },
          {
            id: "00853",
            name: "Xã Mậu Long",
            level: "Xã"
          },
          {
            id: "00856",
            name: "Xã Ngam La",
            level: "Xã"
          },
          {
            id: "00859",
            name: "Xã Ngọc Long",
            level: "Xã"
          },
          {
            id: "00862",
            name: "Xã Đường Thượng",
            level: "Xã"
          },
          {
            id: "00865",
            name: "Xã Lũng Hồ",
            level: "Xã"
          },
          {
            id: "00868",
            name: "Xã Du Tiến",
            level: "Xã"
          },
          {
            id: "00871",
            name: "Xã Du Già",
            level: "Xã"
          }
        ]
      },
      {
        id: "029",
        name: "Huyện Quản Bạ",
        wards: [
          {
            id: "00874",
            name: "Thị trấn Tam Sơn",
            level: "Thị trấn"
          },
          {
            id: "00877",
            name: "Xã Bát Đại Sơn",
            level: "Xã"
          },
          {
            id: "00880",
            name: "Xã Nghĩa Thuận",
            level: "Xã"
          },
          {
            id: "00883",
            name: "Xã Cán Tỷ",
            level: "Xã"
          },
          {
            id: "00886",
            name: "Xã Cao Mã Pờ",
            level: "Xã"
          },
          {
            id: "00889",
            name: "Xã Thanh Vân",
            level: "Xã"
          },
          {
            id: "00892",
            name: "Xã Tùng Vài",
            level: "Xã"
          },
          {
            id: "00895",
            name: "Xã Đông Hà",
            level: "Xã"
          },
          {
            id: "00898",
            name: "Xã Quản Bạ",
            level: "Xã"
          },
          {
            id: "00901",
            name: "Xã Lùng Tám",
            level: "Xã"
          },
          {
            id: "00904",
            name: "Xã Quyết Tiến",
            level: "Xã"
          },
          {
            id: "00907",
            name: "Xã Tả Ván",
            level: "Xã"
          },
          {
            id: "00910",
            name: "Xã Thái An",
            level: "Xã"
          }
        ]
      },
      {
        id: "030",
        name: "Huyện Vị Xuyên",
        wards: [
          {
            id: "00703",
            name: "Xã Kim Thạch",
            level: "Xã"
          },
          {
            id: "00706",
            name: "Xã Phú Linh",
            level: "Xã"
          },
          {
            id: "00709",
            name: "Xã Kim Linh",
            level: "Xã"
          },
          {
            id: "00913",
            name: "Thị trấn Vị Xuyên",
            level: "Thị trấn"
          },
          {
            id: "00916",
            name: "Thị trấn Nông Trường Việt Lâm",
            level: "Thị trấn"
          },
          {
            id: "00919",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "00922",
            name: "Xã Thuận Hoà",
            level: "Xã"
          },
          {
            id: "00925",
            name: "Xã Tùng Bá",
            level: "Xã"
          },
          {
            id: "00928",
            name: "Xã Thanh Thủy",
            level: "Xã"
          },
          {
            id: "00931",
            name: "Xã Thanh Đức",
            level: "Xã"
          },
          {
            id: "00934",
            name: "Xã Phong Quang",
            level: "Xã"
          },
          {
            id: "00937",
            name: "Xã Xín Chải",
            level: "Xã"
          },
          {
            id: "00940",
            name: "Xã Phương Tiến",
            level: "Xã"
          },
          {
            id: "00943",
            name: "Xã Lao Chải",
            level: "Xã"
          },
          {
            id: "00952",
            name: "Xã Cao Bồ",
            level: "Xã"
          },
          {
            id: "00955",
            name: "Xã Đạo Đức",
            level: "Xã"
          },
          {
            id: "00958",
            name: "Xã Thượng Sơn",
            level: "Xã"
          },
          {
            id: "00961",
            name: "Xã Linh Hồ",
            level: "Xã"
          },
          {
            id: "00964",
            name: "Xã Quảng Ngần",
            level: "Xã"
          },
          {
            id: "00967",
            name: "Xã Việt Lâm",
            level: "Xã"
          },
          {
            id: "00970",
            name: "Xã Ngọc Linh",
            level: "Xã"
          },
          {
            id: "00973",
            name: "Xã Ngọc Minh",
            level: "Xã"
          },
          {
            id: "00976",
            name: "Xã Bạch Ngọc",
            level: "Xã"
          },
          {
            id: "00979",
            name: "Xã Trung Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "031",
        name: "Huyện Bắc Mê",
        wards: [
          {
            id: "00982",
            name: "Xã Minh Sơn",
            level: "Xã"
          },
          {
            id: "00985",
            name: "Xã Giáp Trung",
            level: "Xã"
          },
          {
            id: "00988",
            name: "Xã Yên Định",
            level: "Xã"
          },
          {
            id: "00991",
            name: "Thị trấn Yên Phú",
            level: "Thị trấn"
          },
          {
            id: "00994",
            name: "Xã Minh Ngọc",
            level: "Xã"
          },
          {
            id: "00997",
            name: "Xã Yên Phong",
            level: "Xã"
          },
          {
            id: "01000",
            name: "Xã Lạc Nông",
            level: "Xã"
          },
          {
            id: "01003",
            name: "Xã Phú Nam",
            level: "Xã"
          },
          {
            id: "01006",
            name: "Xã Yên Cường",
            level: "Xã"
          },
          {
            id: "01009",
            name: "Xã Thượng Tân",
            level: "Xã"
          },
          {
            id: "01012",
            name: "Xã Đường Âm",
            level: "Xã"
          },
          {
            id: "01015",
            name: "Xã Đường Hồng",
            level: "Xã"
          },
          {
            id: "01018",
            name: "Xã Phiêng Luông",
            level: "Xã"
          }
        ]
      },
      {
        id: "032",
        name: "Huyện Hoàng Su Phì",
        wards: [
          {
            id: "01021",
            name: "Thị trấn Vinh Quang",
            level: "Thị trấn"
          },
          {
            id: "01024",
            name: "Xã Bản Máy",
            level: "Xã"
          },
          {
            id: "01027",
            name: "Xã Thàng Tín",
            level: "Xã"
          },
          {
            id: "01030",
            name: "Xã Thèn Chu Phìn",
            level: "Xã"
          },
          {
            id: "01033",
            name: "Xã Pố Lồ",
            level: "Xã"
          },
          {
            id: "01036",
            name: "Xã Bản Phùng",
            level: "Xã"
          },
          {
            id: "01039",
            name: "Xã Túng Sán",
            level: "Xã"
          },
          {
            id: "01042",
            name: "Xã Chiến Phố",
            level: "Xã"
          },
          {
            id: "01045",
            name: "Xã Đản Ván",
            level: "Xã"
          },
          {
            id: "01048",
            name: "Xã Tụ Nhân",
            level: "Xã"
          },
          {
            id: "01051",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "01054",
            name: "Xã Nàng Đôn",
            level: "Xã"
          },
          {
            id: "01057",
            name: "Xã Pờ Ly Ngài",
            level: "Xã"
          },
          {
            id: "01060",
            name: "Xã Sán Xả Hồ",
            level: "Xã"
          },
          {
            id: "01063",
            name: "Xã Bản Luốc",
            level: "Xã"
          },
          {
            id: "01066",
            name: "Xã Ngàm Đăng Vài",
            level: "Xã"
          },
          {
            id: "01069",
            name: "Xã Bản Nhùng",
            level: "Xã"
          },
          {
            id: "01072",
            name: "Xã Tả Sử Choóng",
            level: "Xã"
          },
          {
            id: "01075",
            name: "Xã Nậm Dịch",
            level: "Xã"
          },
          {
            id: "01081",
            name: "Xã Hồ Thầu",
            level: "Xã"
          },
          {
            id: "01084",
            name: "Xã Nam Sơn",
            level: "Xã"
          },
          {
            id: "01087",
            name: "Xã Nậm Tỵ",
            level: "Xã"
          },
          {
            id: "01090",
            name: "Xã Thông Nguyên",
            level: "Xã"
          },
          {
            id: "01093",
            name: "Xã Nậm Khòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "033",
        name: "Huyện Xín Mần",
        wards: [
          {
            id: "01096",
            name: "Thị trấn Cốc Pài",
            level: "Thị trấn"
          },
          {
            id: "01099",
            name: "Xã Nàn Xỉn",
            level: "Xã"
          },
          {
            id: "01102",
            name: "Xã Bản Díu",
            level: "Xã"
          },
          {
            id: "01105",
            name: "Xã Chí Cà",
            level: "Xã"
          },
          {
            id: "01108",
            name: "Xã Xín Mần",
            level: "Xã"
          },
          {
            id: "01114",
            name: "Xã Thèn Phàng",
            level: "Xã"
          },
          {
            id: "01117",
            name: "Xã Trung Thịnh",
            level: "Xã"
          },
          {
            id: "01120",
            name: "Xã Pà Vầy Sủ",
            level: "Xã"
          },
          {
            id: "01123",
            name: "Xã Cốc Rế",
            level: "Xã"
          },
          {
            id: "01126",
            name: "Xã Thu Tà",
            level: "Xã"
          },
          {
            id: "01129",
            name: "Xã Nàn Ma",
            level: "Xã"
          },
          {
            id: "01132",
            name: "Xã Tả Nhìu",
            level: "Xã"
          },
          {
            id: "01135",
            name: "Xã Bản Ngò",
            level: "Xã"
          },
          {
            id: "01138",
            name: "Xã Chế Là",
            level: "Xã"
          },
          {
            id: "01141",
            name: "Xã Nấm Dẩn",
            level: "Xã"
          },
          {
            id: "01144",
            name: "Xã Quảng Nguyên",
            level: "Xã"
          },
          {
            id: "01147",
            name: "Xã Nà Chì",
            level: "Xã"
          },
          {
            id: "01150",
            name: "Xã Khuôn Lùng",
            level: "Xã"
          }
        ]
      },
      {
        id: "034",
        name: "Huyện Bắc Quang",
        wards: [
          {
            id: "01153",
            name: "Thị trấn Việt Quang",
            level: "Thị trấn"
          },
          {
            id: "01156",
            name: "Thị trấn Vĩnh Tuy",
            level: "Thị trấn"
          },
          {
            id: "01159",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "01162",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "01165",
            name: "Xã Đồng Tiến",
            level: "Xã"
          },
          {
            id: "01168",
            name: "Xã Đồng Tâm",
            level: "Xã"
          },
          {
            id: "01171",
            name: "Xã Tân Quang",
            level: "Xã"
          },
          {
            id: "01174",
            name: "Xã Thượng Bình",
            level: "Xã"
          },
          {
            id: "01177",
            name: "Xã Hữu Sản",
            level: "Xã"
          },
          {
            id: "01180",
            name: "Xã Kim Ngọc",
            level: "Xã"
          },
          {
            id: "01183",
            name: "Xã Việt Vinh",
            level: "Xã"
          },
          {
            id: "01186",
            name: "Xã Bằng Hành",
            level: "Xã"
          },
          {
            id: "01189",
            name: "Xã Quang Minh",
            level: "Xã"
          },
          {
            id: "01192",
            name: "Xã Liên Hiệp",
            level: "Xã"
          },
          {
            id: "01195",
            name: "Xã Vô Điếm",
            level: "Xã"
          },
          {
            id: "01198",
            name: "Xã Việt Hồng",
            level: "Xã"
          },
          {
            id: "01201",
            name: "Xã Hùng An",
            level: "Xã"
          },
          {
            id: "01204",
            name: "Xã Đức Xuân",
            level: "Xã"
          },
          {
            id: "01207",
            name: "Xã Tiên Kiều",
            level: "Xã"
          },
          {
            id: "01210",
            name: "Xã Vĩnh Hảo",
            level: "Xã"
          },
          {
            id: "01213",
            name: "Xã Vĩnh Phúc",
            level: "Xã"
          },
          {
            id: "01216",
            name: "Xã Đồng Yên",
            level: "Xã"
          },
          {
            id: "01219",
            name: "Xã Đông Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "035",
        name: "Huyện Quang Bình",
        wards: [
          {
            id: "01222",
            name: "Xã Xuân Minh",
            level: "Xã"
          },
          {
            id: "01225",
            name: "Xã Tiên Nguyên",
            level: "Xã"
          },
          {
            id: "01228",
            name: "Xã Tân Nam",
            level: "Xã"
          },
          {
            id: "01231",
            name: "Xã Bản Rịa",
            level: "Xã"
          },
          {
            id: "01234",
            name: "Xã Yên Thành",
            level: "Xã"
          },
          {
            id: "01237",
            name: "Thị trấn Yên Bình",
            level: "Thị trấn"
          },
          {
            id: "01240",
            name: "Xã Tân Trịnh",
            level: "Xã"
          },
          {
            id: "01243",
            name: "Xã Tân Bắc",
            level: "Xã"
          },
          {
            id: "01246",
            name: "Xã Bằng Lang",
            level: "Xã"
          },
          {
            id: "01249",
            name: "Xã Yên Hà",
            level: "Xã"
          },
          {
            id: "01252",
            name: "Xã Hương Sơn",
            level: "Xã"
          },
          {
            id: "01255",
            name: "Xã Xuân Giang",
            level: "Xã"
          },
          {
            id: "01258",
            name: "Xã Nà Khương",
            level: "Xã"
          },
          {
            id: "01261",
            name: "Xã Tiên Yên",
            level: "Xã"
          },
          {
            id: "01264",
            name: "Xã Vĩ Thượng",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "04",
    name: "Tỉnh Cao Bằng",
    districts: [
      {
        id: "040",
        name: "Thành phố Cao Bằng",
        wards: [
          {
            id: "01267",
            name: "Phường Sông Hiến",
            level: "Phường"
          },
          {
            id: "01270",
            name: "Phường Sông Bằng",
            level: "Phường"
          },
          {
            id: "01273",
            name: "Phường Hợp Giang",
            level: "Phường"
          },
          {
            id: "01276",
            name: "Phường Tân Giang",
            level: "Phường"
          },
          {
            id: "01279",
            name: "Phường Ngọc Xuân",
            level: "Phường"
          },
          {
            id: "01282",
            name: "Phường Đề Thám",
            level: "Phường"
          },
          {
            id: "01285",
            name: "Phường Hoà Chung",
            level: "Phường"
          },
          {
            id: "01288",
            name: "Phường Duyệt Trung",
            level: "Phường"
          },
          {
            id: "01693",
            name: "Xã Vĩnh Quang",
            level: "Xã"
          },
          {
            id: "01705",
            name: "Xã Hưng Đạo",
            level: "Xã"
          },
          {
            id: "01720",
            name: "Xã Chu Trinh",
            level: "Xã"
          }
        ]
      },
      {
        id: "042",
        name: "Huyện Bảo Lâm",
        wards: [
          {
            id: "01290",
            name: "Thị trấn Pác Miầu",
            level: "Thị trấn"
          },
          {
            id: "01291",
            name: "Xã Đức Hạnh",
            level: "Xã"
          },
          {
            id: "01294",
            name: "Xã Lý Bôn",
            level: "Xã"
          },
          {
            id: "01296",
            name: "Xã Nam Cao",
            level: "Xã"
          },
          {
            id: "01297",
            name: "Xã Nam Quang",
            level: "Xã"
          },
          {
            id: "01300",
            name: "Xã Vĩnh Quang",
            level: "Xã"
          },
          {
            id: "01303",
            name: "Xã Quảng Lâm",
            level: "Xã"
          },
          {
            id: "01304",
            name: "Xã Thạch Lâm",
            level: "Xã"
          },
          {
            id: "01309",
            name: "Xã Vĩnh Phong",
            level: "Xã"
          },
          {
            id: "01312",
            name: "Xã Mông Ân",
            level: "Xã"
          },
          {
            id: "01315",
            name: "Xã Thái Học",
            level: "Xã"
          },
          {
            id: "01316",
            name: "Xã Thái Sơn",
            level: "Xã"
          },
          {
            id: "01318",
            name: "Xã Yên Thổ",
            level: "Xã"
          }
        ]
      },
      {
        id: "043",
        name: "Huyện Bảo Lạc",
        wards: [
          {
            id: "01321",
            name: "Thị trấn Bảo Lạc",
            level: "Thị trấn"
          },
          {
            id: "01324",
            name: "Xã Cốc Pàng",
            level: "Xã"
          },
          {
            id: "01327",
            name: "Xã Thượng Hà",
            level: "Xã"
          },
          {
            id: "01330",
            name: "Xã Cô Ba",
            level: "Xã"
          },
          {
            id: "01333",
            name: "Xã Bảo Toàn",
            level: "Xã"
          },
          {
            id: "01336",
            name: "Xã Khánh Xuân",
            level: "Xã"
          },
          {
            id: "01339",
            name: "Xã Xuân Trường",
            level: "Xã"
          },
          {
            id: "01342",
            name: "Xã Hồng Trị",
            level: "Xã"
          },
          {
            id: "01343",
            name: "Xã Kim Cúc",
            level: "Xã"
          },
          {
            id: "01345",
            name: "Xã Phan Thanh",
            level: "Xã"
          },
          {
            id: "01348",
            name: "Xã Hồng An",
            level: "Xã"
          },
          {
            id: "01351",
            name: "Xã Hưng Đạo",
            level: "Xã"
          },
          {
            id: "01352",
            name: "Xã Hưng Thịnh",
            level: "Xã"
          },
          {
            id: "01354",
            name: "Xã Huy Giáp",
            level: "Xã"
          },
          {
            id: "01357",
            name: "Xã Đình Phùng",
            level: "Xã"
          },
          {
            id: "01359",
            name: "Xã Sơn Lập",
            level: "Xã"
          },
          {
            id: "01360",
            name: "Xã Sơn Lộ",
            level: "Xã"
          }
        ]
      },
      {
        id: "045",
        name: "Huyện Hà Quảng",
        wards: [
          {
            id: "01363",
            name: "Thị trấn Thông Nông",
            level: "Thị trấn"
          },
          {
            id: "01366",
            name: "Xã Cần Yên",
            level: "Xã"
          },
          {
            id: "01367",
            name: "Xã Cần Nông",
            level: "Xã"
          },
          {
            id: "01372",
            name: "Xã Lương Thông",
            level: "Xã"
          },
          {
            id: "01375",
            name: "Xã Đa Thông",
            level: "Xã"
          },
          {
            id: "01378",
            name: "Xã Ngọc Động",
            level: "Xã"
          },
          {
            id: "01381",
            name: "Xã Yên Sơn",
            level: "Xã"
          },
          {
            id: "01384",
            name: "Xã Lương Can",
            level: "Xã"
          },
          {
            id: "01387",
            name: "Xã Thanh Long",
            level: "Xã"
          },
          {
            id: "01392",
            name: "Thị trấn Xuân Hòa",
            level: "Thị trấn"
          },
          {
            id: "01393",
            name: "Xã Lũng Nặm",
            level: "Xã"
          },
          {
            id: "01399",
            name: "Xã Trường Hà",
            level: "Xã"
          },
          {
            id: "01402",
            name: "Xã Cải Viên",
            level: "Xã"
          },
          {
            id: "01411",
            name: "Xã Nội Thôn",
            level: "Xã"
          },
          {
            id: "01414",
            name: "Xã Tổng Cọt",
            level: "Xã"
          },
          {
            id: "01417",
            name: "Xã Sóc Hà",
            level: "Xã"
          },
          {
            id: "01420",
            name: "Xã Thượng Thôn",
            level: "Xã"
          },
          {
            id: "01429",
            name: "Xã Hồng Sỹ",
            level: "Xã"
          },
          {
            id: "01432",
            name: "Xã Quý Quân",
            level: "Xã"
          },
          {
            id: "01435",
            name: "Xã Mã Ba",
            level: "Xã"
          },
          {
            id: "01438",
            name: "Xã Ngọc Đào",
            level: "Xã"
          }
        ]
      },
      {
        id: "047",
        name: "Huyện Trùng Khánh",
        wards: [
          {
            id: "01447",
            name: "Thị trấn Trà Lĩnh",
            level: "Thị trấn"
          },
          {
            id: "01453",
            name: "Xã Tri Phương",
            level: "Xã"
          },
          {
            id: "01456",
            name: "Xã Quang Hán",
            level: "Xã"
          },
          {
            id: "01462",
            name: "Xã Xuân Nội",
            level: "Xã"
          },
          {
            id: "01465",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "01468",
            name: "Xã Quang Vinh",
            level: "Xã"
          },
          {
            id: "01471",
            name: "Xã Cao Chương",
            level: "Xã"
          },
          {
            id: "01477",
            name: "Thị trấn Trùng Khánh",
            level: "Thị trấn"
          },
          {
            id: "01480",
            name: "Xã Ngọc Khê",
            level: "Xã"
          },
          {
            id: "01481",
            name: "Xã Ngọc Côn",
            level: "Xã"
          },
          {
            id: "01483",
            name: "Xã Phong Nậm",
            level: "Xã"
          },
          {
            id: "01489",
            name: "Xã Đình Phong",
            level: "Xã"
          },
          {
            id: "01495",
            name: "Xã Đàm Thuỷ",
            level: "Xã"
          },
          {
            id: "01498",
            name: "Xã Khâm Thành",
            level: "Xã"
          },
          {
            id: "01501",
            name: "Xã Chí Viễn",
            level: "Xã"
          },
          {
            id: "01504",
            name: "Xã Lăng Hiếu",
            level: "Xã"
          },
          {
            id: "01507",
            name: "Xã Phong Châu",
            level: "Xã"
          },
          {
            id: "01516",
            name: "Xã Trung Phúc",
            level: "Xã"
          },
          {
            id: "01519",
            name: "Xã Cao Thăng",
            level: "Xã"
          },
          {
            id: "01522",
            name: "Xã Đức Hồng",
            level: "Xã"
          },
          {
            id: "01525",
            name: "Xã Đoài Dương",
            level: "Xã"
          }
        ]
      },
      {
        id: "048",
        name: "Huyện Hạ Lang",
        wards: [
          {
            id: "01534",
            name: "Xã Minh Long",
            level: "Xã"
          },
          {
            id: "01537",
            name: "Xã Lý Quốc",
            level: "Xã"
          },
          {
            id: "01540",
            name: "Xã Thắng Lợi",
            level: "Xã"
          },
          {
            id: "01543",
            name: "Xã Đồng Loan",
            level: "Xã"
          },
          {
            id: "01546",
            name: "Xã Đức Quang",
            level: "Xã"
          },
          {
            id: "01549",
            name: "Xã Kim Loan",
            level: "Xã"
          },
          {
            id: "01552",
            name: "Xã Quang Long",
            level: "Xã"
          },
          {
            id: "01555",
            name: "Xã An Lạc",
            level: "Xã"
          },
          {
            id: "01558",
            name: "Thị trấn Thanh Nhật",
            level: "Thị trấn"
          },
          {
            id: "01561",
            name: "Xã Vinh Quý",
            level: "Xã"
          },
          {
            id: "01564",
            name: "Xã Thống Nhất",
            level: "Xã"
          },
          {
            id: "01567",
            name: "Xã Cô Ngân",
            level: "Xã"
          },
          {
            id: "01573",
            name: "Xã Thị Hoa",
            level: "Xã"
          }
        ]
      },
      {
        id: "049",
        name: "Huyện Quảng Hòa",
        wards: [
          {
            id: "01474",
            name: "Xã Quốc Toản",
            level: "Xã"
          },
          {
            id: "01576",
            name: "Thị trấn Quảng Uyên",
            level: "Thị trấn"
          },
          {
            id: "01579",
            name: "Xã Phi Hải",
            level: "Xã"
          },
          {
            id: "01582",
            name: "Xã Quảng Hưng",
            level: "Xã"
          },
          {
            id: "01594",
            name: "Xã Độc Lập",
            level: "Xã"
          },
          {
            id: "01597",
            name: "Xã Cai Bộ",
            level: "Xã"
          },
          {
            id: "01603",
            name: "Xã Phúc Sen",
            level: "Xã"
          },
          {
            id: "01606",
            name: "Xã Chí Thảo",
            level: "Xã"
          },
          {
            id: "01609",
            name: "Xã Tự Do",
            level: "Xã"
          },
          {
            id: "01615",
            name: "Xã Hồng Quang",
            level: "Xã"
          },
          {
            id: "01618",
            name: "Xã Ngọc Động",
            level: "Xã"
          },
          {
            id: "01624",
            name: "Xã Hạnh Phúc",
            level: "Xã"
          },
          {
            id: "01627",
            name: "Thị trấn Tà Lùng",
            level: "Thị trấn"
          },
          {
            id: "01630",
            name: "Xã Bế Văn Đàn",
            level: "Xã"
          },
          {
            id: "01636",
            name: "Xã Cách Linh",
            level: "Xã"
          },
          {
            id: "01639",
            name: "Xã Đại Sơn",
            level: "Xã"
          },
          {
            id: "01645",
            name: "Xã Tiên Thành",
            level: "Xã"
          },
          {
            id: "01648",
            name: "Thị trấn Hoà Thuận",
            level: "Thị trấn"
          },
          {
            id: "01651",
            name: "Xã Mỹ Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "051",
        name: "Huyện Hoà An",
        wards: [
          {
            id: "01654",
            name: "Thị trấn Nước Hai",
            level: "Thị trấn"
          },
          {
            id: "01657",
            name: "Xã Dân Chủ",
            level: "Xã"
          },
          {
            id: "01660",
            name: "Xã Nam Tuấn",
            level: "Xã"
          },
          {
            id: "01666",
            name: "Xã Đại Tiến",
            level: "Xã"
          },
          {
            id: "01669",
            name: "Xã Đức Long",
            level: "Xã"
          },
          {
            id: "01672",
            name: "Xã Ngũ Lão",
            level: "Xã"
          },
          {
            id: "01675",
            name: "Xã Trương Lương",
            level: "Xã"
          },
          {
            id: "01687",
            name: "Xã Hồng Việt",
            level: "Xã"
          },
          {
            id: "01696",
            name: "Xã Hoàng Tung",
            level: "Xã"
          },
          {
            id: "01699",
            name: "Xã Nguyễn Huệ",
            level: "Xã"
          },
          {
            id: "01702",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "01708",
            name: "Xã Bạch Đằng",
            level: "Xã"
          },
          {
            id: "01711",
            name: "Xã Bình Dương",
            level: "Xã"
          },
          {
            id: "01714",
            name: "Xã Lê Chung",
            level: "Xã"
          },
          {
            id: "01723",
            name: "Xã Hồng Nam",
            level: "Xã"
          }
        ]
      },
      {
        id: "052",
        name: "Huyện Nguyên Bình",
        wards: [
          {
            id: "01726",
            name: "Thị trấn Nguyên Bình",
            level: "Thị trấn"
          },
          {
            id: "01729",
            name: "Thị trấn Tĩnh Túc",
            level: "Thị trấn"
          },
          {
            id: "01732",
            name: "Xã Yên Lạc",
            level: "Xã"
          },
          {
            id: "01735",
            name: "Xã Triệu Nguyên",
            level: "Xã"
          },
          {
            id: "01738",
            name: "Xã Ca Thành",
            level: "Xã"
          },
          {
            id: "01744",
            name: "Xã Vũ Nông",
            level: "Xã"
          },
          {
            id: "01747",
            name: "Xã Minh Tâm",
            level: "Xã"
          },
          {
            id: "01750",
            name: "Xã Thể Dục",
            level: "Xã"
          },
          {
            id: "01756",
            name: "Xã Mai Long",
            level: "Xã"
          },
          {
            id: "01762",
            name: "Xã Vũ Minh",
            level: "Xã"
          },
          {
            id: "01765",
            name: "Xã Hoa Thám",
            level: "Xã"
          },
          {
            id: "01768",
            name: "Xã Phan Thanh",
            level: "Xã"
          },
          {
            id: "01771",
            name: "Xã Quang Thành",
            level: "Xã"
          },
          {
            id: "01774",
            name: "Xã Tam Kim",
            level: "Xã"
          },
          {
            id: "01777",
            name: "Xã Thành Công",
            level: "Xã"
          },
          {
            id: "01780",
            name: "Xã Thịnh Vượng",
            level: "Xã"
          },
          {
            id: "01783",
            name: "Xã Hưng Đạo",
            level: "Xã"
          }
        ]
      },
      {
        id: "053",
        name: "Huyện Thạch An",
        wards: [
          {
            id: "01786",
            name: "Thị trấn Đông Khê",
            level: "Thị trấn"
          },
          {
            id: "01789",
            name: "Xã Canh Tân",
            level: "Xã"
          },
          {
            id: "01792",
            name: "Xã Kim Đồng",
            level: "Xã"
          },
          {
            id: "01795",
            name: "Xã Minh Khai",
            level: "Xã"
          },
          {
            id: "01801",
            name: "Xã Đức Thông",
            level: "Xã"
          },
          {
            id: "01804",
            name: "Xã Thái Cường",
            level: "Xã"
          },
          {
            id: "01807",
            name: "Xã Vân Trình",
            level: "Xã"
          },
          {
            id: "01810",
            name: "Xã Thụy Hùng",
            level: "Xã"
          },
          {
            id: "01813",
            name: "Xã Quang Trọng",
            level: "Xã"
          },
          {
            id: "01816",
            name: "Xã Trọng Con",
            level: "Xã"
          },
          {
            id: "01819",
            name: "Xã Lê Lai",
            level: "Xã"
          },
          {
            id: "01822",
            name: "Xã Đức Long",
            level: "Xã"
          },
          {
            id: "01828",
            name: "Xã Lê Lợi",
            level: "Xã"
          },
          {
            id: "01831",
            name: "Xã Đức Xuân",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "06",
    name: "Tỉnh Bắc Kạn",
    districts: [
      {
        id: "058",
        name: "Thành Phố Bắc Kạn",
        wards: [
          {
            id: "01834",
            name: "Phường Nguyễn Thị Minh Khai",
            level: "Phường"
          },
          {
            id: "01837",
            name: "Phường Sông Cầu",
            level: "Phường"
          },
          {
            id: "01840",
            name: "Phường Đức Xuân",
            level: "Phường"
          },
          {
            id: "01843",
            name: "Phường Phùng Chí Kiên",
            level: "Phường"
          },
          {
            id: "01846",
            name: "Phường Huyền Tụng",
            level: "Phường"
          },
          {
            id: "01849",
            name: "Xã Dương Quang",
            level: "Xã"
          },
          {
            id: "01852",
            name: "Xã Nông Thượng",
            level: "Xã"
          },
          {
            id: "01855",
            name: "Phường Xuất Hóa",
            level: "Phường"
          }
        ]
      },
      {
        id: "060",
        name: "Huyện Pác Nặm",
        wards: [
          {
            id: "01858",
            name: "Xã Bằng Thành",
            level: "Xã"
          },
          {
            id: "01861",
            name: "Xã Nhạn Môn",
            level: "Xã"
          },
          {
            id: "01864",
            name: "Xã Bộc Bố",
            level: "Xã"
          },
          {
            id: "01867",
            name: "Xã Công Bằng",
            level: "Xã"
          },
          {
            id: "01870",
            name: "Xã Giáo Hiệu",
            level: "Xã"
          },
          {
            id: "01873",
            name: "Xã Xuân La",
            level: "Xã"
          },
          {
            id: "01876",
            name: "Xã An Thắng",
            level: "Xã"
          },
          {
            id: "01879",
            name: "Xã Cổ Linh",
            level: "Xã"
          },
          {
            id: "01882",
            name: "Xã Nghiên Loan",
            level: "Xã"
          },
          {
            id: "01885",
            name: "Xã Cao Tân",
            level: "Xã"
          }
        ]
      },
      {
        id: "061",
        name: "Huyện Ba Bể",
        wards: [
          {
            id: "01888",
            name: "Thị trấn Chợ Rã",
            level: "Thị trấn"
          },
          {
            id: "01891",
            name: "Xã Bành Trạch",
            level: "Xã"
          },
          {
            id: "01894",
            name: "Xã Phúc Lộc",
            level: "Xã"
          },
          {
            id: "01897",
            name: "Xã Hà Hiệu",
            level: "Xã"
          },
          {
            id: "01900",
            name: "Xã Cao Thượng",
            level: "Xã"
          },
          {
            id: "01906",
            name: "Xã Khang Ninh",
            level: "Xã"
          },
          {
            id: "01909",
            name: "Xã Nam Mẫu",
            level: "Xã"
          },
          {
            id: "01912",
            name: "Xã Thượng Giáo",
            level: "Xã"
          },
          {
            id: "01915",
            name: "Xã Địa Linh",
            level: "Xã"
          },
          {
            id: "01918",
            name: "Xã Yến Dương",
            level: "Xã"
          },
          {
            id: "01921",
            name: "Xã Chu Hương",
            level: "Xã"
          },
          {
            id: "01924",
            name: "Xã Quảng Khê",
            level: "Xã"
          },
          {
            id: "01927",
            name: "Xã Mỹ Phương",
            level: "Xã"
          },
          {
            id: "01930",
            name: "Xã Hoàng Trĩ",
            level: "Xã"
          },
          {
            id: "01933",
            name: "Xã Đồng Phúc",
            level: "Xã"
          }
        ]
      },
      {
        id: "062",
        name: "Huyện Ngân Sơn",
        wards: [
          {
            id: "01936",
            name: "Thị trấn Nà Phặc",
            level: "Thị trấn"
          },
          {
            id: "01939",
            name: "Xã Thượng Ân",
            level: "Xã"
          },
          {
            id: "01942",
            name: "Xã Bằng Vân",
            level: "Xã"
          },
          {
            id: "01945",
            name: "Xã Cốc Đán",
            level: "Xã"
          },
          {
            id: "01948",
            name: "Xã Trung Hoà",
            level: "Xã"
          },
          {
            id: "01951",
            name: "Xã Đức Vân",
            level: "Xã"
          },
          {
            id: "01954",
            name: "Xã Vân Tùng",
            level: "Xã"
          },
          {
            id: "01957",
            name: "Xã Thượng Quan",
            level: "Xã"
          },
          {
            id: "01960",
            name: "Xã Hiệp Lực",
            level: "Xã"
          },
          {
            id: "01963",
            name: "Xã Thuần Mang",
            level: "Xã"
          }
        ]
      },
      {
        id: "063",
        name: "Huyện Bạch Thông",
        wards: [
          {
            id: "01969",
            name: "Thị trấn Phủ Thông",
            level: "Thị trấn"
          },
          {
            id: "01975",
            name: "Xã Vi Hương",
            level: "Xã"
          },
          {
            id: "01978",
            name: "Xã Sĩ Bình",
            level: "Xã"
          },
          {
            id: "01981",
            name: "Xã Vũ Muộn",
            level: "Xã"
          },
          {
            id: "01984",
            name: "Xã Đôn Phong",
            level: "Xã"
          },
          {
            id: "01990",
            name: "Xã Lục Bình",
            level: "Xã"
          },
          {
            id: "01993",
            name: "Xã Tân Tú",
            level: "Xã"
          },
          {
            id: "01999",
            name: "Xã Nguyên Phúc",
            level: "Xã"
          },
          {
            id: "02002",
            name: "Xã Cao Sơn",
            level: "Xã"
          },
          {
            id: "02005",
            name: "Xã Quân Hà",
            level: "Xã"
          },
          {
            id: "02008",
            name: "Xã Cẩm Giàng",
            level: "Xã"
          },
          {
            id: "02011",
            name: "Xã Mỹ Thanh",
            level: "Xã"
          },
          {
            id: "02014",
            name: "Xã Dương Phong",
            level: "Xã"
          },
          {
            id: "02017",
            name: "Xã Quang Thuận",
            level: "Xã"
          }
        ]
      },
      {
        id: "064",
        name: "Huyện Chợ Đồn",
        wards: [
          {
            id: "02020",
            name: "Thị trấn Bằng Lũng",
            level: "Thị trấn"
          },
          {
            id: "02023",
            name: "Xã Xuân Lạc",
            level: "Xã"
          },
          {
            id: "02026",
            name: "Xã Nam Cường",
            level: "Xã"
          },
          {
            id: "02029",
            name: "Xã Đồng Lạc",
            level: "Xã"
          },
          {
            id: "02032",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "02035",
            name: "Xã Bản Thi",
            level: "Xã"
          },
          {
            id: "02038",
            name: "Xã Quảng Bạch",
            level: "Xã"
          },
          {
            id: "02041",
            name: "Xã Bằng Phúc",
            level: "Xã"
          },
          {
            id: "02044",
            name: "Xã Yên Thịnh",
            level: "Xã"
          },
          {
            id: "02047",
            name: "Xã Yên Thượng",
            level: "Xã"
          },
          {
            id: "02050",
            name: "Xã Phương Viên",
            level: "Xã"
          },
          {
            id: "02053",
            name: "Xã Ngọc Phái",
            level: "Xã"
          },
          {
            id: "02059",
            name: "Xã Đồng Thắng",
            level: "Xã"
          },
          {
            id: "02062",
            name: "Xã Lương Bằng",
            level: "Xã"
          },
          {
            id: "02065",
            name: "Xã Bằng Lãng",
            level: "Xã"
          },
          {
            id: "02068",
            name: "Xã Đại Sảo",
            level: "Xã"
          },
          {
            id: "02071",
            name: "Xã Nghĩa Tá",
            level: "Xã"
          },
          {
            id: "02077",
            name: "Xã Yên Mỹ",
            level: "Xã"
          },
          {
            id: "02080",
            name: "Xã Bình Trung",
            level: "Xã"
          },
          {
            id: "02083",
            name: "Xã Yên Phong",
            level: "Xã"
          }
        ]
      },
      {
        id: "065",
        name: "Huyện Chợ Mới",
        wards: [
          {
            id: "02086",
            name: "Thị trấn Đồng Tâm",
            level: "Thị trấn"
          },
          {
            id: "02089",
            name: "Xã Tân Sơn",
            level: "Xã"
          },
          {
            id: "02092",
            name: "Xã Thanh Vận",
            level: "Xã"
          },
          {
            id: "02095",
            name: "Xã Mai Lạp",
            level: "Xã"
          },
          {
            id: "02098",
            name: "Xã Hoà Mục",
            level: "Xã"
          },
          {
            id: "02101",
            name: "Xã Thanh Mai",
            level: "Xã"
          },
          {
            id: "02104",
            name: "Xã Cao Kỳ",
            level: "Xã"
          },
          {
            id: "02107",
            name: "Xã Nông Hạ",
            level: "Xã"
          },
          {
            id: "02110",
            name: "Xã Yên Cư",
            level: "Xã"
          },
          {
            id: "02113",
            name: "Xã Thanh Thịnh",
            level: "Xã"
          },
          {
            id: "02116",
            name: "Xã Yên Hân",
            level: "Xã"
          },
          {
            id: "02122",
            name: "Xã Như Cố",
            level: "Xã"
          },
          {
            id: "02125",
            name: "Xã Bình Văn",
            level: "Xã"
          },
          {
            id: "02131",
            name: "Xã Quảng Chu",
            level: "Xã"
          }
        ]
      },
      {
        id: "066",
        name: "Huyện Na Rì",
        wards: [
          {
            id: "02137",
            name: "Xã Văn Vũ",
            level: "Xã"
          },
          {
            id: "02140",
            name: "Xã Văn Lang",
            level: "Xã"
          },
          {
            id: "02143",
            name: "Xã Lương Thượng",
            level: "Xã"
          },
          {
            id: "02146",
            name: "Xã Kim Hỷ",
            level: "Xã"
          },
          {
            id: "02152",
            name: "Xã Cường Lợi",
            level: "Xã"
          },
          {
            id: "02155",
            name: "Thị trấn Yến Lạc",
            level: "Thị trấn"
          },
          {
            id: "02158",
            name: "Xã Kim Lư",
            level: "Xã"
          },
          {
            id: "02161",
            name: "Xã Sơn Thành",
            level: "Xã"
          },
          {
            id: "02170",
            name: "Xã Văn Minh",
            level: "Xã"
          },
          {
            id: "02173",
            name: "Xã Côn Minh",
            level: "Xã"
          },
          {
            id: "02176",
            name: "Xã Cư Lễ",
            level: "Xã"
          },
          {
            id: "02179",
            name: "Xã Trần Phú",
            level: "Xã"
          },
          {
            id: "02185",
            name: "Xã Quang Phong",
            level: "Xã"
          },
          {
            id: "02188",
            name: "Xã Dương Sơn",
            level: "Xã"
          },
          {
            id: "02191",
            name: "Xã Xuân Dương",
            level: "Xã"
          },
          {
            id: "02194",
            name: "Xã Đổng Xá",
            level: "Xã"
          },
          {
            id: "02197",
            name: "Xã Liêm Thuỷ",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "08",
    name: "Tỉnh Tuyên Quang",
    districts: [
      {
        id: "070",
        name: "Thành phố Tuyên Quang",
        wards: [
          {
            id: "02200",
            name: "Phường Phan Thiết",
            level: "Phường"
          },
          {
            id: "02203",
            name: "Phường Minh Xuân",
            level: "Phường"
          },
          {
            id: "02206",
            name: "Phường Tân Quang",
            level: "Phường"
          },
          {
            id: "02209",
            name: "Xã Tràng Đà",
            level: "Xã"
          },
          {
            id: "02212",
            name: "Phường Nông Tiến",
            level: "Phường"
          },
          {
            id: "02215",
            name: "Phường Ỷ La",
            level: "Phường"
          },
          {
            id: "02216",
            name: "Phường Tân Hà",
            level: "Phường"
          },
          {
            id: "02218",
            name: "Phường Hưng Thành",
            level: "Phường"
          },
          {
            id: "02497",
            name: "Xã Kim Phú",
            level: "Xã"
          },
          {
            id: "02503",
            name: "Xã An Khang",
            level: "Xã"
          },
          {
            id: "02509",
            name: "Phường Mỹ Lâm",
            level: "Phường"
          },
          {
            id: "02512",
            name: "Phường An Tường",
            level: "Phường"
          },
          {
            id: "02515",
            name: "Xã Lưỡng Vượng",
            level: "Xã"
          },
          {
            id: "02521",
            name: "Xã Thái Long",
            level: "Xã"
          },
          {
            id: "02524",
            name: "Phường Đội Cấn",
            level: "Phường"
          }
        ]
      },
      {
        id: "071",
        name: "Huyện Lâm Bình",
        wards: [
          {
            id: "02233",
            name: "Xã Phúc Yên",
            level: "Xã"
          },
          {
            id: "02242",
            name: "Xã Xuân Lập",
            level: "Xã"
          },
          {
            id: "02251",
            name: "Xã Khuôn Hà",
            level: "Xã"
          },
          {
            id: "02266",
            name: "Xã Lăng Can",
            level: "Xã"
          },
          {
            id: "02269",
            name: "Xã Thượng Lâm",
            level: "Xã"
          },
          {
            id: "02290",
            name: "Xã Bình An",
            level: "Xã"
          },
          {
            id: "02293",
            name: "Xã Hồng Quang",
            level: "Xã"
          },
          {
            id: "02296",
            name: "Xã Thổ Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "072",
        name: "Huyện Na Hang",
        wards: [
          {
            id: "02221",
            name: "Thị trấn Na Hang",
            level: "Thị trấn"
          },
          {
            id: "02227",
            name: "Xã Sinh Long",
            level: "Xã"
          },
          {
            id: "02230",
            name: "Xã Thượng Giáp",
            level: "Xã"
          },
          {
            id: "02239",
            name: "Xã Thượng Nông",
            level: "Xã"
          },
          {
            id: "02245",
            name: "Xã Côn Lôn",
            level: "Xã"
          },
          {
            id: "02248",
            name: "Xã Yên Hoa",
            level: "Xã"
          },
          {
            id: "02254",
            name: "Xã Hồng Thái",
            level: "Xã"
          },
          {
            id: "02260",
            name: "Xã Đà Vị",
            level: "Xã"
          },
          {
            id: "02263",
            name: "Xã Khau Tinh",
            level: "Xã"
          },
          {
            id: "02275",
            name: "Xã Sơn Phú",
            level: "Xã"
          },
          {
            id: "02281",
            name: "Xã Năng Khả",
            level: "Xã"
          },
          {
            id: "02284",
            name: "Xã Thanh Tương",
            level: "Xã"
          }
        ]
      },
      {
        id: "073",
        name: "Huyện Chiêm Hóa",
        wards: [
          {
            id: "02287",
            name: "Thị trấn Vĩnh Lộc",
            level: "Thị trấn"
          },
          {
            id: "02299",
            name: "Xã Phúc Sơn",
            level: "Xã"
          },
          {
            id: "02302",
            name: "Xã Minh Quang",
            level: "Xã"
          },
          {
            id: "02305",
            name: "Xã Trung Hà",
            level: "Xã"
          },
          {
            id: "02308",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "02311",
            name: "Xã Hà Lang",
            level: "Xã"
          },
          {
            id: "02314",
            name: "Xã Hùng Mỹ",
            level: "Xã"
          },
          {
            id: "02317",
            name: "Xã Yên Lập",
            level: "Xã"
          },
          {
            id: "02320",
            name: "Xã Tân An",
            level: "Xã"
          },
          {
            id: "02323",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "02326",
            name: "Xã Xuân Quang",
            level: "Xã"
          },
          {
            id: "02329",
            name: "Xã Ngọc Hội",
            level: "Xã"
          },
          {
            id: "02332",
            name: "Xã Phú Bình",
            level: "Xã"
          },
          {
            id: "02335",
            name: "Xã Hòa Phú",
            level: "Xã"
          },
          {
            id: "02338",
            name: "Xã Phúc Thịnh",
            level: "Xã"
          },
          {
            id: "02341",
            name: "Xã Kiên Đài",
            level: "Xã"
          },
          {
            id: "02344",
            name: "Xã Tân Thịnh",
            level: "Xã"
          },
          {
            id: "02347",
            name: "Xã Trung Hòa",
            level: "Xã"
          },
          {
            id: "02350",
            name: "Xã Kim Bình",
            level: "Xã"
          },
          {
            id: "02353",
            name: "Xã Hòa An",
            level: "Xã"
          },
          {
            id: "02356",
            name: "Xã Vinh Quang",
            level: "Xã"
          },
          {
            id: "02359",
            name: "Xã Tri Phú",
            level: "Xã"
          },
          {
            id: "02362",
            name: "Xã Nhân Lý",
            level: "Xã"
          },
          {
            id: "02365",
            name: "Xã Yên Nguyên",
            level: "Xã"
          },
          {
            id: "02368",
            name: "Xã Linh Phú",
            level: "Xã"
          },
          {
            id: "02371",
            name: "Xã Bình Nhân",
            level: "Xã"
          }
        ]
      },
      {
        id: "074",
        name: "Huyện Hàm Yên",
        wards: [
          {
            id: "02374",
            name: "Thị trấn Tân Yên",
            level: "Thị trấn"
          },
          {
            id: "02377",
            name: "Xã Yên Thuận",
            level: "Xã"
          },
          {
            id: "02380",
            name: "Xã Bạch Xa",
            level: "Xã"
          },
          {
            id: "02383",
            name: "Xã Minh Khương",
            level: "Xã"
          },
          {
            id: "02386",
            name: "Xã Yên Lâm",
            level: "Xã"
          },
          {
            id: "02389",
            name: "Xã Minh Dân",
            level: "Xã"
          },
          {
            id: "02392",
            name: "Xã Phù Lưu",
            level: "Xã"
          },
          {
            id: "02395",
            name: "Xã Minh Hương",
            level: "Xã"
          },
          {
            id: "02398",
            name: "Xã Yên Phú",
            level: "Xã"
          },
          {
            id: "02401",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "02404",
            name: "Xã Bình Xa",
            level: "Xã"
          },
          {
            id: "02407",
            name: "Xã Thái Sơn",
            level: "Xã"
          },
          {
            id: "02410",
            name: "Xã Nhân Mục",
            level: "Xã"
          },
          {
            id: "02413",
            name: "Xã Thành Long",
            level: "Xã"
          },
          {
            id: "02416",
            name: "Xã Bằng Cốc",
            level: "Xã"
          },
          {
            id: "02419",
            name: "Xã Thái Hòa",
            level: "Xã"
          },
          {
            id: "02422",
            name: "Xã Đức Ninh",
            level: "Xã"
          },
          {
            id: "02425",
            name: "Xã Hùng Đức",
            level: "Xã"
          }
        ]
      },
      {
        id: "075",
        name: "Huyện Yên Sơn",
        wards: [
          {
            id: "02431",
            name: "Xã Quí Quân",
            level: "Xã"
          },
          {
            id: "02434",
            name: "Xã Lực Hành",
            level: "Xã"
          },
          {
            id: "02437",
            name: "Xã Kiến Thiết",
            level: "Xã"
          },
          {
            id: "02440",
            name: "Xã Trung Minh",
            level: "Xã"
          },
          {
            id: "02443",
            name: "Xã Chiêu Yên",
            level: "Xã"
          },
          {
            id: "02446",
            name: "Xã Trung Trực",
            level: "Xã"
          },
          {
            id: "02449",
            name: "Xã Xuân Vân",
            level: "Xã"
          },
          {
            id: "02452",
            name: "Xã Phúc Ninh",
            level: "Xã"
          },
          {
            id: "02455",
            name: "Xã Hùng Lợi",
            level: "Xã"
          },
          {
            id: "02458",
            name: "Xã Trung Sơn",
            level: "Xã"
          },
          {
            id: "02461",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "02464",
            name: "Xã Tứ Quận",
            level: "Xã"
          },
          {
            id: "02467",
            name: "Xã Đạo Viện",
            level: "Xã"
          },
          {
            id: "02470",
            name: "Xã Tân Long",
            level: "Xã"
          },
          {
            id: "02473",
            name: "Xã Thắng Quân",
            level: "Xã"
          },
          {
            id: "02476",
            name: "Xã Kim Quan",
            level: "Xã"
          },
          {
            id: "02479",
            name: "Xã Lang Quán",
            level: "Xã"
          },
          {
            id: "02482",
            name: "Xã Phú Thịnh",
            level: "Xã"
          },
          {
            id: "02485",
            name: "Xã Công Đa",
            level: "Xã"
          },
          {
            id: "02488",
            name: "Xã Trung Môn",
            level: "Xã"
          },
          {
            id: "02491",
            name: "Xã Chân Sơn",
            level: "Xã"
          },
          {
            id: "02494",
            name: "Xã Thái Bình",
            level: "Xã"
          },
          {
            id: "02500",
            name: "Xã Tiến Bộ",
            level: "Xã"
          },
          {
            id: "02506",
            name: "Xã Mỹ Bằng",
            level: "Xã"
          },
          {
            id: "02518",
            name: "Xã Hoàng Khai",
            level: "Xã"
          },
          {
            id: "02527",
            name: "Xã Nhữ Hán",
            level: "Xã"
          },
          {
            id: "02530",
            name: "Xã Nhữ Khê",
            level: "Xã"
          },
          {
            id: "02533",
            name: "Xã Đội Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "076",
        name: "Huyện Sơn Dương",
        wards: [
          {
            id: "02536",
            name: "Thị trấn Sơn Dương",
            level: "Thị trấn"
          },
          {
            id: "02539",
            name: "Xã Trung Yên",
            level: "Xã"
          },
          {
            id: "02542",
            name: "Xã Minh Thanh",
            level: "Xã"
          },
          {
            id: "02545",
            name: "Xã Tân Trào",
            level: "Xã"
          },
          {
            id: "02548",
            name: "Xã Vĩnh Lợi",
            level: "Xã"
          },
          {
            id: "02551",
            name: "Xã Thượng Ấm",
            level: "Xã"
          },
          {
            id: "02554",
            name: "Xã Bình Yên",
            level: "Xã"
          },
          {
            id: "02557",
            name: "Xã Lương Thiện",
            level: "Xã"
          },
          {
            id: "02560",
            name: "Xã Tú Thịnh",
            level: "Xã"
          },
          {
            id: "02563",
            name: "Xã Cấp Tiến",
            level: "Xã"
          },
          {
            id: "02566",
            name: "Xã Hợp Thành",
            level: "Xã"
          },
          {
            id: "02569",
            name: "Xã Phúc Ứng",
            level: "Xã"
          },
          {
            id: "02572",
            name: "Xã Đông Thọ",
            level: "Xã"
          },
          {
            id: "02575",
            name: "Xã Kháng Nhật",
            level: "Xã"
          },
          {
            id: "02578",
            name: "Xã Hợp Hòa",
            level: "Xã"
          },
          {
            id: "02584",
            name: "Xã Quyết Thắng",
            level: "Xã"
          },
          {
            id: "02587",
            name: "Xã Đồng Quý",
            level: "Xã"
          },
          {
            id: "02590",
            name: "Xã Tân Thanh",
            level: "Xã"
          },
          {
            id: "02593",
            name: "Xã Vân Sơn",
            level: "Xã"
          },
          {
            id: "02596",
            name: "Xã Văn Phú",
            level: "Xã"
          },
          {
            id: "02599",
            name: "Xã Chi Thiết",
            level: "Xã"
          },
          {
            id: "02602",
            name: "Xã Đông Lợi",
            level: "Xã"
          },
          {
            id: "02605",
            name: "Xã Thiện Kế",
            level: "Xã"
          },
          {
            id: "02608",
            name: "Xã Hồng Lạc",
            level: "Xã"
          },
          {
            id: "02611",
            name: "Xã Phú Lương",
            level: "Xã"
          },
          {
            id: "02614",
            name: "Xã Ninh Lai",
            level: "Xã"
          },
          {
            id: "02617",
            name: "Xã Đại Phú",
            level: "Xã"
          },
          {
            id: "02620",
            name: "Xã Sơn Nam",
            level: "Xã"
          },
          {
            id: "02623",
            name: "Xã Hào Phú",
            level: "Xã"
          },
          {
            id: "02626",
            name: "Xã Tam Đa",
            level: "Xã"
          },
          {
            id: "02632",
            name: "Xã Trường Sinh",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "10",
    name: "Tỉnh Lào Cai",
    districts: [
      {
        id: "080",
        name: "Thành phố Lào Cai",
        wards: [
          {
            id: "02635",
            name: "Phường Duyên Hải",
            level: "Phường"
          },
          {
            id: "02641",
            name: "Phường Lào Cai",
            level: "Phường"
          },
          {
            id: "02644",
            name: "Phường Cốc Lếu",
            level: "Phường"
          },
          {
            id: "02647",
            name: "Phường Kim Tân",
            level: "Phường"
          },
          {
            id: "02650",
            name: "Phường Bắc Lệnh",
            level: "Phường"
          },
          {
            id: "02653",
            name: "Phường Pom Hán",
            level: "Phường"
          },
          {
            id: "02656",
            name: "Phường Xuân Tăng",
            level: "Phường"
          },
          {
            id: "02658",
            name: "Phường Bình Minh",
            level: "Phường"
          },
          {
            id: "02659",
            name: "Xã Thống Nhất",
            level: "Xã"
          },
          {
            id: "02662",
            name: "Xã Đồng Tuyển",
            level: "Xã"
          },
          {
            id: "02665",
            name: "Xã Vạn Hoà",
            level: "Xã"
          },
          {
            id: "02668",
            name: "Phường Bắc Cường",
            level: "Phường"
          },
          {
            id: "02671",
            name: "Phường Nam Cường",
            level: "Phường"
          },
          {
            id: "02674",
            name: "Xã Cam Đường",
            level: "Xã"
          },
          {
            id: "02677",
            name: "Xã Tả Phời",
            level: "Xã"
          },
          {
            id: "02680",
            name: "Xã Hợp Thành",
            level: "Xã"
          },
          {
            id: "02746",
            name: "Xã Cốc San",
            level: "Xã"
          }
        ]
      },
      {
        id: "082",
        name: "Huyện Bát Xát",
        wards: [
          {
            id: "02683",
            name: "Thị trấn Bát Xát",
            level: "Thị trấn"
          },
          {
            id: "02686",
            name: "Xã A Mú Sung",
            level: "Xã"
          },
          {
            id: "02689",
            name: "Xã Nậm Chạc",
            level: "Xã"
          },
          {
            id: "02692",
            name: "Xã A Lù",
            level: "Xã"
          },
          {
            id: "02695",
            name: "Xã Trịnh Tường",
            level: "Xã"
          },
          {
            id: "02701",
            name: "Xã Y Tý",
            level: "Xã"
          },
          {
            id: "02704",
            name: "Xã Cốc Mỳ",
            level: "Xã"
          },
          {
            id: "02707",
            name: "Xã Dền Sáng",
            level: "Xã"
          },
          {
            id: "02710",
            name: "Xã Bản Vược",
            level: "Xã"
          },
          {
            id: "02713",
            name: "Xã Sàng Ma Sáo",
            level: "Xã"
          },
          {
            id: "02716",
            name: "Xã Bản Qua",
            level: "Xã"
          },
          {
            id: "02719",
            name: "Xã Mường Vi",
            level: "Xã"
          },
          {
            id: "02722",
            name: "Xã Dền Thàng",
            level: "Xã"
          },
          {
            id: "02725",
            name: "Xã Bản Xèo",
            level: "Xã"
          },
          {
            id: "02728",
            name: "Xã Mường Hum",
            level: "Xã"
          },
          {
            id: "02731",
            name: "Xã Trung Lèng Hồ",
            level: "Xã"
          },
          {
            id: "02734",
            name: "Xã Quang Kim",
            level: "Xã"
          },
          {
            id: "02737",
            name: "Xã Pa Cheo",
            level: "Xã"
          },
          {
            id: "02740",
            name: "Xã Nậm Pung",
            level: "Xã"
          },
          {
            id: "02743",
            name: "Xã Phìn Ngan",
            level: "Xã"
          },
          {
            id: "02749",
            name: "Xã Tòng Sành",
            level: "Xã"
          }
        ]
      },
      {
        id: "083",
        name: "Huyện Mường Khương",
        wards: [
          {
            id: "02752",
            name: "Xã Pha Long",
            level: "Xã"
          },
          {
            id: "02755",
            name: "Xã Tả Ngải Chồ",
            level: "Xã"
          },
          {
            id: "02758",
            name: "Xã Tung Chung Phố",
            level: "Xã"
          },
          {
            id: "02761",
            name: "Thị trấn Mường Khương",
            level: "Thị trấn"
          },
          {
            id: "02764",
            name: "Xã Dìn Chin",
            level: "Xã"
          },
          {
            id: "02767",
            name: "Xã Tả Gia Khâu",
            level: "Xã"
          },
          {
            id: "02770",
            name: "Xã Nậm Chảy",
            level: "Xã"
          },
          {
            id: "02773",
            name: "Xã Nấm Lư",
            level: "Xã"
          },
          {
            id: "02776",
            name: "Xã Lùng Khấu Nhin",
            level: "Xã"
          },
          {
            id: "02779",
            name: "Xã Thanh Bình",
            level: "Xã"
          },
          {
            id: "02782",
            name: "Xã Cao Sơn",
            level: "Xã"
          },
          {
            id: "02785",
            name: "Xã Lùng Vai",
            level: "Xã"
          },
          {
            id: "02788",
            name: "Xã Bản Lầu",
            level: "Xã"
          },
          {
            id: "02791",
            name: "Xã La Pan Tẩn",
            level: "Xã"
          },
          {
            id: "02794",
            name: "Xã Tả Thàng",
            level: "Xã"
          },
          {
            id: "02797",
            name: "Xã Bản Sen",
            level: "Xã"
          }
        ]
      },
      {
        id: "084",
        name: "Huyện Si Ma Cai",
        wards: [
          {
            id: "02800",
            name: "Xã Nàn Sán",
            level: "Xã"
          },
          {
            id: "02803",
            name: "Xã Thào Chư Phìn",
            level: "Xã"
          },
          {
            id: "02806",
            name: "Xã Bản Mế",
            level: "Xã"
          },
          {
            id: "02809",
            name: "Thị trấn Si Ma Cai",
            level: "Thị trấn"
          },
          {
            id: "02812",
            name: "Xã Sán Chải",
            level: "Xã"
          },
          {
            id: "02818",
            name: "Xã Lùng Thẩn",
            level: "Xã"
          },
          {
            id: "02821",
            name: "Xã Cán Cấu",
            level: "Xã"
          },
          {
            id: "02824",
            name: "Xã Sín Chéng",
            level: "Xã"
          },
          {
            id: "02827",
            name: "Xã Quan Hồ Thẩn",
            level: "Xã"
          },
          {
            id: "02836",
            name: "Xã Nàn Xín",
            level: "Xã"
          }
        ]
      },
      {
        id: "085",
        name: "Huyện Bắc Hà",
        wards: [
          {
            id: "02839",
            name: "Thị trấn Bắc Hà",
            level: "Thị trấn"
          },
          {
            id: "02842",
            name: "Xã Lùng Cải",
            level: "Xã"
          },
          {
            id: "02848",
            name: "Xã Lùng Phình",
            level: "Xã"
          },
          {
            id: "02851",
            name: "Xã Tả Van Chư",
            level: "Xã"
          },
          {
            id: "02854",
            name: "Xã Tả Củ Tỷ",
            level: "Xã"
          },
          {
            id: "02857",
            name: "Xã Thải Giàng Phố",
            level: "Xã"
          },
          {
            id: "02863",
            name: "Xã Hoàng Thu Phố",
            level: "Xã"
          },
          {
            id: "02866",
            name: "Xã Bản Phố",
            level: "Xã"
          },
          {
            id: "02869",
            name: "Xã Bản Liền",
            level: "Xã"
          },
          {
            id: "02872",
            name: "Xã Tà Chải",
            level: "Xã"
          },
          {
            id: "02875",
            name: "Xã Na Hối",
            level: "Xã"
          },
          {
            id: "02878",
            name: "Xã Cốc Ly",
            level: "Xã"
          },
          {
            id: "02881",
            name: "Xã Nậm Mòn",
            level: "Xã"
          },
          {
            id: "02884",
            name: "Xã Nậm Đét",
            level: "Xã"
          },
          {
            id: "02887",
            name: "Xã Nậm Khánh",
            level: "Xã"
          },
          {
            id: "02890",
            name: "Xã Bảo Nhai",
            level: "Xã"
          },
          {
            id: "02893",
            name: "Xã Nậm Lúc",
            level: "Xã"
          },
          {
            id: "02896",
            name: "Xã Cốc Lầu",
            level: "Xã"
          },
          {
            id: "02899",
            name: "Xã Bản Cái",
            level: "Xã"
          }
        ]
      },
      {
        id: "086",
        name: "Huyện Bảo Thắng",
        wards: [
          {
            id: "02902",
            name: "Thị trấn N.T Phong Hải",
            level: "Thị trấn"
          },
          {
            id: "02905",
            name: "Thị trấn Phố Lu",
            level: "Thị trấn"
          },
          {
            id: "02908",
            name: "Thị trấn Tằng Loỏng",
            level: "Thị trấn"
          },
          {
            id: "02911",
            name: "Xã Bản Phiệt",
            level: "Xã"
          },
          {
            id: "02914",
            name: "Xã Bản Cầm",
            level: "Xã"
          },
          {
            id: "02917",
            name: "Xã Thái Niên",
            level: "Xã"
          },
          {
            id: "02920",
            name: "Xã Phong Niên",
            level: "Xã"
          },
          {
            id: "02923",
            name: "Xã Gia Phú",
            level: "Xã"
          },
          {
            id: "02926",
            name: "Xã Xuân Quang",
            level: "Xã"
          },
          {
            id: "02929",
            name: "Xã Sơn Hải",
            level: "Xã"
          },
          {
            id: "02932",
            name: "Xã Xuân Giao",
            level: "Xã"
          },
          {
            id: "02935",
            name: "Xã Trì Quang",
            level: "Xã"
          },
          {
            id: "02938",
            name: "Xã Sơn Hà",
            level: "Xã"
          },
          {
            id: "02944",
            name: "Xã Phú Nhuận",
            level: "Xã"
          }
        ]
      },
      {
        id: "087",
        name: "Huyện Bảo Yên",
        wards: [
          {
            id: "02947",
            name: "Thị trấn Phố Ràng",
            level: "Thị trấn"
          },
          {
            id: "02950",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "02953",
            name: "Xã Nghĩa Đô",
            level: "Xã"
          },
          {
            id: "02956",
            name: "Xã Vĩnh Yên",
            level: "Xã"
          },
          {
            id: "02959",
            name: "Xã Điện Quan",
            level: "Xã"
          },
          {
            id: "02962",
            name: "Xã Xuân Hoà",
            level: "Xã"
          },
          {
            id: "02965",
            name: "Xã Tân Dương",
            level: "Xã"
          },
          {
            id: "02968",
            name: "Xã Thượng Hà",
            level: "Xã"
          },
          {
            id: "02971",
            name: "Xã Kim Sơn",
            level: "Xã"
          },
          {
            id: "02974",
            name: "Xã Cam Cọn",
            level: "Xã"
          },
          {
            id: "02977",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "02980",
            name: "Xã Xuân Thượng",
            level: "Xã"
          },
          {
            id: "02983",
            name: "Xã Việt Tiến",
            level: "Xã"
          },
          {
            id: "02986",
            name: "Xã Yên Sơn",
            level: "Xã"
          },
          {
            id: "02989",
            name: "Xã Bảo Hà",
            level: "Xã"
          },
          {
            id: "02992",
            name: "Xã Lương Sơn",
            level: "Xã"
          },
          {
            id: "02998",
            name: "Xã Phúc Khánh",
            level: "Xã"
          }
        ]
      },
      {
        id: "088",
        name: "Thị xã Sa Pa",
        wards: [
          {
            id: "03001",
            name: "Phường Sa Pa",
            level: "Phường"
          },
          {
            id: "03002",
            name: "Phường Sa Pả",
            level: "Phường"
          },
          {
            id: "03003",
            name: "Phường Ô Quý Hồ",
            level: "Phường"
          },
          {
            id: "03004",
            name: "Xã Ngũ Chỉ Sơn",
            level: "Xã"
          },
          {
            id: "03006",
            name: "Phường Phan Si Păng",
            level: "Phường"
          },
          {
            id: "03010",
            name: "Xã Trung Chải",
            level: "Xã"
          },
          {
            id: "03013",
            name: "Xã Tả Phìn",
            level: "Xã"
          },
          {
            id: "03016",
            name: "Phường Hàm Rồng",
            level: "Xã"
          },
          {
            id: "03019",
            name: "Xã Hoàng Liên",
            level: "Xã"
          },
          {
            id: "03022",
            name: "Xã Thanh Bình",
            level: "Xã"
          },
          {
            id: "03028",
            name: "Phường Cầu Mây",
            level: "Phường"
          },
          {
            id: "03037",
            name: "Xã Mường Hoa",
            level: "Xã"
          },
          {
            id: "03040",
            name: "Xã Tả Van",
            level: "Xã"
          },
          {
            id: "03043",
            name: "Xã Mường Bo",
            level: "Xã"
          },
          {
            id: "03046",
            name: "Xã Bản Hồ",
            level: "Xã"
          },
          {
            id: "03052",
            name: "Xã Liên Minh",
            level: "Xã"
          }
        ]
      },
      {
        id: "089",
        name: "Huyện Văn Bàn",
        wards: [
          {
            id: "03055",
            name: "Thị trấn Khánh Yên",
            level: "Thị trấn"
          },
          {
            id: "03061",
            name: "Xã Võ Lao",
            level: "Xã"
          },
          {
            id: "03064",
            name: "Xã Sơn Thuỷ",
            level: "Xã"
          },
          {
            id: "03067",
            name: "Xã Nậm Mả",
            level: "Xã"
          },
          {
            id: "03070",
            name: "Xã Tân Thượng",
            level: "Xã"
          },
          {
            id: "03073",
            name: "Xã Nậm Rạng",
            level: "Xã"
          },
          {
            id: "03076",
            name: "Xã Nậm Chầy",
            level: "Xã"
          },
          {
            id: "03079",
            name: "Xã Tân An",
            level: "Xã"
          },
          {
            id: "03082",
            name: "Xã Khánh Yên Thượng",
            level: "Xã"
          },
          {
            id: "03085",
            name: "Xã Nậm Xé",
            level: "Xã"
          },
          {
            id: "03088",
            name: "Xã Dần Thàng",
            level: "Xã"
          },
          {
            id: "03091",
            name: "Xã Chiềng Ken",
            level: "Xã"
          },
          {
            id: "03094",
            name: "Xã Làng Giàng",
            level: "Xã"
          },
          {
            id: "03097",
            name: "Xã Hoà Mạc",
            level: "Xã"
          },
          {
            id: "03100",
            name: "Xã Khánh Yên Trung",
            level: "Xã"
          },
          {
            id: "03103",
            name: "Xã Khánh Yên Hạ",
            level: "Xã"
          },
          {
            id: "03106",
            name: "Xã Dương Quỳ",
            level: "Xã"
          },
          {
            id: "03109",
            name: "Xã Nậm Tha",
            level: "Xã"
          },
          {
            id: "03112",
            name: "Xã Minh Lương",
            level: "Xã"
          },
          {
            id: "03115",
            name: "Xã Thẩm Dương",
            level: "Xã"
          },
          {
            id: "03118",
            name: "Xã Liêm Phú",
            level: "Xã"
          },
          {
            id: "03121",
            name: "Xã Nậm Xây",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "11",
    name: "Tỉnh Điện Biên",
    districts: [
      {
        id: "094",
        name: "Thành phố Điện Biên Phủ",
        wards: [
          {
            id: "03124",
            name: "Phường Noong Bua",
            level: "Phường"
          },
          {
            id: "03127",
            name: "Phường Him Lam",
            level: "Phường"
          },
          {
            id: "03130",
            name: "Phường Thanh Bình",
            level: "Phường"
          },
          {
            id: "03133",
            name: "Phường Tân Thanh",
            level: "Phường"
          },
          {
            id: "03136",
            name: "Phường Mường Thanh",
            level: "Phường"
          },
          {
            id: "03139",
            name: "Phường Nam Thanh",
            level: "Phường"
          },
          {
            id: "03142",
            name: "Phường Thanh Trường",
            level: "Phường"
          },
          {
            id: "03145",
            name: "Xã Thanh Minh",
            level: "Xã"
          },
          {
            id: "03316",
            name: "Xã Nà Tấu",
            level: "Xã"
          },
          {
            id: "03317",
            name: "Xã Nà Nhạn",
            level: "Xã"
          },
          {
            id: "03325",
            name: "Xã Mường Phăng",
            level: "Xã"
          },
          {
            id: "03326",
            name: "Xã Pá Khoang",
            level: "Xã"
          }
        ]
      },
      {
        id: "095",
        name: "Thị Xã Mường Lay",
        wards: [
          {
            id: "03148",
            name: "Phường Sông Đà",
            level: "Phường"
          },
          {
            id: "03151",
            name: "Phường Na Lay",
            level: "Phường"
          },
          {
            id: "03184",
            name: "Xã Lay Nưa",
            level: "Xã"
          }
        ]
      },
      {
        id: "096",
        name: "Huyện Mường Nhé",
        wards: [
          {
            id: "03154",
            name: "Xã Sín Thầu",
            level: "Xã"
          },
          {
            id: "03155",
            name: "Xã Sen Thượng",
            level: "Xã"
          },
          {
            id: "03157",
            name: "Xã Chung Chải",
            level: "Xã"
          },
          {
            id: "03158",
            name: "Xã Leng Su Sìn",
            level: "Xã"
          },
          {
            id: "03159",
            name: "Xã Pá Mỳ",
            level: "Xã"
          },
          {
            id: "03160",
            name: "Xã Mường Nhé",
            level: "Xã"
          },
          {
            id: "03161",
            name: "Xã Nậm Vì",
            level: "Xã"
          },
          {
            id: "03162",
            name: "Xã Nậm Kè",
            level: "Xã"
          },
          {
            id: "03163",
            name: "Xã Mường Toong",
            level: "Xã"
          },
          {
            id: "03164",
            name: "Xã Quảng Lâm",
            level: "Xã"
          },
          {
            id: "03177",
            name: "Xã Huổi Lếnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "097",
        name: "Huyện Mường Chà",
        wards: [
          {
            id: "03172",
            name: "Thị Trấn Mường Chà",
            level: "Thị trấn"
          },
          {
            id: "03178",
            name: "Xã Xá Tổng",
            level: "Xã"
          },
          {
            id: "03181",
            name: "Xã Mường Tùng",
            level: "Xã"
          },
          {
            id: "03190",
            name: "Xã Hừa Ngài",
            level: "Xã"
          },
          {
            id: "03191",
            name: "Xã Huổi Mí",
            level: "Xã"
          },
          {
            id: "03193",
            name: "Xã Pa Ham",
            level: "Xã"
          },
          {
            id: "03194",
            name: "Xã Nậm Nèn",
            level: "Xã"
          },
          {
            id: "03196",
            name: "Xã Huổi Lèng",
            level: "Xã"
          },
          {
            id: "03197",
            name: "Xã Sa Lông",
            level: "Xã"
          },
          {
            id: "03200",
            name: "Xã Ma Thì Hồ",
            level: "Xã"
          },
          {
            id: "03201",
            name: "Xã Na Sang",
            level: "Xã"
          },
          {
            id: "03202",
            name: "Xã Mường Mươn",
            level: "Xã"
          }
        ]
      },
      {
        id: "098",
        name: "Huyện Tủa Chùa",
        wards: [
          {
            id: "03217",
            name: "Thị trấn Tủa Chùa",
            level: "Thị trấn"
          },
          {
            id: "03220",
            name: "Xã Huổi Só",
            level: "Xã"
          },
          {
            id: "03223",
            name: "Xã Xín Chải",
            level: "Xã"
          },
          {
            id: "03226",
            name: "Xã Tả Sìn Thàng",
            level: "Xã"
          },
          {
            id: "03229",
            name: "Xã Lao Xả Phình",
            level: "Xã"
          },
          {
            id: "03232",
            name: "Xã Tả Phìn",
            level: "Xã"
          },
          {
            id: "03235",
            name: "Xã Tủa Thàng",
            level: "Xã"
          },
          {
            id: "03238",
            name: "Xã Trung Thu",
            level: "Xã"
          },
          {
            id: "03241",
            name: "Xã Sính Phình",
            level: "Xã"
          },
          {
            id: "03244",
            name: "Xã Sáng Nhè",
            level: "Xã"
          },
          {
            id: "03247",
            name: "Xã Mường Đun",
            level: "Xã"
          },
          {
            id: "03250",
            name: "Xã Mường Báng",
            level: "Xã"
          }
        ]
      },
      {
        id: "099",
        name: "Huyện Tuần Giáo",
        wards: [
          {
            id: "03253",
            name: "Thị trấn Tuần Giáo",
            level: "Thị trấn"
          },
          {
            id: "03259",
            name: "Xã Phình Sáng",
            level: "Xã"
          },
          {
            id: "03260",
            name: "Xã Rạng Đông",
            level: "Xã"
          },
          {
            id: "03262",
            name: "Xã Mùn Chung",
            level: "Xã"
          },
          {
            id: "03263",
            name: "Xã Nà Tòng",
            level: "Xã"
          },
          {
            id: "03265",
            name: "Xã Ta Ma",
            level: "Xã"
          },
          {
            id: "03268",
            name: "Xã Mường Mùn",
            level: "Xã"
          },
          {
            id: "03269",
            name: "Xã Pú Xi",
            level: "Xã"
          },
          {
            id: "03271",
            name: "Xã Pú Nhung",
            level: "Xã"
          },
          {
            id: "03274",
            name: "Xã Quài Nưa",
            level: "Xã"
          },
          {
            id: "03277",
            name: "Xã Mường Thín",
            level: "Xã"
          },
          {
            id: "03280",
            name: "Xã Tỏa Tình",
            level: "Xã"
          },
          {
            id: "03283",
            name: "Xã Nà Sáy",
            level: "Xã"
          },
          {
            id: "03284",
            name: "Xã Mường Khong",
            level: "Xã"
          },
          {
            id: "03289",
            name: "Xã Quài Cang",
            level: "Xã"
          },
          {
            id: "03295",
            name: "Xã Quài Tở",
            level: "Xã"
          },
          {
            id: "03298",
            name: "Xã Chiềng Sinh",
            level: "Xã"
          },
          {
            id: "03299",
            name: "Xã Chiềng Đông",
            level: "Xã"
          },
          {
            id: "03304",
            name: "Xã Tênh Phông",
            level: "Xã"
          }
        ]
      },
      {
        id: "100",
        name: "Huyện Điện Biên",
        wards: [
          {
            id: "03319",
            name: "Xã Mường Pồn",
            level: "Xã"
          },
          {
            id: "03322",
            name: "Xã Thanh Nưa",
            level: "Xã"
          },
          {
            id: "03323",
            name: "Xã Hua Thanh",
            level: "Xã"
          },
          {
            id: "03328",
            name: "Xã Thanh Luông",
            level: "Xã"
          },
          {
            id: "03331",
            name: "Xã Thanh Hưng",
            level: "Xã"
          },
          {
            id: "03334",
            name: "Xã Thanh Xương",
            level: "Xã"
          },
          {
            id: "03337",
            name: "Xã Thanh Chăn",
            level: "Xã"
          },
          {
            id: "03340",
            name: "Xã Pa Thơm",
            level: "Xã"
          },
          {
            id: "03343",
            name: "Xã Thanh An",
            level: "Xã"
          },
          {
            id: "03346",
            name: "Xã Thanh Yên",
            level: "Xã"
          },
          {
            id: "03349",
            name: "Xã Noong Luống",
            level: "Xã"
          },
          {
            id: "03352",
            name: "Xã Noọng Hẹt",
            level: "Xã"
          },
          {
            id: "03355",
            name: "Xã Sam Mứn",
            level: "Xã"
          },
          {
            id: "03356",
            name: "Xã Pom Lót",
            level: "Xã"
          },
          {
            id: "03358",
            name: "Xã Núa Ngam",
            level: "Xã"
          },
          {
            id: "03359",
            name: "Xã Hẹ Muông",
            level: "Xã"
          },
          {
            id: "03361",
            name: "Xã Na Ư",
            level: "Xã"
          },
          {
            id: "03364",
            name: "Xã Mường Nhà",
            level: "Xã"
          },
          {
            id: "03365",
            name: "Xã Na Tông",
            level: "Xã"
          },
          {
            id: "03367",
            name: "Xã Mường Lói",
            level: "Xã"
          },
          {
            id: "03368",
            name: "Xã Phu Luông",
            level: "Xã"
          }
        ]
      },
      {
        id: "101",
        name: "Huyện Điện Biên Đông",
        wards: [
          {
            id: "03203",
            name: "Thị trấn Điện Biên Đông",
            level: "Thị trấn"
          },
          {
            id: "03205",
            name: "Xã Na Son",
            level: "Xã"
          },
          {
            id: "03208",
            name: "Xã Phì Nhừ",
            level: "Xã"
          },
          {
            id: "03211",
            name: "Xã Chiềng Sơ",
            level: "Xã"
          },
          {
            id: "03214",
            name: "Xã Mường Luân",
            level: "Xã"
          },
          {
            id: "03370",
            name: "Xã Pú Nhi",
            level: "Xã"
          },
          {
            id: "03371",
            name: "Xã Nong U",
            level: "Xã"
          },
          {
            id: "03373",
            name: "Xã Xa Dung",
            level: "Xã"
          },
          {
            id: "03376",
            name: "Xã Keo Lôm",
            level: "Xã"
          },
          {
            id: "03379",
            name: "Xã Luân Giới",
            level: "Xã"
          },
          {
            id: "03382",
            name: "Xã Phình Giàng",
            level: "Xã"
          },
          {
            id: "03383",
            name: "Xã Pú Hồng",
            level: "Xã"
          },
          {
            id: "03384",
            name: "Xã Tìa Dình",
            level: "Xã"
          },
          {
            id: "03385",
            name: "Xã Háng Lìa",
            level: "Xã"
          }
        ]
      },
      {
        id: "102",
        name: "Huyện Mường Ảng",
        wards: [
          {
            id: "03256",
            name: "Thị trấn Mường Ảng",
            level: "Thị trấn"
          },
          {
            id: "03286",
            name: "Xã Mường Đăng",
            level: "Xã"
          },
          {
            id: "03287",
            name: "Xã Ngối Cáy",
            level: "Xã"
          },
          {
            id: "03292",
            name: "Xã Ẳng Tở",
            level: "Xã"
          },
          {
            id: "03301",
            name: "Xã Búng Lao",
            level: "Xã"
          },
          {
            id: "03302",
            name: "Xã Xuân Lao",
            level: "Xã"
          },
          {
            id: "03307",
            name: "Xã Ẳng Nưa",
            level: "Xã"
          },
          {
            id: "03310",
            name: "Xã Ẳng Cang",
            level: "Xã"
          },
          {
            id: "03312",
            name: "Xã Nặm Lịch",
            level: "Xã"
          },
          {
            id: "03313",
            name: "Xã Mường Lạn",
            level: "Xã"
          }
        ]
      },
      {
        id: "103",
        name: "Huyện Nậm Pồ",
        wards: [
          {
            id: "03156",
            name: "Xã Nậm Tin",
            level: "Xã"
          },
          {
            id: "03165",
            name: "Xã Pa Tần",
            level: "Xã"
          },
          {
            id: "03166",
            name: "Xã Chà Cang",
            level: "Xã"
          },
          {
            id: "03167",
            name: "Xã Na Cô Sa",
            level: "Xã"
          },
          {
            id: "03168",
            name: "Xã Nà Khoa",
            level: "Xã"
          },
          {
            id: "03169",
            name: "Xã Nà Hỳ",
            level: "Xã"
          },
          {
            id: "03170",
            name: "Xã Nà Bủng",
            level: "Xã"
          },
          {
            id: "03171",
            name: "Xã Nậm Nhừ",
            level: "Xã"
          },
          {
            id: "03173",
            name: "Xã Nậm Chua",
            level: "Xã"
          },
          {
            id: "03174",
            name: "Xã Nậm Khăn",
            level: "Xã"
          },
          {
            id: "03175",
            name: "Xã Chà Tở",
            level: "Xã"
          },
          {
            id: "03176",
            name: "Xã Vàng Đán",
            level: "Xã"
          },
          {
            id: "03187",
            name: "Xã Chà Nưa",
            level: "Xã"
          },
          {
            id: "03198",
            name: "Xã Phìn Hồ",
            level: "Xã"
          },
          {
            id: "03199",
            name: "Xã Si Pa Phìn",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "12",
    name: "Tỉnh Lai Châu",
    districts: [
      {
        id: "105",
        name: "Thành phố Lai Châu",
        wards: [
          {
            id: "03386",
            name: "Phường Quyết Thắng",
            level: "Phường"
          },
          {
            id: "03387",
            name: "Phường Tân Phong",
            level: "Phường"
          },
          {
            id: "03388",
            name: "Phường Quyết Tiến",
            level: "Phường"
          },
          {
            id: "03389",
            name: "Phường Đoàn Kết",
            level: "Phường"
          },
          {
            id: "03403",
            name: "Xã Sùng Phài",
            level: "Xã"
          },
          {
            id: "03408",
            name: "Phường Đông Phong",
            level: "Phường"
          },
          {
            id: "03409",
            name: "Xã San Thàng",
            level: "Xã"
          }
        ]
      },
      {
        id: "106",
        name: "Huyện Tam Đường",
        wards: [
          {
            id: "03390",
            name: "Thị trấn Tam Đường",
            level: "Thị trấn"
          },
          {
            id: "03394",
            name: "Xã Thèn Sin",
            level: "Xã"
          },
          {
            id: "03400",
            name: "Xã Tả Lèng",
            level: "Xã"
          },
          {
            id: "03405",
            name: "Xã Giang Ma",
            level: "Xã"
          },
          {
            id: "03406",
            name: "Xã Hồ Thầu",
            level: "Xã"
          },
          {
            id: "03412",
            name: "Xã Bình Lư",
            level: "Xã"
          },
          {
            id: "03413",
            name: "Xã Sơn Bình",
            level: "Xã"
          },
          {
            id: "03415",
            name: "Xã Nùng Nàng",
            level: "Xã"
          },
          {
            id: "03418",
            name: "Xã Bản Giang",
            level: "Xã"
          },
          {
            id: "03421",
            name: "Xã Bản Hon",
            level: "Xã"
          },
          {
            id: "03424",
            name: "Xã Bản Bo",
            level: "Xã"
          },
          {
            id: "03427",
            name: "Xã Nà Tăm",
            level: "Xã"
          },
          {
            id: "03430",
            name: "Xã Khun Há",
            level: "Xã"
          }
        ]
      },
      {
        id: "107",
        name: "Huyện Mường Tè",
        wards: [
          {
            id: "03433",
            name: "Thị trấn Mường Tè",
            level: "Thị trấn"
          },
          {
            id: "03436",
            name: "Xã Thu Lũm",
            level: "Xã"
          },
          {
            id: "03439",
            name: "Xã Ka Lăng",
            level: "Xã"
          },
          {
            id: "03440",
            name: "Xã Tá Bạ",
            level: "Xã"
          },
          {
            id: "03442",
            name: "Xã Pa ủ",
            level: "Xã"
          },
          {
            id: "03445",
            name: "Xã Mường Tè",
            level: "Xã"
          },
          {
            id: "03448",
            name: "Xã Pa Vệ Sử",
            level: "Xã"
          },
          {
            id: "03451",
            name: "Xã Mù Cả",
            level: "Xã"
          },
          {
            id: "03454",
            name: "Xã Bum Tở",
            level: "Xã"
          },
          {
            id: "03457",
            name: "Xã Nậm Khao",
            level: "Xã"
          },
          {
            id: "03463",
            name: "Xã Tà Tổng",
            level: "Xã"
          },
          {
            id: "03466",
            name: "Xã Bum Nưa",
            level: "Xã"
          },
          {
            id: "03467",
            name: "Xã Vàng San",
            level: "Xã"
          },
          {
            id: "03469",
            name: "Xã Kan Hồ",
            level: "Xã"
          }
        ]
      },
      {
        id: "108",
        name: "Huyện Sìn Hồ",
        wards: [
          {
            id: "03478",
            name: "Thị trấn Sìn Hồ",
            level: "Thị trấn"
          },
          {
            id: "03487",
            name: "Xã Chăn Nưa",
            level: "Xã"
          },
          {
            id: "03493",
            name: "Xã Pa Tần",
            level: "Xã"
          },
          {
            id: "03496",
            name: "Xã Phìn Hồ",
            level: "Xã"
          },
          {
            id: "03499",
            name: "Xã Hồng Thu",
            level: "Xã"
          },
          {
            id: "03505",
            name: "Xã Phăng Sô Lin",
            level: "Xã"
          },
          {
            id: "03508",
            name: "Xã Ma Quai",
            level: "Xã"
          },
          {
            id: "03509",
            name: "Xã Lùng Thàng",
            level: "Xã"
          },
          {
            id: "03511",
            name: "Xã Tả Phìn",
            level: "Xã"
          },
          {
            id: "03514",
            name: "Xã Sà Dề Phìn",
            level: "Xã"
          },
          {
            id: "03517",
            name: "Xã Nậm Tăm",
            level: "Xã"
          },
          {
            id: "03520",
            name: "Xã Tả Ngảo",
            level: "Xã"
          },
          {
            id: "03523",
            name: "Xã Pu Sam Cáp",
            level: "Xã"
          },
          {
            id: "03526",
            name: "Xã Nậm Cha",
            level: "Xã"
          },
          {
            id: "03527",
            name: "Xã Pa Khoá",
            level: "Xã"
          },
          {
            id: "03529",
            name: "Xã Làng Mô",
            level: "Xã"
          },
          {
            id: "03532",
            name: "Xã Noong Hẻo",
            level: "Xã"
          },
          {
            id: "03535",
            name: "Xã Nậm Mạ",
            level: "Xã"
          },
          {
            id: "03538",
            name: "Xã Căn Co",
            level: "Xã"
          },
          {
            id: "03541",
            name: "Xã Tủa Sín Chải",
            level: "Xã"
          },
          {
            id: "03544",
            name: "Xã Nậm Cuổi",
            level: "Xã"
          },
          {
            id: "03547",
            name: "Xã Nậm Hăn",
            level: "Xã"
          }
        ]
      },
      {
        id: "109",
        name: "Huyện Phong Thổ",
        wards: [
          {
            id: "03391",
            name: "Xã Lả Nhì Thàng",
            level: "Xã"
          },
          {
            id: "03490",
            name: "Xã Huổi Luông",
            level: "Xã"
          },
          {
            id: "03549",
            name: "Thị trấn Phong Thổ",
            level: "Thị trấn"
          },
          {
            id: "03550",
            name: "Xã Sì Lở Lầu",
            level: "Xã"
          },
          {
            id: "03553",
            name: "Xã Mồ Sì San",
            level: "Xã"
          },
          {
            id: "03559",
            name: "Xã Pa Vây Sử",
            level: "Xã"
          },
          {
            id: "03562",
            name: "Xã Vàng Ma Chải",
            level: "Xã"
          },
          {
            id: "03565",
            name: "Xã Tông Qua Lìn",
            level: "Xã"
          },
          {
            id: "03568",
            name: "Xã Mù Sang",
            level: "Xã"
          },
          {
            id: "03571",
            name: "Xã Dào San",
            level: "Xã"
          },
          {
            id: "03574",
            name: "Xã Ma Ly Pho",
            level: "Xã"
          },
          {
            id: "03577",
            name: "Xã Bản Lang",
            level: "Xã"
          },
          {
            id: "03580",
            name: "Xã Hoang Thèn",
            level: "Xã"
          },
          {
            id: "03583",
            name: "Xã Khổng Lào",
            level: "Xã"
          },
          {
            id: "03586",
            name: "Xã Nậm Xe",
            level: "Xã"
          },
          {
            id: "03589",
            name: "Xã Mường So",
            level: "Xã"
          },
          {
            id: "03592",
            name: "Xã Sin Suối Hồ",
            level: "Xã"
          }
        ]
      },
      {
        id: "110",
        name: "Huyện Than Uyên",
        wards: [
          {
            id: "03595",
            name: "Thị trấn Than Uyên",
            level: "Thị trấn"
          },
          {
            id: "03618",
            name: "Xã Phúc Than",
            level: "Xã"
          },
          {
            id: "03619",
            name: "Xã Mường Than",
            level: "Xã"
          },
          {
            id: "03625",
            name: "Xã Mường Mít",
            level: "Xã"
          },
          {
            id: "03628",
            name: "Xã Pha Mu",
            level: "Xã"
          },
          {
            id: "03631",
            name: "Xã Mường Cang",
            level: "Xã"
          },
          {
            id: "03632",
            name: "Xã Hua Nà",
            level: "Xã"
          },
          {
            id: "03634",
            name: "Xã Tà Hừa",
            level: "Xã"
          },
          {
            id: "03637",
            name: "Xã Mường Kim",
            level: "Xã"
          },
          {
            id: "03638",
            name: "Xã Tà Mung",
            level: "Xã"
          },
          {
            id: "03640",
            name: "Xã Tà Gia",
            level: "Xã"
          },
          {
            id: "03643",
            name: "Xã Khoen On",
            level: "Xã"
          }
        ]
      },
      {
        id: "111",
        name: "Huyện Tân Uyên",
        wards: [
          {
            id: "03598",
            name: "Thị trấn Tân Uyên",
            level: "Thị trấn"
          },
          {
            id: "03601",
            name: "Xã Mường Khoa",
            level: "Xã"
          },
          {
            id: "03602",
            name: "Xã Phúc Khoa",
            level: "Xã"
          },
          {
            id: "03604",
            name: "Xã Thân Thuộc",
            level: "Xã"
          },
          {
            id: "03605",
            name: "Xã Trung Đồng",
            level: "Xã"
          },
          {
            id: "03607",
            name: "Xã Hố Mít",
            level: "Xã"
          },
          {
            id: "03610",
            name: "Xã Nậm Cần",
            level: "Xã"
          },
          {
            id: "03613",
            name: "Xã Nậm Sỏ",
            level: "Xã"
          },
          {
            id: "03616",
            name: "Xã Pắc Ta",
            level: "Xã"
          },
          {
            id: "03622",
            name: "Xã Tà Mít",
            level: "Xã"
          }
        ]
      },
      {
        id: "112",
        name: "Huyện Nậm Nhùn",
        wards: [
          {
            id: "03434",
            name: "Thị trấn Nậm Nhùn",
            level: "Thị trấn"
          },
          {
            id: "03460",
            name: "Xã Hua Bun",
            level: "Xã"
          },
          {
            id: "03472",
            name: "Xã Mường Mô",
            level: "Xã"
          },
          {
            id: "03473",
            name: "Xã Nậm Chà",
            level: "Xã"
          },
          {
            id: "03474",
            name: "Xã Nậm Manh",
            level: "Xã"
          },
          {
            id: "03475",
            name: "Xã Nậm Hàng",
            level: "Xã"
          },
          {
            id: "03481",
            name: "Xã Lê Lợi",
            level: "Xã"
          },
          {
            id: "03484",
            name: "Xã Pú Đao",
            level: "Xã"
          },
          {
            id: "03488",
            name: "Xã Nậm Pì",
            level: "Xã"
          },
          {
            id: "03502",
            name: "Xã Nậm Ban",
            level: "Xã"
          },
          {
            id: "03503",
            name: "Xã Trung Chải",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "14",
    name: "Tỉnh Sơn La",
    districts: [
      {
        id: "116",
        name: "Thành phố Sơn La",
        wards: [
          {
            id: "03646",
            name: "Phường Chiềng Lề",
            level: "Phường"
          },
          {
            id: "03649",
            name: "Phường Tô Hiệu",
            level: "Phường"
          },
          {
            id: "03652",
            name: "Phường Quyết Thắng",
            level: "Phường"
          },
          {
            id: "03655",
            name: "Phường Quyết Tâm",
            level: "Phường"
          },
          {
            id: "03658",
            name: "Xã Chiềng Cọ",
            level: "Xã"
          },
          {
            id: "03661",
            name: "Xã Chiềng Đen",
            level: "Xã"
          },
          {
            id: "03664",
            name: "Xã Chiềng Xôm",
            level: "Xã"
          },
          {
            id: "03667",
            name: "Phường Chiềng An",
            level: "Phường"
          },
          {
            id: "03670",
            name: "Phường Chiềng Cơi",
            level: "Phường"
          },
          {
            id: "03673",
            name: "Xã Chiềng Ngần",
            level: "Xã"
          },
          {
            id: "03676",
            name: "Xã Hua La",
            level: "Xã"
          },
          {
            id: "03679",
            name: "Phường Chiềng Sinh",
            level: "Phường"
          }
        ]
      },
      {
        id: "118",
        name: "Huyện Quỳnh Nhai",
        wards: [
          {
            id: "03682",
            name: "Xã Mường Chiên",
            level: "Xã"
          },
          {
            id: "03685",
            name: "Xã Cà Nàng",
            level: "Xã"
          },
          {
            id: "03688",
            name: "Xã Chiềng Khay",
            level: "Xã"
          },
          {
            id: "03694",
            name: "Xã Mường Giôn",
            level: "Xã"
          },
          {
            id: "03697",
            name: "Xã Pá Ma Pha Khinh",
            level: "Xã"
          },
          {
            id: "03700",
            name: "Xã Chiềng Ơn",
            level: "Xã"
          },
          {
            id: "03703",
            name: "Xã Mường Giàng",
            level: "Xã"
          },
          {
            id: "03706",
            name: "Xã Chiềng Bằng",
            level: "Xã"
          },
          {
            id: "03709",
            name: "Xã Mường Sại",
            level: "Xã"
          },
          {
            id: "03712",
            name: "Xã Nậm ét",
            level: "Xã"
          },
          {
            id: "03718",
            name: "Xã Chiềng Khoang",
            level: "Xã"
          }
        ]
      },
      {
        id: "119",
        name: "Huyện Thuận Châu",
        wards: [
          {
            id: "03721",
            name: "Thị trấn Thuận Châu",
            level: "Thị trấn"
          },
          {
            id: "03724",
            name: "Xã Phổng Lái",
            level: "Xã"
          },
          {
            id: "03727",
            name: "Xã Mường é",
            level: "Xã"
          },
          {
            id: "03730",
            name: "Xã Chiềng Pha",
            level: "Xã"
          },
          {
            id: "03733",
            name: "Xã Chiềng La",
            level: "Xã"
          },
          {
            id: "03736",
            name: "Xã Chiềng Ngàm",
            level: "Xã"
          },
          {
            id: "03739",
            name: "Xã Liệp Tè",
            level: "Xã"
          },
          {
            id: "03742",
            name: "Xã é Tòng",
            level: "Xã"
          },
          {
            id: "03745",
            name: "Xã Phổng Lập",
            level: "Xã"
          },
          {
            id: "03748",
            name: "Xã Phổng Lăng",
            level: "Xã"
          },
          {
            id: "03751",
            name: "Xã Chiềng Ly",
            level: "Xã"
          },
          {
            id: "03754",
            name: "Xã Noong Lay",
            level: "Xã"
          },
          {
            id: "03757",
            name: "Xã Mường Khiêng",
            level: "Xã"
          },
          {
            id: "03760",
            name: "Xã Mường Bám",
            level: "Xã"
          },
          {
            id: "03763",
            name: "Xã Long Hẹ",
            level: "Xã"
          },
          {
            id: "03766",
            name: "Xã Chiềng Bôm",
            level: "Xã"
          },
          {
            id: "03769",
            name: "Xã Thôm Mòn",
            level: "Xã"
          },
          {
            id: "03772",
            name: "Xã Tông Lạnh",
            level: "Xã"
          },
          {
            id: "03775",
            name: "Xã Tông Cọ",
            level: "Xã"
          },
          {
            id: "03778",
            name: "Xã Bó Mười",
            level: "Xã"
          },
          {
            id: "03781",
            name: "Xã Co Mạ",
            level: "Xã"
          },
          {
            id: "03784",
            name: "Xã Púng Tra",
            level: "Xã"
          },
          {
            id: "03787",
            name: "Xã Chiềng Pấc",
            level: "Xã"
          },
          {
            id: "03790",
            name: "Xã Nậm Lầu",
            level: "Xã"
          },
          {
            id: "03793",
            name: "Xã Bon Phặng",
            level: "Xã"
          },
          {
            id: "03796",
            name: "Xã Co Tòng",
            level: "Xã"
          },
          {
            id: "03799",
            name: "Xã Muổi Nọi",
            level: "Xã"
          },
          {
            id: "03802",
            name: "Xã Pá Lông",
            level: "Xã"
          },
          {
            id: "03805",
            name: "Xã Bản Lầm",
            level: "Xã"
          }
        ]
      },
      {
        id: "120",
        name: "Huyện Mường La",
        wards: [
          {
            id: "03808",
            name: "Thị trấn Ít Ong",
            level: "Thị trấn"
          },
          {
            id: "03811",
            name: "Xã Nậm Giôn",
            level: "Xã"
          },
          {
            id: "03814",
            name: "Xã Chiềng Lao",
            level: "Xã"
          },
          {
            id: "03817",
            name: "Xã Hua Trai",
            level: "Xã"
          },
          {
            id: "03820",
            name: "Xã Ngọc Chiến",
            level: "Xã"
          },
          {
            id: "03823",
            name: "Xã Mường Trai",
            level: "Xã"
          },
          {
            id: "03826",
            name: "Xã Nậm Păm",
            level: "Xã"
          },
          {
            id: "03829",
            name: "Xã Chiềng Muôn",
            level: "Xã"
          },
          {
            id: "03832",
            name: "Xã Chiềng Ân",
            level: "Xã"
          },
          {
            id: "03835",
            name: "Xã Pi Toong",
            level: "Xã"
          },
          {
            id: "03838",
            name: "Xã Chiềng Công",
            level: "Xã"
          },
          {
            id: "03841",
            name: "Xã Tạ Bú",
            level: "Xã"
          },
          {
            id: "03844",
            name: "Xã Chiềng San",
            level: "Xã"
          },
          {
            id: "03847",
            name: "Xã Mường Bú",
            level: "Xã"
          },
          {
            id: "03850",
            name: "Xã Chiềng Hoa",
            level: "Xã"
          },
          {
            id: "03853",
            name: "Xã Mường Chùm",
            level: "Xã"
          }
        ]
      },
      {
        id: "121",
        name: "Huyện Bắc Yên",
        wards: [
          {
            id: "03856",
            name: "Thị trấn Bắc Yên",
            level: "Thị trấn"
          },
          {
            id: "03859",
            name: "Xã Phiêng Ban",
            level: "Xã"
          },
          {
            id: "03862",
            name: "Xã Hang Chú",
            level: "Xã"
          },
          {
            id: "03865",
            name: "Xã Xím Vàng",
            level: "Xã"
          },
          {
            id: "03868",
            name: "Xã Tà Xùa",
            level: "Xã"
          },
          {
            id: "03869",
            name: "Xã Háng Đồng",
            level: "Xã"
          },
          {
            id: "03871",
            name: "Xã Pắc Ngà",
            level: "Xã"
          },
          {
            id: "03874",
            name: "Xã Làng Chếu",
            level: "Xã"
          },
          {
            id: "03877",
            name: "Xã Chim Vàn",
            level: "Xã"
          },
          {
            id: "03880",
            name: "Xã Mường Khoa",
            level: "Xã"
          },
          {
            id: "03883",
            name: "Xã Song Pe",
            level: "Xã"
          },
          {
            id: "03886",
            name: "Xã Hồng Ngài",
            level: "Xã"
          },
          {
            id: "03889",
            name: "Xã Tạ Khoa",
            level: "Xã"
          },
          {
            id: "03890",
            name: "Xã Hua Nhàn",
            level: "Xã"
          },
          {
            id: "03892",
            name: "Xã Phiêng Côn",
            level: "Xã"
          },
          {
            id: "03895",
            name: "Xã Chiềng Sại",
            level: "Xã"
          }
        ]
      },
      {
        id: "122",
        name: "Huyện Phù Yên",
        wards: [
          {
            id: "03898",
            name: "Thị trấn Phù Yên",
            level: "Thị trấn"
          },
          {
            id: "03901",
            name: "Xã Suối Tọ",
            level: "Xã"
          },
          {
            id: "03904",
            name: "Xã Mường Thải",
            level: "Xã"
          },
          {
            id: "03907",
            name: "Xã Mường Cơi",
            level: "Xã"
          },
          {
            id: "03910",
            name: "Xã Quang Huy",
            level: "Xã"
          },
          {
            id: "03913",
            name: "Xã Huy Bắc",
            level: "Xã"
          },
          {
            id: "03916",
            name: "Xã Huy Thượng",
            level: "Xã"
          },
          {
            id: "03919",
            name: "Xã Tân Lang",
            level: "Xã"
          },
          {
            id: "03922",
            name: "Xã Gia Phù",
            level: "Xã"
          },
          {
            id: "03925",
            name: "Xã Tường Phù",
            level: "Xã"
          },
          {
            id: "03928",
            name: "Xã Huy Hạ",
            level: "Xã"
          },
          {
            id: "03931",
            name: "Xã Huy Tân",
            level: "Xã"
          },
          {
            id: "03934",
            name: "Xã Mường Lang",
            level: "Xã"
          },
          {
            id: "03937",
            name: "Xã Suối Bau",
            level: "Xã"
          },
          {
            id: "03940",
            name: "Xã Huy Tường",
            level: "Xã"
          },
          {
            id: "03943",
            name: "Xã Mường Do",
            level: "Xã"
          },
          {
            id: "03946",
            name: "Xã Sập Xa",
            level: "Xã"
          },
          {
            id: "03949",
            name: "Xã Tường Thượng",
            level: "Xã"
          },
          {
            id: "03952",
            name: "Xã Tường Tiến",
            level: "Xã"
          },
          {
            id: "03955",
            name: "Xã Tường Phong",
            level: "Xã"
          },
          {
            id: "03958",
            name: "Xã Tường Hạ",
            level: "Xã"
          },
          {
            id: "03961",
            name: "Xã Kim Bon",
            level: "Xã"
          },
          {
            id: "03964",
            name: "Xã Mường Bang",
            level: "Xã"
          },
          {
            id: "03967",
            name: "Xã Đá Đỏ",
            level: "Xã"
          },
          {
            id: "03970",
            name: "Xã Tân Phong",
            level: "Xã"
          },
          {
            id: "03973",
            name: "Xã Nam Phong",
            level: "Xã"
          },
          {
            id: "03976",
            name: "Xã Bắc Phong",
            level: "Xã"
          }
        ]
      },
      {
        id: "123",
        name: "Huyện Mộc Châu",
        wards: [
          {
            id: "03979",
            name: "Thị trấn Mộc Châu",
            level: "Thị trấn"
          },
          {
            id: "03982",
            name: "Thị trấn NT Mộc Châu",
            level: "Thị trấn"
          },
          {
            id: "03985",
            name: "Xã Chiềng Sơn",
            level: "Xã"
          },
          {
            id: "03988",
            name: "Xã Tân Hợp",
            level: "Xã"
          },
          {
            id: "03991",
            name: "Xã Qui Hướng",
            level: "Xã"
          },
          {
            id: "03997",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "04000",
            name: "Xã Nà Mường",
            level: "Xã"
          },
          {
            id: "04003",
            name: "Xã Tà Lai",
            level: "Xã"
          },
          {
            id: "04012",
            name: "Xã Chiềng Hắc",
            level: "Xã"
          },
          {
            id: "04015",
            name: "Xã Hua Păng",
            level: "Xã"
          },
          {
            id: "04024",
            name: "Xã Chiềng Khừa",
            level: "Xã"
          },
          {
            id: "04027",
            name: "Xã Mường Sang",
            level: "Xã"
          },
          {
            id: "04030",
            name: "Xã Đông Sang",
            level: "Xã"
          },
          {
            id: "04033",
            name: "Xã Phiêng Luông",
            level: "Xã"
          },
          {
            id: "04045",
            name: "Xã Lóng Sập",
            level: "Xã"
          }
        ]
      },
      {
        id: "124",
        name: "Huyện Yên Châu",
        wards: [
          {
            id: "04060",
            name: "Thị trấn Yên Châu",
            level: "Thị trấn"
          },
          {
            id: "04063",
            name: "Xã Chiềng Đông",
            level: "Xã"
          },
          {
            id: "04066",
            name: "Xã Sập Vạt",
            level: "Xã"
          },
          {
            id: "04069",
            name: "Xã Chiềng Sàng",
            level: "Xã"
          },
          {
            id: "04072",
            name: "Xã Chiềng Pằn",
            level: "Xã"
          },
          {
            id: "04075",
            name: "Xã Viêng Lán",
            level: "Xã"
          },
          {
            id: "04078",
            name: "Xã Chiềng Hặc",
            level: "Xã"
          },
          {
            id: "04081",
            name: "Xã Mường Lựm",
            level: "Xã"
          },
          {
            id: "04084",
            name: "Xã Chiềng On",
            level: "Xã"
          },
          {
            id: "04087",
            name: "Xã Yên Sơn",
            level: "Xã"
          },
          {
            id: "04090",
            name: "Xã Chiềng Khoi",
            level: "Xã"
          },
          {
            id: "04093",
            name: "Xã Tú Nang",
            level: "Xã"
          },
          {
            id: "04096",
            name: "Xã Lóng Phiêng",
            level: "Xã"
          },
          {
            id: "04099",
            name: "Xã Phiêng Khoài",
            level: "Xã"
          },
          {
            id: "04102",
            name: "Xã Chiềng Tương",
            level: "Xã"
          }
        ]
      },
      {
        id: "125",
        name: "Huyện Mai Sơn",
        wards: [
          {
            id: "04105",
            name: "Thị trấn Hát Lót",
            level: "Thị trấn"
          },
          {
            id: "04108",
            name: "Xã Chiềng Sung",
            level: "Xã"
          },
          {
            id: "04111",
            name: "Xã Mường Bằng",
            level: "Xã"
          },
          {
            id: "04114",
            name: "Xã Chiềng Chăn",
            level: "Xã"
          },
          {
            id: "04117",
            name: "Xã Mương Chanh",
            level: "Xã"
          },
          {
            id: "04120",
            name: "Xã Chiềng Ban",
            level: "Xã"
          },
          {
            id: "04123",
            name: "Xã Chiềng Mung",
            level: "Xã"
          },
          {
            id: "04126",
            name: "Xã Mường Bon",
            level: "Xã"
          },
          {
            id: "04129",
            name: "Xã Chiềng Chung",
            level: "Xã"
          },
          {
            id: "04132",
            name: "Xã Chiềng Mai",
            level: "Xã"
          },
          {
            id: "04135",
            name: "Xã Hát Lót",
            level: "Xã"
          },
          {
            id: "04136",
            name: "Xã Nà Pó",
            level: "Xã"
          },
          {
            id: "04138",
            name: "Xã Cò  Nòi",
            level: "Xã"
          },
          {
            id: "04141",
            name: "Xã Chiềng Nơi",
            level: "Xã"
          },
          {
            id: "04144",
            name: "Xã Phiêng Cằm",
            level: "Xã"
          },
          {
            id: "04147",
            name: "Xã Chiềng Dong",
            level: "Xã"
          },
          {
            id: "04150",
            name: "Xã Chiềng Kheo",
            level: "Xã"
          },
          {
            id: "04153",
            name: "Xã Chiềng Ve",
            level: "Xã"
          },
          {
            id: "04156",
            name: "Xã Chiềng Lương",
            level: "Xã"
          },
          {
            id: "04159",
            name: "Xã Phiêng Pằn",
            level: "Xã"
          },
          {
            id: "04162",
            name: "Xã Nà Ơt",
            level: "Xã"
          },
          {
            id: "04165",
            name: "Xã Tà Hộc",
            level: "Xã"
          }
        ]
      },
      {
        id: "126",
        name: "Huyện Sông Mã",
        wards: [
          {
            id: "04168",
            name: "Thị trấn Sông Mã",
            level: "Thị trấn"
          },
          {
            id: "04171",
            name: "Xã Bó Sinh",
            level: "Xã"
          },
          {
            id: "04174",
            name: "Xã Pú Pẩu",
            level: "Xã"
          },
          {
            id: "04177",
            name: "Xã Chiềng Phung",
            level: "Xã"
          },
          {
            id: "04180",
            name: "Xã Chiềng En",
            level: "Xã"
          },
          {
            id: "04183",
            name: "Xã Mường Lầm",
            level: "Xã"
          },
          {
            id: "04186",
            name: "Xã Nậm Ty",
            level: "Xã"
          },
          {
            id: "04189",
            name: "Xã Đứa Mòn",
            level: "Xã"
          },
          {
            id: "04192",
            name: "Xã Yên Hưng",
            level: "Xã"
          },
          {
            id: "04195",
            name: "Xã Chiềng Sơ",
            level: "Xã"
          },
          {
            id: "04198",
            name: "Xã Nà Nghịu",
            level: "Xã"
          },
          {
            id: "04201",
            name: "Xã Nậm Mằn",
            level: "Xã"
          },
          {
            id: "04204",
            name: "Xã Chiềng Khoong",
            level: "Xã"
          },
          {
            id: "04207",
            name: "Xã Chiềng Cang",
            level: "Xã"
          },
          {
            id: "04210",
            name: "Xã Huổi Một",
            level: "Xã"
          },
          {
            id: "04213",
            name: "Xã Mường Sai",
            level: "Xã"
          },
          {
            id: "04216",
            name: "Xã Mường Cai",
            level: "Xã"
          },
          {
            id: "04219",
            name: "Xã Mường Hung",
            level: "Xã"
          },
          {
            id: "04222",
            name: "Xã Chiềng Khương",
            level: "Xã"
          }
        ]
      },
      {
        id: "127",
        name: "Huyện Sốp Cộp",
        wards: [
          {
            id: "04225",
            name: "Xã Sam Kha",
            level: "Xã"
          },
          {
            id: "04228",
            name: "Xã Púng Bánh",
            level: "Xã"
          },
          {
            id: "04231",
            name: "Xã Sốp Cộp",
            level: "Xã"
          },
          {
            id: "04234",
            name: "Xã Dồm Cang",
            level: "Xã"
          },
          {
            id: "04237",
            name: "Xã Nậm Lạnh",
            level: "Xã"
          },
          {
            id: "04240",
            name: "Xã Mường Lèo",
            level: "Xã"
          },
          {
            id: "04243",
            name: "Xã Mường Và",
            level: "Xã"
          },
          {
            id: "04246",
            name: "Xã Mường Lạn",
            level: "Xã"
          }
        ]
      },
      {
        id: "128",
        name: "Huyện Vân Hồ",
        wards: [
          {
            id: "03994",
            name: "Xã Suối Bàng",
            level: "Xã"
          },
          {
            id: "04006",
            name: "Xã Song Khủa",
            level: "Xã"
          },
          {
            id: "04009",
            name: "Xã Liên Hoà",
            level: "Xã"
          },
          {
            id: "04018",
            name: "Xã Tô Múa",
            level: "Xã"
          },
          {
            id: "04021",
            name: "Xã Mường Tè",
            level: "Xã"
          },
          {
            id: "04036",
            name: "Xã Chiềng Khoa",
            level: "Xã"
          },
          {
            id: "04039",
            name: "Xã Mường Men",
            level: "Xã"
          },
          {
            id: "04042",
            name: "Xã Quang Minh",
            level: "Xã"
          },
          {
            id: "04048",
            name: "Xã Vân Hồ",
            level: "Xã"
          },
          {
            id: "04051",
            name: "Xã Lóng Luông",
            level: "Xã"
          },
          {
            id: "04054",
            name: "Xã Chiềng Yên",
            level: "Xã"
          },
          {
            id: "04056",
            name: "Xã Chiềng Xuân",
            level: "Xã"
          },
          {
            id: "04057",
            name: "Xã Xuân Nha",
            level: "Xã"
          },
          {
            id: "04058",
            name: "Xã Tân Xuân",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "15",
    name: "Tỉnh Yên Bái",
    districts: [
      {
        id: "132",
        name: "Thành phố Yên Bái",
        wards: [
          {
            id: "04249",
            name: "Phường Yên Thịnh",
            level: "Phường"
          },
          {
            id: "04252",
            name: "Phường Yên Ninh",
            level: "Phường"
          },
          {
            id: "04255",
            name: "Phường Minh Tân",
            level: "Phường"
          },
          {
            id: "04258",
            name: "Phường Nguyễn Thái Học",
            level: "Phường"
          },
          {
            id: "04261",
            name: "Phường Đồng Tâm",
            level: "Phường"
          },
          {
            id: "04264",
            name: "Phường Nguyễn Phúc",
            level: "Phường"
          },
          {
            id: "04267",
            name: "Phường Hồng Hà",
            level: "Phường"
          },
          {
            id: "04270",
            name: "Xã Minh Bảo",
            level: "Xã"
          },
          {
            id: "04273",
            name: "Phường Nam Cường",
            level: "Phường"
          },
          {
            id: "04276",
            name: "Xã Tuy Lộc",
            level: "Xã"
          },
          {
            id: "04279",
            name: "Xã Tân Thịnh",
            level: "Xã"
          },
          {
            id: "04540",
            name: "Xã Âu Lâu",
            level: "Xã"
          },
          {
            id: "04543",
            name: "Xã Giới Phiên",
            level: "Xã"
          },
          {
            id: "04546",
            name: "Phường Hợp Minh",
            level: "Phường"
          },
          {
            id: "04558",
            name: "Xã Văn Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "133",
        name: "Thị xã Nghĩa Lộ",
        wards: [
          {
            id: "04282",
            name: "Phường Pú Trạng",
            level: "Phường"
          },
          {
            id: "04285",
            name: "Phường Trung Tâm",
            level: "Phường"
          },
          {
            id: "04288",
            name: "Phường Tân An",
            level: "Phường"
          },
          {
            id: "04291",
            name: "Phường Cầu Thia",
            level: "Phường"
          },
          {
            id: "04294",
            name: "Xã Nghĩa Lợi",
            level: "Xã"
          },
          {
            id: "04297",
            name: "Xã Nghĩa Phúc",
            level: "Xã"
          },
          {
            id: "04300",
            name: "Xã Nghĩa An",
            level: "Xã"
          },
          {
            id: "04624",
            name: "Xã Nghĩa Lộ",
            level: "Xã"
          },
          {
            id: "04660",
            name: "Xã Sơn A",
            level: "Xã"
          },
          {
            id: "04663",
            name: "Xã Phù Nham",
            level: "Xã"
          },
          {
            id: "04675",
            name: "Xã Thanh Lương",
            level: "Xã"
          },
          {
            id: "04678",
            name: "Xã Hạnh Sơn",
            level: "Xã"
          },
          {
            id: "04681",
            name: "Xã Phúc Sơn",
            level: "Xã"
          },
          {
            id: "04684",
            name: "Xã Thạch Lương",
            level: "Xã"
          }
        ]
      },
      {
        id: "135",
        name: "Huyện Lục Yên",
        wards: [
          {
            id: "04303",
            name: "Thị trấn Yên Thế",
            level: "Thị trấn"
          },
          {
            id: "04306",
            name: "Xã Tân Phượng",
            level: "Xã"
          },
          {
            id: "04309",
            name: "Xã Lâm Thượng",
            level: "Xã"
          },
          {
            id: "04312",
            name: "Xã Khánh Thiện",
            level: "Xã"
          },
          {
            id: "04315",
            name: "Xã Minh Chuẩn",
            level: "Xã"
          },
          {
            id: "04318",
            name: "Xã Mai Sơn",
            level: "Xã"
          },
          {
            id: "04321",
            name: "Xã Khai Trung",
            level: "Xã"
          },
          {
            id: "04324",
            name: "Xã Mường Lai",
            level: "Xã"
          },
          {
            id: "04327",
            name: "Xã An Lạc",
            level: "Xã"
          },
          {
            id: "04330",
            name: "Xã Minh Xuân",
            level: "Xã"
          },
          {
            id: "04333",
            name: "Xã Tô Mậu",
            level: "Xã"
          },
          {
            id: "04336",
            name: "Xã Tân Lĩnh",
            level: "Xã"
          },
          {
            id: "04339",
            name: "Xã Yên Thắng",
            level: "Xã"
          },
          {
            id: "04342",
            name: "Xã Khánh Hoà",
            level: "Xã"
          },
          {
            id: "04345",
            name: "Xã Vĩnh Lạc",
            level: "Xã"
          },
          {
            id: "04348",
            name: "Xã Liễu Đô",
            level: "Xã"
          },
          {
            id: "04351",
            name: "Xã Động Quan",
            level: "Xã"
          },
          {
            id: "04354",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "04357",
            name: "Xã Minh Tiến",
            level: "Xã"
          },
          {
            id: "04360",
            name: "Xã Trúc Lâu",
            level: "Xã"
          },
          {
            id: "04363",
            name: "Xã Phúc Lợi",
            level: "Xã"
          },
          {
            id: "04366",
            name: "Xã Phan Thanh",
            level: "Xã"
          },
          {
            id: "04369",
            name: "Xã An Phú",
            level: "Xã"
          },
          {
            id: "04372",
            name: "Xã Trung Tâm",
            level: "Xã"
          }
        ]
      },
      {
        id: "136",
        name: "Huyện Văn Yên",
        wards: [
          {
            id: "04375",
            name: "Thị trấn Mậu A",
            level: "Thị trấn"
          },
          {
            id: "04378",
            name: "Xã Lang Thíp",
            level: "Xã"
          },
          {
            id: "04381",
            name: "Xã Lâm Giang",
            level: "Xã"
          },
          {
            id: "04384",
            name: "Xã Châu Quế Thượng",
            level: "Xã"
          },
          {
            id: "04387",
            name: "Xã Châu Quế Hạ",
            level: "Xã"
          },
          {
            id: "04390",
            name: "Xã An Bình",
            level: "Xã"
          },
          {
            id: "04393",
            name: "Xã Quang Minh",
            level: "Xã"
          },
          {
            id: "04396",
            name: "Xã Đông An",
            level: "Xã"
          },
          {
            id: "04399",
            name: "Xã Đông Cuông",
            level: "Xã"
          },
          {
            id: "04402",
            name: "Xã Phong Dụ Hạ",
            level: "Xã"
          },
          {
            id: "04405",
            name: "Xã Mậu Đông",
            level: "Xã"
          },
          {
            id: "04408",
            name: "Xã Ngòi A",
            level: "Xã"
          },
          {
            id: "04411",
            name: "Xã Xuân Tầm",
            level: "Xã"
          },
          {
            id: "04414",
            name: "Xã Tân Hợp",
            level: "Xã"
          },
          {
            id: "04417",
            name: "Xã An Thịnh",
            level: "Xã"
          },
          {
            id: "04420",
            name: "Xã Yên Thái",
            level: "Xã"
          },
          {
            id: "04423",
            name: "Xã Phong Dụ Thượng",
            level: "Xã"
          },
          {
            id: "04426",
            name: "Xã Yên Hợp",
            level: "Xã"
          },
          {
            id: "04429",
            name: "Xã Đại Sơn",
            level: "Xã"
          },
          {
            id: "04435",
            name: "Xã Đại Phác",
            level: "Xã"
          },
          {
            id: "04438",
            name: "Xã Yên Phú",
            level: "Xã"
          },
          {
            id: "04441",
            name: "Xã Xuân Ái",
            level: "Xã"
          },
          {
            id: "04447",
            name: "Xã Viễn Sơn",
            level: "Xã"
          },
          {
            id: "04450",
            name: "Xã Mỏ Vàng",
            level: "Xã"
          },
          {
            id: "04453",
            name: "Xã Nà Hẩu",
            level: "Xã"
          }
        ]
      },
      {
        id: "137",
        name: "Huyện Mù Căng Chải",
        wards: [
          {
            id: "04456",
            name: "Thị trấn Mù Căng Chải",
            level: "Thị trấn"
          },
          {
            id: "04459",
            name: "Xã Hồ Bốn",
            level: "Xã"
          },
          {
            id: "04462",
            name: "Xã Nậm Có",
            level: "Xã"
          },
          {
            id: "04465",
            name: "Xã Khao Mang",
            level: "Xã"
          },
          {
            id: "04468",
            name: "Xã Mồ Dề",
            level: "Xã"
          },
          {
            id: "04471",
            name: "Xã Chế Cu Nha",
            level: "Xã"
          },
          {
            id: "04474",
            name: "Xã Lao Chải",
            level: "Xã"
          },
          {
            id: "04477",
            name: "Xã Kim Nọi",
            level: "Xã"
          },
          {
            id: "04480",
            name: "Xã Cao Phạ",
            level: "Xã"
          },
          {
            id: "04483",
            name: "Xã La Pán Tẩn",
            level: "Xã"
          },
          {
            id: "04486",
            name: "Xã Dế Su Phình",
            level: "Xã"
          },
          {
            id: "04489",
            name: "Xã Chế Tạo",
            level: "Xã"
          },
          {
            id: "04492",
            name: "Xã Púng Luông",
            level: "Xã"
          },
          {
            id: "04495",
            name: "Xã Nậm Khắt",
            level: "Xã"
          }
        ]
      },
      {
        id: "138",
        name: "Huyện Trấn Yên",
        wards: [
          {
            id: "04498",
            name: "Thị trấn Cổ Phúc",
            level: "Thị trấn"
          },
          {
            id: "04501",
            name: "Xã Tân Đồng",
            level: "Xã"
          },
          {
            id: "04504",
            name: "Xã Báo Đáp",
            level: "Xã"
          },
          {
            id: "04507",
            name: "Xã Đào Thịnh",
            level: "Xã"
          },
          {
            id: "04510",
            name: "Xã Việt Thành",
            level: "Xã"
          },
          {
            id: "04513",
            name: "Xã Hòa Cuông",
            level: "Xã"
          },
          {
            id: "04516",
            name: "Xã Minh Quán",
            level: "Xã"
          },
          {
            id: "04519",
            name: "Xã Quy Mông",
            level: "Xã"
          },
          {
            id: "04522",
            name: "Xã Cường Thịnh",
            level: "Xã"
          },
          {
            id: "04525",
            name: "Xã Kiên Thành",
            level: "Xã"
          },
          {
            id: "04528",
            name: "Xã Nga Quán",
            level: "Xã"
          },
          {
            id: "04531",
            name: "Xã Y Can",
            level: "Xã"
          },
          {
            id: "04537",
            name: "Xã Lương Thịnh",
            level: "Xã"
          },
          {
            id: "04561",
            name: "Xã Bảo Hưng",
            level: "Xã"
          },
          {
            id: "04564",
            name: "Xã Việt Cường",
            level: "Xã"
          },
          {
            id: "04567",
            name: "Xã Minh Quân",
            level: "Xã"
          },
          {
            id: "04570",
            name: "Xã Hồng Ca",
            level: "Xã"
          },
          {
            id: "04573",
            name: "Xã Hưng Thịnh",
            level: "Xã"
          },
          {
            id: "04576",
            name: "Xã Hưng Khánh",
            level: "Xã"
          },
          {
            id: "04579",
            name: "Xã Việt Hồng",
            level: "Xã"
          },
          {
            id: "04582",
            name: "Xã Vân Hội",
            level: "Xã"
          }
        ]
      },
      {
        id: "139",
        name: "Huyện Trạm Tấu",
        wards: [
          {
            id: "04585",
            name: "Thị trấn Trạm Tấu",
            level: "Thị trấn"
          },
          {
            id: "04588",
            name: "Xã Túc Đán",
            level: "Xã"
          },
          {
            id: "04591",
            name: "Xã Pá Lau",
            level: "Xã"
          },
          {
            id: "04594",
            name: "Xã Xà Hồ",
            level: "Xã"
          },
          {
            id: "04597",
            name: "Xã Phình Hồ",
            level: "Xã"
          },
          {
            id: "04600",
            name: "Xã Trạm Tấu",
            level: "Xã"
          },
          {
            id: "04603",
            name: "Xã Tà Si Láng",
            level: "Xã"
          },
          {
            id: "04606",
            name: "Xã Pá Hu",
            level: "Xã"
          },
          {
            id: "04609",
            name: "Xã Làng Nhì",
            level: "Xã"
          },
          {
            id: "04612",
            name: "Xã Bản Công",
            level: "Xã"
          },
          {
            id: "04615",
            name: "Xã Bản Mù",
            level: "Xã"
          },
          {
            id: "04618",
            name: "Xã Hát Lìu",
            level: "Xã"
          }
        ]
      },
      {
        id: "140",
        name: "Huyện Văn Chấn",
        wards: [
          {
            id: "04621",
            name: "Thị trấn NT Liên Sơn",
            level: "Thị trấn"
          },
          {
            id: "04627",
            name: "Thị trấn NT Trần Phú",
            level: "Thị trấn"
          },
          {
            id: "04630",
            name: "Xã Tú Lệ",
            level: "Xã"
          },
          {
            id: "04633",
            name: "Xã Nậm Búng",
            level: "Xã"
          },
          {
            id: "04636",
            name: "Xã Gia Hội",
            level: "Xã"
          },
          {
            id: "04639",
            name: "Xã Sùng Đô",
            level: "Xã"
          },
          {
            id: "04642",
            name: "Xã Nậm Mười",
            level: "Xã"
          },
          {
            id: "04645",
            name: "Xã An Lương",
            level: "Xã"
          },
          {
            id: "04648",
            name: "Xã Nậm Lành",
            level: "Xã"
          },
          {
            id: "04651",
            name: "Xã Sơn Lương",
            level: "Xã"
          },
          {
            id: "04654",
            name: "Xã Suối Quyền",
            level: "Xã"
          },
          {
            id: "04657",
            name: "Xã Suối Giàng",
            level: "Xã"
          },
          {
            id: "04666",
            name: "Xã Nghĩa Sơn",
            level: "Xã"
          },
          {
            id: "04669",
            name: "Xã Suối Bu",
            level: "Xã"
          },
          {
            id: "04672",
            name: "Thị trấn Sơn Thịnh",
            level: "Thị trấn"
          },
          {
            id: "04687",
            name: "Xã Đại Lịch",
            level: "Xã"
          },
          {
            id: "04690",
            name: "Xã Đồng Khê",
            level: "Xã"
          },
          {
            id: "04693",
            name: "Xã Cát Thịnh",
            level: "Xã"
          },
          {
            id: "04696",
            name: "Xã Tân Thịnh",
            level: "Xã"
          },
          {
            id: "04699",
            name: "Xã Chấn Thịnh",
            level: "Xã"
          },
          {
            id: "04702",
            name: "Xã Bình Thuận",
            level: "Xã"
          },
          {
            id: "04705",
            name: "Xã Thượng Bằng La",
            level: "Xã"
          },
          {
            id: "04708",
            name: "Xã Minh An",
            level: "Xã"
          },
          {
            id: "04711",
            name: "Xã Nghĩa Tâm",
            level: "Xã"
          }
        ]
      },
      {
        id: "141",
        name: "Huyện Yên Bình",
        wards: [
          {
            id: "04714",
            name: "Thị trấn Yên Bình",
            level: "Thị trấn"
          },
          {
            id: "04717",
            name: "Thị trấn Thác Bà",
            level: "Thị trấn"
          },
          {
            id: "04720",
            name: "Xã Xuân Long",
            level: "Xã"
          },
          {
            id: "04726",
            name: "Xã Cảm Nhân",
            level: "Xã"
          },
          {
            id: "04729",
            name: "Xã Ngọc Chấn",
            level: "Xã"
          },
          {
            id: "04732",
            name: "Xã Tân Nguyên",
            level: "Xã"
          },
          {
            id: "04735",
            name: "Xã Phúc Ninh",
            level: "Xã"
          },
          {
            id: "04738",
            name: "Xã Bảo Ái",
            level: "Xã"
          },
          {
            id: "04741",
            name: "Xã Mỹ Gia",
            level: "Xã"
          },
          {
            id: "04744",
            name: "Xã Xuân Lai",
            level: "Xã"
          },
          {
            id: "04747",
            name: "Xã Mông Sơn",
            level: "Xã"
          },
          {
            id: "04750",
            name: "Xã Cảm Ân",
            level: "Xã"
          },
          {
            id: "04753",
            name: "Xã Yên Thành",
            level: "Xã"
          },
          {
            id: "04756",
            name: "Xã Tân Hương",
            level: "Xã"
          },
          {
            id: "04759",
            name: "Xã Phúc An",
            level: "Xã"
          },
          {
            id: "04762",
            name: "Xã Bạch Hà",
            level: "Xã"
          },
          {
            id: "04765",
            name: "Xã Vũ Linh",
            level: "Xã"
          },
          {
            id: "04768",
            name: "Xã Đại Đồng",
            level: "Xã"
          },
          {
            id: "04771",
            name: "Xã Vĩnh Kiên",
            level: "Xã"
          },
          {
            id: "04774",
            name: "Xã Yên Bình",
            level: "Xã"
          },
          {
            id: "04777",
            name: "Xã Thịnh Hưng",
            level: "Xã"
          },
          {
            id: "04780",
            name: "Xã Hán Đà",
            level: "Xã"
          },
          {
            id: "04783",
            name: "Xã Phú Thịnh",
            level: "Xã"
          },
          {
            id: "04786",
            name: "Xã Đại Minh",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "17",
    name: "Tỉnh Hoà Bình",
    districts: [
      {
        id: "148",
        name: "Thành phố Hòa Bình",
        wards: [
          {
            id: "04789",
            name: "Phường Thái Bình",
            level: "Phường"
          },
          {
            id: "04792",
            name: "Phường Tân Hòa",
            level: "Phường"
          },
          {
            id: "04795",
            name: "Phường Thịnh Lang",
            level: "Phường"
          },
          {
            id: "04798",
            name: "Phường Hữu Nghị",
            level: "Phường"
          },
          {
            id: "04801",
            name: "Phường Tân Thịnh",
            level: "Phường"
          },
          {
            id: "04804",
            name: "Phường Đồng Tiến",
            level: "Phường"
          },
          {
            id: "04807",
            name: "Phường Phương Lâm",
            level: "Phường"
          },
          {
            id: "04813",
            name: "Xã Yên Mông",
            level: "Xã"
          },
          {
            id: "04816",
            name: "Xã Sủ Ngòi",
            level: "Xã"
          },
          {
            id: "04819",
            name: "Phường Dân Chủ",
            level: "Phường"
          },
          {
            id: "04825",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "04828",
            name: "Phường Thống Nhất",
            level: "Phường"
          },
          {
            id: "04894",
            name: "Phường Kỳ Sơn",
            level: "Phường"
          },
          {
            id: "04897",
            name: "Xã Thịnh Minh",
            level: "Xã"
          },
          {
            id: "04903",
            name: "Xã Hợp Thành",
            level: "Xã"
          },
          {
            id: "04906",
            name: "Xã Quang Tiến",
            level: "Xã"
          },
          {
            id: "04912",
            name: "Xã Mông Hóa",
            level: "Xã"
          },
          {
            id: "04918",
            name: "Xã Trung Minh",
            level: "Xã"
          },
          {
            id: "04921",
            name: "Xã Độc Lập",
            level: "Xã"
          }
        ]
      },
      {
        id: "150",
        name: "Huyện Đà Bắc",
        wards: [
          {
            id: "04831",
            name: "Thị trấn Đà Bắc",
            level: "Thị trấn"
          },
          {
            id: "04834",
            name: "Xã Nánh Nghê",
            level: "Xã"
          },
          {
            id: "04840",
            name: "Xã Giáp Đắt",
            level: "Xã"
          },
          {
            id: "04846",
            name: "Xã Mường Chiềng",
            level: "Xã"
          },
          {
            id: "04849",
            name: "Xã Tân Pheo",
            level: "Xã"
          },
          {
            id: "04852",
            name: "Xã Đồng Chum",
            level: "Xã"
          },
          {
            id: "04855",
            name: "Xã Tân Minh",
            level: "Xã"
          },
          {
            id: "04858",
            name: "Xã Đoàn Kết",
            level: "Xã"
          },
          {
            id: "04861",
            name: "Xã Đồng Ruộng",
            level: "Xã"
          },
          {
            id: "04867",
            name: "Xã Tú Lý",
            level: "Xã"
          },
          {
            id: "04870",
            name: "Xã Trung Thành",
            level: "Xã"
          },
          {
            id: "04873",
            name: "Xã Yên Hòa",
            level: "Xã"
          },
          {
            id: "04876",
            name: "Xã Cao Sơn",
            level: "Xã"
          },
          {
            id: "04879",
            name: "Xã Toàn Sơn",
            level: "Xã"
          },
          {
            id: "04885",
            name: "Xã Hiền Lương",
            level: "Xã"
          },
          {
            id: "04888",
            name: "Xã Tiền Phong",
            level: "Xã"
          },
          {
            id: "04891",
            name: "Xã Vầy Nưa",
            level: "Xã"
          }
        ]
      },
      {
        id: "152",
        name: "Huyện Lương Sơn",
        wards: [
          {
            id: "04924",
            name: "Thị trấn Lương Sơn",
            level: "Thị trấn"
          },
          {
            id: "04942",
            name: "Xã Lâm Sơn",
            level: "Xã"
          },
          {
            id: "04945",
            name: "Xã Hòa Sơn",
            level: "Xã"
          },
          {
            id: "04951",
            name: "Xã Tân Vinh",
            level: "Xã"
          },
          {
            id: "04954",
            name: "Xã Nhuận Trạch",
            level: "Xã"
          },
          {
            id: "04957",
            name: "Xã Cao Sơn",
            level: "Xã"
          },
          {
            id: "04960",
            name: "Xã Cư Yên",
            level: "Xã"
          },
          {
            id: "04969",
            name: "Xã Liên Sơn",
            level: "Xã"
          },
          {
            id: "05008",
            name: "Xã Cao Dương",
            level: "Xã"
          },
          {
            id: "05041",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "05047",
            name: "Xã Thanh Cao",
            level: "Xã"
          }
        ]
      },
      {
        id: "153",
        name: "Huyện Kim Bôi",
        wards: [
          {
            id: "04978",
            name: "Thị trấn Bo",
            level: "Thị trấn"
          },
          {
            id: "04984",
            name: "Xã Đú Sáng",
            level: "Xã"
          },
          {
            id: "04987",
            name: "Xã Hùng Sơn",
            level: "Xã"
          },
          {
            id: "04990",
            name: "Xã Bình Sơn",
            level: "Xã"
          },
          {
            id: "04999",
            name: "Xã Tú Sơn",
            level: "Xã"
          },
          {
            id: "05005",
            name: "Xã Vĩnh Tiến",
            level: "Xã"
          },
          {
            id: "05014",
            name: "Xã Đông Bắc",
            level: "Xã"
          },
          {
            id: "05017",
            name: "Xã Xuân Thủy",
            level: "Xã"
          },
          {
            id: "05026",
            name: "Xã Vĩnh Đồng",
            level: "Xã"
          },
          {
            id: "05035",
            name: "Xã Kim Lập",
            level: "Xã"
          },
          {
            id: "05038",
            name: "Xã Hợp Tiến",
            level: "Xã"
          },
          {
            id: "05065",
            name: "Xã Kim Bôi",
            level: "Xã"
          },
          {
            id: "05068",
            name: "Xã Nam Thượng",
            level: "Xã"
          },
          {
            id: "05077",
            name: "Xã Cuối Hạ",
            level: "Xã"
          },
          {
            id: "05080",
            name: "Xã Sào Báy",
            level: "Xã"
          },
          {
            id: "05083",
            name: "Xã Mi Hòa",
            level: "Xã"
          },
          {
            id: "05086",
            name: "Xã Nuông Dăm",
            level: "Xã"
          }
        ]
      },
      {
        id: "154",
        name: "Huyện Cao Phong",
        wards: [
          {
            id: "05089",
            name: "Thị trấn Cao Phong",
            level: "Thị trấn"
          },
          {
            id: "05092",
            name: "Xã Bình Thanh",
            level: "Xã"
          },
          {
            id: "05095",
            name: "Xã Thung Nai",
            level: "Xã"
          },
          {
            id: "05098",
            name: "Xã Bắc Phong",
            level: "Xã"
          },
          {
            id: "05101",
            name: "Xã Thu Phong",
            level: "Xã"
          },
          {
            id: "05104",
            name: "Xã Hợp Phong",
            level: "Xã"
          },
          {
            id: "05110",
            name: "Xã Tây Phong",
            level: "Xã"
          },
          {
            id: "05116",
            name: "Xã Dũng Phong",
            level: "Xã"
          },
          {
            id: "05119",
            name: "Xã Nam Phong",
            level: "Xã"
          },
          {
            id: "05125",
            name: "Xã Thạch Yên",
            level: "Xã"
          }
        ]
      },
      {
        id: "155",
        name: "Huyện Tân Lạc",
        wards: [
          {
            id: "05128",
            name: "Thị trấn Mãn Đức",
            level: "Thị trấn"
          },
          {
            id: "05134",
            name: "Xã Suối Hoa",
            level: "Xã"
          },
          {
            id: "05137",
            name: "Xã Phú Vinh",
            level: "Xã"
          },
          {
            id: "05140",
            name: "Xã Phú Cường",
            level: "Xã"
          },
          {
            id: "05143",
            name: "Xã Mỹ Hòa",
            level: "Xã"
          },
          {
            id: "05152",
            name: "Xã Quyết Chiến",
            level: "Xã"
          },
          {
            id: "05158",
            name: "Xã Phong Phú",
            level: "Xã"
          },
          {
            id: "05164",
            name: "Xã Tử Nê",
            level: "Xã"
          },
          {
            id: "05167",
            name: "Xã Thanh Hối",
            level: "Xã"
          },
          {
            id: "05170",
            name: "Xã Ngọc Mỹ",
            level: "Xã"
          },
          {
            id: "05173",
            name: "Xã Đông Lai",
            level: "Xã"
          },
          {
            id: "05176",
            name: "Xã Vân Sơn",
            level: "Xã"
          },
          {
            id: "05182",
            name: "Xã Nhân Mỹ",
            level: "Xã"
          },
          {
            id: "05191",
            name: "Xã Lỗ Sơn",
            level: "Xã"
          },
          {
            id: "05194",
            name: "Xã Ngổ Luông",
            level: "Xã"
          },
          {
            id: "05197",
            name: "Xã Gia Mô",
            level: "Xã"
          }
        ]
      },
      {
        id: "156",
        name: "Huyện Mai Châu",
        wards: [
          {
            id: "04882",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "05200",
            name: "Thị trấn Mai Châu",
            level: "Thị trấn"
          },
          {
            id: "05206",
            name: "Xã Sơn Thủy",
            level: "Xã"
          },
          {
            id: "05209",
            name: "Xã Pà Cò",
            level: "Xã"
          },
          {
            id: "05212",
            name: "Xã Hang Kia",
            level: "Xã"
          },
          {
            id: "05221",
            name: "Xã Đồng Tân",
            level: "Xã"
          },
          {
            id: "05224",
            name: "Xã Cun Pheo",
            level: "Xã"
          },
          {
            id: "05227",
            name: "Xã Bao La",
            level: "Xã"
          },
          {
            id: "05233",
            name: "Xã Tòng Đậu",
            level: "Xã"
          },
          {
            id: "05242",
            name: "Xã Nà Phòn",
            level: "Xã"
          },
          {
            id: "05245",
            name: "Xã Săm Khóe",
            level: "Xã"
          },
          {
            id: "05248",
            name: "Xã Chiềng Châu",
            level: "Xã"
          },
          {
            id: "05251",
            name: "Xã Mai Hạ",
            level: "Xã"
          },
          {
            id: "05254",
            name: "Xã Thành Sơn",
            level: "Xã"
          },
          {
            id: "05257",
            name: "Xã Mai Hịch",
            level: "Xã"
          },
          {
            id: "05263",
            name: "Xã Vạn Mai",
            level: "Xã"
          }
        ]
      },
      {
        id: "157",
        name: "Huyện Lạc Sơn",
        wards: [
          {
            id: "05266",
            name: "Thị trấn Vụ Bản",
            level: "Thị trấn"
          },
          {
            id: "05269",
            name: "Xã Quý Hòa",
            level: "Xã"
          },
          {
            id: "05272",
            name: "Xã Miền Đồi",
            level: "Xã"
          },
          {
            id: "05275",
            name: "Xã Mỹ Thành",
            level: "Xã"
          },
          {
            id: "05278",
            name: "Xã Tuân Đạo",
            level: "Xã"
          },
          {
            id: "05281",
            name: "Xã Văn Nghĩa",
            level: "Xã"
          },
          {
            id: "05284",
            name: "Xã Văn Sơn",
            level: "Xã"
          },
          {
            id: "05287",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "05290",
            name: "Xã Nhân Nghĩa",
            level: "Xã"
          },
          {
            id: "05293",
            name: "Xã Thượng Cốc",
            level: "Xã"
          },
          {
            id: "05299",
            name: "Xã Quyết Thắng",
            level: "Xã"
          },
          {
            id: "05302",
            name: "Xã Xuất Hóa",
            level: "Xã"
          },
          {
            id: "05305",
            name: "Xã Yên Phú",
            level: "Xã"
          },
          {
            id: "05308",
            name: "Xã Bình Hẻm",
            level: "Xã"
          },
          {
            id: "05320",
            name: "Xã Định Cư",
            level: "Xã"
          },
          {
            id: "05323",
            name: "Xã Chí Đạo",
            level: "Xã"
          },
          {
            id: "05329",
            name: "Xã Ngọc Sơn",
            level: "Xã"
          },
          {
            id: "05332",
            name: "Xã Hương Nhượng",
            level: "Xã"
          },
          {
            id: "05335",
            name: "Xã Vũ Bình",
            level: "Xã"
          },
          {
            id: "05338",
            name: "Xã Tự Do",
            level: "Xã"
          },
          {
            id: "05341",
            name: "Xã Yên Nghiệp",
            level: "Xã"
          },
          {
            id: "05344",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "05347",
            name: "Xã Ân Nghĩa",
            level: "Xã"
          },
          {
            id: "05350",
            name: "Xã Ngọc Lâu",
            level: "Xã"
          }
        ]
      },
      {
        id: "158",
        name: "Huyện Yên Thủy",
        wards: [
          {
            id: "05353",
            name: "Thị trấn Hàng Trạm",
            level: "Thị trấn"
          },
          {
            id: "05356",
            name: "Xã Lạc Sỹ",
            level: "Xã"
          },
          {
            id: "05362",
            name: "Xã Lạc Lương",
            level: "Xã"
          },
          {
            id: "05365",
            name: "Xã Bảo Hiệu",
            level: "Xã"
          },
          {
            id: "05368",
            name: "Xã Đa Phúc",
            level: "Xã"
          },
          {
            id: "05371",
            name: "Xã Hữu Lợi",
            level: "Xã"
          },
          {
            id: "05374",
            name: "Xã Lạc Thịnh",
            level: "Xã"
          },
          {
            id: "05380",
            name: "Xã Đoàn Kết",
            level: "Xã"
          },
          {
            id: "05383",
            name: "Xã Phú Lai",
            level: "Xã"
          },
          {
            id: "05386",
            name: "Xã Yên Trị",
            level: "Xã"
          },
          {
            id: "05389",
            name: "Xã Ngọc Lương",
            level: "Xã"
          }
        ]
      },
      {
        id: "159",
        name: "Huyện Lạc Thủy",
        wards: [
          {
            id: "04981",
            name: "Thị trấn Ba Hàng Đồi",
            level: "Thị trấn"
          },
          {
            id: "05392",
            name: "Thị trấn Chi Nê",
            level: "Thị trấn"
          },
          {
            id: "05395",
            name: "Xã Phú Nghĩa",
            level: "Xã"
          },
          {
            id: "05398",
            name: "Xã Phú Thành",
            level: "Xã"
          },
          {
            id: "05404",
            name: "Xã Hưng Thi",
            level: "Xã"
          },
          {
            id: "05413",
            name: "Xã Khoan Dụ",
            level: "Xã"
          },
          {
            id: "05419",
            name: "Xã Đồng Tâm",
            level: "Xã"
          },
          {
            id: "05422",
            name: "Xã Yên Bồng",
            level: "Xã"
          },
          {
            id: "05425",
            name: "Xã Thống Nhất",
            level: "Xã"
          },
          {
            id: "05428",
            name: "Xã An Bình",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "19",
    name: "Tỉnh Thái Nguyên",
    districts: [
      {
        id: "164",
        name: "Thành phố Thái Nguyên",
        wards: [
          {
            id: "05431",
            name: "Phường Quán Triều",
            level: "Phường"
          },
          {
            id: "05434",
            name: "Phường Quang Vinh",
            level: "Phường"
          },
          {
            id: "05437",
            name: "Phường Túc Duyên",
            level: "Phường"
          },
          {
            id: "05440",
            name: "Phường Hoàng Văn Thụ",
            level: "Phường"
          },
          {
            id: "05443",
            name: "Phường Trưng Vương",
            level: "Phường"
          },
          {
            id: "05446",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "05449",
            name: "Phường Phan Đình Phùng",
            level: "Phường"
          },
          {
            id: "05452",
            name: "Phường Tân Thịnh",
            level: "Phường"
          },
          {
            id: "05455",
            name: "Phường Thịnh Đán",
            level: "Phường"
          },
          {
            id: "05458",
            name: "Phường Đồng Quang",
            level: "Phường"
          },
          {
            id: "05461",
            name: "Phường Gia Sàng",
            level: "Phường"
          },
          {
            id: "05464",
            name: "Phường Tân Lập",
            level: "Phường"
          },
          {
            id: "05467",
            name: "Phường Cam Giá",
            level: "Phường"
          },
          {
            id: "05470",
            name: "Phường Phú Xá",
            level: "Phường"
          },
          {
            id: "05473",
            name: "Phường Hương Sơn",
            level: "Phường"
          },
          {
            id: "05476",
            name: "Phường Trung Thành",
            level: "Phường"
          },
          {
            id: "05479",
            name: "Phường Tân Thành",
            level: "Phường"
          },
          {
            id: "05482",
            name: "Phường Tân Long",
            level: "Phường"
          },
          {
            id: "05485",
            name: "Xã Phúc Hà",
            level: "Xã"
          },
          {
            id: "05488",
            name: "Xã Phúc Xuân",
            level: "Xã"
          },
          {
            id: "05491",
            name: "Xã Quyết Thắng",
            level: "Xã"
          },
          {
            id: "05494",
            name: "Xã Phúc Trìu",
            level: "Xã"
          },
          {
            id: "05497",
            name: "Xã Thịnh Đức",
            level: "Xã"
          },
          {
            id: "05500",
            name: "Phường Tích Lương",
            level: "Phường"
          },
          {
            id: "05503",
            name: "Xã Tân Cương",
            level: "Xã"
          },
          {
            id: "05653",
            name: "Xã Sơn Cẩm",
            level: "Xã"
          },
          {
            id: "05659",
            name: "Phường Chùa Hang",
            level: "Phường"
          },
          {
            id: "05695",
            name: "Xã Cao Ngạn",
            level: "Xã"
          },
          {
            id: "05701",
            name: "Xã Linh Sơn",
            level: "Xã"
          },
          {
            id: "05710",
            name: "Phường Đồng Bẩm",
            level: "Phường"
          },
          {
            id: "05713",
            name: "Xã Huống Thượng",
            level: "Xã"
          },
          {
            id: "05914",
            name: "Xã Đồng Liên",
            level: "Xã"
          }
        ]
      },
      {
        id: "165",
        name: "Thành phố Sông Công",
        wards: [
          {
            id: "05506",
            name: "Phường Lương Sơn",
            level: "Phường"
          },
          {
            id: "05509",
            name: "Phường Châu Sơn",
            level: "Phường"
          },
          {
            id: "05512",
            name: "Phường Mỏ Chè",
            level: "Phường"
          },
          {
            id: "05515",
            name: "Phường Cải Đan",
            level: "Phường"
          },
          {
            id: "05518",
            name: "Phường Thắng Lợi",
            level: "Phường"
          },
          {
            id: "05521",
            name: "Phường Phố Cò",
            level: "Phường"
          },
          {
            id: "05527",
            name: "Xã Tân Quang",
            level: "Xã"
          },
          {
            id: "05528",
            name: "Phường Bách Quang",
            level: "Phường"
          },
          {
            id: "05530",
            name: "Xã Bình Sơn",
            level: "Xã"
          },
          {
            id: "05533",
            name: "Xã Bá Xuyên",
            level: "Xã"
          }
        ]
      },
      {
        id: "167",
        name: "Huyện Định Hóa",
        wards: [
          {
            id: "05536",
            name: "Thị trấn Chợ Chu",
            level: "Thị trấn"
          },
          {
            id: "05539",
            name: "Xã Linh Thông",
            level: "Xã"
          },
          {
            id: "05542",
            name: "Xã Lam Vỹ",
            level: "Xã"
          },
          {
            id: "05545",
            name: "Xã Quy Kỳ",
            level: "Xã"
          },
          {
            id: "05548",
            name: "Xã Tân Thịnh",
            level: "Xã"
          },
          {
            id: "05551",
            name: "Xã Kim Phượng",
            level: "Xã"
          },
          {
            id: "05554",
            name: "Xã Bảo Linh",
            level: "Xã"
          },
          {
            id: "05560",
            name: "Xã Phúc Chu",
            level: "Xã"
          },
          {
            id: "05563",
            name: "Xã Tân Dương",
            level: "Xã"
          },
          {
            id: "05566",
            name: "Xã Phượng Tiến",
            level: "Xã"
          },
          {
            id: "05569",
            name: "Xã Bảo Cường",
            level: "Xã"
          },
          {
            id: "05572",
            name: "Xã Đồng Thịnh",
            level: "Xã"
          },
          {
            id: "05575",
            name: "Xã Định Biên",
            level: "Xã"
          },
          {
            id: "05578",
            name: "Xã Thanh Định",
            level: "Xã"
          },
          {
            id: "05581",
            name: "Xã Trung Hội",
            level: "Xã"
          },
          {
            id: "05584",
            name: "Xã Trung Lương",
            level: "Xã"
          },
          {
            id: "05587",
            name: "Xã Bình Yên",
            level: "Xã"
          },
          {
            id: "05590",
            name: "Xã Điềm Mặc",
            level: "Xã"
          },
          {
            id: "05593",
            name: "Xã Phú Tiến",
            level: "Xã"
          },
          {
            id: "05596",
            name: "Xã Bộc Nhiêu",
            level: "Xã"
          },
          {
            id: "05599",
            name: "Xã Sơn Phú",
            level: "Xã"
          },
          {
            id: "05602",
            name: "Xã Phú Đình",
            level: "Xã"
          },
          {
            id: "05605",
            name: "Xã Bình Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "168",
        name: "Huyện Phú Lương",
        wards: [
          {
            id: "05608",
            name: "Thị trấn Giang Tiên",
            level: "Thị trấn"
          },
          {
            id: "05611",
            name: "Thị trấn Đu",
            level: "Thị trấn"
          },
          {
            id: "05614",
            name: "Xã Yên Ninh",
            level: "Xã"
          },
          {
            id: "05617",
            name: "Xã Yên Trạch",
            level: "Xã"
          },
          {
            id: "05620",
            name: "Xã Yên Đổ",
            level: "Xã"
          },
          {
            id: "05623",
            name: "Xã Yên Lạc",
            level: "Xã"
          },
          {
            id: "05626",
            name: "Xã Ôn Lương",
            level: "Xã"
          },
          {
            id: "05629",
            name: "Xã Động Đạt",
            level: "Xã"
          },
          {
            id: "05632",
            name: "Xã Phủ Lý",
            level: "Xã"
          },
          {
            id: "05635",
            name: "Xã Phú Đô",
            level: "Xã"
          },
          {
            id: "05638",
            name: "Xã Hợp Thành",
            level: "Xã"
          },
          {
            id: "05641",
            name: "Xã Tức Tranh",
            level: "Xã"
          },
          {
            id: "05644",
            name: "Xã Phấn Mễ",
            level: "Xã"
          },
          {
            id: "05647",
            name: "Xã Vô Tranh",
            level: "Xã"
          },
          {
            id: "05650",
            name: "Xã Cổ Lũng",
            level: "Xã"
          }
        ]
      },
      {
        id: "169",
        name: "Huyện Đồng Hỷ",
        wards: [
          {
            id: "05656",
            name: "Thị trấn Sông Cầu",
            level: "Thị trấn"
          },
          {
            id: "05662",
            name: "Thị trấn Trại Cau",
            level: "Thị trấn"
          },
          {
            id: "05665",
            name: "Xã Văn Lăng",
            level: "Xã"
          },
          {
            id: "05668",
            name: "Xã Tân Long",
            level: "Xã"
          },
          {
            id: "05671",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "05674",
            name: "Xã Quang Sơn",
            level: "Xã"
          },
          {
            id: "05677",
            name: "Xã Minh Lập",
            level: "Xã"
          },
          {
            id: "05680",
            name: "Xã Văn Hán",
            level: "Xã"
          },
          {
            id: "05683",
            name: "Xã Hóa Trung",
            level: "Xã"
          },
          {
            id: "05686",
            name: "Xã Khe Mo",
            level: "Xã"
          },
          {
            id: "05689",
            name: "Xã Cây Thị",
            level: "Xã"
          },
          {
            id: "05692",
            name: "Xã Hóa Thượng",
            level: "Xã"
          },
          {
            id: "05698",
            name: "Xã Hợp Tiến",
            level: "Xã"
          },
          {
            id: "05704",
            name: "Xã Tân Lợi",
            level: "Xã"
          },
          {
            id: "05707",
            name: "Xã Nam Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "170",
        name: "Huyện Võ Nhai",
        wards: [
          {
            id: "05716",
            name: "Thị trấn Đình Cả",
            level: "Thị trấn"
          },
          {
            id: "05719",
            name: "Xã Sảng Mộc",
            level: "Xã"
          },
          {
            id: "05722",
            name: "Xã Nghinh Tường",
            level: "Xã"
          },
          {
            id: "05725",
            name: "Xã Thần Xa",
            level: "Xã"
          },
          {
            id: "05728",
            name: "Xã Vũ Chấn",
            level: "Xã"
          },
          {
            id: "05731",
            name: "Xã Thượng Nung",
            level: "Xã"
          },
          {
            id: "05734",
            name: "Xã Phú Thượng",
            level: "Xã"
          },
          {
            id: "05737",
            name: "Xã Cúc Đường",
            level: "Xã"
          },
          {
            id: "05740",
            name: "Xã La Hiên",
            level: "Xã"
          },
          {
            id: "05743",
            name: "Xã Lâu Thượng",
            level: "Xã"
          },
          {
            id: "05746",
            name: "Xã Tràng Xá",
            level: "Xã"
          },
          {
            id: "05749",
            name: "Xã Phương Giao",
            level: "Xã"
          },
          {
            id: "05752",
            name: "Xã Liên Minh",
            level: "Xã"
          },
          {
            id: "05755",
            name: "Xã Dân Tiến",
            level: "Xã"
          },
          {
            id: "05758",
            name: "Xã Bình Long",
            level: "Xã"
          }
        ]
      },
      {
        id: "171",
        name: "Huyện Đại Từ",
        wards: [
          {
            id: "05761",
            name: "Thị trấn Hùng Sơn",
            level: "Thị trấn"
          },
          {
            id: "05764",
            name: "Thị trấn Quân Chu",
            level: "Thị trấn"
          },
          {
            id: "05767",
            name: "Xã Phúc Lương",
            level: "Xã"
          },
          {
            id: "05770",
            name: "Xã Minh Tiến",
            level: "Xã"
          },
          {
            id: "05773",
            name: "Xã Yên Lãng",
            level: "Xã"
          },
          {
            id: "05776",
            name: "Xã Đức Lương",
            level: "Xã"
          },
          {
            id: "05779",
            name: "Xã Phú Cường",
            level: "Xã"
          },
          {
            id: "05782",
            name: "Xã Na Mao",
            level: "Xã"
          },
          {
            id: "05785",
            name: "Xã Phú Lạc",
            level: "Xã"
          },
          {
            id: "05788",
            name: "Xã Tân Linh",
            level: "Xã"
          },
          {
            id: "05791",
            name: "Xã Phú Thịnh",
            level: "Xã"
          },
          {
            id: "05794",
            name: "Xã Phục Linh",
            level: "Xã"
          },
          {
            id: "05797",
            name: "Xã Phú Xuyên",
            level: "Xã"
          },
          {
            id: "05800",
            name: "Xã Bản Ngoại",
            level: "Xã"
          },
          {
            id: "05803",
            name: "Xã Tiên Hội",
            level: "Xã"
          },
          {
            id: "05809",
            name: "Xã Cù Vân",
            level: "Xã"
          },
          {
            id: "05812",
            name: "Xã Hà Thượng",
            level: "Xã"
          },
          {
            id: "05815",
            name: "Xã La Bằng",
            level: "Xã"
          },
          {
            id: "05818",
            name: "Xã Hoàng Nông",
            level: "Xã"
          },
          {
            id: "05821",
            name: "Xã Khôi Kỳ",
            level: "Xã"
          },
          {
            id: "05824",
            name: "Xã An Khánh",
            level: "Xã"
          },
          {
            id: "05827",
            name: "Xã Tân Thái",
            level: "Xã"
          },
          {
            id: "05830",
            name: "Xã Bình Thuận",
            level: "Xã"
          },
          {
            id: "05833",
            name: "Xã Lục Ba",
            level: "Xã"
          },
          {
            id: "05836",
            name: "Xã Mỹ Yên",
            level: "Xã"
          },
          {
            id: "05839",
            name: "Xã Vạn Thọ",
            level: "Xã"
          },
          {
            id: "05842",
            name: "Xã Văn Yên",
            level: "Xã"
          },
          {
            id: "05845",
            name: "Xã Ký Phú",
            level: "Xã"
          },
          {
            id: "05848",
            name: "Xã Cát Nê",
            level: "Xã"
          },
          {
            id: "05851",
            name: "Xã Quân Chu",
            level: "Xã"
          }
        ]
      },
      {
        id: "172",
        name: "Thị xã Phổ Yên",
        wards: [
          {
            id: "05854",
            name: "Phường Bãi Bông",
            level: "Phường"
          },
          {
            id: "05857",
            name: "Phường Bắc Sơn",
            level: "Phường"
          },
          {
            id: "05860",
            name: "Phường Ba Hàng",
            level: "Phường"
          },
          {
            id: "05863",
            name: "Xã Phúc Tân",
            level: "Xã"
          },
          {
            id: "05866",
            name: "Xã Phúc Thuận",
            level: "Xã"
          },
          {
            id: "05869",
            name: "Xã Hồng Tiến",
            level: "Xã"
          },
          {
            id: "05872",
            name: "Xã Minh Đức",
            level: "Xã"
          },
          {
            id: "05875",
            name: "Xã Đắc Sơn",
            level: "Xã"
          },
          {
            id: "05878",
            name: "Phường Đồng Tiến",
            level: "Phường"
          },
          {
            id: "05881",
            name: "Xã Thành Công",
            level: "Xã"
          },
          {
            id: "05884",
            name: "Xã Tiên Phong",
            level: "Xã"
          },
          {
            id: "05887",
            name: "Xã Vạn Phái",
            level: "Xã"
          },
          {
            id: "05890",
            name: "Xã Nam Tiến",
            level: "Xã"
          },
          {
            id: "05893",
            name: "Xã Tân Hương",
            level: "Xã"
          },
          {
            id: "05896",
            name: "Xã Đông Cao",
            level: "Xã"
          },
          {
            id: "05899",
            name: "Xã Trung Thành",
            level: "Xã"
          },
          {
            id: "05902",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "05905",
            name: "Xã Thuận Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "173",
        name: "Huyện Phú Bình",
        wards: [
          {
            id: "05908",
            name: "Thị trấn Hương Sơn",
            level: "Thị trấn"
          },
          {
            id: "05911",
            name: "Xã Bàn Đạt",
            level: "Xã"
          },
          {
            id: "05917",
            name: "Xã Tân Khánh",
            level: "Xã"
          },
          {
            id: "05920",
            name: "Xã Tân Kim",
            level: "Xã"
          },
          {
            id: "05923",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "05926",
            name: "Xã Đào Xá",
            level: "Xã"
          },
          {
            id: "05929",
            name: "Xã Bảo Lý",
            level: "Xã"
          },
          {
            id: "05932",
            name: "Xã Thượng Đình",
            level: "Xã"
          },
          {
            id: "05935",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "05938",
            name: "Xã Nhã Lộng",
            level: "Xã"
          },
          {
            id: "05941",
            name: "Xã Điềm Thụy",
            level: "Xã"
          },
          {
            id: "05944",
            name: "Xã Xuân Phương",
            level: "Xã"
          },
          {
            id: "05947",
            name: "Xã Tân Đức",
            level: "Xã"
          },
          {
            id: "05950",
            name: "Xã Úc Kỳ",
            level: "Xã"
          },
          {
            id: "05953",
            name: "Xã Lương Phú",
            level: "Xã"
          },
          {
            id: "05956",
            name: "Xã Nga My",
            level: "Xã"
          },
          {
            id: "05959",
            name: "Xã Kha Sơn",
            level: "Xã"
          },
          {
            id: "05962",
            name: "Xã Thanh Ninh",
            level: "Xã"
          },
          {
            id: "05965",
            name: "Xã Dương Thành",
            level: "Xã"
          },
          {
            id: "05968",
            name: "Xã Hà Châu",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "20",
    name: "Tỉnh Lạng Sơn",
    districts: [
      {
        id: "178",
        name: "Thành phố Lạng Sơn",
        wards: [
          {
            id: "05971",
            name: "Phường Hoàng Văn Thụ",
            level: "Phường"
          },
          {
            id: "05974",
            name: "Phường Tam Thanh",
            level: "Phường"
          },
          {
            id: "05977",
            name: "Phường Vĩnh Trại",
            level: "Phường"
          },
          {
            id: "05980",
            name: "Phường Đông Kinh",
            level: "Phường"
          },
          {
            id: "05983",
            name: "Phường Chi Lăng",
            level: "Phường"
          },
          {
            id: "05986",
            name: "Xã Hoàng Đồng",
            level: "Xã"
          },
          {
            id: "05989",
            name: "Xã Quảng Lạc",
            level: "Xã"
          },
          {
            id: "05992",
            name: "Xã Mai Pha",
            level: "Xã"
          }
        ]
      },
      {
        id: "180",
        name: "Huyện Tràng Định",
        wards: [
          {
            id: "05995",
            name: "Thị trấn Thất Khê",
            level: "Thị trấn"
          },
          {
            id: "05998",
            name: "Xã Khánh Long",
            level: "Xã"
          },
          {
            id: "06001",
            name: "Xã Đoàn Kết",
            level: "Xã"
          },
          {
            id: "06004",
            name: "Xã Quốc Khánh",
            level: "Xã"
          },
          {
            id: "06007",
            name: "Xã Vĩnh Tiến",
            level: "Xã"
          },
          {
            id: "06010",
            name: "Xã Cao Minh",
            level: "Xã"
          },
          {
            id: "06013",
            name: "Xã Chí Minh",
            level: "Xã"
          },
          {
            id: "06016",
            name: "Xã Tri Phương",
            level: "Xã"
          },
          {
            id: "06019",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "06022",
            name: "Xã Tân Yên",
            level: "Xã"
          },
          {
            id: "06025",
            name: "Xã Đội Cấn",
            level: "Xã"
          },
          {
            id: "06028",
            name: "Xã Tân Minh",
            level: "Xã"
          },
          {
            id: "06031",
            name: "Xã Kim Đồng",
            level: "Xã"
          },
          {
            id: "06034",
            name: "Xã Chi Lăng",
            level: "Xã"
          },
          {
            id: "06037",
            name: "Xã Trung Thành",
            level: "Xã"
          },
          {
            id: "06040",
            name: "Xã Đại Đồng",
            level: "Xã"
          },
          {
            id: "06043",
            name: "Xã Đào Viên",
            level: "Xã"
          },
          {
            id: "06046",
            name: "Xã Đề Thám",
            level: "Xã"
          },
          {
            id: "06049",
            name: "Xã Kháng Chiến",
            level: "Xã"
          },
          {
            id: "06055",
            name: "Xã Hùng Sơn",
            level: "Xã"
          },
          {
            id: "06058",
            name: "Xã Quốc Việt",
            level: "Xã"
          },
          {
            id: "06061",
            name: "Xã Hùng Việt",
            level: "Xã"
          }
        ]
      },
      {
        id: "181",
        name: "Huyện Bình Gia",
        wards: [
          {
            id: "06067",
            name: "Xã Hưng Đạo",
            level: "Xã"
          },
          {
            id: "06070",
            name: "Xã Vĩnh Yên",
            level: "Xã"
          },
          {
            id: "06073",
            name: "Xã Hoa Thám",
            level: "Xã"
          },
          {
            id: "06076",
            name: "Xã Quý Hòa",
            level: "Xã"
          },
          {
            id: "06079",
            name: "Xã Hồng Phong",
            level: "Xã"
          },
          {
            id: "06082",
            name: "Xã Yên Lỗ",
            level: "Xã"
          },
          {
            id: "06085",
            name: "Xã Thiện Hòa",
            level: "Xã"
          },
          {
            id: "06088",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "06091",
            name: "Xã Thiện Thuật",
            level: "Xã"
          },
          {
            id: "06094",
            name: "Xã Minh Khai",
            level: "Xã"
          },
          {
            id: "06097",
            name: "Xã Thiện Long",
            level: "Xã"
          },
          {
            id: "06100",
            name: "Xã Hoàng Văn Thụ",
            level: "Xã"
          },
          {
            id: "06103",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "06106",
            name: "Xã Mông Ân",
            level: "Xã"
          },
          {
            id: "06109",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "06112",
            name: "Thị trấn Bình Gia",
            level: "Thị trấn"
          },
          {
            id: "06115",
            name: "Xã Hồng Thái",
            level: "Xã"
          },
          {
            id: "06118",
            name: "Xã Bình La",
            level: "Xã"
          },
          {
            id: "06121",
            name: "Xã Tân Văn",
            level: "Xã"
          }
        ]
      },
      {
        id: "182",
        name: "Huyện Văn Lãng",
        wards: [
          {
            id: "06124",
            name: "Thị trấn Na Sầm",
            level: "Thị trấn"
          },
          {
            id: "06127",
            name: "Xã Trùng Khánh",
            level: "Xã"
          },
          {
            id: "06133",
            name: "Xã Bắc La",
            level: "Xã"
          },
          {
            id: "06136",
            name: "Xã Thụy Hùng",
            level: "Xã"
          },
          {
            id: "06139",
            name: "Xã Bắc Hùng",
            level: "Xã"
          },
          {
            id: "06142",
            name: "Xã Tân Tác",
            level: "Xã"
          },
          {
            id: "06148",
            name: "Xã Thanh Long",
            level: "Xã"
          },
          {
            id: "06151",
            name: "Xã Hội Hoan",
            level: "Xã"
          },
          {
            id: "06154",
            name: "Xã Bắc Việt",
            level: "Xã"
          },
          {
            id: "06157",
            name: "Xã Hoàng Việt",
            level: "Xã"
          },
          {
            id: "06160",
            name: "Xã Gia Miễn",
            level: "Xã"
          },
          {
            id: "06163",
            name: "Xã Thành Hòa",
            level: "Xã"
          },
          {
            id: "06166",
            name: "Xã Tân Thanh",
            level: "Xã"
          },
          {
            id: "06172",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "06175",
            name: "Xã Hồng Thái",
            level: "Xã"
          },
          {
            id: "06178",
            name: "Xã  Hoàng Văn Thụ",
            level: "Xã"
          },
          {
            id: "06181",
            name: "Xã Nhạc Kỳ",
            level: "Xã"
          }
        ]
      },
      {
        id: "183",
        name: "Huyện Cao Lộc",
        wards: [
          {
            id: "06184",
            name: "Thị trấn Đồng Đăng",
            level: "Thị trấn"
          },
          {
            id: "06187",
            name: "Thị trấn Cao Lộc",
            level: "Thị trấn"
          },
          {
            id: "06190",
            name: "Xã Bảo Lâm",
            level: "Xã"
          },
          {
            id: "06193",
            name: "Xã Thanh Lòa",
            level: "Xã"
          },
          {
            id: "06196",
            name: "Xã Cao Lâu",
            level: "Xã"
          },
          {
            id: "06199",
            name: "Xã Thạch Đạn",
            level: "Xã"
          },
          {
            id: "06202",
            name: "Xã Xuất Lễ",
            level: "Xã"
          },
          {
            id: "06205",
            name: "Xã Hồng Phong",
            level: "Xã"
          },
          {
            id: "06208",
            name: "Xã Thụy Hùng",
            level: "Xã"
          },
          {
            id: "06211",
            name: "Xã Lộc Yên",
            level: "Xã"
          },
          {
            id: "06214",
            name: "Xã Phú Xá",
            level: "Xã"
          },
          {
            id: "06217",
            name: "Xã Bình Trung",
            level: "Xã"
          },
          {
            id: "06220",
            name: "Xã Hải Yến",
            level: "Xã"
          },
          {
            id: "06223",
            name: "Xã Hòa Cư",
            level: "Xã"
          },
          {
            id: "06226",
            name: "Xã Hợp Thành",
            level: "Xã"
          },
          {
            id: "06232",
            name: "Xã Công Sơn",
            level: "Xã"
          },
          {
            id: "06235",
            name: "Xã Gia Cát",
            level: "Xã"
          },
          {
            id: "06238",
            name: "Xã Mẫu Sơn",
            level: "Xã"
          },
          {
            id: "06241",
            name: "Xã Xuân Long",
            level: "Xã"
          },
          {
            id: "06244",
            name: "Xã Tân Liên",
            level: "Xã"
          },
          {
            id: "06247",
            name: "Xã Yên Trạch",
            level: "Xã"
          },
          {
            id: "06250",
            name: "Xã Tân Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "184",
        name: "Huyện Văn Quan",
        wards: [
          {
            id: "06253",
            name: "Thị trấn Văn Quan",
            level: "Thị trấn"
          },
          {
            id: "06256",
            name: "Xã Trấn Ninh",
            level: "Xã"
          },
          {
            id: "06268",
            name: "Xã Liên Hội",
            level: "Xã"
          },
          {
            id: "06274",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "06277",
            name: "Xã Tú Xuyên",
            level: "Xã"
          },
          {
            id: "06280",
            name: "Xã Điềm He",
            level: "Xã"
          },
          {
            id: "06283",
            name: "Xã An Sơn",
            level: "Xã"
          },
          {
            id: "06286",
            name: "Xã Khánh Khê",
            level: "Xã"
          },
          {
            id: "06292",
            name: "Xã Lương Năng",
            level: "Xã"
          },
          {
            id: "06295",
            name: "Xã Đồng Giáp",
            level: "Xã"
          },
          {
            id: "06298",
            name: "Xã Bình Phúc",
            level: "Xã"
          },
          {
            id: "06301",
            name: "Xã Tràng Các",
            level: "Xã"
          },
          {
            id: "06307",
            name: "Xã Tân Đoàn",
            level: "Xã"
          },
          {
            id: "06313",
            name: "Xã Tri Lễ",
            level: "Xã"
          },
          {
            id: "06316",
            name: "Xã Tràng Phái",
            level: "Xã"
          },
          {
            id: "06319",
            name: "Xã Yên Phúc",
            level: "Xã"
          },
          {
            id: "06322",
            name: "Xã Hữu Lễ",
            level: "Xã"
          }
        ]
      },
      {
        id: "185",
        name: "Huyện Bắc Sơn",
        wards: [
          {
            id: "06325",
            name: "Thị trấn Bắc Sơn",
            level: "Thị trấn"
          },
          {
            id: "06328",
            name: "Xã Long Đống",
            level: "Xã"
          },
          {
            id: "06331",
            name: "Xã Vạn Thủy",
            level: "Xã"
          },
          {
            id: "06337",
            name: "Xã Đồng ý",
            level: "Xã"
          },
          {
            id: "06340",
            name: "Xã Tân Tri",
            level: "Xã"
          },
          {
            id: "06343",
            name: "Xã Bắc Quỳnh",
            level: "Xã"
          },
          {
            id: "06349",
            name: "Xã Hưng Vũ",
            level: "Xã"
          },
          {
            id: "06352",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "06355",
            name: "Xã Vũ Sơn",
            level: "Xã"
          },
          {
            id: "06358",
            name: "Xã Chiêu Vũ",
            level: "Xã"
          },
          {
            id: "06361",
            name: "Xã Tân Hương",
            level: "Xã"
          },
          {
            id: "06364",
            name: "Xã Chiến Thắng",
            level: "Xã"
          },
          {
            id: "06367",
            name: "Xã Vũ Lăng",
            level: "Xã"
          },
          {
            id: "06370",
            name: "Xã Trấn Yên",
            level: "Xã"
          },
          {
            id: "06373",
            name: "Xã Vũ Lễ",
            level: "Xã"
          },
          {
            id: "06376",
            name: "Xã Nhất Hòa",
            level: "Xã"
          },
          {
            id: "06379",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "06382",
            name: "Xã Nhất Tiến",
            level: "Xã"
          }
        ]
      },
      {
        id: "186",
        name: "Huyện Hữu Lũng",
        wards: [
          {
            id: "06385",
            name: "Thị trấn Hữu Lũng",
            level: "Thị trấn"
          },
          {
            id: "06388",
            name: "Xã Hữu Liên",
            level: "Xã"
          },
          {
            id: "06391",
            name: "Xã Yên Bình",
            level: "Xã"
          },
          {
            id: "06394",
            name: "Xã Quyết Thắng",
            level: "Xã"
          },
          {
            id: "06397",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "06400",
            name: "Xã Yên Thịnh",
            level: "Xã"
          },
          {
            id: "06403",
            name: "Xã Yên Sơn",
            level: "Xã"
          },
          {
            id: "06406",
            name: "Xã Thiện Tân",
            level: "Xã"
          },
          {
            id: "06412",
            name: "Xã Yên Vượng",
            level: "Xã"
          },
          {
            id: "06415",
            name: "Xã Minh Tiến",
            level: "Xã"
          },
          {
            id: "06418",
            name: "Xã Nhật Tiến",
            level: "Xã"
          },
          {
            id: "06421",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "06424",
            name: "Xã Đồng Tân",
            level: "Xã"
          },
          {
            id: "06427",
            name: "Xã Cai Kinh",
            level: "Xã"
          },
          {
            id: "06430",
            name: "Xã Hòa Lạc",
            level: "Xã"
          },
          {
            id: "06433",
            name: "Xã Vân Nham",
            level: "Xã"
          },
          {
            id: "06436",
            name: "Xã Đồng Tiến",
            level: "Xã"
          },
          {
            id: "06442",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "06445",
            name: "Xã Hòa Sơn",
            level: "Xã"
          },
          {
            id: "06448",
            name: "Xã Minh Sơn",
            level: "Xã"
          },
          {
            id: "06451",
            name: "Xã Hồ Sơn",
            level: "Xã"
          },
          {
            id: "06454",
            name: "Xã Sơn Hà",
            level: "Xã"
          },
          {
            id: "06457",
            name: "Xã Minh Hòa",
            level: "Xã"
          },
          {
            id: "06460",
            name: "Xã Hòa Thắng",
            level: "Xã"
          }
        ]
      },
      {
        id: "187",
        name: "Huyện Chi Lăng",
        wards: [
          {
            id: "06463",
            name: "Thị trấn Đồng Mỏ",
            level: "Thị trấn"
          },
          {
            id: "06466",
            name: "Thị trấn Chi Lăng",
            level: "Thị trấn"
          },
          {
            id: "06469",
            name: "Xã Vân An",
            level: "Xã"
          },
          {
            id: "06472",
            name: "Xã Vân Thủy",
            level: "Xã"
          },
          {
            id: "06475",
            name: "Xã Gia Lộc",
            level: "Xã"
          },
          {
            id: "06478",
            name: "Xã Bắc Thủy",
            level: "Xã"
          },
          {
            id: "06481",
            name: "Xã Chiến Thắng",
            level: "Xã"
          },
          {
            id: "06484",
            name: "Xã Mai Sao",
            level: "Xã"
          },
          {
            id: "06487",
            name: "Xã Bằng Hữu",
            level: "Xã"
          },
          {
            id: "06490",
            name: "Xã Thượng Cường",
            level: "Xã"
          },
          {
            id: "06493",
            name: "Xã Bằng Mạc",
            level: "Xã"
          },
          {
            id: "06496",
            name: "Xã Nhân Lý",
            level: "Xã"
          },
          {
            id: "06499",
            name: "Xã Lâm Sơn",
            level: "Xã"
          },
          {
            id: "06502",
            name: "Xã Liên Sơn",
            level: "Xã"
          },
          {
            id: "06505",
            name: "Xã Vạn Linh",
            level: "Xã"
          },
          {
            id: "06508",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "06514",
            name: "Xã Hữu Kiên",
            level: "Xã"
          },
          {
            id: "06517",
            name: "Xã Quan Sơn",
            level: "Xã"
          },
          {
            id: "06520",
            name: "Xã Y Tịch",
            level: "Xã"
          },
          {
            id: "06523",
            name: "Xã Chi Lăng",
            level: "Xã"
          }
        ]
      },
      {
        id: "188",
        name: "Huyện Lộc Bình",
        wards: [
          {
            id: "06526",
            name: "Thị trấn Na Dương",
            level: "Thị trấn"
          },
          {
            id: "06529",
            name: "Thị trấn Lộc Bình",
            level: "Thị trấn"
          },
          {
            id: "06532",
            name: "Xã Mẫu Sơn",
            level: "Xã"
          },
          {
            id: "06541",
            name: "Xã Yên Khoái",
            level: "Xã"
          },
          {
            id: "06544",
            name: "Xã Khánh Xuân",
            level: "Xã"
          },
          {
            id: "06547",
            name: "Xã Tú Mịch",
            level: "Xã"
          },
          {
            id: "06550",
            name: "Xã Hữu Khánh",
            level: "Xã"
          },
          {
            id: "06553",
            name: "Xã Đồng Bục",
            level: "Xã"
          },
          {
            id: "06559",
            name: "Xã Tam Gia",
            level: "Xã"
          },
          {
            id: "06562",
            name: "Xã Tú Đoạn",
            level: "Xã"
          },
          {
            id: "06565",
            name: "Xã Khuất Xá",
            level: "Xã"
          },
          {
            id: "06574",
            name: "Xã Tĩnh Bắc",
            level: "Xã"
          },
          {
            id: "06577",
            name: "Xã Thống Nhất",
            level: "Xã"
          },
          {
            id: "06589",
            name: "Xã Sàn Viên",
            level: "Xã"
          },
          {
            id: "06592",
            name: "Xã Đông Quan",
            level: "Xã"
          },
          {
            id: "06595",
            name: "Xã Minh Hiệp",
            level: "Xã"
          },
          {
            id: "06598",
            name: "Xã Hữu Lân",
            level: "Xã"
          },
          {
            id: "06601",
            name: "Xã Lợi Bác",
            level: "Xã"
          },
          {
            id: "06604",
            name: "Xã Nam Quan",
            level: "Xã"
          },
          {
            id: "06607",
            name: "Xã Xuân Dương",
            level: "Xã"
          },
          {
            id: "06610",
            name: "Xã Ái Quốc",
            level: "Xã"
          }
        ]
      },
      {
        id: "189",
        name: "Huyện Đình Lập",
        wards: [
          {
            id: "06613",
            name: "Thị trấn Đình Lập",
            level: "Thị trấn"
          },
          {
            id: "06616",
            name: "Thị trấn NT Thái Bình",
            level: "Thị trấn"
          },
          {
            id: "06619",
            name: "Xã Bắc Xa",
            level: "Xã"
          },
          {
            id: "06622",
            name: "Xã Bính Xá",
            level: "Xã"
          },
          {
            id: "06625",
            name: "Xã Kiên Mộc",
            level: "Xã"
          },
          {
            id: "06628",
            name: "Xã Đình Lập",
            level: "Xã"
          },
          {
            id: "06631",
            name: "Xã Thái Bình",
            level: "Xã"
          },
          {
            id: "06634",
            name: "Xã Cường Lợi",
            level: "Xã"
          },
          {
            id: "06637",
            name: "Xã Châu Sơn",
            level: "Xã"
          },
          {
            id: "06640",
            name: "Xã Lâm Ca",
            level: "Xã"
          },
          {
            id: "06643",
            name: "Xã Đồng Thắng",
            level: "Xã"
          },
          {
            id: "06646",
            name: "Xã Bắc Lãng",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "22",
    name: "Tỉnh Quảng Ninh",
    districts: [
      {
        id: "193",
        name: "Thành phố Hạ Long",
        wards: [
          {
            id: "06649",
            name: "Phường Hà Khánh",
            level: "Phường"
          },
          {
            id: "06652",
            name: "Phường Hà Phong",
            level: "Phường"
          },
          {
            id: "06655",
            name: "Phường Hà Khẩu",
            level: "Phường"
          },
          {
            id: "06658",
            name: "Phường Cao Xanh",
            level: "Phường"
          },
          {
            id: "06661",
            name: "Phường Giếng Đáy",
            level: "Phường"
          },
          {
            id: "06664",
            name: "Phường Hà Tu",
            level: "Phường"
          },
          {
            id: "06667",
            name: "Phường Hà Trung",
            level: "Phường"
          },
          {
            id: "06670",
            name: "Phường Hà Lầm",
            level: "Phường"
          },
          {
            id: "06673",
            name: "Phường Bãi Cháy",
            level: "Phường"
          },
          {
            id: "06676",
            name: "Phường Cao Thắng",
            level: "Phường"
          },
          {
            id: "06679",
            name: "Phường Hùng Thắng",
            level: "Phường"
          },
          {
            id: "06682",
            name: "Phường Yết Kiêu",
            level: "Phường"
          },
          {
            id: "06685",
            name: "Phường Trần Hưng Đạo",
            level: "Phường"
          },
          {
            id: "06688",
            name: "Phường Hồng Hải",
            level: "Phường"
          },
          {
            id: "06691",
            name: "Phường Hồng Gai",
            level: "Phường"
          },
          {
            id: "06694",
            name: "Phường Bạch Đằng",
            level: "Phường"
          },
          {
            id: "06697",
            name: "Phường Hồng Hà",
            level: "Phường"
          },
          {
            id: "06700",
            name: "Phường Tuần Châu",
            level: "Phường"
          },
          {
            id: "06703",
            name: "Phường Việt Hưng",
            level: "Phường"
          },
          {
            id: "06706",
            name: "Phường Đại Yên",
            level: "Phường"
          },
          {
            id: "07030",
            name: "Phường Hoành Bồ",
            level: "Phường"
          },
          {
            id: "07033",
            name: "Xã Kỳ Thượng",
            level: "Xã"
          },
          {
            id: "07036",
            name: "Xã Đồng Sơn",
            level: "Xã"
          },
          {
            id: "07039",
            name: "Xã Tân Dân",
            level: "Xã"
          },
          {
            id: "07042",
            name: "Xã Đồng Lâm",
            level: "Xã"
          },
          {
            id: "07045",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "07048",
            name: "Xã Vũ Oai",
            level: "Xã"
          },
          {
            id: "07051",
            name: "Xã Dân Chủ",
            level: "Xã"
          },
          {
            id: "07054",
            name: "Xã Quảng La",
            level: "Xã"
          },
          {
            id: "07057",
            name: "Xã Bằng Cả",
            level: "Xã"
          },
          {
            id: "07060",
            name: "Xã Thống Nhất",
            level: "Xã"
          },
          {
            id: "07063",
            name: "Xã Sơn Dương",
            level: "Xã"
          },
          {
            id: "07066",
            name: "Xã Lê Lợi",
            level: "Xã"
          }
        ]
      },
      {
        id: "194",
        name: "Thành phố Móng Cái",
        wards: [
          {
            id: "06709",
            name: "Phường Ka Long",
            level: "Phường"
          },
          {
            id: "06712",
            name: "Phường Trần Phú",
            level: "Phường"
          },
          {
            id: "06715",
            name: "Phường Ninh Dương",
            level: "Phường"
          },
          {
            id: "06718",
            name: "Phường Hoà Lạc",
            level: "Phường"
          },
          {
            id: "06721",
            name: "Phường Trà Cổ",
            level: "Phường"
          },
          {
            id: "06724",
            name: "Xã Hải Sơn",
            level: "Xã"
          },
          {
            id: "06727",
            name: "Xã Bắc Sơn",
            level: "Xã"
          },
          {
            id: "06730",
            name: "Xã Hải Đông",
            level: "Xã"
          },
          {
            id: "06733",
            name: "Xã Hải Tiến",
            level: "Xã"
          },
          {
            id: "06736",
            name: "Phường Hải Yên",
            level: "Phường"
          },
          {
            id: "06739",
            name: "Xã Quảng Nghĩa",
            level: "Xã"
          },
          {
            id: "06742",
            name: "Phường Hải Hoà",
            level: "Phường"
          },
          {
            id: "06745",
            name: "Xã Hải Xuân",
            level: "Xã"
          },
          {
            id: "06748",
            name: "Xã Vạn Ninh",
            level: "Xã"
          },
          {
            id: "06751",
            name: "Phường Bình Ngọc",
            level: "Phường"
          },
          {
            id: "06754",
            name: "Xã Vĩnh Trung",
            level: "Xã"
          },
          {
            id: "06757",
            name: "Xã Vĩnh Thực",
            level: "Xã"
          }
        ]
      },
      {
        id: "195",
        name: "Thành phố Cẩm Phả",
        wards: [
          {
            id: "06760",
            name: "Phường Mông Dương",
            level: "Phường"
          },
          {
            id: "06763",
            name: "Phường Cửa Ông",
            level: "Phường"
          },
          {
            id: "06766",
            name: "Phường Cẩm Sơn",
            level: "Phường"
          },
          {
            id: "06769",
            name: "Phường Cẩm Đông",
            level: "Phường"
          },
          {
            id: "06772",
            name: "Phường Cẩm Phú",
            level: "Phường"
          },
          {
            id: "06775",
            name: "Phường Cẩm Tây",
            level: "Phường"
          },
          {
            id: "06778",
            name: "Phường Quang Hanh",
            level: "Phường"
          },
          {
            id: "06781",
            name: "Phường Cẩm Thịnh",
            level: "Phường"
          },
          {
            id: "06784",
            name: "Phường Cẩm Thủy",
            level: "Phường"
          },
          {
            id: "06787",
            name: "Phường Cẩm Thạch",
            level: "Phường"
          },
          {
            id: "06790",
            name: "Phường Cẩm Thành",
            level: "Phường"
          },
          {
            id: "06793",
            name: "Phường Cẩm Trung",
            level: "Phường"
          },
          {
            id: "06796",
            name: "Phường Cẩm Bình",
            level: "Phường"
          },
          {
            id: "06799",
            name: "Xã Cộng Hòa",
            level: "Xã"
          },
          {
            id: "06802",
            name: "Xã Cẩm Hải",
            level: "Xã"
          },
          {
            id: "06805",
            name: "Xã Dương Huy",
            level: "Xã"
          }
        ]
      },
      {
        id: "196",
        name: "Thành phố Uông Bí",
        wards: [
          {
            id: "06808",
            name: "Phường Vàng Danh",
            level: "Phường"
          },
          {
            id: "06811",
            name: "Phường Thanh Sơn",
            level: "Phường"
          },
          {
            id: "06814",
            name: "Phường Bắc Sơn",
            level: "Phường"
          },
          {
            id: "06817",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "06820",
            name: "Phường Trưng Vương",
            level: "Phường"
          },
          {
            id: "06823",
            name: "Phường Nam Khê",
            level: "Phường"
          },
          {
            id: "06826",
            name: "Phường Yên Thanh",
            level: "Phường"
          },
          {
            id: "06829",
            name: "Xã Thượng Yên Công",
            level: "Xã"
          },
          {
            id: "06832",
            name: "Phường Phương Đông",
            level: "Phường"
          },
          {
            id: "06835",
            name: "Phường Phương Nam",
            level: "Phường"
          }
        ]
      },
      {
        id: "198",
        name: "Huyện Bình Liêu",
        wards: [
          {
            id: "06838",
            name: "Thị trấn Bình Liêu",
            level: "Thị trấn"
          },
          {
            id: "06841",
            name: "Xã Hoành Mô",
            level: "Xã"
          },
          {
            id: "06844",
            name: "Xã Đồng Tâm",
            level: "Xã"
          },
          {
            id: "06847",
            name: "Xã Đồng Văn",
            level: "Xã"
          },
          {
            id: "06853",
            name: "Xã Vô Ngại",
            level: "Xã"
          },
          {
            id: "06856",
            name: "Xã Lục Hồn",
            level: "Xã"
          },
          {
            id: "06859",
            name: "Xã Húc Động",
            level: "Xã"
          }
        ]
      },
      {
        id: "199",
        name: "Huyện Tiên Yên",
        wards: [
          {
            id: "06862",
            name: "Thị trấn Tiên Yên",
            level: "Thị trấn"
          },
          {
            id: "06865",
            name: "Xã Hà Lâu",
            level: "Xã"
          },
          {
            id: "06868",
            name: "Xã Đại Dực",
            level: "Xã"
          },
          {
            id: "06871",
            name: "Xã Phong Dụ",
            level: "Xã"
          },
          {
            id: "06874",
            name: "Xã Điền Xá",
            level: "Xã"
          },
          {
            id: "06877",
            name: "Xã Đông Ngũ",
            level: "Xã"
          },
          {
            id: "06880",
            name: "Xã Yên Than",
            level: "Xã"
          },
          {
            id: "06883",
            name: "Xã Đông Hải",
            level: "Xã"
          },
          {
            id: "06886",
            name: "Xã Hải Lạng",
            level: "Xã"
          },
          {
            id: "06889",
            name: "Xã Tiên Lãng",
            level: "Xã"
          },
          {
            id: "06892",
            name: "Xã Đồng Rui",
            level: "Xã"
          }
        ]
      },
      {
        id: "200",
        name: "Huyện Đầm Hà",
        wards: [
          {
            id: "06895",
            name: "Thị trấn Đầm Hà",
            level: "Thị trấn"
          },
          {
            id: "06898",
            name: "Xã Quảng Lâm",
            level: "Xã"
          },
          {
            id: "06901",
            name: "Xã Quảng An",
            level: "Xã"
          },
          {
            id: "06904",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "06910",
            name: "Xã Dực Yên",
            level: "Xã"
          },
          {
            id: "06913",
            name: "Xã Quảng Tân",
            level: "Xã"
          },
          {
            id: "06916",
            name: "Xã Đầm Hà",
            level: "Xã"
          },
          {
            id: "06917",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "06919",
            name: "Xã Đại Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "201",
        name: "Huyện Hải Hà",
        wards: [
          {
            id: "06922",
            name: "Thị trấn Quảng Hà",
            level: "Thị trấn"
          },
          {
            id: "06925",
            name: "Xã Quảng Đức",
            level: "Xã"
          },
          {
            id: "06928",
            name: "Xã Quảng Sơn",
            level: "Xã"
          },
          {
            id: "06931",
            name: "Xã Quảng Thành",
            level: "Xã"
          },
          {
            id: "06937",
            name: "Xã Quảng Thịnh",
            level: "Xã"
          },
          {
            id: "06940",
            name: "Xã Quảng Minh",
            level: "Xã"
          },
          {
            id: "06943",
            name: "Xã Quảng Chính",
            level: "Xã"
          },
          {
            id: "06946",
            name: "Xã Quảng Long",
            level: "Xã"
          },
          {
            id: "06949",
            name: "Xã Đường Hoa",
            level: "Xã"
          },
          {
            id: "06952",
            name: "Xã Quảng Phong",
            level: "Xã"
          },
          {
            id: "06967",
            name: "Xã Cái Chiên",
            level: "Xã"
          }
        ]
      },
      {
        id: "202",
        name: "Huyện Ba Chẽ",
        wards: [
          {
            id: "06970",
            name: "Thị trấn Ba Chẽ",
            level: "Thị trấn"
          },
          {
            id: "06973",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "06976",
            name: "Xã Thanh Lâm",
            level: "Xã"
          },
          {
            id: "06979",
            name: "Xã Đạp Thanh",
            level: "Xã"
          },
          {
            id: "06982",
            name: "Xã Nam Sơn",
            level: "Xã"
          },
          {
            id: "06985",
            name: "Xã Lương Mông",
            level: "Xã"
          },
          {
            id: "06988",
            name: "Xã Đồn Đạc",
            level: "Xã"
          },
          {
            id: "06991",
            name: "Xã Minh Cầm",
            level: "Xã"
          }
        ]
      },
      {
        id: "203",
        name: "Huyện Vân Đồn",
        wards: [
          {
            id: "06994",
            name: "Thị trấn Cái Rồng",
            level: "Thị trấn"
          },
          {
            id: "06997",
            name: "Xã Đài Xuyên",
            level: "Xã"
          },
          {
            id: "07000",
            name: "Xã Bình Dân",
            level: "Xã"
          },
          {
            id: "07003",
            name: "Xã Vạn Yên",
            level: "Xã"
          },
          {
            id: "07006",
            name: "Xã Minh Châu",
            level: "Xã"
          },
          {
            id: "07009",
            name: "Xã Đoàn Kết",
            level: "Xã"
          },
          {
            id: "07012",
            name: "Xã Hạ Long",
            level: "Xã"
          },
          {
            id: "07015",
            name: "Xã Đông Xá",
            level: "Xã"
          },
          {
            id: "07018",
            name: "Xã Bản Sen",
            level: "Xã"
          },
          {
            id: "07021",
            name: "Xã Thắng Lợi",
            level: "Xã"
          },
          {
            id: "07024",
            name: "Xã Quan Lạn",
            level: "Xã"
          },
          {
            id: "07027",
            name: "Xã Ngọc Vừng",
            level: "Xã"
          }
        ]
      },
      {
        id: "205",
        name: "Thị xã Đông Triều",
        wards: [
          {
            id: "07069",
            name: "Phường Mạo Khê",
            level: "Phường"
          },
          {
            id: "07072",
            name: "Phường Đông Triều",
            level: "Phường"
          },
          {
            id: "07075",
            name: "Xã An Sinh",
            level: "Xã"
          },
          {
            id: "07078",
            name: "Xã Tràng Lương",
            level: "Xã"
          },
          {
            id: "07081",
            name: "Xã Bình Khê",
            level: "Xã"
          },
          {
            id: "07084",
            name: "Xã Việt Dân",
            level: "Xã"
          },
          {
            id: "07087",
            name: "Xã Tân Việt",
            level: "Xã"
          },
          {
            id: "07090",
            name: "Xã Bình Dương",
            level: "Xã"
          },
          {
            id: "07093",
            name: "Phường Đức Chính",
            level: "Phường"
          },
          {
            id: "07096",
            name: "Phường Tràng An",
            level: "Phường"
          },
          {
            id: "07099",
            name: "Xã Nguyễn Huệ",
            level: "Xã"
          },
          {
            id: "07102",
            name: "Xã Thủy An",
            level: "Xã"
          },
          {
            id: "07105",
            name: "Phường Xuân Sơn",
            level: "Phường"
          },
          {
            id: "07108",
            name: "Xã Hồng Thái Tây",
            level: "Xã"
          },
          {
            id: "07111",
            name: "Xã Hồng Thái Đông",
            level: "Xã"
          },
          {
            id: "07114",
            name: "Phường Hoàng Quế",
            level: "Phường"
          },
          {
            id: "07117",
            name: "Phường Yên Thọ",
            level: "Phường"
          },
          {
            id: "07120",
            name: "Phường Hồng Phong",
            level: "Phường"
          },
          {
            id: "07123",
            name: "Phường Kim Sơn",
            level: "Phường"
          },
          {
            id: "07126",
            name: "Phường Hưng Đạo",
            level: "Phường"
          },
          {
            id: "07129",
            name: "Xã Yên Đức",
            level: "Xã"
          }
        ]
      },
      {
        id: "206",
        name: "Thị xã Quảng Yên",
        wards: [
          {
            id: "07132",
            name: "Phường Quảng Yên",
            level: "Phường"
          },
          {
            id: "07135",
            name: "Phường Đông Mai",
            level: "Phường"
          },
          {
            id: "07138",
            name: "Phường Minh Thành",
            level: "Phường"
          },
          {
            id: "07144",
            name: "Xã Sông Khoai",
            level: "Xã"
          },
          {
            id: "07147",
            name: "Xã Hiệp Hòa",
            level: "Xã"
          },
          {
            id: "07150",
            name: "Phường Cộng Hòa",
            level: "Phường"
          },
          {
            id: "07153",
            name: "Xã Tiền An",
            level: "Xã"
          },
          {
            id: "07156",
            name: "Xã Hoàng Tân",
            level: "Xã"
          },
          {
            id: "07159",
            name: "Phường Tân An",
            level: "Phường"
          },
          {
            id: "07162",
            name: "Phường Yên Giang",
            level: "Phường"
          },
          {
            id: "07165",
            name: "Phường Nam Hoà",
            level: "Phường"
          },
          {
            id: "07168",
            name: "Phường Hà An",
            level: "Phường"
          },
          {
            id: "07171",
            name: "Xã Cẩm La",
            level: "Xã"
          },
          {
            id: "07174",
            name: "Phường Phong Hải",
            level: "Phường"
          },
          {
            id: "07177",
            name: "Phường Yên Hải",
            level: "Phường"
          },
          {
            id: "07180",
            name: "Xã Liên Hòa",
            level: "Xã"
          },
          {
            id: "07183",
            name: "Phường Phong Cốc",
            level: "Phường"
          },
          {
            id: "07186",
            name: "Xã Liên Vị",
            level: "Xã"
          },
          {
            id: "07189",
            name: "Xã Tiền Phong",
            level: "Xã"
          }
        ]
      },
      {
        id: "207",
        name: "Huyện Cô Tô",
        wards: [
          {
            id: "07192",
            name: "Thị trấn Cô Tô",
            level: "Thị trấn"
          },
          {
            id: "07195",
            name: "Xã Đồng Tiến",
            level: "Xã"
          },
          {
            id: "07198",
            name: "Xã Thanh Lân",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "24",
    name: "Tỉnh Bắc Giang",
    districts: [
      {
        id: "213",
        name: "Thành phố Bắc Giang",
        wards: [
          {
            id: "07201",
            name: "Phường Thọ Xương",
            level: "Phường"
          },
          {
            id: "07204",
            name: "Phường Trần Nguyên Hãn",
            level: "Phường"
          },
          {
            id: "07207",
            name: "Phường Ngô Quyền",
            level: "Phường"
          },
          {
            id: "07210",
            name: "Phường Hoàng Văn Thụ",
            level: "Phường"
          },
          {
            id: "07213",
            name: "Phường Trần Phú",
            level: "Phường"
          },
          {
            id: "07216",
            name: "Phường Mỹ Độ",
            level: "Phường"
          },
          {
            id: "07219",
            name: "Phường Lê Lợi",
            level: "Phường"
          },
          {
            id: "07222",
            name: "Xã Song Mai",
            level: "Xã"
          },
          {
            id: "07225",
            name: "Phường Xương Giang",
            level: "Phường"
          },
          {
            id: "07228",
            name: "Phường Đa Mai",
            level: "Phường"
          },
          {
            id: "07231",
            name: "Phường Dĩnh Kế",
            level: "Phường"
          },
          {
            id: "07441",
            name: "Xã Dĩnh Trì",
            level: "Xã"
          },
          {
            id: "07687",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "07696",
            name: "Xã Đồng Sơn",
            level: "Xã"
          },
          {
            id: "07699",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "07705",
            name: "Xã Song Khê",
            level: "Xã"
          }
        ]
      },
      {
        id: "215",
        name: "Huyện Yên Thế",
        wards: [
          {
            id: "07243",
            name: "Xã Đồng Tiến",
            level: "Xã"
          },
          {
            id: "07246",
            name: "Xã Canh Nậu",
            level: "Xã"
          },
          {
            id: "07249",
            name: "Xã Xuân Lương",
            level: "Xã"
          },
          {
            id: "07252",
            name: "Xã Tam Tiến",
            level: "Xã"
          },
          {
            id: "07255",
            name: "Xã Đồng Vương",
            level: "Xã"
          },
          {
            id: "07258",
            name: "Xã Đồng Hưu",
            level: "Xã"
          },
          {
            id: "07260",
            name: "Xã Đồng Tâm",
            level: "Xã"
          },
          {
            id: "07261",
            name: "Xã Tam Hiệp",
            level: "Xã"
          },
          {
            id: "07264",
            name: "Xã Tiến Thắng",
            level: "Xã"
          },
          {
            id: "07267",
            name: "Xã Hồng Kỳ",
            level: "Xã"
          },
          {
            id: "07270",
            name: "Xã Đồng Lạc",
            level: "Xã"
          },
          {
            id: "07273",
            name: "Xã Đông Sơn",
            level: "Xã"
          },
          {
            id: "07276",
            name: "Xã Tân Hiệp",
            level: "Xã"
          },
          {
            id: "07279",
            name: "Xã Hương Vĩ",
            level: "Xã"
          },
          {
            id: "07282",
            name: "Xã Đồng Kỳ",
            level: "Xã"
          },
          {
            id: "07285",
            name: "Xã An Thượng",
            level: "Xã"
          },
          {
            id: "07288",
            name: "Thị trấn Phồn Xương",
            level: "Thị trấn"
          },
          {
            id: "07291",
            name: "Xã Tân Sỏi",
            level: "Xã"
          },
          {
            id: "07294",
            name: "Thị trấn Bố Hạ",
            level: "Thị trấn"
          }
        ]
      },
      {
        id: "216",
        name: "Huyện Tân Yên",
        wards: [
          {
            id: "07303",
            name: "Xã Lan Giới",
            level: "Xã"
          },
          {
            id: "07306",
            name: "Thị trấn Nhã Nam",
            level: "Thị trấn"
          },
          {
            id: "07309",
            name: "Xã Tân Trung",
            level: "Xã"
          },
          {
            id: "07312",
            name: "Xã Đại Hóa",
            level: "Xã"
          },
          {
            id: "07315",
            name: "Xã Quang Tiến",
            level: "Xã"
          },
          {
            id: "07318",
            name: "Xã Phúc Sơn",
            level: "Xã"
          },
          {
            id: "07321",
            name: "Xã An Dương",
            level: "Xã"
          },
          {
            id: "07324",
            name: "Xã Phúc Hòa",
            level: "Xã"
          },
          {
            id: "07327",
            name: "Xã Liên Sơn",
            level: "Xã"
          },
          {
            id: "07330",
            name: "Xã Hợp Đức",
            level: "Xã"
          },
          {
            id: "07333",
            name: "Xã Lam Cốt",
            level: "Xã"
          },
          {
            id: "07336",
            name: "Xã Cao Xá",
            level: "Xã"
          },
          {
            id: "07339",
            name: "Thị trấn Cao Thượng",
            level: "Thị trấn"
          },
          {
            id: "07342",
            name: "Xã Việt Ngọc",
            level: "Xã"
          },
          {
            id: "07345",
            name: "Xã Song Vân",
            level: "Xã"
          },
          {
            id: "07348",
            name: "Xã Ngọc Châu",
            level: "Xã"
          },
          {
            id: "07351",
            name: "Xã Ngọc Vân",
            level: "Xã"
          },
          {
            id: "07354",
            name: "Xã Việt Lập",
            level: "Xã"
          },
          {
            id: "07357",
            name: "Xã Liên Chung",
            level: "Xã"
          },
          {
            id: "07360",
            name: "Xã Ngọc Thiện",
            level: "Xã"
          },
          {
            id: "07363",
            name: "Xã Ngọc Lý",
            level: "Xã"
          },
          {
            id: "07366",
            name: "Xã Quế Nham",
            level: "Xã"
          }
        ]
      },
      {
        id: "217",
        name: "Huyện Lạng Giang",
        wards: [
          {
            id: "07375",
            name: "Thị trấn Vôi",
            level: "Thị trấn"
          },
          {
            id: "07378",
            name: "Xã Nghĩa Hòa",
            level: "Xã"
          },
          {
            id: "07381",
            name: "Xã Nghĩa Hưng",
            level: "Xã"
          },
          {
            id: "07384",
            name: "Xã Quang Thịnh",
            level: "Xã"
          },
          {
            id: "07387",
            name: "Xã Hương Sơn",
            level: "Xã"
          },
          {
            id: "07390",
            name: "Xã Đào Mỹ",
            level: "Xã"
          },
          {
            id: "07393",
            name: "Xã Tiên Lục",
            level: "Xã"
          },
          {
            id: "07396",
            name: "Xã An Hà",
            level: "Xã"
          },
          {
            id: "07399",
            name: "Thị trấn Kép",
            level: "Thị trấn"
          },
          {
            id: "07402",
            name: "Xã Mỹ Hà",
            level: "Xã"
          },
          {
            id: "07405",
            name: "Xã Hương Lạc",
            level: "Xã"
          },
          {
            id: "07408",
            name: "Xã Dương Đức",
            level: "Xã"
          },
          {
            id: "07411",
            name: "Xã Tân Thanh",
            level: "Xã"
          },
          {
            id: "07414",
            name: "Xã Yên Mỹ",
            level: "Xã"
          },
          {
            id: "07417",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "07420",
            name: "Xã Mỹ Thái",
            level: "Xã"
          },
          {
            id: "07426",
            name: "Xã Xương Lâm",
            level: "Xã"
          },
          {
            id: "07429",
            name: "Xã Xuân Hương",
            level: "Xã"
          },
          {
            id: "07432",
            name: "Xã Tân Dĩnh",
            level: "Xã"
          },
          {
            id: "07435",
            name: "Xã Đại Lâm",
            level: "Xã"
          },
          {
            id: "07438",
            name: "Xã Thái Đào",
            level: "Xã"
          }
        ]
      },
      {
        id: "218",
        name: "Huyện Lục Nam",
        wards: [
          {
            id: "07444",
            name: "Thị trấn Đồi Ngô",
            level: "Thị trấn"
          },
          {
            id: "07450",
            name: "Xã Đông Hưng",
            level: "Xã"
          },
          {
            id: "07453",
            name: "Xã Đông Phú",
            level: "Xã"
          },
          {
            id: "07456",
            name: "Xã Tam Dị",
            level: "Xã"
          },
          {
            id: "07459",
            name: "Xã Bảo Sơn",
            level: "Xã"
          },
          {
            id: "07462",
            name: "Xã Bảo Đài",
            level: "Xã"
          },
          {
            id: "07465",
            name: "Xã Thanh Lâm",
            level: "Xã"
          },
          {
            id: "07468",
            name: "Xã Tiên Nha",
            level: "Xã"
          },
          {
            id: "07471",
            name: "Xã Trường Giang",
            level: "Xã"
          },
          {
            id: "07477",
            name: "Xã Phương Sơn",
            level: "Xã"
          },
          {
            id: "07480",
            name: "Xã Chu Điện",
            level: "Xã"
          },
          {
            id: "07483",
            name: "Xã Cương Sơn",
            level: "Xã"
          },
          {
            id: "07486",
            name: "Xã Nghĩa Phương",
            level: "Xã"
          },
          {
            id: "07489",
            name: "Xã Vô Tranh",
            level: "Xã"
          },
          {
            id: "07492",
            name: "Xã Bình Sơn",
            level: "Xã"
          },
          {
            id: "07495",
            name: "Xã Lan Mẫu",
            level: "Xã"
          },
          {
            id: "07498",
            name: "Xã Yên Sơn",
            level: "Xã"
          },
          {
            id: "07501",
            name: "Xã Khám Lạng",
            level: "Xã"
          },
          {
            id: "07504",
            name: "Xã Huyền Sơn",
            level: "Xã"
          },
          {
            id: "07507",
            name: "Xã Trường Sơn",
            level: "Xã"
          },
          {
            id: "07510",
            name: "Xã Lục Sơn",
            level: "Xã"
          },
          {
            id: "07513",
            name: "Xã Bắc Lũng",
            level: "Xã"
          },
          {
            id: "07516",
            name: "Xã Vũ Xá",
            level: "Xã"
          },
          {
            id: "07519",
            name: "Xã Cẩm Lý",
            level: "Xã"
          },
          {
            id: "07522",
            name: "Xã Đan Hội",
            level: "Xã"
          }
        ]
      },
      {
        id: "219",
        name: "Huyện Lục Ngạn",
        wards: [
          {
            id: "07525",
            name: "Thị trấn Chũ",
            level: "Thị trấn"
          },
          {
            id: "07528",
            name: "Xã Cấm Sơn",
            level: "Xã"
          },
          {
            id: "07531",
            name: "Xã Tân Sơn",
            level: "Xã"
          },
          {
            id: "07534",
            name: "Xã Phong Minh",
            level: "Xã"
          },
          {
            id: "07537",
            name: "Xã Phong Vân",
            level: "Xã"
          },
          {
            id: "07540",
            name: "Xã Xa Lý",
            level: "Xã"
          },
          {
            id: "07543",
            name: "Xã Hộ Đáp",
            level: "Xã"
          },
          {
            id: "07546",
            name: "Xã Sơn Hải",
            level: "Xã"
          },
          {
            id: "07549",
            name: "Xã Thanh Hải",
            level: "Xã"
          },
          {
            id: "07552",
            name: "Xã Kiên Lao",
            level: "Xã"
          },
          {
            id: "07555",
            name: "Xã Biên Sơn",
            level: "Xã"
          },
          {
            id: "07558",
            name: "Xã Kiên Thành",
            level: "Xã"
          },
          {
            id: "07561",
            name: "Xã Hồng Giang",
            level: "Xã"
          },
          {
            id: "07564",
            name: "Xã Kim Sơn",
            level: "Xã"
          },
          {
            id: "07567",
            name: "Xã Tân Hoa",
            level: "Xã"
          },
          {
            id: "07570",
            name: "Xã Giáp Sơn",
            level: "Xã"
          },
          {
            id: "07573",
            name: "Xã Biển Động",
            level: "Xã"
          },
          {
            id: "07576",
            name: "Xã Quý Sơn",
            level: "Xã"
          },
          {
            id: "07579",
            name: "Xã Trù Hựu",
            level: "Xã"
          },
          {
            id: "07582",
            name: "Xã Phì Điền",
            level: "Xã"
          },
          {
            id: "07588",
            name: "Xã Tân Quang",
            level: "Xã"
          },
          {
            id: "07591",
            name: "Xã Đồng Cốc",
            level: "Xã"
          },
          {
            id: "07594",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "07597",
            name: "Xã Phú Nhuận",
            level: "Xã"
          },
          {
            id: "07600",
            name: "Xã Mỹ An",
            level: "Xã"
          },
          {
            id: "07603",
            name: "Xã Nam Dương",
            level: "Xã"
          },
          {
            id: "07606",
            name: "Xã Tân Mộc",
            level: "Xã"
          },
          {
            id: "07609",
            name: "Xã Đèo Gia",
            level: "Xã"
          },
          {
            id: "07612",
            name: "Xã Phượng Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "220",
        name: "Huyện Sơn Động",
        wards: [
          {
            id: "07615",
            name: "Thị trấn An Châu",
            level: "Thị trấn"
          },
          {
            id: "07616",
            name: "Thị trấn Tây Yên Tử",
            level: "Thị trấn"
          },
          {
            id: "07621",
            name: "Xã Vân Sơn",
            level: "Xã"
          },
          {
            id: "07624",
            name: "Xã Hữu Sản",
            level: "Xã"
          },
          {
            id: "07627",
            name: "Xã Đại Sơn",
            level: "Xã"
          },
          {
            id: "07630",
            name: "Xã Phúc Sơn",
            level: "Xã"
          },
          {
            id: "07636",
            name: "Xã Giáo Liêm",
            level: "Xã"
          },
          {
            id: "07642",
            name: "Xã Cẩm Đàn",
            level: "Xã"
          },
          {
            id: "07645",
            name: "Xã An Lạc",
            level: "Xã"
          },
          {
            id: "07648",
            name: "Xã Vĩnh An",
            level: "Xã"
          },
          {
            id: "07651",
            name: "Xã Yên Định",
            level: "Xã"
          },
          {
            id: "07654",
            name: "Xã Lệ Viễn",
            level: "Xã"
          },
          {
            id: "07660",
            name: "Xã An Bá",
            level: "Xã"
          },
          {
            id: "07663",
            name: "Xã Tuấn Đạo",
            level: "Xã"
          },
          {
            id: "07666",
            name: "Xã Dương Hưu",
            level: "Xã"
          },
          {
            id: "07672",
            name: "Xã Long Sơn",
            level: "Xã"
          },
          {
            id: "07678",
            name: "Xã Thanh Luận",
            level: "Xã"
          }
        ]
      },
      {
        id: "221",
        name: "Huyện Yên Dũng",
        wards: [
          {
            id: "07681",
            name: "Thị trấn Nham Biền",
            level: "Thị trấn"
          },
          {
            id: "07682",
            name: "Thị trấn Tân An",
            level: "Thị trấn"
          },
          {
            id: "07684",
            name: "Xã Lão Hộ",
            level: "Xã"
          },
          {
            id: "07690",
            name: "Xã Hương Gián",
            level: "Xã"
          },
          {
            id: "07702",
            name: "Xã Quỳnh Sơn",
            level: "Xã"
          },
          {
            id: "07708",
            name: "Xã Nội Hoàng",
            level: "Xã"
          },
          {
            id: "07711",
            name: "Xã Tiền Phong",
            level: "Xã"
          },
          {
            id: "07714",
            name: "Xã Xuân Phú",
            level: "Xã"
          },
          {
            id: "07717",
            name: "Xã Tân Liễu",
            level: "Xã"
          },
          {
            id: "07720",
            name: "Xã Trí Yên",
            level: "Xã"
          },
          {
            id: "07723",
            name: "Xã Lãng Sơn",
            level: "Xã"
          },
          {
            id: "07726",
            name: "Xã Yên Lư",
            level: "Xã"
          },
          {
            id: "07729",
            name: "Xã Tiến Dũng",
            level: "Xã"
          },
          {
            id: "07735",
            name: "Xã Đức Giang",
            level: "Xã"
          },
          {
            id: "07738",
            name: "Xã Cảnh Thụy",
            level: "Xã"
          },
          {
            id: "07741",
            name: "Xã Tư Mại",
            level: "Xã"
          },
          {
            id: "07747",
            name: "Xã Đồng Việt",
            level: "Xã"
          },
          {
            id: "07750",
            name: "Xã Đồng Phúc",
            level: "Xã"
          }
        ]
      },
      {
        id: "222",
        name: "Huyện Việt Yên",
        wards: [
          {
            id: "07759",
            name: "Xã Thượng Lan",
            level: "Xã"
          },
          {
            id: "07762",
            name: "Xã Việt Tiến",
            level: "Xã"
          },
          {
            id: "07765",
            name: "Xã Nghĩa Trung",
            level: "Xã"
          },
          {
            id: "07768",
            name: "Xã Minh Đức",
            level: "Xã"
          },
          {
            id: "07771",
            name: "Xã Hương Mai",
            level: "Xã"
          },
          {
            id: "07774",
            name: "Xã Tự Lạn",
            level: "Xã"
          },
          {
            id: "07777",
            name: "Thị trấn Bích Động",
            level: "Thị trấn"
          },
          {
            id: "07780",
            name: "Xã Trung Sơn",
            level: "Xã"
          },
          {
            id: "07783",
            name: "Xã Hồng Thái",
            level: "Xã"
          },
          {
            id: "07786",
            name: "Xã Tiên Sơn",
            level: "Xã"
          },
          {
            id: "07789",
            name: "Xã Tăng Tiến",
            level: "Xã"
          },
          {
            id: "07792",
            name: "Xã Quảng Minh",
            level: "Xã"
          },
          {
            id: "07795",
            name: "Thị trấn Nếnh",
            level: "Thị trấn"
          },
          {
            id: "07798",
            name: "Xã Ninh Sơn",
            level: "Xã"
          },
          {
            id: "07801",
            name: "Xã Vân Trung",
            level: "Xã"
          },
          {
            id: "07804",
            name: "Xã Vân Hà",
            level: "Xã"
          },
          {
            id: "07807",
            name: "Xã Quang Châu",
            level: "Xã"
          }
        ]
      },
      {
        id: "223",
        name: "Huyện Hiệp Hòa",
        wards: [
          {
            id: "07813",
            name: "Xã Đồng Tân",
            level: "Xã"
          },
          {
            id: "07816",
            name: "Xã Thanh Vân",
            level: "Xã"
          },
          {
            id: "07819",
            name: "Xã Hoàng Lương",
            level: "Xã"
          },
          {
            id: "07822",
            name: "Xã Hoàng Vân",
            level: "Xã"
          },
          {
            id: "07825",
            name: "Xã Hoàng Thanh",
            level: "Xã"
          },
          {
            id: "07828",
            name: "Xã Hoàng An",
            level: "Xã"
          },
          {
            id: "07831",
            name: "Xã Ngọc Sơn",
            level: "Xã"
          },
          {
            id: "07834",
            name: "Xã Thái Sơn",
            level: "Xã"
          },
          {
            id: "07837",
            name: "Xã Hòa Sơn",
            level: "Xã"
          },
          {
            id: "07840",
            name: "Thị trấn Thắng",
            level: "Thị trấn"
          },
          {
            id: "07843",
            name: "Xã Quang Minh",
            level: "Xã"
          },
          {
            id: "07846",
            name: "Xã Lương Phong",
            level: "Xã"
          },
          {
            id: "07849",
            name: "Xã Hùng Sơn",
            level: "Xã"
          },
          {
            id: "07852",
            name: "Xã Đại Thành",
            level: "Xã"
          },
          {
            id: "07855",
            name: "Xã Thường Thắng",
            level: "Xã"
          },
          {
            id: "07858",
            name: "Xã Hợp Thịnh",
            level: "Xã"
          },
          {
            id: "07861",
            name: "Xã Danh Thắng",
            level: "Xã"
          },
          {
            id: "07864",
            name: "Xã Mai Trung",
            level: "Xã"
          },
          {
            id: "07867",
            name: "Xã Đoan Bái",
            level: "Xã"
          },
          {
            id: "07870",
            name: "Xã Bắc Lý",
            level: "Xã"
          },
          {
            id: "07873",
            name: "Xã Xuân Cẩm",
            level: "Xã"
          },
          {
            id: "07876",
            name: "Xã Hương Lâm",
            level: "Xã"
          },
          {
            id: "07879",
            name: "Xã Đông Lỗ",
            level: "Xã"
          },
          {
            id: "07882",
            name: "Xã Châu Minh",
            level: "Xã"
          },
          {
            id: "07885",
            name: "Xã Mai Đình",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "25",
    name: "Tỉnh Phú Thọ",
    districts: [
      {
        id: "227",
        name: "Thành phố Việt Trì",
        wards: [
          {
            id: "07888",
            name: "Phường Dữu Lâu",
            level: "Phường"
          },
          {
            id: "07891",
            name: "Phường Vân Cơ",
            level: "Phường"
          },
          {
            id: "07894",
            name: "Phường Nông Trang",
            level: "Phường"
          },
          {
            id: "07897",
            name: "Phường Tân Dân",
            level: "Phường"
          },
          {
            id: "07900",
            name: "Phường Gia Cẩm",
            level: "Phường"
          },
          {
            id: "07903",
            name: "Phường Tiên Cát",
            level: "Phường"
          },
          {
            id: "07906",
            name: "Phường Thọ Sơn",
            level: "Phường"
          },
          {
            id: "07909",
            name: "Phường Thanh Miếu",
            level: "Phường"
          },
          {
            id: "07912",
            name: "Phường Bạch Hạc",
            level: "Phường"
          },
          {
            id: "07915",
            name: "Phường Bến Gót",
            level: "Phường"
          },
          {
            id: "07918",
            name: "Phường Vân Phú",
            level: "Phường"
          },
          {
            id: "07921",
            name: "Xã Phượng Lâu",
            level: "Xã"
          },
          {
            id: "07924",
            name: "Xã Thụy Vân",
            level: "Xã"
          },
          {
            id: "07927",
            name: "Phường Minh Phương",
            level: "Phường"
          },
          {
            id: "07930",
            name: "Xã Trưng Vương",
            level: "Xã"
          },
          {
            id: "07933",
            name: "Phường Minh Nông",
            level: "Phường"
          },
          {
            id: "07936",
            name: "Xã Sông Lô",
            level: "Xã"
          },
          {
            id: "08281",
            name: "Xã Kim Đức",
            level: "Xã"
          },
          {
            id: "08287",
            name: "Xã Hùng Lô",
            level: "Xã"
          },
          {
            id: "08503",
            name: "Xã Hy Cương",
            level: "Xã"
          },
          {
            id: "08506",
            name: "Xã Chu Hóa",
            level: "Xã"
          },
          {
            id: "08515",
            name: "Xã Thanh Đình",
            level: "Xã"
          }
        ]
      },
      {
        id: "228",
        name: "Thị xã Phú Thọ",
        wards: [
          {
            id: "07942",
            name: "Phường Hùng Vương",
            level: "Phường"
          },
          {
            id: "07945",
            name: "Phường Phong Châu",
            level: "Phường"
          },
          {
            id: "07948",
            name: "Phường Âu Cơ",
            level: "Phường"
          },
          {
            id: "07951",
            name: "Xã Hà Lộc",
            level: "Xã"
          },
          {
            id: "07954",
            name: "Xã Phú Hộ",
            level: "Xã"
          },
          {
            id: "07957",
            name: "Xã Văn Lung",
            level: "Xã"
          },
          {
            id: "07960",
            name: "Xã Thanh Minh",
            level: "Xã"
          },
          {
            id: "07963",
            name: "Xã Hà Thạch",
            level: "Xã"
          },
          {
            id: "07966",
            name: "Phường Thanh Vinh",
            level: "Phường"
          }
        ]
      },
      {
        id: "230",
        name: "Huyện Đoan Hùng",
        wards: [
          {
            id: "07969",
            name: "Thị trấn Đoan Hùng",
            level: "Thị trấn"
          },
          {
            id: "07975",
            name: "Xã Hùng Xuyên",
            level: "Xã"
          },
          {
            id: "07981",
            name: "Xã Bằng Luân",
            level: "Xã"
          },
          {
            id: "07984",
            name: "Xã Vân Du",
            level: "Xã"
          },
          {
            id: "07987",
            name: "Xã Phú Lâm",
            level: "Xã"
          },
          {
            id: "07993",
            name: "Xã Minh Lương",
            level: "Xã"
          },
          {
            id: "07996",
            name: "Xã Bằng Doãn",
            level: "Xã"
          },
          {
            id: "07999",
            name: "Xã Chí Đám",
            level: "Xã"
          },
          {
            id: "08005",
            name: "Xã Phúc Lai",
            level: "Xã"
          },
          {
            id: "08008",
            name: "Xã Ngọc Quan",
            level: "Xã"
          },
          {
            id: "08014",
            name: "Xã Hợp Nhất",
            level: "Xã"
          },
          {
            id: "08017",
            name: "Xã Sóc Đăng",
            level: "Xã"
          },
          {
            id: "08023",
            name: "Xã Tây Cốc",
            level: "Xã"
          },
          {
            id: "08026",
            name: "Xã Yên Kiện",
            level: "Xã"
          },
          {
            id: "08029",
            name: "Xã Hùng Long",
            level: "Xã"
          },
          {
            id: "08032",
            name: "Xã Vụ Quang",
            level: "Xã"
          },
          {
            id: "08035",
            name: "Xã Vân Đồn",
            level: "Xã"
          },
          {
            id: "08038",
            name: "Xã Tiêu Sơn",
            level: "Xã"
          },
          {
            id: "08041",
            name: "Xã Minh Tiến",
            level: "Xã"
          },
          {
            id: "08044",
            name: "Xã Minh Phú",
            level: "Xã"
          },
          {
            id: "08047",
            name: "Xã Chân Mộng",
            level: "Xã"
          },
          {
            id: "08050",
            name: "Xã Ca Đình",
            level: "Xã"
          }
        ]
      },
      {
        id: "231",
        name: "Huyện Hạ Hoà",
        wards: [
          {
            id: "08053",
            name: "Thị trấn Hạ Hoà",
            level: "Thị trấn"
          },
          {
            id: "08056",
            name: "Xã Đại Phạm",
            level: "Xã"
          },
          {
            id: "08062",
            name: "Xã Đan Thượng",
            level: "Xã"
          },
          {
            id: "08065",
            name: "Xã Hà Lương",
            level: "Xã"
          },
          {
            id: "08071",
            name: "Xã Tứ Hiệp",
            level: "Xã"
          },
          {
            id: "08080",
            name: "Xã Hiền Lương",
            level: "Xã"
          },
          {
            id: "08089",
            name: "Xã Phương Viên",
            level: "Xã"
          },
          {
            id: "08092",
            name: "Xã Gia Điền",
            level: "Xã"
          },
          {
            id: "08095",
            name: "Xã Ấm Hạ",
            level: "Xã"
          },
          {
            id: "08104",
            name: "Xã Hương Xạ",
            level: "Xã"
          },
          {
            id: "08110",
            name: "Xã Xuân Áng",
            level: "Xã"
          },
          {
            id: "08113",
            name: "Xã Yên Kỳ",
            level: "Xã"
          },
          {
            id: "08119",
            name: "Xã Minh Hạc",
            level: "Xã"
          },
          {
            id: "08122",
            name: "Xã Lang Sơn",
            level: "Xã"
          },
          {
            id: "08125",
            name: "Xã Bằng Giã",
            level: "Xã"
          },
          {
            id: "08128",
            name: "Xã Yên Luật",
            level: "Xã"
          },
          {
            id: "08131",
            name: "Xã Vô Tranh",
            level: "Xã"
          },
          {
            id: "08134",
            name: "Xã Văn Lang",
            level: "Xã"
          },
          {
            id: "08140",
            name: "Xã Minh Côi",
            level: "Xã"
          },
          {
            id: "08143",
            name: "Xã Vĩnh Chân",
            level: "Xã"
          }
        ]
      },
      {
        id: "232",
        name: "Huyện Thanh Ba",
        wards: [
          {
            id: "08152",
            name: "Thị trấn Thanh Ba",
            level: "Thị trấn"
          },
          {
            id: "08156",
            name: "Xã Vân Lĩnh",
            level: "Xã"
          },
          {
            id: "08158",
            name: "Xã Đông Lĩnh",
            level: "Xã"
          },
          {
            id: "08161",
            name: "Xã Đại An",
            level: "Xã"
          },
          {
            id: "08164",
            name: "Xã Hanh Cù",
            level: "Xã"
          },
          {
            id: "08170",
            name: "Xã Đồng Xuân",
            level: "Xã"
          },
          {
            id: "08173",
            name: "Xã Quảng Yên",
            level: "Xã"
          },
          {
            id: "08179",
            name: "Xã Ninh Dân",
            level: "Xã"
          },
          {
            id: "08194",
            name: "Xã Võ Lao",
            level: "Xã"
          },
          {
            id: "08197",
            name: "Xã Khải Xuân",
            level: "Xã"
          },
          {
            id: "08200",
            name: "Xã Mạn Lạn",
            level: "Xã"
          },
          {
            id: "08203",
            name: "Xã Hoàng Cương",
            level: "Xã"
          },
          {
            id: "08206",
            name: "Xã Chí Tiên",
            level: "Xã"
          },
          {
            id: "08209",
            name: "Xã Đông Thành",
            level: "Xã"
          },
          {
            id: "08215",
            name: "Xã Sơn Cương",
            level: "Xã"
          },
          {
            id: "08218",
            name: "Xã Thanh Hà",
            level: "Xã"
          },
          {
            id: "08221",
            name: "Xã Đỗ Sơn",
            level: "Xã"
          },
          {
            id: "08224",
            name: "Xã Đỗ Xuyên",
            level: "Xã"
          },
          {
            id: "08227",
            name: "Xã Lương Lỗ",
            level: "Xã"
          }
        ]
      },
      {
        id: "233",
        name: "Huyện Phù Ninh",
        wards: [
          {
            id: "08230",
            name: "Thị trấn Phong Châu",
            level: "Thị trấn"
          },
          {
            id: "08233",
            name: "Xã Phú Mỹ",
            level: "Xã"
          },
          {
            id: "08234",
            name: "Xã Lệ Mỹ",
            level: "Xã"
          },
          {
            id: "08236",
            name: "Xã Liên Hoa",
            level: "Xã"
          },
          {
            id: "08239",
            name: "Xã Trạm Thản",
            level: "Xã"
          },
          {
            id: "08242",
            name: "Xã Trị Quận",
            level: "Xã"
          },
          {
            id: "08245",
            name: "Xã Trung Giáp",
            level: "Xã"
          },
          {
            id: "08248",
            name: "Xã Tiên Phú",
            level: "Xã"
          },
          {
            id: "08251",
            name: "Xã Hạ Giáp",
            level: "Xã"
          },
          {
            id: "08254",
            name: "Xã Bảo Thanh",
            level: "Xã"
          },
          {
            id: "08257",
            name: "Xã Phú Lộc",
            level: "Xã"
          },
          {
            id: "08260",
            name: "Xã Gia Thanh",
            level: "Xã"
          },
          {
            id: "08263",
            name: "Xã Tiên Du",
            level: "Xã"
          },
          {
            id: "08266",
            name: "Xã Phú Nham",
            level: "Xã"
          },
          {
            id: "08272",
            name: "Xã An Đạo",
            level: "Xã"
          },
          {
            id: "08275",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "08278",
            name: "Xã Phù Ninh",
            level: "Xã"
          }
        ]
      },
      {
        id: "234",
        name: "Huyện Yên Lập",
        wards: [
          {
            id: "08290",
            name: "Thị trấn Yên Lập",
            level: "Thị trấn"
          },
          {
            id: "08293",
            name: "Xã Mỹ Lung",
            level: "Xã"
          },
          {
            id: "08296",
            name: "Xã Mỹ Lương",
            level: "Xã"
          },
          {
            id: "08299",
            name: "Xã Lương Sơn",
            level: "Xã"
          },
          {
            id: "08302",
            name: "Xã Xuân An",
            level: "Xã"
          },
          {
            id: "08305",
            name: "Xã Xuân Viên",
            level: "Xã"
          },
          {
            id: "08308",
            name: "Xã Xuân Thủy",
            level: "Xã"
          },
          {
            id: "08311",
            name: "Xã Trung Sơn",
            level: "Xã"
          },
          {
            id: "08314",
            name: "Xã Hưng Long",
            level: "Xã"
          },
          {
            id: "08317",
            name: "Xã Nga Hoàng",
            level: "Xã"
          },
          {
            id: "08320",
            name: "Xã Đồng Lạc",
            level: "Xã"
          },
          {
            id: "08323",
            name: "Xã Thượng Long",
            level: "Xã"
          },
          {
            id: "08326",
            name: "Xã Đồng Thịnh",
            level: "Xã"
          },
          {
            id: "08329",
            name: "Xã Phúc Khánh",
            level: "Xã"
          },
          {
            id: "08332",
            name: "Xã Minh Hòa",
            level: "Xã"
          },
          {
            id: "08335",
            name: "Xã Ngọc Lập",
            level: "Xã"
          },
          {
            id: "08338",
            name: "Xã Ngọc Đồng",
            level: "Xã"
          }
        ]
      },
      {
        id: "235",
        name: "Huyện Cẩm Khê",
        wards: [
          {
            id: "08341",
            name: "Thị trấn Cẩm Khê",
            level: "Thị trấn"
          },
          {
            id: "08344",
            name: "Xã Tiên Lương",
            level: "Xã"
          },
          {
            id: "08347",
            name: "Xã Tuy Lộc",
            level: "Xã"
          },
          {
            id: "08350",
            name: "Xã Ngô Xá",
            level: "Xã"
          },
          {
            id: "08353",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "08356",
            name: "Xã Phượng Vĩ",
            level: "Xã"
          },
          {
            id: "08362",
            name: "Xã Thụy Liễu",
            level: "Xã"
          },
          {
            id: "08374",
            name: "Xã Tùng Khê",
            level: "Xã"
          },
          {
            id: "08377",
            name: "Xã Tam Sơn",
            level: "Xã"
          },
          {
            id: "08380",
            name: "Xã Văn Bán",
            level: "Xã"
          },
          {
            id: "08383",
            name: "Xã Cấp Dẫn",
            level: "Xã"
          },
          {
            id: "08389",
            name: "Xã Xương Thịnh",
            level: "Xã"
          },
          {
            id: "08392",
            name: "Xã Phú Khê",
            level: "Xã"
          },
          {
            id: "08395",
            name: "Xã Sơn Tình",
            level: "Xã"
          },
          {
            id: "08398",
            name: "Xã Yên Tập",
            level: "Xã"
          },
          {
            id: "08401",
            name: "Xã Hương Lung",
            level: "Xã"
          },
          {
            id: "08404",
            name: "Xã Tạ Xá",
            level: "Xã"
          },
          {
            id: "08407",
            name: "Xã Phú Lạc",
            level: "Xã"
          },
          {
            id: "08413",
            name: "Xã Chương Xá",
            level: "Xã"
          },
          {
            id: "08416",
            name: "Xã Hùng Việt",
            level: "Xã"
          },
          {
            id: "08419",
            name: "Xã Văn Khúc",
            level: "Xã"
          },
          {
            id: "08422",
            name: "Xã Yên Dưỡng",
            level: "Xã"
          },
          {
            id: "08428",
            name: "Xã Điêu Lương",
            level: "Xã"
          },
          {
            id: "08431",
            name: "Xã Đồng Lương",
            level: "Xã"
          }
        ]
      },
      {
        id: "236",
        name: "Huyện Tam Nông",
        wards: [
          {
            id: "08434",
            name: "Thị trấn Hưng Hoá",
            level: "Thị trấn"
          },
          {
            id: "08440",
            name: "Xã Hiền Quan",
            level: "Xã"
          },
          {
            id: "08443",
            name: "Xã Bắc Sơn",
            level: "Xã"
          },
          {
            id: "08446",
            name: "Xã Thanh Uyên",
            level: "Xã"
          },
          {
            id: "08461",
            name: "Xã Lam Sơn",
            level: "Xã"
          },
          {
            id: "08467",
            name: "Xã Vạn Xuân",
            level: "Xã"
          },
          {
            id: "08470",
            name: "Xã Quang Húc",
            level: "Xã"
          },
          {
            id: "08473",
            name: "Xã Hương Nộn",
            level: "Xã"
          },
          {
            id: "08476",
            name: "Xã Tề Lễ",
            level: "Xã"
          },
          {
            id: "08479",
            name: "Xã Thọ Văn",
            level: "Xã"
          },
          {
            id: "08482",
            name: "Xã Dị Nậu",
            level: "Xã"
          },
          {
            id: "08491",
            name: "Xã Dân Quyền",
            level: "Xã"
          }
        ]
      },
      {
        id: "237",
        name: "Huyện Lâm Thao",
        wards: [
          {
            id: "08494",
            name: "Thị trấn Lâm Thao",
            level: "Thị trấn"
          },
          {
            id: "08497",
            name: "Xã Tiên Kiên",
            level: "Xã"
          },
          {
            id: "08498",
            name: "Thị trấn Hùng Sơn",
            level: "Thị trấn"
          },
          {
            id: "08500",
            name: "Xã Xuân Lũng",
            level: "Xã"
          },
          {
            id: "08509",
            name: "Xã Xuân Huy",
            level: "Xã"
          },
          {
            id: "08512",
            name: "Xã Thạch Sơn",
            level: "Xã"
          },
          {
            id: "08518",
            name: "Xã Sơn Vi",
            level: "Xã"
          },
          {
            id: "08521",
            name: "Xã Phùng Nguyên",
            level: "Xã"
          },
          {
            id: "08527",
            name: "Xã Cao Xá",
            level: "Xã"
          },
          {
            id: "08533",
            name: "Xã Vĩnh Lại",
            level: "Xã"
          },
          {
            id: "08536",
            name: "Xã Tứ Xã",
            level: "Xã"
          },
          {
            id: "08539",
            name: "Xã Bản Nguyên",
            level: "Xã"
          }
        ]
      },
      {
        id: "238",
        name: "Huyện Thanh Sơn",
        wards: [
          {
            id: "08542",
            name: "Thị trấn Thanh Sơn",
            level: "Thị trấn"
          },
          {
            id: "08563",
            name: "Xã Sơn Hùng",
            level: "Xã"
          },
          {
            id: "08572",
            name: "Xã Địch Quả",
            level: "Xã"
          },
          {
            id: "08575",
            name: "Xã Giáp Lai",
            level: "Xã"
          },
          {
            id: "08581",
            name: "Xã Thục Luyện",
            level: "Xã"
          },
          {
            id: "08584",
            name: "Xã Võ Miếu",
            level: "Xã"
          },
          {
            id: "08587",
            name: "Xã Thạch Khoán",
            level: "Xã"
          },
          {
            id: "08602",
            name: "Xã Cự Thắng",
            level: "Xã"
          },
          {
            id: "08605",
            name: "Xã Tất Thắng",
            level: "Xã"
          },
          {
            id: "08611",
            name: "Xã Văn Miếu",
            level: "Xã"
          },
          {
            id: "08614",
            name: "Xã Cự Đồng",
            level: "Xã"
          },
          {
            id: "08623",
            name: "Xã Thắng Sơn",
            level: "Xã"
          },
          {
            id: "08629",
            name: "Xã Tân Minh",
            level: "Xã"
          },
          {
            id: "08632",
            name: "Xã Hương Cần",
            level: "Xã"
          },
          {
            id: "08635",
            name: "Xã Khả Cửu",
            level: "Xã"
          },
          {
            id: "08638",
            name: "Xã Đông Cửu",
            level: "Xã"
          },
          {
            id: "08641",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "08644",
            name: "Xã Yên Lãng",
            level: "Xã"
          },
          {
            id: "08647",
            name: "Xã Yên Lương",
            level: "Xã"
          },
          {
            id: "08650",
            name: "Xã Thượng Cửu",
            level: "Xã"
          },
          {
            id: "08653",
            name: "Xã Lương Nha",
            level: "Xã"
          },
          {
            id: "08656",
            name: "Xã Yên Sơn",
            level: "Xã"
          },
          {
            id: "08659",
            name: "Xã Tinh Nhuệ",
            level: "Xã"
          }
        ]
      },
      {
        id: "239",
        name: "Huyện Thanh Thuỷ",
        wards: [
          {
            id: "08662",
            name: "Xã Đào Xá",
            level: "Xã"
          },
          {
            id: "08665",
            name: "Xã Thạch Đồng",
            level: "Xã"
          },
          {
            id: "08668",
            name: "Xã Xuân Lộc",
            level: "Xã"
          },
          {
            id: "08671",
            name: "Xã Tân Phương",
            level: "Xã"
          },
          {
            id: "08674",
            name: "Thị trấn Thanh Thủy",
            level: "Thị trấn"
          },
          {
            id: "08677",
            name: "Xã Sơn Thủy",
            level: "Xã"
          },
          {
            id: "08680",
            name: "Xã Bảo Yên",
            level: "Xã"
          },
          {
            id: "08683",
            name: "Xã Đoan Hạ",
            level: "Xã"
          },
          {
            id: "08686",
            name: "Xã Đồng Trung",
            level: "Xã"
          },
          {
            id: "08689",
            name: "Xã Hoàng Xá",
            level: "Xã"
          },
          {
            id: "08701",
            name: "Xã Tu Vũ",
            level: "Xã"
          }
        ]
      },
      {
        id: "240",
        name: "Huyện Tân Sơn",
        wards: [
          {
            id: "08545",
            name: "Xã Thu Cúc",
            level: "Xã"
          },
          {
            id: "08548",
            name: "Xã Thạch Kiệt",
            level: "Xã"
          },
          {
            id: "08551",
            name: "Xã Thu Ngạc",
            level: "Xã"
          },
          {
            id: "08554",
            name: "Xã Kiệt Sơn",
            level: "Xã"
          },
          {
            id: "08557",
            name: "Xã Đồng Sơn",
            level: "Xã"
          },
          {
            id: "08560",
            name: "Xã Lai Đồng",
            level: "Xã"
          },
          {
            id: "08566",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "08569",
            name: "Xã Mỹ Thuận",
            level: "Xã"
          },
          {
            id: "08578",
            name: "Xã Tân Sơn",
            level: "Xã"
          },
          {
            id: "08590",
            name: "Xã Xuân Đài",
            level: "Xã"
          },
          {
            id: "08593",
            name: "Xã Minh Đài",
            level: "Xã"
          },
          {
            id: "08596",
            name: "Xã Văn Luông",
            level: "Xã"
          },
          {
            id: "08599",
            name: "Xã Xuân Sơn",
            level: "Xã"
          },
          {
            id: "08608",
            name: "Xã Long Cốc",
            level: "Xã"
          },
          {
            id: "08617",
            name: "Xã Kim Thượng",
            level: "Xã"
          },
          {
            id: "08620",
            name: "Xã Tam Thanh",
            level: "Xã"
          },
          {
            id: "08626",
            name: "Xã Vinh Tiền",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "26",
    name: "Tỉnh Vĩnh Phúc",
    districts: [
      {
        id: "243",
        name: "Thành phố Vĩnh Yên",
        wards: [
          {
            id: "08707",
            name: "Phường Tích Sơn",
            level: "Phường"
          },
          {
            id: "08710",
            name: "Phường Liên Bảo",
            level: "Phường"
          },
          {
            id: "08713",
            name: "Phường Hội Hợp",
            level: "Phường"
          },
          {
            id: "08716",
            name: "Phường Đống Đa",
            level: "Phường"
          },
          {
            id: "08719",
            name: "Phường Ngô Quyền",
            level: "Phường"
          },
          {
            id: "08722",
            name: "Phường Đồng Tâm",
            level: "Phường"
          },
          {
            id: "08725",
            name: "Xã Định Trung",
            level: "Xã"
          },
          {
            id: "08728",
            name: "Phường Khai Quang",
            level: "Phường"
          },
          {
            id: "08731",
            name: "Xã Thanh Trù",
            level: "Xã"
          }
        ]
      },
      {
        id: "244",
        name: "Thành phố Phúc Yên",
        wards: [
          {
            id: "08734",
            name: "Phường Trưng Trắc",
            level: "Phường"
          },
          {
            id: "08737",
            name: "Phường Hùng Vương",
            level: "Phường"
          },
          {
            id: "08740",
            name: "Phường Trưng Nhị",
            level: "Phường"
          },
          {
            id: "08743",
            name: "Phường Phúc Thắng",
            level: "Phường"
          },
          {
            id: "08746",
            name: "Phường Xuân Hoà",
            level: "Phường"
          },
          {
            id: "08747",
            name: "Phường Đồng Xuân",
            level: "Phường"
          },
          {
            id: "08749",
            name: "Xã Ngọc Thanh",
            level: "Xã"
          },
          {
            id: "08752",
            name: "Xã Cao Minh",
            level: "Xã"
          },
          {
            id: "08755",
            name: "Phường Nam Viêm",
            level: "Phường"
          },
          {
            id: "08758",
            name: "Phường Tiền Châu",
            level: "Phường"
          }
        ]
      },
      {
        id: "246",
        name: "Huyện Lập Thạch",
        wards: [
          {
            id: "08761",
            name: "Thị trấn Lập Thạch",
            level: "Thị trấn"
          },
          {
            id: "08764",
            name: "Xã Quang Sơn",
            level: "Xã"
          },
          {
            id: "08767",
            name: "Xã Ngọc Mỹ",
            level: "Xã"
          },
          {
            id: "08770",
            name: "Xã Hợp Lý",
            level: "Xã"
          },
          {
            id: "08785",
            name: "Xã Bắc Bình",
            level: "Xã"
          },
          {
            id: "08788",
            name: "Xã Thái Hòa",
            level: "Xã"
          },
          {
            id: "08789",
            name: "Thị trấn Hoa Sơn",
            level: "Thị trấn"
          },
          {
            id: "08791",
            name: "Xã Liễn Sơn",
            level: "Xã"
          },
          {
            id: "08794",
            name: "Xã Xuân Hòa",
            level: "Xã"
          },
          {
            id: "08797",
            name: "Xã Vân Trục",
            level: "Xã"
          },
          {
            id: "08812",
            name: "Xã Liên Hòa",
            level: "Xã"
          },
          {
            id: "08815",
            name: "Xã Tử Du",
            level: "Xã"
          },
          {
            id: "08833",
            name: "Xã Bàn Giản",
            level: "Xã"
          },
          {
            id: "08836",
            name: "Xã Xuân Lôi",
            level: "Xã"
          },
          {
            id: "08839",
            name: "Xã Đồng Ích",
            level: "Xã"
          },
          {
            id: "08842",
            name: "Xã Tiên Lữ",
            level: "Xã"
          },
          {
            id: "08845",
            name: "Xã Văn Quán",
            level: "Xã"
          },
          {
            id: "08857",
            name: "Xã Đình Chu",
            level: "Xã"
          },
          {
            id: "08863",
            name: "Xã Triệu Đề",
            level: "Xã"
          },
          {
            id: "08866",
            name: "Xã Sơn Đông",
            level: "Xã"
          }
        ]
      },
      {
        id: "247",
        name: "Huyện Tam Dương",
        wards: [
          {
            id: "08869",
            name: "Thị trấn Hợp Hòa",
            level: "Thị trấn"
          },
          {
            id: "08872",
            name: "Xã Hoàng Hoa",
            level: "Xã"
          },
          {
            id: "08875",
            name: "Xã Đồng Tĩnh",
            level: "Xã"
          },
          {
            id: "08878",
            name: "Xã Kim Long",
            level: "Xã"
          },
          {
            id: "08881",
            name: "Xã Hướng Đạo",
            level: "Xã"
          },
          {
            id: "08884",
            name: "Xã Đạo Tú",
            level: "Xã"
          },
          {
            id: "08887",
            name: "Xã An Hòa",
            level: "Xã"
          },
          {
            id: "08890",
            name: "Xã Thanh Vân",
            level: "Xã"
          },
          {
            id: "08893",
            name: "Xã Duy Phiên",
            level: "Xã"
          },
          {
            id: "08896",
            name: "Xã Hoàng Đan",
            level: "Xã"
          },
          {
            id: "08899",
            name: "Xã Hoàng Lâu",
            level: "Xã"
          },
          {
            id: "08902",
            name: "Xã Vân Hội",
            level: "Xã"
          },
          {
            id: "08905",
            name: "Xã Hợp Thịnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "248",
        name: "Huyện Tam Đảo",
        wards: [
          {
            id: "08908",
            name: "Thị trấn Tam Đảo",
            level: "Thị trấn"
          },
          {
            id: "08911",
            name: "Thị trấn Hợp Châu",
            level: "Thị trấn"
          },
          {
            id: "08914",
            name: "Xã Đạo Trù",
            level: "Xã"
          },
          {
            id: "08917",
            name: "Xã Yên Dương",
            level: "Xã"
          },
          {
            id: "08920",
            name: "Xã Bồ Lý",
            level: "Xã"
          },
          {
            id: "08923",
            name: "Thị trấn Đại Đình",
            level: "Thị trấn"
          },
          {
            id: "08926",
            name: "Xã Tam Quan",
            level: "Xã"
          },
          {
            id: "08929",
            name: "Xã Hồ Sơn",
            level: "Xã"
          },
          {
            id: "08932",
            name: "Xã Minh Quang",
            level: "Xã"
          }
        ]
      },
      {
        id: "249",
        name: "Huyện Bình Xuyên",
        wards: [
          {
            id: "08935",
            name: "Thị trấn Hương Canh",
            level: "Thị trấn"
          },
          {
            id: "08936",
            name: "Thị trấn Gia Khánh",
            level: "Thị trấn"
          },
          {
            id: "08938",
            name: "Xã Trung Mỹ",
            level: "Xã"
          },
          {
            id: "08944",
            name: "Thị trấn Bá Hiến",
            level: "Thị trấn"
          },
          {
            id: "08947",
            name: "Xã Thiện Kế",
            level: "Xã"
          },
          {
            id: "08950",
            name: "Xã Hương Sơn",
            level: "Xã"
          },
          {
            id: "08953",
            name: "Xã Tam Hợp",
            level: "Xã"
          },
          {
            id: "08956",
            name: "Xã Quất Lưu",
            level: "Xã"
          },
          {
            id: "08959",
            name: "Xã Sơn Lôi",
            level: "Xã"
          },
          {
            id: "08962",
            name: "Thị trấn Đạo Đức",
            level: "Thị trấn"
          },
          {
            id: "08965",
            name: "Xã Tân Phong",
            level: "Xã"
          },
          {
            id: "08968",
            name: "Thị trấn Thanh Lãng",
            level: "Thị trấn"
          },
          {
            id: "08971",
            name: "Xã Phú Xuân",
            level: "Xã"
          }
        ]
      },
      {
        id: "251",
        name: "Huyện Yên Lạc",
        wards: [
          {
            id: "09025",
            name: "Thị trấn Yên Lạc",
            level: "Thị trấn"
          },
          {
            id: "09028",
            name: "Xã Đồng Cương",
            level: "Xã"
          },
          {
            id: "09031",
            name: "Xã Đồng Văn",
            level: "Xã"
          },
          {
            id: "09034",
            name: "Xã Bình Định",
            level: "Xã"
          },
          {
            id: "09037",
            name: "Xã Trung Nguyên",
            level: "Xã"
          },
          {
            id: "09040",
            name: "Xã Tề Lỗ",
            level: "Xã"
          },
          {
            id: "09043",
            name: "Xã Tam Hồng",
            level: "Xã"
          },
          {
            id: "09046",
            name: "Xã Yên Đồng",
            level: "Xã"
          },
          {
            id: "09049",
            name: "Xã Văn Tiến",
            level: "Xã"
          },
          {
            id: "09052",
            name: "Xã Nguyệt Đức",
            level: "Xã"
          },
          {
            id: "09055",
            name: "Xã Yên Phương",
            level: "Xã"
          },
          {
            id: "09058",
            name: "Xã Hồng Phương",
            level: "Xã"
          },
          {
            id: "09061",
            name: "Xã Trung Kiên",
            level: "Xã"
          },
          {
            id: "09064",
            name: "Xã Liên Châu",
            level: "Xã"
          },
          {
            id: "09067",
            name: "Xã Đại Tự",
            level: "Xã"
          },
          {
            id: "09070",
            name: "Xã Hồng Châu",
            level: "Xã"
          },
          {
            id: "09073",
            name: "Xã Trung Hà",
            level: "Xã"
          }
        ]
      },
      {
        id: "252",
        name: "Huyện Vĩnh Tường",
        wards: [
          {
            id: "09076",
            name: "Thị trấn Vĩnh Tường",
            level: "Thị trấn"
          },
          {
            id: "09079",
            name: "Xã Kim Xá",
            level: "Xã"
          },
          {
            id: "09082",
            name: "Xã Yên Bình",
            level: "Xã"
          },
          {
            id: "09085",
            name: "Xã Chấn Hưng",
            level: "Xã"
          },
          {
            id: "09088",
            name: "Xã Nghĩa Hưng",
            level: "Xã"
          },
          {
            id: "09091",
            name: "Xã Yên Lập",
            level: "Xã"
          },
          {
            id: "09094",
            name: "Xã Việt Xuân",
            level: "Xã"
          },
          {
            id: "09097",
            name: "Xã Bồ Sao",
            level: "Xã"
          },
          {
            id: "09100",
            name: "Xã Đại Đồng",
            level: "Xã"
          },
          {
            id: "09103",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "09106",
            name: "Xã Lũng Hoà",
            level: "Xã"
          },
          {
            id: "09109",
            name: "Xã Cao Đại",
            level: "Xã"
          },
          {
            id: "09112",
            name: "Thị Trấn Thổ Tang",
            level: "Thị trấn"
          },
          {
            id: "09115",
            name: "Xã Vĩnh Sơn",
            level: "Xã"
          },
          {
            id: "09118",
            name: "Xã Bình Dương",
            level: "Xã"
          },
          {
            id: "09124",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "09127",
            name: "Xã Thượng Trưng",
            level: "Xã"
          },
          {
            id: "09130",
            name: "Xã Vũ Di",
            level: "Xã"
          },
          {
            id: "09133",
            name: "Xã Lý Nhân",
            level: "Xã"
          },
          {
            id: "09136",
            name: "Xã Tuân Chính",
            level: "Xã"
          },
          {
            id: "09139",
            name: "Xã Vân Xuân",
            level: "Xã"
          },
          {
            id: "09142",
            name: "Xã Tam Phúc",
            level: "Xã"
          },
          {
            id: "09145",
            name: "Thị trấn Tứ Trưng",
            level: "Thị trấn"
          },
          {
            id: "09148",
            name: "Xã Ngũ Kiên",
            level: "Xã"
          },
          {
            id: "09151",
            name: "Xã An Tường",
            level: "Xã"
          },
          {
            id: "09154",
            name: "Xã Vĩnh Thịnh",
            level: "Xã"
          },
          {
            id: "09157",
            name: "Xã Phú Đa",
            level: "Xã"
          },
          {
            id: "09160",
            name: "Xã Vĩnh Ninh",
            level: "Xã"
          }
        ]
      },
      {
        id: "253",
        name: "Huyện Sông Lô",
        wards: [
          {
            id: "08773",
            name: "Xã Lãng Công",
            level: "Xã"
          },
          {
            id: "08776",
            name: "Xã Quang Yên",
            level: "Xã"
          },
          {
            id: "08779",
            name: "Xã Bạch Lưu",
            level: "Xã"
          },
          {
            id: "08782",
            name: "Xã Hải Lựu",
            level: "Xã"
          },
          {
            id: "08800",
            name: "Xã Đồng Quế",
            level: "Xã"
          },
          {
            id: "08803",
            name: "Xã Nhân Đạo",
            level: "Xã"
          },
          {
            id: "08806",
            name: "Xã Đôn Nhân",
            level: "Xã"
          },
          {
            id: "08809",
            name: "Xã Phương Khoan",
            level: "Xã"
          },
          {
            id: "08818",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "08821",
            name: "Xã Nhạo Sơn",
            level: "Xã"
          },
          {
            id: "08824",
            name: "Thị trấn Tam Sơn",
            level: "Thị trấn"
          },
          {
            id: "08827",
            name: "Xã Như Thụy",
            level: "Xã"
          },
          {
            id: "08830",
            name: "Xã Yên Thạch",
            level: "Xã"
          },
          {
            id: "08848",
            name: "Xã Đồng Thịnh",
            level: "Xã"
          },
          {
            id: "08851",
            name: "Xã Tứ Yên",
            level: "Xã"
          },
          {
            id: "08854",
            name: "Xã Đức Bác",
            level: "Xã"
          },
          {
            id: "08860",
            name: "Xã Cao Phong",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "27",
    name: "Tỉnh Bắc Ninh",
    districts: [
      {
        id: "256",
        name: "Thành phố Bắc Ninh",
        wards: [
          {
            id: "09163",
            name: "Phường Vũ Ninh",
            level: "Phường"
          },
          {
            id: "09166",
            name: "Phường Đáp Cầu",
            level: "Phường"
          },
          {
            id: "09169",
            name: "Phường Thị Cầu",
            level: "Phường"
          },
          {
            id: "09172",
            name: "Phường Kinh Bắc",
            level: "Phường"
          },
          {
            id: "09175",
            name: "Phường Vệ An",
            level: "Phường"
          },
          {
            id: "09178",
            name: "Phường Tiền An",
            level: "Phường"
          },
          {
            id: "09181",
            name: "Phường Đại Phúc",
            level: "Phường"
          },
          {
            id: "09184",
            name: "Phường Ninh Xá",
            level: "Phường"
          },
          {
            id: "09187",
            name: "Phường Suối Hoa",
            level: "Phường"
          },
          {
            id: "09190",
            name: "Phường Võ Cường",
            level: "Phường"
          },
          {
            id: "09214",
            name: "Phường Hòa Long",
            level: "Phường"
          },
          {
            id: "09226",
            name: "Phường Vạn An",
            level: "Phường"
          },
          {
            id: "09235",
            name: "Phường Khúc Xuyên",
            level: "Phường"
          },
          {
            id: "09244",
            name: "Phường Phong Khê",
            level: "Phường"
          },
          {
            id: "09256",
            name: "Phường Kim Chân",
            level: "Phường"
          },
          {
            id: "09271",
            name: "Phường Vân Dương",
            level: "Phường"
          },
          {
            id: "09286",
            name: "Phường Nam Sơn",
            level: "Phường"
          },
          {
            id: "09325",
            name: "Phường Khắc Niệm",
            level: "Phường"
          },
          {
            id: "09331",
            name: "Phường Hạp Lĩnh",
            level: "Phường"
          }
        ]
      },
      {
        id: "258",
        name: "Huyện Yên Phong",
        wards: [
          {
            id: "09193",
            name: "Thị trấn Chờ",
            level: "Thị trấn"
          },
          {
            id: "09196",
            name: "Xã Dũng Liệt",
            level: "Xã"
          },
          {
            id: "09199",
            name: "Xã Tam Đa",
            level: "Xã"
          },
          {
            id: "09202",
            name: "Xã Tam Giang",
            level: "Xã"
          },
          {
            id: "09205",
            name: "Xã Yên Trung",
            level: "Xã"
          },
          {
            id: "09208",
            name: "Xã Thụy Hòa",
            level: "Xã"
          },
          {
            id: "09211",
            name: "Xã Hòa Tiến",
            level: "Xã"
          },
          {
            id: "09217",
            name: "Xã Đông Tiến",
            level: "Xã"
          },
          {
            id: "09220",
            name: "Xã Yên Phụ",
            level: "Xã"
          },
          {
            id: "09223",
            name: "Xã Trung Nghĩa",
            level: "Xã"
          },
          {
            id: "09229",
            name: "Xã Đông Phong",
            level: "Xã"
          },
          {
            id: "09232",
            name: "Xã Long Châu",
            level: "Xã"
          },
          {
            id: "09238",
            name: "Xã Văn Môn",
            level: "Xã"
          },
          {
            id: "09241",
            name: "Xã Đông Thọ",
            level: "Xã"
          }
        ]
      },
      {
        id: "259",
        name: "Huyện Quế Võ",
        wards: [
          {
            id: "09247",
            name: "Thị trấn Phố Mới",
            level: "Thị trấn"
          },
          {
            id: "09250",
            name: "Xã Việt Thống",
            level: "Xã"
          },
          {
            id: "09253",
            name: "Xã Đại Xuân",
            level: "Xã"
          },
          {
            id: "09259",
            name: "Xã Nhân Hòa",
            level: "Xã"
          },
          {
            id: "09262",
            name: "Xã Bằng An",
            level: "Xã"
          },
          {
            id: "09265",
            name: "Xã Phương Liễu",
            level: "Xã"
          },
          {
            id: "09268",
            name: "Xã Quế Tân",
            level: "Xã"
          },
          {
            id: "09274",
            name: "Xã Phù Lương",
            level: "Xã"
          },
          {
            id: "09277",
            name: "Xã Phù Lãng",
            level: "Xã"
          },
          {
            id: "09280",
            name: "Xã Phượng Mao",
            level: "Xã"
          },
          {
            id: "09283",
            name: "Xã Việt Hùng",
            level: "Xã"
          },
          {
            id: "09289",
            name: "Xã Ngọc Xá",
            level: "Xã"
          },
          {
            id: "09292",
            name: "Xã Châu Phong",
            level: "Xã"
          },
          {
            id: "09295",
            name: "Xã Bồng Lai",
            level: "Xã"
          },
          {
            id: "09298",
            name: "Xã Cách Bi",
            level: "Xã"
          },
          {
            id: "09301",
            name: "Xã Đào Viên",
            level: "Xã"
          },
          {
            id: "09304",
            name: "Xã Yên Giả",
            level: "Xã"
          },
          {
            id: "09307",
            name: "Xã Mộ Đạo",
            level: "Xã"
          },
          {
            id: "09310",
            name: "Xã Đức Long",
            level: "Xã"
          },
          {
            id: "09313",
            name: "Xã Chi Lăng",
            level: "Xã"
          },
          {
            id: "09316",
            name: "Xã Hán Quảng",
            level: "Xã"
          }
        ]
      },
      {
        id: "260",
        name: "Huyện Tiên Du",
        wards: [
          {
            id: "09319",
            name: "Thị trấn Lim",
            level: "Thị trấn"
          },
          {
            id: "09322",
            name: "Xã Phú Lâm",
            level: "Xã"
          },
          {
            id: "09328",
            name: "Xã Nội Duệ",
            level: "Xã"
          },
          {
            id: "09334",
            name: "Xã Liên Bão",
            level: "Xã"
          },
          {
            id: "09337",
            name: "Xã Hiên Vân",
            level: "Xã"
          },
          {
            id: "09340",
            name: "Xã Hoàn Sơn",
            level: "Xã"
          },
          {
            id: "09343",
            name: "Xã Lạc Vệ",
            level: "Xã"
          },
          {
            id: "09346",
            name: "Xã Việt Đoàn",
            level: "Xã"
          },
          {
            id: "09349",
            name: "Xã Phật Tích",
            level: "Xã"
          },
          {
            id: "09352",
            name: "Xã Tân Chi",
            level: "Xã"
          },
          {
            id: "09355",
            name: "Xã Đại Đồng",
            level: "Xã"
          },
          {
            id: "09358",
            name: "Xã Tri Phương",
            level: "Xã"
          },
          {
            id: "09361",
            name: "Xã Minh Đạo",
            level: "Xã"
          },
          {
            id: "09364",
            name: "Xã Cảnh Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "261",
        name: "Thị xã Từ Sơn",
        wards: [
          {
            id: "09367",
            name: "Phường Đông Ngàn",
            level: "Phường"
          },
          {
            id: "09370",
            name: "Xã Tam Sơn",
            level: "Xã"
          },
          {
            id: "09373",
            name: "Xã Hương Mạc",
            level: "Xã"
          },
          {
            id: "09376",
            name: "Xã Tương Giang",
            level: "Xã"
          },
          {
            id: "09379",
            name: "Xã Phù Khê",
            level: "Xã"
          },
          {
            id: "09382",
            name: "Phường Đồng Kỵ",
            level: "Phường"
          },
          {
            id: "09383",
            name: "Phường Trang Hạ",
            level: "Phường"
          },
          {
            id: "09385",
            name: "Phường Đồng Nguyên",
            level: "Phường"
          },
          {
            id: "09388",
            name: "Phường Châu Khê",
            level: "Phường"
          },
          {
            id: "09391",
            name: "Phường Tân Hồng",
            level: "Phường"
          },
          {
            id: "09394",
            name: "Phường Đình Bảng",
            level: "Phường"
          },
          {
            id: "09397",
            name: "Xã Phù Chẩn",
            level: "Xã"
          }
        ]
      },
      {
        id: "262",
        name: "Huyện Thuận Thành",
        wards: [
          {
            id: "09400",
            name: "Thị trấn Hồ",
            level: "Thị trấn"
          },
          {
            id: "09403",
            name: "Xã Hoài Thượng",
            level: "Xã"
          },
          {
            id: "09406",
            name: "Xã Đại Đồng Thành",
            level: "Xã"
          },
          {
            id: "09409",
            name: "Xã Mão Điền",
            level: "Xã"
          },
          {
            id: "09412",
            name: "Xã Song Hồ",
            level: "Xã"
          },
          {
            id: "09415",
            name: "Xã Đình Tổ",
            level: "Xã"
          },
          {
            id: "09418",
            name: "Xã An Bình",
            level: "Xã"
          },
          {
            id: "09421",
            name: "Xã Trí Quả",
            level: "Xã"
          },
          {
            id: "09424",
            name: "Xã Gia Đông",
            level: "Xã"
          },
          {
            id: "09427",
            name: "Xã Thanh Khương",
            level: "Xã"
          },
          {
            id: "09430",
            name: "Xã Trạm Lộ",
            level: "Xã"
          },
          {
            id: "09433",
            name: "Xã Xuân Lâm",
            level: "Xã"
          },
          {
            id: "09436",
            name: "Xã Hà Mãn",
            level: "Xã"
          },
          {
            id: "09439",
            name: "Xã Ngũ Thái",
            level: "Xã"
          },
          {
            id: "09442",
            name: "Xã Nguyệt Đức",
            level: "Xã"
          },
          {
            id: "09445",
            name: "Xã Ninh Xá",
            level: "Xã"
          },
          {
            id: "09448",
            name: "Xã Nghĩa Đạo",
            level: "Xã"
          },
          {
            id: "09451",
            name: "Xã Song Liễu",
            level: "Xã"
          }
        ]
      },
      {
        id: "263",
        name: "Huyện Gia Bình",
        wards: [
          {
            id: "09454",
            name: "Thị trấn Gia Bình",
            level: "Thị trấn"
          },
          {
            id: "09457",
            name: "Xã Vạn Ninh",
            level: "Xã"
          },
          {
            id: "09460",
            name: "Xã Thái Bảo",
            level: "Xã"
          },
          {
            id: "09463",
            name: "Xã Giang Sơn",
            level: "Xã"
          },
          {
            id: "09466",
            name: "Xã Cao Đức",
            level: "Xã"
          },
          {
            id: "09469",
            name: "Xã Đại Lai",
            level: "Xã"
          },
          {
            id: "09472",
            name: "Xã Song Giang",
            level: "Xã"
          },
          {
            id: "09475",
            name: "Xã Bình Dương",
            level: "Xã"
          },
          {
            id: "09478",
            name: "Xã Lãng Ngâm",
            level: "Xã"
          },
          {
            id: "09481",
            name: "Xã Nhân Thắng",
            level: "Xã"
          },
          {
            id: "09484",
            name: "Xã Xuân Lai",
            level: "Xã"
          },
          {
            id: "09487",
            name: "Xã Đông Cứu",
            level: "Xã"
          },
          {
            id: "09490",
            name: "Xã Đại Bái",
            level: "Xã"
          },
          {
            id: "09493",
            name: "Xã Quỳnh Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "264",
        name: "Huyện Lương Tài",
        wards: [
          {
            id: "09496",
            name: "Thị trấn Thứa",
            level: "Thị trấn"
          },
          {
            id: "09499",
            name: "Xã An Thịnh",
            level: "Xã"
          },
          {
            id: "09502",
            name: "Xã Trung Kênh",
            level: "Xã"
          },
          {
            id: "09505",
            name: "Xã Phú Hòa",
            level: "Xã"
          },
          {
            id: "09508",
            name: "Xã Mỹ Hương",
            level: "Xã"
          },
          {
            id: "09511",
            name: "Xã Tân Lãng",
            level: "Xã"
          },
          {
            id: "09514",
            name: "Xã Quảng Phú",
            level: "Xã"
          },
          {
            id: "09517",
            name: "Xã Trừng Xá",
            level: "Xã"
          },
          {
            id: "09520",
            name: "Xã Lai Hạ",
            level: "Xã"
          },
          {
            id: "09523",
            name: "Xã Trung Chính",
            level: "Xã"
          },
          {
            id: "09526",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "09529",
            name: "Xã Bình Định",
            level: "Xã"
          },
          {
            id: "09532",
            name: "Xã Phú Lương",
            level: "Xã"
          },
          {
            id: "09535",
            name: "Xã Lâm Thao",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "30",
    name: "Tỉnh Hải Dương",
    districts: [
      {
        id: "288",
        name: "Thành phố Hải Dương",
        wards: [
          {
            id: "10507",
            name: "Phường Cẩm Thượng",
            level: "Phường"
          },
          {
            id: "10510",
            name: "Phường Bình Hàn",
            level: "Phường"
          },
          {
            id: "10513",
            name: "Phường Ngọc Châu",
            level: "Phường"
          },
          {
            id: "10514",
            name: "Phường Nhị Châu",
            level: "Phường"
          },
          {
            id: "10516",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "10519",
            name: "Phường Nguyễn Trãi",
            level: "Phường"
          },
          {
            id: "10522",
            name: "Phường Phạm Ngũ Lão",
            level: "Phường"
          },
          {
            id: "10525",
            name: "Phường Trần Hưng Đạo",
            level: "Phường"
          },
          {
            id: "10528",
            name: "Phường Trần Phú",
            level: "Phường"
          },
          {
            id: "10531",
            name: "Phường Thanh Bình",
            level: "Phường"
          },
          {
            id: "10532",
            name: "Phường Tân Bình",
            level: "Phường"
          },
          {
            id: "10534",
            name: "Phường Lê Thanh Nghị",
            level: "Phường"
          },
          {
            id: "10537",
            name: "Phường Hải Tân",
            level: "Phường"
          },
          {
            id: "10540",
            name: "Phường Tứ Minh",
            level: "Phường"
          },
          {
            id: "10543",
            name: "Phường Việt Hoà",
            level: "Phường"
          },
          {
            id: "10660",
            name: "Phường Ái Quốc",
            level: "Phường"
          },
          {
            id: "10663",
            name: "Xã An Thượng",
            level: "Xã"
          },
          {
            id: "10672",
            name: "Phường Nam Đồng",
            level: "Phường"
          },
          {
            id: "10822",
            name: "Xã Quyết Thắng",
            level: "Xã"
          },
          {
            id: "10837",
            name: "Xã Tiền Tiến",
            level: "Xã"
          },
          {
            id: "11002",
            name: "Phường Thạch Khôi",
            level: "Phường"
          },
          {
            id: "11005",
            name: "Xã Liên Hồng",
            level: "Xã"
          },
          {
            id: "11011",
            name: "Phường Tân Hưng",
            level: "Phường"
          },
          {
            id: "11017",
            name: "Xã Gia Xuyên",
            level: "Xã"
          },
          {
            id: "11077",
            name: "Xã Ngọc Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "290",
        name: "Thành phố Chí Linh",
        wards: [
          {
            id: "10546",
            name: "Phường Phả Lại",
            level: "Phường"
          },
          {
            id: "10549",
            name: "Phường Sao Đỏ",
            level: "Phường"
          },
          {
            id: "10552",
            name: "Phường Bến Tắm",
            level: "Phường"
          },
          {
            id: "10555",
            name: "Xã Hoàng Hoa Thám",
            level: "Xã"
          },
          {
            id: "10558",
            name: "Xã Bắc An",
            level: "Xã"
          },
          {
            id: "10561",
            name: "Xã Hưng Đạo",
            level: "Xã"
          },
          {
            id: "10564",
            name: "Xã Lê Lợi",
            level: "Xã"
          },
          {
            id: "10567",
            name: "Phường Hoàng Tiến",
            level: "Phường"
          },
          {
            id: "10570",
            name: "Phường Cộng Hoà",
            level: "Phường"
          },
          {
            id: "10573",
            name: "Phường Hoàng Tân",
            level: "Phường"
          },
          {
            id: "10576",
            name: "Phường Cổ Thành",
            level: "Phường"
          },
          {
            id: "10579",
            name: "Phường Văn An",
            level: "Phường"
          },
          {
            id: "10582",
            name: "Phường Chí Minh",
            level: "Phường"
          },
          {
            id: "10585",
            name: "Phường Văn Đức",
            level: "Phường"
          },
          {
            id: "10588",
            name: "Phường Thái Học",
            level: "Phường"
          },
          {
            id: "10591",
            name: "Xã Nhân Huệ",
            level: "Xã"
          },
          {
            id: "10594",
            name: "Phường An Lạc",
            level: "Phường"
          },
          {
            id: "10600",
            name: "Phường Đồng Lạc",
            level: "Phường"
          },
          {
            id: "10603",
            name: "Phường Tân Dân",
            level: "Phường"
          }
        ]
      },
      {
        id: "291",
        name: "Huyện Nam Sách",
        wards: [
          {
            id: "10606",
            name: "Thị trấn Nam Sách",
            level: "Thị trấn"
          },
          {
            id: "10609",
            name: "Xã Nam Hưng",
            level: "Xã"
          },
          {
            id: "10612",
            name: "Xã Nam Tân",
            level: "Xã"
          },
          {
            id: "10615",
            name: "Xã Hợp Tiến",
            level: "Xã"
          },
          {
            id: "10618",
            name: "Xã Hiệp Cát",
            level: "Xã"
          },
          {
            id: "10621",
            name: "Xã Thanh Quang",
            level: "Xã"
          },
          {
            id: "10624",
            name: "Xã Quốc Tuấn",
            level: "Xã"
          },
          {
            id: "10627",
            name: "Xã Nam Chính",
            level: "Xã"
          },
          {
            id: "10630",
            name: "Xã An Bình",
            level: "Xã"
          },
          {
            id: "10633",
            name: "Xã Nam Trung",
            level: "Xã"
          },
          {
            id: "10636",
            name: "Xã An Sơn",
            level: "Xã"
          },
          {
            id: "10639",
            name: "Xã Cộng Hòa",
            level: "Xã"
          },
          {
            id: "10642",
            name: "Xã Thái Tân",
            level: "Xã"
          },
          {
            id: "10645",
            name: "Xã An Lâm",
            level: "Xã"
          },
          {
            id: "10648",
            name: "Xã Phú Điền",
            level: "Xã"
          },
          {
            id: "10651",
            name: "Xã Nam Hồng",
            level: "Xã"
          },
          {
            id: "10654",
            name: "Xã Hồng Phong",
            level: "Xã"
          },
          {
            id: "10657",
            name: "Xã Đồng Lạc",
            level: "Xã"
          },
          {
            id: "10666",
            name: "Xã Minh Tân",
            level: "Xã"
          }
        ]
      },
      {
        id: "292",
        name: "Thị xã Kinh Môn",
        wards: [
          {
            id: "10675",
            name: "Phường An Lưu",
            level: "Phường"
          },
          {
            id: "10678",
            name: "Xã Bạch Đằng",
            level: "Xã"
          },
          {
            id: "10681",
            name: "Phường Thất Hùng",
            level: "Phường"
          },
          {
            id: "10684",
            name: "Xã Lê Ninh",
            level: "Xã"
          },
          {
            id: "10687",
            name: "Xã Hoành Sơn",
            level: "Xã"
          },
          {
            id: "10693",
            name: "Phường Phạm Thái",
            level: "Phường"
          },
          {
            id: "10696",
            name: "Phường Duy Tân",
            level: "Phường"
          },
          {
            id: "10699",
            name: "Phường Tân Dân",
            level: "Phường"
          },
          {
            id: "10702",
            name: "Phường Minh Tân",
            level: "Phường"
          },
          {
            id: "10705",
            name: "Xã Quang Thành",
            level: "Xã"
          },
          {
            id: "10708",
            name: "Xã Hiệp Hòa",
            level: "Xã"
          },
          {
            id: "10714",
            name: "Phường Phú Thứ",
            level: "Phường"
          },
          {
            id: "10717",
            name: "Xã Thăng Long",
            level: "Xã"
          },
          {
            id: "10720",
            name: "Xã Lạc Long",
            level: "Xã"
          },
          {
            id: "10723",
            name: "Phường An Sinh",
            level: "Phường"
          },
          {
            id: "10726",
            name: "Phường Hiệp Sơn",
            level: "Phường"
          },
          {
            id: "10729",
            name: "Xã Thượng Quận",
            level: "Xã"
          },
          {
            id: "10732",
            name: "Phường An Phụ",
            level: "Phường"
          },
          {
            id: "10735",
            name: "Phường Hiệp An",
            level: "Phường"
          },
          {
            id: "10738",
            name: "Phường Long Xuyên",
            level: "Phường"
          },
          {
            id: "10741",
            name: "Phường Thái Thịnh",
            level: "Phường"
          },
          {
            id: "10744",
            name: "Phường Hiến Thành",
            level: "Phường"
          },
          {
            id: "10747",
            name: "Xã Minh Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "293",
        name: "Huyện Kim Thành",
        wards: [
          {
            id: "10750",
            name: "Thị trấn Phú Thái",
            level: "Thị trấn"
          },
          {
            id: "10753",
            name: "Xã Lai Vu",
            level: "Xã"
          },
          {
            id: "10756",
            name: "Xã Cộng Hòa",
            level: "Xã"
          },
          {
            id: "10759",
            name: "Xã Thượng Vũ",
            level: "Xã"
          },
          {
            id: "10762",
            name: "Xã Cổ Dũng",
            level: "Xã"
          },
          {
            id: "10768",
            name: "Xã Tuấn Việt",
            level: "Xã"
          },
          {
            id: "10771",
            name: "Xã Kim Xuyên",
            level: "Xã"
          },
          {
            id: "10774",
            name: "Xã Phúc Thành A",
            level: "Xã"
          },
          {
            id: "10777",
            name: "Xã Ngũ Phúc",
            level: "Xã"
          },
          {
            id: "10780",
            name: "Xã Kim Anh",
            level: "Xã"
          },
          {
            id: "10783",
            name: "Xã Kim Liên",
            level: "Xã"
          },
          {
            id: "10786",
            name: "Xã Kim Tân",
            level: "Xã"
          },
          {
            id: "10792",
            name: "Xã Kim Đính",
            level: "Xã"
          },
          {
            id: "10798",
            name: "Xã Bình Dân",
            level: "Xã"
          },
          {
            id: "10801",
            name: "Xã Tam Kỳ",
            level: "Xã"
          },
          {
            id: "10804",
            name: "Xã Đồng Cẩm",
            level: "Xã"
          },
          {
            id: "10807",
            name: "Xã Liên Hòa",
            level: "Xã"
          },
          {
            id: "10810",
            name: "Xã Đại Đức",
            level: "Xã"
          }
        ]
      },
      {
        id: "294",
        name: "Huyện Thanh Hà",
        wards: [
          {
            id: "10813",
            name: "Thị trấn Thanh Hà",
            level: "Thị trấn"
          },
          {
            id: "10816",
            name: "Xã Hồng Lạc",
            level: "Xã"
          },
          {
            id: "10819",
            name: "Xã Việt Hồng",
            level: "Xã"
          },
          {
            id: "10825",
            name: "Xã Tân Việt",
            level: "Xã"
          },
          {
            id: "10828",
            name: "Xã Cẩm Chế",
            level: "Xã"
          },
          {
            id: "10831",
            name: "Xã Thanh An",
            level: "Xã"
          },
          {
            id: "10834",
            name: "Xã Thanh Lang",
            level: "Xã"
          },
          {
            id: "10840",
            name: "Xã Tân An",
            level: "Xã"
          },
          {
            id: "10843",
            name: "Xã Liên Mạc",
            level: "Xã"
          },
          {
            id: "10846",
            name: "Xã Thanh Hải",
            level: "Xã"
          },
          {
            id: "10849",
            name: "Xã Thanh Khê",
            level: "Xã"
          },
          {
            id: "10852",
            name: "Xã Thanh Xá",
            level: "Xã"
          },
          {
            id: "10855",
            name: "Xã Thanh Xuân",
            level: "Xã"
          },
          {
            id: "10861",
            name: "Xã Thanh Thủy",
            level: "Xã"
          },
          {
            id: "10864",
            name: "Xã An Phượng",
            level: "Xã"
          },
          {
            id: "10867",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "10876",
            name: "Xã Thanh Quang",
            level: "Xã"
          },
          {
            id: "10879",
            name: "Xã Thanh Hồng",
            level: "Xã"
          },
          {
            id: "10882",
            name: "Xã Thanh Cường",
            level: "Xã"
          },
          {
            id: "10885",
            name: "Xã Vĩnh Lập",
            level: "Xã"
          }
        ]
      },
      {
        id: "295",
        name: "Huyện Cẩm Giàng",
        wards: [
          {
            id: "10888",
            name: "Thị trấn Cẩm Giang",
            level: "Thị trấn"
          },
          {
            id: "10891",
            name: "Thị trấn Lai Cách",
            level: "Thị trấn"
          },
          {
            id: "10894",
            name: "Xã Cẩm Hưng",
            level: "Xã"
          },
          {
            id: "10897",
            name: "Xã Cẩm Hoàng",
            level: "Xã"
          },
          {
            id: "10900",
            name: "Xã Cẩm Văn",
            level: "Xã"
          },
          {
            id: "10903",
            name: "Xã Ngọc Liên",
            level: "Xã"
          },
          {
            id: "10906",
            name: "Xã Thạch Lỗi",
            level: "Xã"
          },
          {
            id: "10909",
            name: "Xã Cẩm Vũ",
            level: "Xã"
          },
          {
            id: "10912",
            name: "Xã Đức Chính",
            level: "Xã"
          },
          {
            id: "10918",
            name: "Xã Định Sơn",
            level: "Xã"
          },
          {
            id: "10924",
            name: "Xã Lương Điền",
            level: "Xã"
          },
          {
            id: "10927",
            name: "Xã Cao An",
            level: "Xã"
          },
          {
            id: "10930",
            name: "Xã Tân Trường",
            level: "Xã"
          },
          {
            id: "10933",
            name: "Xã Cẩm Phúc",
            level: "Xã"
          },
          {
            id: "10936",
            name: "Xã Cẩm Điền",
            level: "Xã"
          },
          {
            id: "10939",
            name: "Xã Cẩm Đông",
            level: "Xã"
          },
          {
            id: "10942",
            name: "Xã Cẩm Đoài",
            level: "Xã"
          }
        ]
      },
      {
        id: "296",
        name: "Huyện Bình Giang",
        wards: [
          {
            id: "10945",
            name: "Thị trấn Kẻ Sặt",
            level: "Thị trấn"
          },
          {
            id: "10951",
            name: "Xã Vĩnh Hưng",
            level: "Xã"
          },
          {
            id: "10954",
            name: "Xã Hùng Thắng",
            level: "Xã"
          },
          {
            id: "10960",
            name: "Xã Vĩnh Hồng",
            level: "Xã"
          },
          {
            id: "10963",
            name: "Xã Long Xuyên",
            level: "Xã"
          },
          {
            id: "10966",
            name: "Xã Tân Việt",
            level: "Xã"
          },
          {
            id: "10969",
            name: "Xã Thúc Kháng",
            level: "Xã"
          },
          {
            id: "10972",
            name: "Xã Tân Hồng",
            level: "Xã"
          },
          {
            id: "10975",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "10978",
            name: "Xã Hồng Khê",
            level: "Xã"
          },
          {
            id: "10981",
            name: "Xã Thái Học",
            level: "Xã"
          },
          {
            id: "10984",
            name: "Xã Cổ Bì",
            level: "Xã"
          },
          {
            id: "10987",
            name: "Xã Nhân Quyền",
            level: "Xã"
          },
          {
            id: "10990",
            name: "Xã Thái Dương",
            level: "Xã"
          },
          {
            id: "10993",
            name: "Xã Thái Hòa",
            level: "Xã"
          },
          {
            id: "10996",
            name: "Xã Bình Xuyên",
            level: "Xã"
          }
        ]
      },
      {
        id: "297",
        name: "Huyện Gia Lộc",
        wards: [
          {
            id: "10999",
            name: "Thị trấn Gia Lộc",
            level: "Thị trấn"
          },
          {
            id: "11008",
            name: "Xã Thống Nhất",
            level: "Xã"
          },
          {
            id: "11020",
            name: "Xã Yết Kiêu",
            level: "Xã"
          },
          {
            id: "11029",
            name: "Xã Gia Tân",
            level: "Xã"
          },
          {
            id: "11032",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "11035",
            name: "Xã Gia Khánh",
            level: "Xã"
          },
          {
            id: "11038",
            name: "Xã Gia Lương",
            level: "Xã"
          },
          {
            id: "11041",
            name: "Xã Lê Lợi",
            level: "Xã"
          },
          {
            id: "11044",
            name: "Xã Toàn Thắng",
            level: "Xã"
          },
          {
            id: "11047",
            name: "Xã Hoàng Diệu",
            level: "Xã"
          },
          {
            id: "11050",
            name: "Xã Hồng Hưng",
            level: "Xã"
          },
          {
            id: "11053",
            name: "Xã Phạm Trấn",
            level: "Xã"
          },
          {
            id: "11056",
            name: "Xã Đoàn Thượng",
            level: "Xã"
          },
          {
            id: "11059",
            name: "Xã Thống Kênh",
            level: "Xã"
          },
          {
            id: "11062",
            name: "Xã Quang Minh",
            level: "Xã"
          },
          {
            id: "11065",
            name: "Xã Đồng Quang",
            level: "Xã"
          },
          {
            id: "11068",
            name: "Xã Nhật Tân",
            level: "Xã"
          },
          {
            id: "11071",
            name: "Xã Đức Xương",
            level: "Xã"
          }
        ]
      },
      {
        id: "298",
        name: "Huyện Tứ Kỳ",
        wards: [
          {
            id: "11074",
            name: "Thị trấn Tứ Kỳ",
            level: "Thị trấn"
          },
          {
            id: "11083",
            name: "Xã Đại Sơn",
            level: "Xã"
          },
          {
            id: "11086",
            name: "Xã Hưng Đạo",
            level: "Xã"
          },
          {
            id: "11089",
            name: "Xã Ngọc Kỳ",
            level: "Xã"
          },
          {
            id: "11092",
            name: "Xã Bình Lăng",
            level: "Xã"
          },
          {
            id: "11095",
            name: "Xã Chí Minh",
            level: "Xã"
          },
          {
            id: "11098",
            name: "Xã Tái Sơn",
            level: "Xã"
          },
          {
            id: "11101",
            name: "Xã Quang Phục",
            level: "Xã"
          },
          {
            id: "11110",
            name: "Xã Dân Chủ",
            level: "Xã"
          },
          {
            id: "11113",
            name: "Xã Tân Kỳ",
            level: "Xã"
          },
          {
            id: "11116",
            name: "Xã Quang Khải",
            level: "Xã"
          },
          {
            id: "11119",
            name: "Xã Đại Hợp",
            level: "Xã"
          },
          {
            id: "11122",
            name: "Xã Quảng Nghiệp",
            level: "Xã"
          },
          {
            id: "11125",
            name: "Xã An Thanh",
            level: "Xã"
          },
          {
            id: "11128",
            name: "Xã Minh Đức",
            level: "Xã"
          },
          {
            id: "11131",
            name: "Xã Văn Tố",
            level: "Xã"
          },
          {
            id: "11134",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "11137",
            name: "Xã Phượng Kỳ",
            level: "Xã"
          },
          {
            id: "11140",
            name: "Xã Cộng Lạc",
            level: "Xã"
          },
          {
            id: "11143",
            name: "Xã Tiên Động",
            level: "Xã"
          },
          {
            id: "11146",
            name: "Xã Nguyên Giáp",
            level: "Xã"
          },
          {
            id: "11149",
            name: "Xã Hà Kỳ",
            level: "Xã"
          },
          {
            id: "11152",
            name: "Xã Hà Thanh",
            level: "Xã"
          }
        ]
      },
      {
        id: "299",
        name: "Huyện Ninh Giang",
        wards: [
          {
            id: "11155",
            name: "Thị trấn Ninh Giang",
            level: "Thị trấn"
          },
          {
            id: "11161",
            name: "Xã Ứng Hoè",
            level: "Xã"
          },
          {
            id: "11164",
            name: "Xã Nghĩa An",
            level: "Xã"
          },
          {
            id: "11167",
            name: "Xã Hồng Đức",
            level: "Xã"
          },
          {
            id: "11173",
            name: "Xã An Đức",
            level: "Xã"
          },
          {
            id: "11176",
            name: "Xã Vạn Phúc",
            level: "Xã"
          },
          {
            id: "11179",
            name: "Xã Tân Hương",
            level: "Xã"
          },
          {
            id: "11185",
            name: "Xã Vĩnh Hòa",
            level: "Xã"
          },
          {
            id: "11188",
            name: "Xã Đông Xuyên",
            level: "Xã"
          },
          {
            id: "11197",
            name: "Xã Tân Phong",
            level: "Xã"
          },
          {
            id: "11200",
            name: "Xã Ninh Hải",
            level: "Xã"
          },
          {
            id: "11203",
            name: "Xã Đồng Tâm",
            level: "Xã"
          },
          {
            id: "11206",
            name: "Xã Tân Quang",
            level: "Xã"
          },
          {
            id: "11209",
            name: "Xã Kiến Quốc",
            level: "Xã"
          },
          {
            id: "11215",
            name: "Xã Hồng Dụ",
            level: "Xã"
          },
          {
            id: "11218",
            name: "Xã Văn Hội",
            level: "Xã"
          },
          {
            id: "11224",
            name: "Xã Hồng Phong",
            level: "Xã"
          },
          {
            id: "11227",
            name: "Xã Hiệp Lực",
            level: "Xã"
          },
          {
            id: "11230",
            name: "Xã Hồng Phúc",
            level: "Xã"
          },
          {
            id: "11233",
            name: "Xã Hưng Long",
            level: "Xã"
          }
        ]
      },
      {
        id: "300",
        name: "Huyện Thanh Miện",
        wards: [
          {
            id: "11239",
            name: "Thị trấn Thanh Miện",
            level: "Thị trấn"
          },
          {
            id: "11242",
            name: "Xã Thanh Tùng",
            level: "Xã"
          },
          {
            id: "11245",
            name: "Xã Phạm Kha",
            level: "Xã"
          },
          {
            id: "11248",
            name: "Xã Ngô Quyền",
            level: "Xã"
          },
          {
            id: "11251",
            name: "Xã Đoàn Tùng",
            level: "Xã"
          },
          {
            id: "11254",
            name: "Xã Hồng Quang",
            level: "Xã"
          },
          {
            id: "11257",
            name: "Xã Tân Trào",
            level: "Xã"
          },
          {
            id: "11260",
            name: "Xã Lam Sơn",
            level: "Xã"
          },
          {
            id: "11263",
            name: "Xã Đoàn Kết",
            level: "Xã"
          },
          {
            id: "11266",
            name: "Xã Lê Hồng",
            level: "Xã"
          },
          {
            id: "11269",
            name: "Xã Tứ Cường",
            level: "Xã"
          },
          {
            id: "11275",
            name: "Xã Ngũ Hùng",
            level: "Xã"
          },
          {
            id: "11278",
            name: "Xã Cao Thắng",
            level: "Xã"
          },
          {
            id: "11281",
            name: "Xã Chi Lăng Bắc",
            level: "Xã"
          },
          {
            id: "11284",
            name: "Xã Chi Lăng Nam",
            level: "Xã"
          },
          {
            id: "11287",
            name: "Xã Thanh Giang",
            level: "Xã"
          },
          {
            id: "11293",
            name: "Xã Hồng Phong",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "31",
    name: "Thành phố Hải Phòng",
    districts: [
      {
        id: "303",
        name: "Quận Hồng Bàng",
        wards: [
          {
            id: "11296",
            name: "Phường Quán Toan",
            level: "Phường"
          },
          {
            id: "11299",
            name: "Phường Hùng Vương",
            level: "Phường"
          },
          {
            id: "11302",
            name: "Phường Sở Dầu",
            level: "Phường"
          },
          {
            id: "11305",
            name: "Phường Thượng Lý",
            level: "Phường"
          },
          {
            id: "11308",
            name: "Phường Hạ Lý",
            level: "Phường"
          },
          {
            id: "11311",
            name: "Phường Minh Khai",
            level: "Phường"
          },
          {
            id: "11314",
            name: "Phường Trại Chuối",
            level: "Phường"
          },
          {
            id: "11320",
            name: "Phường Hoàng Văn Thụ",
            level: "Phường"
          },
          {
            id: "11323",
            name: "Phường Phan Bội Châu",
            level: "Phường"
          }
        ]
      },
      {
        id: "304",
        name: "Quận Ngô Quyền",
        wards: [
          {
            id: "11329",
            name: "Phường Máy Chai",
            level: "Phường"
          },
          {
            id: "11332",
            name: "Phường Máy Tơ",
            level: "Phường"
          },
          {
            id: "11335",
            name: "Phường Vạn Mỹ",
            level: "Phường"
          },
          {
            id: "11338",
            name: "Phường Cầu Tre",
            level: "Phường"
          },
          {
            id: "11341",
            name: "Phường Lạc Viên",
            level: "Phường"
          },
          {
            id: "11347",
            name: "Phường Gia Viên",
            level: "Phường"
          },
          {
            id: "11350",
            name: "Phường Đông Khê",
            level: "Phường"
          },
          {
            id: "11353",
            name: "Phường Cầu Đất",
            level: "Phường"
          },
          {
            id: "11356",
            name: "Phường Lê Lợi",
            level: "Phường"
          },
          {
            id: "11359",
            name: "Phường Đằng Giang",
            level: "Phường"
          },
          {
            id: "11362",
            name: "Phường Lạch Tray",
            level: "Phường"
          },
          {
            id: "11365",
            name: "Phường Đổng Quốc Bình",
            level: "Phường"
          }
        ]
      },
      {
        id: "305",
        name: "Quận Lê Chân",
        wards: [
          {
            id: "11368",
            name: "Phường Cát Dài",
            level: "Phường"
          },
          {
            id: "11371",
            name: "Phường An Biên",
            level: "Phường"
          },
          {
            id: "11374",
            name: "Phường Lam Sơn",
            level: "Phường"
          },
          {
            id: "11377",
            name: "Phường An Dương",
            level: "Phường"
          },
          {
            id: "11380",
            name: "Phường Trần Nguyên Hãn",
            level: "Phường"
          },
          {
            id: "11383",
            name: "Phường Hồ Nam",
            level: "Phường"
          },
          {
            id: "11386",
            name: "Phường Trại Cau",
            level: "Phường"
          },
          {
            id: "11389",
            name: "Phường Dư Hàng",
            level: "Phường"
          },
          {
            id: "11392",
            name: "Phường Hàng Kênh",
            level: "Phường"
          },
          {
            id: "11395",
            name: "Phường Đông Hải",
            level: "Phường"
          },
          {
            id: "11398",
            name: "Phường Niệm Nghĩa",
            level: "Phường"
          },
          {
            id: "11401",
            name: "Phường Nghĩa Xá",
            level: "Phường"
          },
          {
            id: "11404",
            name: "Phường Dư Hàng Kênh",
            level: "Phường"
          },
          {
            id: "11405",
            name: "Phường Kênh Dương",
            level: "Phường"
          },
          {
            id: "11407",
            name: "Phường Vĩnh Niệm",
            level: "Phường"
          }
        ]
      },
      {
        id: "306",
        name: "Quận Hải An",
        wards: [
          {
            id: "11410",
            name: "Phường Đông Hải 1",
            level: "Phường"
          },
          {
            id: "11411",
            name: "Phường Đông Hải 2",
            level: "Phường"
          },
          {
            id: "11413",
            name: "Phường Đằng Lâm",
            level: "Phường"
          },
          {
            id: "11414",
            name: "Phường Thành Tô",
            level: "Phường"
          },
          {
            id: "11416",
            name: "Phường Đằng Hải",
            level: "Phường"
          },
          {
            id: "11419",
            name: "Phường Nam Hải",
            level: "Phường"
          },
          {
            id: "11422",
            name: "Phường Cát Bi",
            level: "Phường"
          },
          {
            id: "11425",
            name: "Phường Tràng Cát",
            level: "Phường"
          }
        ]
      },
      {
        id: "307",
        name: "Quận Kiến An",
        wards: [
          {
            id: "11428",
            name: "Phường Quán Trữ",
            level: "Phường"
          },
          {
            id: "11429",
            name: "Phường Lãm Hà",
            level: "Phường"
          },
          {
            id: "11431",
            name: "Phường Đồng Hoà",
            level: "Phường"
          },
          {
            id: "11434",
            name: "Phường Bắc Sơn",
            level: "Phường"
          },
          {
            id: "11437",
            name: "Phường Nam Sơn",
            level: "Phường"
          },
          {
            id: "11440",
            name: "Phường Ngọc Sơn",
            level: "Phường"
          },
          {
            id: "11443",
            name: "Phường Trần Thành Ngọ",
            level: "Phường"
          },
          {
            id: "11446",
            name: "Phường Văn Đẩu",
            level: "Phường"
          },
          {
            id: "11449",
            name: "Phường Phù Liễn",
            level: "Phường"
          },
          {
            id: "11452",
            name: "Phường Tràng Minh",
            level: "Phường"
          }
        ]
      },
      {
        id: "308",
        name: "Quận Đồ Sơn",
        wards: [
          {
            id: "11455",
            name: "Phường Ngọc Xuyên",
            level: "Phường"
          },
          {
            id: "11458",
            name: "Phường Hải Sơn",
            level: "Phường"
          },
          {
            id: "11461",
            name: "Phường Vạn Hương",
            level: "Phường"
          },
          {
            id: "11465",
            name: "Phường Minh Đức",
            level: "Phường"
          },
          {
            id: "11467",
            name: "Phường Bàng La",
            level: "Phường"
          },
          {
            id: "11737",
            name: "Phường Hợp Đức",
            level: "Phường"
          }
        ]
      },
      {
        id: "309",
        name: "Quận Dương Kinh",
        wards: [
          {
            id: "11683",
            name: "Phường Đa Phúc",
            level: "Phường"
          },
          {
            id: "11686",
            name: "Phường Hưng Đạo",
            level: "Phường"
          },
          {
            id: "11689",
            name: "Phường Anh Dũng",
            level: "Phường"
          },
          {
            id: "11692",
            name: "Phường Hải Thành",
            level: "Phường"
          },
          {
            id: "11707",
            name: "Phường Hoà Nghĩa",
            level: "Phường"
          },
          {
            id: "11740",
            name: "Phường Tân Thành",
            level: "Phường"
          }
        ]
      },
      {
        id: "311",
        name: "Huyện Thuỷ Nguyên",
        wards: [
          {
            id: "11470",
            name: "Thị trấn Núi Đèo",
            level: "Thị trấn"
          },
          {
            id: "11473",
            name: "Thị trấn Minh Đức",
            level: "Thị trấn"
          },
          {
            id: "11476",
            name: "Xã Lại Xuân",
            level: "Xã"
          },
          {
            id: "11479",
            name: "Xã An Sơn",
            level: "Xã"
          },
          {
            id: "11482",
            name: "Xã Kỳ Sơn",
            level: "Xã"
          },
          {
            id: "11485",
            name: "Xã Liên Khê",
            level: "Xã"
          },
          {
            id: "11488",
            name: "Xã Lưu Kiếm",
            level: "Xã"
          },
          {
            id: "11491",
            name: "Xã Lưu Kỳ",
            level: "Xã"
          },
          {
            id: "11494",
            name: "Xã Gia Minh",
            level: "Xã"
          },
          {
            id: "11497",
            name: "Xã Gia Đức",
            level: "Xã"
          },
          {
            id: "11500",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "11503",
            name: "Xã Phù Ninh",
            level: "Xã"
          },
          {
            id: "11506",
            name: "Xã Quảng Thanh",
            level: "Xã"
          },
          {
            id: "11509",
            name: "Xã Chính Mỹ",
            level: "Xã"
          },
          {
            id: "11512",
            name: "Xã Kênh Giang",
            level: "Xã"
          },
          {
            id: "11515",
            name: "Xã Hợp Thành",
            level: "Xã"
          },
          {
            id: "11518",
            name: "Xã Cao Nhân",
            level: "Xã"
          },
          {
            id: "11521",
            name: "Xã Mỹ Đồng",
            level: "Xã"
          },
          {
            id: "11524",
            name: "Xã Đông Sơn",
            level: "Xã"
          },
          {
            id: "11527",
            name: "Xã Hoà Bình",
            level: "Xã"
          },
          {
            id: "11530",
            name: "Xã Trung Hà",
            level: "Xã"
          },
          {
            id: "11533",
            name: "Xã An Lư",
            level: "Xã"
          },
          {
            id: "11536",
            name: "Xã Thuỷ Triều",
            level: "Xã"
          },
          {
            id: "11539",
            name: "Xã Ngũ Lão",
            level: "Xã"
          },
          {
            id: "11542",
            name: "Xã Phục Lễ",
            level: "Xã"
          },
          {
            id: "11545",
            name: "Xã Tam Hưng",
            level: "Xã"
          },
          {
            id: "11548",
            name: "Xã Phả Lễ",
            level: "Xã"
          },
          {
            id: "11551",
            name: "Xã Lập Lễ",
            level: "Xã"
          },
          {
            id: "11554",
            name: "Xã Kiền Bái",
            level: "Xã"
          },
          {
            id: "11557",
            name: "Xã Thiên Hương",
            level: "Xã"
          },
          {
            id: "11560",
            name: "Xã Thuỷ Sơn",
            level: "Xã"
          },
          {
            id: "11563",
            name: "Xã Thuỷ Đường",
            level: "Xã"
          },
          {
            id: "11566",
            name: "Xã Hoàng Động",
            level: "Xã"
          },
          {
            id: "11569",
            name: "Xã Lâm Động",
            level: "Xã"
          },
          {
            id: "11572",
            name: "Xã Hoa Động",
            level: "Xã"
          },
          {
            id: "11575",
            name: "Xã Tân Dương",
            level: "Xã"
          },
          {
            id: "11578",
            name: "Xã Dương Quan",
            level: "Xã"
          }
        ]
      },
      {
        id: "312",
        name: "Huyện An Dương",
        wards: [
          {
            id: "11581",
            name: "Thị trấn An Dương",
            level: "Thị trấn"
          },
          {
            id: "11584",
            name: "Xã Lê Thiện",
            level: "Xã"
          },
          {
            id: "11587",
            name: "Xã Đại Bản",
            level: "Xã"
          },
          {
            id: "11590",
            name: "Xã An Hoà",
            level: "Xã"
          },
          {
            id: "11593",
            name: "Xã Hồng Phong",
            level: "Xã"
          },
          {
            id: "11596",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "11599",
            name: "Xã An Hưng",
            level: "Xã"
          },
          {
            id: "11602",
            name: "Xã An Hồng",
            level: "Xã"
          },
          {
            id: "11605",
            name: "Xã Bắc Sơn",
            level: "Xã"
          },
          {
            id: "11608",
            name: "Xã Nam Sơn",
            level: "Xã"
          },
          {
            id: "11611",
            name: "Xã Lê Lợi",
            level: "Xã"
          },
          {
            id: "11614",
            name: "Xã Đặng Cương",
            level: "Xã"
          },
          {
            id: "11617",
            name: "Xã Đồng Thái",
            level: "Xã"
          },
          {
            id: "11620",
            name: "Xã Quốc Tuấn",
            level: "Xã"
          },
          {
            id: "11623",
            name: "Xã An Đồng",
            level: "Xã"
          },
          {
            id: "11626",
            name: "Xã Hồng Thái",
            level: "Xã"
          }
        ]
      },
      {
        id: "313",
        name: "Huyện An Lão",
        wards: [
          {
            id: "11629",
            name: "Thị trấn An Lão",
            level: "Thị trấn"
          },
          {
            id: "11632",
            name: "Xã Bát Trang",
            level: "Xã"
          },
          {
            id: "11635",
            name: "Xã Trường Thọ",
            level: "Xã"
          },
          {
            id: "11638",
            name: "Xã Trường Thành",
            level: "Xã"
          },
          {
            id: "11641",
            name: "Xã An Tiến",
            level: "Xã"
          },
          {
            id: "11644",
            name: "Xã Quang Hưng",
            level: "Xã"
          },
          {
            id: "11647",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "11650",
            name: "Xã Quốc Tuấn",
            level: "Xã"
          },
          {
            id: "11653",
            name: "Xã An Thắng",
            level: "Xã"
          },
          {
            id: "11656",
            name: "Thị trấn Trường Sơn",
            level: "Thị trấn"
          },
          {
            id: "11659",
            name: "Xã Tân Dân",
            level: "Xã"
          },
          {
            id: "11662",
            name: "Xã Thái Sơn",
            level: "Xã"
          },
          {
            id: "11665",
            name: "Xã Tân Viên",
            level: "Xã"
          },
          {
            id: "11668",
            name: "Xã Mỹ Đức",
            level: "Xã"
          },
          {
            id: "11671",
            name: "Xã Chiến Thắng",
            level: "Xã"
          },
          {
            id: "11674",
            name: "Xã An Thọ",
            level: "Xã"
          },
          {
            id: "11677",
            name: "Xã An Thái",
            level: "Xã"
          }
        ]
      },
      {
        id: "314",
        name: "Huyện Kiến Thuỵ",
        wards: [
          {
            id: "11680",
            name: "Thị trấn Núi Đối",
            level: "Thị trấn"
          },
          {
            id: "11695",
            name: "Xã Đông Phương",
            level: "Xã"
          },
          {
            id: "11698",
            name: "Xã Thuận Thiên",
            level: "Xã"
          },
          {
            id: "11701",
            name: "Xã Hữu Bằng",
            level: "Xã"
          },
          {
            id: "11704",
            name: "Xã Đại Đồng",
            level: "Xã"
          },
          {
            id: "11710",
            name: "Xã Ngũ Phúc",
            level: "Xã"
          },
          {
            id: "11713",
            name: "Xã Kiến Quốc",
            level: "Xã"
          },
          {
            id: "11716",
            name: "Xã Du Lễ",
            level: "Xã"
          },
          {
            id: "11719",
            name: "Xã Thuỵ Hương",
            level: "Xã"
          },
          {
            id: "11722",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "11725",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "11728",
            name: "Xã Đại Hà",
            level: "Xã"
          },
          {
            id: "11731",
            name: "Xã Ngũ Đoan",
            level: "Xã"
          },
          {
            id: "11734",
            name: "Xã Tân Phong",
            level: "Xã"
          },
          {
            id: "11743",
            name: "Xã Tân Trào",
            level: "Xã"
          },
          {
            id: "11746",
            name: "Xã Đoàn Xá",
            level: "Xã"
          },
          {
            id: "11749",
            name: "Xã Tú Sơn",
            level: "Xã"
          },
          {
            id: "11752",
            name: "Xã Đại Hợp",
            level: "Xã"
          }
        ]
      },
      {
        id: "315",
        name: "Huyện Tiên Lãng",
        wards: [
          {
            id: "11755",
            name: "Thị trấn Tiên Lãng",
            level: "Thị trấn"
          },
          {
            id: "11758",
            name: "Xã Đại Thắng",
            level: "Xã"
          },
          {
            id: "11761",
            name: "Xã Tiên Cường",
            level: "Xã"
          },
          {
            id: "11764",
            name: "Xã Tự Cường",
            level: "Xã"
          },
          {
            id: "11770",
            name: "Xã Quyết Tiến",
            level: "Xã"
          },
          {
            id: "11773",
            name: "Xã Khởi Nghĩa",
            level: "Xã"
          },
          {
            id: "11776",
            name: "Xã Tiên Thanh",
            level: "Xã"
          },
          {
            id: "11779",
            name: "Xã Cấp Tiến",
            level: "Xã"
          },
          {
            id: "11782",
            name: "Xã Kiến Thiết",
            level: "Xã"
          },
          {
            id: "11785",
            name: "Xã Đoàn Lập",
            level: "Xã"
          },
          {
            id: "11788",
            name: "Xã Bạch Đằng",
            level: "Xã"
          },
          {
            id: "11791",
            name: "Xã Quang Phục",
            level: "Xã"
          },
          {
            id: "11794",
            name: "Xã Toàn Thắng",
            level: "Xã"
          },
          {
            id: "11797",
            name: "Xã Tiên Thắng",
            level: "Xã"
          },
          {
            id: "11800",
            name: "Xã Tiên Minh",
            level: "Xã"
          },
          {
            id: "11803",
            name: "Xã Bắc Hưng",
            level: "Xã"
          },
          {
            id: "11806",
            name: "Xã Nam Hưng",
            level: "Xã"
          },
          {
            id: "11809",
            name: "Xã Hùng Thắng",
            level: "Xã"
          },
          {
            id: "11812",
            name: "Xã Tây Hưng",
            level: "Xã"
          },
          {
            id: "11815",
            name: "Xã Đông Hưng",
            level: "Xã"
          },
          {
            id: "11821",
            name: "Xã Vinh Quang",
            level: "Xã"
          }
        ]
      },
      {
        id: "316",
        name: "Huyện Vĩnh Bảo",
        wards: [
          {
            id: "11824",
            name: "Thị trấn Vĩnh Bảo",
            level: "Thị trấn"
          },
          {
            id: "11827",
            name: "Xã Dũng Tiến",
            level: "Xã"
          },
          {
            id: "11830",
            name: "Xã Giang Biên",
            level: "Xã"
          },
          {
            id: "11833",
            name: "Xã Thắng Thuỷ",
            level: "Xã"
          },
          {
            id: "11836",
            name: "Xã Trung Lập",
            level: "Xã"
          },
          {
            id: "11839",
            name: "Xã Việt Tiến",
            level: "Xã"
          },
          {
            id: "11842",
            name: "Xã Vĩnh An",
            level: "Xã"
          },
          {
            id: "11845",
            name: "Xã Vĩnh Long",
            level: "Xã"
          },
          {
            id: "11848",
            name: "Xã Hiệp Hoà",
            level: "Xã"
          },
          {
            id: "11851",
            name: "Xã Hùng Tiến",
            level: "Xã"
          },
          {
            id: "11854",
            name: "Xã An Hoà",
            level: "Xã"
          },
          {
            id: "11857",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "11860",
            name: "Xã Tân Liên",
            level: "Xã"
          },
          {
            id: "11863",
            name: "Xã Nhân Hoà",
            level: "Xã"
          },
          {
            id: "11866",
            name: "Xã Tam Đa",
            level: "Xã"
          },
          {
            id: "11869",
            name: "Xã Hưng Nhân",
            level: "Xã"
          },
          {
            id: "11872",
            name: "Xã Vinh Quang",
            level: "Xã"
          },
          {
            id: "11875",
            name: "Xã Đồng Minh",
            level: "Xã"
          },
          {
            id: "11878",
            name: "Xã Thanh Lương",
            level: "Xã"
          },
          {
            id: "11881",
            name: "Xã Liên Am",
            level: "Xã"
          },
          {
            id: "11884",
            name: "Xã Lý Học",
            level: "Xã"
          },
          {
            id: "11887",
            name: "Xã Tam Cường",
            level: "Xã"
          },
          {
            id: "11890",
            name: "Xã Hoà Bình",
            level: "Xã"
          },
          {
            id: "11893",
            name: "Xã Tiền Phong",
            level: "Xã"
          },
          {
            id: "11896",
            name: "Xã Vĩnh Phong",
            level: "Xã"
          },
          {
            id: "11899",
            name: "Xã Cộng Hiền",
            level: "Xã"
          },
          {
            id: "11902",
            name: "Xã Cao Minh",
            level: "Xã"
          },
          {
            id: "11905",
            name: "Xã Cổ Am",
            level: "Xã"
          },
          {
            id: "11908",
            name: "Xã Vĩnh Tiến",
            level: "Xã"
          },
          {
            id: "11911",
            name: "Xã Trấn Dương",
            level: "Xã"
          }
        ]
      },
      {
        id: "317",
        name: "Huyện Cát Hải",
        wards: [
          {
            id: "11914",
            name: "Thị trấn Cát Bà",
            level: "Thị trấn"
          },
          {
            id: "11917",
            name: "Thị trấn Cát Hải",
            level: "Thị trấn"
          },
          {
            id: "11920",
            name: "Xã Nghĩa Lộ",
            level: "Xã"
          },
          {
            id: "11923",
            name: "Xã Đồng Bài",
            level: "Xã"
          },
          {
            id: "11926",
            name: "Xã Hoàng Châu",
            level: "Xã"
          },
          {
            id: "11929",
            name: "Xã Văn Phong",
            level: "Xã"
          },
          {
            id: "11932",
            name: "Xã Phù Long",
            level: "Xã"
          },
          {
            id: "11935",
            name: "Xã Gia Luận",
            level: "Xã"
          },
          {
            id: "11938",
            name: "Xã Hiền Hào",
            level: "Xã"
          },
          {
            id: "11941",
            name: "Xã Trân Châu",
            level: "Xã"
          },
          {
            id: "11944",
            name: "Xã Việt Hải",
            level: "Xã"
          },
          {
            id: "11947",
            name: "Xã Xuân Đám",
            level: "Xã"
          }
        ]
      },
      {
        id: "318",
        name: "Huyện Bạch Long Vĩ",
        wards: [
          {
            level: "Huyện"
          }
        ]
      }
    ]
  },
  {
    id: "33",
    name: "Tỉnh Hưng Yên",
    districts: [
      {
        id: "323",
        name: "Thành phố Hưng Yên",
        wards: [
          {
            id: "11950",
            name: "Phường Lam Sơn",
            level: "Phường"
          },
          {
            id: "11953",
            name: "Phường Hiến Nam",
            level: "Phường"
          },
          {
            id: "11956",
            name: "Phường An Tảo",
            level: "Phường"
          },
          {
            id: "11959",
            name: "Phường Lê Lợi",
            level: "Phường"
          },
          {
            id: "11962",
            name: "Phường Minh Khai",
            level: "Phường"
          },
          {
            id: "11965",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "11968",
            name: "Phường Hồng Châu",
            level: "Phường"
          },
          {
            id: "11971",
            name: "Xã Trung Nghĩa",
            level: "Xã"
          },
          {
            id: "11974",
            name: "Xã Liên Phương",
            level: "Xã"
          },
          {
            id: "11977",
            name: "Xã Hồng Nam",
            level: "Xã"
          },
          {
            id: "11980",
            name: "Xã Quảng Châu",
            level: "Xã"
          },
          {
            id: "11983",
            name: "Xã Bảo Khê",
            level: "Xã"
          },
          {
            id: "12331",
            name: "Xã Phú Cường",
            level: "Xã"
          },
          {
            id: "12334",
            name: "Xã Hùng Cường",
            level: "Xã"
          },
          {
            id: "12382",
            name: "Xã Phương Chiểu",
            level: "Xã"
          },
          {
            id: "12385",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "12388",
            name: "Xã Hoàng Hanh",
            level: "Xã"
          }
        ]
      },
      {
        id: "325",
        name: "Huyện Văn Lâm",
        wards: [
          {
            id: "11986",
            name: "Thị trấn Như Quỳnh",
            level: "Thị trấn"
          },
          {
            id: "11989",
            name: "Xã Lạc Đạo",
            level: "Xã"
          },
          {
            id: "11992",
            name: "Xã Chỉ Đạo",
            level: "Xã"
          },
          {
            id: "11995",
            name: "Xã Đại Đồng",
            level: "Xã"
          },
          {
            id: "11998",
            name: "Xã Việt Hưng",
            level: "Xã"
          },
          {
            id: "12001",
            name: "Xã Tân Quang",
            level: "Xã"
          },
          {
            id: "12004",
            name: "Xã Đình Dù",
            level: "Xã"
          },
          {
            id: "12007",
            name: "Xã Minh Hải",
            level: "Xã"
          },
          {
            id: "12010",
            name: "Xã Lương Tài",
            level: "Xã"
          },
          {
            id: "12013",
            name: "Xã Trưng Trắc",
            level: "Xã"
          },
          {
            id: "12016",
            name: "Xã Lạc Hồng",
            level: "Xã"
          }
        ]
      },
      {
        id: "326",
        name: "Huyện Văn Giang",
        wards: [
          {
            id: "12019",
            name: "Thị trấn Văn Giang",
            level: "Thị trấn"
          },
          {
            id: "12022",
            name: "Xã Xuân Quan",
            level: "Xã"
          },
          {
            id: "12025",
            name: "Xã Cửu Cao",
            level: "Xã"
          },
          {
            id: "12028",
            name: "Xã Phụng Công",
            level: "Xã"
          },
          {
            id: "12031",
            name: "Xã Nghĩa Trụ",
            level: "Xã"
          },
          {
            id: "12034",
            name: "Xã Long Hưng",
            level: "Xã"
          },
          {
            id: "12037",
            name: "Xã Vĩnh Khúc",
            level: "Xã"
          },
          {
            id: "12040",
            name: "Xã Liên Nghĩa",
            level: "Xã"
          },
          {
            id: "12043",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "12046",
            name: "Xã Thắng Lợi",
            level: "Xã"
          },
          {
            id: "12049",
            name: "Xã Mễ Sở",
            level: "Xã"
          }
        ]
      },
      {
        id: "327",
        name: "Huyện Yên Mỹ",
        wards: [
          {
            id: "12052",
            name: "Thị trấn Yên Mỹ",
            level: "Thị trấn"
          },
          {
            id: "12055",
            name: "Xã Giai Phạm",
            level: "Xã"
          },
          {
            id: "12058",
            name: "Xã Nghĩa Hiệp",
            level: "Xã"
          },
          {
            id: "12061",
            name: "Xã Đồng Than",
            level: "Xã"
          },
          {
            id: "12064",
            name: "Xã Ngọc Long",
            level: "Xã"
          },
          {
            id: "12067",
            name: "Xã Liêu Xá",
            level: "Xã"
          },
          {
            id: "12070",
            name: "Xã Hoàn Long",
            level: "Xã"
          },
          {
            id: "12073",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "12076",
            name: "Xã Thanh Long",
            level: "Xã"
          },
          {
            id: "12079",
            name: "Xã Yên Phú",
            level: "Xã"
          },
          {
            id: "12082",
            name: "Xã Việt Cường",
            level: "Xã"
          },
          {
            id: "12085",
            name: "Xã Trung Hòa",
            level: "Xã"
          },
          {
            id: "12088",
            name: "Xã Yên Hòa",
            level: "Xã"
          },
          {
            id: "12091",
            name: "Xã Minh Châu",
            level: "Xã"
          },
          {
            id: "12094",
            name: "Xã Trung Hưng",
            level: "Xã"
          },
          {
            id: "12097",
            name: "Xã Lý Thường Kiệt",
            level: "Xã"
          },
          {
            id: "12100",
            name: "Xã Tân Việt",
            level: "Xã"
          }
        ]
      },
      {
        id: "328",
        name: "Thị xã Mỹ Hào",
        wards: [
          {
            id: "12103",
            name: "Phường Bần Yên Nhân",
            level: "Phường"
          },
          {
            id: "12106",
            name: "Phường Phan Đình Phùng",
            level: "Phường"
          },
          {
            id: "12109",
            name: "Xã Cẩm Xá",
            level: "Xã"
          },
          {
            id: "12112",
            name: "Xã Dương Quang",
            level: "Xã"
          },
          {
            id: "12115",
            name: "Xã Hòa Phong",
            level: "Xã"
          },
          {
            id: "12118",
            name: "Phường Nhân Hòa",
            level: "Phường"
          },
          {
            id: "12121",
            name: "Phường Dị Sử",
            level: "Phường"
          },
          {
            id: "12124",
            name: "Phường Bạch Sam",
            level: "Phường"
          },
          {
            id: "12127",
            name: "Phường Minh Đức",
            level: "Phường"
          },
          {
            id: "12130",
            name: "Phường Phùng Chí Kiên",
            level: "Phường"
          },
          {
            id: "12133",
            name: "Xã Xuân Dục",
            level: "Xã"
          },
          {
            id: "12136",
            name: "Xã Ngọc Lâm",
            level: "Xã"
          },
          {
            id: "12139",
            name: "Xã Hưng Long",
            level: "Xã"
          }
        ]
      },
      {
        id: "329",
        name: "Huyện Ân Thi",
        wards: [
          {
            id: "12142",
            name: "Thị trấn Ân Thi",
            level: "Thị trấn"
          },
          {
            id: "12145",
            name: "Xã Phù Ủng",
            level: "Xã"
          },
          {
            id: "12148",
            name: "Xã Bắc Sơn",
            level: "Xã"
          },
          {
            id: "12151",
            name: "Xã Bãi Sậy",
            level: "Xã"
          },
          {
            id: "12154",
            name: "Xã Đào Dương",
            level: "Xã"
          },
          {
            id: "12157",
            name: "Xã Tân Phúc",
            level: "Xã"
          },
          {
            id: "12160",
            name: "Xã Vân Du",
            level: "Xã"
          },
          {
            id: "12163",
            name: "Xã Quang Vinh",
            level: "Xã"
          },
          {
            id: "12166",
            name: "Xã Xuân Trúc",
            level: "Xã"
          },
          {
            id: "12169",
            name: "Xã Hoàng Hoa Thám",
            level: "Xã"
          },
          {
            id: "12172",
            name: "Xã Quảng Lãng",
            level: "Xã"
          },
          {
            id: "12175",
            name: "Xã Văn Nhuệ",
            level: "Xã"
          },
          {
            id: "12178",
            name: "Xã Đặng Lễ",
            level: "Xã"
          },
          {
            id: "12181",
            name: "Xã Cẩm Ninh",
            level: "Xã"
          },
          {
            id: "12184",
            name: "Xã Nguyễn Trãi",
            level: "Xã"
          },
          {
            id: "12187",
            name: "Xã Đa Lộc",
            level: "Xã"
          },
          {
            id: "12190",
            name: "Xã Hồ Tùng Mậu",
            level: "Xã"
          },
          {
            id: "12193",
            name: "Xã Tiền Phong",
            level: "Xã"
          },
          {
            id: "12196",
            name: "Xã Hồng Vân",
            level: "Xã"
          },
          {
            id: "12199",
            name: "Xã Hồng Quang",
            level: "Xã"
          },
          {
            id: "12202",
            name: "Xã Hạ Lễ",
            level: "Xã"
          }
        ]
      },
      {
        id: "330",
        name: "Huyện Khoái Châu",
        wards: [
          {
            id: "12205",
            name: "Thị trấn Khoái Châu",
            level: "Thị trấn"
          },
          {
            id: "12208",
            name: "Xã Đông Tảo",
            level: "Xã"
          },
          {
            id: "12211",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "12214",
            name: "Xã Dạ Trạch",
            level: "Xã"
          },
          {
            id: "12217",
            name: "Xã Hàm Tử",
            level: "Xã"
          },
          {
            id: "12220",
            name: "Xã Ông Đình",
            level: "Xã"
          },
          {
            id: "12223",
            name: "Xã Tân Dân",
            level: "Xã"
          },
          {
            id: "12226",
            name: "Xã Tứ Dân",
            level: "Xã"
          },
          {
            id: "12229",
            name: "Xã An Vĩ",
            level: "Xã"
          },
          {
            id: "12232",
            name: "Xã Đông Kết",
            level: "Xã"
          },
          {
            id: "12235",
            name: "Xã Bình Kiều",
            level: "Xã"
          },
          {
            id: "12238",
            name: "Xã Dân Tiến",
            level: "Xã"
          },
          {
            id: "12241",
            name: "Xã Đồng Tiến",
            level: "Xã"
          },
          {
            id: "12244",
            name: "Xã Hồng Tiến",
            level: "Xã"
          },
          {
            id: "12247",
            name: "Xã Tân Châu",
            level: "Xã"
          },
          {
            id: "12250",
            name: "Xã Liên Khê",
            level: "Xã"
          },
          {
            id: "12253",
            name: "Xã Phùng Hưng",
            level: "Xã"
          },
          {
            id: "12256",
            name: "Xã Việt Hòa",
            level: "Xã"
          },
          {
            id: "12259",
            name: "Xã Đông Ninh",
            level: "Xã"
          },
          {
            id: "12262",
            name: "Xã Đại Tập",
            level: "Xã"
          },
          {
            id: "12265",
            name: "Xã Chí Tân",
            level: "Xã"
          },
          {
            id: "12268",
            name: "Xã Đại Hưng",
            level: "Xã"
          },
          {
            id: "12271",
            name: "Xã Thuần Hưng",
            level: "Xã"
          },
          {
            id: "12274",
            name: "Xã Thành Công",
            level: "Xã"
          },
          {
            id: "12277",
            name: "Xã Nhuế Dương",
            level: "Xã"
          }
        ]
      },
      {
        id: "331",
        name: "Huyện Kim Động",
        wards: [
          {
            id: "12280",
            name: "Thị trấn Lương Bằng",
            level: "Thị trấn"
          },
          {
            id: "12283",
            name: "Xã Nghĩa Dân",
            level: "Xã"
          },
          {
            id: "12286",
            name: "Xã Toàn Thắng",
            level: "Xã"
          },
          {
            id: "12289",
            name: "Xã Vĩnh Xá",
            level: "Xã"
          },
          {
            id: "12292",
            name: "Xã Phạm Ngũ Lão",
            level: "Xã"
          },
          {
            id: "12295",
            name: "Xã Thọ Vinh",
            level: "Xã"
          },
          {
            id: "12298",
            name: "Xã Đồng Thanh",
            level: "Xã"
          },
          {
            id: "12301",
            name: "Xã Song Mai",
            level: "Xã"
          },
          {
            id: "12304",
            name: "Xã Chính Nghĩa",
            level: "Xã"
          },
          {
            id: "12307",
            name: "Xã Nhân La",
            level: "Xã"
          },
          {
            id: "12310",
            name: "Xã Phú Thịnh",
            level: "Xã"
          },
          {
            id: "12313",
            name: "Xã Mai Động",
            level: "Xã"
          },
          {
            id: "12316",
            name: "Xã Đức Hợp",
            level: "Xã"
          },
          {
            id: "12319",
            name: "Xã Hùng An",
            level: "Xã"
          },
          {
            id: "12322",
            name: "Xã Ngọc Thanh",
            level: "Xã"
          },
          {
            id: "12325",
            name: "Xã Vũ Xá",
            level: "Xã"
          },
          {
            id: "12328",
            name: "Xã Hiệp Cường",
            level: "Xã"
          }
        ]
      },
      {
        id: "332",
        name: "Huyện Tiên Lữ",
        wards: [
          {
            id: "12337",
            name: "Thị trấn Vương",
            level: "Thị trấn"
          },
          {
            id: "12340",
            name: "Xã Hưng Đạo",
            level: "Xã"
          },
          {
            id: "12343",
            name: "Xã Ngô Quyền",
            level: "Xã"
          },
          {
            id: "12346",
            name: "Xã Nhật Tân",
            level: "Xã"
          },
          {
            id: "12349",
            name: "Xã Dị Chế",
            level: "Xã"
          },
          {
            id: "12352",
            name: "Xã Lệ Xá",
            level: "Xã"
          },
          {
            id: "12355",
            name: "Xã An Viên",
            level: "Xã"
          },
          {
            id: "12358",
            name: "Xã Đức Thắng",
            level: "Xã"
          },
          {
            id: "12361",
            name: "Xã Trung Dũng",
            level: "Xã"
          },
          {
            id: "12364",
            name: "Xã Hải Triều",
            level: "Xã"
          },
          {
            id: "12367",
            name: "Xã Thủ Sỹ",
            level: "Xã"
          },
          {
            id: "12370",
            name: "Xã Thiện Phiến",
            level: "Xã"
          },
          {
            id: "12373",
            name: "Xã Thụy Lôi",
            level: "Xã"
          },
          {
            id: "12376",
            name: "Xã Cương Chính",
            level: "Xã"
          },
          {
            id: "12379",
            name: "Xã Minh Phượng",
            level: "Xã"
          }
        ]
      },
      {
        id: "333",
        name: "Huyện Phù Cừ",
        wards: [
          {
            id: "12391",
            name: "Thị trấn Trần Cao",
            level: "Thị trấn"
          },
          {
            id: "12394",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "12397",
            name: "Xã Phan Sào Nam",
            level: "Xã"
          },
          {
            id: "12400",
            name: "Xã Quang Hưng",
            level: "Xã"
          },
          {
            id: "12403",
            name: "Xã Minh Hoàng",
            level: "Xã"
          },
          {
            id: "12406",
            name: "Xã Đoàn Đào",
            level: "Xã"
          },
          {
            id: "12409",
            name: "Xã Tống Phan",
            level: "Xã"
          },
          {
            id: "12412",
            name: "Xã Đình Cao",
            level: "Xã"
          },
          {
            id: "12415",
            name: "Xã Nhật Quang",
            level: "Xã"
          },
          {
            id: "12418",
            name: "Xã Tiền Tiến",
            level: "Xã"
          },
          {
            id: "12421",
            name: "Xã Tam Đa",
            level: "Xã"
          },
          {
            id: "12424",
            name: "Xã Minh Tiến",
            level: "Xã"
          },
          {
            id: "12427",
            name: "Xã Nguyên Hòa",
            level: "Xã"
          },
          {
            id: "12430",
            name: "Xã Tống Trân",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "34",
    name: "Tỉnh Thái Bình",
    districts: [
      {
        id: "336",
        name: "Thành phố Thái Bình",
        wards: [
          {
            id: "12433",
            name: "Phường Lê Hồng Phong",
            level: "Phường"
          },
          {
            id: "12436",
            name: "Phường Bồ Xuyên",
            level: "Phường"
          },
          {
            id: "12439",
            name: "Phường Đề Thám",
            level: "Phường"
          },
          {
            id: "12442",
            name: "Phường Kỳ Bá",
            level: "Phường"
          },
          {
            id: "12445",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "12448",
            name: "Phường Phú Khánh",
            level: "Phường"
          },
          {
            id: "12451",
            name: "Phường Tiền Phong",
            level: "Phường"
          },
          {
            id: "12452",
            name: "Phường Trần Hưng Đạo",
            level: "Phường"
          },
          {
            id: "12454",
            name: "Phường Trần Lãm",
            level: "Phường"
          },
          {
            id: "12457",
            name: "Xã Đông Hòa",
            level: "Xã"
          },
          {
            id: "12460",
            name: "Phường Hoàng Diệu",
            level: "Phường"
          },
          {
            id: "12463",
            name: "Xã Phú Xuân",
            level: "Xã"
          },
          {
            id: "12466",
            name: "Xã Vũ Phúc",
            level: "Xã"
          },
          {
            id: "12469",
            name: "Xã Vũ Chính",
            level: "Xã"
          },
          {
            id: "12817",
            name: "Xã Đông Mỹ",
            level: "Xã"
          },
          {
            id: "12820",
            name: "Xã Đông Thọ",
            level: "Xã"
          },
          {
            id: "13084",
            name: "Xã Vũ Đông",
            level: "Xã"
          },
          {
            id: "13108",
            name: "Xã Vũ Lạc",
            level: "Xã"
          },
          {
            id: "13225",
            name: "Xã Tân Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "338",
        name: "Huyện Quỳnh Phụ",
        wards: [
          {
            id: "12472",
            name: "Thị trấn Quỳnh Côi",
            level: "Thị trấn"
          },
          {
            id: "12475",
            name: "Xã An Khê",
            level: "Xã"
          },
          {
            id: "12478",
            name: "Xã An Đồng",
            level: "Xã"
          },
          {
            id: "12481",
            name: "Xã Quỳnh Hoa",
            level: "Xã"
          },
          {
            id: "12484",
            name: "Xã Quỳnh Lâm",
            level: "Xã"
          },
          {
            id: "12487",
            name: "Xã Quỳnh Thọ",
            level: "Xã"
          },
          {
            id: "12490",
            name: "Xã An Hiệp",
            level: "Xã"
          },
          {
            id: "12493",
            name: "Xã Quỳnh Hoàng",
            level: "Xã"
          },
          {
            id: "12496",
            name: "Xã Quỳnh Giao",
            level: "Xã"
          },
          {
            id: "12499",
            name: "Xã An Thái",
            level: "Xã"
          },
          {
            id: "12502",
            name: "Xã An Cầu",
            level: "Xã"
          },
          {
            id: "12505",
            name: "Xã Quỳnh Hồng",
            level: "Xã"
          },
          {
            id: "12508",
            name: "Xã Quỳnh Khê",
            level: "Xã"
          },
          {
            id: "12511",
            name: "Xã Quỳnh Minh",
            level: "Xã"
          },
          {
            id: "12514",
            name: "Xã An Ninh",
            level: "Xã"
          },
          {
            id: "12517",
            name: "Xã Quỳnh Ngọc",
            level: "Xã"
          },
          {
            id: "12520",
            name: "Xã Quỳnh Hải",
            level: "Xã"
          },
          {
            id: "12523",
            name: "Thị trấn An Bài",
            level: "Thị trấn"
          },
          {
            id: "12526",
            name: "Xã An Ấp",
            level: "Xã"
          },
          {
            id: "12529",
            name: "Xã Quỳnh Hội",
            level: "Xã"
          },
          {
            id: "12532",
            name: "Xã Châu Sơn",
            level: "Xã"
          },
          {
            id: "12535",
            name: "Xã Quỳnh Mỹ",
            level: "Xã"
          },
          {
            id: "12538",
            name: "Xã An Quí",
            level: "Xã"
          },
          {
            id: "12541",
            name: "Xã An Thanh",
            level: "Xã"
          },
          {
            id: "12547",
            name: "Xã An Vũ",
            level: "Xã"
          },
          {
            id: "12550",
            name: "Xã An Lễ",
            level: "Xã"
          },
          {
            id: "12553",
            name: "Xã Quỳnh Hưng",
            level: "Xã"
          },
          {
            id: "12556",
            name: "Xã Quỳnh Bảo",
            level: "Xã"
          },
          {
            id: "12559",
            name: "Xã An Mỹ",
            level: "Xã"
          },
          {
            id: "12562",
            name: "Xã Quỳnh Nguyên",
            level: "Xã"
          },
          {
            id: "12565",
            name: "Xã An Vinh",
            level: "Xã"
          },
          {
            id: "12568",
            name: "Xã Quỳnh Xá",
            level: "Xã"
          },
          {
            id: "12571",
            name: "Xã An Dục",
            level: "Xã"
          },
          {
            id: "12574",
            name: "Xã Đông Hải",
            level: "Xã"
          },
          {
            id: "12577",
            name: "Xã Quỳnh Trang",
            level: "Xã"
          },
          {
            id: "12580",
            name: "Xã An Tràng",
            level: "Xã"
          },
          {
            id: "12583",
            name: "Xã Đồng Tiến",
            level: "Xã"
          }
        ]
      },
      {
        id: "339",
        name: "Huyện Hưng Hà",
        wards: [
          {
            id: "12586",
            name: "Thị trấn Hưng Hà",
            level: "Thị trấn"
          },
          {
            id: "12589",
            name: "Xã Điệp Nông",
            level: "Xã"
          },
          {
            id: "12592",
            name: "Xã Tân Lễ",
            level: "Xã"
          },
          {
            id: "12595",
            name: "Xã Cộng Hòa",
            level: "Xã"
          },
          {
            id: "12598",
            name: "Xã Dân Chủ",
            level: "Xã"
          },
          {
            id: "12601",
            name: "Xã Canh Tân",
            level: "Xã"
          },
          {
            id: "12604",
            name: "Xã Hòa Tiến",
            level: "Xã"
          },
          {
            id: "12607",
            name: "Xã Hùng Dũng",
            level: "Xã"
          },
          {
            id: "12610",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "12613",
            name: "Thị trấn Hưng Nhân",
            level: "Thị trấn"
          },
          {
            id: "12616",
            name: "Xã Đoan Hùng",
            level: "Xã"
          },
          {
            id: "12619",
            name: "Xã Duyên Hải",
            level: "Xã"
          },
          {
            id: "12622",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "12625",
            name: "Xã Văn Cẩm",
            level: "Xã"
          },
          {
            id: "12628",
            name: "Xã Bắc Sơn",
            level: "Xã"
          },
          {
            id: "12631",
            name: "Xã Đông Đô",
            level: "Xã"
          },
          {
            id: "12634",
            name: "Xã Phúc Khánh",
            level: "Xã"
          },
          {
            id: "12637",
            name: "Xã Liên Hiệp",
            level: "Xã"
          },
          {
            id: "12640",
            name: "Xã Tây Đô",
            level: "Xã"
          },
          {
            id: "12643",
            name: "Xã Thống Nhất",
            level: "Xã"
          },
          {
            id: "12646",
            name: "Xã Tiến Đức",
            level: "Xã"
          },
          {
            id: "12649",
            name: "Xã Thái Hưng",
            level: "Xã"
          },
          {
            id: "12652",
            name: "Xã Thái Phương",
            level: "Xã"
          },
          {
            id: "12655",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "12656",
            name: "Xã Chi Lăng",
            level: "Xã"
          },
          {
            id: "12658",
            name: "Xã Minh Khai",
            level: "Xã"
          },
          {
            id: "12661",
            name: "Xã Hồng An",
            level: "Xã"
          },
          {
            id: "12664",
            name: "Xã Kim Chung",
            level: "Xã"
          },
          {
            id: "12667",
            name: "Xã Hồng Lĩnh",
            level: "Xã"
          },
          {
            id: "12670",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "12673",
            name: "Xã Văn Lang",
            level: "Xã"
          },
          {
            id: "12676",
            name: "Xã Độc Lập",
            level: "Xã"
          },
          {
            id: "12679",
            name: "Xã Chí Hòa",
            level: "Xã"
          },
          {
            id: "12682",
            name: "Xã Minh Hòa",
            level: "Xã"
          },
          {
            id: "12685",
            name: "Xã Hồng Minh",
            level: "Xã"
          }
        ]
      },
      {
        id: "340",
        name: "Huyện Đông Hưng",
        wards: [
          {
            id: "12688",
            name: "Thị trấn Đông Hưng",
            level: "Thị trấn"
          },
          {
            id: "12691",
            name: "Xã Đô Lương",
            level: "Xã"
          },
          {
            id: "12694",
            name: "Xã Đông Phương",
            level: "Xã"
          },
          {
            id: "12697",
            name: "Xã Liên Giang",
            level: "Xã"
          },
          {
            id: "12700",
            name: "Xã An Châu",
            level: "Xã"
          },
          {
            id: "12703",
            name: "Xã Đông Sơn",
            level: "Xã"
          },
          {
            id: "12706",
            name: "Xã Đông Cường",
            level: "Xã"
          },
          {
            id: "12709",
            name: "Xã Phú Lương",
            level: "Xã"
          },
          {
            id: "12712",
            name: "Xã Mê Linh",
            level: "Xã"
          },
          {
            id: "12715",
            name: "Xã Lô Giang",
            level: "Xã"
          },
          {
            id: "12718",
            name: "Xã Đông La",
            level: "Xã"
          },
          {
            id: "12721",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "12724",
            name: "Xã Đông Xá",
            level: "Xã"
          },
          {
            id: "12727",
            name: "Xã Chương Dương",
            level: "Xã"
          },
          {
            id: "12730",
            name: "Xã Nguyên Xá",
            level: "Xã"
          },
          {
            id: "12733",
            name: "Xã Phong Châu",
            level: "Xã"
          },
          {
            id: "12736",
            name: "Xã Hợp Tiến",
            level: "Xã"
          },
          {
            id: "12739",
            name: "Xã Hồng Việt",
            level: "Xã"
          },
          {
            id: "12745",
            name: "Xã Hà Giang",
            level: "Xã"
          },
          {
            id: "12748",
            name: "Xã Đông Kinh",
            level: "Xã"
          },
          {
            id: "12751",
            name: "Xã Đông Hợp",
            level: "Xã"
          },
          {
            id: "12754",
            name: "Xã Thăng Long",
            level: "Xã"
          },
          {
            id: "12757",
            name: "Xã Đông Các",
            level: "Xã"
          },
          {
            id: "12760",
            name: "Xã Phú Châu",
            level: "Xã"
          },
          {
            id: "12763",
            name: "Xã Liên Hoa",
            level: "Xã"
          },
          {
            id: "12769",
            name: "Xã Đông Tân",
            level: "Xã"
          },
          {
            id: "12772",
            name: "Xã Đông Vinh",
            level: "Xã"
          },
          {
            id: "12775",
            name: "Xã Đông Động",
            level: "Xã"
          },
          {
            id: "12778",
            name: "Xã Hồng Bạch",
            level: "Xã"
          },
          {
            id: "12784",
            name: "Xã Trọng Quan",
            level: "Xã"
          },
          {
            id: "12790",
            name: "Xã Hồng Giang",
            level: "Xã"
          },
          {
            id: "12793",
            name: "Xã Đông Quan",
            level: "Xã"
          },
          {
            id: "12796",
            name: "Xã Đông Quang",
            level: "Xã"
          },
          {
            id: "12799",
            name: "Xã Đông Xuân",
            level: "Xã"
          },
          {
            id: "12802",
            name: "Xã Đông Á",
            level: "Xã"
          },
          {
            id: "12808",
            name: "Xã Đông Hoàng",
            level: "Xã"
          },
          {
            id: "12811",
            name: "Xã Đông Dương",
            level: "Xã"
          },
          {
            id: "12823",
            name: "Xã Minh Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "341",
        name: "Huyện Thái Thụy",
        wards: [
          {
            id: "12826",
            name: "Thị trấn Diêm Điền",
            level: "Thị trấn"
          },
          {
            id: "12832",
            name: "Xã Thụy Trường",
            level: "Xã"
          },
          {
            id: "12841",
            name: "Xã Hồng Dũng",
            level: "Xã"
          },
          {
            id: "12844",
            name: "Xã Thụy Quỳnh",
            level: "Xã"
          },
          {
            id: "12847",
            name: "Xã An Tân",
            level: "Xã"
          },
          {
            id: "12850",
            name: "Xã Thụy Ninh",
            level: "Xã"
          },
          {
            id: "12853",
            name: "Xã Thụy Hưng",
            level: "Xã"
          },
          {
            id: "12856",
            name: "Xã Thụy Việt",
            level: "Xã"
          },
          {
            id: "12859",
            name: "Xã Thụy Văn",
            level: "Xã"
          },
          {
            id: "12862",
            name: "Xã Thụy Xuân",
            level: "Xã"
          },
          {
            id: "12865",
            name: "Xã Dương Phúc",
            level: "Xã"
          },
          {
            id: "12868",
            name: "Xã Thụy Trình",
            level: "Xã"
          },
          {
            id: "12871",
            name: "Xã Thụy Bình",
            level: "Xã"
          },
          {
            id: "12874",
            name: "Xã Thụy Chính",
            level: "Xã"
          },
          {
            id: "12877",
            name: "Xã Thụy Dân",
            level: "Xã"
          },
          {
            id: "12880",
            name: "Xã Thụy Hải",
            level: "Xã"
          },
          {
            id: "12889",
            name: "Xã Thụy Liên",
            level: "Xã"
          },
          {
            id: "12892",
            name: "Xã Thụy Duyên",
            level: "Xã"
          },
          {
            id: "12898",
            name: "Xã Thụy Thanh",
            level: "Xã"
          },
          {
            id: "12901",
            name: "Xã Thụy Sơn",
            level: "Xã"
          },
          {
            id: "12904",
            name: "Xã Thụy Phong",
            level: "Xã"
          },
          {
            id: "12907",
            name: "Xã Thái Thượng",
            level: "Xã"
          },
          {
            id: "12910",
            name: "Xã Thái Nguyên",
            level: "Xã"
          },
          {
            id: "12916",
            name: "Xã Dương Hồng  Thủy",
            level: "Xã"
          },
          {
            id: "12919",
            name: "Xã Thái Giang",
            level: "Xã"
          },
          {
            id: "12922",
            name: "Xã Hòa An",
            level: "Xã"
          },
          {
            id: "12925",
            name: "Xã Sơn Hà",
            level: "Xã"
          },
          {
            id: "12934",
            name: "Xã Thái Phúc",
            level: "Xã"
          },
          {
            id: "12937",
            name: "Xã Thái Hưng",
            level: "Xã"
          },
          {
            id: "12940",
            name: "Xã Thái Đô",
            level: "Xã"
          },
          {
            id: "12943",
            name: "Xã Thái Xuyên",
            level: "Xã"
          },
          {
            id: "12949",
            name: "Xã  Mỹ Lộc",
            level: "Xã"
          },
          {
            id: "12958",
            name: "Xã Tân Học",
            level: "Xã"
          },
          {
            id: "12961",
            name: "Xã Thái Thịnh",
            level: "Xã"
          },
          {
            id: "12964",
            name: "Xã Thuần Thành",
            level: "Xã"
          },
          {
            id: "12967",
            name: "Xã Thái Thọ",
            level: "Xã"
          }
        ]
      },
      {
        id: "342",
        name: "Huyện Tiền Hải",
        wards: [
          {
            id: "12970",
            name: "Thị trấn Tiền Hải",
            level: "Thị trấn"
          },
          {
            id: "12976",
            name: "Xã Đông Trà",
            level: "Xã"
          },
          {
            id: "12979",
            name: "Xã Đông Long",
            level: "Xã"
          },
          {
            id: "12982",
            name: "Xã Đông Quí",
            level: "Xã"
          },
          {
            id: "12985",
            name: "Xã Vũ Lăng",
            level: "Xã"
          },
          {
            id: "12988",
            name: "Xã Đông Xuyên",
            level: "Xã"
          },
          {
            id: "12991",
            name: "Xã Tây Lương",
            level: "Xã"
          },
          {
            id: "12994",
            name: "Xã Tây Ninh",
            level: "Xã"
          },
          {
            id: "12997",
            name: "Xã Đông Trung",
            level: "Xã"
          },
          {
            id: "13000",
            name: "Xã Đông Hoàng",
            level: "Xã"
          },
          {
            id: "13003",
            name: "Xã Đông Minh",
            level: "Xã"
          },
          {
            id: "13009",
            name: "Xã Đông Phong",
            level: "Xã"
          },
          {
            id: "13012",
            name: "Xã An Ninh",
            level: "Xã"
          },
          {
            id: "13018",
            name: "Xã Đông Cơ",
            level: "Xã"
          },
          {
            id: "13021",
            name: "Xã Tây Giang",
            level: "Xã"
          },
          {
            id: "13024",
            name: "Xã Đông Lâm",
            level: "Xã"
          },
          {
            id: "13027",
            name: "Xã Phương Công",
            level: "Xã"
          },
          {
            id: "13030",
            name: "Xã Tây Phong",
            level: "Xã"
          },
          {
            id: "13033",
            name: "Xã Tây Tiến",
            level: "Xã"
          },
          {
            id: "13036",
            name: "Xã Nam Cường",
            level: "Xã"
          },
          {
            id: "13039",
            name: "Xã Vân Trường",
            level: "Xã"
          },
          {
            id: "13042",
            name: "Xã Nam Thắng",
            level: "Xã"
          },
          {
            id: "13045",
            name: "Xã Nam Chính",
            level: "Xã"
          },
          {
            id: "13048",
            name: "Xã Bắc Hải",
            level: "Xã"
          },
          {
            id: "13051",
            name: "Xã Nam Thịnh",
            level: "Xã"
          },
          {
            id: "13054",
            name: "Xã Nam Hà",
            level: "Xã"
          },
          {
            id: "13057",
            name: "Xã Nam Thanh",
            level: "Xã"
          },
          {
            id: "13060",
            name: "Xã Nam Trung",
            level: "Xã"
          },
          {
            id: "13063",
            name: "Xã Nam Hồng",
            level: "Xã"
          },
          {
            id: "13066",
            name: "Xã Nam Hưng",
            level: "Xã"
          },
          {
            id: "13069",
            name: "Xã Nam Hải",
            level: "Xã"
          },
          {
            id: "13072",
            name: "Xã Nam Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "343",
        name: "Huyện Kiến Xương",
        wards: [
          {
            id: "13075",
            name: "Thị trấn Kiến Xương",
            level: "Thị trấn"
          },
          {
            id: "13078",
            name: "Xã Trà Giang",
            level: "Xã"
          },
          {
            id: "13081",
            name: "Xã Quốc Tuấn",
            level: "Xã"
          },
          {
            id: "13087",
            name: "Xã An Bình",
            level: "Xã"
          },
          {
            id: "13090",
            name: "Xã Tây Sơn",
            level: "Xã"
          },
          {
            id: "13093",
            name: "Xã Hồng Thái",
            level: "Xã"
          },
          {
            id: "13096",
            name: "Xã Bình Nguyên",
            level: "Xã"
          },
          {
            id: "13102",
            name: "Xã Lê Lợi",
            level: "Xã"
          },
          {
            id: "13111",
            name: "Xã Vũ Lễ",
            level: "Xã"
          },
          {
            id: "13114",
            name: "Xã Thanh Tân",
            level: "Xã"
          },
          {
            id: "13117",
            name: "Xã Thượng Hiền",
            level: "Xã"
          },
          {
            id: "13120",
            name: "Xã Nam Cao",
            level: "Xã"
          },
          {
            id: "13123",
            name: "Xã Đình Phùng",
            level: "Xã"
          },
          {
            id: "13126",
            name: "Xã Vũ Ninh",
            level: "Xã"
          },
          {
            id: "13129",
            name: "Xã Vũ An",
            level: "Xã"
          },
          {
            id: "13132",
            name: "Xã Quang Lịch",
            level: "Xã"
          },
          {
            id: "13135",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "13138",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "13141",
            name: "Xã Vũ Quí",
            level: "Xã"
          },
          {
            id: "13144",
            name: "Xã Quang Bình",
            level: "Xã"
          },
          {
            id: "13150",
            name: "Xã Vũ Trung",
            level: "Xã"
          },
          {
            id: "13153",
            name: "Xã Vũ Thắng",
            level: "Xã"
          },
          {
            id: "13156",
            name: "Xã Vũ Công",
            level: "Xã"
          },
          {
            id: "13159",
            name: "Xã Vũ Hòa",
            level: "Xã"
          },
          {
            id: "13162",
            name: "Xã Quang Minh",
            level: "Xã"
          },
          {
            id: "13165",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "13171",
            name: "Xã Minh Quang",
            level: "Xã"
          },
          {
            id: "13174",
            name: "Xã Vũ Bình",
            level: "Xã"
          },
          {
            id: "13177",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "13180",
            name: "Xã Nam Bình",
            level: "Xã"
          },
          {
            id: "13183",
            name: "Xã Bình Thanh",
            level: "Xã"
          },
          {
            id: "13186",
            name: "Xã Bình Định",
            level: "Xã"
          },
          {
            id: "13189",
            name: "Xã Hồng Tiến",
            level: "Xã"
          }
        ]
      },
      {
        id: "344",
        name: "Huyện Vũ Thư",
        wards: [
          {
            id: "13192",
            name: "Thị trấn Vũ Thư",
            level: "Thị trấn"
          },
          {
            id: "13195",
            name: "Xã Hồng Lý",
            level: "Xã"
          },
          {
            id: "13198",
            name: "Xã Đồng Thanh",
            level: "Xã"
          },
          {
            id: "13201",
            name: "Xã Xuân Hòa",
            level: "Xã"
          },
          {
            id: "13204",
            name: "Xã Hiệp Hòa",
            level: "Xã"
          },
          {
            id: "13207",
            name: "Xã Phúc Thành",
            level: "Xã"
          },
          {
            id: "13210",
            name: "Xã Tân Phong",
            level: "Xã"
          },
          {
            id: "13213",
            name: "Xã Song Lãng",
            level: "Xã"
          },
          {
            id: "13216",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "13219",
            name: "Xã Việt Hùng",
            level: "Xã"
          },
          {
            id: "13222",
            name: "Xã Minh Lãng",
            level: "Xã"
          },
          {
            id: "13228",
            name: "Xã Minh Khai",
            level: "Xã"
          },
          {
            id: "13231",
            name: "Xã Dũng Nghĩa",
            level: "Xã"
          },
          {
            id: "13234",
            name: "Xã Minh Quang",
            level: "Xã"
          },
          {
            id: "13237",
            name: "Xã Tam Quang",
            level: "Xã"
          },
          {
            id: "13240",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "13243",
            name: "Xã Bách Thuận",
            level: "Xã"
          },
          {
            id: "13246",
            name: "Xã Tự Tân",
            level: "Xã"
          },
          {
            id: "13249",
            name: "Xã Song An",
            level: "Xã"
          },
          {
            id: "13252",
            name: "Xã Trung An",
            level: "Xã"
          },
          {
            id: "13255",
            name: "Xã Vũ Hội",
            level: "Xã"
          },
          {
            id: "13258",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "13261",
            name: "Xã Nguyên Xá",
            level: "Xã"
          },
          {
            id: "13264",
            name: "Xã Việt Thuận",
            level: "Xã"
          },
          {
            id: "13267",
            name: "Xã Vũ Vinh",
            level: "Xã"
          },
          {
            id: "13270",
            name: "Xã Vũ Đoài",
            level: "Xã"
          },
          {
            id: "13273",
            name: "Xã Vũ Tiến",
            level: "Xã"
          },
          {
            id: "13276",
            name: "Xã Vũ Vân",
            level: "Xã"
          },
          {
            id: "13279",
            name: "Xã Duy Nhất",
            level: "Xã"
          },
          {
            id: "13282",
            name: "Xã Hồng Phong",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "35",
    name: "Tỉnh Hà Nam",
    districts: [
      {
        id: "347",
        name: "Thành phố Phủ Lý",
        wards: [
          {
            id: "13285",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "13288",
            name: "Phường Lương Khánh Thiện",
            level: "Phường"
          },
          {
            id: "13291",
            name: "Phường Lê Hồng Phong",
            level: "Phường"
          },
          {
            id: "13294",
            name: "Phường Minh Khai",
            level: "Phường"
          },
          {
            id: "13297",
            name: "Phường Hai Bà Trưng",
            level: "Phường"
          },
          {
            id: "13300",
            name: "Phường Trần Hưng Đạo",
            level: "Phường"
          },
          {
            id: "13303",
            name: "Phường Lam Hạ",
            level: "Phường"
          },
          {
            id: "13306",
            name: "Xã Phù Vân",
            level: "Xã"
          },
          {
            id: "13309",
            name: "Phường Liêm Chính",
            level: "Phường"
          },
          {
            id: "13312",
            name: "Xã Liêm Chung",
            level: "Xã"
          },
          {
            id: "13315",
            name: "Phường Thanh Châu",
            level: "Phường"
          },
          {
            id: "13318",
            name: "Phường Châu Sơn",
            level: "Phường"
          },
          {
            id: "13366",
            name: "Xã Tiên Tân",
            level: "Xã"
          },
          {
            id: "13372",
            name: "Xã Tiên Hiệp",
            level: "Xã"
          },
          {
            id: "13381",
            name: "Xã Tiên Hải",
            level: "Xã"
          },
          {
            id: "13426",
            name: "Xã Kim Bình",
            level: "Xã"
          },
          {
            id: "13444",
            name: "Xã Liêm Tuyền",
            level: "Xã"
          },
          {
            id: "13447",
            name: "Xã Liêm Tiết",
            level: "Xã"
          },
          {
            id: "13459",
            name: "Phường Thanh Tuyền",
            level: "Phường"
          },
          {
            id: "13507",
            name: "Xã Đinh Xá",
            level: "Xã"
          },
          {
            id: "13513",
            name: "Xã Trịnh Xá",
            level: "Xã"
          }
        ]
      },
      {
        id: "349",
        name: "Thị xã Duy Tiên",
        wards: [
          {
            id: "13321",
            name: "Phường Đồng Văn",
            level: "Phường"
          },
          {
            id: "13324",
            name: "Phường Hòa Mạc",
            level: "Phường"
          },
          {
            id: "13327",
            name: "Xã Mộc Bắc",
            level: "Xã"
          },
          {
            id: "13330",
            name: "Phường Châu Giang",
            level: "Phường"
          },
          {
            id: "13333",
            name: "Phường Bạch Thượng",
            level: "Phường"
          },
          {
            id: "13336",
            name: "Phường Duy Minh",
            level: "Phường"
          },
          {
            id: "13339",
            name: "Xã Mộc Nam",
            level: "Xã"
          },
          {
            id: "13342",
            name: "Phường Duy Hải",
            level: "Phường"
          },
          {
            id: "13345",
            name: "Xã Chuyên Ngoại",
            level: "Xã"
          },
          {
            id: "13348",
            name: "Phường Yên Bắc",
            level: "Phường"
          },
          {
            id: "13351",
            name: "Xã Trác Văn",
            level: "Xã"
          },
          {
            id: "13354",
            name: "Phường Tiên Nội",
            level: "Phường"
          },
          {
            id: "13357",
            name: "Phường Hoàng Đông",
            level: "Phường"
          },
          {
            id: "13360",
            name: "Xã Yên Nam",
            level: "Xã"
          },
          {
            id: "13363",
            name: "Xã Tiên Ngoại",
            level: "Xã"
          },
          {
            id: "13369",
            name: "Xã Tiên Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "350",
        name: "Huyện Kim Bảng",
        wards: [
          {
            id: "13384",
            name: "Thị trấn Quế",
            level: "Thị trấn"
          },
          {
            id: "13387",
            name: "Xã Nguyễn Úy",
            level: "Xã"
          },
          {
            id: "13390",
            name: "Xã Đại Cương",
            level: "Xã"
          },
          {
            id: "13393",
            name: "Xã Lê Hồ",
            level: "Xã"
          },
          {
            id: "13396",
            name: "Xã Tượng Lĩnh",
            level: "Xã"
          },
          {
            id: "13399",
            name: "Xã Nhật Tựu",
            level: "Xã"
          },
          {
            id: "13402",
            name: "Xã Nhật Tân",
            level: "Xã"
          },
          {
            id: "13405",
            name: "Xã Đồng Hóa",
            level: "Xã"
          },
          {
            id: "13408",
            name: "Xã Hoàng Tây",
            level: "Xã"
          },
          {
            id: "13411",
            name: "Xã Tân Sơn",
            level: "Xã"
          },
          {
            id: "13414",
            name: "Xã Thụy Lôi",
            level: "Xã"
          },
          {
            id: "13417",
            name: "Xã Văn Xá",
            level: "Xã"
          },
          {
            id: "13420",
            name: "Xã Khả Phong",
            level: "Xã"
          },
          {
            id: "13423",
            name: "Xã Ngọc Sơn",
            level: "Xã"
          },
          {
            id: "13429",
            name: "Thị trấn Ba Sao",
            level: "Thị trấn"
          },
          {
            id: "13432",
            name: "Xã Liên Sơn",
            level: "Xã"
          },
          {
            id: "13435",
            name: "Xã Thi Sơn",
            level: "Xã"
          },
          {
            id: "13438",
            name: "Xã Thanh Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "351",
        name: "Huyện Thanh Liêm",
        wards: [
          {
            id: "13441",
            name: "Thị trấn Kiện Khê",
            level: "Thị trấn"
          },
          {
            id: "13450",
            name: "Xã Liêm Phong",
            level: "Xã"
          },
          {
            id: "13453",
            name: "Xã Thanh Hà",
            level: "Xã"
          },
          {
            id: "13456",
            name: "Xã Liêm Cần",
            level: "Xã"
          },
          {
            id: "13465",
            name: "Xã Liêm Thuận",
            level: "Xã"
          },
          {
            id: "13468",
            name: "Xã Thanh Thủy",
            level: "Xã"
          },
          {
            id: "13471",
            name: "Xã Thanh Phong",
            level: "Xã"
          },
          {
            id: "13474",
            name: "Thị trấn Tân Thanh",
            level: "Thị trấn"
          },
          {
            id: "13477",
            name: "Xã Thanh Tân",
            level: "Xã"
          },
          {
            id: "13480",
            name: "Xã Liêm Túc",
            level: "Xã"
          },
          {
            id: "13483",
            name: "Xã Liêm Sơn",
            level: "Xã"
          },
          {
            id: "13486",
            name: "Xã Thanh Hương",
            level: "Xã"
          },
          {
            id: "13489",
            name: "Xã Thanh Nghị",
            level: "Xã"
          },
          {
            id: "13492",
            name: "Xã Thanh Tâm",
            level: "Xã"
          },
          {
            id: "13495",
            name: "Xã Thanh Nguyên",
            level: "Xã"
          },
          {
            id: "13498",
            name: "Xã Thanh Hải",
            level: "Xã"
          }
        ]
      },
      {
        id: "352",
        name: "Huyện Bình Lục",
        wards: [
          {
            id: "13501",
            name: "Thị trấn Bình Mỹ",
            level: "Thị trấn"
          },
          {
            id: "13504",
            name: "Xã Bình Nghĩa",
            level: "Xã"
          },
          {
            id: "13510",
            name: "Xã Tràng An",
            level: "Xã"
          },
          {
            id: "13516",
            name: "Xã Đồng Du",
            level: "Xã"
          },
          {
            id: "13519",
            name: "Xã Ngọc Lũ",
            level: "Xã"
          },
          {
            id: "13522",
            name: "Xã Hưng Công",
            level: "Xã"
          },
          {
            id: "13525",
            name: "Xã Đồn Xá",
            level: "Xã"
          },
          {
            id: "13528",
            name: "Xã An Ninh",
            level: "Xã"
          },
          {
            id: "13531",
            name: "Xã Bồ Đề",
            level: "Xã"
          },
          {
            id: "13534",
            name: "Xã Bối Cầu",
            level: "Xã"
          },
          {
            id: "13540",
            name: "Xã An Nội",
            level: "Xã"
          },
          {
            id: "13543",
            name: "Xã Vũ Bản",
            level: "Xã"
          },
          {
            id: "13546",
            name: "Xã Trung Lương",
            level: "Xã"
          },
          {
            id: "13552",
            name: "Xã An Đổ",
            level: "Xã"
          },
          {
            id: "13555",
            name: "Xã La Sơn",
            level: "Xã"
          },
          {
            id: "13558",
            name: "Xã Tiêu Động",
            level: "Xã"
          },
          {
            id: "13561",
            name: "Xã An Lão",
            level: "Xã"
          }
        ]
      },
      {
        id: "353",
        name: "Huyện Lý Nhân",
        wards: [
          {
            id: "13567",
            name: "Xã Hợp Lý",
            level: "Xã"
          },
          {
            id: "13570",
            name: "Xã Nguyên Lý",
            level: "Xã"
          },
          {
            id: "13573",
            name: "Xã Chính Lý",
            level: "Xã"
          },
          {
            id: "13576",
            name: "Xã Chân Lý",
            level: "Xã"
          },
          {
            id: "13579",
            name: "Xã Đạo Lý",
            level: "Xã"
          },
          {
            id: "13582",
            name: "Xã Công Lý",
            level: "Xã"
          },
          {
            id: "13585",
            name: "Xã Văn Lý",
            level: "Xã"
          },
          {
            id: "13588",
            name: "Xã Bắc Lý",
            level: "Xã"
          },
          {
            id: "13591",
            name: "Xã Đức Lý",
            level: "Xã"
          },
          {
            id: "13594",
            name: "Xã Trần Hưng Đạo",
            level: "Xã"
          },
          {
            id: "13597",
            name: "Thị trấn Vĩnh Trụ",
            level: "Thị trấn"
          },
          {
            id: "13600",
            name: "Xã Nhân Thịnh",
            level: "Xã"
          },
          {
            id: "13606",
            name: "Xã Nhân Khang",
            level: "Xã"
          },
          {
            id: "13609",
            name: "Xã Nhân Mỹ",
            level: "Xã"
          },
          {
            id: "13612",
            name: "Xã Nhân Nghĩa",
            level: "Xã"
          },
          {
            id: "13615",
            name: "Xã Nhân Chính",
            level: "Xã"
          },
          {
            id: "13618",
            name: "Xã Nhân Bình",
            level: "Xã"
          },
          {
            id: "13621",
            name: "Xã Phú Phúc",
            level: "Xã"
          },
          {
            id: "13624",
            name: "Xã Xuân Khê",
            level: "Xã"
          },
          {
            id: "13627",
            name: "Xã Tiến Thắng",
            level: "Xã"
          },
          {
            id: "13630",
            name: "Xã Hòa Hậu",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "36",
    name: "Tỉnh Nam Định",
    districts: [
      {
        id: "356",
        name: "Thành phố Nam Định",
        wards: [
          {
            id: "13633",
            name: "Phường Hạ Long",
            level: "Phường"
          },
          {
            id: "13636",
            name: "Phường Trần Tế Xương",
            level: "Phường"
          },
          {
            id: "13639",
            name: "Phường Vị Hoàng",
            level: "Phường"
          },
          {
            id: "13642",
            name: "Phường Vị Xuyên",
            level: "Phường"
          },
          {
            id: "13645",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "13648",
            name: "Phường Cửa Bắc",
            level: "Phường"
          },
          {
            id: "13651",
            name: "Phường Nguyễn Du",
            level: "Phường"
          },
          {
            id: "13654",
            name: "Phường Bà Triệu",
            level: "Phường"
          },
          {
            id: "13657",
            name: "Phường Trường Thi",
            level: "Phường"
          },
          {
            id: "13660",
            name: "Phường Phan Đình Phùng",
            level: "Phường"
          },
          {
            id: "13663",
            name: "Phường Ngô Quyền",
            level: "Phường"
          },
          {
            id: "13666",
            name: "Phường Trần Hưng Đạo",
            level: "Phường"
          },
          {
            id: "13669",
            name: "Phường Trần Đăng Ninh",
            level: "Phường"
          },
          {
            id: "13672",
            name: "Phường Năng Tĩnh",
            level: "Phường"
          },
          {
            id: "13675",
            name: "Phường Văn Miếu",
            level: "Phường"
          },
          {
            id: "13678",
            name: "Phường Trần Quang Khải",
            level: "Phường"
          },
          {
            id: "13681",
            name: "Phường Thống Nhất",
            level: "Phường"
          },
          {
            id: "13684",
            name: "Phường Lộc Hạ",
            level: "Phường"
          },
          {
            id: "13687",
            name: "Phường Lộc Vượng",
            level: "Phường"
          },
          {
            id: "13690",
            name: "Phường Cửa Nam",
            level: "Phường"
          },
          {
            id: "13693",
            name: "Phường Lộc Hòa",
            level: "Phường"
          },
          {
            id: "13696",
            name: "Xã Nam Phong",
            level: "Xã"
          },
          {
            id: "13699",
            name: "Phường Mỹ Xá",
            level: "Phường"
          },
          {
            id: "13702",
            name: "Xã Lộc An",
            level: "Xã"
          },
          {
            id: "13705",
            name: "Xã Nam Vân",
            level: "Xã"
          }
        ]
      },
      {
        id: "358",
        name: "Huyện Mỹ Lộc",
        wards: [
          {
            id: "13708",
            name: "Thị trấn Mỹ Lộc",
            level: "Thị trấn"
          },
          {
            id: "13711",
            name: "Xã Mỹ Hà",
            level: "Xã"
          },
          {
            id: "13714",
            name: "Xã Mỹ Tiến",
            level: "Xã"
          },
          {
            id: "13717",
            name: "Xã Mỹ Thắng",
            level: "Xã"
          },
          {
            id: "13720",
            name: "Xã Mỹ Trung",
            level: "Xã"
          },
          {
            id: "13723",
            name: "Xã Mỹ Tân",
            level: "Xã"
          },
          {
            id: "13726",
            name: "Xã Mỹ Phúc",
            level: "Xã"
          },
          {
            id: "13729",
            name: "Xã Mỹ Hưng",
            level: "Xã"
          },
          {
            id: "13732",
            name: "Xã Mỹ Thuận",
            level: "Xã"
          },
          {
            id: "13735",
            name: "Xã Mỹ Thịnh",
            level: "Xã"
          },
          {
            id: "13738",
            name: "Xã Mỹ Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "359",
        name: "Huyện Vụ Bản",
        wards: [
          {
            id: "13741",
            name: "Thị trấn Gôi",
            level: "Thị trấn"
          },
          {
            id: "13744",
            name: "Xã Minh Thuận",
            level: "Xã"
          },
          {
            id: "13747",
            name: "Xã Hiển Khánh",
            level: "Xã"
          },
          {
            id: "13750",
            name: "Xã Tân Khánh",
            level: "Xã"
          },
          {
            id: "13753",
            name: "Xã Hợp Hưng",
            level: "Xã"
          },
          {
            id: "13756",
            name: "Xã Đại An",
            level: "Xã"
          },
          {
            id: "13759",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "13762",
            name: "Xã Cộng Hòa",
            level: "Xã"
          },
          {
            id: "13765",
            name: "Xã Trung Thành",
            level: "Xã"
          },
          {
            id: "13768",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "13771",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "13774",
            name: "Xã Liên Bảo",
            level: "Xã"
          },
          {
            id: "13777",
            name: "Xã Thành Lợi",
            level: "Xã"
          },
          {
            id: "13780",
            name: "Xã Kim Thái",
            level: "Xã"
          },
          {
            id: "13783",
            name: "Xã Liên Minh",
            level: "Xã"
          },
          {
            id: "13786",
            name: "Xã Đại Thắng",
            level: "Xã"
          },
          {
            id: "13789",
            name: "Xã Tam Thanh",
            level: "Xã"
          },
          {
            id: "13792",
            name: "Xã Vĩnh Hào",
            level: "Xã"
          }
        ]
      },
      {
        id: "360",
        name: "Huyện Ý Yên",
        wards: [
          {
            id: "13795",
            name: "Thị trấn Lâm",
            level: "Thị trấn"
          },
          {
            id: "13798",
            name: "Xã Yên Trung",
            level: "Xã"
          },
          {
            id: "13801",
            name: "Xã Yên Thành",
            level: "Xã"
          },
          {
            id: "13804",
            name: "Xã Yên Tân",
            level: "Xã"
          },
          {
            id: "13807",
            name: "Xã Yên Lợi",
            level: "Xã"
          },
          {
            id: "13810",
            name: "Xã Yên Thọ",
            level: "Xã"
          },
          {
            id: "13813",
            name: "Xã Yên Nghĩa",
            level: "Xã"
          },
          {
            id: "13816",
            name: "Xã Yên Minh",
            level: "Xã"
          },
          {
            id: "13819",
            name: "Xã Yên Phương",
            level: "Xã"
          },
          {
            id: "13822",
            name: "Xã Yên Chính",
            level: "Xã"
          },
          {
            id: "13825",
            name: "Xã Yên Bình",
            level: "Xã"
          },
          {
            id: "13828",
            name: "Xã Yên Phú",
            level: "Xã"
          },
          {
            id: "13831",
            name: "Xã Yên Mỹ",
            level: "Xã"
          },
          {
            id: "13834",
            name: "Xã Yên Dương",
            level: "Xã"
          },
          {
            id: "13840",
            name: "Xã Yên Hưng",
            level: "Xã"
          },
          {
            id: "13843",
            name: "Xã Yên Khánh",
            level: "Xã"
          },
          {
            id: "13846",
            name: "Xã Yên Phong",
            level: "Xã"
          },
          {
            id: "13849",
            name: "Xã Yên Ninh",
            level: "Xã"
          },
          {
            id: "13852",
            name: "Xã Yên Lương",
            level: "Xã"
          },
          {
            id: "13855",
            name: "Xã Yên Hồng",
            level: "Xã"
          },
          {
            id: "13858",
            name: "Xã Yên Quang",
            level: "Xã"
          },
          {
            id: "13861",
            name: "Xã Yên Tiến",
            level: "Xã"
          },
          {
            id: "13864",
            name: "Xã Yên Thắng",
            level: "Xã"
          },
          {
            id: "13867",
            name: "Xã Yên Phúc",
            level: "Xã"
          },
          {
            id: "13870",
            name: "Xã Yên Cường",
            level: "Xã"
          },
          {
            id: "13873",
            name: "Xã Yên Lộc",
            level: "Xã"
          },
          {
            id: "13876",
            name: "Xã Yên Bằng",
            level: "Xã"
          },
          {
            id: "13879",
            name: "Xã Yên Đồng",
            level: "Xã"
          },
          {
            id: "13882",
            name: "Xã Yên Khang",
            level: "Xã"
          },
          {
            id: "13885",
            name: "Xã Yên Nhân",
            level: "Xã"
          },
          {
            id: "13888",
            name: "Xã Yên Trị",
            level: "Xã"
          }
        ]
      },
      {
        id: "361",
        name: "Huyện Nghĩa Hưng",
        wards: [
          {
            id: "13891",
            name: "Thị trấn Liễu Đề",
            level: "Thị trấn"
          },
          {
            id: "13894",
            name: "Thị trấn Rạng Đông",
            level: "Thị trấn"
          },
          {
            id: "13897",
            name: "Xã Nghĩa Đồng",
            level: "Xã"
          },
          {
            id: "13900",
            name: "Xã Nghĩa Thịnh",
            level: "Xã"
          },
          {
            id: "13903",
            name: "Xã Nghĩa Minh",
            level: "Xã"
          },
          {
            id: "13906",
            name: "Xã Nghĩa Thái",
            level: "Xã"
          },
          {
            id: "13909",
            name: "Xã Hoàng Nam",
            level: "Xã"
          },
          {
            id: "13912",
            name: "Xã Nghĩa Châu",
            level: "Xã"
          },
          {
            id: "13915",
            name: "Xã Nghĩa Trung",
            level: "Xã"
          },
          {
            id: "13918",
            name: "Xã Nghĩa Sơn",
            level: "Xã"
          },
          {
            id: "13921",
            name: "Xã Nghĩa Lạc",
            level: "Xã"
          },
          {
            id: "13924",
            name: "Xã Nghĩa Hồng",
            level: "Xã"
          },
          {
            id: "13927",
            name: "Xã Nghĩa Phong",
            level: "Xã"
          },
          {
            id: "13930",
            name: "Xã Nghĩa Phú",
            level: "Xã"
          },
          {
            id: "13933",
            name: "Xã Nghĩa Bình",
            level: "Xã"
          },
          {
            id: "13936",
            name: "Thị trấn Quỹ Nhất",
            level: "Thị trấn"
          },
          {
            id: "13939",
            name: "Xã Nghĩa Tân",
            level: "Xã"
          },
          {
            id: "13942",
            name: "Xã Nghĩa Hùng",
            level: "Xã"
          },
          {
            id: "13945",
            name: "Xã Nghĩa Lâm",
            level: "Xã"
          },
          {
            id: "13948",
            name: "Xã Nghĩa Thành",
            level: "Xã"
          },
          {
            id: "13951",
            name: "Xã Phúc Thắng",
            level: "Xã"
          },
          {
            id: "13954",
            name: "Xã Nghĩa Lợi",
            level: "Xã"
          },
          {
            id: "13957",
            name: "Xã Nghĩa Hải",
            level: "Xã"
          },
          {
            id: "13963",
            name: "Xã Nam Điền",
            level: "Xã"
          }
        ]
      },
      {
        id: "362",
        name: "Huyện Nam Trực",
        wards: [
          {
            id: "13966",
            name: "Thị trấn Nam Giang",
            level: "Thị trấn"
          },
          {
            id: "13969",
            name: "Xã Nam Mỹ",
            level: "Xã"
          },
          {
            id: "13972",
            name: "Xã Điền Xá",
            level: "Xã"
          },
          {
            id: "13975",
            name: "Xã Nghĩa An",
            level: "Xã"
          },
          {
            id: "13978",
            name: "Xã Nam Thắng",
            level: "Xã"
          },
          {
            id: "13981",
            name: "Xã Nam Toàn",
            level: "Xã"
          },
          {
            id: "13984",
            name: "Xã Hồng Quang",
            level: "Xã"
          },
          {
            id: "13987",
            name: "Xã Tân Thịnh",
            level: "Xã"
          },
          {
            id: "13990",
            name: "Xã Nam Cường",
            level: "Xã"
          },
          {
            id: "13993",
            name: "Xã Nam Hồng",
            level: "Xã"
          },
          {
            id: "13996",
            name: "Xã Nam Hùng",
            level: "Xã"
          },
          {
            id: "13999",
            name: "Xã Nam Hoa",
            level: "Xã"
          },
          {
            id: "14002",
            name: "Xã Nam Dương",
            level: "Xã"
          },
          {
            id: "14005",
            name: "Xã Nam Thanh",
            level: "Xã"
          },
          {
            id: "14008",
            name: "Xã Nam Lợi",
            level: "Xã"
          },
          {
            id: "14011",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "14014",
            name: "Xã Đồng Sơn",
            level: "Xã"
          },
          {
            id: "14017",
            name: "Xã Nam Tiến",
            level: "Xã"
          },
          {
            id: "14020",
            name: "Xã Nam Hải",
            level: "Xã"
          },
          {
            id: "14023",
            name: "Xã Nam Thái",
            level: "Xã"
          }
        ]
      },
      {
        id: "363",
        name: "Huyện Trực Ninh",
        wards: [
          {
            id: "14026",
            name: "Thị trấn Cổ Lễ",
            level: "Thị trấn"
          },
          {
            id: "14029",
            name: "Xã Phương Định",
            level: "Xã"
          },
          {
            id: "14032",
            name: "Xã Trực Chính",
            level: "Xã"
          },
          {
            id: "14035",
            name: "Xã Trung Đông",
            level: "Xã"
          },
          {
            id: "14038",
            name: "Xã Liêm Hải",
            level: "Xã"
          },
          {
            id: "14041",
            name: "Xã Trực Tuấn",
            level: "Xã"
          },
          {
            id: "14044",
            name: "Xã Việt Hùng",
            level: "Xã"
          },
          {
            id: "14047",
            name: "Xã Trực Đạo",
            level: "Xã"
          },
          {
            id: "14050",
            name: "Xã Trực Hưng",
            level: "Xã"
          },
          {
            id: "14053",
            name: "Xã Trực Nội",
            level: "Xã"
          },
          {
            id: "14056",
            name: "Thị trấn Cát Thành",
            level: "Thị trấn"
          },
          {
            id: "14059",
            name: "Xã Trực Thanh",
            level: "Xã"
          },
          {
            id: "14062",
            name: "Xã Trực Khang",
            level: "Xã"
          },
          {
            id: "14065",
            name: "Xã Trực Thuận",
            level: "Xã"
          },
          {
            id: "14068",
            name: "Xã Trực Mỹ",
            level: "Xã"
          },
          {
            id: "14071",
            name: "Xã Trực Đại",
            level: "Xã"
          },
          {
            id: "14074",
            name: "Xã Trực Cường",
            level: "Xã"
          },
          {
            id: "14077",
            name: "Thị trấn Ninh Cường",
            level: "Thị trấn"
          },
          {
            id: "14080",
            name: "Xã Trực Thái",
            level: "Xã"
          },
          {
            id: "14083",
            name: "Xã Trực Hùng",
            level: "Xã"
          },
          {
            id: "14086",
            name: "Xã Trực Thắng",
            level: "Xã"
          }
        ]
      },
      {
        id: "364",
        name: "Huyện Xuân Trường",
        wards: [
          {
            id: "14089",
            name: "Thị trấn Xuân Trường",
            level: "Thị trấn"
          },
          {
            id: "14092",
            name: "Xã Xuân Châu",
            level: "Xã"
          },
          {
            id: "14095",
            name: "Xã Xuân Hồng",
            level: "Xã"
          },
          {
            id: "14098",
            name: "Xã Xuân Thành",
            level: "Xã"
          },
          {
            id: "14101",
            name: "Xã Xuân Thượng",
            level: "Xã"
          },
          {
            id: "14104",
            name: "Xã Xuân Phong",
            level: "Xã"
          },
          {
            id: "14107",
            name: "Xã Xuân Đài",
            level: "Xã"
          },
          {
            id: "14110",
            name: "Xã Xuân Tân",
            level: "Xã"
          },
          {
            id: "14113",
            name: "Xã Xuân Thủy",
            level: "Xã"
          },
          {
            id: "14116",
            name: "Xã Xuân Ngọc",
            level: "Xã"
          },
          {
            id: "14119",
            name: "Xã Xuân Bắc",
            level: "Xã"
          },
          {
            id: "14122",
            name: "Xã Xuân Phương",
            level: "Xã"
          },
          {
            id: "14125",
            name: "Xã Thọ Nghiệp",
            level: "Xã"
          },
          {
            id: "14128",
            name: "Xã Xuân Phú",
            level: "Xã"
          },
          {
            id: "14131",
            name: "Xã Xuân Trung",
            level: "Xã"
          },
          {
            id: "14134",
            name: "Xã Xuân Vinh",
            level: "Xã"
          },
          {
            id: "14137",
            name: "Xã Xuân Kiên",
            level: "Xã"
          },
          {
            id: "14140",
            name: "Xã Xuân Tiến",
            level: "Xã"
          },
          {
            id: "14143",
            name: "Xã Xuân Ninh",
            level: "Xã"
          },
          {
            id: "14146",
            name: "Xã Xuân Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "365",
        name: "Huyện Giao Thủy",
        wards: [
          {
            id: "14149",
            name: "Thị trấn Ngô Đồng",
            level: "Thị trấn"
          },
          {
            id: "14152",
            name: "Thị trấn Quất Lâm",
            level: "Thị trấn"
          },
          {
            id: "14155",
            name: "Xã Giao Hương",
            level: "Xã"
          },
          {
            id: "14158",
            name: "Xã Hồng Thuận",
            level: "Xã"
          },
          {
            id: "14161",
            name: "Xã Giao Thiện",
            level: "Xã"
          },
          {
            id: "14164",
            name: "Xã Giao Thanh",
            level: "Xã"
          },
          {
            id: "14167",
            name: "Xã Hoành Sơn",
            level: "Xã"
          },
          {
            id: "14170",
            name: "Xã Bình Hòa",
            level: "Xã"
          },
          {
            id: "14173",
            name: "Xã Giao Tiến",
            level: "Xã"
          },
          {
            id: "14176",
            name: "Xã Giao Hà",
            level: "Xã"
          },
          {
            id: "14179",
            name: "Xã Giao Nhân",
            level: "Xã"
          },
          {
            id: "14182",
            name: "Xã Giao An",
            level: "Xã"
          },
          {
            id: "14185",
            name: "Xã Giao Lạc",
            level: "Xã"
          },
          {
            id: "14188",
            name: "Xã Giao Châu",
            level: "Xã"
          },
          {
            id: "14191",
            name: "Xã Giao Tân",
            level: "Xã"
          },
          {
            id: "14194",
            name: "Xã Giao Yến",
            level: "Xã"
          },
          {
            id: "14197",
            name: "Xã Giao Xuân",
            level: "Xã"
          },
          {
            id: "14200",
            name: "Xã Giao Thịnh",
            level: "Xã"
          },
          {
            id: "14203",
            name: "Xã Giao Hải",
            level: "Xã"
          },
          {
            id: "14206",
            name: "Xã Bạch Long",
            level: "Xã"
          },
          {
            id: "14209",
            name: "Xã Giao Long",
            level: "Xã"
          },
          {
            id: "14212",
            name: "Xã Giao Phong",
            level: "Xã"
          }
        ]
      },
      {
        id: "366",
        name: "Huyện Hải Hậu",
        wards: [
          {
            id: "14215",
            name: "Thị trấn Yên Định",
            level: "Thị trấn"
          },
          {
            id: "14218",
            name: "Thị trấn Cồn",
            level: "Thị trấn"
          },
          {
            id: "14221",
            name: "Thị trấn Thịnh Long",
            level: "Thị trấn"
          },
          {
            id: "14224",
            name: "Xã Hải Nam",
            level: "Xã"
          },
          {
            id: "14227",
            name: "Xã Hải Trung",
            level: "Xã"
          },
          {
            id: "14230",
            name: "Xã Hải Vân",
            level: "Xã"
          },
          {
            id: "14233",
            name: "Xã Hải Minh",
            level: "Xã"
          },
          {
            id: "14236",
            name: "Xã Hải Anh",
            level: "Xã"
          },
          {
            id: "14239",
            name: "Xã Hải Hưng",
            level: "Xã"
          },
          {
            id: "14242",
            name: "Xã Hải Bắc",
            level: "Xã"
          },
          {
            id: "14245",
            name: "Xã Hải Phúc",
            level: "Xã"
          },
          {
            id: "14248",
            name: "Xã Hải Thanh",
            level: "Xã"
          },
          {
            id: "14251",
            name: "Xã Hải Hà",
            level: "Xã"
          },
          {
            id: "14254",
            name: "Xã Hải Long",
            level: "Xã"
          },
          {
            id: "14257",
            name: "Xã Hải Phương",
            level: "Xã"
          },
          {
            id: "14260",
            name: "Xã Hải Đường",
            level: "Xã"
          },
          {
            id: "14263",
            name: "Xã Hải Lộc",
            level: "Xã"
          },
          {
            id: "14266",
            name: "Xã Hải Quang",
            level: "Xã"
          },
          {
            id: "14269",
            name: "Xã Hải Đông",
            level: "Xã"
          },
          {
            id: "14272",
            name: "Xã Hải Sơn",
            level: "Xã"
          },
          {
            id: "14275",
            name: "Xã Hải Tân",
            level: "Xã"
          },
          {
            id: "14281",
            name: "Xã Hải Phong",
            level: "Xã"
          },
          {
            id: "14284",
            name: "Xã Hải An",
            level: "Xã"
          },
          {
            id: "14287",
            name: "Xã Hải Tây",
            level: "Xã"
          },
          {
            id: "14290",
            name: "Xã Hải Lý",
            level: "Xã"
          },
          {
            id: "14293",
            name: "Xã Hải Phú",
            level: "Xã"
          },
          {
            id: "14296",
            name: "Xã Hải Giang",
            level: "Xã"
          },
          {
            id: "14299",
            name: "Xã Hải Cường",
            level: "Xã"
          },
          {
            id: "14302",
            name: "Xã Hải Ninh",
            level: "Xã"
          },
          {
            id: "14305",
            name: "Xã Hải Chính",
            level: "Xã"
          },
          {
            id: "14308",
            name: "Xã Hải Xuân",
            level: "Xã"
          },
          {
            id: "14311",
            name: "Xã Hải Châu",
            level: "Xã"
          },
          {
            id: "14314",
            name: "Xã Hải Triều",
            level: "Xã"
          },
          {
            id: "14317",
            name: "Xã Hải Hòa",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "37",
    name: "Tỉnh Ninh Bình",
    districts: [
      {
        id: "369",
        name: "Thành phố Ninh Bình",
        wards: [
          {
            id: "14320",
            name: "Phường Đông Thành",
            level: "Phường"
          },
          {
            id: "14323",
            name: "Phường Tân Thành",
            level: "Phường"
          },
          {
            id: "14326",
            name: "Phường Thanh Bình",
            level: "Phường"
          },
          {
            id: "14329",
            name: "Phường Vân Giang",
            level: "Phường"
          },
          {
            id: "14332",
            name: "Phường Bích Đào",
            level: "Phường"
          },
          {
            id: "14335",
            name: "Phường Phúc Thành",
            level: "Phường"
          },
          {
            id: "14338",
            name: "Phường Nam Bình",
            level: "Phường"
          },
          {
            id: "14341",
            name: "Phường Nam Thành",
            level: "Phường"
          },
          {
            id: "14344",
            name: "Phường Ninh Khánh",
            level: "Phường"
          },
          {
            id: "14347",
            name: "Xã Ninh Nhất",
            level: "Xã"
          },
          {
            id: "14350",
            name: "Xã Ninh Tiến",
            level: "Xã"
          },
          {
            id: "14353",
            name: "Xã Ninh Phúc",
            level: "Xã"
          },
          {
            id: "14356",
            name: "Phường Ninh Sơn",
            level: "Phường"
          },
          {
            id: "14359",
            name: "Phường Ninh Phong",
            level: "Phường"
          }
        ]
      },
      {
        id: "370",
        name: "Thành phố Tam Điệp",
        wards: [
          {
            id: "14362",
            name: "Phường Bắc Sơn",
            level: "Phường"
          },
          {
            id: "14365",
            name: "Phường Trung Sơn",
            level: "Phường"
          },
          {
            id: "14368",
            name: "Phường Nam Sơn",
            level: "Phường"
          },
          {
            id: "14369",
            name: "Phường Tây Sơn",
            level: "Phường"
          },
          {
            id: "14371",
            name: "Xã Yên Sơn",
            level: "Xã"
          },
          {
            id: "14374",
            name: "Phường Yên Bình",
            level: "Phường"
          },
          {
            id: "14375",
            name: "Phường Tân Bình",
            level: "Phường"
          },
          {
            id: "14377",
            name: "Xã Quang Sơn",
            level: "Xã"
          },
          {
            id: "14380",
            name: "Xã Đông Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "372",
        name: "Huyện Nho Quan",
        wards: [
          {
            id: "14383",
            name: "Thị trấn Nho Quan",
            level: "Thị trấn"
          },
          {
            id: "14386",
            name: "Xã Xích Thổ",
            level: "Xã"
          },
          {
            id: "14389",
            name: "Xã Gia Lâm",
            level: "Xã"
          },
          {
            id: "14392",
            name: "Xã Gia Sơn",
            level: "Xã"
          },
          {
            id: "14395",
            name: "Xã Thạch Bình",
            level: "Xã"
          },
          {
            id: "14398",
            name: "Xã Gia Thủy",
            level: "Xã"
          },
          {
            id: "14401",
            name: "Xã Gia Tường",
            level: "Xã"
          },
          {
            id: "14404",
            name: "Xã Cúc Phương",
            level: "Xã"
          },
          {
            id: "14407",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "14410",
            name: "Xã Đức Long",
            level: "Xã"
          },
          {
            id: "14413",
            name: "Xã Lạc Vân",
            level: "Xã"
          },
          {
            id: "14416",
            name: "Xã Đồng Phong",
            level: "Xã"
          },
          {
            id: "14419",
            name: "Xã Yên Quang",
            level: "Xã"
          },
          {
            id: "14422",
            name: "Xã Lạng Phong",
            level: "Xã"
          },
          {
            id: "14425",
            name: "Xã Thượng Hòa",
            level: "Xã"
          },
          {
            id: "14428",
            name: "Xã Văn Phong",
            level: "Xã"
          },
          {
            id: "14431",
            name: "Xã Văn Phương",
            level: "Xã"
          },
          {
            id: "14434",
            name: "Xã Thanh Lạc",
            level: "Xã"
          },
          {
            id: "14437",
            name: "Xã Sơn Lai",
            level: "Xã"
          },
          {
            id: "14440",
            name: "Xã Sơn Thành",
            level: "Xã"
          },
          {
            id: "14443",
            name: "Xã Văn Phú",
            level: "Xã"
          },
          {
            id: "14446",
            name: "Xã Phú Lộc",
            level: "Xã"
          },
          {
            id: "14449",
            name: "Xã Kỳ Phú",
            level: "Xã"
          },
          {
            id: "14452",
            name: "Xã Quỳnh Lưu",
            level: "Xã"
          },
          {
            id: "14455",
            name: "Xã Sơn Hà",
            level: "Xã"
          },
          {
            id: "14458",
            name: "Xã Phú Long",
            level: "Xã"
          },
          {
            id: "14461",
            name: "Xã Quảng Lạc",
            level: "Xã"
          }
        ]
      },
      {
        id: "373",
        name: "Huyện Gia Viễn",
        wards: [
          {
            id: "14464",
            name: "Thị trấn Me",
            level: "Thị trấn"
          },
          {
            id: "14467",
            name: "Xã Gia Hòa",
            level: "Xã"
          },
          {
            id: "14470",
            name: "Xã Gia Hưng",
            level: "Xã"
          },
          {
            id: "14473",
            name: "Xã Liên Sơn",
            level: "Xã"
          },
          {
            id: "14476",
            name: "Xã Gia Thanh",
            level: "Xã"
          },
          {
            id: "14479",
            name: "Xã Gia Vân",
            level: "Xã"
          },
          {
            id: "14482",
            name: "Xã Gia Phú",
            level: "Xã"
          },
          {
            id: "14485",
            name: "Xã Gia Xuân",
            level: "Xã"
          },
          {
            id: "14488",
            name: "Xã Gia Lập",
            level: "Xã"
          },
          {
            id: "14491",
            name: "Xã Gia Vượng",
            level: "Xã"
          },
          {
            id: "14494",
            name: "Xã Gia Trấn",
            level: "Xã"
          },
          {
            id: "14497",
            name: "Xã Gia Thịnh",
            level: "Xã"
          },
          {
            id: "14500",
            name: "Xã Gia Phương",
            level: "Xã"
          },
          {
            id: "14503",
            name: "Xã Gia Tân",
            level: "Xã"
          },
          {
            id: "14506",
            name: "Xã Gia Thắng",
            level: "Xã"
          },
          {
            id: "14509",
            name: "Xã Gia Trung",
            level: "Xã"
          },
          {
            id: "14512",
            name: "Xã Gia Minh",
            level: "Xã"
          },
          {
            id: "14515",
            name: "Xã Gia Lạc",
            level: "Xã"
          },
          {
            id: "14518",
            name: "Xã Gia Tiến",
            level: "Xã"
          },
          {
            id: "14521",
            name: "Xã Gia Sinh",
            level: "Xã"
          },
          {
            id: "14524",
            name: "Xã Gia Phong",
            level: "Xã"
          }
        ]
      },
      {
        id: "374",
        name: "Huyện Hoa Lư",
        wards: [
          {
            id: "14527",
            name: "Thị trấn Thiên Tôn",
            level: "Thị trấn"
          },
          {
            id: "14530",
            name: "Xã Ninh Giang",
            level: "Xã"
          },
          {
            id: "14533",
            name: "Xã Trường Yên",
            level: "Xã"
          },
          {
            id: "14536",
            name: "Xã Ninh Khang",
            level: "Xã"
          },
          {
            id: "14539",
            name: "Xã Ninh Mỹ",
            level: "Xã"
          },
          {
            id: "14542",
            name: "Xã Ninh Hòa",
            level: "Xã"
          },
          {
            id: "14545",
            name: "Xã Ninh Xuân",
            level: "Xã"
          },
          {
            id: "14548",
            name: "Xã Ninh Hải",
            level: "Xã"
          },
          {
            id: "14551",
            name: "Xã Ninh Thắng",
            level: "Xã"
          },
          {
            id: "14554",
            name: "Xã Ninh Vân",
            level: "Xã"
          },
          {
            id: "14557",
            name: "Xã Ninh An",
            level: "Xã"
          }
        ]
      },
      {
        id: "375",
        name: "Huyện Yên Khánh",
        wards: [
          {
            id: "14560",
            name: "Thị trấn Yên Ninh",
            level: "Thị trấn"
          },
          {
            id: "14563",
            name: "Xã Khánh Tiên",
            level: "Xã"
          },
          {
            id: "14566",
            name: "Xã Khánh Phú",
            level: "Xã"
          },
          {
            id: "14569",
            name: "Xã Khánh Hòa",
            level: "Xã"
          },
          {
            id: "14572",
            name: "Xã Khánh Lợi",
            level: "Xã"
          },
          {
            id: "14575",
            name: "Xã Khánh An",
            level: "Xã"
          },
          {
            id: "14578",
            name: "Xã Khánh Cường",
            level: "Xã"
          },
          {
            id: "14581",
            name: "Xã Khánh Cư",
            level: "Xã"
          },
          {
            id: "14584",
            name: "Xã Khánh Thiện",
            level: "Xã"
          },
          {
            id: "14587",
            name: "Xã Khánh Hải",
            level: "Xã"
          },
          {
            id: "14590",
            name: "Xã Khánh Trung",
            level: "Xã"
          },
          {
            id: "14593",
            name: "Xã Khánh Mậu",
            level: "Xã"
          },
          {
            id: "14596",
            name: "Xã Khánh Vân",
            level: "Xã"
          },
          {
            id: "14599",
            name: "Xã Khánh Hội",
            level: "Xã"
          },
          {
            id: "14602",
            name: "Xã Khánh Công",
            level: "Xã"
          },
          {
            id: "14608",
            name: "Xã Khánh Thành",
            level: "Xã"
          },
          {
            id: "14611",
            name: "Xã Khánh Nhạc",
            level: "Xã"
          },
          {
            id: "14614",
            name: "Xã Khánh Thủy",
            level: "Xã"
          },
          {
            id: "14617",
            name: "Xã Khánh Hồng",
            level: "Xã"
          }
        ]
      },
      {
        id: "376",
        name: "Huyện Kim Sơn",
        wards: [
          {
            id: "14620",
            name: "Thị trấn Phát Diệm",
            level: "Thị trấn"
          },
          {
            id: "14623",
            name: "Thị trấn Bình Minh",
            level: "Thị trấn"
          },
          {
            id: "14629",
            name: "Xã Hồi Ninh",
            level: "Xã"
          },
          {
            id: "14632",
            name: "Xã Xuân Chính",
            level: "Xã"
          },
          {
            id: "14635",
            name: "Xã Kim Định",
            level: "Xã"
          },
          {
            id: "14638",
            name: "Xã Ân Hòa",
            level: "Xã"
          },
          {
            id: "14641",
            name: "Xã Hùng Tiến",
            level: "Xã"
          },
          {
            id: "14647",
            name: "Xã Quang Thiện",
            level: "Xã"
          },
          {
            id: "14650",
            name: "Xã Như Hòa",
            level: "Xã"
          },
          {
            id: "14653",
            name: "Xã Chất Bình",
            level: "Xã"
          },
          {
            id: "14656",
            name: "Xã Đồng Hướng",
            level: "Xã"
          },
          {
            id: "14659",
            name: "Xã Kim Chính",
            level: "Xã"
          },
          {
            id: "14662",
            name: "Xã Thượng Kiệm",
            level: "Xã"
          },
          {
            id: "14665",
            name: "Xã Lưu Phương",
            level: "Xã"
          },
          {
            id: "14668",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "14671",
            name: "Xã Yên Lộc",
            level: "Xã"
          },
          {
            id: "14674",
            name: "Xã Lai Thành",
            level: "Xã"
          },
          {
            id: "14677",
            name: "Xã Định Hóa",
            level: "Xã"
          },
          {
            id: "14680",
            name: "Xã Văn Hải",
            level: "Xã"
          },
          {
            id: "14683",
            name: "Xã Kim Tân",
            level: "Xã"
          },
          {
            id: "14686",
            name: "Xã Kim Mỹ",
            level: "Xã"
          },
          {
            id: "14689",
            name: "Xã Cồn Thoi",
            level: "Xã"
          },
          {
            id: "14692",
            name: "Xã Kim Hải",
            level: "Xã"
          },
          {
            id: "14695",
            name: "Xã Kim Trung",
            level: "Xã"
          },
          {
            id: "14698",
            name: "Xã Kim Đông",
            level: "Xã"
          }
        ]
      },
      {
        id: "377",
        name: "Huyện Yên Mô",
        wards: [
          {
            id: "14701",
            name: "Thị trấn Yên Thịnh",
            level: "Thị trấn"
          },
          {
            id: "14704",
            name: "Xã Khánh Thượng",
            level: "Xã"
          },
          {
            id: "14707",
            name: "Xã Khánh Dương",
            level: "Xã"
          },
          {
            id: "14710",
            name: "Xã Mai Sơn",
            level: "Xã"
          },
          {
            id: "14713",
            name: "Xã Khánh Thịnh",
            level: "Xã"
          },
          {
            id: "14719",
            name: "Xã Yên Phong",
            level: "Xã"
          },
          {
            id: "14722",
            name: "Xã Yên Hòa",
            level: "Xã"
          },
          {
            id: "14725",
            name: "Xã Yên Thắng",
            level: "Xã"
          },
          {
            id: "14728",
            name: "Xã Yên Từ",
            level: "Xã"
          },
          {
            id: "14731",
            name: "Xã Yên Hưng",
            level: "Xã"
          },
          {
            id: "14734",
            name: "Xã Yên Thành",
            level: "Xã"
          },
          {
            id: "14737",
            name: "Xã Yên Nhân",
            level: "Xã"
          },
          {
            id: "14740",
            name: "Xã Yên Mỹ",
            level: "Xã"
          },
          {
            id: "14743",
            name: "Xã Yên Mạc",
            level: "Xã"
          },
          {
            id: "14746",
            name: "Xã Yên Đồng",
            level: "Xã"
          },
          {
            id: "14749",
            name: "Xã Yên Thái",
            level: "Xã"
          },
          {
            id: "14752",
            name: "Xã Yên Lâm",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "38",
    name: "Tỉnh Thanh Hóa",
    districts: [
      {
        id: "380",
        name: "Thành phố Thanh Hóa",
        wards: [
          {
            id: "14755",
            name: "Phường Hàm Rồng",
            level: "Phường"
          },
          {
            id: "14758",
            name: "Phường Đông Thọ",
            level: "Phường"
          },
          {
            id: "14761",
            name: "Phường Nam Ngạn",
            level: "Phường"
          },
          {
            id: "14764",
            name: "Phường Trường Thi",
            level: "Phường"
          },
          {
            id: "14767",
            name: "Phường Điện Biên",
            level: "Phường"
          },
          {
            id: "14770",
            name: "Phường Phú Sơn",
            level: "Phường"
          },
          {
            id: "14773",
            name: "Phường Lam Sơn",
            level: "Phường"
          },
          {
            id: "14776",
            name: "Phường Ba Đình",
            level: "Phường"
          },
          {
            id: "14779",
            name: "Phường Ngọc Trạo",
            level: "Phường"
          },
          {
            id: "14782",
            name: "Phường Đông Vệ",
            level: "Phường"
          },
          {
            id: "14785",
            name: "Phường Đông Sơn",
            level: "Phường"
          },
          {
            id: "14788",
            name: "Phường Tân Sơn",
            level: "Phường"
          },
          {
            id: "14791",
            name: "Phường Đông Cương",
            level: "Phường"
          },
          {
            id: "14794",
            name: "Phường Đông Hương",
            level: "Phường"
          },
          {
            id: "14797",
            name: "Phường Đông Hải",
            level: "Phường"
          },
          {
            id: "14800",
            name: "Phường Quảng Hưng",
            level: "Phường"
          },
          {
            id: "14803",
            name: "Phường Quảng Thắng",
            level: "Phường"
          },
          {
            id: "14806",
            name: "Phường Quảng Thành",
            level: "Phường"
          },
          {
            id: "15850",
            name: "Xã Thiệu Vân",
            level: "Xã"
          },
          {
            id: "15856",
            name: "Xã Thiệu Khánh",
            level: "Xã"
          },
          {
            id: "15859",
            name: "Xã Thiệu Dương",
            level: "Xã"
          },
          {
            id: "15913",
            name: "Phường Tào Xuyên",
            level: "Phường"
          },
          {
            id: "15922",
            name: "Xã Long Anh",
            level: "Xã"
          },
          {
            id: "15925",
            name: "Xã Hoằng Quang",
            level: "Xã"
          },
          {
            id: "15970",
            name: "Xã Hoằng Đại",
            level: "Xã"
          },
          {
            id: "16396",
            name: "Xã Đông Lĩnh",
            level: "Xã"
          },
          {
            id: "16429",
            name: "Xã Đông Vinh",
            level: "Xã"
          },
          {
            id: "16432",
            name: "Xã Đông Tân",
            level: "Xã"
          },
          {
            id: "16435",
            name: "Phường An Hưng",
            level: "Phường"
          },
          {
            id: "16441",
            name: "Xã Quảng Thịnh",
            level: "Xã"
          },
          {
            id: "16459",
            name: "Xã Quảng Đông",
            level: "Xã"
          },
          {
            id: "16507",
            name: "Xã Quảng Cát",
            level: "Xã"
          },
          {
            id: "16522",
            name: "Xã Quảng Phú",
            level: "Xã"
          },
          {
            id: "16525",
            name: "Xã Quảng Tâm",
            level: "Xã"
          }
        ]
      },
      {
        id: "381",
        name: "Thị xã Bỉm Sơn",
        wards: [
          {
            id: "14809",
            name: "Phường Bắc Sơn",
            level: "Phường"
          },
          {
            id: "14812",
            name: "Phường Ba Đình",
            level: "Phường"
          },
          {
            id: "14815",
            name: "Phường Lam Sơn",
            level: "Phường"
          },
          {
            id: "14818",
            name: "Phường Ngọc Trạo",
            level: "Phường"
          },
          {
            id: "14821",
            name: "Phường Đông Sơn",
            level: "Phường"
          },
          {
            id: "14823",
            name: "Phường Phú Sơn",
            level: "Phường"
          },
          {
            id: "14824",
            name: "Xã Quang Trung",
            level: "Xã"
          }
        ]
      },
      {
        id: "382",
        name: "Thành phố Sầm Sơn",
        wards: [
          {
            id: "14830",
            name: "Phường Trung Sơn",
            level: "Phường"
          },
          {
            id: "14833",
            name: "Phường Bắc Sơn",
            level: "Phường"
          },
          {
            id: "14836",
            name: "Phường Trường Sơn",
            level: "Phường"
          },
          {
            id: "14839",
            name: "Phường Quảng Cư",
            level: "Phường"
          },
          {
            id: "14842",
            name: "Phường Quảng Tiến",
            level: "Phường"
          },
          {
            id: "16513",
            name: "Xã Quảng Minh",
            level: "Xã"
          },
          {
            id: "16516",
            name: "Xã Quảng Hùng",
            level: "Xã"
          },
          {
            id: "16528",
            name: "Phường Quảng Thọ",
            level: "Phường"
          },
          {
            id: "16531",
            name: "Phường Quảng Châu",
            level: "Phường"
          },
          {
            id: "16534",
            name: "Phường Quảng Vinh",
            level: "Phường"
          },
          {
            id: "16537",
            name: "Xã Quảng Đại",
            level: "Xã"
          }
        ]
      },
      {
        id: "384",
        name: "Huyện Mường Lát",
        wards: [
          {
            id: "14845",
            name: "Thị trấn Mường Lát",
            level: "Thị trấn"
          },
          {
            id: "14848",
            name: "Xã Tam Chung",
            level: "Xã"
          },
          {
            id: "14854",
            name: "Xã Mường Lý",
            level: "Xã"
          },
          {
            id: "14857",
            name: "Xã Trung Lý",
            level: "Xã"
          },
          {
            id: "14860",
            name: "Xã Quang Chiểu",
            level: "Xã"
          },
          {
            id: "14863",
            name: "Xã Pù Nhi",
            level: "Xã"
          },
          {
            id: "14864",
            name: "Xã Nhi Sơn",
            level: "Xã"
          },
          {
            id: "14866",
            name: "Xã Mường Chanh",
            level: "Xã"
          }
        ]
      },
      {
        id: "385",
        name: "Huyện Quan Hóa",
        wards: [
          {
            id: "14869",
            name: "Thị trấn Hồi Xuân",
            level: "Thị trấn"
          },
          {
            id: "14872",
            name: "Xã Thành Sơn",
            level: "Xã"
          },
          {
            id: "14875",
            name: "Xã Trung Sơn",
            level: "Xã"
          },
          {
            id: "14878",
            name: "Xã Phú Thanh",
            level: "Xã"
          },
          {
            id: "14881",
            name: "Xã Trung Thành",
            level: "Xã"
          },
          {
            id: "14884",
            name: "Xã Phú Lệ",
            level: "Xã"
          },
          {
            id: "14887",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "14890",
            name: "Xã Phú Xuân",
            level: "Xã"
          },
          {
            id: "14896",
            name: "Xã Hiền Chung",
            level: "Xã"
          },
          {
            id: "14899",
            name: "Xã Hiền Kiệt",
            level: "Xã"
          },
          {
            id: "14902",
            name: "Xã Nam Tiến",
            level: "Xã"
          },
          {
            id: "14908",
            name: "Xã Thiên Phủ",
            level: "Xã"
          },
          {
            id: "14911",
            name: "Xã Phú Nghiêm",
            level: "Xã"
          },
          {
            id: "14914",
            name: "Xã Nam Xuân",
            level: "Xã"
          },
          {
            id: "14917",
            name: "Xã Nam Động",
            level: "Xã"
          }
        ]
      },
      {
        id: "386",
        name: "Huyện Bá Thước",
        wards: [
          {
            id: "14923",
            name: "Thị trấn Cành Nàng",
            level: "Thị trấn"
          },
          {
            id: "14926",
            name: "Xã Điền Thượng",
            level: "Xã"
          },
          {
            id: "14929",
            name: "Xã Điền Hạ",
            level: "Xã"
          },
          {
            id: "14932",
            name: "Xã Điền Quang",
            level: "Xã"
          },
          {
            id: "14935",
            name: "Xã Điền Trung",
            level: "Xã"
          },
          {
            id: "14938",
            name: "Xã Thành Sơn",
            level: "Xã"
          },
          {
            id: "14941",
            name: "Xã Lương Ngoại",
            level: "Xã"
          },
          {
            id: "14944",
            name: "Xã Ái Thượng",
            level: "Xã"
          },
          {
            id: "14947",
            name: "Xã Lương Nội",
            level: "Xã"
          },
          {
            id: "14950",
            name: "Xã Điền Lư",
            level: "Xã"
          },
          {
            id: "14953",
            name: "Xã Lương Trung",
            level: "Xã"
          },
          {
            id: "14956",
            name: "Xã Lũng Niêm",
            level: "Xã"
          },
          {
            id: "14959",
            name: "Xã Lũng Cao",
            level: "Xã"
          },
          {
            id: "14962",
            name: "Xã Hạ Trung",
            level: "Xã"
          },
          {
            id: "14965",
            name: "Xã Cổ Lũng",
            level: "Xã"
          },
          {
            id: "14968",
            name: "Xã Thành Lâm",
            level: "Xã"
          },
          {
            id: "14971",
            name: "Xã Ban Công",
            level: "Xã"
          },
          {
            id: "14974",
            name: "Xã Kỳ Tân",
            level: "Xã"
          },
          {
            id: "14977",
            name: "Xã Văn Nho",
            level: "Xã"
          },
          {
            id: "14980",
            name: "Xã Thiết Ống",
            level: "Xã"
          },
          {
            id: "14986",
            name: "Xã Thiết Kế",
            level: "Xã"
          }
        ]
      },
      {
        id: "387",
        name: "Huyện Quan Sơn",
        wards: [
          {
            id: "14995",
            name: "Xã Trung Xuân",
            level: "Xã"
          },
          {
            id: "14998",
            name: "Xã Trung Thượng",
            level: "Xã"
          },
          {
            id: "14999",
            name: "Xã Trung Tiến",
            level: "Xã"
          },
          {
            id: "15001",
            name: "Xã Trung Hạ",
            level: "Xã"
          },
          {
            id: "15004",
            name: "Xã Sơn Hà",
            level: "Xã"
          },
          {
            id: "15007",
            name: "Xã Tam Thanh",
            level: "Xã"
          },
          {
            id: "15010",
            name: "Xã Sơn Thủy",
            level: "Xã"
          },
          {
            id: "15013",
            name: "Xã Na Mèo",
            level: "Xã"
          },
          {
            id: "15016",
            name: "Thị trấn Sơn Lư",
            level: "Thị trấn"
          },
          {
            id: "15019",
            name: "Xã Tam Lư",
            level: "Xã"
          },
          {
            id: "15022",
            name: "Xã Sơn Điện",
            level: "Xã"
          },
          {
            id: "15025",
            name: "Xã Mường Mìn",
            level: "Xã"
          }
        ]
      },
      {
        id: "388",
        name: "Huyện Lang Chánh",
        wards: [
          {
            id: "15031",
            name: "Xã Yên Khương",
            level: "Xã"
          },
          {
            id: "15034",
            name: "Xã Yên Thắng",
            level: "Xã"
          },
          {
            id: "15037",
            name: "Xã Trí Nang",
            level: "Xã"
          },
          {
            id: "15040",
            name: "Xã Giao An",
            level: "Xã"
          },
          {
            id: "15043",
            name: "Xã Giao Thiện",
            level: "Xã"
          },
          {
            id: "15046",
            name: "Xã Tân Phúc",
            level: "Xã"
          },
          {
            id: "15049",
            name: "Xã Tam Văn",
            level: "Xã"
          },
          {
            id: "15052",
            name: "Xã Lâm Phú",
            level: "Xã"
          },
          {
            id: "15055",
            name: "Thị trấn Lang Chánh",
            level: "Thị trấn"
          },
          {
            id: "15058",
            name: "Xã Đồng Lương",
            level: "Xã"
          }
        ]
      },
      {
        id: "389",
        name: "Huyện Ngọc Lặc",
        wards: [
          {
            id: "15061",
            name: "Thị Trấn Ngọc Lặc",
            level: "Thị trấn"
          },
          {
            id: "15064",
            name: "Xã Lam Sơn",
            level: "Xã"
          },
          {
            id: "15067",
            name: "Xã Mỹ Tân",
            level: "Xã"
          },
          {
            id: "15070",
            name: "Xã Thúy Sơn",
            level: "Xã"
          },
          {
            id: "15073",
            name: "Xã Thạch Lập",
            level: "Xã"
          },
          {
            id: "15076",
            name: "Xã Vân Âm",
            level: "Xã"
          },
          {
            id: "15079",
            name: "Xã Cao Ngọc",
            level: "Xã"
          },
          {
            id: "15085",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "15088",
            name: "Xã Đồng Thịnh",
            level: "Xã"
          },
          {
            id: "15091",
            name: "Xã Ngọc Liên",
            level: "Xã"
          },
          {
            id: "15094",
            name: "Xã Ngọc Sơn",
            level: "Xã"
          },
          {
            id: "15097",
            name: "Xã Lộc Thịnh",
            level: "Xã"
          },
          {
            id: "15100",
            name: "Xã Cao Thịnh",
            level: "Xã"
          },
          {
            id: "15103",
            name: "Xã Ngọc Trung",
            level: "Xã"
          },
          {
            id: "15106",
            name: "Xã Phùng Giáo",
            level: "Xã"
          },
          {
            id: "15109",
            name: "Xã Phùng Minh",
            level: "Xã"
          },
          {
            id: "15112",
            name: "Xã Phúc Thịnh",
            level: "Xã"
          },
          {
            id: "15115",
            name: "Xã Nguyệt Ấn",
            level: "Xã"
          },
          {
            id: "15118",
            name: "Xã Kiên Thọ",
            level: "Xã"
          },
          {
            id: "15121",
            name: "Xã Minh Tiến",
            level: "Xã"
          },
          {
            id: "15124",
            name: "Xã Minh Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "390",
        name: "Huyện Cẩm Thủy",
        wards: [
          {
            id: "15127",
            name: "Thị trấn Phong Sơn",
            level: "Thị trấn"
          },
          {
            id: "15133",
            name: "Xã Cẩm Thành",
            level: "Xã"
          },
          {
            id: "15136",
            name: "Xã Cẩm Quý",
            level: "Xã"
          },
          {
            id: "15139",
            name: "Xã Cẩm Lương",
            level: "Xã"
          },
          {
            id: "15142",
            name: "Xã Cẩm Thạch",
            level: "Xã"
          },
          {
            id: "15145",
            name: "Xã Cẩm Liên",
            level: "Xã"
          },
          {
            id: "15148",
            name: "Xã Cẩm Giang",
            level: "Xã"
          },
          {
            id: "15151",
            name: "Xã Cẩm Bình",
            level: "Xã"
          },
          {
            id: "15154",
            name: "Xã Cẩm Tú",
            level: "Xã"
          },
          {
            id: "15160",
            name: "Xã Cẩm Châu",
            level: "Xã"
          },
          {
            id: "15163",
            name: "Xã Cẩm Tâm",
            level: "Xã"
          },
          {
            id: "15169",
            name: "Xã Cẩm Ngọc",
            level: "Xã"
          },
          {
            id: "15172",
            name: "Xã Cẩm Long",
            level: "Xã"
          },
          {
            id: "15175",
            name: "Xã Cẩm Yên",
            level: "Xã"
          },
          {
            id: "15178",
            name: "Xã Cẩm Tân",
            level: "Xã"
          },
          {
            id: "15181",
            name: "Xã Cẩm Phú",
            level: "Xã"
          },
          {
            id: "15184",
            name: "Xã Cẩm Vân",
            level: "Xã"
          }
        ]
      },
      {
        id: "391",
        name: "Huyện Thạch Thành",
        wards: [
          {
            id: "15187",
            name: "Thị trấn Kim Tân",
            level: "Thị trấn"
          },
          {
            id: "15190",
            name: "Thị trấn Vân Du",
            level: "Thị trấn"
          },
          {
            id: "15196",
            name: "Xã Thạch Lâm",
            level: "Xã"
          },
          {
            id: "15199",
            name: "Xã Thạch Quảng",
            level: "Xã"
          },
          {
            id: "15202",
            name: "Xã Thạch Tượng",
            level: "Xã"
          },
          {
            id: "15205",
            name: "Xã Thạch Cẩm",
            level: "Xã"
          },
          {
            id: "15208",
            name: "Xã Thạch Sơn",
            level: "Xã"
          },
          {
            id: "15211",
            name: "Xã Thạch Bình",
            level: "Xã"
          },
          {
            id: "15214",
            name: "Xã Thạch Định",
            level: "Xã"
          },
          {
            id: "15217",
            name: "Xã Thạch Đồng",
            level: "Xã"
          },
          {
            id: "15220",
            name: "Xã Thạch Long",
            level: "Xã"
          },
          {
            id: "15223",
            name: "Xã Thành Mỹ",
            level: "Xã"
          },
          {
            id: "15226",
            name: "Xã Thành Yên",
            level: "Xã"
          },
          {
            id: "15229",
            name: "Xã Thành Vinh",
            level: "Xã"
          },
          {
            id: "15232",
            name: "Xã Thành Minh",
            level: "Xã"
          },
          {
            id: "15235",
            name: "Xã Thành Công",
            level: "Xã"
          },
          {
            id: "15238",
            name: "Xã Thành Tân",
            level: "Xã"
          },
          {
            id: "15241",
            name: "Xã Thành Trực",
            level: "Xã"
          },
          {
            id: "15247",
            name: "Xã Thành Tâm",
            level: "Xã"
          },
          {
            id: "15250",
            name: "Xã Thành An",
            level: "Xã"
          },
          {
            id: "15253",
            name: "Xã Thành Thọ",
            level: "Xã"
          },
          {
            id: "15256",
            name: "Xã Thành Tiến",
            level: "Xã"
          },
          {
            id: "15259",
            name: "Xã Thành Long",
            level: "Xã"
          },
          {
            id: "15265",
            name: "Xã Thành Hưng",
            level: "Xã"
          },
          {
            id: "15268",
            name: "Xã Ngọc Trạo",
            level: "Xã"
          }
        ]
      },
      {
        id: "392",
        name: "Huyện Hà Trung",
        wards: [
          {
            id: "15271",
            name: "Thị trấn Hà Trung",
            level: "Thị trấn"
          },
          {
            id: "15274",
            name: "Xã Hà Long",
            level: "Xã"
          },
          {
            id: "15277",
            name: "Xã Hà Vinh",
            level: "Xã"
          },
          {
            id: "15280",
            name: "Xã Hà Bắc",
            level: "Xã"
          },
          {
            id: "15283",
            name: "Xã Hoạt Giang",
            level: "Xã"
          },
          {
            id: "15286",
            name: "Xã Yên Dương",
            level: "Xã"
          },
          {
            id: "15292",
            name: "Xã Hà Giang",
            level: "Xã"
          },
          {
            id: "15298",
            name: "Xã Lĩnh Toại",
            level: "Xã"
          },
          {
            id: "15304",
            name: "Xã Hà Ngọc",
            level: "Xã"
          },
          {
            id: "15307",
            name: "Xã Yến Sơn",
            level: "Xã"
          },
          {
            id: "15313",
            name: "Xã Hà Sơn",
            level: "Xã"
          },
          {
            id: "15316",
            name: "Xã Hà Lĩnh",
            level: "Xã"
          },
          {
            id: "15319",
            name: "Xã Hà Đông",
            level: "Xã"
          },
          {
            id: "15322",
            name: "Xã Hà Tân",
            level: "Xã"
          },
          {
            id: "15325",
            name: "Xã Hà Tiến",
            level: "Xã"
          },
          {
            id: "15328",
            name: "Xã Hà Bình",
            level: "Xã"
          },
          {
            id: "15331",
            name: "Xã Hà Lai",
            level: "Xã"
          },
          {
            id: "15334",
            name: "Xã Hà Châu",
            level: "Xã"
          },
          {
            id: "15340",
            name: "Xã Hà Thái",
            level: "Xã"
          },
          {
            id: "15343",
            name: "Xã Hà Hải",
            level: "Xã"
          }
        ]
      },
      {
        id: "393",
        name: "Huyện Vĩnh Lộc",
        wards: [
          {
            id: "15349",
            name: "Thị trấn Vĩnh Lộc",
            level: "Thị trấn"
          },
          {
            id: "15352",
            name: "Xã Vĩnh Quang",
            level: "Xã"
          },
          {
            id: "15355",
            name: "Xã Vĩnh Yên",
            level: "Xã"
          },
          {
            id: "15358",
            name: "Xã Vĩnh Tiến",
            level: "Xã"
          },
          {
            id: "15361",
            name: "Xã Vĩnh Long",
            level: "Xã"
          },
          {
            id: "15364",
            name: "Xã Vĩnh Phúc",
            level: "Xã"
          },
          {
            id: "15367",
            name: "Xã Vĩnh Hưng",
            level: "Xã"
          },
          {
            id: "15376",
            name: "Xã Vĩnh Hòa",
            level: "Xã"
          },
          {
            id: "15379",
            name: "Xã Vĩnh Hùng",
            level: "Xã"
          },
          {
            id: "15382",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "15385",
            name: "Xã Ninh Khang",
            level: "Xã"
          },
          {
            id: "15388",
            name: "Xã Vĩnh Thịnh",
            level: "Xã"
          },
          {
            id: "15391",
            name: "Xã Vĩnh An",
            level: "Xã"
          }
        ]
      },
      {
        id: "394",
        name: "Huyện Yên Định",
        wards: [
          {
            id: "15397",
            name: "Thị trấn Thống Nhất",
            level: "Thị trấn"
          },
          {
            id: "15403",
            name: "Xã Yên Lâm",
            level: "Xã"
          },
          {
            id: "15406",
            name: "Xã Yên Tâm",
            level: "Xã"
          },
          {
            id: "15409",
            name: "Xã Yên Phú",
            level: "Xã"
          },
          {
            id: "15412",
            name: "Xã Quí Lộc",
            level: "Xã"
          },
          {
            id: "15415",
            name: "Xã Yên Thọ",
            level: "Xã"
          },
          {
            id: "15418",
            name: "Xã Yên Trung",
            level: "Xã"
          },
          {
            id: "15421",
            name: "Xã Yên Trường",
            level: "Xã"
          },
          {
            id: "15427",
            name: "Xã Yên Phong",
            level: "Xã"
          },
          {
            id: "15430",
            name: "Xã Yên Thái",
            level: "Xã"
          },
          {
            id: "15433",
            name: "Xã Yên Hùng",
            level: "Xã"
          },
          {
            id: "15436",
            name: "Xã Yên Thịnh",
            level: "Xã"
          },
          {
            id: "15439",
            name: "Xã Yên Ninh",
            level: "Xã"
          },
          {
            id: "15442",
            name: "Xã Yên Lạc",
            level: "Xã"
          },
          {
            id: "15445",
            name: "Xã Định Tăng",
            level: "Xã"
          },
          {
            id: "15448",
            name: "Xã Định Hòa",
            level: "Xã"
          },
          {
            id: "15451",
            name: "Xã Định Thành",
            level: "Xã"
          },
          {
            id: "15454",
            name: "Xã Định Công",
            level: "Xã"
          },
          {
            id: "15457",
            name: "Xã Định Tân",
            level: "Xã"
          },
          {
            id: "15460",
            name: "Xã Định Tiến",
            level: "Xã"
          },
          {
            id: "15463",
            name: "Xã Định Long",
            level: "Xã"
          },
          {
            id: "15466",
            name: "Xã Định Liên",
            level: "Xã"
          },
          {
            id: "15469",
            name: "Thị trấn Quán Lào",
            level: "Thị trấn"
          },
          {
            id: "15472",
            name: "Xã Định Hưng",
            level: "Xã"
          },
          {
            id: "15475",
            name: "Xã Định Hải",
            level: "Xã"
          },
          {
            id: "15478",
            name: "Xã Định Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "395",
        name: "Huyện Thọ Xuân",
        wards: [
          {
            id: "15493",
            name: "Xã Xuân Hồng",
            level: "Xã"
          },
          {
            id: "15499",
            name: "Thị trấn Thọ Xuân",
            level: "Thị trấn"
          },
          {
            id: "15502",
            name: "Xã Bắc Lương",
            level: "Xã"
          },
          {
            id: "15505",
            name: "Xã Nam Giang",
            level: "Xã"
          },
          {
            id: "15508",
            name: "Xã Xuân Phong",
            level: "Xã"
          },
          {
            id: "15511",
            name: "Xã Thọ Lộc",
            level: "Xã"
          },
          {
            id: "15514",
            name: "Xã Xuân Trường",
            level: "Xã"
          },
          {
            id: "15517",
            name: "Xã Xuân Hòa",
            level: "Xã"
          },
          {
            id: "15520",
            name: "Xã Thọ Hải",
            level: "Xã"
          },
          {
            id: "15523",
            name: "Xã Tây Hồ",
            level: "Xã"
          },
          {
            id: "15526",
            name: "Xã Xuân Giang",
            level: "Xã"
          },
          {
            id: "15532",
            name: "Xã Xuân Sinh",
            level: "Xã"
          },
          {
            id: "15535",
            name: "Xã Xuân Hưng",
            level: "Xã"
          },
          {
            id: "15538",
            name: "Xã Thọ Diên",
            level: "Xã"
          },
          {
            id: "15541",
            name: "Xã Thọ Lâm",
            level: "Xã"
          },
          {
            id: "15544",
            name: "Xã Thọ Xương",
            level: "Xã"
          },
          {
            id: "15547",
            name: "Xã Xuân Bái",
            level: "Xã"
          },
          {
            id: "15550",
            name: "Xã Xuân Phú",
            level: "Xã"
          },
          {
            id: "15553",
            name: "Thị trấn Sao Vàng",
            level: "Thị trấn"
          },
          {
            id: "15556",
            name: "Thị trấn Lam Sơn",
            level: "Thị trấn"
          },
          {
            id: "15559",
            name: "Xã Xuân Thiên",
            level: "Xã"
          },
          {
            id: "15565",
            name: "Xã Thuận Minh",
            level: "Xã"
          },
          {
            id: "15568",
            name: "Xã Thọ Lập",
            level: "Xã"
          },
          {
            id: "15571",
            name: "Xã Quảng Phú",
            level: "Xã"
          },
          {
            id: "15574",
            name: "Xã Xuân Tín",
            level: "Xã"
          },
          {
            id: "15577",
            name: "Xã Phú Xuân",
            level: "Xã"
          },
          {
            id: "15583",
            name: "Xã Xuân Lai",
            level: "Xã"
          },
          {
            id: "15586",
            name: "Xã Xuân Lập",
            level: "Xã"
          },
          {
            id: "15592",
            name: "Xã Xuân Minh",
            level: "Xã"
          },
          {
            id: "15598",
            name: "Xã Trường Xuân",
            level: "Xã"
          }
        ]
      },
      {
        id: "396",
        name: "Huyện Thường Xuân",
        wards: [
          {
            id: "15607",
            name: "Xã Bát Mọt",
            level: "Xã"
          },
          {
            id: "15610",
            name: "Xã Yên Nhân",
            level: "Xã"
          },
          {
            id: "15619",
            name: "Xã Xuân Lẹ",
            level: "Xã"
          },
          {
            id: "15622",
            name: "Xã Vạn Xuân",
            level: "Xã"
          },
          {
            id: "15628",
            name: "Xã Lương Sơn",
            level: "Xã"
          },
          {
            id: "15631",
            name: "Xã Xuân Cao",
            level: "Xã"
          },
          {
            id: "15634",
            name: "Xã Luận Thành",
            level: "Xã"
          },
          {
            id: "15637",
            name: "Xã Luận Khê",
            level: "Xã"
          },
          {
            id: "15640",
            name: "Xã Xuân Thắng",
            level: "Xã"
          },
          {
            id: "15643",
            name: "Xã Xuân Lộc",
            level: "Xã"
          },
          {
            id: "15646",
            name: "Thị trấn Thường Xuân",
            level: "Thị trấn"
          },
          {
            id: "15649",
            name: "Xã Xuân Dương",
            level: "Xã"
          },
          {
            id: "15652",
            name: "Xã Thọ Thanh",
            level: "Xã"
          },
          {
            id: "15655",
            name: "Xã Ngọc Phụng",
            level: "Xã"
          },
          {
            id: "15658",
            name: "Xã Xuân Chinh",
            level: "Xã"
          },
          {
            id: "15661",
            name: "Xã Tân Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "397",
        name: "Huyện Triệu Sơn",
        wards: [
          {
            id: "15664",
            name: "Thị trấn Triệu Sơn",
            level: "Thị trấn"
          },
          {
            id: "15667",
            name: "Xã Thọ Sơn",
            level: "Xã"
          },
          {
            id: "15670",
            name: "Xã Thọ Bình",
            level: "Xã"
          },
          {
            id: "15673",
            name: "Xã Thọ Tiến",
            level: "Xã"
          },
          {
            id: "15676",
            name: "Xã Hợp Lý",
            level: "Xã"
          },
          {
            id: "15679",
            name: "Xã Hợp Tiến",
            level: "Xã"
          },
          {
            id: "15682",
            name: "Xã Hợp Thành",
            level: "Xã"
          },
          {
            id: "15685",
            name: "Xã Triệu Thành",
            level: "Xã"
          },
          {
            id: "15688",
            name: "Xã Hợp Thắng",
            level: "Xã"
          },
          {
            id: "15691",
            name: "Xã Minh Sơn",
            level: "Xã"
          },
          {
            id: "15700",
            name: "Xã Dân Lực",
            level: "Xã"
          },
          {
            id: "15703",
            name: "Xã Dân Lý",
            level: "Xã"
          },
          {
            id: "15706",
            name: "Xã Dân Quyền",
            level: "Xã"
          },
          {
            id: "15709",
            name: "Xã An Nông",
            level: "Xã"
          },
          {
            id: "15712",
            name: "Xã Văn Sơn",
            level: "Xã"
          },
          {
            id: "15715",
            name: "Xã Thái Hòa",
            level: "Xã"
          },
          {
            id: "15718",
            name: "Thị trấn Nưa",
            level: "Thị trấn"
          },
          {
            id: "15721",
            name: "Xã Đồng Lợi",
            level: "Xã"
          },
          {
            id: "15724",
            name: "Xã Đồng Tiến",
            level: "Xã"
          },
          {
            id: "15727",
            name: "Xã Đồng Thắng",
            level: "Xã"
          },
          {
            id: "15730",
            name: "Xã Tiến Nông",
            level: "Xã"
          },
          {
            id: "15733",
            name: "Xã Khuyến Nông",
            level: "Xã"
          },
          {
            id: "15736",
            name: "Xã Xuân Thịnh",
            level: "Xã"
          },
          {
            id: "15739",
            name: "Xã Xuân Lộc",
            level: "Xã"
          },
          {
            id: "15742",
            name: "Xã Thọ Dân",
            level: "Xã"
          },
          {
            id: "15745",
            name: "Xã Xuân Thọ",
            level: "Xã"
          },
          {
            id: "15748",
            name: "Xã Thọ Tân",
            level: "Xã"
          },
          {
            id: "15751",
            name: "Xã Thọ Ngọc",
            level: "Xã"
          },
          {
            id: "15754",
            name: "Xã Thọ Cường",
            level: "Xã"
          },
          {
            id: "15757",
            name: "Xã Thọ Phú",
            level: "Xã"
          },
          {
            id: "15760",
            name: "Xã Thọ Vực",
            level: "Xã"
          },
          {
            id: "15763",
            name: "Xã Thọ Thế",
            level: "Xã"
          },
          {
            id: "15766",
            name: "Xã Nông Trường",
            level: "Xã"
          },
          {
            id: "15769",
            name: "Xã Bình Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "398",
        name: "Huyện Thiệu Hóa",
        wards: [
          {
            id: "15772",
            name: "Thị trấn Thiệu Hóa",
            level: "Thị trấn"
          },
          {
            id: "15775",
            name: "Xã Thiệu Ngọc",
            level: "Xã"
          },
          {
            id: "15778",
            name: "Xã Thiệu Vũ",
            level: "Xã"
          },
          {
            id: "15781",
            name: "Xã Thiệu Phúc",
            level: "Xã"
          },
          {
            id: "15784",
            name: "Xã Thiệu Tiến",
            level: "Xã"
          },
          {
            id: "15787",
            name: "Xã Thiệu Công",
            level: "Xã"
          },
          {
            id: "15790",
            name: "Xã Thiệu Phú",
            level: "Xã"
          },
          {
            id: "15793",
            name: "Xã Thiệu Long",
            level: "Xã"
          },
          {
            id: "15796",
            name: "Xã Thiệu Giang",
            level: "Xã"
          },
          {
            id: "15799",
            name: "Xã Thiệu Duy",
            level: "Xã"
          },
          {
            id: "15802",
            name: "Xã Thiệu Nguyên",
            level: "Xã"
          },
          {
            id: "15805",
            name: "Xã Thiệu Hợp",
            level: "Xã"
          },
          {
            id: "15808",
            name: "Xã Thiệu Thịnh",
            level: "Xã"
          },
          {
            id: "15811",
            name: "Xã Thiệu Quang",
            level: "Xã"
          },
          {
            id: "15814",
            name: "Xã Thiệu Thành",
            level: "Xã"
          },
          {
            id: "15817",
            name: "Xã Thiệu Toán",
            level: "Xã"
          },
          {
            id: "15820",
            name: "Xã Thiệu Chính",
            level: "Xã"
          },
          {
            id: "15823",
            name: "Xã Thiệu Hòa",
            level: "Xã"
          },
          {
            id: "15829",
            name: "Xã Minh Tâm",
            level: "Xã"
          },
          {
            id: "15832",
            name: "Xã Thiệu Viên",
            level: "Xã"
          },
          {
            id: "15835",
            name: "Xã Thiệu Lý",
            level: "Xã"
          },
          {
            id: "15838",
            name: "Xã Thiệu Vận",
            level: "Xã"
          },
          {
            id: "15841",
            name: "Xã Thiệu Trung",
            level: "Xã"
          },
          {
            id: "15847",
            name: "Xã Tân Châu",
            level: "Xã"
          },
          {
            id: "15853",
            name: "Xã Thiệu Giao",
            level: "Xã"
          }
        ]
      },
      {
        id: "399",
        name: "Huyện Hoằng Hóa",
        wards: [
          {
            id: "15865",
            name: "Thị trấn Bút Sơn",
            level: "Thị trấn"
          },
          {
            id: "15871",
            name: "Xã Hoằng Giang",
            level: "Xã"
          },
          {
            id: "15877",
            name: "Xã Hoằng Xuân",
            level: "Xã"
          },
          {
            id: "15880",
            name: "Xã Hoằng Phượng",
            level: "Xã"
          },
          {
            id: "15883",
            name: "Xã Hoằng Phú",
            level: "Xã"
          },
          {
            id: "15886",
            name: "Xã Hoằng Quỳ",
            level: "Xã"
          },
          {
            id: "15889",
            name: "Xã Hoằng Kim",
            level: "Xã"
          },
          {
            id: "15892",
            name: "Xã Hoằng Trung",
            level: "Xã"
          },
          {
            id: "15895",
            name: "Xã Hoằng Trinh",
            level: "Xã"
          },
          {
            id: "15901",
            name: "Xã Hoằng Sơn",
            level: "Xã"
          },
          {
            id: "15907",
            name: "Xã Hoằng Cát",
            level: "Xã"
          },
          {
            id: "15910",
            name: "Xã Hoằng Xuyên",
            level: "Xã"
          },
          {
            id: "15916",
            name: "Xã Hoằng Quý",
            level: "Xã"
          },
          {
            id: "15919",
            name: "Xã Hoằng Hợp",
            level: "Xã"
          },
          {
            id: "15928",
            name: "Xã Hoằng Đức",
            level: "Xã"
          },
          {
            id: "15937",
            name: "Xã Hoằng Hà",
            level: "Xã"
          },
          {
            id: "15940",
            name: "Xã Hoằng Đạt",
            level: "Xã"
          },
          {
            id: "15946",
            name: "Xã Hoằng Đạo",
            level: "Xã"
          },
          {
            id: "15949",
            name: "Xã Hoằng Thắng",
            level: "Xã"
          },
          {
            id: "15952",
            name: "Xã Hoằng Đồng",
            level: "Xã"
          },
          {
            id: "15955",
            name: "Xã Hoằng Thái",
            level: "Xã"
          },
          {
            id: "15958",
            name: "Xã Hoằng Thịnh",
            level: "Xã"
          },
          {
            id: "15961",
            name: "Xã Hoằng Thành",
            level: "Xã"
          },
          {
            id: "15964",
            name: "Xã Hoằng Lộc",
            level: "Xã"
          },
          {
            id: "15967",
            name: "Xã Hoằng Trạch",
            level: "Xã"
          },
          {
            id: "15973",
            name: "Xã Hoằng Phong",
            level: "Xã"
          },
          {
            id: "15976",
            name: "Xã Hoằng Lưu",
            level: "Xã"
          },
          {
            id: "15979",
            name: "Xã Hoằng Châu",
            level: "Xã"
          },
          {
            id: "15982",
            name: "Xã Hoằng Tân",
            level: "Xã"
          },
          {
            id: "15985",
            name: "Xã Hoằng Yến",
            level: "Xã"
          },
          {
            id: "15988",
            name: "Xã Hoằng Tiến",
            level: "Xã"
          },
          {
            id: "15991",
            name: "Xã Hoằng Hải",
            level: "Xã"
          },
          {
            id: "15994",
            name: "Xã Hoằng Ngọc",
            level: "Xã"
          },
          {
            id: "15997",
            name: "Xã Hoằng Đông",
            level: "Xã"
          },
          {
            id: "16000",
            name: "Xã Hoằng Thanh",
            level: "Xã"
          },
          {
            id: "16003",
            name: "Xã Hoằng Phụ",
            level: "Xã"
          },
          {
            id: "16006",
            name: "Xã Hoằng Trường",
            level: "Xã"
          }
        ]
      },
      {
        id: "400",
        name: "Huyện Hậu Lộc",
        wards: [
          {
            id: "16012",
            name: "Thị trấn Hậu Lộc",
            level: "Thị trấn"
          },
          {
            id: "16015",
            name: "Xã Đồng Lộc",
            level: "Xã"
          },
          {
            id: "16018",
            name: "Xã Đại Lộc",
            level: "Xã"
          },
          {
            id: "16021",
            name: "Xã Triệu Lộc",
            level: "Xã"
          },
          {
            id: "16027",
            name: "Xã Tiến Lộc",
            level: "Xã"
          },
          {
            id: "16030",
            name: "Xã Lộc Sơn",
            level: "Xã"
          },
          {
            id: "16033",
            name: "Xã Cầu Lộc",
            level: "Xã"
          },
          {
            id: "16036",
            name: "Xã Thành Lộc",
            level: "Xã"
          },
          {
            id: "16039",
            name: "Xã Tuy Lộc",
            level: "Xã"
          },
          {
            id: "16042",
            name: "Xã Phong Lộc",
            level: "Xã"
          },
          {
            id: "16045",
            name: "Xã Mỹ Lộc",
            level: "Xã"
          },
          {
            id: "16048",
            name: "Xã Thuần Lộc",
            level: "Xã"
          },
          {
            id: "16057",
            name: "Xã Xuân Lộc",
            level: "Xã"
          },
          {
            id: "16063",
            name: "Xã Hoa Lộc",
            level: "Xã"
          },
          {
            id: "16066",
            name: "Xã Liên Lộc",
            level: "Xã"
          },
          {
            id: "16069",
            name: "Xã Quang Lộc",
            level: "Xã"
          },
          {
            id: "16072",
            name: "Xã Phú Lộc",
            level: "Xã"
          },
          {
            id: "16075",
            name: "Xã Hòa Lộc",
            level: "Xã"
          },
          {
            id: "16078",
            name: "Xã Minh Lộc",
            level: "Xã"
          },
          {
            id: "16081",
            name: "Xã Hưng Lộc",
            level: "Xã"
          },
          {
            id: "16084",
            name: "Xã Hải Lộc",
            level: "Xã"
          },
          {
            id: "16087",
            name: "Xã Đa Lộc",
            level: "Xã"
          },
          {
            id: "16090",
            name: "Xã Ngư Lộc",
            level: "Xã"
          }
        ]
      },
      {
        id: "401",
        name: "Huyện Nga Sơn",
        wards: [
          {
            id: "16093",
            name: "Thị trấn Nga Sơn",
            level: "Thị trấn"
          },
          {
            id: "16096",
            name: "Xã Ba Đình",
            level: "Xã"
          },
          {
            id: "16099",
            name: "Xã Nga Vịnh",
            level: "Xã"
          },
          {
            id: "16102",
            name: "Xã Nga Văn",
            level: "Xã"
          },
          {
            id: "16105",
            name: "Xã Nga Thiện",
            level: "Xã"
          },
          {
            id: "16108",
            name: "Xã Nga Tiến",
            level: "Xã"
          },
          {
            id: "16114",
            name: "Xã Nga Phượng",
            level: "Xã"
          },
          {
            id: "16117",
            name: "Xã Nga Trung",
            level: "Xã"
          },
          {
            id: "16120",
            name: "Xã Nga Bạch",
            level: "Xã"
          },
          {
            id: "16123",
            name: "Xã Nga Thanh",
            level: "Xã"
          },
          {
            id: "16132",
            name: "Xã Nga Yên",
            level: "Xã"
          },
          {
            id: "16135",
            name: "Xã Nga Giáp",
            level: "Xã"
          },
          {
            id: "16138",
            name: "Xã Nga Hải",
            level: "Xã"
          },
          {
            id: "16141",
            name: "Xã Nga Thành",
            level: "Xã"
          },
          {
            id: "16144",
            name: "Xã Nga An",
            level: "Xã"
          },
          {
            id: "16147",
            name: "Xã Nga Phú",
            level: "Xã"
          },
          {
            id: "16150",
            name: "Xã Nga Điền",
            level: "Xã"
          },
          {
            id: "16153",
            name: "Xã Nga Tân",
            level: "Xã"
          },
          {
            id: "16156",
            name: "Xã Nga Thủy",
            level: "Xã"
          },
          {
            id: "16159",
            name: "Xã Nga Liên",
            level: "Xã"
          },
          {
            id: "16162",
            name: "Xã Nga Thái",
            level: "Xã"
          },
          {
            id: "16165",
            name: "Xã Nga Thạch",
            level: "Xã"
          },
          {
            id: "16168",
            name: "Xã Nga Thắng",
            level: "Xã"
          },
          {
            id: "16171",
            name: "Xã Nga Trường",
            level: "Xã"
          }
        ]
      },
      {
        id: "402",
        name: "Huyện Như Xuân",
        wards: [
          {
            id: "16174",
            name: "Thị trấn Yên Cát",
            level: "Thị trấn"
          },
          {
            id: "16177",
            name: "Xã Bãi Trành",
            level: "Xã"
          },
          {
            id: "16180",
            name: "Xã Xuân Hòa",
            level: "Xã"
          },
          {
            id: "16183",
            name: "Xã Xuân Bình",
            level: "Xã"
          },
          {
            id: "16186",
            name: "Xã Hóa Quỳ",
            level: "Xã"
          },
          {
            id: "16195",
            name: "Xã Cát Vân",
            level: "Xã"
          },
          {
            id: "16198",
            name: "Xã Cát Tân",
            level: "Xã"
          },
          {
            id: "16201",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "16204",
            name: "Xã Bình Lương",
            level: "Xã"
          },
          {
            id: "16207",
            name: "Xã Thanh Quân",
            level: "Xã"
          },
          {
            id: "16210",
            name: "Xã Thanh Xuân",
            level: "Xã"
          },
          {
            id: "16213",
            name: "Xã Thanh Hòa",
            level: "Xã"
          },
          {
            id: "16216",
            name: "Xã Thanh Phong",
            level: "Xã"
          },
          {
            id: "16219",
            name: "Xã Thanh Lâm",
            level: "Xã"
          },
          {
            id: "16222",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "16225",
            name: "Xã Thượng Ninh",
            level: "Xã"
          }
        ]
      },
      {
        id: "403",
        name: "Huyện Như Thanh",
        wards: [
          {
            id: "16228",
            name: "Thị trấn Bến Sung",
            level: "Thị trấn"
          },
          {
            id: "16231",
            name: "Xã Cán Khê",
            level: "Xã"
          },
          {
            id: "16234",
            name: "Xã Xuân Du",
            level: "Xã"
          },
          {
            id: "16240",
            name: "Xã Phượng Nghi",
            level: "Xã"
          },
          {
            id: "16243",
            name: "Xã Mậu Lâm",
            level: "Xã"
          },
          {
            id: "16246",
            name: "Xã Xuân Khang",
            level: "Xã"
          },
          {
            id: "16249",
            name: "Xã Phú Nhuận",
            level: "Xã"
          },
          {
            id: "16252",
            name: "Xã Hải Long",
            level: "Xã"
          },
          {
            id: "16258",
            name: "Xã Xuân Thái",
            level: "Xã"
          },
          {
            id: "16261",
            name: "Xã Xuân Phúc",
            level: "Xã"
          },
          {
            id: "16264",
            name: "Xã Yên Thọ",
            level: "Xã"
          },
          {
            id: "16267",
            name: "Xã Yên Lạc",
            level: "Xã"
          },
          {
            id: "16273",
            name: "Xã Thanh Tân",
            level: "Xã"
          },
          {
            id: "16276",
            name: "Xã Thanh Kỳ",
            level: "Xã"
          }
        ]
      },
      {
        id: "404",
        name: "Huyện Nông Cống",
        wards: [
          {
            id: "16279",
            name: "Thị trấn Nông Cống",
            level: "Thị trấn"
          },
          {
            id: "16282",
            name: "Xã Tân Phúc",
            level: "Xã"
          },
          {
            id: "16285",
            name: "Xã Tân Thọ",
            level: "Xã"
          },
          {
            id: "16288",
            name: "Xã Hoàng Sơn",
            level: "Xã"
          },
          {
            id: "16291",
            name: "Xã Tân Khang",
            level: "Xã"
          },
          {
            id: "16294",
            name: "Xã Hoàng Giang",
            level: "Xã"
          },
          {
            id: "16297",
            name: "Xã Trung Chính",
            level: "Xã"
          },
          {
            id: "16303",
            name: "Xã Trung Thành",
            level: "Xã"
          },
          {
            id: "16309",
            name: "Xã Tế Thắng",
            level: "Xã"
          },
          {
            id: "16315",
            name: "Xã Tế Lợi",
            level: "Xã"
          },
          {
            id: "16318",
            name: "Xã Tế Nông",
            level: "Xã"
          },
          {
            id: "16321",
            name: "Xã Minh Nghĩa",
            level: "Xã"
          },
          {
            id: "16324",
            name: "Xã Minh Khôi",
            level: "Xã"
          },
          {
            id: "16327",
            name: "Xã Vạn Hòa",
            level: "Xã"
          },
          {
            id: "16330",
            name: "Xã Trường Trung",
            level: "Xã"
          },
          {
            id: "16333",
            name: "Xã Vạn Thắng",
            level: "Xã"
          },
          {
            id: "16336",
            name: "Xã Trường Giang",
            level: "Xã"
          },
          {
            id: "16339",
            name: "Xã Vạn Thiện",
            level: "Xã"
          },
          {
            id: "16342",
            name: "Xã Thăng Long",
            level: "Xã"
          },
          {
            id: "16345",
            name: "Xã Trường Minh",
            level: "Xã"
          },
          {
            id: "16348",
            name: "Xã Trường Sơn",
            level: "Xã"
          },
          {
            id: "16351",
            name: "Xã Thăng Bình",
            level: "Xã"
          },
          {
            id: "16354",
            name: "Xã Công Liêm",
            level: "Xã"
          },
          {
            id: "16357",
            name: "Xã Tượng Văn",
            level: "Xã"
          },
          {
            id: "16360",
            name: "Xã Thăng Thọ",
            level: "Xã"
          },
          {
            id: "16363",
            name: "Xã Tượng Lĩnh",
            level: "Xã"
          },
          {
            id: "16366",
            name: "Xã Tượng Sơn",
            level: "Xã"
          },
          {
            id: "16369",
            name: "Xã Công Chính",
            level: "Xã"
          },
          {
            id: "16375",
            name: "Xã Yên Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "405",
        name: "Huyện Đông Sơn",
        wards: [
          {
            id: "16378",
            name: "Thị trấn Rừng Thông",
            level: "Thị trấn"
          },
          {
            id: "16381",
            name: "Xã Đông Hoàng",
            level: "Xã"
          },
          {
            id: "16384",
            name: "Xã Đông Ninh",
            level: "Xã"
          },
          {
            id: "16390",
            name: "Xã Đông Hòa",
            level: "Xã"
          },
          {
            id: "16393",
            name: "Xã Đông Yên",
            level: "Xã"
          },
          {
            id: "16399",
            name: "Xã Đông Minh",
            level: "Xã"
          },
          {
            id: "16402",
            name: "Xã Đông Thanh",
            level: "Xã"
          },
          {
            id: "16405",
            name: "Xã Đông Tiến",
            level: "Xã"
          },
          {
            id: "16408",
            name: "Xã Đông Khê",
            level: "Xã"
          },
          {
            id: "16414",
            name: "Xã Đông Thịnh",
            level: "Xã"
          },
          {
            id: "16417",
            name: "Xã Đông Văn",
            level: "Xã"
          },
          {
            id: "16420",
            name: "Xã Đông Phú",
            level: "Xã"
          },
          {
            id: "16423",
            name: "Xã Đông Nam",
            level: "Xã"
          },
          {
            id: "16426",
            name: "Xã Đông Quang",
            level: "Xã"
          }
        ]
      },
      {
        id: "406",
        name: "Huyện Quảng Xương",
        wards: [
          {
            id: "16438",
            name: "Thị trấn Tân Phong",
            level: "Thị trấn"
          },
          {
            id: "16447",
            name: "Xã Quảng Trạch",
            level: "Xã"
          },
          {
            id: "16453",
            name: "Xã Quảng Đức",
            level: "Xã"
          },
          {
            id: "16456",
            name: "Xã Quảng Định",
            level: "Xã"
          },
          {
            id: "16462",
            name: "Xã Quảng Nhân",
            level: "Xã"
          },
          {
            id: "16465",
            name: "Xã Quảng Ninh",
            level: "Xã"
          },
          {
            id: "16468",
            name: "Xã Quảng Bình",
            level: "Xã"
          },
          {
            id: "16471",
            name: "Xã Quảng Hợp",
            level: "Xã"
          },
          {
            id: "16474",
            name: "Xã Quảng Văn",
            level: "Xã"
          },
          {
            id: "16477",
            name: "Xã Quảng Long",
            level: "Xã"
          },
          {
            id: "16480",
            name: "Xã Quảng Yên",
            level: "Xã"
          },
          {
            id: "16483",
            name: "Xã Quảng Hòa",
            level: "Xã"
          },
          {
            id: "16489",
            name: "Xã Quảng Khê",
            level: "Xã"
          },
          {
            id: "16492",
            name: "Xã Quảng Trung",
            level: "Xã"
          },
          {
            id: "16495",
            name: "Xã Quảng Chính",
            level: "Xã"
          },
          {
            id: "16498",
            name: "Xã Quảng Ngọc",
            level: "Xã"
          },
          {
            id: "16501",
            name: "Xã Quảng Trường",
            level: "Xã"
          },
          {
            id: "16510",
            name: "Xã Quảng Phúc",
            level: "Xã"
          },
          {
            id: "16519",
            name: "Xã Quảng Giao",
            level: "Xã"
          },
          {
            id: "16540",
            name: "Xã Quảng Hải",
            level: "Xã"
          },
          {
            id: "16543",
            name: "Xã Quảng Lưu",
            level: "Xã"
          },
          {
            id: "16546",
            name: "Xã Quảng Lộc",
            level: "Xã"
          },
          {
            id: "16549",
            name: "Xã Tiên Trang",
            level: "Xã"
          },
          {
            id: "16552",
            name: "Xã Quảng Nham",
            level: "Xã"
          },
          {
            id: "16555",
            name: "Xã Quảng Thạch",
            level: "Xã"
          },
          {
            id: "16558",
            name: "Xã Quảng Thái",
            level: "Xã"
          }
        ]
      },
      {
        id: "407",
        name: "Thị xã Nghi Sơn",
        wards: [
          {
            id: "16561",
            name: "Phường Hải Hòa",
            level: "Phường"
          },
          {
            id: "16564",
            name: "Phường Hải Châu",
            level: "Phường"
          },
          {
            id: "16567",
            name: "Xã Thanh Thủy",
            level: "Xã"
          },
          {
            id: "16570",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "16576",
            name: "Phường Hải Ninh",
            level: "Phường"
          },
          {
            id: "16579",
            name: "Xã Anh Sơn",
            level: "Xã"
          },
          {
            id: "16582",
            name: "Xã Ngọc Lĩnh",
            level: "Xã"
          },
          {
            id: "16585",
            name: "Phường Hải An",
            level: "Phường"
          },
          {
            id: "16591",
            name: "Xã Các Sơn",
            level: "Xã"
          },
          {
            id: "16594",
            name: "Phường Tân Dân",
            level: "Phường"
          },
          {
            id: "16597",
            name: "Phường Hải Lĩnh",
            level: "Phường"
          },
          {
            id: "16600",
            name: "Xã Định Hải",
            level: "Xã"
          },
          {
            id: "16603",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "16606",
            name: "Phường Ninh Hải",
            level: "Phường"
          },
          {
            id: "16609",
            name: "Phường Nguyên Bình",
            level: "Phường"
          },
          {
            id: "16612",
            name: "Xã Hải Nhân",
            level: "Xã"
          },
          {
            id: "16618",
            name: "Phường Bình Minh",
            level: "Phường"
          },
          {
            id: "16621",
            name: "Phường Hải Thanh",
            level: "Phường"
          },
          {
            id: "16624",
            name: "Xã Phú Lâm",
            level: "Xã"
          },
          {
            id: "16627",
            name: "Phường Xuân Lâm",
            level: "Phường"
          },
          {
            id: "16630",
            name: "Phường Trúc Lâm",
            level: "Phường"
          },
          {
            id: "16633",
            name: "Phường Hải Bình",
            level: "Phường"
          },
          {
            id: "16636",
            name: "Xã Tân Trường",
            level: "Xã"
          },
          {
            id: "16639",
            name: "Xã Tùng Lâm",
            level: "Xã"
          },
          {
            id: "16642",
            name: "Phường Tĩnh Hải",
            level: "Phường"
          },
          {
            id: "16645",
            name: "Phường Mai Lâm",
            level: "Phường"
          },
          {
            id: "16648",
            name: "Xã Trường Lâm",
            level: "Xã"
          },
          {
            id: "16651",
            name: "Xã Hải Yến",
            level: "Xã"
          },
          {
            id: "16654",
            name: "Phường Hải Thượng",
            level: "Phường"
          },
          {
            id: "16657",
            name: "Xã Nghi Sơn",
            level: "Xã"
          },
          {
            id: "16660",
            name: "Xã Hải Hà",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "40",
    name: "Tỉnh Nghệ An",
    districts: [
      {
        id: "412",
        name: "Thành phố Vinh",
        wards: [
          {
            id: "16663",
            name: "Phường Đông Vĩnh",
            level: "Phường"
          },
          {
            id: "16666",
            name: "Phường Hà Huy Tập",
            level: "Phường"
          },
          {
            id: "16669",
            name: "Phường Lê Lợi",
            level: "Phường"
          },
          {
            id: "16670",
            name: "Phường Quán Bàu",
            level: "Phường"
          },
          {
            id: "16672",
            name: "Phường Hưng Bình",
            level: "Phường"
          },
          {
            id: "16673",
            name: "Phường Hưng Phúc",
            level: "Phường"
          },
          {
            id: "16675",
            name: "Phường Hưng Dũng",
            level: "Phường"
          },
          {
            id: "16678",
            name: "Phường Cửa Nam",
            level: "Phường"
          },
          {
            id: "16681",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "16684",
            name: "Phường Đội Cung",
            level: "Phường"
          },
          {
            id: "16687",
            name: "Phường Lê Mao",
            level: "Phường"
          },
          {
            id: "16690",
            name: "Phường Trường Thi",
            level: "Phường"
          },
          {
            id: "16693",
            name: "Phường Bến Thủy",
            level: "Phường"
          },
          {
            id: "16696",
            name: "Phường Hồng Sơn",
            level: "Phường"
          },
          {
            id: "16699",
            name: "Phường Trung Đô",
            level: "Phường"
          },
          {
            id: "16702",
            name: "Xã Nghi Phú",
            level: "Xã"
          },
          {
            id: "16705",
            name: "Xã Hưng Đông",
            level: "Xã"
          },
          {
            id: "16708",
            name: "Xã Hưng Lộc",
            level: "Xã"
          },
          {
            id: "16711",
            name: "Xã Hưng Hòa",
            level: "Xã"
          },
          {
            id: "16714",
            name: "Phường Vinh Tân",
            level: "Phường"
          },
          {
            id: "17908",
            name: "Xã Nghi Liên",
            level: "Xã"
          },
          {
            id: "17914",
            name: "Xã Nghi Ân",
            level: "Xã"
          },
          {
            id: "17920",
            name: "Xã Nghi Kim",
            level: "Xã"
          },
          {
            id: "17923",
            name: "Xã Nghi Đức",
            level: "Xã"
          },
          {
            id: "18013",
            name: "Xã Hưng Chính",
            level: "Xã"
          }
        ]
      },
      {
        id: "413",
        name: "Thị xã Cửa Lò",
        wards: [
          {
            id: "16717",
            name: "Phường Nghi Thuỷ",
            level: "Phường"
          },
          {
            id: "16720",
            name: "Phường Nghi Tân",
            level: "Phường"
          },
          {
            id: "16723",
            name: "Phường Thu Thuỷ",
            level: "Phường"
          },
          {
            id: "16726",
            name: "Phường Nghi Hòa",
            level: "Phường"
          },
          {
            id: "16729",
            name: "Phường Nghi Hải",
            level: "Phường"
          },
          {
            id: "16732",
            name: "Phường Nghi Hương",
            level: "Phường"
          },
          {
            id: "16735",
            name: "Phường Nghi Thu",
            level: "Phường"
          }
        ]
      },
      {
        id: "414",
        name: "Thị xã Thái Hoà",
        wards: [
          {
            id: "16939",
            name: "Phường Hoà Hiếu",
            level: "Phường"
          },
          {
            id: "16993",
            name: "Phường Quang Phong",
            level: "Phường"
          },
          {
            id: "16994",
            name: "Phường Quang Tiến",
            level: "Phường"
          },
          {
            id: "17003",
            name: "Phường Long Sơn",
            level: "Phường"
          },
          {
            id: "17005",
            name: "Xã Nghĩa Tiến",
            level: "Xã"
          },
          {
            id: "17008",
            name: "Xã Nghĩa Mỹ",
            level: "Xã"
          },
          {
            id: "17011",
            name: "Xã Tây Hiếu",
            level: "Xã"
          },
          {
            id: "17014",
            name: "Xã Nghĩa Thuận",
            level: "Xã"
          },
          {
            id: "17017",
            name: "Xã Đông Hiếu",
            level: "Xã"
          }
        ]
      },
      {
        id: "415",
        name: "Huyện Quế Phong",
        wards: [
          {
            id: "16738",
            name: "Thị trấn Kim Sơn",
            level: "Thị trấn"
          },
          {
            id: "16741",
            name: "Xã Thông Thụ",
            level: "Xã"
          },
          {
            id: "16744",
            name: "Xã Đồng Văn",
            level: "Xã"
          },
          {
            id: "16747",
            name: "Xã Hạnh Dịch",
            level: "Xã"
          },
          {
            id: "16750",
            name: "Xã Tiền Phong",
            level: "Xã"
          },
          {
            id: "16753",
            name: "Xã Nậm Giải",
            level: "Xã"
          },
          {
            id: "16756",
            name: "Xã Tri Lễ",
            level: "Xã"
          },
          {
            id: "16759",
            name: "Xã Châu Kim",
            level: "Xã"
          },
          {
            id: "16763",
            name: "Xã Mường Nọc",
            level: "Xã"
          },
          {
            id: "16765",
            name: "Xã Châu Thôn",
            level: "Xã"
          },
          {
            id: "16768",
            name: "Xã Nậm Nhoóng",
            level: "Xã"
          },
          {
            id: "16771",
            name: "Xã Quang Phong",
            level: "Xã"
          },
          {
            id: "16774",
            name: "Xã Căm Muộn",
            level: "Xã"
          }
        ]
      },
      {
        id: "416",
        name: "Huyện Quỳ Châu",
        wards: [
          {
            id: "16777",
            name: "Thị trấn Tân Lạc",
            level: "Thị trấn"
          },
          {
            id: "16780",
            name: "Xã Châu Bính",
            level: "Xã"
          },
          {
            id: "16783",
            name: "Xã Châu Thuận",
            level: "Xã"
          },
          {
            id: "16786",
            name: "Xã Châu Hội",
            level: "Xã"
          },
          {
            id: "16789",
            name: "Xã Châu Nga",
            level: "Xã"
          },
          {
            id: "16792",
            name: "Xã Châu Tiến",
            level: "Xã"
          },
          {
            id: "16795",
            name: "Xã Châu Hạnh",
            level: "Xã"
          },
          {
            id: "16798",
            name: "Xã Châu Thắng",
            level: "Xã"
          },
          {
            id: "16801",
            name: "Xã Châu Phong",
            level: "Xã"
          },
          {
            id: "16804",
            name: "Xã Châu Bình",
            level: "Xã"
          },
          {
            id: "16807",
            name: "Xã Châu Hoàn",
            level: "Xã"
          },
          {
            id: "16810",
            name: "Xã Diên Lãm",
            level: "Xã"
          }
        ]
      },
      {
        id: "417",
        name: "Huyện Kỳ Sơn",
        wards: [
          {
            id: "16813",
            name: "Thị trấn Mường Xén",
            level: "Thị trấn"
          },
          {
            id: "16816",
            name: "Xã Mỹ Lý",
            level: "Xã"
          },
          {
            id: "16819",
            name: "Xã Bắc Lý",
            level: "Xã"
          },
          {
            id: "16822",
            name: "Xã Keng Đu",
            level: "Xã"
          },
          {
            id: "16825",
            name: "Xã Đoọc Mạy",
            level: "Xã"
          },
          {
            id: "16828",
            name: "Xã Huồi Tụ",
            level: "Xã"
          },
          {
            id: "16831",
            name: "Xã Mường Lống",
            level: "Xã"
          },
          {
            id: "16834",
            name: "Xã Na Loi",
            level: "Xã"
          },
          {
            id: "16837",
            name: "Xã Nậm Cắn",
            level: "Xã"
          },
          {
            id: "16840",
            name: "Xã Bảo Nam",
            level: "Xã"
          },
          {
            id: "16843",
            name: "Xã Phà Đánh",
            level: "Xã"
          },
          {
            id: "16846",
            name: "Xã Bảo Thắng",
            level: "Xã"
          },
          {
            id: "16849",
            name: "Xã Hữu Lập",
            level: "Xã"
          },
          {
            id: "16852",
            name: "Xã Tà Cạ",
            level: "Xã"
          },
          {
            id: "16855",
            name: "Xã Chiêu Lưu",
            level: "Xã"
          },
          {
            id: "16858",
            name: "Xã Mường Típ",
            level: "Xã"
          },
          {
            id: "16861",
            name: "Xã Hữu Kiệm",
            level: "Xã"
          },
          {
            id: "16864",
            name: "Xã Tây Sơn",
            level: "Xã"
          },
          {
            id: "16867",
            name: "Xã Mường Ải",
            level: "Xã"
          },
          {
            id: "16870",
            name: "Xã Na Ngoi",
            level: "Xã"
          },
          {
            id: "16873",
            name: "Xã Nậm Càn",
            level: "Xã"
          }
        ]
      },
      {
        id: "418",
        name: "Huyện Tương Dương",
        wards: [
          {
            id: "16876",
            name: "Thị trấn Thạch Giám",
            level: "Thị trấn"
          },
          {
            id: "16879",
            name: "Xã Mai Sơn",
            level: "Xã"
          },
          {
            id: "16882",
            name: "Xã Nhôn Mai",
            level: "Xã"
          },
          {
            id: "16885",
            name: "Xã Hữu Khuông",
            level: "Xã"
          },
          {
            id: "16900",
            name: "Xã Yên Tĩnh",
            level: "Xã"
          },
          {
            id: "16903",
            name: "Xã Nga My",
            level: "Xã"
          },
          {
            id: "16904",
            name: "Xã Xiêng My",
            level: "Xã"
          },
          {
            id: "16906",
            name: "Xã Lưỡng Minh",
            level: "Xã"
          },
          {
            id: "16909",
            name: "Xã Yên Hòa",
            level: "Xã"
          },
          {
            id: "16912",
            name: "Xã Yên Na",
            level: "Xã"
          },
          {
            id: "16915",
            name: "Xã Lưu Kiền",
            level: "Xã"
          },
          {
            id: "16921",
            name: "Xã Xá Lượng",
            level: "Xã"
          },
          {
            id: "16924",
            name: "Xã Tam Thái",
            level: "Xã"
          },
          {
            id: "16927",
            name: "Xã Tam Đình",
            level: "Xã"
          },
          {
            id: "16930",
            name: "Xã Yên Thắng",
            level: "Xã"
          },
          {
            id: "16933",
            name: "Xã Tam Quang",
            level: "Xã"
          },
          {
            id: "16936",
            name: "Xã Tam Hợp",
            level: "Xã"
          }
        ]
      },
      {
        id: "419",
        name: "Huyện Nghĩa Đàn",
        wards: [
          {
            id: "16941",
            name: "Thị trấn Nghĩa Đàn",
            level: "Thị trấn"
          },
          {
            id: "16942",
            name: "Xã Nghĩa Mai",
            level: "Xã"
          },
          {
            id: "16945",
            name: "Xã Nghĩa Yên",
            level: "Xã"
          },
          {
            id: "16948",
            name: "Xã Nghĩa Lạc",
            level: "Xã"
          },
          {
            id: "16951",
            name: "Xã Nghĩa Lâm",
            level: "Xã"
          },
          {
            id: "16954",
            name: "Xã Nghĩa Sơn",
            level: "Xã"
          },
          {
            id: "16957",
            name: "Xã Nghĩa Lợi",
            level: "Xã"
          },
          {
            id: "16960",
            name: "Xã Nghĩa Bình",
            level: "Xã"
          },
          {
            id: "16963",
            name: "Xã Nghĩa Thọ",
            level: "Xã"
          },
          {
            id: "16966",
            name: "Xã Nghĩa Minh",
            level: "Xã"
          },
          {
            id: "16969",
            name: "Xã Nghĩa Phú",
            level: "Xã"
          },
          {
            id: "16972",
            name: "Xã Nghĩa Hưng",
            level: "Xã"
          },
          {
            id: "16975",
            name: "Xã Nghĩa Hồng",
            level: "Xã"
          },
          {
            id: "16978",
            name: "Xã Nghĩa Thịnh",
            level: "Xã"
          },
          {
            id: "16981",
            name: "Xã Nghĩa Trung",
            level: "Xã"
          },
          {
            id: "16984",
            name: "Xã Nghĩa Hội",
            level: "Xã"
          },
          {
            id: "16987",
            name: "Xã Nghĩa Thành",
            level: "Xã"
          },
          {
            id: "16996",
            name: "Xã Nghĩa Hiếu",
            level: "Xã"
          },
          {
            id: "17020",
            name: "Xã Nghĩa Đức",
            level: "Xã"
          },
          {
            id: "17023",
            name: "Xã Nghĩa An",
            level: "Xã"
          },
          {
            id: "17026",
            name: "Xã Nghĩa Long",
            level: "Xã"
          },
          {
            id: "17029",
            name: "Xã Nghĩa Lộc",
            level: "Xã"
          },
          {
            id: "17032",
            name: "Xã Nghĩa Khánh",
            level: "Xã"
          }
        ]
      },
      {
        id: "420",
        name: "Huyện Quỳ Hợp",
        wards: [
          {
            id: "17035",
            name: "Thị trấn Quỳ Hợp",
            level: "Thị trấn"
          },
          {
            id: "17038",
            name: "Xã Yên Hợp",
            level: "Xã"
          },
          {
            id: "17041",
            name: "Xã Châu Tiến",
            level: "Xã"
          },
          {
            id: "17044",
            name: "Xã Châu Hồng",
            level: "Xã"
          },
          {
            id: "17047",
            name: "Xã Đồng Hợp",
            level: "Xã"
          },
          {
            id: "17050",
            name: "Xã Châu Thành",
            level: "Xã"
          },
          {
            id: "17053",
            name: "Xã Liên Hợp",
            level: "Xã"
          },
          {
            id: "17056",
            name: "Xã Châu Lộc",
            level: "Xã"
          },
          {
            id: "17059",
            name: "Xã Tam Hợp",
            level: "Xã"
          },
          {
            id: "17062",
            name: "Xã Châu Cường",
            level: "Xã"
          },
          {
            id: "17065",
            name: "Xã Châu Quang",
            level: "Xã"
          },
          {
            id: "17068",
            name: "Xã Thọ Hợp",
            level: "Xã"
          },
          {
            id: "17071",
            name: "Xã Minh Hợp",
            level: "Xã"
          },
          {
            id: "17074",
            name: "Xã Nghĩa Xuân",
            level: "Xã"
          },
          {
            id: "17077",
            name: "Xã Châu Thái",
            level: "Xã"
          },
          {
            id: "17080",
            name: "Xã Châu Đình",
            level: "Xã"
          },
          {
            id: "17083",
            name: "Xã Văn Lợi",
            level: "Xã"
          },
          {
            id: "17086",
            name: "Xã Nam Sơn",
            level: "Xã"
          },
          {
            id: "17089",
            name: "Xã Châu Lý",
            level: "Xã"
          },
          {
            id: "17092",
            name: "Xã Hạ Sơn",
            level: "Xã"
          },
          {
            id: "17095",
            name: "Xã Bắc Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "421",
        name: "Huyện Quỳnh Lưu",
        wards: [
          {
            id: "17098",
            name: "Thị trấn Cầu Giát",
            level: "Thị trấn"
          },
          {
            id: "17101",
            name: "Xã Quỳnh Thắng",
            level: "Xã"
          },
          {
            id: "17119",
            name: "Xã Quỳnh Tân",
            level: "Xã"
          },
          {
            id: "17122",
            name: "Xã Quỳnh Châu",
            level: "Xã"
          },
          {
            id: "17140",
            name: "Xã Tân Sơn",
            level: "Xã"
          },
          {
            id: "17143",
            name: "Xã Quỳnh Văn",
            level: "Xã"
          },
          {
            id: "17146",
            name: "Xã Ngọc Sơn",
            level: "Xã"
          },
          {
            id: "17149",
            name: "Xã Quỳnh Tam",
            level: "Xã"
          },
          {
            id: "17152",
            name: "Xã Quỳnh Hoa",
            level: "Xã"
          },
          {
            id: "17155",
            name: "Xã Quỳnh Thạch",
            level: "Xã"
          },
          {
            id: "17158",
            name: "Xã Quỳnh Bảng",
            level: "Xã"
          },
          {
            id: "17161",
            name: "Xã Quỳnh Mỹ",
            level: "Xã"
          },
          {
            id: "17164",
            name: "Xã Quỳnh Thanh",
            level: "Xã"
          },
          {
            id: "17167",
            name: "Xã Quỳnh Hậu",
            level: "Xã"
          },
          {
            id: "17170",
            name: "Xã Quỳnh Lâm",
            level: "Xã"
          },
          {
            id: "17173",
            name: "Xã Quỳnh Đôi",
            level: "Xã"
          },
          {
            id: "17176",
            name: "Xã Quỳnh Lương",
            level: "Xã"
          },
          {
            id: "17179",
            name: "Xã Quỳnh Hồng",
            level: "Xã"
          },
          {
            id: "17182",
            name: "Xã Quỳnh Yên",
            level: "Xã"
          },
          {
            id: "17185",
            name: "Xã Quỳnh Bá",
            level: "Xã"
          },
          {
            id: "17188",
            name: "Xã Quỳnh Minh",
            level: "Xã"
          },
          {
            id: "17191",
            name: "Xã Quỳnh Diễn",
            level: "Xã"
          },
          {
            id: "17194",
            name: "Xã Quỳnh Hưng",
            level: "Xã"
          },
          {
            id: "17197",
            name: "Xã Quỳnh Giang",
            level: "Xã"
          },
          {
            id: "17200",
            name: "Xã Quỳnh Ngọc",
            level: "Xã"
          },
          {
            id: "17203",
            name: "Xã Quỳnh Nghĩa",
            level: "Xã"
          },
          {
            id: "17206",
            name: "Xã An Hòa",
            level: "Xã"
          },
          {
            id: "17209",
            name: "Xã Tiến Thủy",
            level: "Xã"
          },
          {
            id: "17212",
            name: "Xã Sơn Hải",
            level: "Xã"
          },
          {
            id: "17215",
            name: "Xã Quỳnh Thọ",
            level: "Xã"
          },
          {
            id: "17218",
            name: "Xã Quỳnh Thuận",
            level: "Xã"
          },
          {
            id: "17221",
            name: "Xã Quỳnh Long",
            level: "Xã"
          },
          {
            id: "17224",
            name: "Xã Tân Thắng",
            level: "Xã"
          }
        ]
      },
      {
        id: "422",
        name: "Huyện Con Cuông",
        wards: [
          {
            id: "17227",
            name: "Thị trấn Con Cuông",
            level: "Thị trấn"
          },
          {
            id: "17230",
            name: "Xã Bình Chuẩn",
            level: "Xã"
          },
          {
            id: "17233",
            name: "Xã Lạng Khê",
            level: "Xã"
          },
          {
            id: "17236",
            name: "Xã Cam Lâm",
            level: "Xã"
          },
          {
            id: "17239",
            name: "Xã Thạch Ngàn",
            level: "Xã"
          },
          {
            id: "17242",
            name: "Xã Đôn Phục",
            level: "Xã"
          },
          {
            id: "17245",
            name: "Xã Mậu Đức",
            level: "Xã"
          },
          {
            id: "17248",
            name: "Xã Châu Khê",
            level: "Xã"
          },
          {
            id: "17251",
            name: "Xã Chi Khê",
            level: "Xã"
          },
          {
            id: "17254",
            name: "Xã Bồng Khê",
            level: "Xã"
          },
          {
            id: "17257",
            name: "Xã Yên Khê",
            level: "Xã"
          },
          {
            id: "17260",
            name: "Xã Lục Dạ",
            level: "Xã"
          },
          {
            id: "17263",
            name: "Xã Môn Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "423",
        name: "Huyện Tân Kỳ",
        wards: [
          {
            id: "17266",
            name: "Thị trấn Tân Kỳ",
            level: "Thị trấn"
          },
          {
            id: "17269",
            name: "Xã Tân Hợp",
            level: "Xã"
          },
          {
            id: "17272",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "17275",
            name: "Xã Tân Xuân",
            level: "Xã"
          },
          {
            id: "17278",
            name: "Xã Giai Xuân",
            level: "Xã"
          },
          {
            id: "17281",
            name: "Xã Nghĩa Bình",
            level: "Xã"
          },
          {
            id: "17284",
            name: "Xã Nghĩa Đồng",
            level: "Xã"
          },
          {
            id: "17287",
            name: "Xã Đồng Văn",
            level: "Xã"
          },
          {
            id: "17290",
            name: "Xã Nghĩa Thái",
            level: "Xã"
          },
          {
            id: "17293",
            name: "Xã Nghĩa Hợp",
            level: "Xã"
          },
          {
            id: "17296",
            name: "Xã Nghĩa Hoàn",
            level: "Xã"
          },
          {
            id: "17299",
            name: "Xã Nghĩa Phúc",
            level: "Xã"
          },
          {
            id: "17302",
            name: "Xã Tiên Kỳ",
            level: "Xã"
          },
          {
            id: "17305",
            name: "Xã Tân An",
            level: "Xã"
          },
          {
            id: "17308",
            name: "Xã Nghĩa Dũng",
            level: "Xã"
          },
          {
            id: "17311",
            name: "Xã Tân Long",
            level: "Xã"
          },
          {
            id: "17314",
            name: "Xã Kỳ Sơn",
            level: "Xã"
          },
          {
            id: "17317",
            name: "Xã Hương Sơn",
            level: "Xã"
          },
          {
            id: "17320",
            name: "Xã Kỳ Tân",
            level: "Xã"
          },
          {
            id: "17323",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "17325",
            name: "Xã Tân Hương",
            level: "Xã"
          },
          {
            id: "17326",
            name: "Xã Nghĩa Hành",
            level: "Xã"
          }
        ]
      },
      {
        id: "424",
        name: "Huyện Anh Sơn",
        wards: [
          {
            id: "17329",
            name: "Thị trấn Anh Sơn",
            level: "Thị trấn"
          },
          {
            id: "17332",
            name: "Xã Thọ Sơn",
            level: "Xã"
          },
          {
            id: "17335",
            name: "Xã Thành Sơn",
            level: "Xã"
          },
          {
            id: "17338",
            name: "Xã Bình Sơn",
            level: "Xã"
          },
          {
            id: "17341",
            name: "Xã Tam Sơn",
            level: "Xã"
          },
          {
            id: "17344",
            name: "Xã Đỉnh Sơn",
            level: "Xã"
          },
          {
            id: "17347",
            name: "Xã Hùng Sơn",
            level: "Xã"
          },
          {
            id: "17350",
            name: "Xã Cẩm Sơn",
            level: "Xã"
          },
          {
            id: "17353",
            name: "Xã Đức Sơn",
            level: "Xã"
          },
          {
            id: "17356",
            name: "Xã Tường Sơn",
            level: "Xã"
          },
          {
            id: "17357",
            name: "Xã Hoa Sơn",
            level: "Xã"
          },
          {
            id: "17359",
            name: "Xã Tào Sơn",
            level: "Xã"
          },
          {
            id: "17362",
            name: "Xã Vĩnh Sơn",
            level: "Xã"
          },
          {
            id: "17365",
            name: "Xã Lạng Sơn",
            level: "Xã"
          },
          {
            id: "17368",
            name: "Xã Hội Sơn",
            level: "Xã"
          },
          {
            id: "17371",
            name: "Xã Thạch Sơn",
            level: "Xã"
          },
          {
            id: "17374",
            name: "Xã Phúc Sơn",
            level: "Xã"
          },
          {
            id: "17377",
            name: "Xã Long Sơn",
            level: "Xã"
          },
          {
            id: "17380",
            name: "Xã Khai Sơn",
            level: "Xã"
          },
          {
            id: "17383",
            name: "Xã Lĩnh Sơn",
            level: "Xã"
          },
          {
            id: "17386",
            name: "Xã Cao Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "425",
        name: "Huyện Diễn Châu",
        wards: [
          {
            id: "17389",
            name: "Thị trấn Diễn Châu",
            level: "Thị trấn"
          },
          {
            id: "17392",
            name: "Xã Diễn Lâm",
            level: "Xã"
          },
          {
            id: "17395",
            name: "Xã Diễn Đoài",
            level: "Xã"
          },
          {
            id: "17398",
            name: "Xã Diễn Trường",
            level: "Xã"
          },
          {
            id: "17401",
            name: "Xã Diễn Yên",
            level: "Xã"
          },
          {
            id: "17404",
            name: "Xã Diễn Hoàng",
            level: "Xã"
          },
          {
            id: "17407",
            name: "Xã Diễn Hùng",
            level: "Xã"
          },
          {
            id: "17410",
            name: "Xã Diễn Mỹ",
            level: "Xã"
          },
          {
            id: "17413",
            name: "Xã Diễn Hồng",
            level: "Xã"
          },
          {
            id: "17416",
            name: "Xã Diễn Phong",
            level: "Xã"
          },
          {
            id: "17419",
            name: "Xã Diễn Hải",
            level: "Xã"
          },
          {
            id: "17422",
            name: "Xã Diễn Tháp",
            level: "Xã"
          },
          {
            id: "17425",
            name: "Xã Diễn Liên",
            level: "Xã"
          },
          {
            id: "17428",
            name: "Xã Diễn Vạn",
            level: "Xã"
          },
          {
            id: "17431",
            name: "Xã Diễn Kim",
            level: "Xã"
          },
          {
            id: "17434",
            name: "Xã Diễn Kỷ",
            level: "Xã"
          },
          {
            id: "17437",
            name: "Xã Diễn Xuân",
            level: "Xã"
          },
          {
            id: "17440",
            name: "Xã Diễn Thái",
            level: "Xã"
          },
          {
            id: "17443",
            name: "Xã Diễn Đồng",
            level: "Xã"
          },
          {
            id: "17446",
            name: "Xã Diễn Bích",
            level: "Xã"
          },
          {
            id: "17449",
            name: "Xã Diễn Hạnh",
            level: "Xã"
          },
          {
            id: "17452",
            name: "Xã Diễn Ngọc",
            level: "Xã"
          },
          {
            id: "17455",
            name: "Xã Diễn Quảng",
            level: "Xã"
          },
          {
            id: "17458",
            name: "Xã Diễn Nguyên",
            level: "Xã"
          },
          {
            id: "17461",
            name: "Xã Diễn Hoa",
            level: "Xã"
          },
          {
            id: "17464",
            name: "Xã Diễn Thành",
            level: "Xã"
          },
          {
            id: "17467",
            name: "Xã Diễn Phúc",
            level: "Xã"
          },
          {
            id: "17476",
            name: "Xã Diễn Cát",
            level: "Xã"
          },
          {
            id: "17479",
            name: "Xã Diễn Thịnh",
            level: "Xã"
          },
          {
            id: "17482",
            name: "Xã Diễn Tân",
            level: "Xã"
          },
          {
            id: "17485",
            name: "Xã Minh Châu",
            level: "Xã"
          },
          {
            id: "17488",
            name: "Xã Diễn Thọ",
            level: "Xã"
          },
          {
            id: "17491",
            name: "Xã Diễn Lợi",
            level: "Xã"
          },
          {
            id: "17494",
            name: "Xã Diễn Lộc",
            level: "Xã"
          },
          {
            id: "17497",
            name: "Xã Diễn Trung",
            level: "Xã"
          },
          {
            id: "17500",
            name: "Xã Diễn An",
            level: "Xã"
          },
          {
            id: "17503",
            name: "Xã Diễn Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "426",
        name: "Huyện Yên Thành",
        wards: [
          {
            id: "17506",
            name: "Thị trấn Yên Thành",
            level: "Thị trấn"
          },
          {
            id: "17509",
            name: "Xã Mã Thành",
            level: "Xã"
          },
          {
            id: "17510",
            name: "Xã Tiến Thành",
            level: "Xã"
          },
          {
            id: "17512",
            name: "Xã Lăng Thành",
            level: "Xã"
          },
          {
            id: "17515",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "17518",
            name: "Xã Đức Thành",
            level: "Xã"
          },
          {
            id: "17521",
            name: "Xã Kim Thành",
            level: "Xã"
          },
          {
            id: "17524",
            name: "Xã Hậu Thành",
            level: "Xã"
          },
          {
            id: "17525",
            name: "Xã Hùng Thành",
            level: "Xã"
          },
          {
            id: "17527",
            name: "Xã Đô Thành",
            level: "Xã"
          },
          {
            id: "17530",
            name: "Xã Thọ Thành",
            level: "Xã"
          },
          {
            id: "17533",
            name: "Xã Quang Thành",
            level: "Xã"
          },
          {
            id: "17536",
            name: "Xã Tây Thành",
            level: "Xã"
          },
          {
            id: "17539",
            name: "Xã Phúc Thành",
            level: "Xã"
          },
          {
            id: "17542",
            name: "Xã Hồng Thành",
            level: "Xã"
          },
          {
            id: "17545",
            name: "Xã Đồng Thành",
            level: "Xã"
          },
          {
            id: "17548",
            name: "Xã Phú Thành",
            level: "Xã"
          },
          {
            id: "17551",
            name: "Xã Hoa Thành",
            level: "Xã"
          },
          {
            id: "17554",
            name: "Xã Tăng Thành",
            level: "Xã"
          },
          {
            id: "17557",
            name: "Xã Văn Thành",
            level: "Xã"
          },
          {
            id: "17560",
            name: "Xã Thịnh Thành",
            level: "Xã"
          },
          {
            id: "17563",
            name: "Xã Hợp Thành",
            level: "Xã"
          },
          {
            id: "17566",
            name: "Xã Xuân Thành",
            level: "Xã"
          },
          {
            id: "17569",
            name: "Xã Bắc Thành",
            level: "Xã"
          },
          {
            id: "17572",
            name: "Xã Nhân Thành",
            level: "Xã"
          },
          {
            id: "17575",
            name: "Xã Trung Thành",
            level: "Xã"
          },
          {
            id: "17578",
            name: "Xã Long Thành",
            level: "Xã"
          },
          {
            id: "17581",
            name: "Xã Minh Thành",
            level: "Xã"
          },
          {
            id: "17584",
            name: "Xã Nam Thành",
            level: "Xã"
          },
          {
            id: "17587",
            name: "Xã Vĩnh Thành",
            level: "Xã"
          },
          {
            id: "17590",
            name: "Xã Lý Thành",
            level: "Xã"
          },
          {
            id: "17593",
            name: "Xã Khánh Thành",
            level: "Xã"
          },
          {
            id: "17596",
            name: "Xã Viên Thành",
            level: "Xã"
          },
          {
            id: "17599",
            name: "Xã Đại Thành",
            level: "Xã"
          },
          {
            id: "17602",
            name: "Xã Liên Thành",
            level: "Xã"
          },
          {
            id: "17605",
            name: "Xã Bảo Thành",
            level: "Xã"
          },
          {
            id: "17608",
            name: "Xã Mỹ Thành",
            level: "Xã"
          },
          {
            id: "17611",
            name: "Xã Công Thành",
            level: "Xã"
          },
          {
            id: "17614",
            name: "Xã Sơn Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "427",
        name: "Huyện Đô Lương",
        wards: [
          {
            id: "17617",
            name: "Thị trấn Đô Lương",
            level: "Thị trấn"
          },
          {
            id: "17619",
            name: "Xã Giang Sơn Đông",
            level: "Xã"
          },
          {
            id: "17620",
            name: "Xã Giang Sơn Tây",
            level: "Xã"
          },
          {
            id: "17623",
            name: "Xã Lam Sơn",
            level: "Xã"
          },
          {
            id: "17626",
            name: "Xã Bồi Sơn",
            level: "Xã"
          },
          {
            id: "17629",
            name: "Xã Hồng Sơn",
            level: "Xã"
          },
          {
            id: "17632",
            name: "Xã Bài Sơn",
            level: "Xã"
          },
          {
            id: "17635",
            name: "Xã Ngọc Sơn",
            level: "Xã"
          },
          {
            id: "17638",
            name: "Xã Bắc Sơn",
            level: "Xã"
          },
          {
            id: "17641",
            name: "Xã Tràng Sơn",
            level: "Xã"
          },
          {
            id: "17644",
            name: "Xã Thượng Sơn",
            level: "Xã"
          },
          {
            id: "17647",
            name: "Xã Hòa Sơn",
            level: "Xã"
          },
          {
            id: "17650",
            name: "Xã Đặng Sơn",
            level: "Xã"
          },
          {
            id: "17653",
            name: "Xã Đông Sơn",
            level: "Xã"
          },
          {
            id: "17656",
            name: "Xã Nam Sơn",
            level: "Xã"
          },
          {
            id: "17659",
            name: "Xã Lưu Sơn",
            level: "Xã"
          },
          {
            id: "17662",
            name: "Xã Yên Sơn",
            level: "Xã"
          },
          {
            id: "17665",
            name: "Xã Văn Sơn",
            level: "Xã"
          },
          {
            id: "17668",
            name: "Xã Đà Sơn",
            level: "Xã"
          },
          {
            id: "17671",
            name: "Xã Lạc Sơn",
            level: "Xã"
          },
          {
            id: "17674",
            name: "Xã Tân Sơn",
            level: "Xã"
          },
          {
            id: "17677",
            name: "Xã Thái Sơn",
            level: "Xã"
          },
          {
            id: "17680",
            name: "Xã Quang Sơn",
            level: "Xã"
          },
          {
            id: "17683",
            name: "Xã Thịnh Sơn",
            level: "Xã"
          },
          {
            id: "17686",
            name: "Xã Trung Sơn",
            level: "Xã"
          },
          {
            id: "17689",
            name: "Xã Xuân Sơn",
            level: "Xã"
          },
          {
            id: "17692",
            name: "Xã Minh Sơn",
            level: "Xã"
          },
          {
            id: "17695",
            name: "Xã Thuận Sơn",
            level: "Xã"
          },
          {
            id: "17698",
            name: "Xã Nhân Sơn",
            level: "Xã"
          },
          {
            id: "17701",
            name: "Xã Hiến Sơn",
            level: "Xã"
          },
          {
            id: "17704",
            name: "Xã Mỹ Sơn",
            level: "Xã"
          },
          {
            id: "17707",
            name: "Xã Trù Sơn",
            level: "Xã"
          },
          {
            id: "17710",
            name: "Xã Đại Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "428",
        name: "Huyện Thanh Chương",
        wards: [
          {
            id: "17713",
            name: "Thị trấn Thanh Chương",
            level: "Thị trấn"
          },
          {
            id: "17716",
            name: "Xã Cát Văn",
            level: "Xã"
          },
          {
            id: "17719",
            name: "Xã Thanh Nho",
            level: "Xã"
          },
          {
            id: "17722",
            name: "Xã Hạnh Lâm",
            level: "Xã"
          },
          {
            id: "17723",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "17725",
            name: "Xã Thanh Hòa",
            level: "Xã"
          },
          {
            id: "17728",
            name: "Xã Phong Thịnh",
            level: "Xã"
          },
          {
            id: "17731",
            name: "Xã Thanh Phong",
            level: "Xã"
          },
          {
            id: "17734",
            name: "Xã Thanh Mỹ",
            level: "Xã"
          },
          {
            id: "17737",
            name: "Xã Thanh Tiên",
            level: "Xã"
          },
          {
            id: "17743",
            name: "Xã Thanh Liên",
            level: "Xã"
          },
          {
            id: "17749",
            name: "Xã Đại Đồng",
            level: "Xã"
          },
          {
            id: "17752",
            name: "Xã Thanh Đồng",
            level: "Xã"
          },
          {
            id: "17755",
            name: "Xã Thanh Ngọc",
            level: "Xã"
          },
          {
            id: "17758",
            name: "Xã Thanh Hương",
            level: "Xã"
          },
          {
            id: "17759",
            name: "Xã Ngọc Lâm",
            level: "Xã"
          },
          {
            id: "17761",
            name: "Xã Thanh Lĩnh",
            level: "Xã"
          },
          {
            id: "17764",
            name: "Xã Đồng Văn",
            level: "Xã"
          },
          {
            id: "17767",
            name: "Xã Ngọc Sơn",
            level: "Xã"
          },
          {
            id: "17770",
            name: "Xã Thanh Thịnh",
            level: "Xã"
          },
          {
            id: "17773",
            name: "Xã Thanh An",
            level: "Xã"
          },
          {
            id: "17776",
            name: "Xã Thanh Chi",
            level: "Xã"
          },
          {
            id: "17779",
            name: "Xã Xuân Tường",
            level: "Xã"
          },
          {
            id: "17782",
            name: "Xã Thanh Dương",
            level: "Xã"
          },
          {
            id: "17785",
            name: "Xã Thanh Lương",
            level: "Xã"
          },
          {
            id: "17788",
            name: "Xã Thanh Khê",
            level: "Xã"
          },
          {
            id: "17791",
            name: "Xã Võ Liệt",
            level: "Xã"
          },
          {
            id: "17794",
            name: "Xã Thanh Long",
            level: "Xã"
          },
          {
            id: "17797",
            name: "Xã Thanh Thủy",
            level: "Xã"
          },
          {
            id: "17800",
            name: "Xã Thanh Khai",
            level: "Xã"
          },
          {
            id: "17803",
            name: "Xã Thanh Yên",
            level: "Xã"
          },
          {
            id: "17806",
            name: "Xã Thanh Hà",
            level: "Xã"
          },
          {
            id: "17809",
            name: "Xã Thanh Giang",
            level: "Xã"
          },
          {
            id: "17812",
            name: "Xã Thanh Tùng",
            level: "Xã"
          },
          {
            id: "17815",
            name: "Xã Thanh Lâm",
            level: "Xã"
          },
          {
            id: "17818",
            name: "Xã Thanh Mai",
            level: "Xã"
          },
          {
            id: "17821",
            name: "Xã Thanh Xuân",
            level: "Xã"
          },
          {
            id: "17824",
            name: "Xã Thanh Đức",
            level: "Xã"
          }
        ]
      },
      {
        id: "429",
        name: "Huyện Nghi Lộc",
        wards: [
          {
            id: "17827",
            name: "Thị trấn Quán Hành",
            level: "Thị trấn"
          },
          {
            id: "17830",
            name: "Xã Nghi Văn",
            level: "Xã"
          },
          {
            id: "17833",
            name: "Xã Nghi Yên",
            level: "Xã"
          },
          {
            id: "17836",
            name: "Xã Nghi Tiến",
            level: "Xã"
          },
          {
            id: "17839",
            name: "Xã Nghi Hưng",
            level: "Xã"
          },
          {
            id: "17842",
            name: "Xã Nghi Đồng",
            level: "Xã"
          },
          {
            id: "17845",
            name: "Xã Nghi Thiết",
            level: "Xã"
          },
          {
            id: "17848",
            name: "Xã Nghi Lâm",
            level: "Xã"
          },
          {
            id: "17851",
            name: "Xã Nghi Quang",
            level: "Xã"
          },
          {
            id: "17854",
            name: "Xã Nghi Kiều",
            level: "Xã"
          },
          {
            id: "17857",
            name: "Xã Nghi Mỹ",
            level: "Xã"
          },
          {
            id: "17860",
            name: "Xã Nghi Phương",
            level: "Xã"
          },
          {
            id: "17863",
            name: "Xã Nghi Thuận",
            level: "Xã"
          },
          {
            id: "17866",
            name: "Xã Nghi Long",
            level: "Xã"
          },
          {
            id: "17869",
            name: "Xã Nghi Xá",
            level: "Xã"
          },
          {
            id: "17875",
            name: "Xã Nghi Hoa",
            level: "Xã"
          },
          {
            id: "17878",
            name: "Xã Khánh Hợp",
            level: "Xã"
          },
          {
            id: "17881",
            name: "Xã Nghi Thịnh",
            level: "Xã"
          },
          {
            id: "17884",
            name: "Xã Nghi Công Bắc",
            level: "Xã"
          },
          {
            id: "17887",
            name: "Xã Nghi Công Nam",
            level: "Xã"
          },
          {
            id: "17890",
            name: "Xã Nghi Thạch",
            level: "Xã"
          },
          {
            id: "17893",
            name: "Xã Nghi Trung",
            level: "Xã"
          },
          {
            id: "17896",
            name: "Xã Nghi Trường",
            level: "Xã"
          },
          {
            id: "17899",
            name: "Xã Nghi Diên",
            level: "Xã"
          },
          {
            id: "17902",
            name: "Xã Nghi Phong",
            level: "Xã"
          },
          {
            id: "17905",
            name: "Xã Nghi Xuân",
            level: "Xã"
          },
          {
            id: "17911",
            name: "Xã Nghi Vạn",
            level: "Xã"
          },
          {
            id: "17917",
            name: "Xã Phúc Thọ",
            level: "Xã"
          },
          {
            id: "17926",
            name: "Xã Nghi Thái",
            level: "Xã"
          }
        ]
      },
      {
        id: "430",
        name: "Huyện Nam Đàn",
        wards: [
          {
            id: "17932",
            name: "Xã Nam Hưng",
            level: "Xã"
          },
          {
            id: "17935",
            name: "Xã Nam Nghĩa",
            level: "Xã"
          },
          {
            id: "17938",
            name: "Xã Nam Thanh",
            level: "Xã"
          },
          {
            id: "17941",
            name: "Xã Nam Anh",
            level: "Xã"
          },
          {
            id: "17944",
            name: "Xã Nam Xuân",
            level: "Xã"
          },
          {
            id: "17947",
            name: "Xã Nam Thái",
            level: "Xã"
          },
          {
            id: "17950",
            name: "Thị trấn Nam Đàn",
            level: "Thị trấn"
          },
          {
            id: "17953",
            name: "Xã Nam Lĩnh",
            level: "Xã"
          },
          {
            id: "17956",
            name: "Xã Nam Giang",
            level: "Xã"
          },
          {
            id: "17959",
            name: "Xã Xuân Hòa",
            level: "Xã"
          },
          {
            id: "17962",
            name: "Xã Hùng Tiến",
            level: "Xã"
          },
          {
            id: "17968",
            name: "Xã Thượng Tân Lộc",
            level: "Xã"
          },
          {
            id: "17971",
            name: "Xã Kim Liên",
            level: "Xã"
          },
          {
            id: "17977",
            name: "Xã Hồng Long",
            level: "Xã"
          },
          {
            id: "17980",
            name: "Xã Xuân Lâm",
            level: "Xã"
          },
          {
            id: "17983",
            name: "Xã Nam Cát",
            level: "Xã"
          },
          {
            id: "17986",
            name: "Xã Khánh Sơn",
            level: "Xã"
          },
          {
            id: "17989",
            name: "Xã Trung Phúc Cường",
            level: "Xã"
          },
          {
            id: "17998",
            name: "Xã Nam Kim",
            level: "Xã"
          }
        ]
      },
      {
        id: "431",
        name: "Huyện Hưng Nguyên",
        wards: [
          {
            id: "18001",
            name: "Thị trấn Hưng Nguyên",
            level: "Thị trấn"
          },
          {
            id: "18004",
            name: "Xã Hưng Trung",
            level: "Xã"
          },
          {
            id: "18007",
            name: "Xã Hưng Yên",
            level: "Xã"
          },
          {
            id: "18008",
            name: "Xã Hưng Yên Bắc",
            level: "Xã"
          },
          {
            id: "18010",
            name: "Xã Hưng Tây",
            level: "Xã"
          },
          {
            id: "18016",
            name: "Xã Hưng Đạo",
            level: "Xã"
          },
          {
            id: "18019",
            name: "Xã Hưng Mỹ",
            level: "Xã"
          },
          {
            id: "18022",
            name: "Xã Hưng Thịnh",
            level: "Xã"
          },
          {
            id: "18025",
            name: "Xã Hưng Lĩnh",
            level: "Xã"
          },
          {
            id: "18028",
            name: "Xã Hưng Thông",
            level: "Xã"
          },
          {
            id: "18031",
            name: "Xã Hưng Tân",
            level: "Xã"
          },
          {
            id: "18034",
            name: "Xã Hưng Lợi",
            level: "Xã"
          },
          {
            id: "18037",
            name: "Xã Hưng Nghĩa",
            level: "Xã"
          },
          {
            id: "18040",
            name: "Xã Hưng Phúc",
            level: "Xã"
          },
          {
            id: "18043",
            name: "Xã Long Xá",
            level: "Xã"
          },
          {
            id: "18052",
            name: "Xã Châu Nhân",
            level: "Xã"
          },
          {
            id: "18055",
            name: "Xã Xuân Lam",
            level: "Xã"
          },
          {
            id: "18064",
            name: "Xã Hưng Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "432",
        name: "Thị xã Hoàng Mai",
        wards: [
          {
            id: "17104",
            name: "Xã Quỳnh Vinh",
            level: "Xã"
          },
          {
            id: "17107",
            name: "Xã Quỳnh Lộc",
            level: "Xã"
          },
          {
            id: "17110",
            name: "Phường Quỳnh Thiện",
            level: "Phường"
          },
          {
            id: "17113",
            name: "Xã Quỳnh Lập",
            level: "Xã"
          },
          {
            id: "17116",
            name: "Xã Quỳnh Trang",
            level: "Xã"
          },
          {
            id: "17125",
            name: "Phường Mai Hùng",
            level: "Phường"
          },
          {
            id: "17128",
            name: "Phường Quỳnh Dị",
            level: "Phường"
          },
          {
            id: "17131",
            name: "Phường Quỳnh Xuân",
            level: "Phường"
          },
          {
            id: "17134",
            name: "Phường Quỳnh Phương",
            level: "Phường"
          },
          {
            id: "17137",
            name: "Xã Quỳnh Liên",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "42",
    name: "Tỉnh Hà Tĩnh",
    districts: [
      {
        id: "436",
        name: "Thành phố Hà Tĩnh",
        wards: [
          {
            id: "18070",
            name: "Phường Trần Phú",
            level: "Phường"
          },
          {
            id: "18073",
            name: "Phường Nam Hà",
            level: "Phường"
          },
          {
            id: "18076",
            name: "Phường Bắc Hà",
            level: "Phường"
          },
          {
            id: "18077",
            name: "Phường Nguyễn Du",
            level: "Phường"
          },
          {
            id: "18079",
            name: "Phường Tân Giang",
            level: "Phường"
          },
          {
            id: "18082",
            name: "Phường Đại Nài",
            level: "Phường"
          },
          {
            id: "18085",
            name: "Phường Hà Huy Tập",
            level: "Phường"
          },
          {
            id: "18088",
            name: "Xã Thạch Trung",
            level: "Xã"
          },
          {
            id: "18091",
            name: "Phường Thạch Quý",
            level: "Phường"
          },
          {
            id: "18094",
            name: "Phường Thạch Linh",
            level: "Phường"
          },
          {
            id: "18097",
            name: "Phường Văn Yên",
            level: "Phường"
          },
          {
            id: "18100",
            name: "Xã Thạch Hạ",
            level: "Xã"
          },
          {
            id: "18103",
            name: "Xã Đồng Môn",
            level: "Xã"
          },
          {
            id: "18109",
            name: "Xã Thạch Hưng",
            level: "Xã"
          },
          {
            id: "18112",
            name: "Xã Thạch Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "437",
        name: "Thị xã Hồng Lĩnh",
        wards: [
          {
            id: "18115",
            name: "Phường Bắc Hồng",
            level: "Phường"
          },
          {
            id: "18118",
            name: "Phường Nam Hồng",
            level: "Phường"
          },
          {
            id: "18121",
            name: "Phường Trung Lương",
            level: "Phường"
          },
          {
            id: "18124",
            name: "Phường Đức Thuận",
            level: "Phường"
          },
          {
            id: "18127",
            name: "Phường Đậu Liêu",
            level: "Phường"
          },
          {
            id: "18130",
            name: "Xã Thuận Lộc",
            level: "Xã"
          }
        ]
      },
      {
        id: "439",
        name: "Huyện Hương Sơn",
        wards: [
          {
            id: "18133",
            name: "Thị trấn Phố Châu",
            level: "Thị trấn"
          },
          {
            id: "18136",
            name: "Thị trấn  Tây Sơn",
            level: "Thị trấn"
          },
          {
            id: "18139",
            name: "Xã Sơn Hồng",
            level: "Xã"
          },
          {
            id: "18142",
            name: "Xã Sơn Tiến",
            level: "Xã"
          },
          {
            id: "18145",
            name: "Xã Sơn Lâm",
            level: "Xã"
          },
          {
            id: "18148",
            name: "Xã Sơn Lễ",
            level: "Xã"
          },
          {
            id: "18157",
            name: "Xã Sơn Giang",
            level: "Xã"
          },
          {
            id: "18160",
            name: "Xã Sơn Lĩnh",
            level: "Xã"
          },
          {
            id: "18163",
            name: "Xã An Hòa Thịnh",
            level: "Xã"
          },
          {
            id: "18172",
            name: "Xã Sơn Tây",
            level: "Xã"
          },
          {
            id: "18175",
            name: "Xã Sơn Ninh",
            level: "Xã"
          },
          {
            id: "18178",
            name: "Xã Sơn Châu",
            level: "Xã"
          },
          {
            id: "18181",
            name: "Xã Tân Mỹ Hà",
            level: "Xã"
          },
          {
            id: "18184",
            name: "Xã Quang Diệm",
            level: "Xã"
          },
          {
            id: "18187",
            name: "Xã Sơn Trung",
            level: "Xã"
          },
          {
            id: "18190",
            name: "Xã Sơn Bằng",
            level: "Xã"
          },
          {
            id: "18193",
            name: "Xã Sơn Bình",
            level: "Xã"
          },
          {
            id: "18196",
            name: "Xã Sơn Kim 1",
            level: "Xã"
          },
          {
            id: "18199",
            name: "Xã Sơn Kim 2",
            level: "Xã"
          },
          {
            id: "18202",
            name: "Xã Sơn Trà",
            level: "Xã"
          },
          {
            id: "18205",
            name: "Xã Sơn Long",
            level: "Xã"
          },
          {
            id: "18211",
            name: "Xã Kim Hoa",
            level: "Xã"
          },
          {
            id: "18214",
            name: "Xã Sơn Hàm",
            level: "Xã"
          },
          {
            id: "18217",
            name: "Xã Sơn Phú",
            level: "Xã"
          },
          {
            id: "18223",
            name: "Xã Sơn Trường",
            level: "Xã"
          }
        ]
      },
      {
        id: "440",
        name: "Huyện Đức Thọ",
        wards: [
          {
            id: "18229",
            name: "Thị trấn Đức Thọ",
            level: "Thị trấn"
          },
          {
            id: "18235",
            name: "Xã Quang Vĩnh",
            level: "Xã"
          },
          {
            id: "18241",
            name: "Xã Tùng Châu",
            level: "Xã"
          },
          {
            id: "18244",
            name: "Xã Trường Sơn",
            level: "Xã"
          },
          {
            id: "18247",
            name: "Xã Liên Minh",
            level: "Xã"
          },
          {
            id: "18253",
            name: "Xã Yên Hồ",
            level: "Xã"
          },
          {
            id: "18259",
            name: "Xã Tùng Ảnh",
            level: "Xã"
          },
          {
            id: "18262",
            name: "Xã Bùi La Nhân",
            level: "Xã"
          },
          {
            id: "18274",
            name: "Xã Thanh Bình Thịnh",
            level: "Xã"
          },
          {
            id: "18277",
            name: "Xã Lâm Trung Thủy",
            level: "Xã"
          },
          {
            id: "18280",
            name: "Xã Hòa Lạc",
            level: "Xã"
          },
          {
            id: "18283",
            name: "Xã Tân Dân",
            level: "Xã"
          },
          {
            id: "18298",
            name: "Xã An Dũng",
            level: "Xã"
          },
          {
            id: "18304",
            name: "Xã Đức Đồng",
            level: "Xã"
          },
          {
            id: "18307",
            name: "Xã Đức Lạng",
            level: "Xã"
          },
          {
            id: "18310",
            name: "Xã Tân Hương",
            level: "Xã"
          }
        ]
      },
      {
        id: "441",
        name: "Huyện Vũ Quang",
        wards: [
          {
            id: "18313",
            name: "Thị trấn Vũ Quang",
            level: "Thị trấn"
          },
          {
            id: "18316",
            name: "Xã Ân Phú",
            level: "Xã"
          },
          {
            id: "18319",
            name: "Xã Đức Giang",
            level: "Xã"
          },
          {
            id: "18322",
            name: "Xã Đức Lĩnh",
            level: "Xã"
          },
          {
            id: "18325",
            name: "Xã Thọ Điền",
            level: "Xã"
          },
          {
            id: "18328",
            name: "Xã Đức Hương",
            level: "Xã"
          },
          {
            id: "18331",
            name: "Xã Đức Bồng",
            level: "Xã"
          },
          {
            id: "18334",
            name: "Xã Đức Liên",
            level: "Xã"
          },
          {
            id: "18340",
            name: "Xã Hương Minh",
            level: "Xã"
          },
          {
            id: "18343",
            name: "Xã Quang Thọ",
            level: "Xã"
          }
        ]
      },
      {
        id: "442",
        name: "Huyện Nghi Xuân",
        wards: [
          {
            id: "18352",
            name: "Thị trấn Xuân An",
            level: "Thị trấn"
          },
          {
            id: "18355",
            name: "Xã Xuân Hội",
            level: "Xã"
          },
          {
            id: "18358",
            name: "Xã Đan Trường",
            level: "Xã"
          },
          {
            id: "18364",
            name: "Xã Xuân Phổ",
            level: "Xã"
          },
          {
            id: "18367",
            name: "Xã Xuân Hải",
            level: "Xã"
          },
          {
            id: "18370",
            name: "Xã Xuân Giang",
            level: "Xã"
          },
          {
            id: "18373",
            name: "Thị trấn Tiên Điền",
            level: "Thị trấn"
          },
          {
            id: "18376",
            name: "Xã Xuân Yên",
            level: "Xã"
          },
          {
            id: "18379",
            name: "Xã Xuân Mỹ",
            level: "Xã"
          },
          {
            id: "18382",
            name: "Xã Xuân Thành",
            level: "Xã"
          },
          {
            id: "18385",
            name: "Xã Xuân Viên",
            level: "Xã"
          },
          {
            id: "18388",
            name: "Xã Xuân Hồng",
            level: "Xã"
          },
          {
            id: "18391",
            name: "Xã Cỗ Đạm",
            level: "Xã"
          },
          {
            id: "18394",
            name: "Xã Xuân Liên",
            level: "Xã"
          },
          {
            id: "18397",
            name: "Xã Xuân Lĩnh",
            level: "Xã"
          },
          {
            id: "18400",
            name: "Xã Xuân Lam",
            level: "Xã"
          },
          {
            id: "18403",
            name: "Xã Cương Gián",
            level: "Xã"
          }
        ]
      },
      {
        id: "443",
        name: "Huyện Can Lộc",
        wards: [
          {
            id: "18406",
            name: "Thị trấn Nghèn",
            level: "Thị trấn"
          },
          {
            id: "18415",
            name: "Xã Thiên Lộc",
            level: "Xã"
          },
          {
            id: "18418",
            name: "Xã Thuần Thiện",
            level: "Xã"
          },
          {
            id: "18427",
            name: "Xã Vượng Lộc",
            level: "Xã"
          },
          {
            id: "18433",
            name: "Xã Thanh Lộc",
            level: "Xã"
          },
          {
            id: "18436",
            name: "Xã Kim Song Trường",
            level: "Xã"
          },
          {
            id: "18439",
            name: "Xã Thường Nga",
            level: "Xã"
          },
          {
            id: "18445",
            name: "Xã Tùng Lộc",
            level: "Xã"
          },
          {
            id: "18454",
            name: "Xã Phú Lộc",
            level: "Xã"
          },
          {
            id: "18463",
            name: "Xã Gia Hanh",
            level: "Xã"
          },
          {
            id: "18466",
            name: "Xã Khánh Vĩnh Yên",
            level: "Xã"
          },
          {
            id: "18472",
            name: "Xã Trung Lộc",
            level: "Xã"
          },
          {
            id: "18475",
            name: "Xã Xuân Lộc",
            level: "Xã"
          },
          {
            id: "18478",
            name: "Xã Thượng Lộc",
            level: "Xã"
          },
          {
            id: "18481",
            name: "Xã Quang Lộc",
            level: "Xã"
          },
          {
            id: "18484",
            name: "Thị trấn Đồng Lộc",
            level: "Thị trấn"
          },
          {
            id: "18487",
            name: "Xã Mỹ Lộc",
            level: "Xã"
          },
          {
            id: "18490",
            name: "Xã Sơn Lộc",
            level: "Xã"
          }
        ]
      },
      {
        id: "444",
        name: "Huyện Hương Khê",
        wards: [
          {
            id: "18496",
            name: "Thị trấn Hương Khê",
            level: "Thị trấn"
          },
          {
            id: "18499",
            name: "Xã Điền Mỹ",
            level: "Xã"
          },
          {
            id: "18502",
            name: "Xã Hà Linh",
            level: "Xã"
          },
          {
            id: "18505",
            name: "Xã Hương Thủy",
            level: "Xã"
          },
          {
            id: "18508",
            name: "Xã Hòa Hải",
            level: "Xã"
          },
          {
            id: "18514",
            name: "Xã Phúc Đồng",
            level: "Xã"
          },
          {
            id: "18517",
            name: "Xã Hương Giang",
            level: "Xã"
          },
          {
            id: "18520",
            name: "Xã Lộc Yên",
            level: "Xã"
          },
          {
            id: "18523",
            name: "Xã Hương Bình",
            level: "Xã"
          },
          {
            id: "18526",
            name: "Xã Hương Long",
            level: "Xã"
          },
          {
            id: "18529",
            name: "Xã Phú Gia",
            level: "Xã"
          },
          {
            id: "18532",
            name: "Xã Gia Phố",
            level: "Xã"
          },
          {
            id: "18535",
            name: "Xã Phú Phong",
            level: "Xã"
          },
          {
            id: "18538",
            name: "Xã Hương Đô",
            level: "Xã"
          },
          {
            id: "18541",
            name: "Xã Hương Vĩnh",
            level: "Xã"
          },
          {
            id: "18544",
            name: "Xã Hương Xuân",
            level: "Xã"
          },
          {
            id: "18547",
            name: "Xã Phúc Trạch",
            level: "Xã"
          },
          {
            id: "18550",
            name: "Xã Hương Trà",
            level: "Xã"
          },
          {
            id: "18553",
            name: "Xã Hương Trạch",
            level: "Xã"
          },
          {
            id: "18556",
            name: "Xã Hương Lâm",
            level: "Xã"
          },
          {
            id: "18559",
            name: "Xã Hương Liên",
            level: "Xã"
          }
        ]
      },
      {
        id: "445",
        name: "Huyện Thạch Hà",
        wards: [
          {
            id: "18562",
            name: "Thị trấn Thạch Hà",
            level: "Thị trấn"
          },
          {
            id: "18565",
            name: "Xã Ngọc Sơn",
            level: "Xã"
          },
          {
            id: "18571",
            name: "Xã Thạch Hải",
            level: "Xã"
          },
          {
            id: "18586",
            name: "Xã Thạch Kênh",
            level: "Xã"
          },
          {
            id: "18589",
            name: "Xã Thạch Sơn",
            level: "Xã"
          },
          {
            id: "18592",
            name: "Xã Thạch Liên",
            level: "Xã"
          },
          {
            id: "18595",
            name: "Xã Đỉnh Bàn",
            level: "Xã"
          },
          {
            id: "18601",
            name: "Xã Việt Tiến",
            level: "Xã"
          },
          {
            id: "18604",
            name: "Xã Thạch Khê",
            level: "Xã"
          },
          {
            id: "18607",
            name: "Xã Thạch Long",
            level: "Xã"
          },
          {
            id: "18619",
            name: "Xã Thạch Trị",
            level: "Xã"
          },
          {
            id: "18622",
            name: "Xã Thạch Lạc",
            level: "Xã"
          },
          {
            id: "18625",
            name: "Xã Thạch Ngọc",
            level: "Xã"
          },
          {
            id: "18628",
            name: "Xã Tượng Sơn",
            level: "Xã"
          },
          {
            id: "18631",
            name: "Xã Thạch Văn",
            level: "Xã"
          },
          {
            id: "18634",
            name: "Xã Lưu Vĩnh Sơn",
            level: "Xã"
          },
          {
            id: "18637",
            name: "Xã Thạch Thắng",
            level: "Xã"
          },
          {
            id: "18643",
            name: "Xã Thạch Đài",
            level: "Xã"
          },
          {
            id: "18649",
            name: "Xã Thạch Hội",
            level: "Xã"
          },
          {
            id: "18652",
            name: "Xã Tân Lâm Hương",
            level: "Xã"
          },
          {
            id: "18658",
            name: "Xã Thạch Xuân",
            level: "Xã"
          },
          {
            id: "18667",
            name: "Xã Nam Điền",
            level: "Xã"
          }
        ]
      },
      {
        id: "446",
        name: "Huyện Cẩm Xuyên",
        wards: [
          {
            id: "18673",
            name: "Thị trấn Cẩm Xuyên",
            level: "Thị trấn"
          },
          {
            id: "18676",
            name: "Thị trấn Thiên Cầm",
            level: "Thị trấn"
          },
          {
            id: "18679",
            name: "Xã Yên Hòa",
            level: "Xã"
          },
          {
            id: "18682",
            name: "Xã Cẩm Dương",
            level: "Xã"
          },
          {
            id: "18685",
            name: "Xã Cẩm Bình",
            level: "Xã"
          },
          {
            id: "18691",
            name: "Xã Cẩm Vĩnh",
            level: "Xã"
          },
          {
            id: "18694",
            name: "Xã Cẩm Thành",
            level: "Xã"
          },
          {
            id: "18697",
            name: "Xã Cẩm Quang",
            level: "Xã"
          },
          {
            id: "18706",
            name: "Xã Cẩm Thạch",
            level: "Xã"
          },
          {
            id: "18709",
            name: "Xã Cẩm Nhượng",
            level: "Xã"
          },
          {
            id: "18712",
            name: "Xã Nam Phúc Thăng",
            level: "Xã"
          },
          {
            id: "18715",
            name: "Xã Cẩm Duệ",
            level: "Xã"
          },
          {
            id: "18721",
            name: "Xã Cẩm Lĩnh",
            level: "Xã"
          },
          {
            id: "18724",
            name: "Xã Cẩm Quan",
            level: "Xã"
          },
          {
            id: "18727",
            name: "Xã Cẩm Hà",
            level: "Xã"
          },
          {
            id: "18730",
            name: "Xã Cẩm Lộc",
            level: "Xã"
          },
          {
            id: "18733",
            name: "Xã Cẩm Hưng",
            level: "Xã"
          },
          {
            id: "18736",
            name: "Xã Cẩm Thịnh",
            level: "Xã"
          },
          {
            id: "18739",
            name: "Xã Cẩm Mỹ",
            level: "Xã"
          },
          {
            id: "18742",
            name: "Xã Cẩm Trung",
            level: "Xã"
          },
          {
            id: "18745",
            name: "Xã Cẩm Sơn",
            level: "Xã"
          },
          {
            id: "18748",
            name: "Xã Cẩm Lạc",
            level: "Xã"
          },
          {
            id: "18751",
            name: "Xã Cẩm Minh",
            level: "Xã"
          }
        ]
      },
      {
        id: "447",
        name: "Huyện Kỳ Anh",
        wards: [
          {
            id: "18757",
            name: "Xã Kỳ Xuân",
            level: "Xã"
          },
          {
            id: "18760",
            name: "Xã Kỳ Bắc",
            level: "Xã"
          },
          {
            id: "18763",
            name: "Xã Kỳ Phú",
            level: "Xã"
          },
          {
            id: "18766",
            name: "Xã Kỳ Phong",
            level: "Xã"
          },
          {
            id: "18769",
            name: "Xã Kỳ Tiến",
            level: "Xã"
          },
          {
            id: "18772",
            name: "Xã Kỳ Giang",
            level: "Xã"
          },
          {
            id: "18775",
            name: "Xã Kỳ Đồng",
            level: "Xã"
          },
          {
            id: "18778",
            name: "Xã Kỳ Khang",
            level: "Xã"
          },
          {
            id: "18784",
            name: "Xã Kỳ Văn",
            level: "Xã"
          },
          {
            id: "18787",
            name: "Xã Kỳ Trung",
            level: "Xã"
          },
          {
            id: "18790",
            name: "Xã Kỳ Thọ",
            level: "Xã"
          },
          {
            id: "18793",
            name: "Xã Kỳ Tây",
            level: "Xã"
          },
          {
            id: "18799",
            name: "Xã Kỳ Thượng",
            level: "Xã"
          },
          {
            id: "18802",
            name: "Xã Kỳ Hải",
            level: "Xã"
          },
          {
            id: "18805",
            name: "Xã Kỳ Thư",
            level: "Xã"
          },
          {
            id: "18811",
            name: "Xã Kỳ Châu",
            level: "Xã"
          },
          {
            id: "18814",
            name: "Xã Kỳ Tân",
            level: "Xã"
          },
          {
            id: "18838",
            name: "Xã Lâm Hợp",
            level: "Xã"
          },
          {
            id: "18844",
            name: "Xã Kỳ Sơn",
            level: "Xã"
          },
          {
            id: "18850",
            name: "Xã Kỳ Lạc",
            level: "Xã"
          }
        ]
      },
      {
        id: "448",
        name: "Huyện Lộc Hà",
        wards: [
          {
            id: "18409",
            name: "Xã Tân Lộc",
            level: "Xã"
          },
          {
            id: "18412",
            name: "Xã Hồng Lộc",
            level: "Xã"
          },
          {
            id: "18421",
            name: "Xã Thịnh Lộc",
            level: "Xã"
          },
          {
            id: "18430",
            name: "Xã Bình An",
            level: "Xã"
          },
          {
            id: "18457",
            name: "Xã Ích Hậu",
            level: "Xã"
          },
          {
            id: "18493",
            name: "Xã Phù Lưu",
            level: "Xã"
          },
          {
            id: "18568",
            name: "Thị trấn Lộc Hà",
            level: "Thị trấn"
          },
          {
            id: "18577",
            name: "Xã Thạch Mỹ",
            level: "Xã"
          },
          {
            id: "18580",
            name: "Xã Thạch Kim",
            level: "Xã"
          },
          {
            id: "18583",
            name: "Xã Thạch Châu",
            level: "Xã"
          },
          {
            id: "18598",
            name: "Xã Hộ Độ",
            level: "Xã"
          },
          {
            id: "18670",
            name: "Xã Mai Phụ",
            level: "Xã"
          }
        ]
      },
      {
        id: "449",
        name: "Thị xã Kỳ Anh",
        wards: [
          {
            id: "18754",
            name: "Phường Hưng Trí",
            level: "Phường"
          },
          {
            id: "18781",
            name: "Xã Kỳ Ninh",
            level: "Xã"
          },
          {
            id: "18796",
            name: "Xã Kỳ Lợi",
            level: "Xã"
          },
          {
            id: "18808",
            name: "Xã Kỳ Hà",
            level: "Xã"
          },
          {
            id: "18820",
            name: "Phường Kỳ Trinh",
            level: "Phường"
          },
          {
            id: "18823",
            name: "Phường Kỳ Thịnh",
            level: "Phường"
          },
          {
            id: "18829",
            name: "Xã Kỳ Hoa",
            level: "Xã"
          },
          {
            id: "18832",
            name: "Phường Kỳ Phương",
            level: "Phường"
          },
          {
            id: "18835",
            name: "Phường Kỳ Long",
            level: "Phường"
          },
          {
            id: "18841",
            name: "Phường Kỳ Liên",
            level: "Phường"
          },
          {
            id: "18847",
            name: "Xã Kỳ Nam",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "44",
    name: "Tỉnh Quảng Bình",
    districts: [
      {
        id: "450",
        name: "Thành Phố Đồng Hới",
        wards: [
          {
            id: "18853",
            name: "Phường Hải Thành",
            level: "Phường"
          },
          {
            id: "18856",
            name: "Phường Đồng Phú",
            level: "Phường"
          },
          {
            id: "18859",
            name: "Phường Bắc Lý",
            level: "Phường"
          },
          {
            id: "18865",
            name: "Phường Nam Lý",
            level: "Phường"
          },
          {
            id: "18868",
            name: "Phường Đồng Hải",
            level: "Phường"
          },
          {
            id: "18871",
            name: "Phường Đồng Sơn",
            level: "Phường"
          },
          {
            id: "18874",
            name: "Phường Phú Hải",
            level: "Phường"
          },
          {
            id: "18877",
            name: "Phường Bắc Nghĩa",
            level: "Phường"
          },
          {
            id: "18880",
            name: "Phường Đức Ninh Đông",
            level: "Phường"
          },
          {
            id: "18883",
            name: "Xã Quang Phú",
            level: "Xã"
          },
          {
            id: "18886",
            name: "Xã Lộc Ninh",
            level: "Xã"
          },
          {
            id: "18889",
            name: "Xã Bảo Ninh",
            level: "Xã"
          },
          {
            id: "18892",
            name: "Xã Nghĩa Ninh",
            level: "Xã"
          },
          {
            id: "18895",
            name: "Xã Thuận Đức",
            level: "Xã"
          },
          {
            id: "18898",
            name: "Xã Đức Ninh",
            level: "Xã"
          }
        ]
      },
      {
        id: "452",
        name: "Huyện Minh Hóa",
        wards: [
          {
            id: "18901",
            name: "Thị trấn Quy Đạt",
            level: "Thị trấn"
          },
          {
            id: "18904",
            name: "Xã Dân Hóa",
            level: "Xã"
          },
          {
            id: "18907",
            name: "Xã Trọng Hóa",
            level: "Xã"
          },
          {
            id: "18910",
            name: "Xã Hóa Phúc",
            level: "Xã"
          },
          {
            id: "18913",
            name: "Xã Hồng Hóa",
            level: "Xã"
          },
          {
            id: "18916",
            name: "Xã Hóa Thanh",
            level: "Xã"
          },
          {
            id: "18919",
            name: "Xã Hóa Tiến",
            level: "Xã"
          },
          {
            id: "18922",
            name: "Xã Hóa Hợp",
            level: "Xã"
          },
          {
            id: "18925",
            name: "Xã Xuân Hóa",
            level: "Xã"
          },
          {
            id: "18928",
            name: "Xã Yên Hóa",
            level: "Xã"
          },
          {
            id: "18931",
            name: "Xã Minh Hóa",
            level: "Xã"
          },
          {
            id: "18934",
            name: "Xã Tân Hóa",
            level: "Xã"
          },
          {
            id: "18937",
            name: "Xã Hóa Sơn",
            level: "Xã"
          },
          {
            id: "18943",
            name: "Xã Trung Hóa",
            level: "Xã"
          },
          {
            id: "18946",
            name: "Xã Thượng Hóa",
            level: "Xã"
          }
        ]
      },
      {
        id: "453",
        name: "Huyện Tuyên Hóa",
        wards: [
          {
            id: "18949",
            name: "Thị trấn Đồng Lê",
            level: "Thị trấn"
          },
          {
            id: "18952",
            name: "Xã Hương Hóa",
            level: "Xã"
          },
          {
            id: "18955",
            name: "Xã Kim Hóa",
            level: "Xã"
          },
          {
            id: "18958",
            name: "Xã Thanh Hóa",
            level: "Xã"
          },
          {
            id: "18961",
            name: "Xã Thanh Thạch",
            level: "Xã"
          },
          {
            id: "18964",
            name: "Xã Thuận Hóa",
            level: "Xã"
          },
          {
            id: "18967",
            name: "Xã Lâm Hóa",
            level: "Xã"
          },
          {
            id: "18970",
            name: "Xã Lê Hóa",
            level: "Xã"
          },
          {
            id: "18973",
            name: "Xã Sơn Hóa",
            level: "Xã"
          },
          {
            id: "18976",
            name: "Xã Đồng Hóa",
            level: "Xã"
          },
          {
            id: "18979",
            name: "Xã Ngư Hóa",
            level: "Xã"
          },
          {
            id: "18985",
            name: "Xã Thạch Hóa",
            level: "Xã"
          },
          {
            id: "18988",
            name: "Xã Đức Hóa",
            level: "Xã"
          },
          {
            id: "18991",
            name: "Xã Phong Hóa",
            level: "Xã"
          },
          {
            id: "18994",
            name: "Xã Mai Hóa",
            level: "Xã"
          },
          {
            id: "18997",
            name: "Xã Tiến Hóa",
            level: "Xã"
          },
          {
            id: "19000",
            name: "Xã Châu Hóa",
            level: "Xã"
          },
          {
            id: "19003",
            name: "Xã Cao Quảng",
            level: "Xã"
          },
          {
            id: "19006",
            name: "Xã Văn Hóa",
            level: "Xã"
          }
        ]
      },
      {
        id: "454",
        name: "Huyện Quảng Trạch",
        wards: [
          {
            id: "19012",
            name: "Xã Quảng Hợp",
            level: "Xã"
          },
          {
            id: "19015",
            name: "Xã Quảng Kim",
            level: "Xã"
          },
          {
            id: "19018",
            name: "Xã Quảng Đông",
            level: "Xã"
          },
          {
            id: "19021",
            name: "Xã Quảng Phú",
            level: "Xã"
          },
          {
            id: "19024",
            name: "Xã Quảng Châu",
            level: "Xã"
          },
          {
            id: "19027",
            name: "Xã Quảng Thạch",
            level: "Xã"
          },
          {
            id: "19030",
            name: "Xã Quảng Lưu",
            level: "Xã"
          },
          {
            id: "19033",
            name: "Xã Quảng Tùng",
            level: "Xã"
          },
          {
            id: "19036",
            name: "Xã Cảnh Dương",
            level: "Xã"
          },
          {
            id: "19039",
            name: "Xã Quảng Tiến",
            level: "Xã"
          },
          {
            id: "19042",
            name: "Xã Quảng Hưng",
            level: "Xã"
          },
          {
            id: "19045",
            name: "Xã Quảng Xuân",
            level: "Xã"
          },
          {
            id: "19048",
            name: "Xã Cảnh Hóa",
            level: "Xã"
          },
          {
            id: "19051",
            name: "Xã Liên Trường",
            level: "Xã"
          },
          {
            id: "19057",
            name: "Xã Quảng Phương",
            level: "Xã"
          },
          {
            id: "19063",
            name: "Xã Phù Hóa",
            level: "Xã"
          },
          {
            id: "19072",
            name: "Xã Quảng Thanh",
            level: "Xã"
          }
        ]
      },
      {
        id: "455",
        name: "Huyện Bố Trạch",
        wards: [
          {
            id: "19111",
            name: "Thị trấn Hoàn Lão",
            level: "Thị trấn"
          },
          {
            id: "19114",
            name: "Thị trấn NT Việt Trung",
            level: "Thị trấn"
          },
          {
            id: "19117",
            name: "Xã Xuân Trạch",
            level: "Xã"
          },
          {
            id: "19120",
            name: "Xã Mỹ Trạch",
            level: "Xã"
          },
          {
            id: "19123",
            name: "Xã Hạ Trạch",
            level: "Xã"
          },
          {
            id: "19126",
            name: "Xã Bắc Trạch",
            level: "Xã"
          },
          {
            id: "19129",
            name: "Xã Lâm Trạch",
            level: "Xã"
          },
          {
            id: "19132",
            name: "Xã Thanh Trạch",
            level: "Xã"
          },
          {
            id: "19135",
            name: "Xã Liên Trạch",
            level: "Xã"
          },
          {
            id: "19138",
            name: "Xã Phúc Trạch",
            level: "Xã"
          },
          {
            id: "19141",
            name: "Xã Cự Nẫm",
            level: "Xã"
          },
          {
            id: "19144",
            name: "Xã Hải Phú",
            level: "Xã"
          },
          {
            id: "19147",
            name: "Xã Thượng Trạch",
            level: "Xã"
          },
          {
            id: "19150",
            name: "Xã Sơn Lộc",
            level: "Xã"
          },
          {
            id: "19156",
            name: "Xã Hưng Trạch",
            level: "Xã"
          },
          {
            id: "19159",
            name: "Xã Đồng Trạch",
            level: "Xã"
          },
          {
            id: "19162",
            name: "Xã Đức Trạch",
            level: "Xã"
          },
          {
            id: "19165",
            name: "Thị trấn Phong Nha",
            level: "Thị trấn"
          },
          {
            id: "19168",
            name: "Xã Vạn Trạch",
            level: "Xã"
          },
          {
            id: "19174",
            name: "Xã Phú Định",
            level: "Xã"
          },
          {
            id: "19177",
            name: "Xã Trung Trạch",
            level: "Xã"
          },
          {
            id: "19180",
            name: "Xã Tây Trạch",
            level: "Xã"
          },
          {
            id: "19183",
            name: "Xã Hòa Trạch",
            level: "Xã"
          },
          {
            id: "19186",
            name: "Xã Đại Trạch",
            level: "Xã"
          },
          {
            id: "19189",
            name: "Xã Nhân Trạch",
            level: "Xã"
          },
          {
            id: "19192",
            name: "Xã Tân Trạch",
            level: "Xã"
          },
          {
            id: "19195",
            name: "Xã Nam Trạch",
            level: "Xã"
          },
          {
            id: "19198",
            name: "Xã Lý Trạch",
            level: "Xã"
          }
        ]
      },
      {
        id: "456",
        name: "Huyện Quảng Ninh",
        wards: [
          {
            id: "19201",
            name: "Thị trấn Quán Hàu",
            level: "Thị trấn"
          },
          {
            id: "19204",
            name: "Xã Trường Sơn",
            level: "Xã"
          },
          {
            id: "19207",
            name: "Xã Lương Ninh",
            level: "Xã"
          },
          {
            id: "19210",
            name: "Xã Vĩnh Ninh",
            level: "Xã"
          },
          {
            id: "19213",
            name: "Xã Võ Ninh",
            level: "Xã"
          },
          {
            id: "19216",
            name: "Xã Hải Ninh",
            level: "Xã"
          },
          {
            id: "19219",
            name: "Xã Hàm Ninh",
            level: "Xã"
          },
          {
            id: "19222",
            name: "Xã Duy Ninh",
            level: "Xã"
          },
          {
            id: "19225",
            name: "Xã Gia Ninh",
            level: "Xã"
          },
          {
            id: "19228",
            name: "Xã Trường Xuân",
            level: "Xã"
          },
          {
            id: "19231",
            name: "Xã Hiền Ninh",
            level: "Xã"
          },
          {
            id: "19234",
            name: "Xã Tân Ninh",
            level: "Xã"
          },
          {
            id: "19237",
            name: "Xã Xuân Ninh",
            level: "Xã"
          },
          {
            id: "19240",
            name: "Xã An Ninh",
            level: "Xã"
          },
          {
            id: "19243",
            name: "Xã Vạn Ninh",
            level: "Xã"
          }
        ]
      },
      {
        id: "457",
        name: "Huyện Lệ Thủy",
        wards: [
          {
            id: "19246",
            name: "Thị trấn NT Lệ Ninh",
            level: "Thị trấn"
          },
          {
            id: "19249",
            name: "Thị trấn Kiến Giang",
            level: "Thị trấn"
          },
          {
            id: "19252",
            name: "Xã Hồng Thủy",
            level: "Xã"
          },
          {
            id: "19255",
            name: "Xã Ngư Thủy Bắc",
            level: "Xã"
          },
          {
            id: "19258",
            name: "Xã Hoa Thủy",
            level: "Xã"
          },
          {
            id: "19261",
            name: "Xã Thanh Thủy",
            level: "Xã"
          },
          {
            id: "19264",
            name: "Xã An Thủy",
            level: "Xã"
          },
          {
            id: "19267",
            name: "Xã Phong Thủy",
            level: "Xã"
          },
          {
            id: "19270",
            name: "Xã Cam Thủy",
            level: "Xã"
          },
          {
            id: "19273",
            name: "Xã Ngân Thủy",
            level: "Xã"
          },
          {
            id: "19276",
            name: "Xã Sơn Thủy",
            level: "Xã"
          },
          {
            id: "19279",
            name: "Xã Lộc Thủy",
            level: "Xã"
          },
          {
            id: "19285",
            name: "Xã Liên Thủy",
            level: "Xã"
          },
          {
            id: "19288",
            name: "Xã Hưng Thủy",
            level: "Xã"
          },
          {
            id: "19291",
            name: "Xã Dương Thủy",
            level: "Xã"
          },
          {
            id: "19294",
            name: "Xã Tân Thủy",
            level: "Xã"
          },
          {
            id: "19297",
            name: "Xã Phú Thủy",
            level: "Xã"
          },
          {
            id: "19300",
            name: "Xã Xuân Thủy",
            level: "Xã"
          },
          {
            id: "19303",
            name: "Xã Mỹ Thủy",
            level: "Xã"
          },
          {
            id: "19306",
            name: "Xã Ngư Thủy ",
            level: "Xã"
          },
          {
            id: "19309",
            name: "Xã Mai Thủy",
            level: "Xã"
          },
          {
            id: "19312",
            name: "Xã Sen Thủy",
            level: "Xã"
          },
          {
            id: "19315",
            name: "Xã Thái Thủy",
            level: "Xã"
          },
          {
            id: "19318",
            name: "Xã Kim Thủy",
            level: "Xã"
          },
          {
            id: "19321",
            name: "Xã Trường Thủy",
            level: "Xã"
          },
          {
            id: "19327",
            name: "Xã Lâm Thủy",
            level: "Xã"
          }
        ]
      },
      {
        id: "458",
        name: "Thị xã Ba Đồn",
        wards: [
          {
            id: "19009",
            name: "Phường Ba Đồn",
            level: "Phường"
          },
          {
            id: "19060",
            name: "Phường Quảng Long",
            level: "Phường"
          },
          {
            id: "19066",
            name: "Phường Quảng Thọ",
            level: "Phường"
          },
          {
            id: "19069",
            name: "Xã Quảng Tiên",
            level: "Xã"
          },
          {
            id: "19075",
            name: "Xã Quảng Trung",
            level: "Xã"
          },
          {
            id: "19078",
            name: "Phường Quảng Phong",
            level: "Phường"
          },
          {
            id: "19081",
            name: "Phường Quảng Thuận",
            level: "Phường"
          },
          {
            id: "19084",
            name: "Xã Quảng Tân",
            level: "Xã"
          },
          {
            id: "19087",
            name: "Xã Quảng Hải",
            level: "Xã"
          },
          {
            id: "19090",
            name: "Xã Quảng Sơn",
            level: "Xã"
          },
          {
            id: "19093",
            name: "Xã Quảng Lộc",
            level: "Xã"
          },
          {
            id: "19096",
            name: "Xã Quảng Thủy",
            level: "Xã"
          },
          {
            id: "19099",
            name: "Xã Quảng Văn",
            level: "Xã"
          },
          {
            id: "19102",
            name: "Phường Quảng Phúc",
            level: "Phường"
          },
          {
            id: "19105",
            name: "Xã Quảng Hòa",
            level: "Xã"
          },
          {
            id: "19108",
            name: "Xã Quảng Minh",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "45",
    name: "Tỉnh Quảng Trị",
    districts: [
      {
        id: "461",
        name: "Thành phố Đông Hà",
        wards: [
          {
            id: "19330",
            name: "Phường Đông Giang",
            level: "Phường"
          },
          {
            id: "19333",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "19336",
            name: "Phường Đông Lễ",
            level: "Phường"
          },
          {
            id: "19339",
            name: "Phường Đông Thanh",
            level: "Phường"
          },
          {
            id: "19342",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "19345",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "19348",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "19351",
            name: "Phường Đông Lương",
            level: "Phường"
          },
          {
            id: "19354",
            name: "Phường 3",
            level: "Phường"
          }
        ]
      },
      {
        id: "462",
        name: "Thị xã Quảng Trị",
        wards: [
          {
            id: "19357",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "19358",
            name: "Phường An Đôn",
            level: "Phường"
          },
          {
            id: "19360",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "19361",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "19705",
            name: "Xã Hải Lệ",
            level: "Xã"
          }
        ]
      },
      {
        id: "464",
        name: "Huyện Vĩnh Linh",
        wards: [
          {
            id: "19363",
            name: "Thị trấn Hồ Xá",
            level: "Thị trấn"
          },
          {
            id: "19366",
            name: "Thị trấn Bến Quan",
            level: "Thị trấn"
          },
          {
            id: "19369",
            name: "Xã Vĩnh Thái",
            level: "Xã"
          },
          {
            id: "19372",
            name: "Xã Vĩnh Tú",
            level: "Xã"
          },
          {
            id: "19375",
            name: "Xã Vĩnh Chấp",
            level: "Xã"
          },
          {
            id: "19378",
            name: "Xã Trung Nam",
            level: "Xã"
          },
          {
            id: "19384",
            name: "Xã Kim Thạch",
            level: "Xã"
          },
          {
            id: "19387",
            name: "Xã Vĩnh Long",
            level: "Xã"
          },
          {
            id: "19393",
            name: "Xã Vĩnh Khê",
            level: "Xã"
          },
          {
            id: "19396",
            name: "Xã Vĩnh Hòa",
            level: "Xã"
          },
          {
            id: "19402",
            name: "Xã Vĩnh Thủy",
            level: "Xã"
          },
          {
            id: "19405",
            name: "Xã Vĩnh Lâm",
            level: "Xã"
          },
          {
            id: "19408",
            name: "Xã Hiền Thành",
            level: "Xã"
          },
          {
            id: "19414",
            name: "Thị trấn Cửa Tùng",
            level: "Thị trấn"
          },
          {
            id: "19417",
            name: "Xã Vĩnh Hà",
            level: "Xã"
          },
          {
            id: "19420",
            name: "Xã Vĩnh Sơn",
            level: "Xã"
          },
          {
            id: "19423",
            name: "Xã Vĩnh Giang",
            level: "Xã"
          },
          {
            id: "19426",
            name: "Xã Vĩnh Ô",
            level: "Xã"
          }
        ]
      },
      {
        id: "465",
        name: "Huyện Hướng Hóa",
        wards: [
          {
            id: "19429",
            name: "Thị trấn Khe Sanh",
            level: "Thị trấn"
          },
          {
            id: "19432",
            name: "Thị trấn Lao Bảo",
            level: "Thị trấn"
          },
          {
            id: "19435",
            name: "Xã Hướng Lập",
            level: "Xã"
          },
          {
            id: "19438",
            name: "Xã Hướng Việt",
            level: "Xã"
          },
          {
            id: "19441",
            name: "Xã Hướng Phùng",
            level: "Xã"
          },
          {
            id: "19444",
            name: "Xã Hướng Sơn",
            level: "Xã"
          },
          {
            id: "19447",
            name: "Xã Hướng Linh",
            level: "Xã"
          },
          {
            id: "19450",
            name: "Xã Tân Hợp",
            level: "Xã"
          },
          {
            id: "19453",
            name: "Xã Hướng Tân",
            level: "Xã"
          },
          {
            id: "19456",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "19459",
            name: "Xã Tân Long",
            level: "Xã"
          },
          {
            id: "19462",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "19465",
            name: "Xã Tân Liên",
            level: "Xã"
          },
          {
            id: "19468",
            name: "Xã Húc",
            level: "Xã"
          },
          {
            id: "19471",
            name: "Xã Thuận",
            level: "Xã"
          },
          {
            id: "19474",
            name: "Xã Hướng Lộc",
            level: "Xã"
          },
          {
            id: "19477",
            name: "Xã Ba Tầng",
            level: "Xã"
          },
          {
            id: "19480",
            name: "Xã Thanh",
            level: "Xã"
          },
          {
            id: "19483",
            name: "Xã  A Dơi",
            level: "Xã"
          },
          {
            id: "19489",
            name: "Xã Lìa",
            level: "Xã"
          },
          {
            id: "19492",
            name: "Xã Xy",
            level: "Xã"
          }
        ]
      },
      {
        id: "466",
        name: "Huyện Gio Linh",
        wards: [
          {
            id: "19495",
            name: "Thị trấn Gio Linh",
            level: "Thị trấn"
          },
          {
            id: "19496",
            name: "Thị trấn Cửa Việt",
            level: "Thị trấn"
          },
          {
            id: "19498",
            name: "Xã Trung Giang",
            level: "Xã"
          },
          {
            id: "19501",
            name: "Xã Trung Hải",
            level: "Xã"
          },
          {
            id: "19504",
            name: "Xã Trung Sơn",
            level: "Xã"
          },
          {
            id: "19507",
            name: "Xã Phong Bình",
            level: "Xã"
          },
          {
            id: "19510",
            name: "Xã Gio Mỹ",
            level: "Xã"
          },
          {
            id: "19519",
            name: "Xã Gio Hải",
            level: "Xã"
          },
          {
            id: "19522",
            name: "Xã Gio An",
            level: "Xã"
          },
          {
            id: "19525",
            name: "Xã Gio Châu",
            level: "Xã"
          },
          {
            id: "19531",
            name: "Xã Gio Việt",
            level: "Xã"
          },
          {
            id: "19534",
            name: "Xã Linh Trường",
            level: "Xã"
          },
          {
            id: "19537",
            name: "Xã Gio Sơn",
            level: "Xã"
          },
          {
            id: "19543",
            name: "Xã Gio Mai",
            level: "Xã"
          },
          {
            id: "19546",
            name: "Xã Hải Thái",
            level: "Xã"
          },
          {
            id: "19549",
            name: "Xã Linh Hải",
            level: "Xã"
          },
          {
            id: "19552",
            name: "Xã Gio Quang",
            level: "Xã"
          }
        ]
      },
      {
        id: "467",
        name: "Huyện Đa Krông",
        wards: [
          {
            id: "19555",
            name: "Thị trấn Krông Klang",
            level: "Thị trấn"
          },
          {
            id: "19558",
            name: "Xã Mò Ó",
            level: "Xã"
          },
          {
            id: "19561",
            name: "Xã Hướng Hiệp",
            level: "Xã"
          },
          {
            id: "19564",
            name: "Xã Đa Krông",
            level: "Xã"
          },
          {
            id: "19567",
            name: "Xã Triệu Nguyên",
            level: "Xã"
          },
          {
            id: "19570",
            name: "Xã Ba Lòng",
            level: "Xã"
          },
          {
            id: "19576",
            name: "Xã Ba Nang",
            level: "Xã"
          },
          {
            id: "19579",
            name: "Xã Tà Long",
            level: "Xã"
          },
          {
            id: "19582",
            name: "Xã Húc Nghì",
            level: "Xã"
          },
          {
            id: "19585",
            name: "Xã A Vao",
            level: "Xã"
          },
          {
            id: "19588",
            name: "Xã Tà Rụt",
            level: "Xã"
          },
          {
            id: "19591",
            name: "Xã A Bung",
            level: "Xã"
          },
          {
            id: "19594",
            name: "Xã A Ngo",
            level: "Xã"
          }
        ]
      },
      {
        id: "468",
        name: "Huyện Cam Lộ",
        wards: [
          {
            id: "19597",
            name: "Thị trấn Cam Lộ",
            level: "Thị trấn"
          },
          {
            id: "19600",
            name: "Xã Cam Tuyền",
            level: "Xã"
          },
          {
            id: "19603",
            name: "Xã Thanh An",
            level: "Xã"
          },
          {
            id: "19606",
            name: "Xã Cam Thủy",
            level: "Xã"
          },
          {
            id: "19612",
            name: "Xã Cam Thành",
            level: "Xã"
          },
          {
            id: "19615",
            name: "Xã Cam Hiếu",
            level: "Xã"
          },
          {
            id: "19618",
            name: "Xã Cam Chính",
            level: "Xã"
          },
          {
            id: "19621",
            name: "Xã Cam Nghĩa",
            level: "Xã"
          }
        ]
      },
      {
        id: "469",
        name: "Huyện Triệu Phong",
        wards: [
          {
            id: "19624",
            name: "Thị Trấn Ái Tử",
            level: "Thị trấn"
          },
          {
            id: "19627",
            name: "Xã Triệu An",
            level: "Xã"
          },
          {
            id: "19630",
            name: "Xã Triệu Vân",
            level: "Xã"
          },
          {
            id: "19633",
            name: "Xã Triệu Phước",
            level: "Xã"
          },
          {
            id: "19636",
            name: "Xã Triệu Độ",
            level: "Xã"
          },
          {
            id: "19639",
            name: "Xã Triệu Trạch",
            level: "Xã"
          },
          {
            id: "19642",
            name: "Xã Triệu Thuận",
            level: "Xã"
          },
          {
            id: "19645",
            name: "Xã Triệu Đại",
            level: "Xã"
          },
          {
            id: "19648",
            name: "Xã Triệu Hòa",
            level: "Xã"
          },
          {
            id: "19651",
            name: "Xã Triệu Lăng",
            level: "Xã"
          },
          {
            id: "19654",
            name: "Xã Triệu Sơn",
            level: "Xã"
          },
          {
            id: "19657",
            name: "Xã Triệu Long",
            level: "Xã"
          },
          {
            id: "19660",
            name: "Xã Triệu Tài",
            level: "Xã"
          },
          {
            id: "19666",
            name: "Xã Triệu Trung",
            level: "Xã"
          },
          {
            id: "19669",
            name: "Xã Triệu Ái",
            level: "Xã"
          },
          {
            id: "19672",
            name: "Xã Triệu Thượng",
            level: "Xã"
          },
          {
            id: "19675",
            name: "Xã Triệu Giang",
            level: "Xã"
          },
          {
            id: "19678",
            name: "Xã Triệu Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "470",
        name: "Huyện Hải Lăng",
        wards: [
          {
            id: "19681",
            name: "Thị trấn Diên Sanh",
            level: "Thị trấn"
          },
          {
            id: "19684",
            name: "Xã Hải An",
            level: "Xã"
          },
          {
            id: "19687",
            name: "Xã Hải Ba",
            level: "Xã"
          },
          {
            id: "19693",
            name: "Xã Hải Quy",
            level: "Xã"
          },
          {
            id: "19696",
            name: "Xã Hải Quế",
            level: "Xã"
          },
          {
            id: "19699",
            name: "Xã Hải Hưng",
            level: "Xã"
          },
          {
            id: "19702",
            name: "Xã Hải Phú",
            level: "Xã"
          },
          {
            id: "19708",
            name: "Xã Hải Thượng",
            level: "Xã"
          },
          {
            id: "19711",
            name: "Xã Hải Dương",
            level: "Xã"
          },
          {
            id: "19714",
            name: "Xã Hải Định",
            level: "Xã"
          },
          {
            id: "19717",
            name: "Xã Hải Lâm",
            level: "Xã"
          },
          {
            id: "19726",
            name: "Xã Hải Phong",
            level: "Xã"
          },
          {
            id: "19729",
            name: "Xã Hải Trường",
            level: "Xã"
          },
          {
            id: "19735",
            name: "Xã Hải Sơn",
            level: "Xã"
          },
          {
            id: "19738",
            name: "Xã Hải Chánh",
            level: "Xã"
          },
          {
            id: "19741",
            name: "Xã Hải Khê",
            level: "Xã"
          }
        ]
      },
      {
        id: "471",
        name: "Huyện Cồn Cỏ",
        wards: [
          {
            level: "Huyện"
          }
        ]
      }
    ]
  },
  {
    id: "46",
    name: "Tỉnh Thừa Thiên Huế",
    districts: [
      {
        id: "474",
        name: "Thành phố Huế",
        wards: [
          {
            id: "19744",
            name: "Phường Phú Thuận",
            level: "Phường"
          },
          {
            id: "19747",
            name: "Phường Phú Bình",
            level: "Phường"
          },
          {
            id: "19750",
            name: "Phường Tây Lộc",
            level: "Phường"
          },
          {
            id: "19753",
            name: "Phường Thuận Lộc",
            level: "Phường"
          },
          {
            id: "19756",
            name: "Phường Phú Hiệp",
            level: "Phường"
          },
          {
            id: "19759",
            name: "Phường Phú Hậu",
            level: "Phường"
          },
          {
            id: "19762",
            name: "Phường Thuận Hòa",
            level: "Phường"
          },
          {
            id: "19765",
            name: "Phường Thuận Thành",
            level: "Phường"
          },
          {
            id: "19768",
            name: "Phường Phú Hòa",
            level: "Phường"
          },
          {
            id: "19771",
            name: "Phường Phú Cát",
            level: "Phường"
          },
          {
            id: "19774",
            name: "Phường Kim Long",
            level: "Phường"
          },
          {
            id: "19777",
            name: "Phường Vĩ Dạ",
            level: "Phường"
          },
          {
            id: "19780",
            name: "Phường Phường Đúc",
            level: "Phường"
          },
          {
            id: "19783",
            name: "Phường Vĩnh Ninh",
            level: "Phường"
          },
          {
            id: "19786",
            name: "Phường Phú Hội",
            level: "Phường"
          },
          {
            id: "19789",
            name: "Phường Phú Nhuận",
            level: "Phường"
          },
          {
            id: "19792",
            name: "Phường Xuân Phú",
            level: "Phường"
          },
          {
            id: "19795",
            name: "Phường Trường An",
            level: "Phường"
          },
          {
            id: "19798",
            name: "Phường Phước Vĩnh",
            level: "Phường"
          },
          {
            id: "19801",
            name: "Phường An Cựu",
            level: "Phường"
          },
          {
            id: "19803",
            name: "Phường An Hòa",
            level: "Phường"
          },
          {
            id: "19804",
            name: "Phường Hương Sơ",
            level: "Phường"
          },
          {
            id: "19807",
            name: "Phường Thuỷ Biều",
            level: "Phường"
          },
          {
            id: "19810",
            name: "Phường Hương Long",
            level: "Phường"
          },
          {
            id: "19813",
            name: "Phường Thuỷ Xuân",
            level: "Phường"
          },
          {
            id: "19815",
            name: "Phường An Đông",
            level: "Phường"
          },
          {
            id: "19816",
            name: "Phường An Tây",
            level: "Phường"
          }
        ]
      },
      {
        id: "476",
        name: "Huyện Phong Điền",
        wards: [
          {
            id: "19819",
            name: "Thị trấn Phong Điền",
            level: "Thị trấn"
          },
          {
            id: "19822",
            name: "Xã Điền Hương",
            level: "Xã"
          },
          {
            id: "19825",
            name: "Xã Điền Môn",
            level: "Xã"
          },
          {
            id: "19828",
            name: "Xã Điền Lộc",
            level: "Xã"
          },
          {
            id: "19831",
            name: "Xã Phong Bình",
            level: "Xã"
          },
          {
            id: "19834",
            name: "Xã Điền Hòa",
            level: "Xã"
          },
          {
            id: "19837",
            name: "Xã Phong Chương",
            level: "Xã"
          },
          {
            id: "19840",
            name: "Xã Phong Hải",
            level: "Xã"
          },
          {
            id: "19843",
            name: "Xã Điền Hải",
            level: "Xã"
          },
          {
            id: "19846",
            name: "Xã Phong Hòa",
            level: "Xã"
          },
          {
            id: "19849",
            name: "Xã Phong Thu",
            level: "Xã"
          },
          {
            id: "19852",
            name: "Xã Phong Hiền",
            level: "Xã"
          },
          {
            id: "19855",
            name: "Xã Phong Mỹ",
            level: "Xã"
          },
          {
            id: "19858",
            name: "Xã Phong An",
            level: "Xã"
          },
          {
            id: "19861",
            name: "Xã Phong Xuân",
            level: "Xã"
          },
          {
            id: "19864",
            name: "Xã Phong Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "477",
        name: "Huyện Quảng Điền",
        wards: [
          {
            id: "19867",
            name: "Thị trấn Sịa",
            level: "Thị trấn"
          },
          {
            id: "19870",
            name: "Xã Quảng Thái",
            level: "Xã"
          },
          {
            id: "19873",
            name: "Xã Quảng Ngạn",
            level: "Xã"
          },
          {
            id: "19876",
            name: "Xã Quảng Lợi",
            level: "Xã"
          },
          {
            id: "19879",
            name: "Xã Quảng Công",
            level: "Xã"
          },
          {
            id: "19882",
            name: "Xã Quảng Phước",
            level: "Xã"
          },
          {
            id: "19885",
            name: "Xã Quảng Vinh",
            level: "Xã"
          },
          {
            id: "19888",
            name: "Xã Quảng An",
            level: "Xã"
          },
          {
            id: "19891",
            name: "Xã Quảng Thành",
            level: "Xã"
          },
          {
            id: "19894",
            name: "Xã Quảng Thọ",
            level: "Xã"
          },
          {
            id: "19897",
            name: "Xã Quảng Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "478",
        name: "Huyện Phú Vang",
        wards: [
          {
            id: "19900",
            name: "Thị trấn Thuận An",
            level: "Thị trấn"
          },
          {
            id: "19903",
            name: "Xã Phú Thuận",
            level: "Xã"
          },
          {
            id: "19906",
            name: "Xã Phú Dương",
            level: "Xã"
          },
          {
            id: "19909",
            name: "Xã Phú Mậu",
            level: "Xã"
          },
          {
            id: "19912",
            name: "Xã Phú An",
            level: "Xã"
          },
          {
            id: "19915",
            name: "Xã Phú Hải",
            level: "Xã"
          },
          {
            id: "19918",
            name: "Xã Phú Xuân",
            level: "Xã"
          },
          {
            id: "19921",
            name: "Xã Phú Diên",
            level: "Xã"
          },
          {
            id: "19924",
            name: "Xã Phú Thanh",
            level: "Xã"
          },
          {
            id: "19927",
            name: "Xã Phú Mỹ",
            level: "Xã"
          },
          {
            id: "19930",
            name: "Xã Phú Thượng",
            level: "Xã"
          },
          {
            id: "19933",
            name: "Xã Phú Hồ",
            level: "Xã"
          },
          {
            id: "19936",
            name: "Xã Vinh Xuân",
            level: "Xã"
          },
          {
            id: "19939",
            name: "Xã Phú Lương",
            level: "Xã"
          },
          {
            id: "19942",
            name: "Thị trấn Phú Đa",
            level: "Thị trấn"
          },
          {
            id: "19945",
            name: "Xã Vinh Thanh",
            level: "Xã"
          },
          {
            id: "19948",
            name: "Xã Vinh An",
            level: "Xã"
          },
          {
            id: "19954",
            name: "Xã Phú Gia",
            level: "Xã"
          },
          {
            id: "19957",
            name: "Xã Vinh Hà",
            level: "Xã"
          }
        ]
      },
      {
        id: "479",
        name: "Thị xã Hương Thủy",
        wards: [
          {
            id: "19960",
            name: "Phường Phú Bài",
            level: "Phường"
          },
          {
            id: "19963",
            name: "Xã Thủy Vân",
            level: "Xã"
          },
          {
            id: "19966",
            name: "Xã Thủy Thanh",
            level: "Xã"
          },
          {
            id: "19969",
            name: "Phường Thủy Dương",
            level: "Phường"
          },
          {
            id: "19972",
            name: "Phường Thủy Phương",
            level: "Phường"
          },
          {
            id: "19975",
            name: "Phường Thủy Châu",
            level: "Phường"
          },
          {
            id: "19978",
            name: "Phường Thủy Lương",
            level: "Phường"
          },
          {
            id: "19981",
            name: "Xã Thủy Bằng",
            level: "Xã"
          },
          {
            id: "19984",
            name: "Xã Thủy Tân",
            level: "Xã"
          },
          {
            id: "19987",
            name: "Xã Thủy Phù",
            level: "Xã"
          },
          {
            id: "19990",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "19993",
            name: "Xã Dương Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "480",
        name: "Thị xã Hương Trà",
        wards: [
          {
            id: "19996",
            name: "Phường Tứ Hạ",
            level: "Phường"
          },
          {
            id: "19999",
            name: "Xã Hải Dương",
            level: "Xã"
          },
          {
            id: "20002",
            name: "Xã Hương Phong",
            level: "Xã"
          },
          {
            id: "20005",
            name: "Xã Hương Toàn",
            level: "Xã"
          },
          {
            id: "20008",
            name: "Phường Hương Vân",
            level: "Phường"
          },
          {
            id: "20011",
            name: "Phường Hương Văn",
            level: "Phường"
          },
          {
            id: "20014",
            name: "Xã Hương Vinh",
            level: "Xã"
          },
          {
            id: "20017",
            name: "Phường Hương Xuân",
            level: "Phường"
          },
          {
            id: "20020",
            name: "Phường Hương Chữ",
            level: "Phường"
          },
          {
            id: "20023",
            name: "Phường Hương An",
            level: "Phường"
          },
          {
            id: "20026",
            name: "Xã Hương Bình",
            level: "Xã"
          },
          {
            id: "20029",
            name: "Phường Hương Hồ",
            level: "Phường"
          },
          {
            id: "20032",
            name: "Xã Hương Thọ",
            level: "Xã"
          },
          {
            id: "20035",
            name: "Xã Bình Tiến",
            level: "Xã"
          },
          {
            id: "20041",
            name: "Xã Bình Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "481",
        name: "Huyện A Lưới",
        wards: [
          {
            id: "20044",
            name: "Thị trấn A Lưới",
            level: "Thị trấn"
          },
          {
            id: "20047",
            name: "Xã Hồng Vân",
            level: "Xã"
          },
          {
            id: "20050",
            name: "Xã Hồng Hạ",
            level: "Xã"
          },
          {
            id: "20053",
            name: "Xã Hồng Kim",
            level: "Xã"
          },
          {
            id: "20056",
            name: "Xã Trung Sơn",
            level: "Xã"
          },
          {
            id: "20059",
            name: "Xã Hương Nguyên",
            level: "Xã"
          },
          {
            id: "20065",
            name: "Xã Hồng Bắc",
            level: "Xã"
          },
          {
            id: "20068",
            name: "Xã A Ngo",
            level: "Xã"
          },
          {
            id: "20071",
            name: "Xã Sơn Thủy",
            level: "Xã"
          },
          {
            id: "20074",
            name: "Xã Phú Vinh",
            level: "Xã"
          },
          {
            id: "20080",
            name: "Xã Hương Phong",
            level: "Xã"
          },
          {
            id: "20083",
            name: "Xã Quảng Nhâm",
            level: "Xã"
          },
          {
            id: "20086",
            name: "Xã Hồng Thượng",
            level: "Xã"
          },
          {
            id: "20089",
            name: "Xã Hồng Thái",
            level: "Xã"
          },
          {
            id: "20095",
            name: "Xã A Roằng",
            level: "Xã"
          },
          {
            id: "20098",
            name: "Xã Đông Sơn",
            level: "Xã"
          },
          {
            id: "20101",
            name: "Xã Lâm Đớt",
            level: "Xã"
          },
          {
            id: "20104",
            name: "Xã Hồng Thủy",
            level: "Xã"
          }
        ]
      },
      {
        id: "482",
        name: "Huyện Phú Lộc",
        wards: [
          {
            id: "20107",
            name: "Thị trấn Phú Lộc",
            level: "Thị trấn"
          },
          {
            id: "20110",
            name: "Thị trấn Lăng Cô",
            level: "Thị trấn"
          },
          {
            id: "20113",
            name: "Xã Vinh Mỹ",
            level: "Xã"
          },
          {
            id: "20116",
            name: "Xã Vinh Hưng",
            level: "Xã"
          },
          {
            id: "20122",
            name: "Xã Giang Hải",
            level: "Xã"
          },
          {
            id: "20125",
            name: "Xã Vinh Hiền",
            level: "Xã"
          },
          {
            id: "20128",
            name: "Xã Lộc Bổn",
            level: "Xã"
          },
          {
            id: "20131",
            name: "Xã Lộc Sơn",
            level: "Xã"
          },
          {
            id: "20134",
            name: "Xã Lộc Bình",
            level: "Xã"
          },
          {
            id: "20137",
            name: "Xã Lộc Vĩnh",
            level: "Xã"
          },
          {
            id: "20140",
            name: "Xã Lộc An",
            level: "Xã"
          },
          {
            id: "20143",
            name: "Xã Lộc Điền",
            level: "Xã"
          },
          {
            id: "20146",
            name: "Xã Lộc Thủy",
            level: "Xã"
          },
          {
            id: "20149",
            name: "Xã Lộc Trì",
            level: "Xã"
          },
          {
            id: "20152",
            name: "Xã Lộc Tiến",
            level: "Xã"
          },
          {
            id: "20155",
            name: "Xã Lộc Hòa",
            level: "Xã"
          },
          {
            id: "20158",
            name: "Xã Xuân Lộc",
            level: "Xã"
          }
        ]
      },
      {
        id: "483",
        name: "Huyện Nam Đông",
        wards: [
          {
            id: "20161",
            name: "Thị trấn Khe Tre",
            level: "Thị trấn"
          },
          {
            id: "20164",
            name: "Xã Hương Phú",
            level: "Xã"
          },
          {
            id: "20167",
            name: "Xã Hương Sơn",
            level: "Xã"
          },
          {
            id: "20170",
            name: "Xã Hương Lộc",
            level: "Xã"
          },
          {
            id: "20173",
            name: "Xã Thượng Quảng",
            level: "Xã"
          },
          {
            id: "20179",
            name: "Xã Hương Xuân",
            level: "Xã"
          },
          {
            id: "20182",
            name: "Xã Hương Hữu",
            level: "Xã"
          },
          {
            id: "20185",
            name: "Xã Thượng Lộ",
            level: "Xã"
          },
          {
            id: "20188",
            name: "Xã Thượng Long",
            level: "Xã"
          },
          {
            id: "20191",
            name: "Xã Thượng Nhật",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "48",
    name: "Thành phố Đà Nẵng",
    districts: [
      {
        id: "490",
        name: "Quận Liên Chiểu",
        wards: [
          {
            id: "20194",
            name: "Phường Hòa Hiệp Bắc",
            level: "Phường"
          },
          {
            id: "20195",
            name: "Phường Hòa Hiệp Nam",
            level: "Phường"
          },
          {
            id: "20197",
            name: "Phường Hòa Khánh Bắc",
            level: "Phường"
          },
          {
            id: "20198",
            name: "Phường Hòa Khánh Nam",
            level: "Phường"
          },
          {
            id: "20200",
            name: "Phường Hòa Minh",
            level: "Phường"
          }
        ]
      },
      {
        id: "491",
        name: "Quận Thanh Khê",
        wards: [
          {
            id: "20203",
            name: "Phường Tam Thuận",
            level: "Phường"
          },
          {
            id: "20206",
            name: "Phường Thanh Khê Tây",
            level: "Phường"
          },
          {
            id: "20207",
            name: "Phường Thanh Khê Đông",
            level: "Phường"
          },
          {
            id: "20209",
            name: "Phường Xuân Hà",
            level: "Phường"
          },
          {
            id: "20212",
            name: "Phường Tân Chính",
            level: "Phường"
          },
          {
            id: "20215",
            name: "Phường Chính Gián",
            level: "Phường"
          },
          {
            id: "20218",
            name: "Phường Vĩnh Trung",
            level: "Phường"
          },
          {
            id: "20221",
            name: "Phường Thạc Gián",
            level: "Phường"
          },
          {
            id: "20224",
            name: "Phường An Khê",
            level: "Phường"
          },
          {
            id: "20225",
            name: "Phường Hòa Khê",
            level: "Phường"
          }
        ]
      },
      {
        id: "492",
        name: "Quận Hải Châu",
        wards: [
          {
            id: "20227",
            name: "Phường Thanh Bình",
            level: "Phường"
          },
          {
            id: "20230",
            name: "Phường Thuận Phước",
            level: "Phường"
          },
          {
            id: "20233",
            name: "Phường Thạch Thang",
            level: "Phường"
          },
          {
            id: "20236",
            name: "Phường Hải Châu  I",
            level: "Phường"
          },
          {
            id: "20239",
            name: "Phường Hải Châu II",
            level: "Phường"
          },
          {
            id: "20242",
            name: "Phường Phước Ninh",
            level: "Phường"
          },
          {
            id: "20245",
            name: "Phường Hòa Thuận Tây",
            level: "Phường"
          },
          {
            id: "20246",
            name: "Phường Hòa Thuận Đông",
            level: "Phường"
          },
          {
            id: "20248",
            name: "Phường Nam Dương",
            level: "Phường"
          },
          {
            id: "20251",
            name: "Phường Bình Hiên",
            level: "Phường"
          },
          {
            id: "20254",
            name: "Phường Bình Thuận",
            level: "Phường"
          },
          {
            id: "20257",
            name: "Phường Hòa Cường Bắc",
            level: "Phường"
          },
          {
            id: "20258",
            name: "Phường Hòa Cường Nam",
            level: "Phường"
          }
        ]
      },
      {
        id: "493",
        name: "Quận Sơn Trà",
        wards: [
          {
            id: "20263",
            name: "Phường Thọ Quang",
            level: "Phường"
          },
          {
            id: "20266",
            name: "Phường Nại Hiên Đông",
            level: "Phường"
          },
          {
            id: "20269",
            name: "Phường Mân Thái",
            level: "Phường"
          },
          {
            id: "20272",
            name: "Phường An Hải Bắc",
            level: "Phường"
          },
          {
            id: "20275",
            name: "Phường Phước Mỹ",
            level: "Phường"
          },
          {
            id: "20278",
            name: "Phường An Hải Tây",
            level: "Phường"
          },
          {
            id: "20281",
            name: "Phường An Hải Đông",
            level: "Phường"
          }
        ]
      },
      {
        id: "494",
        name: "Quận Ngũ Hành Sơn",
        wards: [
          {
            id: "20284",
            name: "Phường Mỹ An",
            level: "Phường"
          },
          {
            id: "20285",
            name: "Phường Khuê Mỹ",
            level: "Phường"
          },
          {
            id: "20287",
            name: "Phường Hoà Quý",
            level: "Phường"
          },
          {
            id: "20290",
            name: "Phường Hoà Hải",
            level: "Phường"
          }
        ]
      },
      {
        id: "495",
        name: "Quận Cẩm Lệ",
        wards: [
          {
            id: "20260",
            name: "Phường Khuê Trung",
            level: "Phường"
          },
          {
            id: "20305",
            name: "Phường Hòa Phát",
            level: "Phường"
          },
          {
            id: "20306",
            name: "Phường Hòa An",
            level: "Phường"
          },
          {
            id: "20311",
            name: "Phường Hòa Thọ Tây",
            level: "Phường"
          },
          {
            id: "20312",
            name: "Phường Hòa Thọ Đông",
            level: "Phường"
          },
          {
            id: "20314",
            name: "Phường Hòa Xuân",
            level: "Phường"
          }
        ]
      },
      {
        id: "497",
        name: "Huyện Hòa Vang",
        wards: [
          {
            id: "20293",
            name: "Xã Hòa Bắc",
            level: "Xã"
          },
          {
            id: "20296",
            name: "Xã Hòa Liên",
            level: "Xã"
          },
          {
            id: "20299",
            name: "Xã Hòa Ninh",
            level: "Xã"
          },
          {
            id: "20302",
            name: "Xã Hòa Sơn",
            level: "Xã"
          },
          {
            id: "20308",
            name: "Xã Hòa Nhơn",
            level: "Xã"
          },
          {
            id: "20317",
            name: "Xã Hòa Phú",
            level: "Xã"
          },
          {
            id: "20320",
            name: "Xã Hòa Phong",
            level: "Xã"
          },
          {
            id: "20323",
            name: "Xã Hòa Châu",
            level: "Xã"
          },
          {
            id: "20326",
            name: "Xã Hòa Tiến",
            level: "Xã"
          },
          {
            id: "20329",
            name: "Xã Hòa Phước",
            level: "Xã"
          },
          {
            id: "20332",
            name: "Xã Hòa Khương",
            level: "Xã"
          }
        ]
      },
      {
        id: "498",
        name: "Huyện Hoàng Sa",
        wards: [
          {
            level: "Huyện"
          }
        ]
      }
    ]
  },
  {
    id: "49",
    name: "Tỉnh Quảng Nam",
    districts: [
      {
        id: "502",
        name: "Thành phố Tam Kỳ",
        wards: [
          {
            id: "20335",
            name: "Phường Tân Thạnh",
            level: "Phường"
          },
          {
            id: "20338",
            name: "Phường Phước Hòa",
            level: "Phường"
          },
          {
            id: "20341",
            name: "Phường An Mỹ",
            level: "Phường"
          },
          {
            id: "20344",
            name: "Phường Hòa Hương",
            level: "Phường"
          },
          {
            id: "20347",
            name: "Phường An Xuân",
            level: "Phường"
          },
          {
            id: "20350",
            name: "Phường An Sơn",
            level: "Phường"
          },
          {
            id: "20353",
            name: "Phường Trường Xuân",
            level: "Phường"
          },
          {
            id: "20356",
            name: "Phường An Phú",
            level: "Phường"
          },
          {
            id: "20359",
            name: "Xã Tam Thanh",
            level: "Xã"
          },
          {
            id: "20362",
            name: "Xã Tam Thăng",
            level: "Xã"
          },
          {
            id: "20371",
            name: "Xã Tam Phú",
            level: "Xã"
          },
          {
            id: "20375",
            name: "Phường Hoà Thuận",
            level: "Phường"
          },
          {
            id: "20389",
            name: "Xã Tam Ngọc",
            level: "Xã"
          }
        ]
      },
      {
        id: "503",
        name: "Thành phố Hội An",
        wards: [
          {
            id: "20398",
            name: "Phường Minh An",
            level: "Phường"
          },
          {
            id: "20401",
            name: "Phường Tân An",
            level: "Phường"
          },
          {
            id: "20404",
            name: "Phường Cẩm Phô",
            level: "Phường"
          },
          {
            id: "20407",
            name: "Phường Thanh Hà",
            level: "Phường"
          },
          {
            id: "20410",
            name: "Phường Sơn Phong",
            level: "Phường"
          },
          {
            id: "20413",
            name: "Phường Cẩm Châu",
            level: "Phường"
          },
          {
            id: "20416",
            name: "Phường Cửa Đại",
            level: "Phường"
          },
          {
            id: "20419",
            name: "Phường Cẩm An",
            level: "Phường"
          },
          {
            id: "20422",
            name: "Xã Cẩm Hà",
            level: "Xã"
          },
          {
            id: "20425",
            name: "Xã Cẩm Kim",
            level: "Xã"
          },
          {
            id: "20428",
            name: "Phường Cẩm Nam",
            level: "Phường"
          },
          {
            id: "20431",
            name: "Xã Cẩm Thanh",
            level: "Xã"
          },
          {
            id: "20434",
            name: "Xã Tân Hiệp",
            level: "Xã"
          }
        ]
      },
      {
        id: "504",
        name: "Huyện Tây Giang",
        wards: [
          {
            id: "20437",
            name: "Xã Ch'ơm",
            level: "Xã"
          },
          {
            id: "20440",
            name: "Xã Ga Ri",
            level: "Xã"
          },
          {
            id: "20443",
            name: "Xã A Xan",
            level: "Xã"
          },
          {
            id: "20446",
            name: "Xã Tr'Hy",
            level: "Xã"
          },
          {
            id: "20449",
            name: "Xã Lăng",
            level: "Xã"
          },
          {
            id: "20452",
            name: "Xã A Nông",
            level: "Xã"
          },
          {
            id: "20455",
            name: "Xã A Tiêng",
            level: "Xã"
          },
          {
            id: "20458",
            name: "Xã Bha Lê",
            level: "Xã"
          },
          {
            id: "20461",
            name: "Xã A Vương",
            level: "Xã"
          },
          {
            id: "20464",
            name: "Xã Dang",
            level: "Xã"
          }
        ]
      },
      {
        id: "505",
        name: "Huyện Đông Giang",
        wards: [
          {
            id: "20467",
            name: "Thị trấn P Rao",
            level: "Thị trấn"
          },
          {
            id: "20470",
            name: "Xã Tà Lu",
            level: "Xã"
          },
          {
            id: "20473",
            name: "Xã Sông Kôn",
            level: "Xã"
          },
          {
            id: "20476",
            name: "Xã Jơ Ngây",
            level: "Xã"
          },
          {
            id: "20479",
            name: "Xã A Ting",
            level: "Xã"
          },
          {
            id: "20482",
            name: "Xã  Tư",
            level: "Xã"
          },
          {
            id: "20485",
            name: "Xã Ba",
            level: "Xã"
          },
          {
            id: "20488",
            name: "Xã A Rooi",
            level: "Xã"
          },
          {
            id: "20491",
            name: "Xã Za Hung",
            level: "Xã"
          },
          {
            id: "20494",
            name: "Xã Mà Cooi",
            level: "Xã"
          },
          {
            id: "20497",
            name: "Xã Ka Dăng",
            level: "Xã"
          }
        ]
      },
      {
        id: "506",
        name: "Huyện Đại Lộc",
        wards: [
          {
            id: "20500",
            name: "Thị Trấn Ái Nghĩa",
            level: "Thị trấn"
          },
          {
            id: "20503",
            name: "Xã Đại Sơn",
            level: "Xã"
          },
          {
            id: "20506",
            name: "Xã Đại Lãnh",
            level: "Xã"
          },
          {
            id: "20509",
            name: "Xã Đại Hưng",
            level: "Xã"
          },
          {
            id: "20512",
            name: "Xã Đại Hồng",
            level: "Xã"
          },
          {
            id: "20515",
            name: "Xã Đại Đồng",
            level: "Xã"
          },
          {
            id: "20518",
            name: "Xã Đại Quang",
            level: "Xã"
          },
          {
            id: "20521",
            name: "Xã Đại Nghĩa",
            level: "Xã"
          },
          {
            id: "20524",
            name: "Xã Đại Hiệp",
            level: "Xã"
          },
          {
            id: "20527",
            name: "Xã Đại Thạnh",
            level: "Xã"
          },
          {
            id: "20530",
            name: "Xã Đại Chánh",
            level: "Xã"
          },
          {
            id: "20533",
            name: "Xã Đại Tân",
            level: "Xã"
          },
          {
            id: "20536",
            name: "Xã Đại Phong",
            level: "Xã"
          },
          {
            id: "20539",
            name: "Xã Đại Minh",
            level: "Xã"
          },
          {
            id: "20542",
            name: "Xã Đại Thắng",
            level: "Xã"
          },
          {
            id: "20545",
            name: "Xã Đại Cường",
            level: "Xã"
          },
          {
            id: "20547",
            name: "Xã Đại An",
            level: "Xã"
          },
          {
            id: "20548",
            name: "Xã Đại Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "507",
        name: "Thị xã Điện Bàn",
        wards: [
          {
            id: "20551",
            name: "Phường Vĩnh Điện",
            level: "Phường"
          },
          {
            id: "20554",
            name: "Xã Điện Tiến",
            level: "Xã"
          },
          {
            id: "20557",
            name: "Xã Điện Hòa",
            level: "Xã"
          },
          {
            id: "20560",
            name: "Xã Điện Thắng Bắc",
            level: "Xã"
          },
          {
            id: "20561",
            name: "Xã Điện Thắng Trung",
            level: "Xã"
          },
          {
            id: "20562",
            name: "Xã Điện Thắng Nam",
            level: "Xã"
          },
          {
            id: "20563",
            name: "Phường Điện Ngọc",
            level: "Phường"
          },
          {
            id: "20566",
            name: "Xã Điện Hồng",
            level: "Xã"
          },
          {
            id: "20569",
            name: "Xã Điện Thọ",
            level: "Xã"
          },
          {
            id: "20572",
            name: "Xã Điện Phước",
            level: "Xã"
          },
          {
            id: "20575",
            name: "Phường Điện An",
            level: "Phường"
          },
          {
            id: "20578",
            name: "Phường Điện Nam Bắc",
            level: "Phường"
          },
          {
            id: "20579",
            name: "Phường Điện Nam Trung",
            level: "Phường"
          },
          {
            id: "20580",
            name: "Phường Điện Nam Đông",
            level: "Phường"
          },
          {
            id: "20581",
            name: "Phường Điện Dương",
            level: "Phường"
          },
          {
            id: "20584",
            name: "Xã Điện Quang",
            level: "Xã"
          },
          {
            id: "20587",
            name: "Xã Điện Trung",
            level: "Xã"
          },
          {
            id: "20590",
            name: "Xã Điện Phong",
            level: "Xã"
          },
          {
            id: "20593",
            name: "Xã Điện Minh",
            level: "Xã"
          },
          {
            id: "20596",
            name: "Xã Điện Phương",
            level: "Xã"
          }
        ]
      },
      {
        id: "508",
        name: "Huyện Duy Xuyên",
        wards: [
          {
            id: "20599",
            name: "Thị trấn Nam Phước",
            level: "Thị trấn"
          },
          {
            id: "20602",
            name: "Xã Duy Thu",
            level: "Xã"
          },
          {
            id: "20605",
            name: "Xã Duy Phú",
            level: "Xã"
          },
          {
            id: "20608",
            name: "Xã Duy Tân",
            level: "Xã"
          },
          {
            id: "20611",
            name: "Xã Duy Hòa",
            level: "Xã"
          },
          {
            id: "20614",
            name: "Xã Duy Châu",
            level: "Xã"
          },
          {
            id: "20617",
            name: "Xã Duy Trinh",
            level: "Xã"
          },
          {
            id: "20620",
            name: "Xã Duy Sơn",
            level: "Xã"
          },
          {
            id: "20623",
            name: "Xã Duy Trung",
            level: "Xã"
          },
          {
            id: "20626",
            name: "Xã Duy Phước",
            level: "Xã"
          },
          {
            id: "20629",
            name: "Xã Duy Thành",
            level: "Xã"
          },
          {
            id: "20632",
            name: "Xã Duy Vinh",
            level: "Xã"
          },
          {
            id: "20635",
            name: "Xã Duy Nghĩa",
            level: "Xã"
          },
          {
            id: "20638",
            name: "Xã Duy Hải",
            level: "Xã"
          }
        ]
      },
      {
        id: "509",
        name: "Huyện Quế Sơn",
        wards: [
          {
            id: "20641",
            name: "Thị trấn Đông Phú",
            level: "Thị trấn"
          },
          {
            id: "20644",
            name: "Xã Quế Xuân 1",
            level: "Xã"
          },
          {
            id: "20647",
            name: "Xã Quế Xuân 2",
            level: "Xã"
          },
          {
            id: "20650",
            name: "Xã Quế Phú",
            level: "Xã"
          },
          {
            id: "20651",
            name: "Thị trấn Hương An",
            level: "Thị trấn"
          },
          {
            id: "20659",
            name: "Xã Quế Hiệp",
            level: "Xã"
          },
          {
            id: "20662",
            name: "Xã Quế Thuận",
            level: "Xã"
          },
          {
            id: "20665",
            name: "Xã Quế Mỹ",
            level: "Xã"
          },
          {
            id: "20677",
            name: "Xã Quế Long",
            level: "Xã"
          },
          {
            id: "20680",
            name: "Xã Quế Châu",
            level: "Xã"
          },
          {
            id: "20683",
            name: "Xã Quế Phong",
            level: "Xã"
          },
          {
            id: "20686",
            name: "Xã Quế An",
            level: "Xã"
          },
          {
            id: "20689",
            name: "Xã Quế Minh",
            level: "Xã"
          }
        ]
      },
      {
        id: "510",
        name: "Huyện Nam Giang",
        wards: [
          {
            id: "20695",
            name: "Thị trấn Thạnh Mỹ",
            level: "Thị trấn"
          },
          {
            id: "20698",
            name: "Xã Laêê",
            level: "Xã"
          },
          {
            id: "20699",
            name: "Xã Chơ Chun",
            level: "Xã"
          },
          {
            id: "20701",
            name: "Xã Zuôich",
            level: "Xã"
          },
          {
            id: "20702",
            name: "Xã Tà Pơơ",
            level: "Xã"
          },
          {
            id: "20704",
            name: "Xã La Dêê",
            level: "Xã"
          },
          {
            id: "20705",
            name: "Xã Đắc Tôi",
            level: "Xã"
          },
          {
            id: "20707",
            name: "Xã Chà Vàl",
            level: "Xã"
          },
          {
            id: "20710",
            name: "Xã Tà Bhinh",
            level: "Xã"
          },
          {
            id: "20713",
            name: "Xã Cà Dy",
            level: "Xã"
          },
          {
            id: "20716",
            name: "Xã Đắc Pre",
            level: "Xã"
          },
          {
            id: "20719",
            name: "Xã Đắc Pring",
            level: "Xã"
          }
        ]
      },
      {
        id: "511",
        name: "Huyện Phước Sơn",
        wards: [
          {
            id: "20722",
            name: "Thị trấn Khâm Đức",
            level: "Thị trấn"
          },
          {
            id: "20725",
            name: "Xã Phước Xuân",
            level: "Xã"
          },
          {
            id: "20728",
            name: "Xã Phước Hiệp",
            level: "Xã"
          },
          {
            id: "20729",
            name: "Xã Phước Hoà",
            level: "Xã"
          },
          {
            id: "20731",
            name: "Xã Phước Đức",
            level: "Xã"
          },
          {
            id: "20734",
            name: "Xã Phước Năng",
            level: "Xã"
          },
          {
            id: "20737",
            name: "Xã Phước Mỹ",
            level: "Xã"
          },
          {
            id: "20740",
            name: "Xã Phước Chánh",
            level: "Xã"
          },
          {
            id: "20743",
            name: "Xã Phước Công",
            level: "Xã"
          },
          {
            id: "20746",
            name: "Xã Phước Kim",
            level: "Xã"
          },
          {
            id: "20749",
            name: "Xã Phước Lộc",
            level: "Xã"
          },
          {
            id: "20752",
            name: "Xã Phước Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "512",
        name: "Huyện Hiệp Đức",
        wards: [
          {
            id: "20758",
            name: "Xã Hiệp Hòa",
            level: "Xã"
          },
          {
            id: "20761",
            name: "Xã Hiệp Thuận",
            level: "Xã"
          },
          {
            id: "20764",
            name: "Xã Quế Thọ",
            level: "Xã"
          },
          {
            id: "20767",
            name: "Xã Bình Lâm",
            level: "Xã"
          },
          {
            id: "20770",
            name: "Xã Sông Trà",
            level: "Xã"
          },
          {
            id: "20773",
            name: "Xã Phước Trà",
            level: "Xã"
          },
          {
            id: "20776",
            name: "Xã Phước Gia",
            level: "Xã"
          },
          {
            id: "20779",
            name: "Thị trấn Tân Bình",
            level: "Thị trấn"
          },
          {
            id: "20782",
            name: "Xã Quế Lưu",
            level: "Xã"
          },
          {
            id: "20785",
            name: "Xã Thăng Phước",
            level: "Xã"
          },
          {
            id: "20788",
            name: "Xã Bình Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "513",
        name: "Huyện Thăng Bình",
        wards: [
          {
            id: "20791",
            name: "Thị trấn Hà Lam",
            level: "Thị trấn"
          },
          {
            id: "20794",
            name: "Xã Bình Dương",
            level: "Xã"
          },
          {
            id: "20797",
            name: "Xã Bình Giang",
            level: "Xã"
          },
          {
            id: "20800",
            name: "Xã Bình Nguyên",
            level: "Xã"
          },
          {
            id: "20803",
            name: "Xã Bình Phục",
            level: "Xã"
          },
          {
            id: "20806",
            name: "Xã Bình Triều",
            level: "Xã"
          },
          {
            id: "20809",
            name: "Xã Bình Đào",
            level: "Xã"
          },
          {
            id: "20812",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "20815",
            name: "Xã Bình Lãnh",
            level: "Xã"
          },
          {
            id: "20818",
            name: "Xã Bình Trị",
            level: "Xã"
          },
          {
            id: "20821",
            name: "Xã Bình Định Bắc",
            level: "Xã"
          },
          {
            id: "20822",
            name: "Xã Bình Định Nam",
            level: "Xã"
          },
          {
            id: "20824",
            name: "Xã Bình Quý",
            level: "Xã"
          },
          {
            id: "20827",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "20830",
            name: "Xã Bình Chánh",
            level: "Xã"
          },
          {
            id: "20833",
            name: "Xã Bình Tú",
            level: "Xã"
          },
          {
            id: "20836",
            name: "Xã Bình Sa",
            level: "Xã"
          },
          {
            id: "20839",
            name: "Xã Bình Hải",
            level: "Xã"
          },
          {
            id: "20842",
            name: "Xã Bình Quế",
            level: "Xã"
          },
          {
            id: "20845",
            name: "Xã Bình An",
            level: "Xã"
          },
          {
            id: "20848",
            name: "Xã Bình Trung",
            level: "Xã"
          },
          {
            id: "20851",
            name: "Xã Bình Nam",
            level: "Xã"
          }
        ]
      },
      {
        id: "514",
        name: "Huyện Tiên Phước",
        wards: [
          {
            id: "20854",
            name: "Thị trấn Tiên Kỳ",
            level: "Thị trấn"
          },
          {
            id: "20857",
            name: "Xã Tiên Sơn",
            level: "Xã"
          },
          {
            id: "20860",
            name: "Xã Tiên Hà",
            level: "Xã"
          },
          {
            id: "20863",
            name: "Xã Tiên Cẩm",
            level: "Xã"
          },
          {
            id: "20866",
            name: "Xã Tiên Châu",
            level: "Xã"
          },
          {
            id: "20869",
            name: "Xã Tiên Lãnh",
            level: "Xã"
          },
          {
            id: "20872",
            name: "Xã Tiên Ngọc",
            level: "Xã"
          },
          {
            id: "20875",
            name: "Xã Tiên Hiệp",
            level: "Xã"
          },
          {
            id: "20878",
            name: "Xã Tiên Cảnh",
            level: "Xã"
          },
          {
            id: "20881",
            name: "Xã Tiên Mỹ",
            level: "Xã"
          },
          {
            id: "20884",
            name: "Xã Tiên Phong",
            level: "Xã"
          },
          {
            id: "20887",
            name: "Xã Tiên Thọ",
            level: "Xã"
          },
          {
            id: "20890",
            name: "Xã Tiên An",
            level: "Xã"
          },
          {
            id: "20893",
            name: "Xã Tiên Lộc",
            level: "Xã"
          },
          {
            id: "20896",
            name: "Xã Tiên Lập",
            level: "Xã"
          }
        ]
      },
      {
        id: "515",
        name: "Huyện Bắc Trà My",
        wards: [
          {
            id: "20899",
            name: "Thị trấn Trà My",
            level: "Thị trấn"
          },
          {
            id: "20900",
            name: "Xã Trà Sơn",
            level: "Xã"
          },
          {
            id: "20902",
            name: "Xã Trà Kót",
            level: "Xã"
          },
          {
            id: "20905",
            name: "Xã Trà Nú",
            level: "Xã"
          },
          {
            id: "20908",
            name: "Xã Trà Đông",
            level: "Xã"
          },
          {
            id: "20911",
            name: "Xã Trà Dương",
            level: "Xã"
          },
          {
            id: "20914",
            name: "Xã Trà Giang",
            level: "Xã"
          },
          {
            id: "20917",
            name: "Xã Trà Bui",
            level: "Xã"
          },
          {
            id: "20920",
            name: "Xã Trà Đốc",
            level: "Xã"
          },
          {
            id: "20923",
            name: "Xã Trà Tân",
            level: "Xã"
          },
          {
            id: "20926",
            name: "Xã Trà Giác",
            level: "Xã"
          },
          {
            id: "20929",
            name: "Xã Trà Giáp",
            level: "Xã"
          },
          {
            id: "20932",
            name: "Xã Trà Ka",
            level: "Xã"
          }
        ]
      },
      {
        id: "516",
        name: "Huyện Nam Trà My",
        wards: [
          {
            id: "20935",
            name: "Xã Trà Leng",
            level: "Xã"
          },
          {
            id: "20938",
            name: "Xã Trà Dơn",
            level: "Xã"
          },
          {
            id: "20941",
            name: "Xã Trà Tập",
            level: "Xã"
          },
          {
            id: "20944",
            name: "Xã Trà Mai",
            level: "Xã"
          },
          {
            id: "20947",
            name: "Xã Trà Cang",
            level: "Xã"
          },
          {
            id: "20950",
            name: "Xã Trà Linh",
            level: "Xã"
          },
          {
            id: "20953",
            name: "Xã Trà Nam",
            level: "Xã"
          },
          {
            id: "20956",
            name: "Xã Trà Don",
            level: "Xã"
          },
          {
            id: "20959",
            name: "Xã Trà Vân",
            level: "Xã"
          },
          {
            id: "20962",
            name: "Xã Trà Vinh",
            level: "Xã"
          }
        ]
      },
      {
        id: "517",
        name: "Huyện Núi Thành",
        wards: [
          {
            id: "20965",
            name: "Thị trấn Núi Thành",
            level: "Thị trấn"
          },
          {
            id: "20968",
            name: "Xã Tam Xuân I",
            level: "Xã"
          },
          {
            id: "20971",
            name: "Xã Tam Xuân II",
            level: "Xã"
          },
          {
            id: "20974",
            name: "Xã Tam Tiến",
            level: "Xã"
          },
          {
            id: "20977",
            name: "Xã Tam Sơn",
            level: "Xã"
          },
          {
            id: "20980",
            name: "Xã Tam Thạnh",
            level: "Xã"
          },
          {
            id: "20983",
            name: "Xã Tam Anh Bắc",
            level: "Xã"
          },
          {
            id: "20984",
            name: "Xã Tam Anh Nam",
            level: "Xã"
          },
          {
            id: "20986",
            name: "Xã Tam Hòa",
            level: "Xã"
          },
          {
            id: "20989",
            name: "Xã Tam Hiệp",
            level: "Xã"
          },
          {
            id: "20992",
            name: "Xã Tam Hải",
            level: "Xã"
          },
          {
            id: "20995",
            name: "Xã Tam Giang",
            level: "Xã"
          },
          {
            id: "20998",
            name: "Xã Tam Quang",
            level: "Xã"
          },
          {
            id: "21001",
            name: "Xã Tam Nghĩa",
            level: "Xã"
          },
          {
            id: "21004",
            name: "Xã Tam Mỹ Tây",
            level: "Xã"
          },
          {
            id: "21005",
            name: "Xã Tam Mỹ Đông",
            level: "Xã"
          },
          {
            id: "21007",
            name: "Xã Tam Trà",
            level: "Xã"
          }
        ]
      },
      {
        id: "518",
        name: "Huyện Phú Ninh",
        wards: [
          {
            id: "20364",
            name: "Thị trấn Phú Thịnh",
            level: "Thị trấn"
          },
          {
            id: "20365",
            name: "Xã Tam Thành",
            level: "Xã"
          },
          {
            id: "20368",
            name: "Xã Tam An",
            level: "Xã"
          },
          {
            id: "20374",
            name: "Xã Tam Đàn",
            level: "Xã"
          },
          {
            id: "20377",
            name: "Xã Tam Lộc",
            level: "Xã"
          },
          {
            id: "20380",
            name: "Xã Tam Phước",
            level: "Xã"
          },
          {
            id: "20383",
            name: "Xã Tam Vinh",
            level: "Xã"
          },
          {
            id: "20386",
            name: "Xã Tam Thái",
            level: "Xã"
          },
          {
            id: "20387",
            name: "Xã Tam Đại",
            level: "Xã"
          },
          {
            id: "20392",
            name: "Xã Tam Dân",
            level: "Xã"
          },
          {
            id: "20395",
            name: "Xã Tam Lãnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "519",
        name: "Huyện Nông Sơn",
        wards: [
          {
            id: "20656",
            name: "Xã Quế Trung",
            level: "Xã"
          },
          {
            id: "20668",
            name: "Xã Ninh Phước",
            level: "Xã"
          },
          {
            id: "20669",
            name: "Xã Phước Ninh",
            level: "Xã"
          },
          {
            id: "20671",
            name: "Xã Quế Lộc",
            level: "Xã"
          },
          {
            id: "20672",
            name: "Xã Sơn Viên",
            level: "Xã"
          },
          {
            id: "20692",
            name: "Xã Quế Lâm",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "51",
    name: "Tỉnh Quảng Ngãi",
    districts: [
      {
        id: "522",
        name: "Thành phố Quảng Ngãi",
        wards: [
          {
            id: "21010",
            name: "Phường Lê Hồng Phong",
            level: "Phường"
          },
          {
            id: "21013",
            name: "Phường Trần Phú",
            level: "Phường"
          },
          {
            id: "21016",
            name: "Phường Quảng Phú",
            level: "Phường"
          },
          {
            id: "21019",
            name: "Phường Nghĩa Chánh",
            level: "Phường"
          },
          {
            id: "21022",
            name: "Phường Trần Hưng Đạo",
            level: "Phường"
          },
          {
            id: "21025",
            name: "Phường Nguyễn Nghiêm",
            level: "Phường"
          },
          {
            id: "21028",
            name: "Phường Nghĩa Lộ",
            level: "Phường"
          },
          {
            id: "21031",
            name: "Phường Chánh Lộ",
            level: "Phường"
          },
          {
            id: "21034",
            name: "Xã Nghĩa Dũng",
            level: "Xã"
          },
          {
            id: "21037",
            name: "Xã Nghĩa Dõng",
            level: "Xã"
          },
          {
            id: "21172",
            name: "Phường Trương Quang Trọng",
            level: "Phường"
          },
          {
            id: "21187",
            name: "Xã Tịnh Hòa",
            level: "Xã"
          },
          {
            id: "21190",
            name: "Xã Tịnh Kỳ",
            level: "Xã"
          },
          {
            id: "21199",
            name: "Xã Tịnh Thiện",
            level: "Xã"
          },
          {
            id: "21202",
            name: "Xã Tịnh Ấn Đông",
            level: "Xã"
          },
          {
            id: "21208",
            name: "Xã Tịnh Châu",
            level: "Xã"
          },
          {
            id: "21211",
            name: "Xã Tịnh Khê",
            level: "Xã"
          },
          {
            id: "21214",
            name: "Xã Tịnh Long",
            level: "Xã"
          },
          {
            id: "21223",
            name: "Xã Tịnh Ấn Tây",
            level: "Xã"
          },
          {
            id: "21232",
            name: "Xã Tịnh An",
            level: "Xã"
          },
          {
            id: "21253",
            name: "Xã Nghĩa Phú",
            level: "Xã"
          },
          {
            id: "21256",
            name: "Xã Nghĩa Hà",
            level: "Xã"
          },
          {
            id: "21262",
            name: "Xã Nghĩa An",
            level: "Xã"
          }
        ]
      },
      {
        id: "524",
        name: "Huyện Bình Sơn",
        wards: [
          {
            id: "21040",
            name: "Thị Trấn Châu Ổ",
            level: "Thị trấn"
          },
          {
            id: "21043",
            name: "Xã Bình Thuận",
            level: "Xã"
          },
          {
            id: "21046",
            name: "Xã Bình Thạnh",
            level: "Xã"
          },
          {
            id: "21049",
            name: "Xã Bình Đông",
            level: "Xã"
          },
          {
            id: "21052",
            name: "Xã Bình Chánh",
            level: "Xã"
          },
          {
            id: "21055",
            name: "Xã Bình Nguyên",
            level: "Xã"
          },
          {
            id: "21058",
            name: "Xã Bình Khương",
            level: "Xã"
          },
          {
            id: "21061",
            name: "Xã Bình Trị",
            level: "Xã"
          },
          {
            id: "21064",
            name: "Xã Bình An",
            level: "Xã"
          },
          {
            id: "21067",
            name: "Xã Bình Hải",
            level: "Xã"
          },
          {
            id: "21070",
            name: "Xã Bình Dương",
            level: "Xã"
          },
          {
            id: "21073",
            name: "Xã Bình Phước",
            level: "Xã"
          },
          {
            id: "21079",
            name: "Xã Bình Hòa",
            level: "Xã"
          },
          {
            id: "21082",
            name: "Xã Bình Trung",
            level: "Xã"
          },
          {
            id: "21085",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "21088",
            name: "Xã Bình Long",
            level: "Xã"
          },
          {
            id: "21091",
            name: "Xã Bình Thanh ",
            level: "Xã"
          },
          {
            id: "21100",
            name: "Xã Bình Chương",
            level: "Xã"
          },
          {
            id: "21103",
            name: "Xã Bình Hiệp",
            level: "Xã"
          },
          {
            id: "21106",
            name: "Xã Bình Mỹ",
            level: "Xã"
          },
          {
            id: "21109",
            name: "Xã Bình Tân Phú",
            level: "Xã"
          },
          {
            id: "21112",
            name: "Xã Bình Châu",
            level: "Xã"
          }
        ]
      },
      {
        id: "525",
        name: "Huyện Trà Bồng",
        wards: [
          {
            id: "21115",
            name: "Thị trấn Trà Xuân",
            level: "Thị trấn"
          },
          {
            id: "21118",
            name: "Xã Trà Giang",
            level: "Xã"
          },
          {
            id: "21121",
            name: "Xã Trà Thủy",
            level: "Xã"
          },
          {
            id: "21124",
            name: "Xã Trà Hiệp",
            level: "Xã"
          },
          {
            id: "21127",
            name: "Xã Trà Bình",
            level: "Xã"
          },
          {
            id: "21130",
            name: "Xã Trà Phú",
            level: "Xã"
          },
          {
            id: "21133",
            name: "Xã Trà Lâm",
            level: "Xã"
          },
          {
            id: "21136",
            name: "Xã Trà Tân",
            level: "Xã"
          },
          {
            id: "21139",
            name: "Xã Trà Sơn",
            level: "Xã"
          },
          {
            id: "21142",
            name: "Xã Trà Bùi",
            level: "Xã"
          },
          {
            id: "21145",
            name: "Xã Trà Thanh",
            level: "Xã"
          },
          {
            id: "21148",
            name: "Xã Sơn Trà",
            level: "Xã"
          },
          {
            id: "21154",
            name: "Xã Trà Phong",
            level: "Xã"
          },
          {
            id: "21157",
            name: "Xã Hương Trà",
            level: "Xã"
          },
          {
            id: "21163",
            name: "Xã Trà Xinh",
            level: "Xã"
          },
          {
            id: "21166",
            name: "Xã Trà Tây",
            level: "Xã"
          }
        ]
      },
      {
        id: "527",
        name: "Huyện Sơn Tịnh",
        wards: [
          {
            id: "21175",
            name: "Xã Tịnh Thọ",
            level: "Xã"
          },
          {
            id: "21178",
            name: "Xã Tịnh Trà",
            level: "Xã"
          },
          {
            id: "21181",
            name: "Xã Tịnh Phong",
            level: "Xã"
          },
          {
            id: "21184",
            name: "Xã Tịnh Hiệp",
            level: "Xã"
          },
          {
            id: "21193",
            name: "Xã Tịnh Bình",
            level: "Xã"
          },
          {
            id: "21196",
            name: "Xã Tịnh Đông",
            level: "Xã"
          },
          {
            id: "21205",
            name: "Xã Tịnh Bắc",
            level: "Xã"
          },
          {
            id: "21217",
            name: "Xã Tịnh Sơn",
            level: "Xã"
          },
          {
            id: "21220",
            name: "Xã Tịnh Hà",
            level: "Xã"
          },
          {
            id: "21226",
            name: "Xã Tịnh Giang",
            level: "Xã"
          },
          {
            id: "21229",
            name: "Xã Tịnh Minh",
            level: "Xã"
          }
        ]
      },
      {
        id: "528",
        name: "Huyện Tư Nghĩa",
        wards: [
          {
            id: "21235",
            name: "Thị trấn La Hà",
            level: "Thị trấn"
          },
          {
            id: "21238",
            name: "Thị trấn Sông Vệ",
            level: "Thị trấn"
          },
          {
            id: "21241",
            name: "Xã Nghĩa Lâm",
            level: "Xã"
          },
          {
            id: "21244",
            name: "Xã Nghĩa Thắng",
            level: "Xã"
          },
          {
            id: "21247",
            name: "Xã Nghĩa Thuận",
            level: "Xã"
          },
          {
            id: "21250",
            name: "Xã Nghĩa Kỳ",
            level: "Xã"
          },
          {
            id: "21259",
            name: "Xã Nghĩa Sơn",
            level: "Xã"
          },
          {
            id: "21268",
            name: "Xã Nghĩa Hòa",
            level: "Xã"
          },
          {
            id: "21271",
            name: "Xã Nghĩa Điền",
            level: "Xã"
          },
          {
            id: "21274",
            name: "Xã Nghĩa Thương",
            level: "Xã"
          },
          {
            id: "21277",
            name: "Xã Nghĩa Trung",
            level: "Xã"
          },
          {
            id: "21280",
            name: "Xã Nghĩa Hiệp",
            level: "Xã"
          },
          {
            id: "21283",
            name: "Xã Nghĩa Phương",
            level: "Xã"
          },
          {
            id: "21286",
            name: "Xã Nghĩa Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "529",
        name: "Huyện Sơn Hà",
        wards: [
          {
            id: "21289",
            name: "Thị trấn Di Lăng",
            level: "Thị trấn"
          },
          {
            id: "21292",
            name: "Xã Sơn Hạ",
            level: "Xã"
          },
          {
            id: "21295",
            name: "Xã Sơn Thành",
            level: "Xã"
          },
          {
            id: "21298",
            name: "Xã Sơn Nham",
            level: "Xã"
          },
          {
            id: "21301",
            name: "Xã Sơn Bao",
            level: "Xã"
          },
          {
            id: "21304",
            name: "Xã Sơn Linh",
            level: "Xã"
          },
          {
            id: "21307",
            name: "Xã Sơn Giang",
            level: "Xã"
          },
          {
            id: "21310",
            name: "Xã Sơn Trung",
            level: "Xã"
          },
          {
            id: "21313",
            name: "Xã Sơn Thượng",
            level: "Xã"
          },
          {
            id: "21316",
            name: "Xã Sơn Cao",
            level: "Xã"
          },
          {
            id: "21319",
            name: "Xã Sơn Hải",
            level: "Xã"
          },
          {
            id: "21322",
            name: "Xã Sơn Thủy",
            level: "Xã"
          },
          {
            id: "21325",
            name: "Xã Sơn Kỳ",
            level: "Xã"
          },
          {
            id: "21328",
            name: "Xã Sơn Ba",
            level: "Xã"
          }
        ]
      },
      {
        id: "530",
        name: "Huyện Sơn Tây",
        wards: [
          {
            id: "21331",
            name: "Xã Sơn Bua",
            level: "Xã"
          },
          {
            id: "21334",
            name: "Xã Sơn Mùa",
            level: "Xã"
          },
          {
            id: "21335",
            name: "Xã Sơn Liên",
            level: "Xã"
          },
          {
            id: "21337",
            name: "Xã Sơn Tân",
            level: "Xã"
          },
          {
            id: "21338",
            name: "Xã Sơn Màu",
            level: "Xã"
          },
          {
            id: "21340",
            name: "Xã Sơn Dung",
            level: "Xã"
          },
          {
            id: "21341",
            name: "Xã Sơn Long",
            level: "Xã"
          },
          {
            id: "21343",
            name: "Xã Sơn Tinh",
            level: "Xã"
          },
          {
            id: "21346",
            name: "Xã Sơn Lập",
            level: "Xã"
          }
        ]
      },
      {
        id: "531",
        name: "Huyện Minh Long",
        wards: [
          {
            id: "21349",
            name: "Xã Long Sơn",
            level: "Xã"
          },
          {
            id: "21352",
            name: "Xã Long Mai",
            level: "Xã"
          },
          {
            id: "21355",
            name: "Xã Thanh An",
            level: "Xã"
          },
          {
            id: "21358",
            name: "Xã Long Môn",
            level: "Xã"
          },
          {
            id: "21361",
            name: "Xã Long Hiệp",
            level: "Xã"
          }
        ]
      },
      {
        id: "532",
        name: "Huyện Nghĩa Hành",
        wards: [
          {
            id: "21364",
            name: "Thị trấn Chợ Chùa",
            level: "Thị trấn"
          },
          {
            id: "21367",
            name: "Xã Hành Thuận",
            level: "Xã"
          },
          {
            id: "21370",
            name: "Xã Hành Dũng",
            level: "Xã"
          },
          {
            id: "21373",
            name: "Xã Hành Trung",
            level: "Xã"
          },
          {
            id: "21376",
            name: "Xã Hành Nhân",
            level: "Xã"
          },
          {
            id: "21379",
            name: "Xã Hành Đức",
            level: "Xã"
          },
          {
            id: "21382",
            name: "Xã Hành Minh",
            level: "Xã"
          },
          {
            id: "21385",
            name: "Xã Hành Phước",
            level: "Xã"
          },
          {
            id: "21388",
            name: "Xã Hành Thiện",
            level: "Xã"
          },
          {
            id: "21391",
            name: "Xã Hành Thịnh",
            level: "Xã"
          },
          {
            id: "21394",
            name: "Xã Hành Tín Tây",
            level: "Xã"
          },
          {
            id: "21397",
            name: "Xã Hành Tín  Đông",
            level: "Xã"
          }
        ]
      },
      {
        id: "533",
        name: "Huyện Mộ Đức",
        wards: [
          {
            id: "21400",
            name: "Thị trấn Mộ Đức",
            level: "Thị trấn"
          },
          {
            id: "21403",
            name: "Xã Đức Lợi",
            level: "Xã"
          },
          {
            id: "21406",
            name: "Xã Đức Thắng",
            level: "Xã"
          },
          {
            id: "21409",
            name: "Xã Đức Nhuận",
            level: "Xã"
          },
          {
            id: "21412",
            name: "Xã Đức Chánh",
            level: "Xã"
          },
          {
            id: "21415",
            name: "Xã Đức Hiệp",
            level: "Xã"
          },
          {
            id: "21418",
            name: "Xã Đức Minh",
            level: "Xã"
          },
          {
            id: "21421",
            name: "Xã Đức Thạnh",
            level: "Xã"
          },
          {
            id: "21424",
            name: "Xã Đức Hòa",
            level: "Xã"
          },
          {
            id: "21427",
            name: "Xã Đức Tân",
            level: "Xã"
          },
          {
            id: "21430",
            name: "Xã Đức Phú",
            level: "Xã"
          },
          {
            id: "21433",
            name: "Xã Đức Phong",
            level: "Xã"
          },
          {
            id: "21436",
            name: "Xã Đức Lân",
            level: "Xã"
          }
        ]
      },
      {
        id: "534",
        name: "Thị xã Đức Phổ",
        wards: [
          {
            id: "21439",
            name: "Phường Nguyễn Nghiêm",
            level: "Phường"
          },
          {
            id: "21442",
            name: "Xã Phổ An",
            level: "Xã"
          },
          {
            id: "21445",
            name: "Xã Phổ Phong",
            level: "Xã"
          },
          {
            id: "21448",
            name: "Xã Phổ Thuận",
            level: "Xã"
          },
          {
            id: "21451",
            name: "Phường Phổ Văn",
            level: "Phường"
          },
          {
            id: "21454",
            name: "Phường Phổ Quang",
            level: "Phường"
          },
          {
            id: "21457",
            name: "Xã Phổ Nhơn",
            level: "Xã"
          },
          {
            id: "21460",
            name: "Phường Phổ Ninh",
            level: "Phường"
          },
          {
            id: "21463",
            name: "Phường Phổ Minh",
            level: "Phường"
          },
          {
            id: "21466",
            name: "Phường Phổ Vinh",
            level: "Phường"
          },
          {
            id: "21469",
            name: "Phường Phổ Hòa",
            level: "Phường"
          },
          {
            id: "21472",
            name: "Xã Phổ Cường",
            level: "Xã"
          },
          {
            id: "21475",
            name: "Xã Phổ Khánh",
            level: "Xã"
          },
          {
            id: "21478",
            name: "Phường Phổ Thạnh",
            level: "Phường"
          },
          {
            id: "21481",
            name: "Xã Phổ Châu",
            level: "Xã"
          }
        ]
      },
      {
        id: "535",
        name: "Huyện Ba Tơ",
        wards: [
          {
            id: "21484",
            name: "Thị trấn Ba Tơ",
            level: "Thị trấn"
          },
          {
            id: "21487",
            name: "Xã Ba Điền",
            level: "Xã"
          },
          {
            id: "21490",
            name: "Xã Ba Vinh",
            level: "Xã"
          },
          {
            id: "21493",
            name: "Xã Ba Thành",
            level: "Xã"
          },
          {
            id: "21496",
            name: "Xã Ba Động",
            level: "Xã"
          },
          {
            id: "21499",
            name: "Xã Ba Dinh",
            level: "Xã"
          },
          {
            id: "21500",
            name: "Xã Ba Giang",
            level: "Xã"
          },
          {
            id: "21502",
            name: "Xã Ba Liên",
            level: "Xã"
          },
          {
            id: "21505",
            name: "Xã Ba Ngạc",
            level: "Xã"
          },
          {
            id: "21508",
            name: "Xã Ba Khâm",
            level: "Xã"
          },
          {
            id: "21511",
            name: "Xã Ba Cung",
            level: "Xã"
          },
          {
            id: "21517",
            name: "Xã Ba Tiêu",
            level: "Xã"
          },
          {
            id: "21520",
            name: "Xã Ba Trang",
            level: "Xã"
          },
          {
            id: "21523",
            name: "Xã Ba Tô",
            level: "Xã"
          },
          {
            id: "21526",
            name: "Xã Ba Bích",
            level: "Xã"
          },
          {
            id: "21529",
            name: "Xã Ba Vì",
            level: "Xã"
          },
          {
            id: "21532",
            name: "Xã Ba Lế",
            level: "Xã"
          },
          {
            id: "21535",
            name: "Xã Ba Nam",
            level: "Xã"
          },
          {
            id: "21538",
            name: "Xã Ba Xa",
            level: "Xã"
          }
        ]
      },
      {
        id: "536",
        name: "Huyện Lý Sơn",
        wards: [
          {
            level: "Huyện"
          }
        ]
      }
    ]
  },
  {
    id: "52",
    name: "Tỉnh Bình Định",
    districts: [
      {
        id: "540",
        name: "Thành phố Quy Nhơn",
        wards: [
          {
            id: "21550",
            name: "Phường Nhơn Bình",
            level: "Phường"
          },
          {
            id: "21553",
            name: "Phường Nhơn Phú",
            level: "Phường"
          },
          {
            id: "21556",
            name: "Phường Đống Đa",
            level: "Phường"
          },
          {
            id: "21559",
            name: "Phường Trần Quang Diệu",
            level: "Phường"
          },
          {
            id: "21562",
            name: "Phường Hải Cảng",
            level: "Phường"
          },
          {
            id: "21565",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "21568",
            name: "Phường Thị Nại",
            level: "Phường"
          },
          {
            id: "21571",
            name: "Phường Lê Hồng Phong",
            level: "Phường"
          },
          {
            id: "21574",
            name: "Phường Trần Hưng Đạo",
            level: "Phường"
          },
          {
            id: "21577",
            name: "Phường Ngô Mây",
            level: "Phường"
          },
          {
            id: "21580",
            name: "Phường Lý Thường Kiệt",
            level: "Phường"
          },
          {
            id: "21583",
            name: "Phường Lê Lợi",
            level: "Phường"
          },
          {
            id: "21586",
            name: "Phường Trần Phú",
            level: "Phường"
          },
          {
            id: "21589",
            name: "Phường Bùi Thị Xuân",
            level: "Phường"
          },
          {
            id: "21592",
            name: "Phường Nguyễn Văn Cừ",
            level: "Phường"
          },
          {
            id: "21595",
            name: "Phường Ghềnh Ráng",
            level: "Phường"
          },
          {
            id: "21598",
            name: "Xã Nhơn Lý",
            level: "Xã"
          },
          {
            id: "21601",
            name: "Xã Nhơn Hội",
            level: "Xã"
          },
          {
            id: "21604",
            name: "Xã Nhơn Hải",
            level: "Xã"
          },
          {
            id: "21607",
            name: "Xã Nhơn Châu",
            level: "Xã"
          },
          {
            id: "21991",
            name: "Xã Phước Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "542",
        name: "Huyện An Lão",
        wards: [
          {
            id: "21609",
            name: "Thị trấn An Lão",
            level: "Thị trấn"
          },
          {
            id: "21610",
            name: "Xã An Hưng",
            level: "Xã"
          },
          {
            id: "21613",
            name: "Xã An Trung",
            level: "Xã"
          },
          {
            id: "21616",
            name: "Xã An Dũng",
            level: "Xã"
          },
          {
            id: "21619",
            name: "Xã An Vinh",
            level: "Xã"
          },
          {
            id: "21622",
            name: "Xã An Toàn",
            level: "Xã"
          },
          {
            id: "21625",
            name: "Xã An Tân",
            level: "Xã"
          },
          {
            id: "21628",
            name: "Xã An Hòa",
            level: "Xã"
          },
          {
            id: "21631",
            name: "Xã An Quang",
            level: "Xã"
          },
          {
            id: "21634",
            name: "Xã An Nghĩa",
            level: "Xã"
          }
        ]
      },
      {
        id: "543",
        name: "Thị xã Hoài Nhơn",
        wards: [
          {
            id: "21637",
            name: "Phường Tam Quan",
            level: "Phường"
          },
          {
            id: "21640",
            name: "Phường Bồng Sơn",
            level: "Phường"
          },
          {
            id: "21643",
            name: "Xã Hoài Sơn",
            level: "Xã"
          },
          {
            id: "21646",
            name: "Xã Hoài Châu Bắc",
            level: "Xã"
          },
          {
            id: "21649",
            name: "Xã Hoài Châu",
            level: "Xã"
          },
          {
            id: "21652",
            name: "Xã Hoài Phú",
            level: "Xã"
          },
          {
            id: "21655",
            name: "Phường Tam Quan Bắc",
            level: "Phường"
          },
          {
            id: "21658",
            name: "Phường Tam Quan Nam",
            level: "Phường"
          },
          {
            id: "21661",
            name: "Phường Hoài Hảo",
            level: "Phường"
          },
          {
            id: "21664",
            name: "Phường Hoài Thanh Tây",
            level: "Phường"
          },
          {
            id: "21667",
            name: "Phường Hoài Thanh",
            level: "Phường"
          },
          {
            id: "21670",
            name: "Phường Hoài Hương",
            level: "Phường"
          },
          {
            id: "21673",
            name: "Phường Hoài Tân",
            level: "Phường"
          },
          {
            id: "21676",
            name: "Xã Hoài Hải",
            level: "Xã"
          },
          {
            id: "21679",
            name: "Phường Hoài Xuân",
            level: "Phường"
          },
          {
            id: "21682",
            name: "Xã Hoài Mỹ",
            level: "Xã"
          },
          {
            id: "21685",
            name: "Phường Hoài Đức",
            level: "Phường"
          }
        ]
      },
      {
        id: "544",
        name: "Huyện Hoài Ân",
        wards: [
          {
            id: "21688",
            name: "Thị trấn Tăng Bạt Hổ",
            level: "Thị trấn"
          },
          {
            id: "21690",
            name: "Xã Ân Hảo Tây",
            level: "Xã"
          },
          {
            id: "21691",
            name: "Xã Ân Hảo Đông",
            level: "Xã"
          },
          {
            id: "21694",
            name: "Xã Ân Sơn",
            level: "Xã"
          },
          {
            id: "21697",
            name: "Xã Ân Mỹ",
            level: "Xã"
          },
          {
            id: "21700",
            name: "Xã Đak Mang",
            level: "Xã"
          },
          {
            id: "21703",
            name: "Xã Ân Tín",
            level: "Xã"
          },
          {
            id: "21706",
            name: "Xã Ân Thạnh",
            level: "Xã"
          },
          {
            id: "21709",
            name: "Xã Ân Phong",
            level: "Xã"
          },
          {
            id: "21712",
            name: "Xã Ân Đức",
            level: "Xã"
          },
          {
            id: "21715",
            name: "Xã Ân Hữu",
            level: "Xã"
          },
          {
            id: "21718",
            name: "Xã Bok Tới",
            level: "Xã"
          },
          {
            id: "21721",
            name: "Xã Ân Tường Tây",
            level: "Xã"
          },
          {
            id: "21724",
            name: "Xã Ân Tường Đông",
            level: "Xã"
          },
          {
            id: "21727",
            name: "Xã Ân Nghĩa",
            level: "Xã"
          }
        ]
      },
      {
        id: "545",
        name: "Huyện Phù Mỹ",
        wards: [
          {
            id: "21730",
            name: "Thị trấn Phù Mỹ",
            level: "Thị trấn"
          },
          {
            id: "21733",
            name: "Thị trấn Bình Dương",
            level: "Thị trấn"
          },
          {
            id: "21736",
            name: "Xã Mỹ Đức",
            level: "Xã"
          },
          {
            id: "21739",
            name: "Xã Mỹ Châu",
            level: "Xã"
          },
          {
            id: "21742",
            name: "Xã Mỹ Thắng",
            level: "Xã"
          },
          {
            id: "21745",
            name: "Xã Mỹ Lộc",
            level: "Xã"
          },
          {
            id: "21748",
            name: "Xã Mỹ Lợi",
            level: "Xã"
          },
          {
            id: "21751",
            name: "Xã Mỹ An",
            level: "Xã"
          },
          {
            id: "21754",
            name: "Xã Mỹ Phong",
            level: "Xã"
          },
          {
            id: "21757",
            name: "Xã Mỹ Trinh",
            level: "Xã"
          },
          {
            id: "21760",
            name: "Xã Mỹ Thọ",
            level: "Xã"
          },
          {
            id: "21763",
            name: "Xã Mỹ Hòa",
            level: "Xã"
          },
          {
            id: "21766",
            name: "Xã Mỹ Thành",
            level: "Xã"
          },
          {
            id: "21769",
            name: "Xã Mỹ Chánh",
            level: "Xã"
          },
          {
            id: "21772",
            name: "Xã Mỹ Quang",
            level: "Xã"
          },
          {
            id: "21775",
            name: "Xã Mỹ Hiệp",
            level: "Xã"
          },
          {
            id: "21778",
            name: "Xã Mỹ Tài",
            level: "Xã"
          },
          {
            id: "21781",
            name: "Xã Mỹ Cát",
            level: "Xã"
          },
          {
            id: "21784",
            name: "Xã Mỹ Chánh Tây",
            level: "Xã"
          }
        ]
      },
      {
        id: "546",
        name: "Huyện Vĩnh Thạnh",
        wards: [
          {
            id: "21786",
            name: "Thị trấn Vĩnh Thạnh",
            level: "Thị trấn"
          },
          {
            id: "21787",
            name: "Xã Vĩnh Sơn",
            level: "Xã"
          },
          {
            id: "21790",
            name: "Xã Vĩnh Kim",
            level: "Xã"
          },
          {
            id: "21796",
            name: "Xã Vĩnh Hiệp",
            level: "Xã"
          },
          {
            id: "21799",
            name: "Xã Vĩnh Hảo",
            level: "Xã"
          },
          {
            id: "21801",
            name: "Xã Vĩnh Hòa",
            level: "Xã"
          },
          {
            id: "21802",
            name: "Xã Vĩnh Thịnh",
            level: "Xã"
          },
          {
            id: "21804",
            name: "Xã Vĩnh Thuận",
            level: "Xã"
          },
          {
            id: "21805",
            name: "Xã Vĩnh Quang",
            level: "Xã"
          }
        ]
      },
      {
        id: "547",
        name: "Huyện Tây Sơn",
        wards: [
          {
            id: "21808",
            name: "Thị trấn Phú Phong",
            level: "Thị trấn"
          },
          {
            id: "21811",
            name: "Xã Bình Tân",
            level: "Xã"
          },
          {
            id: "21814",
            name: "Xã Tây Thuận",
            level: "Xã"
          },
          {
            id: "21817",
            name: "Xã Bình Thuận",
            level: "Xã"
          },
          {
            id: "21820",
            name: "Xã Tây Giang",
            level: "Xã"
          },
          {
            id: "21823",
            name: "Xã Bình Thành",
            level: "Xã"
          },
          {
            id: "21826",
            name: "Xã Tây An",
            level: "Xã"
          },
          {
            id: "21829",
            name: "Xã Bình Hòa",
            level: "Xã"
          },
          {
            id: "21832",
            name: "Xã Tây Bình",
            level: "Xã"
          },
          {
            id: "21835",
            name: "Xã Bình Tường",
            level: "Xã"
          },
          {
            id: "21838",
            name: "Xã Tây Vinh",
            level: "Xã"
          },
          {
            id: "21841",
            name: "Xã Vĩnh An",
            level: "Xã"
          },
          {
            id: "21844",
            name: "Xã Tây Xuân",
            level: "Xã"
          },
          {
            id: "21847",
            name: "Xã Bình Nghi",
            level: "Xã"
          },
          {
            id: "21850",
            name: "Xã Tây Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "548",
        name: "Huyện Phù Cát",
        wards: [
          {
            id: "21853",
            name: "Thị trấn Ngô Mây",
            level: "Thị trấn"
          },
          {
            id: "21856",
            name: "Xã Cát Sơn",
            level: "Xã"
          },
          {
            id: "21859",
            name: "Xã Cát Minh",
            level: "Xã"
          },
          {
            id: "21862",
            name: "Xã Cát Khánh",
            level: "Xã"
          },
          {
            id: "21865",
            name: "Xã Cát Tài",
            level: "Xã"
          },
          {
            id: "21868",
            name: "Xã Cát Lâm",
            level: "Xã"
          },
          {
            id: "21871",
            name: "Xã Cát Hanh",
            level: "Xã"
          },
          {
            id: "21874",
            name: "Xã Cát Thành",
            level: "Xã"
          },
          {
            id: "21877",
            name: "Xã Cát Trinh",
            level: "Xã"
          },
          {
            id: "21880",
            name: "Xã Cát Hải",
            level: "Xã"
          },
          {
            id: "21883",
            name: "Xã Cát Hiệp",
            level: "Xã"
          },
          {
            id: "21886",
            name: "Xã Cát Nhơn",
            level: "Xã"
          },
          {
            id: "21889",
            name: "Xã Cát Hưng",
            level: "Xã"
          },
          {
            id: "21892",
            name: "Xã Cát Tường",
            level: "Xã"
          },
          {
            id: "21895",
            name: "Xã Cát Tân",
            level: "Xã"
          },
          {
            id: "21898",
            name: "Xã Cát Tiến",
            level: "Xã"
          },
          {
            id: "21901",
            name: "Xã Cát Thắng",
            level: "Xã"
          },
          {
            id: "21904",
            name: "Xã Cát Chánh",
            level: "Xã"
          }
        ]
      },
      {
        id: "549",
        name: "Thị xã An Nhơn",
        wards: [
          {
            id: "21907",
            name: "Phường Bình Định",
            level: "Phường"
          },
          {
            id: "21910",
            name: "Phường Đập Đá",
            level: "Phường"
          },
          {
            id: "21913",
            name: "Xã Nhơn Mỹ",
            level: "Xã"
          },
          {
            id: "21916",
            name: "Phường Nhơn Thành",
            level: "Phường"
          },
          {
            id: "21919",
            name: "Xã Nhơn Hạnh",
            level: "Xã"
          },
          {
            id: "21922",
            name: "Xã Nhơn Hậu",
            level: "Xã"
          },
          {
            id: "21925",
            name: "Xã Nhơn Phong",
            level: "Xã"
          },
          {
            id: "21928",
            name: "Xã Nhơn An",
            level: "Xã"
          },
          {
            id: "21931",
            name: "Xã Nhơn Phúc",
            level: "Xã"
          },
          {
            id: "21934",
            name: "Phường Nhơn Hưng",
            level: "Phường"
          },
          {
            id: "21937",
            name: "Xã Nhơn Khánh",
            level: "Xã"
          },
          {
            id: "21940",
            name: "Xã Nhơn Lộc",
            level: "Xã"
          },
          {
            id: "21943",
            name: "Phường Nhơn Hoà",
            level: "Phường"
          },
          {
            id: "21946",
            name: "Xã Nhơn Tân",
            level: "Xã"
          },
          {
            id: "21949",
            name: "Xã Nhơn Thọ",
            level: "Xã"
          }
        ]
      },
      {
        id: "550",
        name: "Huyện Tuy Phước",
        wards: [
          {
            id: "21952",
            name: "Thị trấn Tuy Phước",
            level: "Thị trấn"
          },
          {
            id: "21955",
            name: "Thị trấn Diêu Trì",
            level: "Thị trấn"
          },
          {
            id: "21958",
            name: "Xã Phước Thắng",
            level: "Xã"
          },
          {
            id: "21961",
            name: "Xã Phước Hưng",
            level: "Xã"
          },
          {
            id: "21964",
            name: "Xã Phước Quang",
            level: "Xã"
          },
          {
            id: "21967",
            name: "Xã Phước Hòa",
            level: "Xã"
          },
          {
            id: "21970",
            name: "Xã Phước Sơn",
            level: "Xã"
          },
          {
            id: "21973",
            name: "Xã Phước Hiệp",
            level: "Xã"
          },
          {
            id: "21976",
            name: "Xã Phước Lộc",
            level: "Xã"
          },
          {
            id: "21979",
            name: "Xã Phước Nghĩa",
            level: "Xã"
          },
          {
            id: "21982",
            name: "Xã Phước Thuận",
            level: "Xã"
          },
          {
            id: "21985",
            name: "Xã Phước An",
            level: "Xã"
          },
          {
            id: "21988",
            name: "Xã Phước Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "551",
        name: "Huyện Vân Canh",
        wards: [
          {
            id: "21994",
            name: "Thị trấn Vân Canh",
            level: "Thị trấn"
          },
          {
            id: "21997",
            name: "Xã Canh Liên",
            level: "Xã"
          },
          {
            id: "22000",
            name: "Xã Canh Hiệp",
            level: "Xã"
          },
          {
            id: "22003",
            name: "Xã Canh Vinh",
            level: "Xã"
          },
          {
            id: "22006",
            name: "Xã Canh Hiển",
            level: "Xã"
          },
          {
            id: "22009",
            name: "Xã Canh Thuận",
            level: "Xã"
          },
          {
            id: "22012",
            name: "Xã Canh Hòa",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "54",
    name: "Tỉnh Phú Yên",
    districts: [
      {
        id: "555",
        name: "Thành phố Tuy Hoà",
        wards: [
          {
            id: "22015",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "22018",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "22021",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "22024",
            name: "Phường 9",
            level: "Phường"
          },
          {
            id: "22027",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "22030",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "22033",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "22036",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "22039",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "22040",
            name: "Phường Phú Thạnh",
            level: "Phường"
          },
          {
            id: "22041",
            name: "Phường Phú Đông",
            level: "Phường"
          },
          {
            id: "22042",
            name: "Xã Hòa Kiến",
            level: "Xã"
          },
          {
            id: "22045",
            name: "Xã Bình Kiến",
            level: "Xã"
          },
          {
            id: "22048",
            name: "Xã Bình Ngọc",
            level: "Xã"
          },
          {
            id: "22162",
            name: "Xã An Phú",
            level: "Xã"
          },
          {
            id: "22240",
            name: "Phường Phú Lâm",
            level: "Phường"
          }
        ]
      },
      {
        id: "557",
        name: "Thị xã Sông Cầu",
        wards: [
          {
            id: "22051",
            name: "Phường Xuân Phú",
            level: "Phường"
          },
          {
            id: "22052",
            name: "Xã Xuân Lâm",
            level: "Xã"
          },
          {
            id: "22053",
            name: "Phường Xuân Thành",
            level: "Phường"
          },
          {
            id: "22054",
            name: "Xã Xuân Hải",
            level: "Xã"
          },
          {
            id: "22057",
            name: "Xã Xuân Lộc",
            level: "Xã"
          },
          {
            id: "22060",
            name: "Xã Xuân Bình",
            level: "Xã"
          },
          {
            id: "22066",
            name: "Xã Xuân Cảnh",
            level: "Xã"
          },
          {
            id: "22069",
            name: "Xã Xuân Thịnh",
            level: "Xã"
          },
          {
            id: "22072",
            name: "Xã Xuân Phương",
            level: "Xã"
          },
          {
            id: "22073",
            name: "Phường Xuân Yên",
            level: "Phường"
          },
          {
            id: "22075",
            name: "Xã Xuân Thọ 1",
            level: "Xã"
          },
          {
            id: "22076",
            name: "Phường Xuân Đài",
            level: "Phường"
          },
          {
            id: "22078",
            name: "Xã Xuân Thọ 2",
            level: "Xã"
          }
        ]
      },
      {
        id: "558",
        name: "Huyện Đồng Xuân",
        wards: [
          {
            id: "22081",
            name: "Thị trấn La Hai",
            level: "Thị trấn"
          },
          {
            id: "22084",
            name: "Xã Đa Lộc",
            level: "Xã"
          },
          {
            id: "22087",
            name: "Xã Phú Mỡ",
            level: "Xã"
          },
          {
            id: "22090",
            name: "Xã Xuân Lãnh",
            level: "Xã"
          },
          {
            id: "22093",
            name: "Xã Xuân Long",
            level: "Xã"
          },
          {
            id: "22096",
            name: "Xã Xuân Quang 1",
            level: "Xã"
          },
          {
            id: "22099",
            name: "Xã Xuân Sơn Bắc",
            level: "Xã"
          },
          {
            id: "22102",
            name: "Xã Xuân Quang 2",
            level: "Xã"
          },
          {
            id: "22105",
            name: "Xã Xuân Sơn Nam",
            level: "Xã"
          },
          {
            id: "22108",
            name: "Xã Xuân Quang 3",
            level: "Xã"
          },
          {
            id: "22111",
            name: "Xã Xuân Phước",
            level: "Xã"
          }
        ]
      },
      {
        id: "559",
        name: "Huyện Tuy An",
        wards: [
          {
            id: "22114",
            name: "Thị trấn Chí Thạnh",
            level: "Thị trấn"
          },
          {
            id: "22117",
            name: "Xã An Dân",
            level: "Xã"
          },
          {
            id: "22120",
            name: "Xã An Ninh Tây",
            level: "Xã"
          },
          {
            id: "22123",
            name: "Xã An Ninh Đông",
            level: "Xã"
          },
          {
            id: "22126",
            name: "Xã An Thạch",
            level: "Xã"
          },
          {
            id: "22129",
            name: "Xã An Định",
            level: "Xã"
          },
          {
            id: "22132",
            name: "Xã An Nghiệp",
            level: "Xã"
          },
          {
            id: "22138",
            name: "Xã An Cư",
            level: "Xã"
          },
          {
            id: "22141",
            name: "Xã An Xuân",
            level: "Xã"
          },
          {
            id: "22144",
            name: "Xã An Lĩnh",
            level: "Xã"
          },
          {
            id: "22147",
            name: "Xã An Hòa Hải",
            level: "Xã"
          },
          {
            id: "22150",
            name: "Xã An Hiệp",
            level: "Xã"
          },
          {
            id: "22153",
            name: "Xã An Mỹ",
            level: "Xã"
          },
          {
            id: "22156",
            name: "Xã An Chấn",
            level: "Xã"
          },
          {
            id: "22159",
            name: "Xã An Thọ",
            level: "Xã"
          }
        ]
      },
      {
        id: "560",
        name: "Huyện Sơn Hòa",
        wards: [
          {
            id: "22165",
            name: "Thị trấn Củng Sơn",
            level: "Thị trấn"
          },
          {
            id: "22168",
            name: "Xã Phước Tân",
            level: "Xã"
          },
          {
            id: "22171",
            name: "Xã Sơn Hội",
            level: "Xã"
          },
          {
            id: "22174",
            name: "Xã Sơn Định",
            level: "Xã"
          },
          {
            id: "22177",
            name: "Xã Sơn Long",
            level: "Xã"
          },
          {
            id: "22180",
            name: "Xã Cà Lúi",
            level: "Xã"
          },
          {
            id: "22183",
            name: "Xã Sơn Phước",
            level: "Xã"
          },
          {
            id: "22186",
            name: "Xã Sơn Xuân",
            level: "Xã"
          },
          {
            id: "22189",
            name: "Xã Sơn Nguyên",
            level: "Xã"
          },
          {
            id: "22192",
            name: "Xã Eachà Rang",
            level: "Xã"
          },
          {
            id: "22195",
            name: "Xã Krông Pa",
            level: "Xã"
          },
          {
            id: "22198",
            name: "Xã Suối Bạc",
            level: "Xã"
          },
          {
            id: "22201",
            name: "Xã Sơn Hà",
            level: "Xã"
          },
          {
            id: "22204",
            name: "Xã Suối Trai",
            level: "Xã"
          }
        ]
      },
      {
        id: "561",
        name: "Huyện Sông Hinh",
        wards: [
          {
            id: "22207",
            name: "Thị trấn Hai Riêng",
            level: "Thị trấn"
          },
          {
            id: "22210",
            name: "Xã Ea Lâm",
            level: "Xã"
          },
          {
            id: "22213",
            name: "Xã Đức Bình Tây",
            level: "Xã"
          },
          {
            id: "22216",
            name: "Xã Ea Bá",
            level: "Xã"
          },
          {
            id: "22219",
            name: "Xã Sơn Giang",
            level: "Xã"
          },
          {
            id: "22222",
            name: "Xã Đức Bình Đông",
            level: "Xã"
          },
          {
            id: "22225",
            name: "Xã EaBar",
            level: "Xã"
          },
          {
            id: "22228",
            name: "Xã EaBia",
            level: "Xã"
          },
          {
            id: "22231",
            name: "Xã EaTrol",
            level: "Xã"
          },
          {
            id: "22234",
            name: "Xã Sông Hinh",
            level: "Xã"
          },
          {
            id: "22237",
            name: "Xã Ealy",
            level: "Xã"
          }
        ]
      },
      {
        id: "562",
        name: "Huyện Tây Hoà",
        wards: [
          {
            id: "22249",
            name: "Xã Sơn Thành Tây",
            level: "Xã"
          },
          {
            id: "22250",
            name: "Xã Sơn Thành Đông",
            level: "Xã"
          },
          {
            id: "22252",
            name: "Xã Hòa Bình 1",
            level: "Xã"
          },
          {
            id: "22255",
            name: "Thị trấn Phú Thứ",
            level: "Thị trấn"
          },
          {
            id: "22264",
            name: "Xã Hòa Phong",
            level: "Xã"
          },
          {
            id: "22270",
            name: "Xã Hòa Phú",
            level: "Xã"
          },
          {
            id: "22273",
            name: "Xã Hòa Tân Tây",
            level: "Xã"
          },
          {
            id: "22276",
            name: "Xã Hòa Đồng",
            level: "Xã"
          },
          {
            id: "22285",
            name: "Xã Hòa Mỹ Đông",
            level: "Xã"
          },
          {
            id: "22288",
            name: "Xã Hòa Mỹ Tây",
            level: "Xã"
          },
          {
            id: "22294",
            name: "Xã Hòa Thịnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "563",
        name: "Huyện Phú Hoà",
        wards: [
          {
            id: "22303",
            name: "Xã Hòa Quang Bắc",
            level: "Xã"
          },
          {
            id: "22306",
            name: "Xã Hòa Quang Nam",
            level: "Xã"
          },
          {
            id: "22309",
            name: "Xã Hòa Hội",
            level: "Xã"
          },
          {
            id: "22312",
            name: "Xã Hòa Trị",
            level: "Xã"
          },
          {
            id: "22315",
            name: "Xã Hòa An",
            level: "Xã"
          },
          {
            id: "22318",
            name: "Xã Hòa Định Đông",
            level: "Xã"
          },
          {
            id: "22319",
            name: "Thị Trấn Phú Hoà",
            level: "Thị trấn"
          },
          {
            id: "22321",
            name: "Xã Hòa Định Tây",
            level: "Xã"
          },
          {
            id: "22324",
            name: "Xã Hòa Thắng",
            level: "Xã"
          }
        ]
      },
      {
        id: "564",
        name: "Thị xã Đông Hòa",
        wards: [
          {
            id: "22243",
            name: "Xã Hòa Thành",
            level: "Xã"
          },
          {
            id: "22246",
            name: "Phường Hòa Hiệp Bắc",
            level: "Phường"
          },
          {
            id: "22258",
            name: "Phường Hoà Vinh",
            level: "Phường"
          },
          {
            id: "22261",
            name: "Phường Hoà Hiệp Trung",
            level: "Phường"
          },
          {
            id: "22267",
            name: "Xã Hòa Tân Đông",
            level: "Xã"
          },
          {
            id: "22279",
            name: "Phường Hòa Xuân Tây",
            level: "Phường"
          },
          {
            id: "22282",
            name: "Phường Hòa Hiệp Nam",
            level: "Phường"
          },
          {
            id: "22291",
            name: "Xã Hòa Xuân Đông",
            level: "Xã"
          },
          {
            id: "22297",
            name: "Xã Hòa Tâm",
            level: "Xã"
          },
          {
            id: "22300",
            name: "Xã Hòa Xuân Nam",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "56",
    name: "Tỉnh Khánh Hòa",
    districts: [
      {
        id: "568",
        name: "Thành phố Nha Trang",
        wards: [
          {
            id: "22327",
            name: "Phường Vĩnh Hòa",
            level: "Phường"
          },
          {
            id: "22330",
            name: "Phường Vĩnh Hải",
            level: "Phường"
          },
          {
            id: "22333",
            name: "Phường Vĩnh Phước",
            level: "Phường"
          },
          {
            id: "22336",
            name: "Phường Ngọc Hiệp",
            level: "Phường"
          },
          {
            id: "22339",
            name: "Phường Vĩnh Thọ",
            level: "Phường"
          },
          {
            id: "22342",
            name: "Phường Xương Huân",
            level: "Phường"
          },
          {
            id: "22345",
            name: "Phường Vạn Thắng",
            level: "Phường"
          },
          {
            id: "22348",
            name: "Phường Vạn Thạnh",
            level: "Phường"
          },
          {
            id: "22351",
            name: "Phường Phương Sài",
            level: "Phường"
          },
          {
            id: "22354",
            name: "Phường Phương Sơn",
            level: "Phường"
          },
          {
            id: "22357",
            name: "Phường Phước Hải",
            level: "Phường"
          },
          {
            id: "22360",
            name: "Phường Phước Tân",
            level: "Phường"
          },
          {
            id: "22363",
            name: "Phường Lộc Thọ",
            level: "Phường"
          },
          {
            id: "22366",
            name: "Phường Phước Tiến",
            level: "Phường"
          },
          {
            id: "22369",
            name: "Phường Tân Lập",
            level: "Phường"
          },
          {
            id: "22372",
            name: "Phường Phước Hòa",
            level: "Phường"
          },
          {
            id: "22375",
            name: "Phường Vĩnh Nguyên",
            level: "Phường"
          },
          {
            id: "22378",
            name: "Phường Phước Long",
            level: "Phường"
          },
          {
            id: "22381",
            name: "Phường Vĩnh Trường",
            level: "Phường"
          },
          {
            id: "22384",
            name: "Xã Vĩnh Lương",
            level: "Xã"
          },
          {
            id: "22387",
            name: "Xã Vĩnh Phương",
            level: "Xã"
          },
          {
            id: "22390",
            name: "Xã Vĩnh Ngọc",
            level: "Xã"
          },
          {
            id: "22393",
            name: "Xã Vĩnh Thạnh",
            level: "Xã"
          },
          {
            id: "22396",
            name: "Xã Vĩnh Trung",
            level: "Xã"
          },
          {
            id: "22399",
            name: "Xã Vĩnh Hiệp",
            level: "Xã"
          },
          {
            id: "22402",
            name: "Xã Vĩnh Thái",
            level: "Xã"
          },
          {
            id: "22405",
            name: "Xã Phước Đồng",
            level: "Xã"
          }
        ]
      },
      {
        id: "569",
        name: "Thành phố Cam Ranh",
        wards: [
          {
            id: "22408",
            name: "Phường Cam Nghĩa",
            level: "Phường"
          },
          {
            id: "22411",
            name: "Phường Cam Phúc Bắc",
            level: "Phường"
          },
          {
            id: "22414",
            name: "Phường Cam Phúc Nam",
            level: "Phường"
          },
          {
            id: "22417",
            name: "Phường Cam Lộc",
            level: "Phường"
          },
          {
            id: "22420",
            name: "Phường Cam Phú",
            level: "Phường"
          },
          {
            id: "22423",
            name: "Phường Ba Ngòi",
            level: "Phường"
          },
          {
            id: "22426",
            name: "Phường Cam Thuận",
            level: "Phường"
          },
          {
            id: "22429",
            name: "Phường Cam Lợi",
            level: "Phường"
          },
          {
            id: "22432",
            name: "Phường Cam Linh",
            level: "Phường"
          },
          {
            id: "22468",
            name: "Xã Cam Thành Nam",
            level: "Xã"
          },
          {
            id: "22474",
            name: "Xã Cam Phước Đông",
            level: "Xã"
          },
          {
            id: "22477",
            name: "Xã Cam Thịnh Tây",
            level: "Xã"
          },
          {
            id: "22480",
            name: "Xã Cam Thịnh Đông",
            level: "Xã"
          },
          {
            id: "22483",
            name: "Xã Cam Lập",
            level: "Xã"
          },
          {
            id: "22486",
            name: "Xã Cam Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "570",
        name: "Huyện Cam Lâm",
        wards: [
          {
            id: "22435",
            name: "Xã Cam Tân",
            level: "Xã"
          },
          {
            id: "22438",
            name: "Xã Cam Hòa",
            level: "Xã"
          },
          {
            id: "22441",
            name: "Xã Cam Hải Đông",
            level: "Xã"
          },
          {
            id: "22444",
            name: "Xã Cam Hải Tây",
            level: "Xã"
          },
          {
            id: "22447",
            name: "Xã Sơn Tân",
            level: "Xã"
          },
          {
            id: "22450",
            name: "Xã Cam Hiệp Bắc",
            level: "Xã"
          },
          {
            id: "22453",
            name: "Thị trấn Cam Đức",
            level: "Thị trấn"
          },
          {
            id: "22456",
            name: "Xã Cam Hiệp Nam",
            level: "Xã"
          },
          {
            id: "22459",
            name: "Xã Cam Phước Tây",
            level: "Xã"
          },
          {
            id: "22462",
            name: "Xã Cam Thành Bắc",
            level: "Xã"
          },
          {
            id: "22465",
            name: "Xã Cam An Bắc",
            level: "Xã"
          },
          {
            id: "22471",
            name: "Xã Cam An Nam",
            level: "Xã"
          },
          {
            id: "22708",
            name: "Xã Suối Cát",
            level: "Xã"
          },
          {
            id: "22711",
            name: "Xã Suối Tân",
            level: "Xã"
          }
        ]
      },
      {
        id: "571",
        name: "Huyện Vạn Ninh",
        wards: [
          {
            id: "22489",
            name: "Thị trấn Vạn Giã",
            level: "Thị trấn"
          },
          {
            id: "22492",
            name: "Xã Đại Lãnh",
            level: "Xã"
          },
          {
            id: "22495",
            name: "Xã Vạn Phước",
            level: "Xã"
          },
          {
            id: "22498",
            name: "Xã Vạn Long",
            level: "Xã"
          },
          {
            id: "22501",
            name: "Xã Vạn Bình",
            level: "Xã"
          },
          {
            id: "22504",
            name: "Xã Vạn Thọ",
            level: "Xã"
          },
          {
            id: "22507",
            name: "Xã Vạn Khánh",
            level: "Xã"
          },
          {
            id: "22510",
            name: "Xã Vạn Phú",
            level: "Xã"
          },
          {
            id: "22513",
            name: "Xã Vạn Lương",
            level: "Xã"
          },
          {
            id: "22516",
            name: "Xã Vạn Thắng",
            level: "Xã"
          },
          {
            id: "22519",
            name: "Xã Vạn Thạnh",
            level: "Xã"
          },
          {
            id: "22522",
            name: "Xã Xuân Sơn",
            level: "Xã"
          },
          {
            id: "22525",
            name: "Xã Vạn Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "572",
        name: "Thị xã Ninh Hòa",
        wards: [
          {
            id: "22528",
            name: "Phường Ninh Hiệp",
            level: "Phường"
          },
          {
            id: "22531",
            name: "Xã Ninh Sơn",
            level: "Xã"
          },
          {
            id: "22534",
            name: "Xã Ninh Tây",
            level: "Xã"
          },
          {
            id: "22537",
            name: "Xã Ninh Thượng",
            level: "Xã"
          },
          {
            id: "22540",
            name: "Xã Ninh An",
            level: "Xã"
          },
          {
            id: "22543",
            name: "Phường Ninh Hải",
            level: "Phường"
          },
          {
            id: "22546",
            name: "Xã Ninh Thọ",
            level: "Xã"
          },
          {
            id: "22549",
            name: "Xã Ninh Trung",
            level: "Xã"
          },
          {
            id: "22552",
            name: "Xã Ninh Sim",
            level: "Xã"
          },
          {
            id: "22555",
            name: "Xã Ninh Xuân",
            level: "Xã"
          },
          {
            id: "22558",
            name: "Xã Ninh Thân",
            level: "Xã"
          },
          {
            id: "22561",
            name: "Phường Ninh Diêm",
            level: "Phường"
          },
          {
            id: "22564",
            name: "Xã Ninh Đông",
            level: "Xã"
          },
          {
            id: "22567",
            name: "Phường Ninh Thủy",
            level: "Phường"
          },
          {
            id: "22570",
            name: "Phường Ninh Đa",
            level: "Phường"
          },
          {
            id: "22573",
            name: "Xã Ninh Phụng",
            level: "Xã"
          },
          {
            id: "22576",
            name: "Xã Ninh Bình",
            level: "Xã"
          },
          {
            id: "22579",
            name: "Xã Ninh Phước",
            level: "Xã"
          },
          {
            id: "22582",
            name: "Xã Ninh Phú",
            level: "Xã"
          },
          {
            id: "22585",
            name: "Xã Ninh Tân",
            level: "Xã"
          },
          {
            id: "22588",
            name: "Xã Ninh Quang",
            level: "Xã"
          },
          {
            id: "22591",
            name: "Phường Ninh Giang",
            level: "Phường"
          },
          {
            id: "22594",
            name: "Phường Ninh Hà",
            level: "Phường"
          },
          {
            id: "22597",
            name: "Xã Ninh Hưng",
            level: "Xã"
          },
          {
            id: "22600",
            name: "Xã Ninh Lộc",
            level: "Xã"
          },
          {
            id: "22603",
            name: "Xã Ninh Ích",
            level: "Xã"
          },
          {
            id: "22606",
            name: "Xã Ninh Vân",
            level: "Xã"
          }
        ]
      },
      {
        id: "573",
        name: "Huyện Khánh Vĩnh",
        wards: [
          {
            id: "22609",
            name: "Thị trấn Khánh Vĩnh",
            level: "Thị trấn"
          },
          {
            id: "22612",
            name: "Xã Khánh Hiệp",
            level: "Xã"
          },
          {
            id: "22615",
            name: "Xã Khánh Bình",
            level: "Xã"
          },
          {
            id: "22618",
            name: "Xã Khánh Trung",
            level: "Xã"
          },
          {
            id: "22621",
            name: "Xã Khánh Đông",
            level: "Xã"
          },
          {
            id: "22624",
            name: "Xã Khánh Thượng",
            level: "Xã"
          },
          {
            id: "22627",
            name: "Xã Khánh Nam",
            level: "Xã"
          },
          {
            id: "22630",
            name: "Xã Sông Cầu",
            level: "Xã"
          },
          {
            id: "22633",
            name: "Xã Giang Ly",
            level: "Xã"
          },
          {
            id: "22636",
            name: "Xã Cầu Bà",
            level: "Xã"
          },
          {
            id: "22639",
            name: "Xã Liên Sang",
            level: "Xã"
          },
          {
            id: "22642",
            name: "Xã Khánh Thành",
            level: "Xã"
          },
          {
            id: "22645",
            name: "Xã Khánh Phú",
            level: "Xã"
          },
          {
            id: "22648",
            name: "Xã Sơn Thái",
            level: "Xã"
          }
        ]
      },
      {
        id: "574",
        name: "Huyện Diên Khánh",
        wards: [
          {
            id: "22651",
            name: "Thị trấn Diên Khánh",
            level: "Thị trấn"
          },
          {
            id: "22654",
            name: "Xã Diên Lâm",
            level: "Xã"
          },
          {
            id: "22657",
            name: "Xã Diên Điền",
            level: "Xã"
          },
          {
            id: "22660",
            name: "Xã Diên Xuân",
            level: "Xã"
          },
          {
            id: "22663",
            name: "Xã Diên Sơn",
            level: "Xã"
          },
          {
            id: "22666",
            name: "Xã Diên Đồng",
            level: "Xã"
          },
          {
            id: "22669",
            name: "Xã Diên Phú",
            level: "Xã"
          },
          {
            id: "22672",
            name: "Xã Diên Thọ",
            level: "Xã"
          },
          {
            id: "22675",
            name: "Xã Diên Phước",
            level: "Xã"
          },
          {
            id: "22678",
            name: "Xã Diên Lạc",
            level: "Xã"
          },
          {
            id: "22681",
            name: "Xã Diên Tân",
            level: "Xã"
          },
          {
            id: "22684",
            name: "Xã Diên Hòa",
            level: "Xã"
          },
          {
            id: "22687",
            name: "Xã Diên Thạnh",
            level: "Xã"
          },
          {
            id: "22690",
            name: "Xã Diên Toàn",
            level: "Xã"
          },
          {
            id: "22693",
            name: "Xã Diên An",
            level: "Xã"
          },
          {
            id: "22696",
            name: "Xã Bình Lộc",
            level: "Xã"
          },
          {
            id: "22702",
            name: "Xã Suối Hiệp",
            level: "Xã"
          },
          {
            id: "22705",
            name: "Xã Suối Tiên",
            level: "Xã"
          }
        ]
      },
      {
        id: "575",
        name: "Huyện Khánh Sơn",
        wards: [
          {
            id: "22714",
            name: "Thị trấn Tô Hạp",
            level: "Thị trấn"
          },
          {
            id: "22717",
            name: "Xã Thành Sơn",
            level: "Xã"
          },
          {
            id: "22720",
            name: "Xã Sơn Lâm",
            level: "Xã"
          },
          {
            id: "22723",
            name: "Xã Sơn Hiệp",
            level: "Xã"
          },
          {
            id: "22726",
            name: "Xã Sơn Bình",
            level: "Xã"
          },
          {
            id: "22729",
            name: "Xã Sơn Trung",
            level: "Xã"
          },
          {
            id: "22732",
            name: "Xã Ba Cụm Bắc",
            level: "Xã"
          },
          {
            id: "22735",
            name: "Xã Ba Cụm Nam",
            level: "Xã"
          }
        ]
      },
      {
        id: "576",
        name: "Huyện Trường Sa",
        wards: [
          {
            id: "22736",
            name: "Thị trấn Trường Sa",
            level: "Thị trấn"
          },
          {
            id: "22737",
            name: "Xã Song Tử Tây",
            level: "Xã"
          },
          {
            id: "22739",
            name: "Xã Sinh Tồn",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "58",
    name: "Tỉnh Ninh Thuận",
    districts: [
      {
        id: "582",
        name: "Thành phố Phan Rang-Tháp Chàm",
        wards: [
          {
            id: "22738",
            name: "Phường Đô Vinh",
            level: "Phường"
          },
          {
            id: "22741",
            name: "Phường Phước Mỹ",
            level: "Phường"
          },
          {
            id: "22744",
            name: "Phường Bảo An",
            level: "Phường"
          },
          {
            id: "22747",
            name: "Phường Phủ Hà",
            level: "Phường"
          },
          {
            id: "22750",
            name: "Phường Thanh Sơn",
            level: "Phường"
          },
          {
            id: "22753",
            name: "Phường Mỹ Hương",
            level: "Phường"
          },
          {
            id: "22756",
            name: "Phường Tấn Tài",
            level: "Phường"
          },
          {
            id: "22759",
            name: "Phường Kinh Dinh",
            level: "Phường"
          },
          {
            id: "22762",
            name: "Phường Đạo Long",
            level: "Phường"
          },
          {
            id: "22765",
            name: "Phường Đài Sơn",
            level: "Phường"
          },
          {
            id: "22768",
            name: "Phường Đông Hải",
            level: "Phường"
          },
          {
            id: "22771",
            name: "Phường Mỹ Đông",
            level: "Phường"
          },
          {
            id: "22774",
            name: "Xã Thành Hải",
            level: "Xã"
          },
          {
            id: "22777",
            name: "Phường Văn Hải",
            level: "Phường"
          },
          {
            id: "22779",
            name: "Phường Mỹ Bình",
            level: "Phường"
          },
          {
            id: "22780",
            name: "Phường Mỹ Hải",
            level: "Phường"
          }
        ]
      },
      {
        id: "584",
        name: "Huyện Bác Ái",
        wards: [
          {
            id: "22783",
            name: "Xã Phước Bình",
            level: "Xã"
          },
          {
            id: "22786",
            name: "Xã Phước Hòa",
            level: "Xã"
          },
          {
            id: "22789",
            name: "Xã Phước Tân",
            level: "Xã"
          },
          {
            id: "22792",
            name: "Xã Phước Tiến",
            level: "Xã"
          },
          {
            id: "22795",
            name: "Xã Phước Thắng",
            level: "Xã"
          },
          {
            id: "22798",
            name: "Xã Phước Thành",
            level: "Xã"
          },
          {
            id: "22801",
            name: "Xã Phước Đại",
            level: "Xã"
          },
          {
            id: "22804",
            name: "Xã Phước Chính",
            level: "Xã"
          },
          {
            id: "22807",
            name: "Xã Phước Trung",
            level: "Xã"
          }
        ]
      },
      {
        id: "585",
        name: "Huyện Ninh Sơn",
        wards: [
          {
            id: "22810",
            name: "Thị trấn Tân Sơn",
            level: "Thị trấn"
          },
          {
            id: "22813",
            name: "Xã Lâm Sơn",
            level: "Xã"
          },
          {
            id: "22816",
            name: "Xã Lương Sơn",
            level: "Xã"
          },
          {
            id: "22819",
            name: "Xã Quảng Sơn",
            level: "Xã"
          },
          {
            id: "22822",
            name: "Xã Mỹ Sơn",
            level: "Xã"
          },
          {
            id: "22825",
            name: "Xã Hòa Sơn",
            level: "Xã"
          },
          {
            id: "22828",
            name: "Xã Ma Nới",
            level: "Xã"
          },
          {
            id: "22831",
            name: "Xã Nhơn Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "586",
        name: "Huyện Ninh Hải",
        wards: [
          {
            id: "22834",
            name: "Thị trấn Khánh Hải",
            level: "Thị trấn"
          },
          {
            id: "22846",
            name: "Xã Vĩnh Hải",
            level: "Xã"
          },
          {
            id: "22852",
            name: "Xã Phương Hải",
            level: "Xã"
          },
          {
            id: "22855",
            name: "Xã Tân Hải",
            level: "Xã"
          },
          {
            id: "22858",
            name: "Xã Xuân Hải",
            level: "Xã"
          },
          {
            id: "22861",
            name: "Xã Hộ Hải",
            level: "Xã"
          },
          {
            id: "22864",
            name: "Xã Tri Hải",
            level: "Xã"
          },
          {
            id: "22867",
            name: "Xã Nhơn Hải",
            level: "Xã"
          },
          {
            id: "22868",
            name: "Xã Thanh Hải",
            level: "Xã"
          }
        ]
      },
      {
        id: "587",
        name: "Huyện Ninh Phước",
        wards: [
          {
            id: "22870",
            name: "Thị trấn Phước Dân",
            level: "Thị trấn"
          },
          {
            id: "22873",
            name: "Xã Phước Sơn",
            level: "Xã"
          },
          {
            id: "22876",
            name: "Xã Phước Thái",
            level: "Xã"
          },
          {
            id: "22879",
            name: "Xã Phước Hậu",
            level: "Xã"
          },
          {
            id: "22882",
            name: "Xã Phước Thuận",
            level: "Xã"
          },
          {
            id: "22888",
            name: "Xã An Hải",
            level: "Xã"
          },
          {
            id: "22891",
            name: "Xã Phước Hữu",
            level: "Xã"
          },
          {
            id: "22894",
            name: "Xã Phước Hải",
            level: "Xã"
          },
          {
            id: "22912",
            name: "Xã Phước Vinh",
            level: "Xã"
          }
        ]
      },
      {
        id: "588",
        name: "Huyện Thuận Bắc",
        wards: [
          {
            id: "22837",
            name: "Xã Phước Chiến",
            level: "Xã"
          },
          {
            id: "22840",
            name: "Xã Công Hải",
            level: "Xã"
          },
          {
            id: "22843",
            name: "Xã Phước Kháng",
            level: "Xã"
          },
          {
            id: "22849",
            name: "Xã Lợi Hải",
            level: "Xã"
          },
          {
            id: "22853",
            name: "Xã Bắc Sơn",
            level: "Xã"
          },
          {
            id: "22856",
            name: "Xã Bắc Phong",
            level: "Xã"
          }
        ]
      },
      {
        id: "589",
        name: "Huyện Thuận Nam",
        wards: [
          {
            id: "22885",
            name: "Xã Phước Hà",
            level: "Xã"
          },
          {
            id: "22897",
            name: "Xã Phước Nam",
            level: "Xã"
          },
          {
            id: "22898",
            name: "Xã Phước Ninh",
            level: "Xã"
          },
          {
            id: "22900",
            name: "Xã Nhị Hà",
            level: "Xã"
          },
          {
            id: "22903",
            name: "Xã Phước Dinh",
            level: "Xã"
          },
          {
            id: "22906",
            name: "Xã Phước Minh",
            level: "Xã"
          },
          {
            id: "22909",
            name: "Xã Phước Diêm",
            level: "Xã"
          },
          {
            id: "22910",
            name: "Xã Cà Ná",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "60",
    name: "Tỉnh Bình Thuận",
    districts: [
      {
        id: "593",
        name: "Thành phố Phan Thiết",
        wards: [
          {
            id: "22915",
            name: "Phường Mũi Né",
            level: "Phường"
          },
          {
            id: "22918",
            name: "Phường Hàm Tiến",
            level: "Phường"
          },
          {
            id: "22921",
            name: "Phường Phú Hài",
            level: "Phường"
          },
          {
            id: "22924",
            name: "Phường Phú Thủy",
            level: "Phường"
          },
          {
            id: "22927",
            name: "Phường Phú Tài",
            level: "Phường"
          },
          {
            id: "22930",
            name: "Phường Phú Trinh",
            level: "Phường"
          },
          {
            id: "22933",
            name: "Phường Xuân An",
            level: "Phường"
          },
          {
            id: "22936",
            name: "Phường Thanh Hải",
            level: "Phường"
          },
          {
            id: "22939",
            name: "Phường Bình Hưng",
            level: "Phường"
          },
          {
            id: "22942",
            name: "Phường Đức Nghĩa",
            level: "Phường"
          },
          {
            id: "22945",
            name: "Phường Lạc Đạo",
            level: "Phường"
          },
          {
            id: "22948",
            name: "Phường Đức Thắng",
            level: "Phường"
          },
          {
            id: "22951",
            name: "Phường Hưng Long",
            level: "Phường"
          },
          {
            id: "22954",
            name: "Phường Đức Long",
            level: "Phường"
          },
          {
            id: "22957",
            name: "Xã Thiện Nghiệp",
            level: "Xã"
          },
          {
            id: "22960",
            name: "Xã Phong Nẫm",
            level: "Xã"
          },
          {
            id: "22963",
            name: "Xã Tiến Lợi",
            level: "Xã"
          },
          {
            id: "22966",
            name: "Xã Tiến Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "594",
        name: "Thị xã La Gi",
        wards: [
          {
            id: "23231",
            name: "Phường Phước Hội",
            level: "Phường"
          },
          {
            id: "23232",
            name: "Phường Phước Lộc",
            level: "Phường"
          },
          {
            id: "23234",
            name: "Phường Tân Thiện",
            level: "Phường"
          },
          {
            id: "23235",
            name: "Phường Tân An",
            level: "Phường"
          },
          {
            id: "23237",
            name: "Phường Bình Tân",
            level: "Phường"
          },
          {
            id: "23245",
            name: "Xã Tân Hải",
            level: "Xã"
          },
          {
            id: "23246",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "23248",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "23268",
            name: "Xã Tân Phước",
            level: "Xã"
          }
        ]
      },
      {
        id: "595",
        name: "Huyện Tuy Phong",
        wards: [
          {
            id: "22969",
            name: "Thị trấn Liên Hương",
            level: "Thị trấn"
          },
          {
            id: "22972",
            name: "Thị trấn Phan Rí Cửa",
            level: "Thị trấn"
          },
          {
            id: "22975",
            name: "Xã Phan Dũng",
            level: "Xã"
          },
          {
            id: "22978",
            name: "Xã Phong Phú",
            level: "Xã"
          },
          {
            id: "22981",
            name: "Xã Vĩnh Hảo",
            level: "Xã"
          },
          {
            id: "22984",
            name: "Xã Vĩnh Tân",
            level: "Xã"
          },
          {
            id: "22987",
            name: "Xã Phú Lạc",
            level: "Xã"
          },
          {
            id: "22990",
            name: "Xã Phước Thể",
            level: "Xã"
          },
          {
            id: "22993",
            name: "Xã Hòa Minh",
            level: "Xã"
          },
          {
            id: "22996",
            name: "Xã Chí Công",
            level: "Xã"
          },
          {
            id: "22999",
            name: "Xã Bình Thạnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "596",
        name: "Huyện Bắc Bình",
        wards: [
          {
            id: "23005",
            name: "Thị trấn Chợ Lầu",
            level: "Thị trấn"
          },
          {
            id: "23008",
            name: "Xã Phan Sơn",
            level: "Xã"
          },
          {
            id: "23011",
            name: "Xã Phan Lâm",
            level: "Xã"
          },
          {
            id: "23014",
            name: "Xã Bình An",
            level: "Xã"
          },
          {
            id: "23017",
            name: "Xã Phan Điền",
            level: "Xã"
          },
          {
            id: "23020",
            name: "Xã Hải Ninh",
            level: "Xã"
          },
          {
            id: "23023",
            name: "Xã Sông Lũy",
            level: "Xã"
          },
          {
            id: "23026",
            name: "Xã Phan Tiến",
            level: "Xã"
          },
          {
            id: "23029",
            name: "Xã Sông Bình",
            level: "Xã"
          },
          {
            id: "23032",
            name: "Thị trấn Lương Sơn",
            level: "Thị trấn"
          },
          {
            id: "23035",
            name: "Xã Phan Hòa",
            level: "Xã"
          },
          {
            id: "23038",
            name: "Xã Phan Thanh",
            level: "Xã"
          },
          {
            id: "23041",
            name: "Xã Hồng Thái",
            level: "Xã"
          },
          {
            id: "23044",
            name: "Xã Phan Hiệp",
            level: "Xã"
          },
          {
            id: "23047",
            name: "Xã Bình Tân",
            level: "Xã"
          },
          {
            id: "23050",
            name: "Xã Phan Rí Thành",
            level: "Xã"
          },
          {
            id: "23053",
            name: "Xã Hòa Thắng",
            level: "Xã"
          },
          {
            id: "23056",
            name: "Xã Hồng Phong",
            level: "Xã"
          }
        ]
      },
      {
        id: "597",
        name: "Huyện Hàm Thuận Bắc",
        wards: [
          {
            id: "23059",
            name: "Thị trấn Ma Lâm",
            level: "Thị trấn"
          },
          {
            id: "23062",
            name: "Thị trấn Phú Long",
            level: "Thị trấn"
          },
          {
            id: "23065",
            name: "Xã La Dạ",
            level: "Xã"
          },
          {
            id: "23068",
            name: "Xã Đông Tiến",
            level: "Xã"
          },
          {
            id: "23071",
            name: "Xã Thuận Hòa",
            level: "Xã"
          },
          {
            id: "23074",
            name: "Xã Đông Giang",
            level: "Xã"
          },
          {
            id: "23077",
            name: "Xã Hàm Phú",
            level: "Xã"
          },
          {
            id: "23080",
            name: "Xã Hồng Liêm",
            level: "Xã"
          },
          {
            id: "23083",
            name: "Xã Thuận Minh",
            level: "Xã"
          },
          {
            id: "23086",
            name: "Xã Hồng Sơn",
            level: "Xã"
          },
          {
            id: "23089",
            name: "Xã Hàm Trí",
            level: "Xã"
          },
          {
            id: "23092",
            name: "Xã Hàm Đức",
            level: "Xã"
          },
          {
            id: "23095",
            name: "Xã Hàm Liêm",
            level: "Xã"
          },
          {
            id: "23098",
            name: "Xã Hàm Chính",
            level: "Xã"
          },
          {
            id: "23101",
            name: "Xã Hàm Hiệp",
            level: "Xã"
          },
          {
            id: "23104",
            name: "Xã Hàm Thắng",
            level: "Xã"
          },
          {
            id: "23107",
            name: "Xã Đa Mi",
            level: "Xã"
          }
        ]
      },
      {
        id: "598",
        name: "Huyện Hàm Thuận Nam",
        wards: [
          {
            id: "23110",
            name: "Thị trấn Thuận Nam",
            level: "Thị trấn"
          },
          {
            id: "23113",
            name: "Xã Mỹ Thạnh",
            level: "Xã"
          },
          {
            id: "23116",
            name: "Xã Hàm Cần",
            level: "Xã"
          },
          {
            id: "23119",
            name: "Xã Mương Mán",
            level: "Xã"
          },
          {
            id: "23122",
            name: "Xã Hàm Thạnh",
            level: "Xã"
          },
          {
            id: "23125",
            name: "Xã Hàm Kiệm",
            level: "Xã"
          },
          {
            id: "23128",
            name: "Xã Hàm Cường",
            level: "Xã"
          },
          {
            id: "23131",
            name: "Xã Hàm Mỹ",
            level: "Xã"
          },
          {
            id: "23134",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "23137",
            name: "Xã Hàm Minh",
            level: "Xã"
          },
          {
            id: "23140",
            name: "Xã Thuận Quí",
            level: "Xã"
          },
          {
            id: "23143",
            name: "Xã Tân Thuận",
            level: "Xã"
          },
          {
            id: "23146",
            name: "Xã Tân Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "599",
        name: "Huyện Tánh Linh",
        wards: [
          {
            id: "23149",
            name: "Thị trấn Lạc Tánh",
            level: "Thị trấn"
          },
          {
            id: "23152",
            name: "Xã Bắc Ruộng",
            level: "Xã"
          },
          {
            id: "23158",
            name: "Xã Nghị Đức",
            level: "Xã"
          },
          {
            id: "23161",
            name: "Xã La Ngâu",
            level: "Xã"
          },
          {
            id: "23164",
            name: "Xã Huy Khiêm",
            level: "Xã"
          },
          {
            id: "23167",
            name: "Xã Măng Tố",
            level: "Xã"
          },
          {
            id: "23170",
            name: "Xã Đức Phú",
            level: "Xã"
          },
          {
            id: "23173",
            name: "Xã Đồng Kho",
            level: "Xã"
          },
          {
            id: "23176",
            name: "Xã Gia An",
            level: "Xã"
          },
          {
            id: "23179",
            name: "Xã Đức Bình",
            level: "Xã"
          },
          {
            id: "23182",
            name: "Xã Gia Huynh",
            level: "Xã"
          },
          {
            id: "23185",
            name: "Xã Đức Thuận",
            level: "Xã"
          },
          {
            id: "23188",
            name: "Xã Suối Kiết",
            level: "Xã"
          }
        ]
      },
      {
        id: "600",
        name: "Huyện Đức Linh",
        wards: [
          {
            id: "23191",
            name: "Thị trấn Võ Xu",
            level: "Thị trấn"
          },
          {
            id: "23194",
            name: "Thị trấn Đức Tài",
            level: "Thị trấn"
          },
          {
            id: "23197",
            name: "Xã Đa Kai",
            level: "Xã"
          },
          {
            id: "23200",
            name: "Xã Sùng Nhơn",
            level: "Xã"
          },
          {
            id: "23203",
            name: "Xã Mê Pu",
            level: "Xã"
          },
          {
            id: "23206",
            name: "Xã Nam Chính",
            level: "Xã"
          },
          {
            id: "23212",
            name: "Xã Đức Hạnh",
            level: "Xã"
          },
          {
            id: "23215",
            name: "Xã Đức Tín",
            level: "Xã"
          },
          {
            id: "23218",
            name: "Xã Vũ Hoà",
            level: "Xã"
          },
          {
            id: "23221",
            name: "Xã Tân Hà",
            level: "Xã"
          },
          {
            id: "23224",
            name: "Xã Đông Hà",
            level: "Xã"
          },
          {
            id: "23227",
            name: "Xã Trà Tân",
            level: "Xã"
          }
        ]
      },
      {
        id: "601",
        name: "Huyện Hàm Tân",
        wards: [
          {
            id: "23230",
            name: "Thị trấn Tân Minh",
            level: "Thị trấn"
          },
          {
            id: "23236",
            name: "Thị trấn Tân Nghĩa",
            level: "Thị trấn"
          },
          {
            id: "23239",
            name: "Xã Sông Phan",
            level: "Xã"
          },
          {
            id: "23242",
            name: "Xã Tân Phúc",
            level: "Xã"
          },
          {
            id: "23251",
            name: "Xã Tân Đức",
            level: "Xã"
          },
          {
            id: "23254",
            name: "Xã Tân Thắng",
            level: "Xã"
          },
          {
            id: "23255",
            name: "Xã Thắng Hải",
            level: "Xã"
          },
          {
            id: "23257",
            name: "Xã Tân Hà",
            level: "Xã"
          },
          {
            id: "23260",
            name: "Xã Tân Xuân",
            level: "Xã"
          },
          {
            id: "23266",
            name: "Xã Sơn Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "602",
        name: "Huyện Phú Quí",
        wards: [
          {
            id: "23272",
            name: "Xã Ngũ Phụng",
            level: "Xã"
          },
          {
            id: "23275",
            name: "Xã Long Hải",
            level: "Xã"
          },
          {
            id: "23278",
            name: "Xã Tam Thanh",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "62",
    name: "Tỉnh Kon Tum",
    districts: [
      {
        id: "608",
        name: "Thành phố Kon Tum",
        wards: [
          {
            id: "23281",
            name: "Phường Quang Trung",
            level: "Phường"
          },
          {
            id: "23284",
            name: "Phường Duy Tân",
            level: "Phường"
          },
          {
            id: "23287",
            name: "Phường Quyết Thắng",
            level: "Phường"
          },
          {
            id: "23290",
            name: "Phường Trường Chinh",
            level: "Phường"
          },
          {
            id: "23293",
            name: "Phường Thắng Lợi",
            level: "Phường"
          },
          {
            id: "23296",
            name: "Phường Ngô Mây",
            level: "Phường"
          },
          {
            id: "23299",
            name: "Phường Thống Nhất",
            level: "Phường"
          },
          {
            id: "23302",
            name: "Phường Lê Lợi",
            level: "Phường"
          },
          {
            id: "23305",
            name: "Phường Nguyễn Trãi",
            level: "Phường"
          },
          {
            id: "23308",
            name: "Phường Trần Hưng Đạo",
            level: "Phường"
          },
          {
            id: "23311",
            name: "Xã Đắk Cấm",
            level: "Xã"
          },
          {
            id: "23314",
            name: "Xã Kroong",
            level: "Xã"
          },
          {
            id: "23317",
            name: "Xã Ngọk Bay",
            level: "Xã"
          },
          {
            id: "23320",
            name: "Xã Vinh Quang",
            level: "Xã"
          },
          {
            id: "23323",
            name: "Xã Đắk Blà",
            level: "Xã"
          },
          {
            id: "23326",
            name: "Xã Ia Chim",
            level: "Xã"
          },
          {
            id: "23327",
            name: "Xã Đăk Năng",
            level: "Xã"
          },
          {
            id: "23329",
            name: "Xã Đoàn Kết",
            level: "Xã"
          },
          {
            id: "23332",
            name: "Xã Chư Hreng",
            level: "Xã"
          },
          {
            id: "23335",
            name: "Xã Đắk Rơ Wa",
            level: "Xã"
          },
          {
            id: "23338",
            name: "Xã Hòa Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "610",
        name: "Huyện Đắk Glei",
        wards: [
          {
            id: "23341",
            name: "Thị trấn Đắk Glei",
            level: "Thị trấn"
          },
          {
            id: "23344",
            name: "Xã Đắk Blô",
            level: "Xã"
          },
          {
            id: "23347",
            name: "Xã Đắk Man",
            level: "Xã"
          },
          {
            id: "23350",
            name: "Xã Đắk Nhoong",
            level: "Xã"
          },
          {
            id: "23353",
            name: "Xã Đắk Pék",
            level: "Xã"
          },
          {
            id: "23356",
            name: "Xã Đắk Choong",
            level: "Xã"
          },
          {
            id: "23359",
            name: "Xã Xốp",
            level: "Xã"
          },
          {
            id: "23362",
            name: "Xã Mường Hoong",
            level: "Xã"
          },
          {
            id: "23365",
            name: "Xã Ngọc Linh",
            level: "Xã"
          },
          {
            id: "23368",
            name: "Xã Đắk Long",
            level: "Xã"
          },
          {
            id: "23371",
            name: "Xã Đắk KRoong",
            level: "Xã"
          },
          {
            id: "23374",
            name: "Xã Đắk Môn",
            level: "Xã"
          }
        ]
      },
      {
        id: "611",
        name: "Huyện Ngọc Hồi",
        wards: [
          {
            id: "23377",
            name: "Thị trấn Plei Kần",
            level: "Thị trấn"
          },
          {
            id: "23380",
            name: "Xã Đắk Ang",
            level: "Xã"
          },
          {
            id: "23383",
            name: "Xã Đắk Dục",
            level: "Xã"
          },
          {
            id: "23386",
            name: "Xã Đắk Nông",
            level: "Xã"
          },
          {
            id: "23389",
            name: "Xã Đắk Xú",
            level: "Xã"
          },
          {
            id: "23392",
            name: "Xã Đắk Kan",
            level: "Xã"
          },
          {
            id: "23395",
            name: "Xã Bờ Y",
            level: "Xã"
          },
          {
            id: "23398",
            name: "Xã Sa Loong",
            level: "Xã"
          }
        ]
      },
      {
        id: "612",
        name: "Huyện Đắk Tô",
        wards: [
          {
            id: "23401",
            name: "Thị trấn Đắk Tô",
            level: "Thị trấn"
          },
          {
            id: "23427",
            name: "Xã Đắk Rơ Nga",
            level: "Xã"
          },
          {
            id: "23428",
            name: "Xã Ngọk Tụ",
            level: "Xã"
          },
          {
            id: "23430",
            name: "Xã Đắk Trăm",
            level: "Xã"
          },
          {
            id: "23431",
            name: "Xã Văn Lem",
            level: "Xã"
          },
          {
            id: "23434",
            name: "Xã Kon Đào",
            level: "Xã"
          },
          {
            id: "23437",
            name: "Xã Tân Cảnh",
            level: "Xã"
          },
          {
            id: "23440",
            name: "Xã Diên Bình",
            level: "Xã"
          },
          {
            id: "23443",
            name: "Xã Pô Kô",
            level: "Xã"
          }
        ]
      },
      {
        id: "613",
        name: "Huyện Kon Plông",
        wards: [
          {
            id: "23452",
            name: "Xã Đắk Nên",
            level: "Xã"
          },
          {
            id: "23455",
            name: "Xã Đắk Ring",
            level: "Xã"
          },
          {
            id: "23458",
            name: "Xã Măng Buk",
            level: "Xã"
          },
          {
            id: "23461",
            name: "Xã Đắk Tăng",
            level: "Xã"
          },
          {
            id: "23464",
            name: "Xã Ngok Tem",
            level: "Xã"
          },
          {
            id: "23467",
            name: "Xã Pờ Ê",
            level: "Xã"
          },
          {
            id: "23470",
            name: "Xã Măng Cành",
            level: "Xã"
          },
          {
            id: "23473",
            name: "Thị trấn Măng Đen",
            level: "Thị trấn"
          },
          {
            id: "23476",
            name: "Xã Hiếu",
            level: "Xã"
          }
        ]
      },
      {
        id: "614",
        name: "Huyện Kon Rẫy",
        wards: [
          {
            id: "23479",
            name: "Thị trấn Đắk Rve",
            level: "Thị trấn"
          },
          {
            id: "23482",
            name: "Xã Đắk Kôi",
            level: "Xã"
          },
          {
            id: "23485",
            name: "Xã Đắk Tơ Lung",
            level: "Xã"
          },
          {
            id: "23488",
            name: "Xã Đắk Ruồng",
            level: "Xã"
          },
          {
            id: "23491",
            name: "Xã Đắk Pne",
            level: "Xã"
          },
          {
            id: "23494",
            name: "Xã Đắk Tờ Re",
            level: "Xã"
          },
          {
            id: "23497",
            name: "Xã Tân Lập",
            level: "Xã"
          }
        ]
      },
      {
        id: "615",
        name: "Huyện Đắk Hà",
        wards: [
          {
            id: "23500",
            name: "Thị trấn Đắk Hà",
            level: "Thị trấn"
          },
          {
            id: "23503",
            name: "Xã Đắk PXi",
            level: "Xã"
          },
          {
            id: "23504",
            name: "Xã Đăk Long",
            level: "Xã"
          },
          {
            id: "23506",
            name: "Xã Đắk HRing",
            level: "Xã"
          },
          {
            id: "23509",
            name: "Xã Đắk Ui",
            level: "Xã"
          },
          {
            id: "23510",
            name: "Xã Đăk Ngọk",
            level: "Xã"
          },
          {
            id: "23512",
            name: "Xã Đắk Mar",
            level: "Xã"
          },
          {
            id: "23515",
            name: "Xã Ngok Wang",
            level: "Xã"
          },
          {
            id: "23518",
            name: "Xã Ngok Réo",
            level: "Xã"
          },
          {
            id: "23521",
            name: "Xã Hà Mòn",
            level: "Xã"
          },
          {
            id: "23524",
            name: "Xã Đắk La",
            level: "Xã"
          }
        ]
      },
      {
        id: "616",
        name: "Huyện Sa Thầy",
        wards: [
          {
            id: "23527",
            name: "Thị trấn Sa Thầy",
            level: "Thị trấn"
          },
          {
            id: "23530",
            name: "Xã Rơ Kơi",
            level: "Xã"
          },
          {
            id: "23533",
            name: "Xã Sa Nhơn",
            level: "Xã"
          },
          {
            id: "23534",
            name: "Xã Hơ Moong",
            level: "Xã"
          },
          {
            id: "23536",
            name: "Xã Mô Rai",
            level: "Xã"
          },
          {
            id: "23539",
            name: "Xã Sa Sơn",
            level: "Xã"
          },
          {
            id: "23542",
            name: "Xã Sa Nghĩa",
            level: "Xã"
          },
          {
            id: "23545",
            name: "Xã Sa Bình",
            level: "Xã"
          },
          {
            id: "23548",
            name: "Xã Ya Xiêr",
            level: "Xã"
          },
          {
            id: "23551",
            name: "Xã Ya Tăng",
            level: "Xã"
          },
          {
            id: "23554",
            name: "Xã Ya ly",
            level: "Xã"
          }
        ]
      },
      {
        id: "617",
        name: "Huyện Tu Mơ Rông",
        wards: [
          {
            id: "23404",
            name: "Xã Ngọc Lây",
            level: "Xã"
          },
          {
            id: "23407",
            name: "Xã Đắk Na",
            level: "Xã"
          },
          {
            id: "23410",
            name: "Xã Măng Ri",
            level: "Xã"
          },
          {
            id: "23413",
            name: "Xã Ngọc Yêu",
            level: "Xã"
          },
          {
            id: "23416",
            name: "Xã Đắk Sao",
            level: "Xã"
          },
          {
            id: "23417",
            name: "Xã Đắk Rơ Ông",
            level: "Xã"
          },
          {
            id: "23419",
            name: "Xã Đắk Tờ Kan",
            level: "Xã"
          },
          {
            id: "23422",
            name: "Xã Tu Mơ Rông",
            level: "Xã"
          },
          {
            id: "23425",
            name: "Xã Đắk Hà",
            level: "Xã"
          },
          {
            id: "23446",
            name: "Xã Tê Xăng",
            level: "Xã"
          },
          {
            id: "23449",
            name: "Xã Văn Xuôi",
            level: "Xã"
          }
        ]
      },
      {
        id: "618",
        name: "Huyện Ia H' Drai",
        wards: [
          {
            id: "23535",
            name: "Xã Ia Đal",
            level: "Xã"
          },
          {
            id: "23537",
            name: "Xã Ia Dom",
            level: "Xã"
          },
          {
            id: "23538",
            name: "Xã Ia Tơi",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "64",
    name: "Tỉnh Gia Lai",
    districts: [
      {
        id: "622",
        name: "Thành phố Pleiku",
        wards: [
          {
            id: "23557",
            name: "Phường Yên Đỗ",
            level: "Phường"
          },
          {
            id: "23560",
            name: "Phường Diên Hồng",
            level: "Phường"
          },
          {
            id: "23563",
            name: "Phường Ia Kring",
            level: "Phường"
          },
          {
            id: "23566",
            name: "Phường Hội Thương",
            level: "Phường"
          },
          {
            id: "23569",
            name: "Phường Hội Phú",
            level: "Phường"
          },
          {
            id: "23570",
            name: "Phường Phù Đổng",
            level: "Phường"
          },
          {
            id: "23572",
            name: "Phường Hoa Lư",
            level: "Phường"
          },
          {
            id: "23575",
            name: "Phường Tây Sơn",
            level: "Phường"
          },
          {
            id: "23578",
            name: "Phường Thống Nhất",
            level: "Phường"
          },
          {
            id: "23579",
            name: "Phường Đống Đa",
            level: "Phường"
          },
          {
            id: "23581",
            name: "Phường Trà Bá",
            level: "Phường"
          },
          {
            id: "23582",
            name: "Phường Thắng Lợi",
            level: "Phường"
          },
          {
            id: "23584",
            name: "Phường Yên Thế",
            level: "Phường"
          },
          {
            id: "23586",
            name: "Phường Chi Lăng",
            level: "Phường"
          },
          {
            id: "23590",
            name: "Xã Biển Hồ",
            level: "Xã"
          },
          {
            id: "23593",
            name: "Xã Tân Sơn",
            level: "Xã"
          },
          {
            id: "23596",
            name: "Xã Trà Đa",
            level: "Xã"
          },
          {
            id: "23599",
            name: "Xã Chư Á",
            level: "Xã"
          },
          {
            id: "23602",
            name: "Xã An Phú",
            level: "Xã"
          },
          {
            id: "23605",
            name: "Xã Diên Phú",
            level: "Xã"
          },
          {
            id: "23608",
            name: "Xã Ia Kênh",
            level: "Xã"
          },
          {
            id: "23611",
            name: "Xã Gào",
            level: "Xã"
          }
        ]
      },
      {
        id: "623",
        name: "Thị xã An Khê",
        wards: [
          {
            id: "23614",
            name: "Phường An Bình",
            level: "Phường"
          },
          {
            id: "23617",
            name: "Phường Tây Sơn",
            level: "Phường"
          },
          {
            id: "23620",
            name: "Phường An Phú",
            level: "Phường"
          },
          {
            id: "23623",
            name: "Phường An Tân",
            level: "Phường"
          },
          {
            id: "23626",
            name: "Xã Tú An",
            level: "Xã"
          },
          {
            id: "23627",
            name: "Xã Xuân An",
            level: "Xã"
          },
          {
            id: "23629",
            name: "Xã Cửu An",
            level: "Xã"
          },
          {
            id: "23630",
            name: "Phường An Phước",
            level: "Phường"
          },
          {
            id: "23632",
            name: "Xã Song An",
            level: "Xã"
          },
          {
            id: "23633",
            name: "Phường Ngô Mây",
            level: "Phường"
          },
          {
            id: "23635",
            name: "Xã Thành An",
            level: "Xã"
          }
        ]
      },
      {
        id: "624",
        name: "Thị xã Ayun Pa",
        wards: [
          {
            id: "24041",
            name: "Phường Cheo Reo",
            level: "Phường"
          },
          {
            id: "24042",
            name: "Phường Hòa Bình",
            level: "Phường"
          },
          {
            id: "24044",
            name: "Phường Đoàn Kết",
            level: "Phường"
          },
          {
            id: "24045",
            name: "Phường Sông Bờ",
            level: "Phường"
          },
          {
            id: "24064",
            name: "Xã Ia RBol",
            level: "Xã"
          },
          {
            id: "24065",
            name: "Xã Chư Băh",
            level: "Xã"
          },
          {
            id: "24070",
            name: "Xã Ia RTô",
            level: "Xã"
          },
          {
            id: "24073",
            name: "Xã Ia Sao",
            level: "Xã"
          }
        ]
      },
      {
        id: "625",
        name: "Huyện KBang",
        wards: [
          {
            id: "23638",
            name: "Thị trấn KBang",
            level: "Thị trấn"
          },
          {
            id: "23641",
            name: "Xã Kon Pne",
            level: "Xã"
          },
          {
            id: "23644",
            name: "Xã Đăk Roong",
            level: "Xã"
          },
          {
            id: "23647",
            name: "Xã Sơn Lang",
            level: "Xã"
          },
          {
            id: "23650",
            name: "Xã KRong",
            level: "Xã"
          },
          {
            id: "23653",
            name: "Xã Sơ Pai",
            level: "Xã"
          },
          {
            id: "23656",
            name: "Xã Lơ Ku",
            level: "Xã"
          },
          {
            id: "23659",
            name: "Xã Đông",
            level: "Xã"
          },
          {
            id: "23660",
            name: "Xã Đak SMar",
            level: "Xã"
          },
          {
            id: "23662",
            name: "Xã Nghĩa An",
            level: "Xã"
          },
          {
            id: "23665",
            name: "Xã Tơ Tung",
            level: "Xã"
          },
          {
            id: "23668",
            name: "Xã Kông Lơng Khơng",
            level: "Xã"
          },
          {
            id: "23671",
            name: "Xã Kông Pla",
            level: "Xã"
          },
          {
            id: "23674",
            name: "Xã Đăk HLơ",
            level: "Xã"
          }
        ]
      },
      {
        id: "626",
        name: "Huyện Đăk Đoa",
        wards: [
          {
            id: "23677",
            name: "Thị trấn Đăk Đoa",
            level: "Thị trấn"
          },
          {
            id: "23680",
            name: "Xã Hà Đông",
            level: "Xã"
          },
          {
            id: "23683",
            name: "Xã Đăk Sơmei",
            level: "Xã"
          },
          {
            id: "23684",
            name: "Xã Đăk Krong",
            level: "Xã"
          },
          {
            id: "23686",
            name: "Xã Hải Yang",
            level: "Xã"
          },
          {
            id: "23689",
            name: "Xã Kon Gang",
            level: "Xã"
          },
          {
            id: "23692",
            name: "Xã Hà Bầu",
            level: "Xã"
          },
          {
            id: "23695",
            name: "Xã Nam Yang",
            level: "Xã"
          },
          {
            id: "23698",
            name: "Xã K' Dang",
            level: "Xã"
          },
          {
            id: "23701",
            name: "Xã H' Neng",
            level: "Xã"
          },
          {
            id: "23704",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "23707",
            name: "Xã Glar",
            level: "Xã"
          },
          {
            id: "23710",
            name: "Xã A Dơk",
            level: "Xã"
          },
          {
            id: "23713",
            name: "Xã Trang",
            level: "Xã"
          },
          {
            id: "23714",
            name: "Xã HNol",
            level: "Xã"
          },
          {
            id: "23716",
            name: "Xã Ia Pết",
            level: "Xã"
          },
          {
            id: "23719",
            name: "Xã Ia Băng",
            level: "Xã"
          }
        ]
      },
      {
        id: "627",
        name: "Huyện Chư Păh",
        wards: [
          {
            id: "23722",
            name: "Thị trấn Phú Hòa",
            level: "Thị trấn"
          },
          {
            id: "23725",
            name: "Xã Hà Tây",
            level: "Xã"
          },
          {
            id: "23728",
            name: "Xã Ia Khươl",
            level: "Xã"
          },
          {
            id: "23731",
            name: "Xã Ia Phí",
            level: "Xã"
          },
          {
            id: "23734",
            name: "Thị trấn Ia Ly",
            level: "Thị trấn"
          },
          {
            id: "23737",
            name: "Xã Ia Mơ Nông",
            level: "Xã"
          },
          {
            id: "23738",
            name: "Xã Ia Kreng",
            level: "Xã"
          },
          {
            id: "23740",
            name: "Xã Đăk Tơ Ver",
            level: "Xã"
          },
          {
            id: "23743",
            name: "Xã Hòa Phú",
            level: "Xã"
          },
          {
            id: "23746",
            name: "Xã Chư Đăng Ya",
            level: "Xã"
          },
          {
            id: "23749",
            name: "Xã Ia Ka",
            level: "Xã"
          },
          {
            id: "23752",
            name: "Xã Ia Nhin",
            level: "Xã"
          },
          {
            id: "23755",
            name: "Xã Nghĩa Hòa",
            level: "Xã"
          },
          {
            id: "23761",
            name: "Xã Nghĩa Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "628",
        name: "Huyện Ia Grai",
        wards: [
          {
            id: "23764",
            name: "Thị trấn Ia Kha",
            level: "Thị trấn"
          },
          {
            id: "23767",
            name: "Xã Ia Sao",
            level: "Xã"
          },
          {
            id: "23768",
            name: "Xã Ia Yok",
            level: "Xã"
          },
          {
            id: "23770",
            name: "Xã Ia Hrung",
            level: "Xã"
          },
          {
            id: "23771",
            name: "Xã Ia Bă",
            level: "Xã"
          },
          {
            id: "23773",
            name: "Xã Ia Khai",
            level: "Xã"
          },
          {
            id: "23776",
            name: "Xã Ia KRai",
            level: "Xã"
          },
          {
            id: "23778",
            name: "Xã Ia Grăng",
            level: "Xã"
          },
          {
            id: "23779",
            name: "Xã Ia Tô",
            level: "Xã"
          },
          {
            id: "23782",
            name: "Xã Ia O",
            level: "Xã"
          },
          {
            id: "23785",
            name: "Xã Ia Dêr",
            level: "Xã"
          },
          {
            id: "23788",
            name: "Xã Ia Chia",
            level: "Xã"
          },
          {
            id: "23791",
            name: "Xã Ia Pếch",
            level: "Xã"
          }
        ]
      },
      {
        id: "629",
        name: "Huyện Mang Yang",
        wards: [
          {
            id: "23794",
            name: "Thị trấn Kon Dơng",
            level: "Thị trấn"
          },
          {
            id: "23797",
            name: "Xã Ayun",
            level: "Xã"
          },
          {
            id: "23798",
            name: "Xã Đak Jơ Ta",
            level: "Xã"
          },
          {
            id: "23799",
            name: "Xã Đak Ta Ley",
            level: "Xã"
          },
          {
            id: "23800",
            name: "Xã Hra",
            level: "Xã"
          },
          {
            id: "23803",
            name: "Xã Đăk Yă",
            level: "Xã"
          },
          {
            id: "23806",
            name: "Xã Đăk Djrăng",
            level: "Xã"
          },
          {
            id: "23809",
            name: "Xã Lơ Pang",
            level: "Xã"
          },
          {
            id: "23812",
            name: "Xã Kon Thụp",
            level: "Xã"
          },
          {
            id: "23815",
            name: "Xã Đê Ar",
            level: "Xã"
          },
          {
            id: "23818",
            name: "Xã Kon Chiêng",
            level: "Xã"
          },
          {
            id: "23821",
            name: "Xã Đăk Trôi",
            level: "Xã"
          }
        ]
      },
      {
        id: "630",
        name: "Huyện Kông Chro",
        wards: [
          {
            id: "23824",
            name: "Thị trấn Kông Chro",
            level: "Thị trấn"
          },
          {
            id: "23827",
            name: "Xã Chư Krêy",
            level: "Xã"
          },
          {
            id: "23830",
            name: "Xã An Trung",
            level: "Xã"
          },
          {
            id: "23833",
            name: "Xã Kông Yang",
            level: "Xã"
          },
          {
            id: "23836",
            name: "Xã Đăk Tơ Pang",
            level: "Xã"
          },
          {
            id: "23839",
            name: "Xã SRó",
            level: "Xã"
          },
          {
            id: "23840",
            name: "Xã Đắk Kơ Ning",
            level: "Xã"
          },
          {
            id: "23842",
            name: "Xã Đăk Song",
            level: "Xã"
          },
          {
            id: "23843",
            name: "Xã Đăk Pling",
            level: "Xã"
          },
          {
            id: "23845",
            name: "Xã Yang Trung",
            level: "Xã"
          },
          {
            id: "23846",
            name: "Xã Đăk Pơ Pho",
            level: "Xã"
          },
          {
            id: "23848",
            name: "Xã Ya Ma",
            level: "Xã"
          },
          {
            id: "23851",
            name: "Xã Chơ Long",
            level: "Xã"
          },
          {
            id: "23854",
            name: "Xã Yang Nam",
            level: "Xã"
          }
        ]
      },
      {
        id: "631",
        name: "Huyện Đức Cơ",
        wards: [
          {
            id: "23857",
            name: "Thị trấn Chư Ty",
            level: "Thị trấn"
          },
          {
            id: "23860",
            name: "Xã Ia Dơk",
            level: "Xã"
          },
          {
            id: "23863",
            name: "Xã Ia Krêl",
            level: "Xã"
          },
          {
            id: "23866",
            name: "Xã Ia Din",
            level: "Xã"
          },
          {
            id: "23869",
            name: "Xã Ia Kla",
            level: "Xã"
          },
          {
            id: "23872",
            name: "Xã Ia Dom",
            level: "Xã"
          },
          {
            id: "23875",
            name: "Xã Ia Lang",
            level: "Xã"
          },
          {
            id: "23878",
            name: "Xã Ia Kriêng",
            level: "Xã"
          },
          {
            id: "23881",
            name: "Xã Ia Pnôn",
            level: "Xã"
          },
          {
            id: "23884",
            name: "Xã Ia Nan",
            level: "Xã"
          }
        ]
      },
      {
        id: "632",
        name: "Huyện Chư Prông",
        wards: [
          {
            id: "23887",
            name: "Thị trấn Chư Prông",
            level: "Thị trấn"
          },
          {
            id: "23888",
            name: "Xã Ia Kly",
            level: "Xã"
          },
          {
            id: "23890",
            name: "Xã Bình Giáo",
            level: "Xã"
          },
          {
            id: "23893",
            name: "Xã Ia Drăng",
            level: "Xã"
          },
          {
            id: "23896",
            name: "Xã Thăng Hưng",
            level: "Xã"
          },
          {
            id: "23899",
            name: "Xã Bàu Cạn",
            level: "Xã"
          },
          {
            id: "23902",
            name: "Xã Ia Phìn",
            level: "Xã"
          },
          {
            id: "23905",
            name: "Xã Ia Băng",
            level: "Xã"
          },
          {
            id: "23908",
            name: "Xã Ia Tôr",
            level: "Xã"
          },
          {
            id: "23911",
            name: "Xã Ia Boòng",
            level: "Xã"
          },
          {
            id: "23914",
            name: "Xã Ia O",
            level: "Xã"
          },
          {
            id: "23917",
            name: "Xã Ia Púch",
            level: "Xã"
          },
          {
            id: "23920",
            name: "Xã Ia Me",
            level: "Xã"
          },
          {
            id: "23923",
            name: "Xã Ia Vê",
            level: "Xã"
          },
          {
            id: "23924",
            name: "Xã Ia Bang",
            level: "Xã"
          },
          {
            id: "23926",
            name: "Xã Ia Pia",
            level: "Xã"
          },
          {
            id: "23929",
            name: "Xã Ia Ga",
            level: "Xã"
          },
          {
            id: "23932",
            name: "Xã Ia Lâu",
            level: "Xã"
          },
          {
            id: "23935",
            name: "Xã Ia Piơr",
            level: "Xã"
          },
          {
            id: "23938",
            name: "Xã Ia Mơ",
            level: "Xã"
          }
        ]
      },
      {
        id: "633",
        name: "Huyện Chư Sê",
        wards: [
          {
            id: "23941",
            name: "Thị trấn Chư Sê",
            level: "Thị trấn"
          },
          {
            id: "23944",
            name: "Xã Ia Tiêm",
            level: "Xã"
          },
          {
            id: "23945",
            name: "Xã Chư Pơng",
            level: "Xã"
          },
          {
            id: "23946",
            name: "Xã Bar Măih",
            level: "Xã"
          },
          {
            id: "23947",
            name: "Xã Bờ Ngoong",
            level: "Xã"
          },
          {
            id: "23950",
            name: "Xã Ia Glai",
            level: "Xã"
          },
          {
            id: "23953",
            name: "Xã AL Bá",
            level: "Xã"
          },
          {
            id: "23954",
            name: "Xã Kông HTok",
            level: "Xã"
          },
          {
            id: "23956",
            name: "Xã AYun",
            level: "Xã"
          },
          {
            id: "23959",
            name: "Xã Ia HLốp",
            level: "Xã"
          },
          {
            id: "23962",
            name: "Xã Ia Blang",
            level: "Xã"
          },
          {
            id: "23965",
            name: "Xã Dun",
            level: "Xã"
          },
          {
            id: "23966",
            name: "Xã Ia Pal",
            level: "Xã"
          },
          {
            id: "23968",
            name: "Xã H Bông",
            level: "Xã"
          },
          {
            id: "23977",
            name: "Xã Ia Ko",
            level: "Xã"
          }
        ]
      },
      {
        id: "634",
        name: "Huyện Đăk Pơ",
        wards: [
          {
            id: "23989",
            name: "Xã Hà Tam",
            level: "Xã"
          },
          {
            id: "23992",
            name: "Xã An Thành",
            level: "Xã"
          },
          {
            id: "23995",
            name: "Thị trấn Đak Pơ",
            level: "Thị trấn"
          },
          {
            id: "23998",
            name: "Xã Yang Bắc",
            level: "Xã"
          },
          {
            id: "24001",
            name: "Xã Cư An",
            level: "Xã"
          },
          {
            id: "24004",
            name: "Xã Tân An",
            level: "Xã"
          },
          {
            id: "24007",
            name: "Xã Phú An",
            level: "Xã"
          },
          {
            id: "24010",
            name: "Xã Ya Hội",
            level: "Xã"
          }
        ]
      },
      {
        id: "635",
        name: "Huyện Ia Pa",
        wards: [
          {
            id: "24013",
            name: "Xã Pờ Tó",
            level: "Xã"
          },
          {
            id: "24016",
            name: "Xã Chư Răng",
            level: "Xã"
          },
          {
            id: "24019",
            name: "Xã Ia KDăm",
            level: "Xã"
          },
          {
            id: "24022",
            name: "Xã Kim Tân",
            level: "Xã"
          },
          {
            id: "24025",
            name: "Xã Chư Mố",
            level: "Xã"
          },
          {
            id: "24028",
            name: "Xã Ia Tul",
            level: "Xã"
          },
          {
            id: "24031",
            name: "Xã Ia Ma Rơn",
            level: "Xã"
          },
          {
            id: "24034",
            name: "Xã Ia Broăi",
            level: "Xã"
          },
          {
            id: "24037",
            name: "Xã Ia Trok",
            level: "Xã"
          }
        ]
      },
      {
        id: "637",
        name: "Huyện Krông Pa",
        wards: [
          {
            id: "24076",
            name: "Thị trấn Phú Túc",
            level: "Thị trấn"
          },
          {
            id: "24079",
            name: "Xã Ia RSai",
            level: "Xã"
          },
          {
            id: "24082",
            name: "Xã Ia RSươm",
            level: "Xã"
          },
          {
            id: "24085",
            name: "Xã Chư Gu",
            level: "Xã"
          },
          {
            id: "24088",
            name: "Xã Đất Bằng",
            level: "Xã"
          },
          {
            id: "24091",
            name: "Xã Ia Mláh",
            level: "Xã"
          },
          {
            id: "24094",
            name: "Xã Chư Drăng",
            level: "Xã"
          },
          {
            id: "24097",
            name: "Xã Phú Cần",
            level: "Xã"
          },
          {
            id: "24100",
            name: "Xã Ia HDreh",
            level: "Xã"
          },
          {
            id: "24103",
            name: "Xã Ia RMok",
            level: "Xã"
          },
          {
            id: "24106",
            name: "Xã Chư Ngọc",
            level: "Xã"
          },
          {
            id: "24109",
            name: "Xã Uar",
            level: "Xã"
          },
          {
            id: "24112",
            name: "Xã Chư Rcăm",
            level: "Xã"
          },
          {
            id: "24115",
            name: "Xã Krông Năng",
            level: "Xã"
          }
        ]
      },
      {
        id: "638",
        name: "Huyện Phú Thiện",
        wards: [
          {
            id: "24043",
            name: "Thị trấn Phú Thiện",
            level: "Thị trấn"
          },
          {
            id: "24046",
            name: "Xã Chư A Thai",
            level: "Xã"
          },
          {
            id: "24048",
            name: "Xã Ayun Hạ",
            level: "Xã"
          },
          {
            id: "24049",
            name: "Xã Ia Ake",
            level: "Xã"
          },
          {
            id: "24052",
            name: "Xã Ia Sol",
            level: "Xã"
          },
          {
            id: "24055",
            name: "Xã Ia Piar",
            level: "Xã"
          },
          {
            id: "24058",
            name: "Xã Ia Peng",
            level: "Xã"
          },
          {
            id: "24060",
            name: "Xã Chrôh Pơnan",
            level: "Xã"
          },
          {
            id: "24061",
            name: "Xã Ia Hiao",
            level: "Xã"
          },
          {
            id: "24067",
            name: "Xã Ia Yeng",
            level: "Xã"
          }
        ]
      },
      {
        id: "639",
        name: "Huyện Chư Pưh",
        wards: [
          {
            id: "23942",
            name: "Thị trấn Nhơn Hoà",
            level: "Thị trấn"
          },
          {
            id: "23971",
            name: "Xã Ia Hrú",
            level: "Xã"
          },
          {
            id: "23972",
            name: "Xã Ia Rong",
            level: "Xã"
          },
          {
            id: "23974",
            name: "Xã Ia Dreng",
            level: "Xã"
          },
          {
            id: "23978",
            name: "Xã Ia Hla",
            level: "Xã"
          },
          {
            id: "23980",
            name: "Xã Chư Don",
            level: "Xã"
          },
          {
            id: "23983",
            name: "Xã Ia Phang",
            level: "Xã"
          },
          {
            id: "23986",
            name: "Xã Ia Le",
            level: "Xã"
          },
          {
            id: "23987",
            name: "Xã Ia BLứ",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "66",
    name: "Tỉnh Đắk Lắk",
    districts: [
      {
        id: "643",
        name: "Thành phố Buôn Ma Thuột",
        wards: [
          {
            id: "24118",
            name: "Phường Tân Lập",
            level: "Phường"
          },
          {
            id: "24121",
            name: "Phường Tân Hòa",
            level: "Phường"
          },
          {
            id: "24124",
            name: "Phường Tân An",
            level: "Phường"
          },
          {
            id: "24127",
            name: "Phường Thống Nhất",
            level: "Phường"
          },
          {
            id: "24130",
            name: "Phường Thành Nhất",
            level: "Phường"
          },
          {
            id: "24133",
            name: "Phường Thắng Lợi",
            level: "Phường"
          },
          {
            id: "24136",
            name: "Phường Tân Lợi",
            level: "Phường"
          },
          {
            id: "24139",
            name: "Phường Thành Công",
            level: "Phường"
          },
          {
            id: "24142",
            name: "Phường Tân Thành",
            level: "Phường"
          },
          {
            id: "24145",
            name: "Phường Tân Tiến",
            level: "Phường"
          },
          {
            id: "24148",
            name: "Phường Tự An",
            level: "Phường"
          },
          {
            id: "24151",
            name: "Phường Ea Tam",
            level: "Phường"
          },
          {
            id: "24154",
            name: "Phường Khánh Xuân",
            level: "Phường"
          },
          {
            id: "24157",
            name: "Xã Hòa Thuận",
            level: "Xã"
          },
          {
            id: "24160",
            name: "Xã Cư ÊBur",
            level: "Xã"
          },
          {
            id: "24163",
            name: "Xã Ea Tu",
            level: "Xã"
          },
          {
            id: "24166",
            name: "Xã Hòa Thắng",
            level: "Xã"
          },
          {
            id: "24169",
            name: "Xã Ea Kao",
            level: "Xã"
          },
          {
            id: "24172",
            name: "Xã Hòa Phú",
            level: "Xã"
          },
          {
            id: "24175",
            name: "Xã Hòa Khánh",
            level: "Xã"
          },
          {
            id: "24178",
            name: "Xã Hòa Xuân",
            level: "Xã"
          }
        ]
      },
      {
        id: "644",
        name: "Thị Xã Buôn Hồ",
        wards: [
          {
            id: "24305",
            name: "Phường An Lạc",
            level: "Phường"
          },
          {
            id: "24308",
            name: "Phường An Bình",
            level: "Phường"
          },
          {
            id: "24311",
            name: "Phường Thiện An",
            level: "Phường"
          },
          {
            id: "24318",
            name: "Phường Đạt Hiếu",
            level: "Phường"
          },
          {
            id: "24322",
            name: "Phường Đoàn Kết",
            level: "Phường"
          },
          {
            id: "24325",
            name: "Xã Ea Blang",
            level: "Xã"
          },
          {
            id: "24328",
            name: "Xã Ea Drông",
            level: "Xã"
          },
          {
            id: "24331",
            name: "Phường Thống Nhất",
            level: "Phường"
          },
          {
            id: "24332",
            name: "Phường Bình Tân",
            level: "Phường"
          },
          {
            id: "24334",
            name: "Xã Ea Siên",
            level: "Xã"
          },
          {
            id: "24337",
            name: "Xã Bình Thuận",
            level: "Xã"
          },
          {
            id: "24340",
            name: "Xã Cư Bao",
            level: "Xã"
          }
        ]
      },
      {
        id: "645",
        name: "Huyện Ea H'leo",
        wards: [
          {
            id: "24181",
            name: "Thị trấn Ea Drăng",
            level: "Thị trấn"
          },
          {
            id: "24184",
            name: "Xã Ea H'leo",
            level: "Xã"
          },
          {
            id: "24187",
            name: "Xã Ea Sol",
            level: "Xã"
          },
          {
            id: "24190",
            name: "Xã Ea Ral",
            level: "Xã"
          },
          {
            id: "24193",
            name: "Xã Ea Wy",
            level: "Xã"
          },
          {
            id: "24194",
            name: "Xã Cư A Mung",
            level: "Xã"
          },
          {
            id: "24196",
            name: "Xã Cư Mốt",
            level: "Xã"
          },
          {
            id: "24199",
            name: "Xã Ea Hiao",
            level: "Xã"
          },
          {
            id: "24202",
            name: "Xã Ea Khal",
            level: "Xã"
          },
          {
            id: "24205",
            name: "Xã Dliê Yang",
            level: "Xã"
          },
          {
            id: "24207",
            name: "Xã Ea Tir",
            level: "Xã"
          },
          {
            id: "24208",
            name: "Xã Ea Nam",
            level: "Xã"
          }
        ]
      },
      {
        id: "646",
        name: "Huyện Ea Súp",
        wards: [
          {
            id: "24211",
            name: "Thị trấn Ea Súp",
            level: "Thị trấn"
          },
          {
            id: "24214",
            name: "Xã Ia Lốp",
            level: "Xã"
          },
          {
            id: "24215",
            name: "Xã Ia JLơi",
            level: "Xã"
          },
          {
            id: "24217",
            name: "Xã Ea Rốk",
            level: "Xã"
          },
          {
            id: "24220",
            name: "Xã Ya Tờ Mốt",
            level: "Xã"
          },
          {
            id: "24221",
            name: "Xã Ia RVê",
            level: "Xã"
          },
          {
            id: "24223",
            name: "Xã Ea Lê",
            level: "Xã"
          },
          {
            id: "24226",
            name: "Xã Cư KBang",
            level: "Xã"
          },
          {
            id: "24229",
            name: "Xã Ea Bung",
            level: "Xã"
          },
          {
            id: "24232",
            name: "Xã Cư M'Lan",
            level: "Xã"
          }
        ]
      },
      {
        id: "647",
        name: "Huyện Buôn Đôn",
        wards: [
          {
            id: "24235",
            name: "Xã Krông Na",
            level: "Xã"
          },
          {
            id: "24238",
            name: "Xã Ea Huar",
            level: "Xã"
          },
          {
            id: "24241",
            name: "Xã Ea Wer",
            level: "Xã"
          },
          {
            id: "24244",
            name: "Xã Tân Hoà",
            level: "Xã"
          },
          {
            id: "24247",
            name: "Xã Cuôr KNia",
            level: "Xã"
          },
          {
            id: "24250",
            name: "Xã Ea Bar",
            level: "Xã"
          },
          {
            id: "24253",
            name: "Xã Ea Nuôl",
            level: "Xã"
          }
        ]
      },
      {
        id: "648",
        name: "Huyện Cư M'gar",
        wards: [
          {
            id: "24256",
            name: "Thị trấn Ea Pốk",
            level: "Thị trấn"
          },
          {
            id: "24259",
            name: "Thị trấn Quảng Phú",
            level: "Thị trấn"
          },
          {
            id: "24262",
            name: "Xã Quảng Tiến",
            level: "Xã"
          },
          {
            id: "24264",
            name: "Xã Ea Kuêh",
            level: "Xã"
          },
          {
            id: "24265",
            name: "Xã Ea Kiết",
            level: "Xã"
          },
          {
            id: "24268",
            name: "Xã Ea Tar",
            level: "Xã"
          },
          {
            id: "24271",
            name: "Xã Cư Dliê M'nông",
            level: "Xã"
          },
          {
            id: "24274",
            name: "Xã Ea H'đinh",
            level: "Xã"
          },
          {
            id: "24277",
            name: "Xã Ea Tul",
            level: "Xã"
          },
          {
            id: "24280",
            name: "Xã Ea KPam",
            level: "Xã"
          },
          {
            id: "24283",
            name: "Xã Ea M'DRóh",
            level: "Xã"
          },
          {
            id: "24286",
            name: "Xã Quảng Hiệp",
            level: "Xã"
          },
          {
            id: "24289",
            name: "Xã Cư M'gar",
            level: "Xã"
          },
          {
            id: "24292",
            name: "Xã Ea D'Rơng",
            level: "Xã"
          },
          {
            id: "24295",
            name: "Xã Ea M'nang",
            level: "Xã"
          },
          {
            id: "24298",
            name: "Xã Cư Suê",
            level: "Xã"
          },
          {
            id: "24301",
            name: "Xã Cuor Đăng",
            level: "Xã"
          }
        ]
      },
      {
        id: "649",
        name: "Huyện Krông Búk",
        wards: [
          {
            id: "24307",
            name: "Xã Cư Né",
            level: "Xã"
          },
          {
            id: "24310",
            name: "Xã Chư KBô",
            level: "Xã"
          },
          {
            id: "24313",
            name: "Xã Cư Pơng",
            level: "Xã"
          },
          {
            id: "24314",
            name: "Xã Ea Sin",
            level: "Xã"
          },
          {
            id: "24316",
            name: "Xã Pơng Drang",
            level: "Xã"
          },
          {
            id: "24317",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "24319",
            name: "Xã Ea Ngai",
            level: "Xã"
          }
        ]
      },
      {
        id: "650",
        name: "Huyện Krông Năng",
        wards: [
          {
            id: "24343",
            name: "Thị trấn Krông Năng",
            level: "Thị trấn"
          },
          {
            id: "24346",
            name: "Xã ĐLiê Ya",
            level: "Xã"
          },
          {
            id: "24349",
            name: "Xã Ea Tóh",
            level: "Xã"
          },
          {
            id: "24352",
            name: "Xã Ea Tam",
            level: "Xã"
          },
          {
            id: "24355",
            name: "Xã Phú Lộc",
            level: "Xã"
          },
          {
            id: "24358",
            name: "Xã Tam Giang",
            level: "Xã"
          },
          {
            id: "24359",
            name: "Xã Ea Puk",
            level: "Xã"
          },
          {
            id: "24360",
            name: "Xã Ea Dăh",
            level: "Xã"
          },
          {
            id: "24361",
            name: "Xã Ea Hồ",
            level: "Xã"
          },
          {
            id: "24364",
            name: "Xã Phú Xuân",
            level: "Xã"
          },
          {
            id: "24367",
            name: "Xã Cư Klông",
            level: "Xã"
          },
          {
            id: "24370",
            name: "Xã Ea Tân",
            level: "Xã"
          }
        ]
      },
      {
        id: "651",
        name: "Huyện Ea Kar",
        wards: [
          {
            id: "24373",
            name: "Thị trấn Ea Kar",
            level: "Thị trấn"
          },
          {
            id: "24376",
            name: "Thị trấn Ea Knốp",
            level: "Thị trấn"
          },
          {
            id: "24379",
            name: "Xã Ea Sô",
            level: "Xã"
          },
          {
            id: "24380",
            name: "Xã Ea Sar",
            level: "Xã"
          },
          {
            id: "24382",
            name: "Xã Xuân Phú",
            level: "Xã"
          },
          {
            id: "24385",
            name: "Xã Cư Huê",
            level: "Xã"
          },
          {
            id: "24388",
            name: "Xã Ea Tih",
            level: "Xã"
          },
          {
            id: "24391",
            name: "Xã Ea Đar",
            level: "Xã"
          },
          {
            id: "24394",
            name: "Xã Ea Kmút",
            level: "Xã"
          },
          {
            id: "24397",
            name: "Xã Cư Ni",
            level: "Xã"
          },
          {
            id: "24400",
            name: "Xã Ea Păl",
            level: "Xã"
          },
          {
            id: "24401",
            name: "Xã Cư Prông",
            level: "Xã"
          },
          {
            id: "24403",
            name: "Xã Ea Ô",
            level: "Xã"
          },
          {
            id: "24404",
            name: "Xã Cư ELang",
            level: "Xã"
          },
          {
            id: "24406",
            name: "Xã Cư Bông",
            level: "Xã"
          },
          {
            id: "24409",
            name: "Xã Cư Jang",
            level: "Xã"
          }
        ]
      },
      {
        id: "652",
        name: "Huyện M'Đrắk",
        wards: [
          {
            id: "24412",
            name: "Thị trấn M'Đrắk",
            level: "Thị trấn"
          },
          {
            id: "24415",
            name: "Xã Cư Prao",
            level: "Xã"
          },
          {
            id: "24418",
            name: "Xã Ea Pil",
            level: "Xã"
          },
          {
            id: "24421",
            name: "Xã Ea Lai",
            level: "Xã"
          },
          {
            id: "24424",
            name: "Xã Ea H'MLay",
            level: "Xã"
          },
          {
            id: "24427",
            name: "Xã Krông Jing",
            level: "Xã"
          },
          {
            id: "24430",
            name: "Xã Ea M' Doal",
            level: "Xã"
          },
          {
            id: "24433",
            name: "Xã Ea Riêng",
            level: "Xã"
          },
          {
            id: "24436",
            name: "Xã Cư M'ta",
            level: "Xã"
          },
          {
            id: "24439",
            name: "Xã Cư K Róa",
            level: "Xã"
          },
          {
            id: "24442",
            name: "Xã Krông Á",
            level: "Xã"
          },
          {
            id: "24444",
            name: "Xã Cư San",
            level: "Xã"
          },
          {
            id: "24445",
            name: "Xã Ea Trang",
            level: "Xã"
          }
        ]
      },
      {
        id: "653",
        name: "Huyện Krông Bông",
        wards: [
          {
            id: "24448",
            name: "Thị trấn Krông Kmar",
            level: "Thị trấn"
          },
          {
            id: "24451",
            name: "Xã Dang Kang",
            level: "Xã"
          },
          {
            id: "24454",
            name: "Xã Cư KTy",
            level: "Xã"
          },
          {
            id: "24457",
            name: "Xã Hòa Thành",
            level: "Xã"
          },
          {
            id: "24460",
            name: "Xã Hòa Tân",
            level: "Xã"
          },
          {
            id: "24463",
            name: "Xã Hòa Phong",
            level: "Xã"
          },
          {
            id: "24466",
            name: "Xã Hòa Lễ",
            level: "Xã"
          },
          {
            id: "24469",
            name: "Xã Yang Reh",
            level: "Xã"
          },
          {
            id: "24472",
            name: "Xã Ea Trul",
            level: "Xã"
          },
          {
            id: "24475",
            name: "Xã Khuê Ngọc Điền",
            level: "Xã"
          },
          {
            id: "24478",
            name: "Xã Cư Pui",
            level: "Xã"
          },
          {
            id: "24481",
            name: "Xã Hòa Sơn",
            level: "Xã"
          },
          {
            id: "24484",
            name: "Xã Cư Drăm",
            level: "Xã"
          },
          {
            id: "24487",
            name: "Xã Yang Mao",
            level: "Xã"
          }
        ]
      },
      {
        id: "654",
        name: "Huyện Krông Pắc",
        wards: [
          {
            id: "24490",
            name: "Thị trấn Phước An",
            level: "Thị trấn"
          },
          {
            id: "24493",
            name: "Xã KRông Búk",
            level: "Xã"
          },
          {
            id: "24496",
            name: "Xã Ea Kly",
            level: "Xã"
          },
          {
            id: "24499",
            name: "Xã Ea Kênh",
            level: "Xã"
          },
          {
            id: "24502",
            name: "Xã Ea Phê",
            level: "Xã"
          },
          {
            id: "24505",
            name: "Xã Ea KNuec",
            level: "Xã"
          },
          {
            id: "24508",
            name: "Xã Ea Yông",
            level: "Xã"
          },
          {
            id: "24511",
            name: "Xã Hòa An",
            level: "Xã"
          },
          {
            id: "24514",
            name: "Xã Ea Kuăng",
            level: "Xã"
          },
          {
            id: "24517",
            name: "Xã Hòa Đông",
            level: "Xã"
          },
          {
            id: "24520",
            name: "Xã Ea Hiu",
            level: "Xã"
          },
          {
            id: "24523",
            name: "Xã Hòa Tiến",
            level: "Xã"
          },
          {
            id: "24526",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "24529",
            name: "Xã Vụ Bổn",
            level: "Xã"
          },
          {
            id: "24532",
            name: "Xã Ea Uy",
            level: "Xã"
          },
          {
            id: "24535",
            name: "Xã Ea Yiêng",
            level: "Xã"
          }
        ]
      },
      {
        id: "655",
        name: "Huyện Krông A Na",
        wards: [
          {
            id: "24538",
            name: "Thị trấn Buôn Trấp",
            level: "Thị trấn"
          },
          {
            id: "24556",
            name: "Xã Dray Sáp",
            level: "Xã"
          },
          {
            id: "24559",
            name: "Xã Ea Na",
            level: "Xã"
          },
          {
            id: "24565",
            name: "Xã Ea Bông",
            level: "Xã"
          },
          {
            id: "24568",
            name: "Xã Băng A Drênh",
            level: "Xã"
          },
          {
            id: "24571",
            name: "Xã Dur KMăl",
            level: "Xã"
          },
          {
            id: "24574",
            name: "Xã Bình Hòa",
            level: "Xã"
          },
          {
            id: "24577",
            name: "Xã Quảng Điền",
            level: "Xã"
          }
        ]
      },
      {
        id: "656",
        name: "Huyện Lắk",
        wards: [
          {
            id: "24580",
            name: "Thị trấn Liên Sơn",
            level: "Thị trấn"
          },
          {
            id: "24583",
            name: "Xã Yang Tao",
            level: "Xã"
          },
          {
            id: "24586",
            name: "Xã Bông Krang",
            level: "Xã"
          },
          {
            id: "24589",
            name: "Xã Đắk Liêng",
            level: "Xã"
          },
          {
            id: "24592",
            name: "Xã Buôn Triết",
            level: "Xã"
          },
          {
            id: "24595",
            name: "Xã Buôn Tría",
            level: "Xã"
          },
          {
            id: "24598",
            name: "Xã Đắk Phơi",
            level: "Xã"
          },
          {
            id: "24601",
            name: "Xã Đắk Nuê",
            level: "Xã"
          },
          {
            id: "24604",
            name: "Xã Krông Nô",
            level: "Xã"
          },
          {
            id: "24607",
            name: "Xã Nam Ka",
            level: "Xã"
          },
          {
            id: "24610",
            name: "Xã Ea R'Bin",
            level: "Xã"
          }
        ]
      },
      {
        id: "657",
        name: "Huyện Cư Kuin",
        wards: [
          {
            id: "24540",
            name: "Xã Ea Ning",
            level: "Xã"
          },
          {
            id: "24541",
            name: "Xã Cư Ê Wi",
            level: "Xã"
          },
          {
            id: "24544",
            name: "Xã Ea Ktur",
            level: "Xã"
          },
          {
            id: "24547",
            name: "Xã Ea Tiêu",
            level: "Xã"
          },
          {
            id: "24550",
            name: "Xã Ea BHốk",
            level: "Xã"
          },
          {
            id: "24553",
            name: "Xã Ea Hu",
            level: "Xã"
          },
          {
            id: "24561",
            name: "Xã Dray Bhăng",
            level: "Xã"
          },
          {
            id: "24562",
            name: "Xã Hòa Hiệp",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "67",
    name: "Tỉnh Đắk Nông",
    districts: [
      {
        id: "660",
        name: "Thành phố Gia Nghĩa",
        wards: [
          {
            id: "24611",
            name: "Phường Nghĩa Đức",
            level: "Phường"
          },
          {
            id: "24612",
            name: "Phường Nghĩa Thành",
            level: "Phường"
          },
          {
            id: "24614",
            name: "Phường Nghĩa Phú",
            level: "Phường"
          },
          {
            id: "24615",
            name: "Phường Nghĩa Tân",
            level: "Phường"
          },
          {
            id: "24617",
            name: "Phường Nghĩa Trung",
            level: "Phường"
          },
          {
            id: "24618",
            name: "Xã Đăk R'Moan",
            level: "Xã"
          },
          {
            id: "24619",
            name: "Phường Quảng Thành",
            level: "Phường"
          },
          {
            id: "24628",
            name: "Xã Đắk Nia",
            level: "Xã"
          }
        ]
      },
      {
        id: "661",
        name: "Huyện Đăk Glong",
        wards: [
          {
            id: "24616",
            name: "Xã Quảng Sơn",
            level: "Xã"
          },
          {
            id: "24620",
            name: "Xã Quảng Hoà",
            level: "Xã"
          },
          {
            id: "24622",
            name: "Xã Đắk Ha",
            level: "Xã"
          },
          {
            id: "24625",
            name: "Xã Đắk R'Măng",
            level: "Xã"
          },
          {
            id: "24631",
            name: "Xã Quảng Khê",
            level: "Xã"
          },
          {
            id: "24634",
            name: "Xã Đắk Plao",
            level: "Xã"
          },
          {
            id: "24637",
            name: "Xã Đắk Som",
            level: "Xã"
          }
        ]
      },
      {
        id: "662",
        name: "Huyện Cư Jút",
        wards: [
          {
            id: "24640",
            name: "Thị trấn Ea T'Ling",
            level: "Thị trấn"
          },
          {
            id: "24643",
            name: "Xã Đắk Wil",
            level: "Xã"
          },
          {
            id: "24646",
            name: "Xã Ea Pô",
            level: "Xã"
          },
          {
            id: "24649",
            name: "Xã Nam Dong",
            level: "Xã"
          },
          {
            id: "24652",
            name: "Xã Đắk DRông",
            level: "Xã"
          },
          {
            id: "24655",
            name: "Xã Tâm Thắng",
            level: "Xã"
          },
          {
            id: "24658",
            name: "Xã Cư Knia",
            level: "Xã"
          },
          {
            id: "24661",
            name: "Xã Trúc Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "663",
        name: "Huyện Đắk Mil",
        wards: [
          {
            id: "24664",
            name: "Thị trấn Đắk Mil",
            level: "Thị trấn"
          },
          {
            id: "24667",
            name: "Xã  Đắk Lao",
            level: "Xã"
          },
          {
            id: "24670",
            name: "Xã Đắk R'La",
            level: "Xã"
          },
          {
            id: "24673",
            name: "Xã Đắk Gằn",
            level: "Xã"
          },
          {
            id: "24676",
            name: "Xã Đức Mạnh",
            level: "Xã"
          },
          {
            id: "24677",
            name: "Xã Đắk N'Drót",
            level: "Xã"
          },
          {
            id: "24678",
            name: "Xã Long Sơn",
            level: "Xã"
          },
          {
            id: "24679",
            name: "Xã Đắk Sắk",
            level: "Xã"
          },
          {
            id: "24682",
            name: "Xã Thuận An",
            level: "Xã"
          },
          {
            id: "24685",
            name: "Xã Đức Minh",
            level: "Xã"
          }
        ]
      },
      {
        id: "664",
        name: "Huyện Krông Nô",
        wards: [
          {
            id: "24688",
            name: "Thị trấn Đắk Mâm",
            level: "Thị trấn"
          },
          {
            id: "24691",
            name: "Xã Đắk Sôr",
            level: "Xã"
          },
          {
            id: "24692",
            name: "Xã Nam Xuân",
            level: "Xã"
          },
          {
            id: "24694",
            name: "Xã Buôn Choah",
            level: "Xã"
          },
          {
            id: "24697",
            name: "Xã Nam Đà",
            level: "Xã"
          },
          {
            id: "24699",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "24700",
            name: "Xã Đắk Drô",
            level: "Xã"
          },
          {
            id: "24703",
            name: "Xã Nâm Nung",
            level: "Xã"
          },
          {
            id: "24706",
            name: "Xã Đức Xuyên",
            level: "Xã"
          },
          {
            id: "24709",
            name: "Xã Đắk Nang",
            level: "Xã"
          },
          {
            id: "24712",
            name: "Xã Quảng Phú",
            level: "Xã"
          },
          {
            id: "24715",
            name: "Xã Nâm N'Đir",
            level: "Xã"
          }
        ]
      },
      {
        id: "665",
        name: "Huyện Đắk Song",
        wards: [
          {
            id: "24717",
            name: "Thị trấn Đức An",
            level: "Thị trấn"
          },
          {
            id: "24718",
            name: "Xã Đắk Môl",
            level: "Xã"
          },
          {
            id: "24719",
            name: "Xã Đắk Hòa",
            level: "Xã"
          },
          {
            id: "24721",
            name: "Xã Nam Bình",
            level: "Xã"
          },
          {
            id: "24722",
            name: "Xã Thuận Hà",
            level: "Xã"
          },
          {
            id: "24724",
            name: "Xã Thuận Hạnh",
            level: "Xã"
          },
          {
            id: "24727",
            name: "Xã Đắk N'Dung",
            level: "Xã"
          },
          {
            id: "24728",
            name: "Xã Nâm N'Jang",
            level: "Xã"
          },
          {
            id: "24730",
            name: "Xã Trường Xuân",
            level: "Xã"
          }
        ]
      },
      {
        id: "666",
        name: "Huyện Đắk R'Lấp",
        wards: [
          {
            id: "24733",
            name: "Thị trấn Kiến Đức",
            level: "Thị trấn"
          },
          {
            id: "24745",
            name: "Xã Quảng Tín",
            level: "Xã"
          },
          {
            id: "24750",
            name: "Xã Đắk Wer",
            level: "Xã"
          },
          {
            id: "24751",
            name: "Xã Nhân Cơ",
            level: "Xã"
          },
          {
            id: "24754",
            name: "Xã Kiến Thành",
            level: "Xã"
          },
          {
            id: "24756",
            name: "Xã Nghĩa Thắng",
            level: "Xã"
          },
          {
            id: "24757",
            name: "Xã Đạo Nghĩa",
            level: "Xã"
          },
          {
            id: "24760",
            name: "Xã Đắk Sin",
            level: "Xã"
          },
          {
            id: "24761",
            name: "Xã Hưng Bình",
            level: "Xã"
          },
          {
            id: "24763",
            name: "Xã Đắk Ru",
            level: "Xã"
          },
          {
            id: "24766",
            name: "Xã Nhân Đạo",
            level: "Xã"
          }
        ]
      },
      {
        id: "667",
        name: "Huyện Tuy Đức",
        wards: [
          {
            id: "24736",
            name: "Xã Quảng Trực",
            level: "Xã"
          },
          {
            id: "24739",
            name: "Xã Đắk Búk So",
            level: "Xã"
          },
          {
            id: "24740",
            name: "Xã Quảng Tâm",
            level: "Xã"
          },
          {
            id: "24742",
            name: "Xã Đắk R'Tíh",
            level: "Xã"
          },
          {
            id: "24746",
            name: "Xã Đắk Ngo",
            level: "Xã"
          },
          {
            id: "24748",
            name: "Xã Quảng Tân",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "68",
    name: "Tỉnh Lâm Đồng",
    districts: [
      {
        id: "672",
        name: "Thành phố Đà Lạt",
        wards: [
          {
            id: "24769",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "24772",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "24775",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "24778",
            name: "Phường 9",
            level: "Phường"
          },
          {
            id: "24781",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "24784",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "24787",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "24790",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "24793",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "24796",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "24799",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "24802",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "24805",
            name: "Xã Xuân Thọ",
            level: "Xã"
          },
          {
            id: "24808",
            name: "Xã Tà Nung",
            level: "Xã"
          },
          {
            id: "24810",
            name: "Xã Trạm Hành",
            level: "Xã"
          },
          {
            id: "24811",
            name: "Xã Xuân Trường",
            level: "Xã"
          }
        ]
      },
      {
        id: "673",
        name: "Thành phố Bảo Lộc",
        wards: [
          {
            id: "24814",
            name: "Phường Lộc Phát",
            level: "Phường"
          },
          {
            id: "24817",
            name: "Phường Lộc Tiến",
            level: "Phường"
          },
          {
            id: "24820",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "24823",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "24826",
            name: "Phường B'lao",
            level: "Phường"
          },
          {
            id: "24829",
            name: "Phường Lộc Sơn",
            level: "Phường"
          },
          {
            id: "24832",
            name: "Xã Đạm Bri",
            level: "Xã"
          },
          {
            id: "24835",
            name: "Xã Lộc Thanh",
            level: "Xã"
          },
          {
            id: "24838",
            name: "Xã Lộc Nga",
            level: "Xã"
          },
          {
            id: "24841",
            name: "Xã Lộc Châu",
            level: "Xã"
          },
          {
            id: "24844",
            name: "Xã Đại Lào",
            level: "Xã"
          }
        ]
      },
      {
        id: "674",
        name: "Huyện Đam Rông",
        wards: [
          {
            id: "24853",
            name: "Xã Đạ Tông",
            level: "Xã"
          },
          {
            id: "24856",
            name: "Xã Đạ Long",
            level: "Xã"
          },
          {
            id: "24859",
            name: "Xã Đạ M' Rong",
            level: "Xã"
          },
          {
            id: "24874",
            name: "Xã Liêng Srônh",
            level: "Xã"
          },
          {
            id: "24875",
            name: "Xã Đạ Rsal",
            level: "Xã"
          },
          {
            id: "24877",
            name: "Xã Rô Men",
            level: "Xã"
          },
          {
            id: "24886",
            name: "Xã Phi Liêng",
            level: "Xã"
          },
          {
            id: "24889",
            name: "Xã Đạ K' Nàng",
            level: "Xã"
          }
        ]
      },
      {
        id: "675",
        name: "Huyện Lạc Dương",
        wards: [
          {
            id: "24846",
            name: "Thị trấn Lạc Dương",
            level: "Thị trấn"
          },
          {
            id: "24847",
            name: "Xã Đạ Chais",
            level: "Xã"
          },
          {
            id: "24848",
            name: "Xã Đạ Nhim",
            level: "Xã"
          },
          {
            id: "24850",
            name: "Xã Đưng KNớ",
            level: "Xã"
          },
          {
            id: "24862",
            name: "Xã Lát",
            level: "Xã"
          },
          {
            id: "24865",
            name: "Xã Đạ Sar",
            level: "Xã"
          }
        ]
      },
      {
        id: "676",
        name: "Huyện Lâm Hà",
        wards: [
          {
            id: "24868",
            name: "Thị trấn Nam Ban",
            level: "Thị trấn"
          },
          {
            id: "24871",
            name: "Thị trấn Đinh Văn",
            level: "Thị trấn"
          },
          {
            id: "24880",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "24883",
            name: "Xã Phi Tô",
            level: "Xã"
          },
          {
            id: "24892",
            name: "Xã Mê Linh",
            level: "Xã"
          },
          {
            id: "24895",
            name: "Xã Đạ Đờn",
            level: "Xã"
          },
          {
            id: "24898",
            name: "Xã Phúc Thọ",
            level: "Xã"
          },
          {
            id: "24901",
            name: "Xã Đông Thanh",
            level: "Xã"
          },
          {
            id: "24904",
            name: "Xã Gia Lâm",
            level: "Xã"
          },
          {
            id: "24907",
            name: "Xã Tân Thanh",
            level: "Xã"
          },
          {
            id: "24910",
            name: "Xã Tân Văn",
            level: "Xã"
          },
          {
            id: "24913",
            name: "Xã Hoài Đức",
            level: "Xã"
          },
          {
            id: "24916",
            name: "Xã Tân Hà",
            level: "Xã"
          },
          {
            id: "24919",
            name: "Xã Liên Hà",
            level: "Xã"
          },
          {
            id: "24922",
            name: "Xã Đan Phượng",
            level: "Xã"
          },
          {
            id: "24925",
            name: "Xã Nam Hà",
            level: "Xã"
          }
        ]
      },
      {
        id: "677",
        name: "Huyện Đơn Dương",
        wards: [
          {
            id: "24928",
            name: "Thị trấn D'Ran",
            level: "Thị trấn"
          },
          {
            id: "24931",
            name: "Thị trấn Thạnh Mỹ",
            level: "Thị trấn"
          },
          {
            id: "24934",
            name: "Xã Lạc Xuân",
            level: "Xã"
          },
          {
            id: "24937",
            name: "Xã Đạ Ròn",
            level: "Xã"
          },
          {
            id: "24940",
            name: "Xã Lạc Lâm",
            level: "Xã"
          },
          {
            id: "24943",
            name: "Xã Ka Đô",
            level: "Xã"
          },
          {
            id: "24946",
            name: "Xã Quảng Lập",
            level: "Xã"
          },
          {
            id: "24949",
            name: "Xã Ka Đơn",
            level: "Xã"
          },
          {
            id: "24952",
            name: "Xã Tu Tra",
            level: "Xã"
          },
          {
            id: "24955",
            name: "Xã Pró",
            level: "Xã"
          }
        ]
      },
      {
        id: "678",
        name: "Huyện Đức Trọng",
        wards: [
          {
            id: "24958",
            name: "Thị trấn Liên Nghĩa",
            level: "Thị trấn"
          },
          {
            id: "24961",
            name: "Xã Hiệp An",
            level: "Xã"
          },
          {
            id: "24964",
            name: "Xã Liên Hiệp",
            level: "Xã"
          },
          {
            id: "24967",
            name: "Xã Hiệp Thạnh",
            level: "Xã"
          },
          {
            id: "24970",
            name: "Xã Bình Thạnh",
            level: "Xã"
          },
          {
            id: "24973",
            name: "Xã N'Thol Hạ",
            level: "Xã"
          },
          {
            id: "24976",
            name: "Xã Tân Hội",
            level: "Xã"
          },
          {
            id: "24979",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "24982",
            name: "Xã Phú Hội",
            level: "Xã"
          },
          {
            id: "24985",
            name: "Xã Ninh Gia",
            level: "Xã"
          },
          {
            id: "24988",
            name: "Xã Tà Năng",
            level: "Xã"
          },
          {
            id: "24989",
            name: "Xã Đa Quyn",
            level: "Xã"
          },
          {
            id: "24991",
            name: "Xã Tà Hine",
            level: "Xã"
          },
          {
            id: "24994",
            name: "Xã Đà Loan",
            level: "Xã"
          },
          {
            id: "24997",
            name: "Xã Ninh Loan",
            level: "Xã"
          }
        ]
      },
      {
        id: "679",
        name: "Huyện Di Linh",
        wards: [
          {
            id: "25000",
            name: "Thị trấn Di Linh",
            level: "Thị trấn"
          },
          {
            id: "25003",
            name: "Xã Đinh Trang Thượng",
            level: "Xã"
          },
          {
            id: "25006",
            name: "Xã Tân Thượng",
            level: "Xã"
          },
          {
            id: "25007",
            name: "Xã Tân Lâm",
            level: "Xã"
          },
          {
            id: "25009",
            name: "Xã Tân Châu",
            level: "Xã"
          },
          {
            id: "25012",
            name: "Xã Tân Nghĩa",
            level: "Xã"
          },
          {
            id: "25015",
            name: "Xã Gia Hiệp",
            level: "Xã"
          },
          {
            id: "25018",
            name: "Xã Đinh Lạc",
            level: "Xã"
          },
          {
            id: "25021",
            name: "Xã Tam Bố",
            level: "Xã"
          },
          {
            id: "25024",
            name: "Xã Đinh Trang Hòa",
            level: "Xã"
          },
          {
            id: "25027",
            name: "Xã Liên Đầm",
            level: "Xã"
          },
          {
            id: "25030",
            name: "Xã Gung Ré",
            level: "Xã"
          },
          {
            id: "25033",
            name: "Xã Bảo Thuận",
            level: "Xã"
          },
          {
            id: "25036",
            name: "Xã Hòa Ninh",
            level: "Xã"
          },
          {
            id: "25039",
            name: "Xã Hòa Trung",
            level: "Xã"
          },
          {
            id: "25042",
            name: "Xã Hòa Nam",
            level: "Xã"
          },
          {
            id: "25045",
            name: "Xã Hòa Bắc",
            level: "Xã"
          },
          {
            id: "25048",
            name: "Xã Sơn Điền",
            level: "Xã"
          },
          {
            id: "25051",
            name: "Xã Gia Bắc",
            level: "Xã"
          }
        ]
      },
      {
        id: "680",
        name: "Huyện Bảo Lâm",
        wards: [
          {
            id: "25054",
            name: "Thị trấn Lộc Thắng",
            level: "Thị trấn"
          },
          {
            id: "25057",
            name: "Xã Lộc Bảo",
            level: "Xã"
          },
          {
            id: "25060",
            name: "Xã Lộc Lâm",
            level: "Xã"
          },
          {
            id: "25063",
            name: "Xã Lộc Phú",
            level: "Xã"
          },
          {
            id: "25066",
            name: "Xã Lộc Bắc",
            level: "Xã"
          },
          {
            id: "25069",
            name: "Xã B' Lá",
            level: "Xã"
          },
          {
            id: "25072",
            name: "Xã Lộc Ngãi",
            level: "Xã"
          },
          {
            id: "25075",
            name: "Xã Lộc Quảng",
            level: "Xã"
          },
          {
            id: "25078",
            name: "Xã Lộc Tân",
            level: "Xã"
          },
          {
            id: "25081",
            name: "Xã Lộc Đức",
            level: "Xã"
          },
          {
            id: "25084",
            name: "Xã Lộc An",
            level: "Xã"
          },
          {
            id: "25087",
            name: "Xã Tân Lạc",
            level: "Xã"
          },
          {
            id: "25090",
            name: "Xã Lộc Thành",
            level: "Xã"
          },
          {
            id: "25093",
            name: "Xã Lộc Nam",
            level: "Xã"
          }
        ]
      },
      {
        id: "681",
        name: "Huyện Đạ Huoai",
        wards: [
          {
            id: "25096",
            name: "Thị trấn Đạ M'ri",
            level: "Thị trấn"
          },
          {
            id: "25099",
            name: "Thị trấn Ma Đa Guôi",
            level: "Thị trấn"
          },
          {
            id: "25105",
            name: "Xã Hà Lâm",
            level: "Xã"
          },
          {
            id: "25108",
            name: "Xã Đạ Tồn",
            level: "Xã"
          },
          {
            id: "25111",
            name: "Xã Đạ Oai",
            level: "Xã"
          },
          {
            id: "25114",
            name: "Xã Đạ Ploa",
            level: "Xã"
          },
          {
            id: "25117",
            name: "Xã Ma Đa Guôi",
            level: "Xã"
          },
          {
            id: "25120",
            name: "Xã Đoàn Kết",
            level: "Xã"
          },
          {
            id: "25123",
            name: "Xã Phước Lộc",
            level: "Xã"
          }
        ]
      },
      {
        id: "682",
        name: "Huyện Đạ Tẻh",
        wards: [
          {
            id: "25126",
            name: "Thị trấn Đạ Tẻh",
            level: "Thị trấn"
          },
          {
            id: "25129",
            name: "Xã An Nhơn",
            level: "Xã"
          },
          {
            id: "25132",
            name: "Xã Quốc Oai",
            level: "Xã"
          },
          {
            id: "25135",
            name: "Xã Mỹ Đức",
            level: "Xã"
          },
          {
            id: "25138",
            name: "Xã Quảng Trị",
            level: "Xã"
          },
          {
            id: "25141",
            name: "Xã Đạ Lây",
            level: "Xã"
          },
          {
            id: "25147",
            name: "Xã Triệu Hải",
            level: "Xã"
          },
          {
            id: "25153",
            name: "Xã Đạ Kho",
            level: "Xã"
          },
          {
            id: "25156",
            name: "Xã Đạ Pal",
            level: "Xã"
          }
        ]
      },
      {
        id: "683",
        name: "Huyện Cát Tiên",
        wards: [
          {
            id: "25159",
            name: "Thị trấn Cát Tiên",
            level: "Thị trấn"
          },
          {
            id: "25162",
            name: "Xã Tiên Hoàng",
            level: "Xã"
          },
          {
            id: "25165",
            name: "Xã Phước Cát 2",
            level: "Xã"
          },
          {
            id: "25168",
            name: "Xã Gia Viễn",
            level: "Xã"
          },
          {
            id: "25171",
            name: "Xã Nam Ninh",
            level: "Xã"
          },
          {
            id: "25180",
            name: "Thị trấn Phước Cát ",
            level: "Thị trấn"
          },
          {
            id: "25183",
            name: "Xã Đức Phổ",
            level: "Xã"
          },
          {
            id: "25189",
            name: "Xã Quảng Ngãi",
            level: "Xã"
          },
          {
            id: "25192",
            name: "Xã Đồng Nai Thượng",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "70",
    name: "Tỉnh Bình Phước",
    districts: [
      {
        id: "688",
        name: "Thị xã Phước Long",
        wards: [
          {
            id: "25216",
            name: "Phường Thác Mơ",
            level: "Phường"
          },
          {
            id: "25217",
            name: "Phường Long Thủy",
            level: "Phường"
          },
          {
            id: "25219",
            name: "Phường Phước Bình",
            level: "Phường"
          },
          {
            id: "25220",
            name: "Phường Long Phước",
            level: "Phường"
          },
          {
            id: "25237",
            name: "Phường Sơn Giang",
            level: "Phường"
          },
          {
            id: "25245",
            name: "Xã Long Giang",
            level: "Xã"
          },
          {
            id: "25249",
            name: "Xã Phước Tín",
            level: "Xã"
          }
        ]
      },
      {
        id: "689",
        name: "Thành phố Đồng Xoài",
        wards: [
          {
            id: "25195",
            name: "Phường Tân Phú",
            level: "Phường"
          },
          {
            id: "25198",
            name: "Phường Tân Đồng",
            level: "Phường"
          },
          {
            id: "25201",
            name: "Phường Tân Bình",
            level: "Phường"
          },
          {
            id: "25204",
            name: "Phường Tân Xuân",
            level: "Phường"
          },
          {
            id: "25205",
            name: "Phường Tân Thiện",
            level: "Phường"
          },
          {
            id: "25207",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "25210",
            name: "Phường Tiến Thành",
            level: "Phường"
          },
          {
            id: "25213",
            name: "Xã Tiến Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "690",
        name: "Thị xã Bình Long",
        wards: [
          {
            id: "25320",
            name: "Phường Hưng Chiến",
            level: "Phường"
          },
          {
            id: "25324",
            name: "Phường An Lộc",
            level: "Phường"
          },
          {
            id: "25325",
            name: "Phường Phú Thịnh",
            level: "Phường"
          },
          {
            id: "25326",
            name: "Phường Phú Đức",
            level: "Phường"
          },
          {
            id: "25333",
            name: "Xã Thanh Lương",
            level: "Xã"
          },
          {
            id: "25336",
            name: "Xã Thanh Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "691",
        name: "Huyện Bù Gia Mập",
        wards: [
          {
            id: "25222",
            name: "Xã Bù Gia Mập",
            level: "Xã"
          },
          {
            id: "25225",
            name: "Xã Đak Ơ",
            level: "Xã"
          },
          {
            id: "25228",
            name: "Xã Đức Hạnh",
            level: "Xã"
          },
          {
            id: "25229",
            name: "Xã Phú Văn",
            level: "Xã"
          },
          {
            id: "25231",
            name: "Xã Đa Kia",
            level: "Xã"
          },
          {
            id: "25232",
            name: "Xã Phước Minh",
            level: "Xã"
          },
          {
            id: "25234",
            name: "Xã Bình Thắng",
            level: "Xã"
          },
          {
            id: "25267",
            name: "Xã Phú Nghĩa",
            level: "Xã"
          }
        ]
      },
      {
        id: "692",
        name: "Huyện Lộc Ninh",
        wards: [
          {
            id: "25270",
            name: "Thị trấn Lộc Ninh",
            level: "Thị trấn"
          },
          {
            id: "25273",
            name: "Xã Lộc Hòa",
            level: "Xã"
          },
          {
            id: "25276",
            name: "Xã Lộc An",
            level: "Xã"
          },
          {
            id: "25279",
            name: "Xã Lộc Tấn",
            level: "Xã"
          },
          {
            id: "25280",
            name: "Xã Lộc Thạnh",
            level: "Xã"
          },
          {
            id: "25282",
            name: "Xã Lộc Hiệp",
            level: "Xã"
          },
          {
            id: "25285",
            name: "Xã Lộc Thiện",
            level: "Xã"
          },
          {
            id: "25288",
            name: "Xã Lộc Thuận",
            level: "Xã"
          },
          {
            id: "25291",
            name: "Xã Lộc Quang",
            level: "Xã"
          },
          {
            id: "25292",
            name: "Xã Lộc Phú",
            level: "Xã"
          },
          {
            id: "25294",
            name: "Xã Lộc Thành",
            level: "Xã"
          },
          {
            id: "25297",
            name: "Xã Lộc Thái",
            level: "Xã"
          },
          {
            id: "25300",
            name: "Xã Lộc Điền",
            level: "Xã"
          },
          {
            id: "25303",
            name: "Xã Lộc Hưng",
            level: "Xã"
          },
          {
            id: "25305",
            name: "Xã Lộc Thịnh",
            level: "Xã"
          },
          {
            id: "25306",
            name: "Xã Lộc Khánh",
            level: "Xã"
          }
        ]
      },
      {
        id: "693",
        name: "Huyện Bù Đốp",
        wards: [
          {
            id: "25308",
            name: "Thị trấn Thanh Bình",
            level: "Thị trấn"
          },
          {
            id: "25309",
            name: "Xã Hưng Phước",
            level: "Xã"
          },
          {
            id: "25310",
            name: "Xã Phước Thiện",
            level: "Xã"
          },
          {
            id: "25312",
            name: "Xã Thiện Hưng",
            level: "Xã"
          },
          {
            id: "25315",
            name: "Xã Thanh Hòa",
            level: "Xã"
          },
          {
            id: "25318",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "25321",
            name: "Xã Tân Tiến",
            level: "Xã"
          }
        ]
      },
      {
        id: "694",
        name: "Huyện Hớn Quản",
        wards: [
          {
            id: "25327",
            name: "Xã Thanh An",
            level: "Xã"
          },
          {
            id: "25330",
            name: "Xã An Khương",
            level: "Xã"
          },
          {
            id: "25339",
            name: "Xã An Phú",
            level: "Xã"
          },
          {
            id: "25342",
            name: "Xã Tân Lợi",
            level: "Xã"
          },
          {
            id: "25345",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "25348",
            name: "Xã Minh Đức",
            level: "Xã"
          },
          {
            id: "25349",
            name: "Xã Minh Tâm",
            level: "Xã"
          },
          {
            id: "25351",
            name: "Xã Phước An",
            level: "Xã"
          },
          {
            id: "25354",
            name: "Xã Thanh Bình",
            level: "Xã"
          },
          {
            id: "25357",
            name: "Thị trấn Tân Khai",
            level: "Thị trấn"
          },
          {
            id: "25360",
            name: "Xã Đồng Nơ",
            level: "Xã"
          },
          {
            id: "25361",
            name: "Xã Tân Hiệp",
            level: "Xã"
          },
          {
            id: "25438",
            name: "Xã Tân Quan",
            level: "Xã"
          }
        ]
      },
      {
        id: "695",
        name: "Huyện Đồng Phú",
        wards: [
          {
            id: "25363",
            name: "Thị trấn Tân Phú",
            level: "Thị trấn"
          },
          {
            id: "25366",
            name: "Xã Thuận Lợi",
            level: "Xã"
          },
          {
            id: "25369",
            name: "Xã Đồng Tâm",
            level: "Xã"
          },
          {
            id: "25372",
            name: "Xã Tân Phước",
            level: "Xã"
          },
          {
            id: "25375",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "25378",
            name: "Xã Tân Lợi",
            level: "Xã"
          },
          {
            id: "25381",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "25384",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "25387",
            name: "Xã Thuận Phú",
            level: "Xã"
          },
          {
            id: "25390",
            name: "Xã Đồng Tiến",
            level: "Xã"
          },
          {
            id: "25393",
            name: "Xã Tân Tiến",
            level: "Xã"
          }
        ]
      },
      {
        id: "696",
        name: "Huyện Bù Đăng",
        wards: [
          {
            id: "25396",
            name: "Thị trấn Đức Phong",
            level: "Thị trấn"
          },
          {
            id: "25398",
            name: "Xã Đường 10",
            level: "Xã"
          },
          {
            id: "25399",
            name: "Xã Đak Nhau",
            level: "Xã"
          },
          {
            id: "25400",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "25402",
            name: "Xã Thọ Sơn",
            level: "Xã"
          },
          {
            id: "25404",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "25405",
            name: "Xã Bom Bo",
            level: "Xã"
          },
          {
            id: "25408",
            name: "Xã Minh Hưng",
            level: "Xã"
          },
          {
            id: "25411",
            name: "Xã Đoàn Kết",
            level: "Xã"
          },
          {
            id: "25414",
            name: "Xã Đồng Nai",
            level: "Xã"
          },
          {
            id: "25417",
            name: "Xã Đức Liễu",
            level: "Xã"
          },
          {
            id: "25420",
            name: "Xã Thống Nhất",
            level: "Xã"
          },
          {
            id: "25423",
            name: "Xã Nghĩa Trung",
            level: "Xã"
          },
          {
            id: "25424",
            name: "Xã Nghĩa Bình",
            level: "Xã"
          },
          {
            id: "25426",
            name: "Xã Đăng Hà",
            level: "Xã"
          },
          {
            id: "25429",
            name: "Xã Phước Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "697",
        name: "Huyện Chơn Thành",
        wards: [
          {
            id: "25432",
            name: "Thị trấn Chơn Thành",
            level: "Thị trấn"
          },
          {
            id: "25433",
            name: "Xã Thành Tâm",
            level: "Xã"
          },
          {
            id: "25435",
            name: "Xã Minh Lập",
            level: "Xã"
          },
          {
            id: "25439",
            name: "Xã Quang Minh",
            level: "Xã"
          },
          {
            id: "25441",
            name: "Xã Minh Hưng",
            level: "Xã"
          },
          {
            id: "25444",
            name: "Xã Minh Long",
            level: "Xã"
          },
          {
            id: "25447",
            name: "Xã Minh Thành",
            level: "Xã"
          },
          {
            id: "25450",
            name: "Xã Nha Bích",
            level: "Xã"
          },
          {
            id: "25453",
            name: "Xã Minh Thắng",
            level: "Xã"
          }
        ]
      },
      {
        id: "698",
        name: "Huyện Phú Riềng",
        wards: [
          {
            id: "25240",
            name: "Xã Long Bình",
            level: "Xã"
          },
          {
            id: "25243",
            name: "Xã Bình Tân",
            level: "Xã"
          },
          {
            id: "25244",
            name: "Xã Bình Sơn",
            level: "Xã"
          },
          {
            id: "25246",
            name: "Xã Long Hưng",
            level: "Xã"
          },
          {
            id: "25250",
            name: "Xã Phước Tân",
            level: "Xã"
          },
          {
            id: "25252",
            name: "Xã Bù Nho",
            level: "Xã"
          },
          {
            id: "25255",
            name: "Xã Long Hà",
            level: "Xã"
          },
          {
            id: "25258",
            name: "Xã Long Tân",
            level: "Xã"
          },
          {
            id: "25261",
            name: "Xã Phú Trung",
            level: "Xã"
          },
          {
            id: "25264",
            name: "Xã Phú Riềng",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "72",
    name: "Tỉnh Tây Ninh",
    districts: [
      {
        id: "703",
        name: "Thành phố Tây Ninh",
        wards: [
          {
            id: "25456",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "25459",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "25462",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "25465",
            name: "Phường Hiệp Ninh",
            level: "Phường"
          },
          {
            id: "25468",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "25471",
            name: "Xã Thạnh Tân",
            level: "Xã"
          },
          {
            id: "25474",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "25477",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "25480",
            name: "Phường Ninh Sơn",
            level: "Phường"
          },
          {
            id: "25483",
            name: "Phường Ninh Thạnh",
            level: "Phường"
          }
        ]
      },
      {
        id: "705",
        name: "Huyện Tân Biên",
        wards: [
          {
            id: "25486",
            name: "Thị trấn Tân Biên",
            level: "Thị trấn"
          },
          {
            id: "25489",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "25492",
            name: "Xã Thạnh Bắc",
            level: "Xã"
          },
          {
            id: "25495",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "25498",
            name: "Xã Thạnh Bình",
            level: "Xã"
          },
          {
            id: "25501",
            name: "Xã Thạnh Tây",
            level: "Xã"
          },
          {
            id: "25504",
            name: "Xã Hòa Hiệp",
            level: "Xã"
          },
          {
            id: "25507",
            name: "Xã Tân Phong",
            level: "Xã"
          },
          {
            id: "25510",
            name: "Xã Mỏ Công",
            level: "Xã"
          },
          {
            id: "25513",
            name: "Xã Trà Vong",
            level: "Xã"
          }
        ]
      },
      {
        id: "706",
        name: "Huyện Tân Châu",
        wards: [
          {
            id: "25516",
            name: "Thị trấn Tân Châu",
            level: "Thị trấn"
          },
          {
            id: "25519",
            name: "Xã Tân Hà",
            level: "Xã"
          },
          {
            id: "25522",
            name: "Xã Tân Đông",
            level: "Xã"
          },
          {
            id: "25525",
            name: "Xã Tân Hội",
            level: "Xã"
          },
          {
            id: "25528",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "25531",
            name: "Xã Suối Ngô",
            level: "Xã"
          },
          {
            id: "25534",
            name: "Xã Suối Dây",
            level: "Xã"
          },
          {
            id: "25537",
            name: "Xã Tân Hiệp",
            level: "Xã"
          },
          {
            id: "25540",
            name: "Xã Thạnh Đông",
            level: "Xã"
          },
          {
            id: "25543",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "25546",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "25549",
            name: "Xã Tân Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "707",
        name: "Huyện Dương Minh Châu",
        wards: [
          {
            id: "25552",
            name: "Thị trấn Dương Minh Châu",
            level: "Thị trấn"
          },
          {
            id: "25555",
            name: "Xã Suối Đá",
            level: "Xã"
          },
          {
            id: "25558",
            name: "Xã Phan",
            level: "Xã"
          },
          {
            id: "25561",
            name: "Xã Phước Ninh",
            level: "Xã"
          },
          {
            id: "25564",
            name: "Xã Phước Minh",
            level: "Xã"
          },
          {
            id: "25567",
            name: "Xã Bàu Năng",
            level: "Xã"
          },
          {
            id: "25570",
            name: "Xã Chà Là",
            level: "Xã"
          },
          {
            id: "25573",
            name: "Xã Cầu Khởi",
            level: "Xã"
          },
          {
            id: "25576",
            name: "Xã Bến Củi",
            level: "Xã"
          },
          {
            id: "25579",
            name: "Xã Lộc Ninh",
            level: "Xã"
          },
          {
            id: "25582",
            name: "Xã Truông Mít",
            level: "Xã"
          }
        ]
      },
      {
        id: "708",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "25585",
            name: "Thị trấn Châu Thành",
            level: "Thị trấn"
          },
          {
            id: "25588",
            name: "Xã Hảo Đước",
            level: "Xã"
          },
          {
            id: "25591",
            name: "Xã Phước Vinh",
            level: "Xã"
          },
          {
            id: "25594",
            name: "Xã Đồng Khởi",
            level: "Xã"
          },
          {
            id: "25597",
            name: "Xã Thái Bình",
            level: "Xã"
          },
          {
            id: "25600",
            name: "Xã An Cơ",
            level: "Xã"
          },
          {
            id: "25603",
            name: "Xã Biên Giới",
            level: "Xã"
          },
          {
            id: "25606",
            name: "Xã Hòa Thạnh",
            level: "Xã"
          },
          {
            id: "25609",
            name: "Xã Trí Bình",
            level: "Xã"
          },
          {
            id: "25612",
            name: "Xã Hòa Hội",
            level: "Xã"
          },
          {
            id: "25615",
            name: "Xã An Bình",
            level: "Xã"
          },
          {
            id: "25618",
            name: "Xã Thanh Điền",
            level: "Xã"
          },
          {
            id: "25621",
            name: "Xã Thành Long",
            level: "Xã"
          },
          {
            id: "25624",
            name: "Xã Ninh Điền",
            level: "Xã"
          },
          {
            id: "25627",
            name: "Xã Long Vĩnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "709",
        name: "Thị xã Hòa Thành",
        wards: [
          {
            id: "25630",
            name: "Phường Long Hoa",
            level: "Phường"
          },
          {
            id: "25633",
            name: "Phường Hiệp Tân",
            level: "Phường"
          },
          {
            id: "25636",
            name: "Phường Long Thành Bắc",
            level: "Phường"
          },
          {
            id: "25639",
            name: "Xã Trường Hòa",
            level: "Xã"
          },
          {
            id: "25642",
            name: "Xã Trường Đông",
            level: "Xã"
          },
          {
            id: "25645",
            name: "Phường Long Thành Trung",
            level: "Phường"
          },
          {
            id: "25648",
            name: "Xã Trường Tây",
            level: "Xã"
          },
          {
            id: "25651",
            name: "Xã Long Thành Nam",
            level: "Xã"
          }
        ]
      },
      {
        id: "710",
        name: "Huyện Gò Dầu",
        wards: [
          {
            id: "25654",
            name: "Thị trấn Gò Dầu",
            level: "Thị trấn"
          },
          {
            id: "25657",
            name: "Xã Thạnh Đức",
            level: "Xã"
          },
          {
            id: "25660",
            name: "Xã Cẩm Giang",
            level: "Xã"
          },
          {
            id: "25663",
            name: "Xã Hiệp Thạnh",
            level: "Xã"
          },
          {
            id: "25666",
            name: "Xã Bàu Đồn",
            level: "Xã"
          },
          {
            id: "25669",
            name: "Xã Phước Thạnh",
            level: "Xã"
          },
          {
            id: "25672",
            name: "Xã Phước Đông",
            level: "Xã"
          },
          {
            id: "25675",
            name: "Xã Phước Trạch",
            level: "Xã"
          },
          {
            id: "25678",
            name: "Xã Thanh Phước",
            level: "Xã"
          }
        ]
      },
      {
        id: "711",
        name: "Huyện Bến Cầu",
        wards: [
          {
            id: "25681",
            name: "Thị trấn Bến Cầu",
            level: "Thị trấn"
          },
          {
            id: "25684",
            name: "Xã Long Chữ",
            level: "Xã"
          },
          {
            id: "25687",
            name: "Xã Long Phước",
            level: "Xã"
          },
          {
            id: "25690",
            name: "Xã Long Giang",
            level: "Xã"
          },
          {
            id: "25693",
            name: "Xã Tiên Thuận",
            level: "Xã"
          },
          {
            id: "25696",
            name: "Xã Long Khánh",
            level: "Xã"
          },
          {
            id: "25699",
            name: "Xã Lợi Thuận",
            level: "Xã"
          },
          {
            id: "25702",
            name: "Xã Long Thuận",
            level: "Xã"
          },
          {
            id: "25705",
            name: "Xã An Thạnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "712",
        name: "Thị xã Trảng Bàng",
        wards: [
          {
            id: "25708",
            name: "Phường Trảng Bàng",
            level: "Phường"
          },
          {
            id: "25711",
            name: "Xã Đôn Thuận",
            level: "Xã"
          },
          {
            id: "25714",
            name: "Xã Hưng Thuận",
            level: "Xã"
          },
          {
            id: "25717",
            name: "Phường Lộc Hưng",
            level: "Phường"
          },
          {
            id: "25720",
            name: "Phường Gia Lộc",
            level: "Phường"
          },
          {
            id: "25723",
            name: "Phường Gia Bình",
            level: "Phường"
          },
          {
            id: "25729",
            name: "Xã Phước Bình",
            level: "Xã"
          },
          {
            id: "25732",
            name: "Phường An Tịnh",
            level: "Phường"
          },
          {
            id: "25735",
            name: "Phường An Hòa",
            level: "Phường"
          },
          {
            id: "25738",
            name: "Xã Phước Chỉ",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "74",
    name: "Tỉnh Bình Dương",
    districts: [
      {
        id: "718",
        name: "Thành phố Thủ Dầu Một",
        wards: [
          {
            id: "25741",
            name: "Phường Hiệp Thành",
            level: "Phường"
          },
          {
            id: "25744",
            name: "Phường Phú Lợi",
            level: "Phường"
          },
          {
            id: "25747",
            name: "Phường Phú Cường",
            level: "Phường"
          },
          {
            id: "25750",
            name: "Phường Phú Hòa",
            level: "Phường"
          },
          {
            id: "25753",
            name: "Phường Phú Thọ",
            level: "Phường"
          },
          {
            id: "25756",
            name: "Phường Chánh Nghĩa",
            level: "Phường"
          },
          {
            id: "25759",
            name: "Phường Định Hoà",
            level: "Phường"
          },
          {
            id: "25760",
            name: "Phường Hoà Phú",
            level: "Phường"
          },
          {
            id: "25762",
            name: "Phường Phú Mỹ",
            level: "Phường"
          },
          {
            id: "25763",
            name: "Phường Phú Tân",
            level: "Phường"
          },
          {
            id: "25765",
            name: "Phường Tân An",
            level: "Phường"
          },
          {
            id: "25768",
            name: "Phường Hiệp An",
            level: "Phường"
          },
          {
            id: "25771",
            name: "Phường Tương Bình Hiệp",
            level: "Phường"
          },
          {
            id: "25774",
            name: "Phường Chánh Mỹ",
            level: "Phường"
          }
        ]
      },
      {
        id: "719",
        name: "Huyện Bàu Bàng",
        wards: [
          {
            id: "25816",
            name: "Xã Trừ Văn Thố",
            level: "Xã"
          },
          {
            id: "25819",
            name: "Xã Cây Trường II",
            level: "Xã"
          },
          {
            id: "25822",
            name: "Thị trấn Lai Uyên",
            level: "Thị trấn"
          },
          {
            id: "25825",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "25828",
            name: "Xã Long Nguyên",
            level: "Xã"
          },
          {
            id: "25831",
            name: "Xã Hưng Hòa",
            level: "Xã"
          },
          {
            id: "25834",
            name: "Xã Lai Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "720",
        name: "Huyện Dầu Tiếng",
        wards: [
          {
            id: "25777",
            name: "Thị trấn Dầu Tiếng",
            level: "Thị trấn"
          },
          {
            id: "25780",
            name: "Xã Minh Hoà",
            level: "Xã"
          },
          {
            id: "25783",
            name: "Xã Minh Thạnh",
            level: "Xã"
          },
          {
            id: "25786",
            name: "Xã Minh Tân",
            level: "Xã"
          },
          {
            id: "25789",
            name: "Xã Định An",
            level: "Xã"
          },
          {
            id: "25792",
            name: "Xã Long Hoà",
            level: "Xã"
          },
          {
            id: "25795",
            name: "Xã Định Thành",
            level: "Xã"
          },
          {
            id: "25798",
            name: "Xã Định Hiệp",
            level: "Xã"
          },
          {
            id: "25801",
            name: "Xã An Lập",
            level: "Xã"
          },
          {
            id: "25804",
            name: "Xã Long Tân",
            level: "Xã"
          },
          {
            id: "25807",
            name: "Xã Thanh An",
            level: "Xã"
          },
          {
            id: "25810",
            name: "Xã Thanh Tuyền",
            level: "Xã"
          }
        ]
      },
      {
        id: "721",
        name: "Thị xã Bến Cát",
        wards: [
          {
            id: "25813",
            name: "Phường Mỹ Phước",
            level: "Phường"
          },
          {
            id: "25837",
            name: "Phường Chánh Phú Hòa",
            level: "Phường"
          },
          {
            id: "25840",
            name: "Xã An Điền",
            level: "Xã"
          },
          {
            id: "25843",
            name: "Xã An Tây",
            level: "Xã"
          },
          {
            id: "25846",
            name: "Phường Thới Hòa",
            level: "Phường"
          },
          {
            id: "25849",
            name: "Phường Hòa Lợi",
            level: "Phường"
          },
          {
            id: "25852",
            name: "Phường Tân Định",
            level: "Phường"
          },
          {
            id: "25855",
            name: "Xã Phú An",
            level: "Xã"
          }
        ]
      },
      {
        id: "722",
        name: "Huyện Phú Giáo",
        wards: [
          {
            id: "25858",
            name: "Thị trấn Phước Vĩnh",
            level: "Thị trấn"
          },
          {
            id: "25861",
            name: "Xã An Linh",
            level: "Xã"
          },
          {
            id: "25864",
            name: "Xã Phước Sang",
            level: "Xã"
          },
          {
            id: "25865",
            name: "Xã An Thái",
            level: "Xã"
          },
          {
            id: "25867",
            name: "Xã An Long",
            level: "Xã"
          },
          {
            id: "25870",
            name: "Xã An Bình",
            level: "Xã"
          },
          {
            id: "25873",
            name: "Xã Tân Hiệp",
            level: "Xã"
          },
          {
            id: "25876",
            name: "Xã Tam Lập",
            level: "Xã"
          },
          {
            id: "25879",
            name: "Xã Tân Long",
            level: "Xã"
          },
          {
            id: "25882",
            name: "Xã Vĩnh Hoà",
            level: "Xã"
          },
          {
            id: "25885",
            name: "Xã Phước Hoà",
            level: "Xã"
          }
        ]
      },
      {
        id: "723",
        name: "Thị xã Tân Uyên",
        wards: [
          {
            id: "25888",
            name: "Phường Uyên Hưng",
            level: "Phường"
          },
          {
            id: "25891",
            name: "Phường Tân Phước Khánh",
            level: "Phường"
          },
          {
            id: "25912",
            name: "Phường Vĩnh Tân",
            level: "Phường"
          },
          {
            id: "25915",
            name: "Phường Hội Nghĩa",
            level: "Phường"
          },
          {
            id: "25920",
            name: "Phường Tân Hiệp",
            level: "Phường"
          },
          {
            id: "25921",
            name: "Phường Khánh Bình",
            level: "Phường"
          },
          {
            id: "25924",
            name: "Phường Phú Chánh",
            level: "Phường"
          },
          {
            id: "25930",
            name: "Xã Bạch Đằng",
            level: "Xã"
          },
          {
            id: "25933",
            name: "Phường Tân Vĩnh Hiệp",
            level: "Phường"
          },
          {
            id: "25936",
            name: "Phường Thạnh Phước",
            level: "Phường"
          },
          {
            id: "25937",
            name: "Xã Thạnh Hội",
            level: "Xã"
          },
          {
            id: "25939",
            name: "Phường Thái Hòa",
            level: "Phường"
          }
        ]
      },
      {
        id: "724",
        name: "Thành phố Dĩ An",
        wards: [
          {
            id: "25942",
            name: "Phường Dĩ An",
            level: "Phường"
          },
          {
            id: "25945",
            name: "Phường Tân Bình",
            level: "Phường"
          },
          {
            id: "25948",
            name: "Phường Tân Đông Hiệp",
            level: "Phường"
          },
          {
            id: "25951",
            name: "Phường Bình An",
            level: "Phường"
          },
          {
            id: "25954",
            name: "Phường Bình Thắng",
            level: "Phường"
          },
          {
            id: "25957",
            name: "Phường Đông Hòa",
            level: "Phường"
          },
          {
            id: "25960",
            name: "Phường An Bình",
            level: "Phường"
          }
        ]
      },
      {
        id: "725",
        name: "Thành phố Thuận An",
        wards: [
          {
            id: "25963",
            name: "Phường An Thạnh",
            level: "Phường"
          },
          {
            id: "25966",
            name: "Phường Lái Thiêu",
            level: "Phường"
          },
          {
            id: "25969",
            name: "Phường Bình Chuẩn",
            level: "Phường"
          },
          {
            id: "25972",
            name: "Phường Thuận Giao",
            level: "Phường"
          },
          {
            id: "25975",
            name: "Phường An Phú",
            level: "Phường"
          },
          {
            id: "25978",
            name: "Phường Hưng Định",
            level: "Phường"
          },
          {
            id: "25981",
            name: "Xã An Sơn",
            level: "Xã"
          },
          {
            id: "25984",
            name: "Phường Bình Nhâm",
            level: "Phường"
          },
          {
            id: "25987",
            name: "Phường Bình Hòa",
            level: "Phường"
          },
          {
            id: "25990",
            name: "Phường Vĩnh Phú",
            level: "Phường"
          }
        ]
      },
      {
        id: "726",
        name: "Huyện Bắc Tân Uyên",
        wards: [
          {
            id: "25894",
            name: "Xã Tân Định",
            level: "Xã"
          },
          {
            id: "25897",
            name: "Xã Bình Mỹ",
            level: "Xã"
          },
          {
            id: "25900",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "25903",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "25906",
            name: "Thị trấn Tân Thành",
            level: "Thị trấn"
          },
          {
            id: "25907",
            name: "Xã Đất Cuốc",
            level: "Xã"
          },
          {
            id: "25908",
            name: "Xã Hiếu Liêm",
            level: "Xã"
          },
          {
            id: "25909",
            name: "Xã Lạc An",
            level: "Xã"
          },
          {
            id: "25918",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "25927",
            name: "Xã Thường Tân",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "75",
    name: "Tỉnh Đồng Nai",
    districts: [
      {
        id: "731",
        name: "Thành phố Biên Hòa",
        wards: [
          {
            id: "25993",
            name: "Phường Trảng Dài",
            level: "Phường"
          },
          {
            id: "25996",
            name: "Phường Tân Phong",
            level: "Phường"
          },
          {
            id: "25999",
            name: "Phường Tân Biên",
            level: "Phường"
          },
          {
            id: "26002",
            name: "Phường Hố Nai",
            level: "Phường"
          },
          {
            id: "26005",
            name: "Phường Tân Hòa",
            level: "Phường"
          },
          {
            id: "26008",
            name: "Phường Tân Hiệp",
            level: "Phường"
          },
          {
            id: "26011",
            name: "Phường Bửu Long",
            level: "Phường"
          },
          {
            id: "26014",
            name: "Phường Tân Tiến",
            level: "Phường"
          },
          {
            id: "26017",
            name: "Phường Tam Hiệp",
            level: "Phường"
          },
          {
            id: "26020",
            name: "Phường Long Bình",
            level: "Phường"
          },
          {
            id: "26023",
            name: "Phường Quang Vinh",
            level: "Phường"
          },
          {
            id: "26026",
            name: "Phường Tân Mai",
            level: "Phường"
          },
          {
            id: "26029",
            name: "Phường Thống Nhất",
            level: "Phường"
          },
          {
            id: "26032",
            name: "Phường Trung Dũng",
            level: "Phường"
          },
          {
            id: "26035",
            name: "Phường Tam Hòa",
            level: "Phường"
          },
          {
            id: "26038",
            name: "Phường Hòa Bình",
            level: "Phường"
          },
          {
            id: "26041",
            name: "Phường Quyết Thắng",
            level: "Phường"
          },
          {
            id: "26044",
            name: "Phường Thanh Bình",
            level: "Phường"
          },
          {
            id: "26047",
            name: "Phường Bình Đa",
            level: "Phường"
          },
          {
            id: "26050",
            name: "Phường An Bình",
            level: "Phường"
          },
          {
            id: "26053",
            name: "Phường Bửu Hòa",
            level: "Phường"
          },
          {
            id: "26056",
            name: "Phường Long Bình Tân",
            level: "Phường"
          },
          {
            id: "26059",
            name: "Phường Tân Vạn",
            level: "Phường"
          },
          {
            id: "26062",
            name: "Phường Tân Hạnh",
            level: "Phường"
          },
          {
            id: "26065",
            name: "Phường Hiệp Hòa",
            level: "Phường"
          },
          {
            id: "26068",
            name: "Phường Hóa An",
            level: "Phường"
          },
          {
            id: "26371",
            name: "Phường An Hòa",
            level: "Phường"
          },
          {
            id: "26374",
            name: "Phường Tam Phước",
            level: "Phường"
          },
          {
            id: "26377",
            name: "Phường Phước Tân",
            level: "Phường"
          },
          {
            id: "26380",
            name: "Xã Long Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "732",
        name: "Thành phố Long Khánh",
        wards: [
          {
            id: "26071",
            name: "Phường Xuân Trung",
            level: "Phường"
          },
          {
            id: "26074",
            name: "Phường Xuân Thanh",
            level: "Phường"
          },
          {
            id: "26077",
            name: "Phường Xuân Bình",
            level: "Phường"
          },
          {
            id: "26080",
            name: "Phường Xuân An",
            level: "Phường"
          },
          {
            id: "26083",
            name: "Phường Xuân Hoà",
            level: "Phường"
          },
          {
            id: "26086",
            name: "Phường Phú Bình",
            level: "Phường"
          },
          {
            id: "26089",
            name: "Xã Bình Lộc",
            level: "Xã"
          },
          {
            id: "26092",
            name: "Xã Bảo Quang",
            level: "Xã"
          },
          {
            id: "26095",
            name: "Phường Suối Tre",
            level: "Phường"
          },
          {
            id: "26098",
            name: "Phường Bảo Vinh",
            level: "Phường"
          },
          {
            id: "26101",
            name: "Phường Xuân Lập",
            level: "Phường"
          },
          {
            id: "26104",
            name: "Phường Bàu Sen",
            level: "Phường"
          },
          {
            id: "26107",
            name: "Xã Bàu Trâm",
            level: "Xã"
          },
          {
            id: "26110",
            name: "Phường Xuân Tân",
            level: "Phường"
          },
          {
            id: "26113",
            name: "Xã Hàng Gòn",
            level: "Xã"
          }
        ]
      },
      {
        id: "734",
        name: "Huyện Tân Phú",
        wards: [
          {
            id: "26116",
            name: "Thị trấn Tân Phú",
            level: "Thị trấn"
          },
          {
            id: "26119",
            name: "Xã Dak Lua",
            level: "Xã"
          },
          {
            id: "26122",
            name: "Xã Nam Cát Tiên",
            level: "Xã"
          },
          {
            id: "26125",
            name: "Xã Phú An",
            level: "Xã"
          },
          {
            id: "26128",
            name: "Xã Núi Tượng",
            level: "Xã"
          },
          {
            id: "26131",
            name: "Xã Tà Lài",
            level: "Xã"
          },
          {
            id: "26134",
            name: "Xã Phú Lập",
            level: "Xã"
          },
          {
            id: "26137",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "26140",
            name: "Xã Phú Thịnh",
            level: "Xã"
          },
          {
            id: "26143",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "26146",
            name: "Xã Phú Trung",
            level: "Xã"
          },
          {
            id: "26149",
            name: "Xã Phú Xuân",
            level: "Xã"
          },
          {
            id: "26152",
            name: "Xã Phú Lộc",
            level: "Xã"
          },
          {
            id: "26155",
            name: "Xã Phú Lâm",
            level: "Xã"
          },
          {
            id: "26158",
            name: "Xã Phú Bình",
            level: "Xã"
          },
          {
            id: "26161",
            name: "Xã Phú Thanh",
            level: "Xã"
          },
          {
            id: "26164",
            name: "Xã Trà Cổ",
            level: "Xã"
          },
          {
            id: "26167",
            name: "Xã Phú Điền",
            level: "Xã"
          }
        ]
      },
      {
        id: "735",
        name: "Huyện Vĩnh Cửu",
        wards: [
          {
            id: "26170",
            name: "Thị trấn Vĩnh An",
            level: "Thị trấn"
          },
          {
            id: "26173",
            name: "Xã Phú Lý",
            level: "Xã"
          },
          {
            id: "26176",
            name: "Xã Trị An",
            level: "Xã"
          },
          {
            id: "26179",
            name: "Xã Tân An",
            level: "Xã"
          },
          {
            id: "26182",
            name: "Xã Vĩnh Tân",
            level: "Xã"
          },
          {
            id: "26185",
            name: "Xã Bình Lợi",
            level: "Xã"
          },
          {
            id: "26188",
            name: "Xã Thạnh Phú",
            level: "Xã"
          },
          {
            id: "26191",
            name: "Xã Thiện Tân",
            level: "Xã"
          },
          {
            id: "26194",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "26197",
            name: "Xã Bình Hòa",
            level: "Xã"
          },
          {
            id: "26200",
            name: "Xã Mã Đà",
            level: "Xã"
          },
          {
            id: "26203",
            name: "Xã Hiếu Liêm",
            level: "Xã"
          }
        ]
      },
      {
        id: "736",
        name: "Huyện Định Quán",
        wards: [
          {
            id: "26206",
            name: "Thị trấn Định Quán",
            level: "Thị trấn"
          },
          {
            id: "26209",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "26212",
            name: "Xã Phú Tân",
            level: "Xã"
          },
          {
            id: "26215",
            name: "Xã Phú Vinh",
            level: "Xã"
          },
          {
            id: "26218",
            name: "Xã Phú Lợi",
            level: "Xã"
          },
          {
            id: "26221",
            name: "Xã Phú Hòa",
            level: "Xã"
          },
          {
            id: "26224",
            name: "Xã Ngọc Định",
            level: "Xã"
          },
          {
            id: "26227",
            name: "Xã La Ngà",
            level: "Xã"
          },
          {
            id: "26230",
            name: "Xã Gia Canh",
            level: "Xã"
          },
          {
            id: "26233",
            name: "Xã Phú Ngọc",
            level: "Xã"
          },
          {
            id: "26236",
            name: "Xã Phú Cường",
            level: "Xã"
          },
          {
            id: "26239",
            name: "Xã Túc Trưng",
            level: "Xã"
          },
          {
            id: "26242",
            name: "Xã Phú Túc",
            level: "Xã"
          },
          {
            id: "26245",
            name: "Xã Suối Nho",
            level: "Xã"
          }
        ]
      },
      {
        id: "737",
        name: "Huyện Trảng Bom",
        wards: [
          {
            id: "26248",
            name: "Thị trấn Trảng Bom",
            level: "Thị trấn"
          },
          {
            id: "26251",
            name: "Xã Thanh Bình",
            level: "Xã"
          },
          {
            id: "26254",
            name: "Xã Cây Gáo",
            level: "Xã"
          },
          {
            id: "26257",
            name: "Xã Bàu Hàm",
            level: "Xã"
          },
          {
            id: "26260",
            name: "Xã Sông Thao",
            level: "Xã"
          },
          {
            id: "26263",
            name: "Xã Sông Trầu",
            level: "Xã"
          },
          {
            id: "26266",
            name: "Xã Đông Hoà",
            level: "Xã"
          },
          {
            id: "26269",
            name: "Xã Bắc Sơn",
            level: "Xã"
          },
          {
            id: "26272",
            name: "Xã Hố Nai 3",
            level: "Xã"
          },
          {
            id: "26275",
            name: "Xã Tây Hoà",
            level: "Xã"
          },
          {
            id: "26278",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "26281",
            name: "Xã Trung Hoà",
            level: "Xã"
          },
          {
            id: "26284",
            name: "Xã Đồi 61",
            level: "Xã"
          },
          {
            id: "26287",
            name: "Xã Hưng Thịnh",
            level: "Xã"
          },
          {
            id: "26290",
            name: "Xã Quảng Tiến",
            level: "Xã"
          },
          {
            id: "26293",
            name: "Xã Giang Điền",
            level: "Xã"
          },
          {
            id: "26296",
            name: "Xã An Viễn",
            level: "Xã"
          }
        ]
      },
      {
        id: "738",
        name: "Huyện Thống Nhất",
        wards: [
          {
            id: "26299",
            name: "Xã Gia Tân 1",
            level: "Xã"
          },
          {
            id: "26302",
            name: "Xã Gia Tân 2",
            level: "Xã"
          },
          {
            id: "26305",
            name: "Xã Gia Tân 3",
            level: "Xã"
          },
          {
            id: "26308",
            name: "Xã Gia Kiệm",
            level: "Xã"
          },
          {
            id: "26311",
            name: "Xã Quang Trung",
            level: "Xã"
          },
          {
            id: "26314",
            name: "Xã Bàu Hàm 2",
            level: "Xã"
          },
          {
            id: "26317",
            name: "Xã Hưng Lộc",
            level: "Xã"
          },
          {
            id: "26320",
            name: "Xã Lộ 25",
            level: "Xã"
          },
          {
            id: "26323",
            name: "Xã Xuân Thiện",
            level: "Xã"
          },
          {
            id: "26326",
            name: "Thị trấn Dầu Giây",
            level: "Thị trấn"
          }
        ]
      },
      {
        id: "739",
        name: "Huyện Cẩm Mỹ",
        wards: [
          {
            id: "26329",
            name: "Xã Sông Nhạn",
            level: "Xã"
          },
          {
            id: "26332",
            name: "Xã Xuân Quế",
            level: "Xã"
          },
          {
            id: "26335",
            name: "Xã Nhân Nghĩa",
            level: "Xã"
          },
          {
            id: "26338",
            name: "Xã Xuân Đường",
            level: "Xã"
          },
          {
            id: "26341",
            name: "Xã Long Giao",
            level: "Xã"
          },
          {
            id: "26344",
            name: "Xã Xuân Mỹ",
            level: "Xã"
          },
          {
            id: "26347",
            name: "Xã Thừa Đức",
            level: "Xã"
          },
          {
            id: "26350",
            name: "Xã Bảo Bình",
            level: "Xã"
          },
          {
            id: "26353",
            name: "Xã Xuân Bảo",
            level: "Xã"
          },
          {
            id: "26356",
            name: "Xã Xuân Tây",
            level: "Xã"
          },
          {
            id: "26359",
            name: "Xã Xuân Đông",
            level: "Xã"
          },
          {
            id: "26362",
            name: "Xã Sông Ray",
            level: "Xã"
          },
          {
            id: "26365",
            name: "Xã Lâm San",
            level: "Xã"
          }
        ]
      },
      {
        id: "740",
        name: "Huyện Long Thành",
        wards: [
          {
            id: "26368",
            name: "Thị trấn Long Thành",
            level: "Thị trấn"
          },
          {
            id: "26383",
            name: "Xã An Phước",
            level: "Xã"
          },
          {
            id: "26386",
            name: "Xã Bình An",
            level: "Xã"
          },
          {
            id: "26389",
            name: "Xã Long Đức",
            level: "Xã"
          },
          {
            id: "26392",
            name: "Xã Lộc An",
            level: "Xã"
          },
          {
            id: "26395",
            name: "Xã Bình Sơn",
            level: "Xã"
          },
          {
            id: "26398",
            name: "Xã Tam An",
            level: "Xã"
          },
          {
            id: "26401",
            name: "Xã Cẩm Đường",
            level: "Xã"
          },
          {
            id: "26404",
            name: "Xã Long An",
            level: "Xã"
          },
          {
            id: "26410",
            name: "Xã Bàu Cạn",
            level: "Xã"
          },
          {
            id: "26413",
            name: "Xã Long Phước",
            level: "Xã"
          },
          {
            id: "26416",
            name: "Xã Phước Bình",
            level: "Xã"
          },
          {
            id: "26419",
            name: "Xã Tân Hiệp",
            level: "Xã"
          },
          {
            id: "26422",
            name: "Xã Phước Thái",
            level: "Xã"
          }
        ]
      },
      {
        id: "741",
        name: "Huyện Xuân Lộc",
        wards: [
          {
            id: "26425",
            name: "Thị trấn Gia Ray",
            level: "Thị trấn"
          },
          {
            id: "26428",
            name: "Xã Xuân Bắc",
            level: "Xã"
          },
          {
            id: "26431",
            name: "Xã Suối Cao",
            level: "Xã"
          },
          {
            id: "26434",
            name: "Xã Xuân Thành",
            level: "Xã"
          },
          {
            id: "26437",
            name: "Xã Xuân Thọ",
            level: "Xã"
          },
          {
            id: "26440",
            name: "Xã Xuân Trường",
            level: "Xã"
          },
          {
            id: "26443",
            name: "Xã Xuân Hòa",
            level: "Xã"
          },
          {
            id: "26446",
            name: "Xã Xuân Hưng",
            level: "Xã"
          },
          {
            id: "26449",
            name: "Xã Xuân Tâm",
            level: "Xã"
          },
          {
            id: "26452",
            name: "Xã Suối Cát",
            level: "Xã"
          },
          {
            id: "26455",
            name: "Xã Xuân Hiệp",
            level: "Xã"
          },
          {
            id: "26458",
            name: "Xã Xuân Phú",
            level: "Xã"
          },
          {
            id: "26461",
            name: "Xã Xuân Định",
            level: "Xã"
          },
          {
            id: "26464",
            name: "Xã Bảo Hoà",
            level: "Xã"
          },
          {
            id: "26467",
            name: "Xã Lang Minh",
            level: "Xã"
          }
        ]
      },
      {
        id: "742",
        name: "Huyện Nhơn Trạch",
        wards: [
          {
            id: "26470",
            name: "Xã Phước Thiền",
            level: "Xã"
          },
          {
            id: "26473",
            name: "Xã Long Tân",
            level: "Xã"
          },
          {
            id: "26476",
            name: "Xã Đại Phước",
            level: "Xã"
          },
          {
            id: "26479",
            name: "Thị trấn Hiệp Phước",
            level: "Thị trấn"
          },
          {
            id: "26482",
            name: "Xã Phú Hữu",
            level: "Xã"
          },
          {
            id: "26485",
            name: "Xã Phú Hội",
            level: "Xã"
          },
          {
            id: "26488",
            name: "Xã Phú Thạnh",
            level: "Xã"
          },
          {
            id: "26491",
            name: "Xã Phú Đông",
            level: "Xã"
          },
          {
            id: "26494",
            name: "Xã Long Thọ",
            level: "Xã"
          },
          {
            id: "26497",
            name: "Xã Vĩnh Thanh",
            level: "Xã"
          },
          {
            id: "26500",
            name: "Xã Phước Khánh",
            level: "Xã"
          },
          {
            id: "26503",
            name: "Xã Phước An",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "77",
    name: "Tỉnh Bà Rịa - Vũng Tàu",
    districts: [
      {
        id: "747",
        name: "Thành phố Vũng Tàu",
        wards: [
          {
            id: "26506",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "26508",
            name: "Phường Thắng Tam",
            level: "Phường"
          },
          {
            id: "26509",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "26512",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "26515",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "26518",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "26521",
            name: "Phường Thắng Nhì",
            level: "Phường"
          },
          {
            id: "26524",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "26526",
            name: "Phường Nguyễn An Ninh",
            level: "Phường"
          },
          {
            id: "26527",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "26530",
            name: "Phường 9",
            level: "Phường"
          },
          {
            id: "26533",
            name: "Phường Thắng Nhất",
            level: "Phường"
          },
          {
            id: "26535",
            name: "Phường Rạch Dừa",
            level: "Phường"
          },
          {
            id: "26536",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "26539",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "26542",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "26545",
            name: "Xã Long Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "748",
        name: "Thành phố Bà Rịa",
        wards: [
          {
            id: "26548",
            name: "Phường Phước Hưng",
            level: "Phường"
          },
          {
            id: "26551",
            name: "Phường Phước Hiệp",
            level: "Phường"
          },
          {
            id: "26554",
            name: "Phường Phước Nguyên",
            level: "Phường"
          },
          {
            id: "26557",
            name: "Phường Long Toàn",
            level: "Phường"
          },
          {
            id: "26558",
            name: "Phường Long Tâm",
            level: "Phường"
          },
          {
            id: "26560",
            name: "Phường Phước Trung",
            level: "Phường"
          },
          {
            id: "26563",
            name: "Phường Long Hương",
            level: "Phường"
          },
          {
            id: "26566",
            name: "Phường Kim Dinh",
            level: "Phường"
          },
          {
            id: "26567",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "26569",
            name: "Xã Long Phước",
            level: "Xã"
          },
          {
            id: "26572",
            name: "Xã Hoà Long",
            level: "Xã"
          }
        ]
      },
      {
        id: "750",
        name: "Huyện Châu Đức",
        wards: [
          {
            id: "26574",
            name: "Xã Bàu Chinh",
            level: "Xã"
          },
          {
            id: "26575",
            name: "Thị trấn Ngãi Giao",
            level: "Thị trấn"
          },
          {
            id: "26578",
            name: "Xã Bình Ba",
            level: "Xã"
          },
          {
            id: "26581",
            name: "Xã Suối Nghệ",
            level: "Xã"
          },
          {
            id: "26584",
            name: "Xã Xuân Sơn",
            level: "Xã"
          },
          {
            id: "26587",
            name: "Xã Sơn Bình",
            level: "Xã"
          },
          {
            id: "26590",
            name: "Xã Bình Giã",
            level: "Xã"
          },
          {
            id: "26593",
            name: "Xã Bình Trung",
            level: "Xã"
          },
          {
            id: "26596",
            name: "Xã Xà Bang",
            level: "Xã"
          },
          {
            id: "26599",
            name: "Xã Cù Bị",
            level: "Xã"
          },
          {
            id: "26602",
            name: "Xã Láng Lớn",
            level: "Xã"
          },
          {
            id: "26605",
            name: "Xã Quảng Thành",
            level: "Xã"
          },
          {
            id: "26608",
            name: "Xã Kim Long",
            level: "Xã"
          },
          {
            id: "26611",
            name: "Xã Suối Rao",
            level: "Xã"
          },
          {
            id: "26614",
            name: "Xã Đá Bạc",
            level: "Xã"
          },
          {
            id: "26617",
            name: "Xã Nghĩa Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "751",
        name: "Huyện Xuyên Mộc",
        wards: [
          {
            id: "26620",
            name: "Thị trấn Phước Bửu",
            level: "Thị trấn"
          },
          {
            id: "26623",
            name: "Xã Phước Thuận",
            level: "Xã"
          },
          {
            id: "26626",
            name: "Xã Phước Tân",
            level: "Xã"
          },
          {
            id: "26629",
            name: "Xã Xuyên Mộc",
            level: "Xã"
          },
          {
            id: "26632",
            name: "Xã Bông Trang",
            level: "Xã"
          },
          {
            id: "26635",
            name: "Xã Tân Lâm",
            level: "Xã"
          },
          {
            id: "26638",
            name: "Xã Bàu Lâm",
            level: "Xã"
          },
          {
            id: "26641",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "26644",
            name: "Xã Hòa Hưng",
            level: "Xã"
          },
          {
            id: "26647",
            name: "Xã Hòa Hiệp",
            level: "Xã"
          },
          {
            id: "26650",
            name: "Xã Hòa Hội",
            level: "Xã"
          },
          {
            id: "26653",
            name: "Xã Bưng Riềng",
            level: "Xã"
          },
          {
            id: "26656",
            name: "Xã Bình Châu",
            level: "Xã"
          }
        ]
      },
      {
        id: "752",
        name: "Huyện Long Điền",
        wards: [
          {
            id: "26659",
            name: "Thị trấn Long Điền",
            level: "Thị trấn"
          },
          {
            id: "26662",
            name: "Thị trấn Long Hải",
            level: "Thị trấn"
          },
          {
            id: "26665",
            name: "Xã An Ngãi",
            level: "Xã"
          },
          {
            id: "26668",
            name: "Xã Tam Phước",
            level: "Xã"
          },
          {
            id: "26671",
            name: "Xã An Nhứt",
            level: "Xã"
          },
          {
            id: "26674",
            name: "Xã Phước Tỉnh",
            level: "Xã"
          },
          {
            id: "26677",
            name: "Xã Phước Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "753",
        name: "Huyện Đất Đỏ",
        wards: [
          {
            id: "26680",
            name: "Thị trấn Đất Đỏ",
            level: "Thị trấn"
          },
          {
            id: "26683",
            name: "Xã Phước Long Thọ",
            level: "Xã"
          },
          {
            id: "26686",
            name: "Xã Phước Hội",
            level: "Xã"
          },
          {
            id: "26689",
            name: "Xã Long Mỹ",
            level: "Xã"
          },
          {
            id: "26692",
            name: "Thị trấn Phước Hải",
            level: "Thị trấn"
          },
          {
            id: "26695",
            name: "Xã Long Tân",
            level: "Xã"
          },
          {
            id: "26698",
            name: "Xã Láng Dài",
            level: "Xã"
          },
          {
            id: "26701",
            name: "Xã Lộc An",
            level: "Xã"
          }
        ]
      },
      {
        id: "754",
        name: "Thị xã Phú Mỹ",
        wards: [
          {
            id: "26704",
            name: "Phường Phú Mỹ",
            level: "Phường"
          },
          {
            id: "26707",
            name: "Xã Tân Hoà",
            level: "Xã"
          },
          {
            id: "26710",
            name: "Xã Tân Hải",
            level: "Xã"
          },
          {
            id: "26713",
            name: "Phường Phước Hoà",
            level: "Phường"
          },
          {
            id: "26716",
            name: "Phường Tân Phước",
            level: "Phường"
          },
          {
            id: "26719",
            name: "Phường Mỹ Xuân",
            level: "Phường"
          },
          {
            id: "26722",
            name: "Xã Sông Xoài",
            level: "Xã"
          },
          {
            id: "26725",
            name: "Phường Hắc Dịch",
            level: "Phường"
          },
          {
            id: "26728",
            name: "Xã Châu Pha",
            level: "Xã"
          },
          {
            id: "26731",
            name: "Xã Tóc Tiên",
            level: "Xã"
          }
        ]
      },
      {
        id: "755",
        name: "Huyện Côn Đảo",
        wards: [
          {
            level: "Huyện"
          }
        ]
      }
    ]
  },
  {
    id: "79",
    name: "Thành phố Hồ Chí Minh",
    districts: [
      {
        id: "760",
        name: "Quận 1",
        wards: [
          {
            id: "26734",
            name: "Phường Tân Định",
            level: "Phường"
          },
          {
            id: "26737",
            name: "Phường Đa Kao",
            level: "Phường"
          },
          {
            id: "26740",
            name: "Phường Bến Nghé",
            level: "Phường"
          },
          {
            id: "26743",
            name: "Phường Bến Thành",
            level: "Phường"
          },
          {
            id: "26746",
            name: "Phường Nguyễn Thái Bình",
            level: "Phường"
          },
          {
            id: "26749",
            name: "Phường Phạm Ngũ Lão",
            level: "Phường"
          },
          {
            id: "26752",
            name: "Phường Cầu Ông Lãnh",
            level: "Phường"
          },
          {
            id: "26755",
            name: "Phường Cô Giang",
            level: "Phường"
          },
          {
            id: "26758",
            name: "Phường Nguyễn Cư Trinh",
            level: "Phường"
          },
          {
            id: "26761",
            name: "Phường Cầu Kho",
            level: "Phường"
          }
        ]
      },
      {
        id: "761",
        name: "Quận 12",
        wards: [
          {
            id: "26764",
            name: "Phường Thạnh Xuân",
            level: "Phường"
          },
          {
            id: "26767",
            name: "Phường Thạnh Lộc",
            level: "Phường"
          },
          {
            id: "26770",
            name: "Phường Hiệp Thành",
            level: "Phường"
          },
          {
            id: "26773",
            name: "Phường Thới An",
            level: "Phường"
          },
          {
            id: "26776",
            name: "Phường Tân Chánh Hiệp",
            level: "Phường"
          },
          {
            id: "26779",
            name: "Phường An Phú Đông",
            level: "Phường"
          },
          {
            id: "26782",
            name: "Phường Tân Thới Hiệp",
            level: "Phường"
          },
          {
            id: "26785",
            name: "Phường Trung Mỹ Tây",
            level: "Phường"
          },
          {
            id: "26787",
            name: "Phường Tân Hưng Thuận",
            level: "Phường"
          },
          {
            id: "26788",
            name: "Phường Đông Hưng Thuận",
            level: "Phường"
          },
          {
            id: "26791",
            name: "Phường Tân Thới Nhất",
            level: "Phường"
          }
        ]
      },
      {
        id: "762",
        name: "Quận Thủ Đức",
        wards: [
          {
            id: "26794",
            name: "Phường Linh Xuân",
            level: "Phường"
          },
          {
            id: "26797",
            name: "Phường Bình Chiểu",
            level: "Phường"
          },
          {
            id: "26800",
            name: "Phường Linh Trung",
            level: "Phường"
          },
          {
            id: "26803",
            name: "Phường Tam Bình",
            level: "Phường"
          },
          {
            id: "26806",
            name: "Phường Tam Phú",
            level: "Phường"
          },
          {
            id: "26809",
            name: "Phường Hiệp Bình Phước",
            level: "Phường"
          },
          {
            id: "26812",
            name: "Phường Hiệp Bình Chánh",
            level: "Phường"
          },
          {
            id: "26815",
            name: "Phường Linh Chiểu",
            level: "Phường"
          },
          {
            id: "26818",
            name: "Phường Linh Tây",
            level: "Phường"
          },
          {
            id: "26821",
            name: "Phường Linh Đông",
            level: "Phường"
          },
          {
            id: "26824",
            name: "Phường Bình Thọ",
            level: "Phường"
          },
          {
            id: "26827",
            name: "Phường Trường Thọ",
            level: "Phường"
          }
        ]
      },
      {
        id: "763",
        name: "Quận 9",
        wards: [
          {
            id: "26830",
            name: "Phường Long Bình",
            level: "Phường"
          },
          {
            id: "26833",
            name: "Phường Long Thạnh Mỹ",
            level: "Phường"
          },
          {
            id: "26836",
            name: "Phường Tân Phú",
            level: "Phường"
          },
          {
            id: "26839",
            name: "Phường Hiệp Phú",
            level: "Phường"
          },
          {
            id: "26842",
            name: "Phường Tăng Nhơn Phú A",
            level: "Phường"
          },
          {
            id: "26845",
            name: "Phường Tăng Nhơn Phú B",
            level: "Phường"
          },
          {
            id: "26848",
            name: "Phường Phước Long B",
            level: "Phường"
          },
          {
            id: "26851",
            name: "Phường Phước Long A",
            level: "Phường"
          },
          {
            id: "26854",
            name: "Phường Trường Thạnh",
            level: "Phường"
          },
          {
            id: "26857",
            name: "Phường Long Phước",
            level: "Phường"
          },
          {
            id: "26860",
            name: "Phường Long Trường",
            level: "Phường"
          },
          {
            id: "26863",
            name: "Phường Phước Bình",
            level: "Phường"
          },
          {
            id: "26866",
            name: "Phường Phú Hữu",
            level: "Phường"
          }
        ]
      },
      {
        id: "764",
        name: "Quận Gò Vấp",
        wards: [
          {
            id: "26869",
            name: "Phường 15",
            level: "Phường"
          },
          {
            id: "26872",
            name: "Phường 13",
            level: "Phường"
          },
          {
            id: "26875",
            name: "Phường 17",
            level: "Phường"
          },
          {
            id: "26876",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "26878",
            name: "Phường 16",
            level: "Phường"
          },
          {
            id: "26881",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "26882",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "26884",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "26887",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "26890",
            name: "Phường 07",
            level: "Phường"
          },
          {
            id: "26893",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "26896",
            name: "Phường 01",
            level: "Phường"
          },
          {
            id: "26897",
            name: "Phường 9",
            level: "Phường"
          },
          {
            id: "26898",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "26899",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "26902",
            name: "Phường 03",
            level: "Phường"
          }
        ]
      },
      {
        id: "765",
        name: "Quận Bình Thạnh",
        wards: [
          {
            id: "26905",
            name: "Phường 13",
            level: "Phường"
          },
          {
            id: "26908",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "26911",
            name: "Phường 27",
            level: "Phường"
          },
          {
            id: "26914",
            name: "Phường 26",
            level: "Phường"
          },
          {
            id: "26917",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "26920",
            name: "Phường 25",
            level: "Phường"
          },
          {
            id: "26923",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "26926",
            name: "Phường 07",
            level: "Phường"
          },
          {
            id: "26929",
            name: "Phường 24",
            level: "Phường"
          },
          {
            id: "26932",
            name: "Phường 06",
            level: "Phường"
          },
          {
            id: "26935",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "26938",
            name: "Phường 15",
            level: "Phường"
          },
          {
            id: "26941",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "26944",
            name: "Phường 01",
            level: "Phường"
          },
          {
            id: "26947",
            name: "Phường 03",
            level: "Phường"
          },
          {
            id: "26950",
            name: "Phường 17",
            level: "Phường"
          },
          {
            id: "26953",
            name: "Phường 21",
            level: "Phường"
          },
          {
            id: "26956",
            name: "Phường 22",
            level: "Phường"
          },
          {
            id: "26959",
            name: "Phường 19",
            level: "Phường"
          },
          {
            id: "26962",
            name: "Phường 28",
            level: "Phường"
          }
        ]
      },
      {
        id: "766",
        name: "Quận Tân Bình",
        wards: [
          {
            id: "26965",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "26968",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "26971",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "26974",
            name: "Phường 13",
            level: "Phường"
          },
          {
            id: "26977",
            name: "Phường 01",
            level: "Phường"
          },
          {
            id: "26980",
            name: "Phường 03",
            level: "Phường"
          },
          {
            id: "26983",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "26986",
            name: "Phường 07",
            level: "Phường"
          },
          {
            id: "26989",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "26992",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "26995",
            name: "Phường 06",
            level: "Phường"
          },
          {
            id: "26998",
            name: "Phường 08",
            level: "Phường"
          },
          {
            id: "27001",
            name: "Phường 09",
            level: "Phường"
          },
          {
            id: "27004",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "27007",
            name: "Phường 15",
            level: "Phường"
          }
        ]
      },
      {
        id: "767",
        name: "Quận Tân Phú",
        wards: [
          {
            id: "27010",
            name: "Phường Tân Sơn Nhì",
            level: "Phường"
          },
          {
            id: "27013",
            name: "Phường Tây Thạnh",
            level: "Phường"
          },
          {
            id: "27016",
            name: "Phường Sơn Kỳ",
            level: "Phường"
          },
          {
            id: "27019",
            name: "Phường Tân Quý",
            level: "Phường"
          },
          {
            id: "27022",
            name: "Phường Tân Thành",
            level: "Phường"
          },
          {
            id: "27025",
            name: "Phường Phú Thọ Hòa",
            level: "Phường"
          },
          {
            id: "27028",
            name: "Phường Phú Thạnh",
            level: "Phường"
          },
          {
            id: "27031",
            name: "Phường Phú Trung",
            level: "Phường"
          },
          {
            id: "27034",
            name: "Phường Hòa Thạnh",
            level: "Phường"
          },
          {
            id: "27037",
            name: "Phường Hiệp Tân",
            level: "Phường"
          },
          {
            id: "27040",
            name: "Phường Tân Thới Hòa",
            level: "Phường"
          }
        ]
      },
      {
        id: "768",
        name: "Quận Phú Nhuận",
        wards: [
          {
            id: "27043",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "27046",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "27049",
            name: "Phường 09",
            level: "Phường"
          },
          {
            id: "27052",
            name: "Phường 07",
            level: "Phường"
          },
          {
            id: "27055",
            name: "Phường 03",
            level: "Phường"
          },
          {
            id: "27058",
            name: "Phường 01",
            level: "Phường"
          },
          {
            id: "27061",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "27064",
            name: "Phường 08",
            level: "Phường"
          },
          {
            id: "27067",
            name: "Phường 15",
            level: "Phường"
          },
          {
            id: "27070",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "27073",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "27076",
            name: "Phường 17",
            level: "Phường"
          },
          {
            id: "27079",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "27082",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "27085",
            name: "Phường 13",
            level: "Phường"
          }
        ]
      },
      {
        id: "769",
        name: "Quận 2",
        wards: [
          {
            id: "27088",
            name: "Phường Thảo Điền",
            level: "Phường"
          },
          {
            id: "27091",
            name: "Phường An Phú",
            level: "Phường"
          },
          {
            id: "27094",
            name: "Phường Bình An",
            level: "Phường"
          },
          {
            id: "27097",
            name: "Phường Bình Trưng Đông",
            level: "Phường"
          },
          {
            id: "27100",
            name: "Phường Bình Trưng Tây",
            level: "Phường"
          },
          {
            id: "27103",
            name: "Phường Bình Khánh",
            level: "Phường"
          },
          {
            id: "27106",
            name: "Phường An Khánh",
            level: "Phường"
          },
          {
            id: "27109",
            name: "Phường Cát Lái",
            level: "Phường"
          },
          {
            id: "27112",
            name: "Phường Thạnh Mỹ Lợi",
            level: "Phường"
          },
          {
            id: "27115",
            name: "Phường An Lợi Đông",
            level: "Phường"
          },
          {
            id: "27118",
            name: "Phường Thủ Thiêm",
            level: "Phường"
          }
        ]
      },
      {
        id: "770",
        name: "Quận 3",
        wards: [
          {
            id: "27121",
            name: "Phường 08",
            level: "Phường"
          },
          {
            id: "27124",
            name: "Phường 07",
            level: "Phường"
          },
          {
            id: "27127",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "27130",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "27133",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "27136",
            name: "Phường 13",
            level: "Phường"
          },
          {
            id: "27139",
            name: "Phường 06",
            level: "Phường"
          },
          {
            id: "27142",
            name: "Phường 09",
            level: "Phường"
          },
          {
            id: "27145",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "27148",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "27151",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "27154",
            name: "Phường 03",
            level: "Phường"
          },
          {
            id: "27157",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "27160",
            name: "Phường 01",
            level: "Phường"
          }
        ]
      },
      {
        id: "771",
        name: "Quận 10",
        wards: [
          {
            id: "27163",
            name: "Phường 15",
            level: "Phường"
          },
          {
            id: "27166",
            name: "Phường 13",
            level: "Phường"
          },
          {
            id: "27169",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "27172",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "27175",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "27178",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "27181",
            name: "Phường 09",
            level: "Phường"
          },
          {
            id: "27184",
            name: "Phường 01",
            level: "Phường"
          },
          {
            id: "27187",
            name: "Phường 08",
            level: "Phường"
          },
          {
            id: "27190",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "27193",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "27196",
            name: "Phường 07",
            level: "Phường"
          },
          {
            id: "27199",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "27202",
            name: "Phường 06",
            level: "Phường"
          },
          {
            id: "27205",
            name: "Phường 03",
            level: "Phường"
          }
        ]
      },
      {
        id: "772",
        name: "Quận 11",
        wards: [
          {
            id: "27208",
            name: "Phường 15",
            level: "Phường"
          },
          {
            id: "27211",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "27214",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "27217",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "27220",
            name: "Phường 03",
            level: "Phường"
          },
          {
            id: "27223",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "27226",
            name: "Phường 13",
            level: "Phường"
          },
          {
            id: "27229",
            name: "Phường 08",
            level: "Phường"
          },
          {
            id: "27232",
            name: "Phường 09",
            level: "Phường"
          },
          {
            id: "27235",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "27238",
            name: "Phường 07",
            level: "Phường"
          },
          {
            id: "27241",
            name: "Phường 06",
            level: "Phường"
          },
          {
            id: "27244",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "27247",
            name: "Phường 01",
            level: "Phường"
          },
          {
            id: "27250",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "27253",
            name: "Phường 16",
            level: "Phường"
          }
        ]
      },
      {
        id: "773",
        name: "Quận 4",
        wards: [
          {
            id: "27256",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "27259",
            name: "Phường 13",
            level: "Phường"
          },
          {
            id: "27262",
            name: "Phường 09",
            level: "Phường"
          },
          {
            id: "27265",
            name: "Phường 06",
            level: "Phường"
          },
          {
            id: "27268",
            name: "Phường 08",
            level: "Phường"
          },
          {
            id: "27271",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "27274",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "27277",
            name: "Phường 18",
            level: "Phường"
          },
          {
            id: "27280",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "27283",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "27286",
            name: "Phường 03",
            level: "Phường"
          },
          {
            id: "27289",
            name: "Phường 16",
            level: "Phường"
          },
          {
            id: "27292",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "27295",
            name: "Phường 15",
            level: "Phường"
          },
          {
            id: "27298",
            name: "Phường 01",
            level: "Phường"
          }
        ]
      },
      {
        id: "774",
        name: "Quận 5",
        wards: [
          {
            id: "27301",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "27304",
            name: "Phường 09",
            level: "Phường"
          },
          {
            id: "27307",
            name: "Phường 03",
            level: "Phường"
          },
          {
            id: "27310",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "27313",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "27316",
            name: "Phường 08",
            level: "Phường"
          },
          {
            id: "27319",
            name: "Phường 15",
            level: "Phường"
          },
          {
            id: "27322",
            name: "Phường 07",
            level: "Phường"
          },
          {
            id: "27325",
            name: "Phường 01",
            level: "Phường"
          },
          {
            id: "27328",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "27331",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "27334",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "27337",
            name: "Phường 06",
            level: "Phường"
          },
          {
            id: "27340",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "27343",
            name: "Phường 13",
            level: "Phường"
          }
        ]
      },
      {
        id: "775",
        name: "Quận 6",
        wards: [
          {
            id: "27346",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "27349",
            name: "Phường 13",
            level: "Phường"
          },
          {
            id: "27352",
            name: "Phường 09",
            level: "Phường"
          },
          {
            id: "27355",
            name: "Phường 06",
            level: "Phường"
          },
          {
            id: "27358",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "27361",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "27364",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "27367",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "27370",
            name: "Phường 01",
            level: "Phường"
          },
          {
            id: "27373",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "27376",
            name: "Phường 08",
            level: "Phường"
          },
          {
            id: "27379",
            name: "Phường 03",
            level: "Phường"
          },
          {
            id: "27382",
            name: "Phường 07",
            level: "Phường"
          },
          {
            id: "27385",
            name: "Phường 10",
            level: "Phường"
          }
        ]
      },
      {
        id: "776",
        name: "Quận 8",
        wards: [
          {
            id: "27388",
            name: "Phường 08",
            level: "Phường"
          },
          {
            id: "27391",
            name: "Phường 02",
            level: "Phường"
          },
          {
            id: "27394",
            name: "Phường 01",
            level: "Phường"
          },
          {
            id: "27397",
            name: "Phường 03",
            level: "Phường"
          },
          {
            id: "27400",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "27403",
            name: "Phường 09",
            level: "Phường"
          },
          {
            id: "27406",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "27409",
            name: "Phường 04",
            level: "Phường"
          },
          {
            id: "27412",
            name: "Phường 13",
            level: "Phường"
          },
          {
            id: "27415",
            name: "Phường 12",
            level: "Phường"
          },
          {
            id: "27418",
            name: "Phường 05",
            level: "Phường"
          },
          {
            id: "27421",
            name: "Phường 14",
            level: "Phường"
          },
          {
            id: "27424",
            name: "Phường 06",
            level: "Phường"
          },
          {
            id: "27427",
            name: "Phường 15",
            level: "Phường"
          },
          {
            id: "27430",
            name: "Phường 16",
            level: "Phường"
          },
          {
            id: "27433",
            name: "Phường 07",
            level: "Phường"
          }
        ]
      },
      {
        id: "777",
        name: "Quận Bình Tân",
        wards: [
          {
            id: "27436",
            name: "Phường Bình Hưng Hòa",
            level: "Phường"
          },
          {
            id: "27439",
            name: "Phường Bình Hưng Hoà A",
            level: "Phường"
          },
          {
            id: "27442",
            name: "Phường Bình Hưng Hoà B",
            level: "Phường"
          },
          {
            id: "27445",
            name: "Phường Bình Trị Đông",
            level: "Phường"
          },
          {
            id: "27448",
            name: "Phường Bình Trị Đông A",
            level: "Phường"
          },
          {
            id: "27451",
            name: "Phường Bình Trị Đông B",
            level: "Phường"
          },
          {
            id: "27454",
            name: "Phường Tân Tạo",
            level: "Phường"
          },
          {
            id: "27457",
            name: "Phường Tân Tạo A",
            level: "Phường"
          },
          {
            id: "27460",
            name: "Phường  An Lạc",
            level: "Phường"
          },
          {
            id: "27463",
            name: "Phường An Lạc A",
            level: "Phường"
          }
        ]
      },
      {
        id: "778",
        name: "Quận 7",
        wards: [
          {
            id: "27466",
            name: "Phường Tân Thuận Đông",
            level: "Phường"
          },
          {
            id: "27469",
            name: "Phường Tân Thuận Tây",
            level: "Phường"
          },
          {
            id: "27472",
            name: "Phường Tân Kiểng",
            level: "Phường"
          },
          {
            id: "27475",
            name: "Phường Tân Hưng",
            level: "Phường"
          },
          {
            id: "27478",
            name: "Phường Bình Thuận",
            level: "Phường"
          },
          {
            id: "27481",
            name: "Phường Tân Quy",
            level: "Phường"
          },
          {
            id: "27484",
            name: "Phường Phú Thuận",
            level: "Phường"
          },
          {
            id: "27487",
            name: "Phường Tân Phú",
            level: "Phường"
          },
          {
            id: "27490",
            name: "Phường Tân Phong",
            level: "Phường"
          },
          {
            id: "27493",
            name: "Phường Phú Mỹ",
            level: "Phường"
          }
        ]
      },
      {
        id: "783",
        name: "Huyện Củ Chi",
        wards: [
          {
            id: "27496",
            name: "Thị trấn Củ Chi",
            level: "Thị trấn"
          },
          {
            id: "27499",
            name: "Xã Phú Mỹ Hưng",
            level: "Xã"
          },
          {
            id: "27502",
            name: "Xã An Phú",
            level: "Xã"
          },
          {
            id: "27505",
            name: "Xã Trung Lập Thượng",
            level: "Xã"
          },
          {
            id: "27508",
            name: "Xã An Nhơn Tây",
            level: "Xã"
          },
          {
            id: "27511",
            name: "Xã Nhuận Đức",
            level: "Xã"
          },
          {
            id: "27514",
            name: "Xã Phạm Văn Cội",
            level: "Xã"
          },
          {
            id: "27517",
            name: "Xã Phú Hòa Đông",
            level: "Xã"
          },
          {
            id: "27520",
            name: "Xã Trung Lập Hạ",
            level: "Xã"
          },
          {
            id: "27523",
            name: "Xã Trung An",
            level: "Xã"
          },
          {
            id: "27526",
            name: "Xã Phước Thạnh",
            level: "Xã"
          },
          {
            id: "27529",
            name: "Xã Phước Hiệp",
            level: "Xã"
          },
          {
            id: "27532",
            name: "Xã Tân An Hội",
            level: "Xã"
          },
          {
            id: "27535",
            name: "Xã Phước Vĩnh An",
            level: "Xã"
          },
          {
            id: "27538",
            name: "Xã Thái Mỹ",
            level: "Xã"
          },
          {
            id: "27541",
            name: "Xã Tân Thạnh Tây",
            level: "Xã"
          },
          {
            id: "27544",
            name: "Xã Hòa Phú",
            level: "Xã"
          },
          {
            id: "27547",
            name: "Xã Tân Thạnh Đông",
            level: "Xã"
          },
          {
            id: "27550",
            name: "Xã Bình Mỹ",
            level: "Xã"
          },
          {
            id: "27553",
            name: "Xã Tân Phú Trung",
            level: "Xã"
          },
          {
            id: "27556",
            name: "Xã Tân Thông Hội",
            level: "Xã"
          }
        ]
      },
      {
        id: "784",
        name: "Huyện Hóc Môn",
        wards: [
          {
            id: "27559",
            name: "Thị trấn Hóc Môn",
            level: "Thị trấn"
          },
          {
            id: "27562",
            name: "Xã Tân Hiệp",
            level: "Xã"
          },
          {
            id: "27565",
            name: "Xã Nhị Bình",
            level: "Xã"
          },
          {
            id: "27568",
            name: "Xã Đông Thạnh",
            level: "Xã"
          },
          {
            id: "27571",
            name: "Xã Tân Thới Nhì",
            level: "Xã"
          },
          {
            id: "27574",
            name: "Xã Thới Tam Thôn",
            level: "Xã"
          },
          {
            id: "27577",
            name: "Xã Xuân Thới Sơn",
            level: "Xã"
          },
          {
            id: "27580",
            name: "Xã Tân Xuân",
            level: "Xã"
          },
          {
            id: "27583",
            name: "Xã Xuân Thới Đông",
            level: "Xã"
          },
          {
            id: "27586",
            name: "Xã Trung Chánh",
            level: "Xã"
          },
          {
            id: "27589",
            name: "Xã Xuân Thới Thượng",
            level: "Xã"
          },
          {
            id: "27592",
            name: "Xã Bà Điểm",
            level: "Xã"
          }
        ]
      },
      {
        id: "785",
        name: "Huyện Bình Chánh",
        wards: [
          {
            id: "27595",
            name: "Thị trấn Tân Túc",
            level: "Thị trấn"
          },
          {
            id: "27598",
            name: "Xã Phạm Văn Hai",
            level: "Xã"
          },
          {
            id: "27601",
            name: "Xã Vĩnh Lộc A",
            level: "Xã"
          },
          {
            id: "27604",
            name: "Xã Vĩnh Lộc B",
            level: "Xã"
          },
          {
            id: "27607",
            name: "Xã Bình Lợi",
            level: "Xã"
          },
          {
            id: "27610",
            name: "Xã Lê Minh Xuân",
            level: "Xã"
          },
          {
            id: "27613",
            name: "Xã Tân Nhựt",
            level: "Xã"
          },
          {
            id: "27616",
            name: "Xã Tân Kiên",
            level: "Xã"
          },
          {
            id: "27619",
            name: "Xã Bình Hưng",
            level: "Xã"
          },
          {
            id: "27622",
            name: "Xã Phong Phú",
            level: "Xã"
          },
          {
            id: "27625",
            name: "Xã An Phú Tây",
            level: "Xã"
          },
          {
            id: "27628",
            name: "Xã Hưng Long",
            level: "Xã"
          },
          {
            id: "27631",
            name: "Xã Đa Phước",
            level: "Xã"
          },
          {
            id: "27634",
            name: "Xã Tân Quý Tây",
            level: "Xã"
          },
          {
            id: "27637",
            name: "Xã Bình Chánh",
            level: "Xã"
          },
          {
            id: "27640",
            name: "Xã Quy Đức",
            level: "Xã"
          }
        ]
      },
      {
        id: "786",
        name: "Huyện Nhà Bè",
        wards: [
          {
            id: "27643",
            name: "Thị trấn Nhà Bè",
            level: "Thị trấn"
          },
          {
            id: "27646",
            name: "Xã Phước Kiển",
            level: "Xã"
          },
          {
            id: "27649",
            name: "Xã Phước Lộc",
            level: "Xã"
          },
          {
            id: "27652",
            name: "Xã Nhơn Đức",
            level: "Xã"
          },
          {
            id: "27655",
            name: "Xã Phú Xuân",
            level: "Xã"
          },
          {
            id: "27658",
            name: "Xã Long Thới",
            level: "Xã"
          },
          {
            id: "27661",
            name: "Xã Hiệp Phước",
            level: "Xã"
          }
        ]
      },
      {
        id: "787",
        name: "Huyện Cần Giờ",
        wards: [
          {
            id: "27664",
            name: "Thị trấn Cần Thạnh",
            level: "Thị trấn"
          },
          {
            id: "27667",
            name: "Xã Bình Khánh",
            level: "Xã"
          },
          {
            id: "27670",
            name: "Xã Tam Thôn Hiệp",
            level: "Xã"
          },
          {
            id: "27673",
            name: "Xã An Thới Đông",
            level: "Xã"
          },
          {
            id: "27676",
            name: "Xã Thạnh An",
            level: "Xã"
          },
          {
            id: "27679",
            name: "Xã Long Hòa",
            level: "Xã"
          },
          {
            id: "27682",
            name: "Xã Lý Nhơn",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "80",
    name: "Tỉnh Long An",
    districts: [
      {
        id: "794",
        name: "Thành phố Tân An",
        wards: [
          {
            id: "27685",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "27688",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "27691",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "27692",
            name: "Phường Tân Khánh",
            level: "Phường"
          },
          {
            id: "27694",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "27697",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "27698",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "27700",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "27703",
            name: "Xã Hướng Thọ Phú",
            level: "Xã"
          },
          {
            id: "27706",
            name: "Xã Nhơn Thạnh Trung",
            level: "Xã"
          },
          {
            id: "27709",
            name: "Xã Lợi Bình Nhơn",
            level: "Xã"
          },
          {
            id: "27712",
            name: "Xã Bình Tâm",
            level: "Xã"
          },
          {
            id: "27715",
            name: "Phường Khánh Hậu",
            level: "Phường"
          },
          {
            id: "27718",
            name: "Xã An Vĩnh Ngãi",
            level: "Xã"
          }
        ]
      },
      {
        id: "795",
        name: "Thị xã Kiến Tường",
        wards: [
          {
            id: "27787",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "27788",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "27790",
            name: "Xã Thạnh Trị",
            level: "Xã"
          },
          {
            id: "27793",
            name: "Xã Bình Hiệp",
            level: "Xã"
          },
          {
            id: "27799",
            name: "Xã Bình Tân",
            level: "Xã"
          },
          {
            id: "27805",
            name: "Xã Tuyên Thạnh",
            level: "Xã"
          },
          {
            id: "27806",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "27817",
            name: "Xã Thạnh Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "796",
        name: "Huyện Tân Hưng",
        wards: [
          {
            id: "27721",
            name: "Thị trấn Tân Hưng",
            level: "Thị trấn"
          },
          {
            id: "27724",
            name: "Xã Hưng Hà",
            level: "Xã"
          },
          {
            id: "27727",
            name: "Xã Hưng Điền B",
            level: "Xã"
          },
          {
            id: "27730",
            name: "Xã Hưng Điền",
            level: "Xã"
          },
          {
            id: "27733",
            name: "Xã Thạnh Hưng",
            level: "Xã"
          },
          {
            id: "27736",
            name: "Xã Hưng Thạnh",
            level: "Xã"
          },
          {
            id: "27739",
            name: "Xã Vĩnh Thạnh",
            level: "Xã"
          },
          {
            id: "27742",
            name: "Xã Vĩnh Châu B",
            level: "Xã"
          },
          {
            id: "27745",
            name: "Xã Vĩnh Lợi",
            level: "Xã"
          },
          {
            id: "27748",
            name: "Xã Vĩnh Đại",
            level: "Xã"
          },
          {
            id: "27751",
            name: "Xã Vĩnh Châu A",
            level: "Xã"
          },
          {
            id: "27754",
            name: "Xã Vĩnh Bửu",
            level: "Xã"
          }
        ]
      },
      {
        id: "797",
        name: "Huyện Vĩnh Hưng",
        wards: [
          {
            id: "27757",
            name: "Thị trấn Vĩnh Hưng",
            level: "Thị trấn"
          },
          {
            id: "27760",
            name: "Xã Hưng Điền A",
            level: "Xã"
          },
          {
            id: "27763",
            name: "Xã Khánh Hưng",
            level: "Xã"
          },
          {
            id: "27766",
            name: "Xã Thái Trị",
            level: "Xã"
          },
          {
            id: "27769",
            name: "Xã Vĩnh Trị",
            level: "Xã"
          },
          {
            id: "27772",
            name: "Xã Thái Bình Trung",
            level: "Xã"
          },
          {
            id: "27775",
            name: "Xã Vĩnh Bình",
            level: "Xã"
          },
          {
            id: "27778",
            name: "Xã Vĩnh Thuận",
            level: "Xã"
          },
          {
            id: "27781",
            name: "Xã Tuyên Bình",
            level: "Xã"
          },
          {
            id: "27784",
            name: "Xã Tuyên Bình Tây",
            level: "Xã"
          }
        ]
      },
      {
        id: "798",
        name: "Huyện Mộc Hóa",
        wards: [
          {
            id: "27796",
            name: "Xã Bình Hòa Tây",
            level: "Xã"
          },
          {
            id: "27802",
            name: "Xã Bình Thạnh",
            level: "Xã"
          },
          {
            id: "27808",
            name: "Xã Bình Hòa Trung",
            level: "Xã"
          },
          {
            id: "27811",
            name: "Xã Bình Hòa Đông",
            level: "Xã"
          },
          {
            id: "27814",
            name: "Thị trấn Bình Phong Thạnh",
            level: "Thị trấn"
          },
          {
            id: "27820",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "27823",
            name: "Xã Tân Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "799",
        name: "Huyện Tân Thạnh",
        wards: [
          {
            id: "27826",
            name: "Thị trấn Tân Thạnh",
            level: "Thị trấn"
          },
          {
            id: "27829",
            name: "Xã Bắc Hòa",
            level: "Xã"
          },
          {
            id: "27832",
            name: "Xã Hậu Thạnh Tây",
            level: "Xã"
          },
          {
            id: "27835",
            name: "Xã Nhơn Hòa Lập",
            level: "Xã"
          },
          {
            id: "27838",
            name: "Xã Tân Lập",
            level: "Xã"
          },
          {
            id: "27841",
            name: "Xã Hậu Thạnh Đông",
            level: "Xã"
          },
          {
            id: "27844",
            name: "Xã Nhơn Hoà",
            level: "Xã"
          },
          {
            id: "27847",
            name: "Xã Kiến Bình",
            level: "Xã"
          },
          {
            id: "27850",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "27853",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "27856",
            name: "Xã Tân Ninh",
            level: "Xã"
          },
          {
            id: "27859",
            name: "Xã Nhơn Ninh",
            level: "Xã"
          },
          {
            id: "27862",
            name: "Xã Tân Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "800",
        name: "Huyện Thạnh Hóa",
        wards: [
          {
            id: "27865",
            name: "Thị trấn Thạnh Hóa",
            level: "Thị trấn"
          },
          {
            id: "27868",
            name: "Xã Tân Hiệp",
            level: "Xã"
          },
          {
            id: "27871",
            name: "Xã Thuận Bình",
            level: "Xã"
          },
          {
            id: "27874",
            name: "Xã Thạnh Phước",
            level: "Xã"
          },
          {
            id: "27877",
            name: "Xã Thạnh Phú",
            level: "Xã"
          },
          {
            id: "27880",
            name: "Xã Thuận Nghĩa Hòa",
            level: "Xã"
          },
          {
            id: "27883",
            name: "Xã Thủy Đông",
            level: "Xã"
          },
          {
            id: "27886",
            name: "Xã Thủy Tây",
            level: "Xã"
          },
          {
            id: "27889",
            name: "Xã Tân Tây",
            level: "Xã"
          },
          {
            id: "27892",
            name: "Xã Tân Đông",
            level: "Xã"
          },
          {
            id: "27895",
            name: "Xã Thạnh An",
            level: "Xã"
          }
        ]
      },
      {
        id: "801",
        name: "Huyện Đức Huệ",
        wards: [
          {
            id: "27898",
            name: "Thị trấn Đông Thành",
            level: "Thị trấn"
          },
          {
            id: "27901",
            name: "Xã Mỹ Quý Đông",
            level: "Xã"
          },
          {
            id: "27904",
            name: "Xã Mỹ Thạnh Bắc",
            level: "Xã"
          },
          {
            id: "27907",
            name: "Xã Mỹ Quý Tây",
            level: "Xã"
          },
          {
            id: "27910",
            name: "Xã Mỹ Thạnh Tây",
            level: "Xã"
          },
          {
            id: "27913",
            name: "Xã Mỹ Thạnh Đông",
            level: "Xã"
          },
          {
            id: "27916",
            name: "Xã Bình Thành",
            level: "Xã"
          },
          {
            id: "27919",
            name: "Xã Bình Hòa Bắc",
            level: "Xã"
          },
          {
            id: "27922",
            name: "Xã Bình Hòa Hưng",
            level: "Xã"
          },
          {
            id: "27925",
            name: "Xã Bình Hòa Nam",
            level: "Xã"
          },
          {
            id: "27928",
            name: "Xã Mỹ Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "802",
        name: "Huyện Đức Hòa",
        wards: [
          {
            id: "27931",
            name: "Thị trấn Hậu Nghĩa",
            level: "Thị trấn"
          },
          {
            id: "27934",
            name: "Thị trấn Hiệp Hòa",
            level: "Thị trấn"
          },
          {
            id: "27937",
            name: "Thị trấn Đức Hòa",
            level: "Thị trấn"
          },
          {
            id: "27940",
            name: "Xã Lộc Giang",
            level: "Xã"
          },
          {
            id: "27943",
            name: "Xã An Ninh Đông",
            level: "Xã"
          },
          {
            id: "27946",
            name: "Xã An Ninh Tây",
            level: "Xã"
          },
          {
            id: "27949",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "27952",
            name: "Xã Hiệp Hòa",
            level: "Xã"
          },
          {
            id: "27955",
            name: "Xã Đức Lập Thượng",
            level: "Xã"
          },
          {
            id: "27958",
            name: "Xã Đức Lập Hạ",
            level: "Xã"
          },
          {
            id: "27961",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "27964",
            name: "Xã Mỹ Hạnh Bắc",
            level: "Xã"
          },
          {
            id: "27967",
            name: "Xã Đức Hòa Thượng",
            level: "Xã"
          },
          {
            id: "27970",
            name: "Xã Hòa Khánh Tây",
            level: "Xã"
          },
          {
            id: "27973",
            name: "Xã Hòa Khánh Đông",
            level: "Xã"
          },
          {
            id: "27976",
            name: "Xã Mỹ Hạnh Nam",
            level: "Xã"
          },
          {
            id: "27979",
            name: "Xã Hòa Khánh Nam",
            level: "Xã"
          },
          {
            id: "27982",
            name: "Xã Đức Hòa Đông",
            level: "Xã"
          },
          {
            id: "27985",
            name: "Xã Đức Hòa Hạ",
            level: "Xã"
          },
          {
            id: "27988",
            name: "Xã Hựu Thạnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "803",
        name: "Huyện Bến Lức",
        wards: [
          {
            id: "27991",
            name: "Thị trấn Bến Lức",
            level: "Thị trấn"
          },
          {
            id: "27994",
            name: "Xã Thạnh Lợi",
            level: "Xã"
          },
          {
            id: "27997",
            name: "Xã Lương Bình",
            level: "Xã"
          },
          {
            id: "28000",
            name: "Xã Thạnh Hòa",
            level: "Xã"
          },
          {
            id: "28003",
            name: "Xã Lương Hòa",
            level: "Xã"
          },
          {
            id: "28006",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "28009",
            name: "Xã Tân Bửu",
            level: "Xã"
          },
          {
            id: "28012",
            name: "Xã An Thạnh",
            level: "Xã"
          },
          {
            id: "28015",
            name: "Xã Bình Đức",
            level: "Xã"
          },
          {
            id: "28018",
            name: "Xã Mỹ Yên",
            level: "Xã"
          },
          {
            id: "28021",
            name: "Xã Thanh Phú",
            level: "Xã"
          },
          {
            id: "28024",
            name: "Xã Long Hiệp",
            level: "Xã"
          },
          {
            id: "28027",
            name: "Xã Thạnh Đức",
            level: "Xã"
          },
          {
            id: "28030",
            name: "Xã Phước Lợi",
            level: "Xã"
          },
          {
            id: "28033",
            name: "Xã Nhựt Chánh",
            level: "Xã"
          }
        ]
      },
      {
        id: "804",
        name: "Huyện Thủ Thừa",
        wards: [
          {
            id: "28036",
            name: "Thị trấn Thủ Thừa",
            level: "Thị trấn"
          },
          {
            id: "28039",
            name: "Xã Long Thạnh",
            level: "Xã"
          },
          {
            id: "28042",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "28045",
            name: "Xã Long Thuận",
            level: "Xã"
          },
          {
            id: "28048",
            name: "Xã Mỹ Lạc",
            level: "Xã"
          },
          {
            id: "28051",
            name: "Xã Mỹ Thạnh",
            level: "Xã"
          },
          {
            id: "28054",
            name: "Xã Bình An",
            level: "Xã"
          },
          {
            id: "28057",
            name: "Xã Nhị Thành",
            level: "Xã"
          },
          {
            id: "28060",
            name: "Xã Mỹ An",
            level: "Xã"
          },
          {
            id: "28063",
            name: "Xã Bình Thạnh",
            level: "Xã"
          },
          {
            id: "28066",
            name: "Xã Mỹ Phú",
            level: "Xã"
          },
          {
            id: "28072",
            name: "Xã Tân Long",
            level: "Xã"
          }
        ]
      },
      {
        id: "805",
        name: "Huyện Tân Trụ",
        wards: [
          {
            id: "28075",
            name: "Thị trấn Tân Trụ",
            level: "Thị trấn"
          },
          {
            id: "28078",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "28084",
            name: "Xã Quê Mỹ Thạnh",
            level: "Xã"
          },
          {
            id: "28087",
            name: "Xã Lạc Tấn",
            level: "Xã"
          },
          {
            id: "28090",
            name: "Xã Bình Trinh Đông",
            level: "Xã"
          },
          {
            id: "28093",
            name: "Xã Tân Phước Tây",
            level: "Xã"
          },
          {
            id: "28096",
            name: "Xã Bình Lãng",
            level: "Xã"
          },
          {
            id: "28099",
            name: "Xã Bình Tịnh",
            level: "Xã"
          },
          {
            id: "28102",
            name: "Xã Đức Tân",
            level: "Xã"
          },
          {
            id: "28105",
            name: "Xã Nhựt Ninh",
            level: "Xã"
          }
        ]
      },
      {
        id: "806",
        name: "Huyện Cần Đước",
        wards: [
          {
            id: "28108",
            name: "Thị trấn Cần Đước",
            level: "Thị trấn"
          },
          {
            id: "28111",
            name: "Xã Long Trạch",
            level: "Xã"
          },
          {
            id: "28114",
            name: "Xã Long Khê",
            level: "Xã"
          },
          {
            id: "28117",
            name: "Xã Long Định",
            level: "Xã"
          },
          {
            id: "28120",
            name: "Xã Phước Vân",
            level: "Xã"
          },
          {
            id: "28123",
            name: "Xã Long Hòa",
            level: "Xã"
          },
          {
            id: "28126",
            name: "Xã Long Cang",
            level: "Xã"
          },
          {
            id: "28129",
            name: "Xã Long Sơn",
            level: "Xã"
          },
          {
            id: "28132",
            name: "Xã Tân Trạch",
            level: "Xã"
          },
          {
            id: "28135",
            name: "Xã Mỹ Lệ",
            level: "Xã"
          },
          {
            id: "28138",
            name: "Xã Tân Lân",
            level: "Xã"
          },
          {
            id: "28141",
            name: "Xã Phước Tuy",
            level: "Xã"
          },
          {
            id: "28144",
            name: "Xã Long Hựu Đông",
            level: "Xã"
          },
          {
            id: "28147",
            name: "Xã Tân Ân",
            level: "Xã"
          },
          {
            id: "28150",
            name: "Xã Phước Đông",
            level: "Xã"
          },
          {
            id: "28153",
            name: "Xã Long Hựu Tây",
            level: "Xã"
          },
          {
            id: "28156",
            name: "Xã Tân Chánh",
            level: "Xã"
          }
        ]
      },
      {
        id: "807",
        name: "Huyện Cần Giuộc",
        wards: [
          {
            id: "28159",
            name: "Thị trấn Cần Giuộc",
            level: "Thị trấn"
          },
          {
            id: "28162",
            name: "Xã Phước Lý",
            level: "Xã"
          },
          {
            id: "28165",
            name: "Xã Long Thượng",
            level: "Xã"
          },
          {
            id: "28168",
            name: "Xã Long Hậu",
            level: "Xã"
          },
          {
            id: "28174",
            name: "Xã Phước Hậu",
            level: "Xã"
          },
          {
            id: "28177",
            name: "Xã Mỹ Lộc",
            level: "Xã"
          },
          {
            id: "28180",
            name: "Xã Phước Lại",
            level: "Xã"
          },
          {
            id: "28183",
            name: "Xã Phước Lâm",
            level: "Xã"
          },
          {
            id: "28189",
            name: "Xã Thuận Thành",
            level: "Xã"
          },
          {
            id: "28192",
            name: "Xã Phước Vĩnh Tây",
            level: "Xã"
          },
          {
            id: "28195",
            name: "Xã Phước Vĩnh Đông",
            level: "Xã"
          },
          {
            id: "28198",
            name: "Xã Long An",
            level: "Xã"
          },
          {
            id: "28201",
            name: "Xã Long Phụng",
            level: "Xã"
          },
          {
            id: "28204",
            name: "Xã Đông Thạnh",
            level: "Xã"
          },
          {
            id: "28207",
            name: "Xã Tân Tập",
            level: "Xã"
          }
        ]
      },
      {
        id: "808",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "28210",
            name: "Thị trấn Tầm Vu",
            level: "Thị trấn"
          },
          {
            id: "28213",
            name: "Xã Bình Quới",
            level: "Xã"
          },
          {
            id: "28216",
            name: "Xã Hòa Phú",
            level: "Xã"
          },
          {
            id: "28219",
            name: "Xã Phú Ngãi Trị",
            level: "Xã"
          },
          {
            id: "28222",
            name: "Xã Vĩnh Công",
            level: "Xã"
          },
          {
            id: "28225",
            name: "Xã Thuận Mỹ",
            level: "Xã"
          },
          {
            id: "28228",
            name: "Xã Hiệp Thạnh",
            level: "Xã"
          },
          {
            id: "28231",
            name: "Xã Phước Tân Hưng",
            level: "Xã"
          },
          {
            id: "28234",
            name: "Xã Thanh Phú Long",
            level: "Xã"
          },
          {
            id: "28237",
            name: "Xã Dương Xuân Hội",
            level: "Xã"
          },
          {
            id: "28240",
            name: "Xã An Lục Long",
            level: "Xã"
          },
          {
            id: "28243",
            name: "Xã Long Trì",
            level: "Xã"
          },
          {
            id: "28246",
            name: "Xã Thanh Vĩnh Đông",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "82",
    name: "Tỉnh Tiền Giang",
    districts: [
      {
        id: "815",
        name: "Thành phố Mỹ Tho",
        wards: [
          {
            id: "28249",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "28252",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "28255",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "28258",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "28261",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "28264",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "28267",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "28270",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "28273",
            name: "Phường 9",
            level: "Phường"
          },
          {
            id: "28276",
            name: "Phường 10",
            level: "Phường"
          },
          {
            id: "28279",
            name: "Phường Tân Long",
            level: "Phường"
          },
          {
            id: "28282",
            name: "Xã Đạo Thạnh",
            level: "Xã"
          },
          {
            id: "28285",
            name: "Xã Trung An",
            level: "Xã"
          },
          {
            id: "28288",
            name: "Xã Mỹ Phong",
            level: "Xã"
          },
          {
            id: "28291",
            name: "Xã Tân Mỹ Chánh",
            level: "Xã"
          },
          {
            id: "28567",
            name: "Xã Phước Thạnh",
            level: "Xã"
          },
          {
            id: "28591",
            name: "Xã Thới Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "816",
        name: "Thị xã Gò Công",
        wards: [
          {
            id: "28294",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "28297",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "28300",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "28303",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "28306",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "28309",
            name: "Xã Long Hưng",
            level: "Xã"
          },
          {
            id: "28312",
            name: "Xã Long Thuận",
            level: "Xã"
          },
          {
            id: "28315",
            name: "Xã Long Chánh",
            level: "Xã"
          },
          {
            id: "28318",
            name: "Xã Long Hòa",
            level: "Xã"
          },
          {
            id: "28708",
            name: "Xã Bình Đông",
            level: "Xã"
          },
          {
            id: "28717",
            name: "Xã Bình Xuân",
            level: "Xã"
          },
          {
            id: "28729",
            name: "Xã Tân Trung",
            level: "Xã"
          }
        ]
      },
      {
        id: "817",
        name: "Thị xã Cai Lậy",
        wards: [
          {
            id: "28435",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "28436",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "28437",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "28439",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "28440",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "28447",
            name: "Xã Mỹ Phước Tây",
            level: "Xã"
          },
          {
            id: "28450",
            name: "Xã Mỹ Hạnh Đông",
            level: "Xã"
          },
          {
            id: "28453",
            name: "Xã Mỹ Hạnh Trung",
            level: "Xã"
          },
          {
            id: "28459",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "28462",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "28468",
            name: "Xã Tân Hội",
            level: "Xã"
          },
          {
            id: "28474",
            name: "Phường Nhị Mỹ",
            level: "Phường"
          },
          {
            id: "28477",
            name: "Xã Nhị Quý",
            level: "Xã"
          },
          {
            id: "28480",
            name: "Xã Thanh Hòa",
            level: "Xã"
          },
          {
            id: "28483",
            name: "Xã Phú Quý",
            level: "Xã"
          },
          {
            id: "28486",
            name: "Xã Long Khánh",
            level: "Xã"
          }
        ]
      },
      {
        id: "818",
        name: "Huyện Tân Phước",
        wards: [
          {
            id: "28321",
            name: "Thị trấn Mỹ Phước",
            level: "Thị trấn"
          },
          {
            id: "28324",
            name: "Xã Tân Hòa Đông",
            level: "Xã"
          },
          {
            id: "28327",
            name: "Xã Thạnh Tân",
            level: "Xã"
          },
          {
            id: "28330",
            name: "Xã Thạnh Mỹ",
            level: "Xã"
          },
          {
            id: "28333",
            name: "Xã Thạnh Hoà",
            level: "Xã"
          },
          {
            id: "28336",
            name: "Xã Phú Mỹ",
            level: "Xã"
          },
          {
            id: "28339",
            name: "Xã Tân Hòa Thành",
            level: "Xã"
          },
          {
            id: "28342",
            name: "Xã Hưng Thạnh",
            level: "Xã"
          },
          {
            id: "28345",
            name: "Xã Tân Lập 1",
            level: "Xã"
          },
          {
            id: "28348",
            name: "Xã Tân Hòa Tây",
            level: "Xã"
          },
          {
            id: "28354",
            name: "Xã Tân Lập 2",
            level: "Xã"
          },
          {
            id: "28357",
            name: "Xã Phước Lập",
            level: "Xã"
          }
        ]
      },
      {
        id: "819",
        name: "Huyện Cái Bè",
        wards: [
          {
            id: "28360",
            name: "Thị trấn Cái Bè",
            level: "Thị trấn"
          },
          {
            id: "28363",
            name: "Xã Hậu Mỹ Bắc B",
            level: "Xã"
          },
          {
            id: "28366",
            name: "Xã Hậu Mỹ Bắc A",
            level: "Xã"
          },
          {
            id: "28369",
            name: "Xã Mỹ Trung",
            level: "Xã"
          },
          {
            id: "28372",
            name: "Xã Hậu Mỹ Trinh",
            level: "Xã"
          },
          {
            id: "28375",
            name: "Xã Hậu Mỹ Phú",
            level: "Xã"
          },
          {
            id: "28378",
            name: "Xã Mỹ Tân",
            level: "Xã"
          },
          {
            id: "28381",
            name: "Xã Mỹ Lợi B",
            level: "Xã"
          },
          {
            id: "28384",
            name: "Xã Thiện Trung",
            level: "Xã"
          },
          {
            id: "28387",
            name: "Xã Mỹ Hội",
            level: "Xã"
          },
          {
            id: "28390",
            name: "Xã An Cư",
            level: "Xã"
          },
          {
            id: "28393",
            name: "Xã Hậu Thành",
            level: "Xã"
          },
          {
            id: "28396",
            name: "Xã Mỹ Lợi A",
            level: "Xã"
          },
          {
            id: "28399",
            name: "Xã Hòa Khánh",
            level: "Xã"
          },
          {
            id: "28402",
            name: "Xã Thiện Trí",
            level: "Xã"
          },
          {
            id: "28405",
            name: "Xã Mỹ Đức Đông",
            level: "Xã"
          },
          {
            id: "28408",
            name: "Xã Mỹ Đức Tây",
            level: "Xã"
          },
          {
            id: "28411",
            name: "Xã Đông Hòa Hiệp",
            level: "Xã"
          },
          {
            id: "28414",
            name: "Xã An Thái Đông",
            level: "Xã"
          },
          {
            id: "28417",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "28420",
            name: "Xã Mỹ Lương",
            level: "Xã"
          },
          {
            id: "28423",
            name: "Xã Tân Thanh",
            level: "Xã"
          },
          {
            id: "28426",
            name: "Xã An Thái Trung",
            level: "Xã"
          },
          {
            id: "28429",
            name: "Xã An Hữu",
            level: "Xã"
          },
          {
            id: "28432",
            name: "Xã Hòa Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "820",
        name: "Huyện Cai Lậy",
        wards: [
          {
            id: "28438",
            name: "Xã Thạnh Lộc",
            level: "Xã"
          },
          {
            id: "28441",
            name: "Xã Mỹ Thành Bắc",
            level: "Xã"
          },
          {
            id: "28444",
            name: "Xã Phú Cường",
            level: "Xã"
          },
          {
            id: "28456",
            name: "Xã Mỹ Thành Nam",
            level: "Xã"
          },
          {
            id: "28465",
            name: "Xã Phú Nhuận",
            level: "Xã"
          },
          {
            id: "28471",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "28489",
            name: "Xã Cẩm Sơn",
            level: "Xã"
          },
          {
            id: "28492",
            name: "Xã Phú An",
            level: "Xã"
          },
          {
            id: "28495",
            name: "Xã Mỹ Long",
            level: "Xã"
          },
          {
            id: "28498",
            name: "Xã Long Tiên",
            level: "Xã"
          },
          {
            id: "28501",
            name: "Xã Hiệp Đức",
            level: "Xã"
          },
          {
            id: "28504",
            name: "Xã Long Trung",
            level: "Xã"
          },
          {
            id: "28507",
            name: "Xã Hội Xuân",
            level: "Xã"
          },
          {
            id: "28510",
            name: "Xã Tân Phong",
            level: "Xã"
          },
          {
            id: "28513",
            name: "Xã Tam Bình",
            level: "Xã"
          },
          {
            id: "28516",
            name: "Xã Ngũ Hiệp",
            level: "Xã"
          }
        ]
      },
      {
        id: "821",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "28519",
            name: "Thị trấn Tân Hiệp",
            level: "Thị trấn"
          },
          {
            id: "28522",
            name: "Xã Tân Hội Đông",
            level: "Xã"
          },
          {
            id: "28525",
            name: "Xã Tân Hương",
            level: "Xã"
          },
          {
            id: "28528",
            name: "Xã Tân Lý Đông",
            level: "Xã"
          },
          {
            id: "28531",
            name: "Xã Tân Lý Tây",
            level: "Xã"
          },
          {
            id: "28534",
            name: "Xã Thân Cửu Nghĩa",
            level: "Xã"
          },
          {
            id: "28537",
            name: "Xã Tam Hiệp",
            level: "Xã"
          },
          {
            id: "28540",
            name: "Xã Điềm Hy",
            level: "Xã"
          },
          {
            id: "28543",
            name: "Xã Nhị Bình",
            level: "Xã"
          },
          {
            id: "28546",
            name: "Xã Dưỡng Điềm",
            level: "Xã"
          },
          {
            id: "28549",
            name: "Xã Đông Hòa",
            level: "Xã"
          },
          {
            id: "28552",
            name: "Xã Long Định",
            level: "Xã"
          },
          {
            id: "28555",
            name: "Xã Hữu Đạo",
            level: "Xã"
          },
          {
            id: "28558",
            name: "Xã Long An",
            level: "Xã"
          },
          {
            id: "28561",
            name: "Xã Long Hưng",
            level: "Xã"
          },
          {
            id: "28564",
            name: "Xã Bình Trưng",
            level: "Xã"
          },
          {
            id: "28570",
            name: "Xã Thạnh Phú",
            level: "Xã"
          },
          {
            id: "28573",
            name: "Xã Bàn Long",
            level: "Xã"
          },
          {
            id: "28576",
            name: "Xã Vĩnh Kim",
            level: "Xã"
          },
          {
            id: "28579",
            name: "Xã Bình Đức",
            level: "Xã"
          },
          {
            id: "28582",
            name: "Xã Song Thuận",
            level: "Xã"
          },
          {
            id: "28585",
            name: "Xã Kim Sơn",
            level: "Xã"
          },
          {
            id: "28588",
            name: "Xã Phú Phong",
            level: "Xã"
          }
        ]
      },
      {
        id: "822",
        name: "Huyện Chợ Gạo",
        wards: [
          {
            id: "28594",
            name: "Thị trấn Chợ Gạo",
            level: "Thị trấn"
          },
          {
            id: "28597",
            name: "Xã Trung Hòa",
            level: "Xã"
          },
          {
            id: "28600",
            name: "Xã Hòa Tịnh",
            level: "Xã"
          },
          {
            id: "28603",
            name: "Xã Mỹ Tịnh An",
            level: "Xã"
          },
          {
            id: "28606",
            name: "Xã Tân Bình Thạnh",
            level: "Xã"
          },
          {
            id: "28609",
            name: "Xã Phú Kiết",
            level: "Xã"
          },
          {
            id: "28612",
            name: "Xã Lương Hòa Lạc",
            level: "Xã"
          },
          {
            id: "28615",
            name: "Xã Thanh Bình",
            level: "Xã"
          },
          {
            id: "28618",
            name: "Xã Quơn Long",
            level: "Xã"
          },
          {
            id: "28621",
            name: "Xã Bình Phục Nhứt",
            level: "Xã"
          },
          {
            id: "28624",
            name: "Xã Đăng Hưng Phước",
            level: "Xã"
          },
          {
            id: "28627",
            name: "Xã Tân Thuận Bình",
            level: "Xã"
          },
          {
            id: "28630",
            name: "Xã Song Bình",
            level: "Xã"
          },
          {
            id: "28633",
            name: "Xã Bình Phan",
            level: "Xã"
          },
          {
            id: "28636",
            name: "Xã Long Bình Điền",
            level: "Xã"
          },
          {
            id: "28639",
            name: "Xã An Thạnh Thủy",
            level: "Xã"
          },
          {
            id: "28642",
            name: "Xã Xuân Đông",
            level: "Xã"
          },
          {
            id: "28645",
            name: "Xã Hòa Định",
            level: "Xã"
          },
          {
            id: "28648",
            name: "Xã Bình Ninh",
            level: "Xã"
          }
        ]
      },
      {
        id: "823",
        name: "Huyện Gò Công Tây",
        wards: [
          {
            id: "28651",
            name: "Thị trấn Vĩnh Bình",
            level: "Thị trấn"
          },
          {
            id: "28654",
            name: "Xã Đồng Sơn",
            level: "Xã"
          },
          {
            id: "28657",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "28660",
            name: "Xã Đồng Thạnh",
            level: "Xã"
          },
          {
            id: "28663",
            name: "Xã Thành Công",
            level: "Xã"
          },
          {
            id: "28666",
            name: "Xã Bình Nhì",
            level: "Xã"
          },
          {
            id: "28669",
            name: "Xã Yên Luông",
            level: "Xã"
          },
          {
            id: "28672",
            name: "Xã Thạnh Trị",
            level: "Xã"
          },
          {
            id: "28675",
            name: "Xã Thạnh Nhựt",
            level: "Xã"
          },
          {
            id: "28678",
            name: "Xã Long Vĩnh",
            level: "Xã"
          },
          {
            id: "28681",
            name: "Xã Bình Tân",
            level: "Xã"
          },
          {
            id: "28684",
            name: "Xã Vĩnh Hựu",
            level: "Xã"
          },
          {
            id: "28687",
            name: "Xã Long Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "824",
        name: "Huyện Gò Công Đông",
        wards: [
          {
            id: "28702",
            name: "Thị trấn Tân Hòa",
            level: "Thị trấn"
          },
          {
            id: "28705",
            name: "Xã Tăng Hoà",
            level: "Xã"
          },
          {
            id: "28711",
            name: "Xã Tân Phước",
            level: "Xã"
          },
          {
            id: "28714",
            name: "Xã Gia Thuận",
            level: "Xã"
          },
          {
            id: "28720",
            name: "Thị trấn Vàm Láng",
            level: "Thị trấn"
          },
          {
            id: "28723",
            name: "Xã Tân Tây",
            level: "Xã"
          },
          {
            id: "28726",
            name: "Xã Kiểng Phước",
            level: "Xã"
          },
          {
            id: "28732",
            name: "Xã Tân Đông",
            level: "Xã"
          },
          {
            id: "28735",
            name: "Xã Bình Ân",
            level: "Xã"
          },
          {
            id: "28738",
            name: "Xã Tân Điền",
            level: "Xã"
          },
          {
            id: "28741",
            name: "Xã Bình Nghị",
            level: "Xã"
          },
          {
            id: "28744",
            name: "Xã Phước Trung",
            level: "Xã"
          },
          {
            id: "28747",
            name: "Xã Tân Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "825",
        name: "Huyện Tân Phú Đông",
        wards: [
          {
            id: "28690",
            name: "Xã Tân Thới",
            level: "Xã"
          },
          {
            id: "28693",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "28696",
            name: "Xã Phú Thạnh",
            level: "Xã"
          },
          {
            id: "28699",
            name: "Xã Tân Thạnh",
            level: "Xã"
          },
          {
            id: "28750",
            name: "Xã Phú Đông",
            level: "Xã"
          },
          {
            id: "28753",
            name: "Xã Phú Tân",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "83",
    name: "Tỉnh Bến Tre",
    districts: [
      {
        id: "829",
        name: "Thành phố Bến Tre",
        wards: [
          {
            id: "28756",
            name: "Phường Phú Khương",
            level: "Phường"
          },
          {
            id: "28757",
            name: "Phường Phú Tân",
            level: "Phường"
          },
          {
            id: "28759",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "28762",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "28765",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "28768",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "28777",
            name: "Phường An Hội",
            level: "Phường"
          },
          {
            id: "28780",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "28783",
            name: "Xã Sơn Đông",
            level: "Xã"
          },
          {
            id: "28786",
            name: "Xã Phú Hưng",
            level: "Xã"
          },
          {
            id: "28789",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "28792",
            name: "Xã Mỹ Thạnh An",
            level: "Xã"
          },
          {
            id: "28795",
            name: "Xã Nhơn Thạnh",
            level: "Xã"
          },
          {
            id: "28798",
            name: "Xã Phú Nhuận",
            level: "Xã"
          }
        ]
      },
      {
        id: "831",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "28801",
            name: "Thị trấn Châu Thành",
            level: "Thị trấn"
          },
          {
            id: "28804",
            name: "Xã Tân Thạch",
            level: "Xã"
          },
          {
            id: "28807",
            name: "Xã Qưới Sơn",
            level: "Xã"
          },
          {
            id: "28810",
            name: "Xã An Khánh",
            level: "Xã"
          },
          {
            id: "28813",
            name: "Xã Giao Long",
            level: "Xã"
          },
          {
            id: "28819",
            name: "Xã Phú Túc",
            level: "Xã"
          },
          {
            id: "28822",
            name: "Xã Phú Đức",
            level: "Xã"
          },
          {
            id: "28825",
            name: "Xã Phú An Hòa",
            level: "Xã"
          },
          {
            id: "28828",
            name: "Xã An Phước",
            level: "Xã"
          },
          {
            id: "28831",
            name: "Xã Tam Phước",
            level: "Xã"
          },
          {
            id: "28834",
            name: "Xã Thành Triệu",
            level: "Xã"
          },
          {
            id: "28837",
            name: "Xã Tường Đa",
            level: "Xã"
          },
          {
            id: "28840",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "28843",
            name: "Xã Quới Thành",
            level: "Xã"
          },
          {
            id: "28846",
            name: "Xã Phước Thạnh",
            level: "Xã"
          },
          {
            id: "28849",
            name: "Xã An Hóa",
            level: "Xã"
          },
          {
            id: "28852",
            name: "Xã Tiên Long",
            level: "Xã"
          },
          {
            id: "28855",
            name: "Xã An Hiệp",
            level: "Xã"
          },
          {
            id: "28858",
            name: "Xã Hữu Định",
            level: "Xã"
          },
          {
            id: "28861",
            name: "Xã Tiên Thủy",
            level: "Xã"
          },
          {
            id: "28864",
            name: "Xã Sơn Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "832",
        name: "Huyện Chợ Lách",
        wards: [
          {
            id: "28870",
            name: "Thị trấn Chợ Lách",
            level: "Thị trấn"
          },
          {
            id: "28873",
            name: "Xã Phú Phụng",
            level: "Xã"
          },
          {
            id: "28876",
            name: "Xã Sơn Định",
            level: "Xã"
          },
          {
            id: "28879",
            name: "Xã Vĩnh Bình",
            level: "Xã"
          },
          {
            id: "28882",
            name: "Xã Hòa Nghĩa",
            level: "Xã"
          },
          {
            id: "28885",
            name: "Xã Long Thới",
            level: "Xã"
          },
          {
            id: "28888",
            name: "Xã Phú Sơn",
            level: "Xã"
          },
          {
            id: "28891",
            name: "Xã Tân Thiềng",
            level: "Xã"
          },
          {
            id: "28894",
            name: "Xã Vĩnh Thành",
            level: "Xã"
          },
          {
            id: "28897",
            name: "Xã Vĩnh Hòa",
            level: "Xã"
          },
          {
            id: "28900",
            name: "Xã Hưng Khánh Trung B",
            level: "Xã"
          }
        ]
      },
      {
        id: "833",
        name: "Huyện Mỏ Cày Nam",
        wards: [
          {
            id: "28903",
            name: "Thị trấn Mỏ Cày",
            level: "Thị trấn"
          },
          {
            id: "28930",
            name: "Xã Định Thủy",
            level: "Xã"
          },
          {
            id: "28939",
            name: "Xã Đa Phước Hội",
            level: "Xã"
          },
          {
            id: "28940",
            name: "Xã Tân Hội",
            level: "Xã"
          },
          {
            id: "28942",
            name: "Xã Phước Hiệp",
            level: "Xã"
          },
          {
            id: "28945",
            name: "Xã Bình Khánh ",
            level: "Xã"
          },
          {
            id: "28951",
            name: "Xã An Thạnh",
            level: "Xã"
          },
          {
            id: "28957",
            name: "Xã An Định",
            level: "Xã"
          },
          {
            id: "28960",
            name: "Xã Thành Thới B",
            level: "Xã"
          },
          {
            id: "28963",
            name: "Xã Tân Trung",
            level: "Xã"
          },
          {
            id: "28966",
            name: "Xã An Thới",
            level: "Xã"
          },
          {
            id: "28969",
            name: "Xã Thành Thới A",
            level: "Xã"
          },
          {
            id: "28972",
            name: "Xã Minh Đức",
            level: "Xã"
          },
          {
            id: "28975",
            name: "Xã Ngãi Đăng",
            level: "Xã"
          },
          {
            id: "28978",
            name: "Xã Cẩm Sơn",
            level: "Xã"
          },
          {
            id: "28981",
            name: "Xã Hương Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "834",
        name: "Huyện Giồng Trôm",
        wards: [
          {
            id: "28984",
            name: "Thị trấn Giồng Trôm",
            level: "Thị trấn"
          },
          {
            id: "28987",
            name: "Xã Phong Nẫm",
            level: "Xã"
          },
          {
            id: "28993",
            name: "Xã Mỹ Thạnh",
            level: "Xã"
          },
          {
            id: "28996",
            name: "Xã Châu Hòa",
            level: "Xã"
          },
          {
            id: "28999",
            name: "Xã Lương Hòa",
            level: "Xã"
          },
          {
            id: "29002",
            name: "Xã Lương Quới",
            level: "Xã"
          },
          {
            id: "29005",
            name: "Xã Lương Phú",
            level: "Xã"
          },
          {
            id: "29008",
            name: "Xã Châu Bình",
            level: "Xã"
          },
          {
            id: "29011",
            name: "Xã Thuận Điền",
            level: "Xã"
          },
          {
            id: "29014",
            name: "Xã Sơn Phú",
            level: "Xã"
          },
          {
            id: "29017",
            name: "Xã Bình Hoà",
            level: "Xã"
          },
          {
            id: "29020",
            name: "Xã Phước Long",
            level: "Xã"
          },
          {
            id: "29023",
            name: "Xã Hưng Phong",
            level: "Xã"
          },
          {
            id: "29026",
            name: "Xã Long Mỹ",
            level: "Xã"
          },
          {
            id: "29029",
            name: "Xã Tân Hào",
            level: "Xã"
          },
          {
            id: "29032",
            name: "Xã Bình Thành",
            level: "Xã"
          },
          {
            id: "29035",
            name: "Xã Tân Thanh",
            level: "Xã"
          },
          {
            id: "29038",
            name: "Xã Tân Lợi Thạnh",
            level: "Xã"
          },
          {
            id: "29041",
            name: "Xã Thạnh Phú Đông",
            level: "Xã"
          },
          {
            id: "29044",
            name: "Xã Hưng Nhượng",
            level: "Xã"
          },
          {
            id: "29047",
            name: "Xã Hưng Lễ",
            level: "Xã"
          }
        ]
      },
      {
        id: "835",
        name: "Huyện Bình Đại",
        wards: [
          {
            id: "29050",
            name: "Thị trấn Bình Đại",
            level: "Thị trấn"
          },
          {
            id: "29053",
            name: "Xã Tam Hiệp",
            level: "Xã"
          },
          {
            id: "29056",
            name: "Xã Long Định",
            level: "Xã"
          },
          {
            id: "29059",
            name: "Xã Long Hòa",
            level: "Xã"
          },
          {
            id: "29062",
            name: "Xã Phú Thuận",
            level: "Xã"
          },
          {
            id: "29065",
            name: "Xã Vang Quới Tây",
            level: "Xã"
          },
          {
            id: "29068",
            name: "Xã Vang Quới Đông",
            level: "Xã"
          },
          {
            id: "29071",
            name: "Xã Châu Hưng",
            level: "Xã"
          },
          {
            id: "29074",
            name: "Xã Phú Vang",
            level: "Xã"
          },
          {
            id: "29077",
            name: "Xã Lộc Thuận",
            level: "Xã"
          },
          {
            id: "29080",
            name: "Xã Định Trung",
            level: "Xã"
          },
          {
            id: "29083",
            name: "Xã Thới Lai",
            level: "Xã"
          },
          {
            id: "29086",
            name: "Xã Bình Thới",
            level: "Xã"
          },
          {
            id: "29089",
            name: "Xã Phú Long",
            level: "Xã"
          },
          {
            id: "29092",
            name: "Xã Bình Thắng",
            level: "Xã"
          },
          {
            id: "29095",
            name: "Xã Thạnh Trị",
            level: "Xã"
          },
          {
            id: "29098",
            name: "Xã Đại Hòa Lộc",
            level: "Xã"
          },
          {
            id: "29101",
            name: "Xã Thừa Đức",
            level: "Xã"
          },
          {
            id: "29104",
            name: "Xã Thạnh Phước",
            level: "Xã"
          },
          {
            id: "29107",
            name: "Xã Thới Thuận",
            level: "Xã"
          }
        ]
      },
      {
        id: "836",
        name: "Huyện Ba Tri",
        wards: [
          {
            id: "29110",
            name: "Thị trấn Ba Tri",
            level: "Thị trấn"
          },
          {
            id: "29113",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "29116",
            name: "Xã Mỹ Hòa",
            level: "Xã"
          },
          {
            id: "29119",
            name: "Xã Tân Xuân",
            level: "Xã"
          },
          {
            id: "29122",
            name: "Xã Mỹ Chánh",
            level: "Xã"
          },
          {
            id: "29125",
            name: "Xã Bảo Thạnh",
            level: "Xã"
          },
          {
            id: "29128",
            name: "Xã An Phú Trung",
            level: "Xã"
          },
          {
            id: "29131",
            name: "Xã Mỹ Thạnh",
            level: "Xã"
          },
          {
            id: "29134",
            name: "Xã Mỹ Nhơn",
            level: "Xã"
          },
          {
            id: "29137",
            name: "Xã Phước Ngãi",
            level: "Xã"
          },
          {
            id: "29143",
            name: "Xã An Ngãi Trung",
            level: "Xã"
          },
          {
            id: "29146",
            name: "Xã Phú Lễ",
            level: "Xã"
          },
          {
            id: "29149",
            name: "Xã An Bình Tây",
            level: "Xã"
          },
          {
            id: "29152",
            name: "Xã Bảo Thuận",
            level: "Xã"
          },
          {
            id: "29155",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "29158",
            name: "Xã An Ngãi Tây",
            level: "Xã"
          },
          {
            id: "29161",
            name: "Xã An Hiệp",
            level: "Xã"
          },
          {
            id: "29164",
            name: "Xã Vĩnh Hòa",
            level: "Xã"
          },
          {
            id: "29167",
            name: "Xã Tân Thủy",
            level: "Xã"
          },
          {
            id: "29170",
            name: "Xã Vĩnh An",
            level: "Xã"
          },
          {
            id: "29173",
            name: "Xã An Đức",
            level: "Xã"
          },
          {
            id: "29176",
            name: "Xã An Hòa Tây",
            level: "Xã"
          },
          {
            id: "29179",
            name: "Xã An Thủy",
            level: "Xã"
          }
        ]
      },
      {
        id: "837",
        name: "Huyện Thạnh Phú",
        wards: [
          {
            id: "29182",
            name: "Thị trấn Thạnh Phú",
            level: "Thị trấn"
          },
          {
            id: "29185",
            name: "Xã Phú Khánh",
            level: "Xã"
          },
          {
            id: "29188",
            name: "Xã Đại Điền",
            level: "Xã"
          },
          {
            id: "29191",
            name: "Xã Quới Điền",
            level: "Xã"
          },
          {
            id: "29194",
            name: "Xã Tân Phong",
            level: "Xã"
          },
          {
            id: "29197",
            name: "Xã Mỹ Hưng",
            level: "Xã"
          },
          {
            id: "29200",
            name: "Xã An Thạnh",
            level: "Xã"
          },
          {
            id: "29203",
            name: "Xã Thới Thạnh",
            level: "Xã"
          },
          {
            id: "29206",
            name: "Xã Hòa Lợi",
            level: "Xã"
          },
          {
            id: "29209",
            name: "Xã An Điền",
            level: "Xã"
          },
          {
            id: "29212",
            name: "Xã Bình Thạnh",
            level: "Xã"
          },
          {
            id: "29215",
            name: "Xã An Thuận",
            level: "Xã"
          },
          {
            id: "29218",
            name: "Xã An Quy",
            level: "Xã"
          },
          {
            id: "29221",
            name: "Xã Thạnh Hải",
            level: "Xã"
          },
          {
            id: "29224",
            name: "Xã An Nhơn",
            level: "Xã"
          },
          {
            id: "29227",
            name: "Xã Giao Thạnh",
            level: "Xã"
          },
          {
            id: "29230",
            name: "Xã Thạnh Phong",
            level: "Xã"
          },
          {
            id: "29233",
            name: "Xã Mỹ An",
            level: "Xã"
          }
        ]
      },
      {
        id: "838",
        name: "Huyện Mỏ Cày Bắc",
        wards: [
          {
            id: "28889",
            name: "Xã Phú Mỹ",
            level: "Xã"
          },
          {
            id: "28901",
            name: "Xã Hưng Khánh Trung A",
            level: "Xã"
          },
          {
            id: "28906",
            name: "Xã Thanh Tân",
            level: "Xã"
          },
          {
            id: "28909",
            name: "Xã Thạnh Ngãi",
            level: "Xã"
          },
          {
            id: "28912",
            name: "Xã Tân Phú Tây",
            level: "Xã"
          },
          {
            id: "28915",
            name: "Xã Phước Mỹ Trung",
            level: "Xã"
          },
          {
            id: "28918",
            name: "Xã Tân Thành Bình",
            level: "Xã"
          },
          {
            id: "28921",
            name: "Xã Thành An",
            level: "Xã"
          },
          {
            id: "28924",
            name: "Xã Hòa Lộc",
            level: "Xã"
          },
          {
            id: "28927",
            name: "Xã Tân Thanh Tây",
            level: "Xã"
          },
          {
            id: "28933",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "28936",
            name: "Xã Nhuận Phú Tân",
            level: "Xã"
          },
          {
            id: "28948",
            name: "Xã Khánh Thạnh Tân",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "84",
    name: "Tỉnh Trà Vinh",
    districts: [
      {
        id: "842",
        name: "Thành phố Trà Vinh",
        wards: [
          {
            id: "29236",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "29239",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "29242",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "29245",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "29248",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "29251",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "29254",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "29257",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "29260",
            name: "Phường 9",
            level: "Phường"
          },
          {
            id: "29263",
            name: "Xã Long Đức",
            level: "Xã"
          }
        ]
      },
      {
        id: "844",
        name: "Huyện Càng Long",
        wards: [
          {
            id: "29266",
            name: "Thị trấn Càng Long",
            level: "Thị trấn"
          },
          {
            id: "29269",
            name: "Xã Mỹ Cẩm",
            level: "Xã"
          },
          {
            id: "29272",
            name: "Xã An Trường A",
            level: "Xã"
          },
          {
            id: "29275",
            name: "Xã An Trường",
            level: "Xã"
          },
          {
            id: "29278",
            name: "Xã Huyền Hội",
            level: "Xã"
          },
          {
            id: "29281",
            name: "Xã Tân An",
            level: "Xã"
          },
          {
            id: "29284",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "29287",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "29290",
            name: "Xã Phương Thạnh",
            level: "Xã"
          },
          {
            id: "29293",
            name: "Xã Đại Phúc",
            level: "Xã"
          },
          {
            id: "29296",
            name: "Xã Đại Phước",
            level: "Xã"
          },
          {
            id: "29299",
            name: "Xã Nhị Long Phú",
            level: "Xã"
          },
          {
            id: "29302",
            name: "Xã Nhị Long",
            level: "Xã"
          },
          {
            id: "29305",
            name: "Xã Đức Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "845",
        name: "Huyện Cầu Kè",
        wards: [
          {
            id: "29308",
            name: "Thị trấn Cầu Kè",
            level: "Thị trấn"
          },
          {
            id: "29311",
            name: "Xã Hòa Ân",
            level: "Xã"
          },
          {
            id: "29314",
            name: "Xã Châu Điền",
            level: "Xã"
          },
          {
            id: "29317",
            name: "Xã An Phú Tân",
            level: "Xã"
          },
          {
            id: "29320",
            name: "Xã Hoà Tân",
            level: "Xã"
          },
          {
            id: "29323",
            name: "Xã Ninh Thới",
            level: "Xã"
          },
          {
            id: "29326",
            name: "Xã Phong Phú",
            level: "Xã"
          },
          {
            id: "29329",
            name: "Xã Phong Thạnh",
            level: "Xã"
          },
          {
            id: "29332",
            name: "Xã Tam Ngãi",
            level: "Xã"
          },
          {
            id: "29335",
            name: "Xã Thông Hòa",
            level: "Xã"
          },
          {
            id: "29338",
            name: "Xã Thạnh Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "846",
        name: "Huyện Tiểu Cần",
        wards: [
          {
            id: "29341",
            name: "Thị trấn Tiểu Cần",
            level: "Thị trấn"
          },
          {
            id: "29344",
            name: "Thị trấn Cầu Quan",
            level: "Thị trấn"
          },
          {
            id: "29347",
            name: "Xã Phú Cần",
            level: "Xã"
          },
          {
            id: "29350",
            name: "Xã Hiếu Tử",
            level: "Xã"
          },
          {
            id: "29353",
            name: "Xã Hiếu Trung",
            level: "Xã"
          },
          {
            id: "29356",
            name: "Xã Long Thới",
            level: "Xã"
          },
          {
            id: "29359",
            name: "Xã Hùng Hòa",
            level: "Xã"
          },
          {
            id: "29362",
            name: "Xã Tân Hùng",
            level: "Xã"
          },
          {
            id: "29365",
            name: "Xã Tập Ngãi",
            level: "Xã"
          },
          {
            id: "29368",
            name: "Xã Ngãi Hùng",
            level: "Xã"
          },
          {
            id: "29371",
            name: "Xã Tân Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "847",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "29374",
            name: "Thị trấn Châu Thành",
            level: "Thị trấn"
          },
          {
            id: "29377",
            name: "Xã Đa Lộc",
            level: "Xã"
          },
          {
            id: "29380",
            name: "Xã Mỹ Chánh",
            level: "Xã"
          },
          {
            id: "29383",
            name: "Xã Thanh Mỹ",
            level: "Xã"
          },
          {
            id: "29386",
            name: "Xã Lương Hoà A",
            level: "Xã"
          },
          {
            id: "29389",
            name: "Xã Lương Hòa",
            level: "Xã"
          },
          {
            id: "29392",
            name: "Xã Song Lộc",
            level: "Xã"
          },
          {
            id: "29395",
            name: "Xã Nguyệt Hóa",
            level: "Xã"
          },
          {
            id: "29398",
            name: "Xã Hòa Thuận",
            level: "Xã"
          },
          {
            id: "29401",
            name: "Xã Hòa Lợi",
            level: "Xã"
          },
          {
            id: "29404",
            name: "Xã Phước Hảo",
            level: "Xã"
          },
          {
            id: "29407",
            name: "Xã Hưng Mỹ",
            level: "Xã"
          },
          {
            id: "29410",
            name: "Xã Hòa Minh",
            level: "Xã"
          },
          {
            id: "29413",
            name: "Xã Long Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "848",
        name: "Huyện Cầu Ngang",
        wards: [
          {
            id: "29416",
            name: "Thị trấn Cầu Ngang",
            level: "Thị trấn"
          },
          {
            id: "29419",
            name: "Thị trấn Mỹ Long",
            level: "Thị trấn"
          },
          {
            id: "29422",
            name: "Xã Mỹ Long Bắc",
            level: "Xã"
          },
          {
            id: "29425",
            name: "Xã Mỹ Long Nam",
            level: "Xã"
          },
          {
            id: "29428",
            name: "Xã Mỹ Hòa",
            level: "Xã"
          },
          {
            id: "29431",
            name: "Xã Vĩnh Kim",
            level: "Xã"
          },
          {
            id: "29434",
            name: "Xã Kim Hòa",
            level: "Xã"
          },
          {
            id: "29437",
            name: "Xã Hiệp Hòa",
            level: "Xã"
          },
          {
            id: "29440",
            name: "Xã Thuận Hòa",
            level: "Xã"
          },
          {
            id: "29443",
            name: "Xã Long Sơn",
            level: "Xã"
          },
          {
            id: "29446",
            name: "Xã Nhị Trường",
            level: "Xã"
          },
          {
            id: "29449",
            name: "Xã Trường Thọ",
            level: "Xã"
          },
          {
            id: "29452",
            name: "Xã Hiệp Mỹ Đông",
            level: "Xã"
          },
          {
            id: "29455",
            name: "Xã Hiệp Mỹ Tây",
            level: "Xã"
          },
          {
            id: "29458",
            name: "Xã Thạnh Hòa Sơn",
            level: "Xã"
          }
        ]
      },
      {
        id: "849",
        name: "Huyện Trà Cú",
        wards: [
          {
            id: "29461",
            name: "Thị trấn Trà Cú",
            level: "Thị trấn"
          },
          {
            id: "29462",
            name: "Thị trấn Định An",
            level: "Thị trấn"
          },
          {
            id: "29464",
            name: "Xã Phước Hưng",
            level: "Xã"
          },
          {
            id: "29467",
            name: "Xã Tập Sơn",
            level: "Xã"
          },
          {
            id: "29470",
            name: "Xã Tân Sơn",
            level: "Xã"
          },
          {
            id: "29473",
            name: "Xã An Quảng Hữu",
            level: "Xã"
          },
          {
            id: "29476",
            name: "Xã Lưu Nghiệp Anh",
            level: "Xã"
          },
          {
            id: "29479",
            name: "Xã Ngãi Xuyên",
            level: "Xã"
          },
          {
            id: "29482",
            name: "Xã Kim Sơn",
            level: "Xã"
          },
          {
            id: "29485",
            name: "Xã Thanh Sơn",
            level: "Xã"
          },
          {
            id: "29488",
            name: "Xã Hàm Giang",
            level: "Xã"
          },
          {
            id: "29489",
            name: "Xã Hàm Tân",
            level: "Xã"
          },
          {
            id: "29491",
            name: "Xã Đại An",
            level: "Xã"
          },
          {
            id: "29494",
            name: "Xã Định An",
            level: "Xã"
          },
          {
            id: "29503",
            name: "Xã Ngọc Biên",
            level: "Xã"
          },
          {
            id: "29506",
            name: "Xã Long Hiệp",
            level: "Xã"
          },
          {
            id: "29509",
            name: "Xã Tân Hiệp",
            level: "Xã"
          }
        ]
      },
      {
        id: "850",
        name: "Huyện Duyên Hải",
        wards: [
          {
            id: "29497",
            name: "Xã Đôn Xuân",
            level: "Xã"
          },
          {
            id: "29500",
            name: "Xã Đôn Châu",
            level: "Xã"
          },
          {
            id: "29513",
            name: "Thị trấn Long Thành",
            level: "Thị trấn"
          },
          {
            id: "29521",
            name: "Xã Long Khánh",
            level: "Xã"
          },
          {
            id: "29530",
            name: "Xã Ngũ Lạc",
            level: "Xã"
          },
          {
            id: "29533",
            name: "Xã Long Vĩnh",
            level: "Xã"
          },
          {
            id: "29536",
            name: "Xã Đông Hải",
            level: "Xã"
          }
        ]
      },
      {
        id: "851",
        name: "Thị xã Duyên Hải",
        wards: [
          {
            id: "29512",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "29515",
            name: "Xã Long Toàn",
            level: "Xã"
          },
          {
            id: "29516",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "29518",
            name: "Xã Long Hữu",
            level: "Xã"
          },
          {
            id: "29524",
            name: "Xã Dân Thành",
            level: "Xã"
          },
          {
            id: "29527",
            name: "Xã Trường Long Hòa",
            level: "Xã"
          },
          {
            id: "29539",
            name: "Xã Hiệp Thạnh",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "86",
    name: "Tỉnh Vĩnh Long",
    districts: [
      {
        id: "855",
        name: "Thành phố Vĩnh Long",
        wards: [
          {
            id: "29542",
            name: "Phường 9",
            level: "Phường"
          },
          {
            id: "29545",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "29548",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "29551",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "29554",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "29557",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "29560",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "29563",
            name: "Phường Tân Ngãi",
            level: "Phường"
          },
          {
            id: "29566",
            name: "Phường Tân Hòa",
            level: "Phường"
          },
          {
            id: "29569",
            name: "Phường Tân Hội",
            level: "Phường"
          },
          {
            id: "29572",
            name: "Phường Trường An",
            level: "Phường"
          }
        ]
      },
      {
        id: "857",
        name: "Huyện Long Hồ",
        wards: [
          {
            id: "29575",
            name: "Thị trấn Long Hồ",
            level: "Thị trấn"
          },
          {
            id: "29578",
            name: "Xã Đồng Phú",
            level: "Xã"
          },
          {
            id: "29581",
            name: "Xã Bình Hòa Phước",
            level: "Xã"
          },
          {
            id: "29584",
            name: "Xã Hòa Ninh",
            level: "Xã"
          },
          {
            id: "29587",
            name: "Xã An Bình",
            level: "Xã"
          },
          {
            id: "29590",
            name: "Xã Thanh Đức",
            level: "Xã"
          },
          {
            id: "29593",
            name: "Xã Tân Hạnh",
            level: "Xã"
          },
          {
            id: "29596",
            name: "Xã Phước Hậu",
            level: "Xã"
          },
          {
            id: "29599",
            name: "Xã Long Phước",
            level: "Xã"
          },
          {
            id: "29602",
            name: "Xã Phú Đức",
            level: "Xã"
          },
          {
            id: "29605",
            name: "Xã Lộc Hòa",
            level: "Xã"
          },
          {
            id: "29608",
            name: "Xã Long An",
            level: "Xã"
          },
          {
            id: "29611",
            name: "Xã Phú Quới",
            level: "Xã"
          },
          {
            id: "29614",
            name: "Xã Thạnh Quới",
            level: "Xã"
          },
          {
            id: "29617",
            name: "Xã Hòa Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "858",
        name: "Huyện Mang Thít",
        wards: [
          {
            id: "29623",
            name: "Xã Mỹ An",
            level: "Xã"
          },
          {
            id: "29626",
            name: "Xã Mỹ Phước",
            level: "Xã"
          },
          {
            id: "29629",
            name: "Xã An Phước",
            level: "Xã"
          },
          {
            id: "29632",
            name: "Xã Nhơn Phú",
            level: "Xã"
          },
          {
            id: "29635",
            name: "Xã Long Mỹ",
            level: "Xã"
          },
          {
            id: "29638",
            name: "Xã Hòa Tịnh",
            level: "Xã"
          },
          {
            id: "29641",
            name: "Thị trấn Cái Nhum",
            level: "Thị trấn"
          },
          {
            id: "29644",
            name: "Xã Bình Phước",
            level: "Xã"
          },
          {
            id: "29647",
            name: "Xã Chánh An",
            level: "Xã"
          },
          {
            id: "29650",
            name: "Xã Tân An Hội",
            level: "Xã"
          },
          {
            id: "29653",
            name: "Xã Tân Long",
            level: "Xã"
          },
          {
            id: "29656",
            name: "Xã Tân Long Hội",
            level: "Xã"
          }
        ]
      },
      {
        id: "859",
        name: "Huyện  Vũng Liêm",
        wards: [
          {
            id: "29659",
            name: "Thị trấn Vũng Liêm",
            level: "Thị trấn"
          },
          {
            id: "29662",
            name: "Xã Tân Quới Trung",
            level: "Xã"
          },
          {
            id: "29665",
            name: "Xã Quới Thiện",
            level: "Xã"
          },
          {
            id: "29668",
            name: "Xã Quới An",
            level: "Xã"
          },
          {
            id: "29671",
            name: "Xã Trung Chánh",
            level: "Xã"
          },
          {
            id: "29674",
            name: "Xã Tân An Luông",
            level: "Xã"
          },
          {
            id: "29677",
            name: "Xã Thanh Bình",
            level: "Xã"
          },
          {
            id: "29680",
            name: "Xã Trung Thành Tây",
            level: "Xã"
          },
          {
            id: "29683",
            name: "Xã Trung Hiệp",
            level: "Xã"
          },
          {
            id: "29686",
            name: "Xã Hiếu Phụng",
            level: "Xã"
          },
          {
            id: "29689",
            name: "Xã Trung Thành Đông",
            level: "Xã"
          },
          {
            id: "29692",
            name: "Xã Trung Thành",
            level: "Xã"
          },
          {
            id: "29695",
            name: "Xã Trung Hiếu",
            level: "Xã"
          },
          {
            id: "29698",
            name: "Xã Trung Ngãi",
            level: "Xã"
          },
          {
            id: "29701",
            name: "Xã Hiếu Thuận",
            level: "Xã"
          },
          {
            id: "29704",
            name: "Xã Trung Nghĩa",
            level: "Xã"
          },
          {
            id: "29707",
            name: "Xã Trung An",
            level: "Xã"
          },
          {
            id: "29710",
            name: "Xã Hiếu Nhơn",
            level: "Xã"
          },
          {
            id: "29713",
            name: "Xã Hiếu Thành",
            level: "Xã"
          },
          {
            id: "29716",
            name: "Xã Hiếu Nghĩa",
            level: "Xã"
          }
        ]
      },
      {
        id: "860",
        name: "Huyện Tam Bình",
        wards: [
          {
            id: "29719",
            name: "Thị trấn Tam Bình",
            level: "Thị trấn"
          },
          {
            id: "29722",
            name: "Xã Tân Lộc",
            level: "Xã"
          },
          {
            id: "29725",
            name: "Xã Phú Thịnh",
            level: "Xã"
          },
          {
            id: "29728",
            name: "Xã Hậu Lộc",
            level: "Xã"
          },
          {
            id: "29731",
            name: "Xã Hòa Thạnh",
            level: "Xã"
          },
          {
            id: "29734",
            name: "Xã Hoà Lộc",
            level: "Xã"
          },
          {
            id: "29737",
            name: "Xã Phú Lộc",
            level: "Xã"
          },
          {
            id: "29740",
            name: "Xã Song Phú",
            level: "Xã"
          },
          {
            id: "29743",
            name: "Xã Hòa Hiệp",
            level: "Xã"
          },
          {
            id: "29746",
            name: "Xã Mỹ Lộc",
            level: "Xã"
          },
          {
            id: "29749",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "29752",
            name: "Xã Long Phú",
            level: "Xã"
          },
          {
            id: "29755",
            name: "Xã Mỹ Thạnh Trung",
            level: "Xã"
          },
          {
            id: "29758",
            name: "Xã Tường Lộc",
            level: "Xã"
          },
          {
            id: "29761",
            name: "Xã Loan Mỹ",
            level: "Xã"
          },
          {
            id: "29764",
            name: "Xã Ngãi Tứ",
            level: "Xã"
          },
          {
            id: "29767",
            name: "Xã Bình Ninh",
            level: "Xã"
          }
        ]
      },
      {
        id: "861",
        name: "Thị xã Bình Minh",
        wards: [
          {
            id: "29770",
            name: "Phường Cái Vồn",
            level: "Phường"
          },
          {
            id: "29771",
            name: "Phường Thành Phước",
            level: "Phường"
          },
          {
            id: "29806",
            name: "Xã Thuận An",
            level: "Xã"
          },
          {
            id: "29809",
            name: "Xã Đông Thạnh",
            level: "Xã"
          },
          {
            id: "29812",
            name: "Xã Đông Bình",
            level: "Xã"
          },
          {
            id: "29813",
            name: "Phường Đông Thuận",
            level: "Phường"
          },
          {
            id: "29815",
            name: "Xã Mỹ Hòa",
            level: "Xã"
          },
          {
            id: "29818",
            name: "Xã Đông Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "862",
        name: "Huyện Trà Ôn",
        wards: [
          {
            id: "29821",
            name: "Thị trấn Trà Ôn",
            level: "Thị trấn"
          },
          {
            id: "29824",
            name: "Xã Xuân Hiệp",
            level: "Xã"
          },
          {
            id: "29827",
            name: "Xã Nhơn Bình",
            level: "Xã"
          },
          {
            id: "29830",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "29833",
            name: "Xã Thới Hòa",
            level: "Xã"
          },
          {
            id: "29836",
            name: "Xã Trà Côn",
            level: "Xã"
          },
          {
            id: "29839",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "29842",
            name: "Xã Hựu Thành",
            level: "Xã"
          },
          {
            id: "29845",
            name: "Xã Vĩnh Xuân",
            level: "Xã"
          },
          {
            id: "29848",
            name: "Xã Thuận Thới",
            level: "Xã"
          },
          {
            id: "29851",
            name: "Xã Phú Thành",
            level: "Xã"
          },
          {
            id: "29854",
            name: "Xã Thiện Mỹ",
            level: "Xã"
          },
          {
            id: "29857",
            name: "Xã Lục Sỹ Thành",
            level: "Xã"
          },
          {
            id: "29860",
            name: "Xã Tích Thiện",
            level: "Xã"
          }
        ]
      },
      {
        id: "863",
        name: "Huyện Bình Tân",
        wards: [
          {
            id: "29773",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "29776",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "29779",
            name: "Xã Thành Trung",
            level: "Xã"
          },
          {
            id: "29782",
            name: "Xã Tân An Thạnh",
            level: "Xã"
          },
          {
            id: "29785",
            name: "Xã Tân Lược",
            level: "Xã"
          },
          {
            id: "29788",
            name: "Xã Nguyễn Văn Thảnh",
            level: "Xã"
          },
          {
            id: "29791",
            name: "Xã Thành Lợi",
            level: "Xã"
          },
          {
            id: "29794",
            name: "Xã Mỹ Thuận",
            level: "Xã"
          },
          {
            id: "29797",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "29800",
            name: "Thị trấn Tân Quới",
            level: "Thị trấn"
          }
        ]
      }
    ]
  },
  {
    id: "87",
    name: "Tỉnh Đồng Tháp",
    districts: [
      {
        id: "866",
        name: "Thành phố Cao Lãnh",
        wards: [
          {
            id: "29863",
            name: "Phường 11",
            level: "Phường"
          },
          {
            id: "29866",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "29869",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "29872",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "29875",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "29878",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "29881",
            name: "Xã Mỹ Ngãi",
            level: "Xã"
          },
          {
            id: "29884",
            name: "Xã Mỹ Tân",
            level: "Xã"
          },
          {
            id: "29887",
            name: "Xã Mỹ Trà",
            level: "Xã"
          },
          {
            id: "29888",
            name: "Phường Mỹ Phú",
            level: "Phường"
          },
          {
            id: "29890",
            name: "Xã Tân Thuận Tây",
            level: "Xã"
          },
          {
            id: "29892",
            name: "Phường Hoà Thuận",
            level: "Phường"
          },
          {
            id: "29893",
            name: "Xã Hòa An",
            level: "Xã"
          },
          {
            id: "29896",
            name: "Xã Tân Thuận Đông",
            level: "Xã"
          },
          {
            id: "29899",
            name: "Xã Tịnh Thới",
            level: "Xã"
          }
        ]
      },
      {
        id: "867",
        name: "Thành phố Sa Đéc",
        wards: [
          {
            id: "29902",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "29905",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "29908",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "29911",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "29914",
            name: "Xã Tân Khánh Đông",
            level: "Xã"
          },
          {
            id: "29917",
            name: "Phường Tân Quy Đông",
            level: "Phường"
          },
          {
            id: "29919",
            name: "Phường An Hoà",
            level: "Phường"
          },
          {
            id: "29920",
            name: "Xã Tân Quy Tây",
            level: "Xã"
          },
          {
            id: "29923",
            name: "Xã Tân Phú Đông",
            level: "Xã"
          }
        ]
      },
      {
        id: "868",
        name: "Thành phố Hồng Ngự",
        wards: [
          {
            id: "29954",
            name: "Phường An Lộc",
            level: "Phường"
          },
          {
            id: "29955",
            name: "Phường An Thạnh",
            level: "Phường"
          },
          {
            id: "29959",
            name: "Xã Bình Thạnh",
            level: "Xã"
          },
          {
            id: "29965",
            name: "Xã Tân Hội",
            level: "Xã"
          },
          {
            id: "29978",
            name: "Phường An Lạc",
            level: "Phường"
          },
          {
            id: "29986",
            name: "Phường An Bình B",
            level: "Phường"
          },
          {
            id: "29989",
            name: "Phường An Bình A",
            level: "Phường"
          }
        ]
      },
      {
        id: "869",
        name: "Huyện Tân Hồng",
        wards: [
          {
            id: "29926",
            name: "Thị trấn Sa Rài",
            level: "Thị trấn"
          },
          {
            id: "29929",
            name: "Xã Tân Hộ Cơ",
            level: "Xã"
          },
          {
            id: "29932",
            name: "Xã Thông Bình",
            level: "Xã"
          },
          {
            id: "29935",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "29938",
            name: "Xã Tân Thành A",
            level: "Xã"
          },
          {
            id: "29941",
            name: "Xã Tân Thành B",
            level: "Xã"
          },
          {
            id: "29944",
            name: "Xã Tân Phước",
            level: "Xã"
          },
          {
            id: "29947",
            name: "Xã Tân Công Chí",
            level: "Xã"
          },
          {
            id: "29950",
            name: "Xã An Phước",
            level: "Xã"
          }
        ]
      },
      {
        id: "870",
        name: "Huyện Hồng Ngự",
        wards: [
          {
            id: "29956",
            name: "Xã Thường Phước 1",
            level: "Xã"
          },
          {
            id: "29962",
            name: "Xã Thường Thới Hậu A",
            level: "Xã"
          },
          {
            id: "29971",
            name: "Thị trấn Thường Thới Tiền",
            level: "Thị trấn"
          },
          {
            id: "29974",
            name: "Xã Thường Phước 2",
            level: "Xã"
          },
          {
            id: "29977",
            name: "Xã Thường Lạc",
            level: "Xã"
          },
          {
            id: "29980",
            name: "Xã Long Khánh A",
            level: "Xã"
          },
          {
            id: "29983",
            name: "Xã Long Khánh B",
            level: "Xã"
          },
          {
            id: "29992",
            name: "Xã Long Thuận",
            level: "Xã"
          },
          {
            id: "29995",
            name: "Xã Phú Thuận B",
            level: "Xã"
          },
          {
            id: "29998",
            name: "Xã Phú Thuận A",
            level: "Xã"
          }
        ]
      },
      {
        id: "871",
        name: "Huyện Tam Nông",
        wards: [
          {
            id: "30001",
            name: "Thị trấn Tràm Chim",
            level: "Thị trấn"
          },
          {
            id: "30004",
            name: "Xã Hoà Bình",
            level: "Xã"
          },
          {
            id: "30007",
            name: "Xã Tân Công Sính",
            level: "Xã"
          },
          {
            id: "30010",
            name: "Xã Phú Hiệp",
            level: "Xã"
          },
          {
            id: "30013",
            name: "Xã Phú Đức",
            level: "Xã"
          },
          {
            id: "30016",
            name: "Xã Phú Thành B",
            level: "Xã"
          },
          {
            id: "30019",
            name: "Xã An Hòa",
            level: "Xã"
          },
          {
            id: "30022",
            name: "Xã An Long",
            level: "Xã"
          },
          {
            id: "30025",
            name: "Xã Phú Cường",
            level: "Xã"
          },
          {
            id: "30028",
            name: "Xã Phú Ninh",
            level: "Xã"
          },
          {
            id: "30031",
            name: "Xã Phú Thọ",
            level: "Xã"
          },
          {
            id: "30034",
            name: "Xã Phú Thành A",
            level: "Xã"
          }
        ]
      },
      {
        id: "872",
        name: "Huyện Tháp Mười",
        wards: [
          {
            id: "30037",
            name: "Thị trấn Mỹ An",
            level: "Thị trấn"
          },
          {
            id: "30040",
            name: "Xã Thạnh Lợi",
            level: "Xã"
          },
          {
            id: "30043",
            name: "Xã Hưng Thạnh",
            level: "Xã"
          },
          {
            id: "30046",
            name: "Xã Trường Xuân",
            level: "Xã"
          },
          {
            id: "30049",
            name: "Xã Tân Kiều",
            level: "Xã"
          },
          {
            id: "30052",
            name: "Xã Mỹ Hòa",
            level: "Xã"
          },
          {
            id: "30055",
            name: "Xã Mỹ Quý",
            level: "Xã"
          },
          {
            id: "30058",
            name: "Xã Mỹ Đông",
            level: "Xã"
          },
          {
            id: "30061",
            name: "Xã Đốc Binh Kiều",
            level: "Xã"
          },
          {
            id: "30064",
            name: "Xã Mỹ An",
            level: "Xã"
          },
          {
            id: "30067",
            name: "Xã Phú Điền",
            level: "Xã"
          },
          {
            id: "30070",
            name: "Xã Láng Biển",
            level: "Xã"
          },
          {
            id: "30073",
            name: "Xã Thanh Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "873",
        name: "Huyện Cao Lãnh",
        wards: [
          {
            id: "30076",
            name: "Thị trấn Mỹ Thọ",
            level: "Thị trấn"
          },
          {
            id: "30079",
            name: "Xã Gáo Giồng",
            level: "Xã"
          },
          {
            id: "30082",
            name: "Xã Phương Thịnh",
            level: "Xã"
          },
          {
            id: "30085",
            name: "Xã Ba Sao",
            level: "Xã"
          },
          {
            id: "30088",
            name: "Xã Phong Mỹ",
            level: "Xã"
          },
          {
            id: "30091",
            name: "Xã Tân Nghĩa",
            level: "Xã"
          },
          {
            id: "30094",
            name: "Xã Phương Trà",
            level: "Xã"
          },
          {
            id: "30097",
            name: "Xã Nhị Mỹ",
            level: "Xã"
          },
          {
            id: "30100",
            name: "Xã Mỹ Thọ",
            level: "Xã"
          },
          {
            id: "30103",
            name: "Xã Tân Hội Trung",
            level: "Xã"
          },
          {
            id: "30106",
            name: "Xã An Bình",
            level: "Xã"
          },
          {
            id: "30109",
            name: "Xã Mỹ Hội",
            level: "Xã"
          },
          {
            id: "30112",
            name: "Xã Mỹ Hiệp",
            level: "Xã"
          },
          {
            id: "30115",
            name: "Xã Mỹ Long",
            level: "Xã"
          },
          {
            id: "30118",
            name: "Xã Bình Hàng Trung",
            level: "Xã"
          },
          {
            id: "30121",
            name: "Xã Mỹ Xương",
            level: "Xã"
          },
          {
            id: "30124",
            name: "Xã Bình Hàng Tây",
            level: "Xã"
          },
          {
            id: "30127",
            name: "Xã Bình Thạnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "874",
        name: "Huyện Thanh Bình",
        wards: [
          {
            id: "30130",
            name: "Thị trấn Thanh Bình",
            level: "Thị trấn"
          },
          {
            id: "30133",
            name: "Xã Tân Quới",
            level: "Xã"
          },
          {
            id: "30136",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "30139",
            name: "Xã An Phong",
            level: "Xã"
          },
          {
            id: "30142",
            name: "Xã Phú Lợi",
            level: "Xã"
          },
          {
            id: "30145",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "30148",
            name: "Xã Bình Tấn",
            level: "Xã"
          },
          {
            id: "30151",
            name: "Xã Tân Huề",
            level: "Xã"
          },
          {
            id: "30154",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "30157",
            name: "Xã Tân Thạnh",
            level: "Xã"
          },
          {
            id: "30160",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "30163",
            name: "Xã Bình Thành",
            level: "Xã"
          },
          {
            id: "30166",
            name: "Xã Tân Long",
            level: "Xã"
          }
        ]
      },
      {
        id: "875",
        name: "Huyện Lấp Vò",
        wards: [
          {
            id: "30169",
            name: "Thị trấn Lấp Vò",
            level: "Thị trấn"
          },
          {
            id: "30172",
            name: "Xã Mỹ An Hưng A",
            level: "Xã"
          },
          {
            id: "30175",
            name: "Xã Tân Mỹ",
            level: "Xã"
          },
          {
            id: "30178",
            name: "Xã Mỹ An Hưng B",
            level: "Xã"
          },
          {
            id: "30181",
            name: "Xã Tân  Khánh Trung",
            level: "Xã"
          },
          {
            id: "30184",
            name: "Xã Long Hưng A",
            level: "Xã"
          },
          {
            id: "30187",
            name: "Xã Vĩnh Thạnh",
            level: "Xã"
          },
          {
            id: "30190",
            name: "Xã Long Hưng B",
            level: "Xã"
          },
          {
            id: "30193",
            name: "Xã Bình Thành",
            level: "Xã"
          },
          {
            id: "30196",
            name: "Xã Định An",
            level: "Xã"
          },
          {
            id: "30199",
            name: "Xã Định Yên",
            level: "Xã"
          },
          {
            id: "30202",
            name: "Xã Hội An Đông",
            level: "Xã"
          },
          {
            id: "30205",
            name: "Xã Bình Thạnh Trung",
            level: "Xã"
          }
        ]
      },
      {
        id: "876",
        name: "Huyện Lai Vung",
        wards: [
          {
            id: "30208",
            name: "Thị trấn Lai Vung",
            level: "Thị trấn"
          },
          {
            id: "30211",
            name: "Xã Tân Dương",
            level: "Xã"
          },
          {
            id: "30214",
            name: "Xã Hòa Thành",
            level: "Xã"
          },
          {
            id: "30217",
            name: "Xã Long Hậu",
            level: "Xã"
          },
          {
            id: "30220",
            name: "Xã Tân Phước",
            level: "Xã"
          },
          {
            id: "30223",
            name: "Xã Hòa Long",
            level: "Xã"
          },
          {
            id: "30226",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "30229",
            name: "Xã Long Thắng",
            level: "Xã"
          },
          {
            id: "30232",
            name: "Xã Vĩnh Thới",
            level: "Xã"
          },
          {
            id: "30235",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "30238",
            name: "Xã Định Hòa",
            level: "Xã"
          },
          {
            id: "30241",
            name: "Xã Phong Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "877",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "30244",
            name: "Thị trấn Cái Tàu Hạ",
            level: "Thị trấn"
          },
          {
            id: "30247",
            name: "Xã An Hiệp",
            level: "Xã"
          },
          {
            id: "30250",
            name: "Xã An Nhơn",
            level: "Xã"
          },
          {
            id: "30253",
            name: "Xã Tân Nhuận Đông",
            level: "Xã"
          },
          {
            id: "30256",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "30259",
            name: "Xã Tân Phú Trung",
            level: "Xã"
          },
          {
            id: "30262",
            name: "Xã Phú Long",
            level: "Xã"
          },
          {
            id: "30265",
            name: "Xã An Phú Thuận",
            level: "Xã"
          },
          {
            id: "30268",
            name: "Xã Phú Hựu",
            level: "Xã"
          },
          {
            id: "30271",
            name: "Xã An Khánh",
            level: "Xã"
          },
          {
            id: "30274",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "30277",
            name: "Xã Hòa Tân",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "89",
    name: "Tỉnh An Giang",
    districts: [
      {
        id: "883",
        name: "Thành phố Long Xuyên",
        wards: [
          {
            id: "30280",
            name: "Phường Mỹ Bình",
            level: "Phường"
          },
          {
            id: "30283",
            name: "Phường Mỹ Long",
            level: "Phường"
          },
          {
            id: "30285",
            name: "Phường Đông Xuyên",
            level: "Phường"
          },
          {
            id: "30286",
            name: "Phường Mỹ Xuyên",
            level: "Phường"
          },
          {
            id: "30289",
            name: "Phường Bình Đức",
            level: "Phường"
          },
          {
            id: "30292",
            name: "Phường Bình Khánh",
            level: "Phường"
          },
          {
            id: "30295",
            name: "Phường Mỹ Phước",
            level: "Phường"
          },
          {
            id: "30298",
            name: "Phường Mỹ Quý",
            level: "Phường"
          },
          {
            id: "30301",
            name: "Phường Mỹ Thới",
            level: "Phường"
          },
          {
            id: "30304",
            name: "Phường Mỹ Thạnh",
            level: "Phường"
          },
          {
            id: "30307",
            name: "Phường Mỹ Hòa",
            level: "Phường"
          },
          {
            id: "30310",
            name: "Xã Mỹ Khánh",
            level: "Xã"
          },
          {
            id: "30313",
            name: "Xã Mỹ Hoà Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "884",
        name: "Thành phố Châu Đốc",
        wards: [
          {
            id: "30316",
            name: "Phường Châu Phú B",
            level: "Phường"
          },
          {
            id: "30319",
            name: "Phường Châu Phú A",
            level: "Phường"
          },
          {
            id: "30322",
            name: "Phường Vĩnh Mỹ",
            level: "Phường"
          },
          {
            id: "30325",
            name: "Phường Núi Sam",
            level: "Phường"
          },
          {
            id: "30328",
            name: "Phường Vĩnh Ngươn",
            level: "Phường"
          },
          {
            id: "30331",
            name: "Xã Vĩnh Tế",
            level: "Xã"
          },
          {
            id: "30334",
            name: "Xã Vĩnh Châu",
            level: "Xã"
          }
        ]
      },
      {
        id: "886",
        name: "Huyện An Phú",
        wards: [
          {
            id: "30337",
            name: "Thị trấn An Phú",
            level: "Thị trấn"
          },
          {
            id: "30340",
            name: "Xã Khánh An",
            level: "Xã"
          },
          {
            id: "30341",
            name: "Thị Trấn Long Bình",
            level: "Thị trấn"
          },
          {
            id: "30343",
            name: "Xã Khánh Bình",
            level: "Xã"
          },
          {
            id: "30346",
            name: "Xã Quốc Thái",
            level: "Xã"
          },
          {
            id: "30349",
            name: "Xã Nhơn Hội",
            level: "Xã"
          },
          {
            id: "30352",
            name: "Xã Phú Hữu",
            level: "Xã"
          },
          {
            id: "30355",
            name: "Xã Phú Hội",
            level: "Xã"
          },
          {
            id: "30358",
            name: "Xã Phước Hưng",
            level: "Xã"
          },
          {
            id: "30361",
            name: "Xã Vĩnh Lộc",
            level: "Xã"
          },
          {
            id: "30364",
            name: "Xã Vĩnh Hậu",
            level: "Xã"
          },
          {
            id: "30367",
            name: "Xã Vĩnh Trường",
            level: "Xã"
          },
          {
            id: "30370",
            name: "Xã Vĩnh Hội Đông",
            level: "Xã"
          },
          {
            id: "30373",
            name: "Xã Đa Phước",
            level: "Xã"
          }
        ]
      },
      {
        id: "887",
        name: "Thị xã Tân Châu",
        wards: [
          {
            id: "30376",
            name: "Phường Long Thạnh",
            level: "Phường"
          },
          {
            id: "30377",
            name: "Phường Long Hưng",
            level: "Phường"
          },
          {
            id: "30378",
            name: "Phường Long Châu",
            level: "Phường"
          },
          {
            id: "30379",
            name: "Xã Phú Lộc",
            level: "Xã"
          },
          {
            id: "30382",
            name: "Xã Vĩnh Xương",
            level: "Xã"
          },
          {
            id: "30385",
            name: "Xã Vĩnh Hòa",
            level: "Xã"
          },
          {
            id: "30387",
            name: "Xã Tân Thạnh",
            level: "Xã"
          },
          {
            id: "30388",
            name: "Xã Tân An",
            level: "Xã"
          },
          {
            id: "30391",
            name: "Xã Long An",
            level: "Xã"
          },
          {
            id: "30394",
            name: "Phường Long Phú",
            level: "Phường"
          },
          {
            id: "30397",
            name: "Xã Châu Phong",
            level: "Xã"
          },
          {
            id: "30400",
            name: "Xã Phú Vĩnh",
            level: "Xã"
          },
          {
            id: "30403",
            name: "Xã Lê Chánh",
            level: "Xã"
          },
          {
            id: "30412",
            name: "Phường Long Sơn",
            level: "Phường"
          }
        ]
      },
      {
        id: "888",
        name: "Huyện Phú Tân",
        wards: [
          {
            id: "30406",
            name: "Thị trấn Phú Mỹ",
            level: "Thị trấn"
          },
          {
            id: "30409",
            name: "Thị trấn Chợ Vàm",
            level: "Thị trấn"
          },
          {
            id: "30415",
            name: "Xã Long Hoà",
            level: "Xã"
          },
          {
            id: "30418",
            name: "Xã Phú Long",
            level: "Xã"
          },
          {
            id: "30421",
            name: "Xã Phú Lâm",
            level: "Xã"
          },
          {
            id: "30424",
            name: "Xã Phú Hiệp",
            level: "Xã"
          },
          {
            id: "30427",
            name: "Xã Phú Thạnh",
            level: "Xã"
          },
          {
            id: "30430",
            name: "Xã Hoà Lạc",
            level: "Xã"
          },
          {
            id: "30433",
            name: "Xã Phú Thành",
            level: "Xã"
          },
          {
            id: "30436",
            name: "Xã Phú An",
            level: "Xã"
          },
          {
            id: "30439",
            name: "Xã Phú Xuân",
            level: "Xã"
          },
          {
            id: "30442",
            name: "Xã Hiệp Xương",
            level: "Xã"
          },
          {
            id: "30445",
            name: "Xã Phú Bình",
            level: "Xã"
          },
          {
            id: "30448",
            name: "Xã Phú Thọ",
            level: "Xã"
          },
          {
            id: "30451",
            name: "Xã Phú Hưng",
            level: "Xã"
          },
          {
            id: "30454",
            name: "Xã Bình Thạnh Đông",
            level: "Xã"
          },
          {
            id: "30457",
            name: "Xã Tân Hòa",
            level: "Xã"
          },
          {
            id: "30460",
            name: "Xã Tân Trung",
            level: "Xã"
          }
        ]
      },
      {
        id: "889",
        name: "Huyện Châu Phú",
        wards: [
          {
            id: "30463",
            name: "Thị trấn Cái Dầu",
            level: "Thị trấn"
          },
          {
            id: "30466",
            name: "Xã Khánh Hòa",
            level: "Xã"
          },
          {
            id: "30469",
            name: "Xã Mỹ Đức",
            level: "Xã"
          },
          {
            id: "30472",
            name: "Xã Mỹ Phú",
            level: "Xã"
          },
          {
            id: "30475",
            name: "Xã Ô Long Vỹ",
            level: "Xã"
          },
          {
            id: "30478",
            name: "Xã Vĩnh Thạnh Trung",
            level: "Xã"
          },
          {
            id: "30481",
            name: "Xã Thạnh Mỹ Tây",
            level: "Xã"
          },
          {
            id: "30484",
            name: "Xã Bình Long",
            level: "Xã"
          },
          {
            id: "30487",
            name: "Xã Bình Mỹ",
            level: "Xã"
          },
          {
            id: "30490",
            name: "Xã Bình Thủy",
            level: "Xã"
          },
          {
            id: "30493",
            name: "Xã Đào Hữu Cảnh",
            level: "Xã"
          },
          {
            id: "30496",
            name: "Xã Bình Phú",
            level: "Xã"
          },
          {
            id: "30499",
            name: "Xã Bình Chánh",
            level: "Xã"
          }
        ]
      },
      {
        id: "890",
        name: "Huyện Tịnh Biên",
        wards: [
          {
            id: "30502",
            name: "Thị trấn Nhà Bàng",
            level: "Thị trấn"
          },
          {
            id: "30505",
            name: "Thị trấn Chi Lăng",
            level: "Thị trấn"
          },
          {
            id: "30508",
            name: "Xã Núi Voi",
            level: "Xã"
          },
          {
            id: "30511",
            name: "Xã Nhơn Hưng",
            level: "Xã"
          },
          {
            id: "30514",
            name: "Xã An Phú",
            level: "Xã"
          },
          {
            id: "30517",
            name: "Xã Thới Sơn",
            level: "Xã"
          },
          {
            id: "30520",
            name: "Thị trấn Tịnh Biên",
            level: "Thị trấn"
          },
          {
            id: "30523",
            name: "Xã Văn Giáo",
            level: "Xã"
          },
          {
            id: "30526",
            name: "Xã An Cư",
            level: "Xã"
          },
          {
            id: "30529",
            name: "Xã An Nông",
            level: "Xã"
          },
          {
            id: "30532",
            name: "Xã Vĩnh Trung",
            level: "Xã"
          },
          {
            id: "30535",
            name: "Xã Tân Lợi",
            level: "Xã"
          },
          {
            id: "30538",
            name: "Xã An Hảo",
            level: "Xã"
          },
          {
            id: "30541",
            name: "Xã Tân Lập",
            level: "Xã"
          }
        ]
      },
      {
        id: "891",
        name: "Huyện Tri Tôn",
        wards: [
          {
            id: "30544",
            name: "Thị trấn Tri Tôn",
            level: "Thị trấn"
          },
          {
            id: "30547",
            name: "Thị trấn Ba Chúc",
            level: "Thị trấn"
          },
          {
            id: "30550",
            name: "Xã Lạc Quới",
            level: "Xã"
          },
          {
            id: "30553",
            name: "Xã Lê Trì",
            level: "Xã"
          },
          {
            id: "30556",
            name: "Xã Vĩnh Gia",
            level: "Xã"
          },
          {
            id: "30559",
            name: "Xã Vĩnh Phước",
            level: "Xã"
          },
          {
            id: "30562",
            name: "Xã Châu Lăng",
            level: "Xã"
          },
          {
            id: "30565",
            name: "Xã Lương Phi",
            level: "Xã"
          },
          {
            id: "30568",
            name: "Xã Lương An Trà",
            level: "Xã"
          },
          {
            id: "30571",
            name: "Xã Tà Đảnh",
            level: "Xã"
          },
          {
            id: "30574",
            name: "Xã Núi Tô",
            level: "Xã"
          },
          {
            id: "30577",
            name: "Xã An Tức",
            level: "Xã"
          },
          {
            id: "30580",
            name: "Xã Cô Tô",
            level: "Xã"
          },
          {
            id: "30583",
            name: "Xã Tân Tuyến",
            level: "Xã"
          },
          {
            id: "30586",
            name: "Xã Ô Lâm",
            level: "Xã"
          }
        ]
      },
      {
        id: "892",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "30589",
            name: "Thị trấn An Châu",
            level: "Thị trấn"
          },
          {
            id: "30592",
            name: "Xã An Hòa",
            level: "Xã"
          },
          {
            id: "30595",
            name: "Xã Cần Đăng",
            level: "Xã"
          },
          {
            id: "30598",
            name: "Xã Vĩnh Hanh",
            level: "Xã"
          },
          {
            id: "30601",
            name: "Xã Bình Thạnh",
            level: "Xã"
          },
          {
            id: "30604",
            name: "Xã Vĩnh Bình",
            level: "Xã"
          },
          {
            id: "30607",
            name: "Xã Bình Hòa",
            level: "Xã"
          },
          {
            id: "30610",
            name: "Xã Vĩnh An",
            level: "Xã"
          },
          {
            id: "30613",
            name: "Xã Hòa Bình Thạnh",
            level: "Xã"
          },
          {
            id: "30616",
            name: "Xã Vĩnh Lợi",
            level: "Xã"
          },
          {
            id: "30619",
            name: "Xã Vĩnh Nhuận",
            level: "Xã"
          },
          {
            id: "30622",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "30625",
            name: "Xã Vĩnh Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "893",
        name: "Huyện Chợ Mới",
        wards: [
          {
            id: "30628",
            name: "Thị trấn Chợ Mới",
            level: "Thị trấn"
          },
          {
            id: "30631",
            name: "Thị trấn Mỹ Luông",
            level: "Thị trấn"
          },
          {
            id: "30634",
            name: "Xã Kiến An",
            level: "Xã"
          },
          {
            id: "30637",
            name: "Xã Mỹ Hội Đông",
            level: "Xã"
          },
          {
            id: "30640",
            name: "Xã Long Điền A",
            level: "Xã"
          },
          {
            id: "30643",
            name: "Xã Tấn Mỹ",
            level: "Xã"
          },
          {
            id: "30646",
            name: "Xã Long Điền B",
            level: "Xã"
          },
          {
            id: "30649",
            name: "Xã Kiến Thành",
            level: "Xã"
          },
          {
            id: "30652",
            name: "Xã Mỹ Hiệp",
            level: "Xã"
          },
          {
            id: "30655",
            name: "Xã Mỹ An",
            level: "Xã"
          },
          {
            id: "30658",
            name: "Xã Nhơn Mỹ",
            level: "Xã"
          },
          {
            id: "30661",
            name: "Xã Long Giang",
            level: "Xã"
          },
          {
            id: "30664",
            name: "Xã Long Kiến",
            level: "Xã"
          },
          {
            id: "30667",
            name: "Xã Bình Phước Xuân",
            level: "Xã"
          },
          {
            id: "30670",
            name: "Xã An Thạnh Trung",
            level: "Xã"
          },
          {
            id: "30673",
            name: "Xã Hội An",
            level: "Xã"
          },
          {
            id: "30676",
            name: "Xã Hòa Bình",
            level: "Xã"
          },
          {
            id: "30679",
            name: "Xã Hòa An",
            level: "Xã"
          }
        ]
      },
      {
        id: "894",
        name: "Huyện Thoại Sơn",
        wards: [
          {
            id: "30682",
            name: "Thị trấn Núi Sập",
            level: "Thị trấn"
          },
          {
            id: "30685",
            name: "Thị trấn Phú Hoà",
            level: "Thị trấn"
          },
          {
            id: "30688",
            name: "Thị Trấn Óc Eo",
            level: "Thị trấn"
          },
          {
            id: "30691",
            name: "Xã Tây Phú",
            level: "Xã"
          },
          {
            id: "30692",
            name: "Xã An Bình",
            level: "Xã"
          },
          {
            id: "30694",
            name: "Xã Vĩnh Phú",
            level: "Xã"
          },
          {
            id: "30697",
            name: "Xã Vĩnh Trạch",
            level: "Xã"
          },
          {
            id: "30700",
            name: "Xã Phú Thuận",
            level: "Xã"
          },
          {
            id: "30703",
            name: "Xã Vĩnh Chánh",
            level: "Xã"
          },
          {
            id: "30706",
            name: "Xã Định Mỹ",
            level: "Xã"
          },
          {
            id: "30709",
            name: "Xã Định Thành",
            level: "Xã"
          },
          {
            id: "30712",
            name: "Xã Mỹ Phú Đông",
            level: "Xã"
          },
          {
            id: "30715",
            name: "Xã Vọng Đông",
            level: "Xã"
          },
          {
            id: "30718",
            name: "Xã Vĩnh Khánh",
            level: "Xã"
          },
          {
            id: "30721",
            name: "Xã Thoại Giang",
            level: "Xã"
          },
          {
            id: "30724",
            name: "Xã Bình Thành",
            level: "Xã"
          },
          {
            id: "30727",
            name: "Xã Vọng Thê",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "91",
    name: "Tỉnh Kiên Giang",
    districts: [
      {
        id: "899",
        name: "Thành phố Rạch Giá",
        wards: [
          {
            id: "30730",
            name: "Phường Vĩnh Thanh Vân",
            level: "Phường"
          },
          {
            id: "30733",
            name: "Phường Vĩnh Thanh",
            level: "Phường"
          },
          {
            id: "30736",
            name: "Phường Vĩnh Quang",
            level: "Phường"
          },
          {
            id: "30739",
            name: "Phường Vĩnh Hiệp",
            level: "Phường"
          },
          {
            id: "30742",
            name: "Phường Vĩnh Bảo",
            level: "Phường"
          },
          {
            id: "30745",
            name: "Phường Vĩnh Lạc",
            level: "Phường"
          },
          {
            id: "30748",
            name: "Phường An Hòa",
            level: "Phường"
          },
          {
            id: "30751",
            name: "Phường An Bình",
            level: "Phường"
          },
          {
            id: "30754",
            name: "Phường Rạch Sỏi",
            level: "Phường"
          },
          {
            id: "30757",
            name: "Phường Vĩnh Lợi",
            level: "Phường"
          },
          {
            id: "30760",
            name: "Phường Vĩnh Thông",
            level: "Phường"
          },
          {
            id: "30763",
            name: "Xã Phi Thông",
            level: "Xã"
          }
        ]
      },
      {
        id: "900",
        name: "Thành phố Hà Tiên",
        wards: [
          {
            id: "30766",
            name: "Phường Tô Châu",
            level: "Phường"
          },
          {
            id: "30769",
            name: "Phường Đông Hồ",
            level: "Phường"
          },
          {
            id: "30772",
            name: "Phường Bình San",
            level: "Phường"
          },
          {
            id: "30775",
            name: "Phường Pháo Đài",
            level: "Phường"
          },
          {
            id: "30778",
            name: "Phường Mỹ Đức",
            level: "Phường"
          },
          {
            id: "30781",
            name: "Xã Tiên Hải",
            level: "Xã"
          },
          {
            id: "30784",
            name: "Xã Thuận Yên",
            level: "Xã"
          }
        ]
      },
      {
        id: "902",
        name: "Huyện Kiên Lương",
        wards: [
          {
            id: "30787",
            name: "Thị trấn Kiên Lương",
            level: "Thị trấn"
          },
          {
            id: "30790",
            name: "Xã Kiên Bình",
            level: "Xã"
          },
          {
            id: "30802",
            name: "Xã Hòa Điền",
            level: "Xã"
          },
          {
            id: "30805",
            name: "Xã Dương Hòa",
            level: "Xã"
          },
          {
            id: "30808",
            name: "Xã Bình An",
            level: "Xã"
          },
          {
            id: "30809",
            name: "Xã Bình Trị",
            level: "Xã"
          },
          {
            id: "30811",
            name: "Xã Sơn Hải",
            level: "Xã"
          },
          {
            id: "30814",
            name: "Xã Hòn Nghệ",
            level: "Xã"
          }
        ]
      },
      {
        id: "903",
        name: "Huyện Hòn Đất",
        wards: [
          {
            id: "30817",
            name: "Thị trấn Hòn Đất",
            level: "Thị trấn"
          },
          {
            id: "30820",
            name: "Thị trấn Sóc Sơn",
            level: "Thị trấn"
          },
          {
            id: "30823",
            name: "Xã Bình Sơn",
            level: "Xã"
          },
          {
            id: "30826",
            name: "Xã Bình Giang",
            level: "Xã"
          },
          {
            id: "30828",
            name: "Xã Mỹ Thái",
            level: "Xã"
          },
          {
            id: "30829",
            name: "Xã Nam Thái Sơn",
            level: "Xã"
          },
          {
            id: "30832",
            name: "Xã Mỹ Hiệp Sơn",
            level: "Xã"
          },
          {
            id: "30835",
            name: "Xã Sơn Kiên",
            level: "Xã"
          },
          {
            id: "30836",
            name: "Xã Sơn Bình",
            level: "Xã"
          },
          {
            id: "30838",
            name: "Xã Mỹ Thuận",
            level: "Xã"
          },
          {
            id: "30840",
            name: "Xã Lình Huỳnh",
            level: "Xã"
          },
          {
            id: "30841",
            name: "Xã Thổ Sơn",
            level: "Xã"
          },
          {
            id: "30844",
            name: "Xã Mỹ Lâm",
            level: "Xã"
          },
          {
            id: "30847",
            name: "Xã Mỹ Phước",
            level: "Xã"
          }
        ]
      },
      {
        id: "904",
        name: "Huyện Tân Hiệp",
        wards: [
          {
            id: "30850",
            name: "Thị trấn Tân Hiệp",
            level: "Thị trấn"
          },
          {
            id: "30853",
            name: "Xã Tân Hội",
            level: "Xã"
          },
          {
            id: "30856",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "30859",
            name: "Xã Tân Hiệp B",
            level: "Xã"
          },
          {
            id: "30860",
            name: "Xã Tân Hoà",
            level: "Xã"
          },
          {
            id: "30862",
            name: "Xã Thạnh Đông B",
            level: "Xã"
          },
          {
            id: "30865",
            name: "Xã Thạnh Đông",
            level: "Xã"
          },
          {
            id: "30868",
            name: "Xã Tân Hiệp A",
            level: "Xã"
          },
          {
            id: "30871",
            name: "Xã Tân An",
            level: "Xã"
          },
          {
            id: "30874",
            name: "Xã Thạnh Đông A",
            level: "Xã"
          },
          {
            id: "30877",
            name: "Xã Thạnh Trị",
            level: "Xã"
          }
        ]
      },
      {
        id: "905",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "30880",
            name: "Thị trấn Minh Lương",
            level: "Thị trấn"
          },
          {
            id: "30883",
            name: "Xã Mong Thọ A",
            level: "Xã"
          },
          {
            id: "30886",
            name: "Xã Mong Thọ B",
            level: "Xã"
          },
          {
            id: "30887",
            name: "Xã Mong Thọ",
            level: "Xã"
          },
          {
            id: "30889",
            name: "Xã Giục Tượng",
            level: "Xã"
          },
          {
            id: "30892",
            name: "Xã Vĩnh Hòa Hiệp",
            level: "Xã"
          },
          {
            id: "30893",
            name: "Xã Vĩnh Hoà Phú",
            level: "Xã"
          },
          {
            id: "30895",
            name: "Xã Minh Hòa",
            level: "Xã"
          },
          {
            id: "30898",
            name: "Xã Bình An",
            level: "Xã"
          },
          {
            id: "30901",
            name: "Xã Thạnh Lộc",
            level: "Xã"
          }
        ]
      },
      {
        id: "906",
        name: "Huyện Giồng Riềng",
        wards: [
          {
            id: "30904",
            name: "Thị Trấn Giồng Riềng",
            level: "Thị trấn"
          },
          {
            id: "30907",
            name: "Xã Thạnh Hưng",
            level: "Xã"
          },
          {
            id: "30910",
            name: "Xã Thạnh Phước",
            level: "Xã"
          },
          {
            id: "30913",
            name: "Xã Thạnh Lộc",
            level: "Xã"
          },
          {
            id: "30916",
            name: "Xã Thạnh Hòa",
            level: "Xã"
          },
          {
            id: "30917",
            name: "Xã Thạnh Bình",
            level: "Xã"
          },
          {
            id: "30919",
            name: "Xã Bàn Thạch",
            level: "Xã"
          },
          {
            id: "30922",
            name: "Xã Bàn Tân Định",
            level: "Xã"
          },
          {
            id: "30925",
            name: "Xã Ngọc Thành",
            level: "Xã"
          },
          {
            id: "30928",
            name: "Xã Ngọc Chúc",
            level: "Xã"
          },
          {
            id: "30931",
            name: "Xã Ngọc Thuận",
            level: "Xã"
          },
          {
            id: "30934",
            name: "Xã Hòa Hưng",
            level: "Xã"
          },
          {
            id: "30937",
            name: "Xã Hoà Lợi",
            level: "Xã"
          },
          {
            id: "30940",
            name: "Xã Hoà An",
            level: "Xã"
          },
          {
            id: "30943",
            name: "Xã Long Thạnh",
            level: "Xã"
          },
          {
            id: "30946",
            name: "Xã Vĩnh Thạnh",
            level: "Xã"
          },
          {
            id: "30947",
            name: "Xã Vĩnh Phú",
            level: "Xã"
          },
          {
            id: "30949",
            name: "Xã  Hòa Thuận",
            level: "Xã"
          },
          {
            id: "30950",
            name: "Xã Ngọc Hoà",
            level: "Xã"
          }
        ]
      },
      {
        id: "907",
        name: "Huyện Gò Quao",
        wards: [
          {
            id: "30952",
            name: "Thị trấn Gò Quao",
            level: "Thị trấn"
          },
          {
            id: "30955",
            name: "Xã Vĩnh Hòa Hưng Bắc",
            level: "Xã"
          },
          {
            id: "30958",
            name: "Xã Định Hòa",
            level: "Xã"
          },
          {
            id: "30961",
            name: "Xã Thới Quản",
            level: "Xã"
          },
          {
            id: "30964",
            name: "Xã Định An",
            level: "Xã"
          },
          {
            id: "30967",
            name: "Xã Thủy Liễu",
            level: "Xã"
          },
          {
            id: "30970",
            name: "Xã Vĩnh Hòa Hưng Nam",
            level: "Xã"
          },
          {
            id: "30973",
            name: "Xã Vĩnh Phước A",
            level: "Xã"
          },
          {
            id: "30976",
            name: "Xã Vĩnh Phước B",
            level: "Xã"
          },
          {
            id: "30979",
            name: "Xã Vĩnh Tuy",
            level: "Xã"
          },
          {
            id: "30982",
            name: "Xã Vĩnh Thắng",
            level: "Xã"
          }
        ]
      },
      {
        id: "908",
        name: "Huyện An Biên",
        wards: [
          {
            id: "30985",
            name: "Thị trấn Thứ Ba",
            level: "Thị trấn"
          },
          {
            id: "30988",
            name: "Xã Tây Yên",
            level: "Xã"
          },
          {
            id: "30991",
            name: "Xã Tây Yên A",
            level: "Xã"
          },
          {
            id: "30994",
            name: "Xã Nam Yên",
            level: "Xã"
          },
          {
            id: "30997",
            name: "Xã Hưng Yên",
            level: "Xã"
          },
          {
            id: "31000",
            name: "Xã Nam Thái",
            level: "Xã"
          },
          {
            id: "31003",
            name: "Xã Nam Thái A",
            level: "Xã"
          },
          {
            id: "31006",
            name: "Xã Đông Thái",
            level: "Xã"
          },
          {
            id: "31009",
            name: "Xã Đông Yên",
            level: "Xã"
          }
        ]
      },
      {
        id: "909",
        name: "Huyện An Minh",
        wards: [
          {
            id: "31018",
            name: "Thị trấn Thứ Mười Một",
            level: "Thị trấn"
          },
          {
            id: "31021",
            name: "Xã Thuận Hoà",
            level: "Xã"
          },
          {
            id: "31024",
            name: "Xã Đông Hòa",
            level: "Xã"
          },
          {
            id: "31030",
            name: "Xã Đông Thạnh",
            level: "Xã"
          },
          {
            id: "31031",
            name: "Xã Tân Thạnh",
            level: "Xã"
          },
          {
            id: "31033",
            name: "Xã Đông Hưng",
            level: "Xã"
          },
          {
            id: "31036",
            name: "Xã Đông Hưng A",
            level: "Xã"
          },
          {
            id: "31039",
            name: "Xã Đông Hưng B",
            level: "Xã"
          },
          {
            id: "31042",
            name: "Xã Vân Khánh",
            level: "Xã"
          },
          {
            id: "31045",
            name: "Xã Vân Khánh Đông",
            level: "Xã"
          },
          {
            id: "31048",
            name: "Xã Vân Khánh Tây",
            level: "Xã"
          }
        ]
      },
      {
        id: "910",
        name: "Huyện Vĩnh Thuận",
        wards: [
          {
            id: "31051",
            name: "Thị trấn Vĩnh Thuận",
            level: "Thị trấn"
          },
          {
            id: "31060",
            name: "Xã Vĩnh Bình Bắc",
            level: "Xã"
          },
          {
            id: "31063",
            name: "Xã Vĩnh Bình Nam",
            level: "Xã"
          },
          {
            id: "31064",
            name: "Xã Bình Minh",
            level: "Xã"
          },
          {
            id: "31069",
            name: "Xã Vĩnh Thuận",
            level: "Xã"
          },
          {
            id: "31072",
            name: "Xã Tân Thuận",
            level: "Xã"
          },
          {
            id: "31074",
            name: "Xã Phong Đông",
            level: "Xã"
          },
          {
            id: "31075",
            name: "Xã Vĩnh Phong",
            level: "Xã"
          }
        ]
      },
      {
        id: "911",
        name: "Thành phố Phú Quốc",
        wards: [
          {
            id: "31078",
            name: "Phường Dương Đông",
            level: "Phường"
          },
          {
            id: "31081",
            name: "Phường An Thới",
            level: "Phường"
          },
          {
            id: "31084",
            name: "Xã Cửa Cạn",
            level: "Xã"
          },
          {
            id: "31087",
            name: "Xã Gành Dầu",
            level: "Xã"
          },
          {
            id: "31090",
            name: "Xã Cửa Dương",
            level: "Xã"
          },
          {
            id: "31093",
            name: "Xã Hàm Ninh",
            level: "Xã"
          },
          {
            id: "31096",
            name: "Xã Dương Tơ",
            level: "Xã"
          },
          {
            id: "31102",
            name: "Xã Bãi Thơm",
            level: "Xã"
          },
          {
            id: "31105",
            name: "Xã Thổ Châu",
            level: "Xã"
          }
        ]
      },
      {
        id: "912",
        name: "Huyện Kiên Hải",
        wards: [
          {
            id: "31108",
            name: "Xã Hòn Tre",
            level: "Xã"
          },
          {
            id: "31111",
            name: "Xã Lại Sơn",
            level: "Xã"
          },
          {
            id: "31114",
            name: "Xã An Sơn",
            level: "Xã"
          },
          {
            id: "31115",
            name: "Xã Nam Du",
            level: "Xã"
          }
        ]
      },
      {
        id: "913",
        name: "Huyện U Minh Thượng",
        wards: [
          {
            id: "31012",
            name: "Xã Thạnh Yên",
            level: "Xã"
          },
          {
            id: "31015",
            name: "Xã Thạnh Yên A",
            level: "Xã"
          },
          {
            id: "31027",
            name: "Xã An Minh Bắc",
            level: "Xã"
          },
          {
            id: "31054",
            name: "Xã Vĩnh Hòa",
            level: "Xã"
          },
          {
            id: "31057",
            name: "Xã Hoà Chánh",
            level: "Xã"
          },
          {
            id: "31066",
            name: "Xã Minh Thuận",
            level: "Xã"
          }
        ]
      },
      {
        id: "914",
        name: "Huyện Giang Thành",
        wards: [
          {
            id: "30791",
            name: "Xã Vĩnh Phú",
            level: "Xã"
          },
          {
            id: "30793",
            name: "Xã Vĩnh Điều",
            level: "Xã"
          },
          {
            id: "30796",
            name: "Xã Tân Khánh Hòa",
            level: "Xã"
          },
          {
            id: "30797",
            name: "Xã Phú Lợi",
            level: "Xã"
          },
          {
            id: "30799",
            name: "Xã Phú Mỹ",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "92",
    name: "Thành phố Cần Thơ",
    districts: [
      {
        id: "916",
        name: "Quận Ninh Kiều",
        wards: [
          {
            id: "31117",
            name: "Phường Cái Khế",
            level: "Phường"
          },
          {
            id: "31120",
            name: "Phường An Hòa",
            level: "Phường"
          },
          {
            id: "31123",
            name: "Phường Thới Bình",
            level: "Phường"
          },
          {
            id: "31126",
            name: "Phường An Nghiệp",
            level: "Phường"
          },
          {
            id: "31129",
            name: "Phường An Cư",
            level: "Phường"
          },
          {
            id: "31135",
            name: "Phường Tân An",
            level: "Phường"
          },
          {
            id: "31141",
            name: "Phường An Phú",
            level: "Phường"
          },
          {
            id: "31144",
            name: "Phường Xuân Khánh",
            level: "Phường"
          },
          {
            id: "31147",
            name: "Phường Hưng Lợi",
            level: "Phường"
          },
          {
            id: "31149",
            name: "Phường An Khánh",
            level: "Phường"
          },
          {
            id: "31150",
            name: "Phường An Bình",
            level: "Phường"
          }
        ]
      },
      {
        id: "917",
        name: "Quận Ô Môn",
        wards: [
          {
            id: "31153",
            name: "Phường Châu Văn Liêm",
            level: "Phường"
          },
          {
            id: "31154",
            name: "Phường Thới Hòa",
            level: "Phường"
          },
          {
            id: "31156",
            name: "Phường Thới Long",
            level: "Phường"
          },
          {
            id: "31157",
            name: "Phường Long Hưng",
            level: "Phường"
          },
          {
            id: "31159",
            name: "Phường Thới An",
            level: "Phường"
          },
          {
            id: "31162",
            name: "Phường Phước Thới",
            level: "Phường"
          },
          {
            id: "31165",
            name: "Phường Trường Lạc",
            level: "Phường"
          }
        ]
      },
      {
        id: "918",
        name: "Quận Bình Thuỷ",
        wards: [
          {
            id: "31168",
            name: "Phường Bình Thủy",
            level: "Phường"
          },
          {
            id: "31169",
            name: "Phường Trà An",
            level: "Phường"
          },
          {
            id: "31171",
            name: "Phường Trà Nóc",
            level: "Phường"
          },
          {
            id: "31174",
            name: "Phường Thới An Đông",
            level: "Phường"
          },
          {
            id: "31177",
            name: "Phường An Thới",
            level: "Phường"
          },
          {
            id: "31178",
            name: "Phường Bùi Hữu Nghĩa",
            level: "Phường"
          },
          {
            id: "31180",
            name: "Phường Long Hòa",
            level: "Phường"
          },
          {
            id: "31183",
            name: "Phường Long Tuyền",
            level: "Phường"
          }
        ]
      },
      {
        id: "919",
        name: "Quận Cái Răng",
        wards: [
          {
            id: "31186",
            name: "Phường Lê Bình",
            level: "Phường"
          },
          {
            id: "31189",
            name: "Phường Hưng Phú",
            level: "Phường"
          },
          {
            id: "31192",
            name: "Phường Hưng Thạnh",
            level: "Phường"
          },
          {
            id: "31195",
            name: "Phường Ba Láng",
            level: "Phường"
          },
          {
            id: "31198",
            name: "Phường Thường Thạnh",
            level: "Phường"
          },
          {
            id: "31201",
            name: "Phường Phú Thứ",
            level: "Phường"
          },
          {
            id: "31204",
            name: "Phường Tân Phú",
            level: "Phường"
          }
        ]
      },
      {
        id: "923",
        name: "Quận Thốt Nốt",
        wards: [
          {
            id: "31207",
            name: "Phường Thốt Nốt",
            level: "Phường"
          },
          {
            id: "31210",
            name: "Phường Thới Thuận",
            level: "Phường"
          },
          {
            id: "31212",
            name: "Phường Thuận An",
            level: "Phường"
          },
          {
            id: "31213",
            name: "Phường Tân Lộc",
            level: "Phường"
          },
          {
            id: "31216",
            name: "Phường Trung Nhứt",
            level: "Phường"
          },
          {
            id: "31217",
            name: "Phường Thạnh Hoà",
            level: "Phường"
          },
          {
            id: "31219",
            name: "Phường Trung Kiên",
            level: "Phường"
          },
          {
            id: "31227",
            name: "Phường Tân Hưng",
            level: "Phường"
          },
          {
            id: "31228",
            name: "Phường Thuận Hưng",
            level: "Phường"
          }
        ]
      },
      {
        id: "924",
        name: "Huyện Vĩnh Thạnh",
        wards: [
          {
            id: "31211",
            name: "Xã Vĩnh Bình",
            level: "Xã"
          },
          {
            id: "31231",
            name: "Thị trấn Thanh An",
            level: "Thị trấn"
          },
          {
            id: "31232",
            name: "Thị trấn Vĩnh Thạnh",
            level: "Thị trấn"
          },
          {
            id: "31234",
            name: "Xã Thạnh Mỹ",
            level: "Xã"
          },
          {
            id: "31237",
            name: "Xã Vĩnh Trinh",
            level: "Xã"
          },
          {
            id: "31240",
            name: "Xã Thạnh An",
            level: "Xã"
          },
          {
            id: "31241",
            name: "Xã Thạnh Tiến",
            level: "Xã"
          },
          {
            id: "31243",
            name: "Xã Thạnh Thắng",
            level: "Xã"
          },
          {
            id: "31244",
            name: "Xã Thạnh Lợi",
            level: "Xã"
          },
          {
            id: "31246",
            name: "Xã Thạnh Qưới",
            level: "Xã"
          },
          {
            id: "31252",
            name: "Xã Thạnh Lộc",
            level: "Xã"
          }
        ]
      },
      {
        id: "925",
        name: "Huyện Cờ Đỏ",
        wards: [
          {
            id: "31222",
            name: "Xã Trung An",
            level: "Xã"
          },
          {
            id: "31225",
            name: "Xã Trung Thạnh",
            level: "Xã"
          },
          {
            id: "31249",
            name: "Xã Thạnh Phú",
            level: "Xã"
          },
          {
            id: "31255",
            name: "Xã Trung Hưng",
            level: "Xã"
          },
          {
            id: "31261",
            name: "Thị trấn Cờ Đỏ",
            level: "Thị trấn"
          },
          {
            id: "31264",
            name: "Xã Thới Hưng",
            level: "Xã"
          },
          {
            id: "31273",
            name: "Xã Đông Hiệp",
            level: "Xã"
          },
          {
            id: "31274",
            name: "Xã Đông Thắng",
            level: "Xã"
          },
          {
            id: "31276",
            name: "Xã Thới Đông",
            level: "Xã"
          },
          {
            id: "31277",
            name: "Xã Thới Xuân",
            level: "Xã"
          }
        ]
      },
      {
        id: "926",
        name: "Huyện Phong Điền",
        wards: [
          {
            id: "31299",
            name: "Thị trấn Phong Điền",
            level: "Thị trấn"
          },
          {
            id: "31300",
            name: "Xã Nhơn Ái",
            level: "Xã"
          },
          {
            id: "31303",
            name: "Xã Giai Xuân",
            level: "Xã"
          },
          {
            id: "31306",
            name: "Xã Tân Thới",
            level: "Xã"
          },
          {
            id: "31309",
            name: "Xã Trường Long",
            level: "Xã"
          },
          {
            id: "31312",
            name: "Xã Mỹ Khánh",
            level: "Xã"
          },
          {
            id: "31315",
            name: "Xã Nhơn Nghĩa",
            level: "Xã"
          }
        ]
      },
      {
        id: "927",
        name: "Huyện Thới Lai",
        wards: [
          {
            id: "31258",
            name: "Thị trấn Thới Lai",
            level: "Thị trấn"
          },
          {
            id: "31267",
            name: "Xã Thới Thạnh",
            level: "Xã"
          },
          {
            id: "31268",
            name: "Xã Tân Thạnh",
            level: "Xã"
          },
          {
            id: "31270",
            name: "Xã Xuân Thắng",
            level: "Xã"
          },
          {
            id: "31279",
            name: "Xã Đông Bình",
            level: "Xã"
          },
          {
            id: "31282",
            name: "Xã Đông Thuận",
            level: "Xã"
          },
          {
            id: "31285",
            name: "Xã Thới Tân",
            level: "Xã"
          },
          {
            id: "31286",
            name: "Xã Trường Thắng",
            level: "Xã"
          },
          {
            id: "31288",
            name: "Xã Định Môn",
            level: "Xã"
          },
          {
            id: "31291",
            name: "Xã Trường Thành",
            level: "Xã"
          },
          {
            id: "31294",
            name: "Xã Trường Xuân",
            level: "Xã"
          },
          {
            id: "31297",
            name: "Xã Trường Xuân A",
            level: "Xã"
          },
          {
            id: "31298",
            name: "Xã Trường Xuân B",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "93",
    name: "Tỉnh Hậu Giang",
    districts: [
      {
        id: "930",
        name: "Thành phố Vị Thanh",
        wards: [
          {
            id: "31318",
            name: "Phường I",
            level: "Phường"
          },
          {
            id: "31321",
            name: "Phường III",
            level: "Phường"
          },
          {
            id: "31324",
            name: "Phường IV",
            level: "Phường"
          },
          {
            id: "31327",
            name: "Phường V",
            level: "Phường"
          },
          {
            id: "31330",
            name: "Phường VII",
            level: "Phường"
          },
          {
            id: "31333",
            name: "Xã Vị Tân",
            level: "Xã"
          },
          {
            id: "31336",
            name: "Xã Hoả Lựu",
            level: "Xã"
          },
          {
            id: "31338",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "31339",
            name: "Xã Hoả Tiến",
            level: "Xã"
          }
        ]
      },
      {
        id: "931",
        name: "Thành phố Ngã Bảy",
        wards: [
          {
            id: "31340",
            name: "Phường Ngã Bảy",
            level: "Phường"
          },
          {
            id: "31341",
            name: "Phường Lái Hiếu",
            level: "Phường"
          },
          {
            id: "31343",
            name: "Phường Hiệp Thành",
            level: "Phường"
          },
          {
            id: "31344",
            name: "Phường Hiệp Lợi",
            level: "Phường"
          },
          {
            id: "31411",
            name: "Xã Đại Thành",
            level: "Xã"
          },
          {
            id: "31414",
            name: "Xã Tân Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "932",
        name: "Huyện Châu Thành A",
        wards: [
          {
            id: "31342",
            name: "Thị trấn Một Ngàn",
            level: "Thị trấn"
          },
          {
            id: "31345",
            name: "Xã Tân Hoà",
            level: "Xã"
          },
          {
            id: "31346",
            name: "Thị trấn Bảy Ngàn",
            level: "Thị trấn"
          },
          {
            id: "31348",
            name: "Xã Trường Long Tây",
            level: "Xã"
          },
          {
            id: "31351",
            name: "Xã Trường Long A",
            level: "Xã"
          },
          {
            id: "31357",
            name: "Xã Nhơn Nghĩa A",
            level: "Xã"
          },
          {
            id: "31359",
            name: "Thị trấn Rạch Gòi",
            level: "Thị trấn"
          },
          {
            id: "31360",
            name: "Xã Thạnh Xuân",
            level: "Xã"
          },
          {
            id: "31362",
            name: "Thị trấn Cái Tắc",
            level: "Thị trấn"
          },
          {
            id: "31363",
            name: "Xã Tân Phú Thạnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "933",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "31366",
            name: "Thị Trấn Ngã Sáu",
            level: "Thị trấn"
          },
          {
            id: "31369",
            name: "Xã Đông Thạnh",
            level: "Xã"
          },
          {
            id: "31375",
            name: "Xã Đông Phú",
            level: "Xã"
          },
          {
            id: "31378",
            name: "Xã Phú Hữu",
            level: "Xã"
          },
          {
            id: "31379",
            name: "Xã Phú Tân",
            level: "Xã"
          },
          {
            id: "31381",
            name: "Thị trấn Mái Dầm",
            level: "Thị trấn"
          },
          {
            id: "31384",
            name: "Xã Đông Phước",
            level: "Xã"
          },
          {
            id: "31387",
            name: "Xã Đông Phước A",
            level: "Xã"
          }
        ]
      },
      {
        id: "934",
        name: "Huyện Phụng Hiệp",
        wards: [
          {
            id: "31393",
            name: "Thị trấn Kinh Cùng",
            level: "Thị trấn"
          },
          {
            id: "31396",
            name: "Thị trấn Cây Dương",
            level: "Thị trấn"
          },
          {
            id: "31399",
            name: "Xã Tân Bình",
            level: "Xã"
          },
          {
            id: "31402",
            name: "Xã Bình Thành",
            level: "Xã"
          },
          {
            id: "31405",
            name: "Xã Thạnh Hòa",
            level: "Xã"
          },
          {
            id: "31408",
            name: "Xã Long Thạnh",
            level: "Xã"
          },
          {
            id: "31417",
            name: "Xã Phụng Hiệp",
            level: "Xã"
          },
          {
            id: "31420",
            name: "Xã Hòa Mỹ",
            level: "Xã"
          },
          {
            id: "31423",
            name: "Xã Hòa An",
            level: "Xã"
          },
          {
            id: "31426",
            name: "Xã Phương Bình",
            level: "Xã"
          },
          {
            id: "31429",
            name: "Xã Hiệp Hưng",
            level: "Xã"
          },
          {
            id: "31432",
            name: "Xã Tân Phước Hưng",
            level: "Xã"
          },
          {
            id: "31433",
            name: "Thị trấn Búng Tàu",
            level: "Thị trấn"
          },
          {
            id: "31435",
            name: "Xã Phương Phú",
            level: "Xã"
          },
          {
            id: "31438",
            name: "Xã Tân Long",
            level: "Xã"
          }
        ]
      },
      {
        id: "935",
        name: "Huyện Vị Thuỷ",
        wards: [
          {
            id: "31441",
            name: "Thị trấn Nàng Mau",
            level: "Thị trấn"
          },
          {
            id: "31444",
            name: "Xã Vị Trung",
            level: "Xã"
          },
          {
            id: "31447",
            name: "Xã Vị Thuỷ",
            level: "Xã"
          },
          {
            id: "31450",
            name: "Xã Vị Thắng",
            level: "Xã"
          },
          {
            id: "31453",
            name: "Xã Vĩnh Thuận Tây",
            level: "Xã"
          },
          {
            id: "31456",
            name: "Xã Vĩnh Trung",
            level: "Xã"
          },
          {
            id: "31459",
            name: "Xã Vĩnh Tường",
            level: "Xã"
          },
          {
            id: "31462",
            name: "Xã Vị Đông",
            level: "Xã"
          },
          {
            id: "31465",
            name: "Xã Vị Thanh",
            level: "Xã"
          },
          {
            id: "31468",
            name: "Xã Vị Bình",
            level: "Xã"
          }
        ]
      },
      {
        id: "936",
        name: "Huyện Long Mỹ",
        wards: [
          {
            id: "31483",
            name: "Xã Thuận Hưng",
            level: "Xã"
          },
          {
            id: "31484",
            name: "Xã Thuận Hòa",
            level: "Xã"
          },
          {
            id: "31486",
            name: "Xã Vĩnh Thuận Đông",
            level: "Xã"
          },
          {
            id: "31489",
            name: "Thị trấn Vĩnh Viễn",
            level: "Thị trấn"
          },
          {
            id: "31490",
            name: "Xã Vĩnh Viễn A",
            level: "Xã"
          },
          {
            id: "31492",
            name: "Xã Lương Tâm",
            level: "Xã"
          },
          {
            id: "31493",
            name: "Xã Lương Nghĩa",
            level: "Xã"
          },
          {
            id: "31495",
            name: "Xã Xà Phiên",
            level: "Xã"
          }
        ]
      },
      {
        id: "937",
        name: "Thị xã Long Mỹ",
        wards: [
          {
            id: "31471",
            name: "Phường Thuận An",
            level: "Phường"
          },
          {
            id: "31472",
            name: "Phường Trà Lồng",
            level: "Phường"
          },
          {
            id: "31473",
            name: "Phường Bình Thạnh",
            level: "Phường"
          },
          {
            id: "31474",
            name: "Xã Long Bình",
            level: "Xã"
          },
          {
            id: "31475",
            name: "Phường Vĩnh Tường",
            level: "Phường"
          },
          {
            id: "31477",
            name: "Xã Long Trị",
            level: "Xã"
          },
          {
            id: "31478",
            name: "Xã Long Trị A",
            level: "Xã"
          },
          {
            id: "31480",
            name: "Xã Long Phú",
            level: "Xã"
          },
          {
            id: "31481",
            name: "Xã Tân Phú",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "94",
    name: "Tỉnh Sóc Trăng",
    districts: [
      {
        id: "941",
        name: "Thành phố Sóc Trăng",
        wards: [
          {
            id: "31498",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "31501",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "31504",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "31507",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "31510",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "31513",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "31516",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "31519",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "31522",
            name: "Phường 9",
            level: "Phường"
          },
          {
            id: "31525",
            name: "Phường 10",
            level: "Phường"
          }
        ]
      },
      {
        id: "942",
        name: "Huyện Châu Thành",
        wards: [
          {
            id: "31569",
            name: "Thị trấn Châu Thành",
            level: "Thị trấn"
          },
          {
            id: "31570",
            name: "Xã Hồ Đắc Kiện",
            level: "Xã"
          },
          {
            id: "31573",
            name: "Xã Phú Tâm",
            level: "Xã"
          },
          {
            id: "31576",
            name: "Xã Thuận Hòa",
            level: "Xã"
          },
          {
            id: "31582",
            name: "Xã Phú Tân",
            level: "Xã"
          },
          {
            id: "31585",
            name: "Xã Thiện Mỹ",
            level: "Xã"
          },
          {
            id: "31594",
            name: "Xã An Hiệp",
            level: "Xã"
          },
          {
            id: "31600",
            name: "Xã An Ninh",
            level: "Xã"
          }
        ]
      },
      {
        id: "943",
        name: "Huyện Kế Sách",
        wards: [
          {
            id: "31528",
            name: "Thị trấn Kế Sách",
            level: "Thị trấn"
          },
          {
            id: "31531",
            name: "Thị trấn An Lạc Thôn",
            level: "Thị trấn"
          },
          {
            id: "31534",
            name: "Xã Xuân Hòa",
            level: "Xã"
          },
          {
            id: "31537",
            name: "Xã Phong Nẫm",
            level: "Xã"
          },
          {
            id: "31540",
            name: "Xã An Lạc Tây",
            level: "Xã"
          },
          {
            id: "31543",
            name: "Xã Trinh Phú",
            level: "Xã"
          },
          {
            id: "31546",
            name: "Xã Ba Trinh",
            level: "Xã"
          },
          {
            id: "31549",
            name: "Xã Thới An Hội",
            level: "Xã"
          },
          {
            id: "31552",
            name: "Xã Nhơn Mỹ",
            level: "Xã"
          },
          {
            id: "31555",
            name: "Xã Kế Thành",
            level: "Xã"
          },
          {
            id: "31558",
            name: "Xã Kế An",
            level: "Xã"
          },
          {
            id: "31561",
            name: "Xã Đại Hải",
            level: "Xã"
          },
          {
            id: "31564",
            name: "Xã An Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "944",
        name: "Huyện Mỹ Tú",
        wards: [
          {
            id: "31567",
            name: "Thị trấn Huỳnh Hữu Nghĩa",
            level: "Thị trấn"
          },
          {
            id: "31579",
            name: "Xã Long Hưng",
            level: "Xã"
          },
          {
            id: "31588",
            name: "Xã Hưng Phú",
            level: "Xã"
          },
          {
            id: "31591",
            name: "Xã Mỹ Hương",
            level: "Xã"
          },
          {
            id: "31597",
            name: "Xã Mỹ Tú",
            level: "Xã"
          },
          {
            id: "31603",
            name: "Xã Mỹ Phước",
            level: "Xã"
          },
          {
            id: "31606",
            name: "Xã Thuận Hưng",
            level: "Xã"
          },
          {
            id: "31609",
            name: "Xã Mỹ Thuận",
            level: "Xã"
          },
          {
            id: "31612",
            name: "Xã Phú Mỹ",
            level: "Xã"
          }
        ]
      },
      {
        id: "945",
        name: "Huyện Cù Lao Dung",
        wards: [
          {
            id: "31615",
            name: "Thị trấn Cù Lao Dung",
            level: "Thị trấn"
          },
          {
            id: "31618",
            name: "Xã An Thạnh 1",
            level: "Xã"
          },
          {
            id: "31621",
            name: "Xã An Thạnh Tây",
            level: "Xã"
          },
          {
            id: "31624",
            name: "Xã An Thạnh Đông",
            level: "Xã"
          },
          {
            id: "31627",
            name: "Xã Đại Ân 1",
            level: "Xã"
          },
          {
            id: "31630",
            name: "Xã An Thạnh 2",
            level: "Xã"
          },
          {
            id: "31633",
            name: "Xã An Thạnh 3",
            level: "Xã"
          },
          {
            id: "31636",
            name: "Xã An Thạnh Nam",
            level: "Xã"
          }
        ]
      },
      {
        id: "946",
        name: "Huyện Long Phú",
        wards: [
          {
            id: "31639",
            name: "Thị trấn Long Phú",
            level: "Thị trấn"
          },
          {
            id: "31642",
            name: "Xã Song Phụng",
            level: "Xã"
          },
          {
            id: "31645",
            name: "Thị trấn Đại Ngãi",
            level: "Thị trấn"
          },
          {
            id: "31648",
            name: "Xã Hậu Thạnh",
            level: "Xã"
          },
          {
            id: "31651",
            name: "Xã Long Đức",
            level: "Xã"
          },
          {
            id: "31654",
            name: "Xã Trường Khánh",
            level: "Xã"
          },
          {
            id: "31657",
            name: "Xã Phú Hữu",
            level: "Xã"
          },
          {
            id: "31660",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "31663",
            name: "Xã Châu Khánh",
            level: "Xã"
          },
          {
            id: "31666",
            name: "Xã Tân Thạnh",
            level: "Xã"
          },
          {
            id: "31669",
            name: "Xã Long Phú",
            level: "Xã"
          }
        ]
      },
      {
        id: "947",
        name: "Huyện Mỹ Xuyên",
        wards: [
          {
            id: "31684",
            name: "Thị trấn Mỹ Xuyên",
            level: "Thị trấn"
          },
          {
            id: "31690",
            name: "Xã Đại Tâm",
            level: "Xã"
          },
          {
            id: "31693",
            name: "Xã Tham Đôn",
            level: "Xã"
          },
          {
            id: "31708",
            name: "Xã Thạnh Phú",
            level: "Xã"
          },
          {
            id: "31711",
            name: "Xã Ngọc Đông",
            level: "Xã"
          },
          {
            id: "31714",
            name: "Xã Thạnh Quới",
            level: "Xã"
          },
          {
            id: "31717",
            name: "Xã Hòa Tú 1",
            level: "Xã"
          },
          {
            id: "31720",
            name: "Xã Gia Hòa 1",
            level: "Xã"
          },
          {
            id: "31723",
            name: "Xã Ngọc Tố",
            level: "Xã"
          },
          {
            id: "31726",
            name: "Xã Gia Hòa 2",
            level: "Xã"
          },
          {
            id: "31729",
            name: "Xã Hòa Tú II",
            level: "Xã"
          }
        ]
      },
      {
        id: "948",
        name: "Thị xã Ngã Năm",
        wards: [
          {
            id: "31732",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "31735",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "31738",
            name: "Xã Vĩnh Quới",
            level: "Xã"
          },
          {
            id: "31741",
            name: "Xã Tân Long",
            level: "Xã"
          },
          {
            id: "31744",
            name: "Xã Long Bình",
            level: "Xã"
          },
          {
            id: "31747",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "31750",
            name: "Xã Mỹ Bình",
            level: "Xã"
          },
          {
            id: "31753",
            name: "Xã Mỹ Quới",
            level: "Xã"
          }
        ]
      },
      {
        id: "949",
        name: "Huyện Thạnh Trị",
        wards: [
          {
            id: "31756",
            name: "Thị trấn Phú Lộc",
            level: "Thị trấn"
          },
          {
            id: "31757",
            name: "Thị trấn Hưng Lợi",
            level: "Thị trấn"
          },
          {
            id: "31759",
            name: "Xã Lâm Tân",
            level: "Xã"
          },
          {
            id: "31762",
            name: "Xã Thạnh Tân",
            level: "Xã"
          },
          {
            id: "31765",
            name: "Xã Lâm Kiết",
            level: "Xã"
          },
          {
            id: "31768",
            name: "Xã Tuân Tức",
            level: "Xã"
          },
          {
            id: "31771",
            name: "Xã Vĩnh Thành",
            level: "Xã"
          },
          {
            id: "31774",
            name: "Xã Thạnh Trị",
            level: "Xã"
          },
          {
            id: "31777",
            name: "Xã Vĩnh Lợi",
            level: "Xã"
          },
          {
            id: "31780",
            name: "Xã Châu Hưng",
            level: "Xã"
          }
        ]
      },
      {
        id: "950",
        name: "Thị xã Vĩnh Châu",
        wards: [
          {
            id: "31783",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "31786",
            name: "Xã Hòa Đông",
            level: "Xã"
          },
          {
            id: "31789",
            name: "Phường Khánh Hòa",
            level: "Phường"
          },
          {
            id: "31792",
            name: "Xã Vĩnh Hiệp",
            level: "Xã"
          },
          {
            id: "31795",
            name: "Xã Vĩnh Hải",
            level: "Xã"
          },
          {
            id: "31798",
            name: "Xã Lạc Hòa",
            level: "Xã"
          },
          {
            id: "31801",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "31804",
            name: "Phường Vĩnh Phước",
            level: "Phường"
          },
          {
            id: "31807",
            name: "Xã Vĩnh Tân",
            level: "Xã"
          },
          {
            id: "31810",
            name: "Xã Lai Hòa",
            level: "Xã"
          }
        ]
      },
      {
        id: "951",
        name: "Huyện Trần Đề",
        wards: [
          {
            id: "31672",
            name: "Xã Đại Ân  2",
            level: "Xã"
          },
          {
            id: "31673",
            name: "Thị trấn Trần Đề",
            level: "Thị trấn"
          },
          {
            id: "31675",
            name: "Xã Liêu Tú",
            level: "Xã"
          },
          {
            id: "31678",
            name: "Xã Lịch Hội Thượng",
            level: "Xã"
          },
          {
            id: "31679",
            name: "Thị trấn Lịch Hội Thượng",
            level: "Thị trấn"
          },
          {
            id: "31681",
            name: "Xã Trung Bình",
            level: "Xã"
          },
          {
            id: "31687",
            name: "Xã Tài Văn",
            level: "Xã"
          },
          {
            id: "31696",
            name: "Xã Viên An",
            level: "Xã"
          },
          {
            id: "31699",
            name: "Xã Thạnh Thới An",
            level: "Xã"
          },
          {
            id: "31702",
            name: "Xã Thạnh Thới Thuận",
            level: "Xã"
          },
          {
            id: "31705",
            name: "Xã Viên Bình",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "95",
    name: "Tỉnh Bạc Liêu",
    districts: [
      {
        id: "954",
        name: "Thành phố Bạc Liêu",
        wards: [
          {
            id: "31813",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "31816",
            name: "Phường 3",
            level: "Phường"
          },
          {
            id: "31819",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "31822",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "31825",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "31828",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "31831",
            name: "Phường Nhà Mát",
            level: "Phường"
          },
          {
            id: "31834",
            name: "Xã Vĩnh Trạch",
            level: "Xã"
          },
          {
            id: "31837",
            name: "Xã Vĩnh Trạch Đông",
            level: "Xã"
          },
          {
            id: "31840",
            name: "Xã Hiệp Thành",
            level: "Xã"
          }
        ]
      },
      {
        id: "956",
        name: "Huyện Hồng Dân",
        wards: [
          {
            id: "31843",
            name: "Thị trấn Ngan Dừa",
            level: "Thị trấn"
          },
          {
            id: "31846",
            name: "Xã Ninh Quới",
            level: "Xã"
          },
          {
            id: "31849",
            name: "Xã Ninh Quới A",
            level: "Xã"
          },
          {
            id: "31852",
            name: "Xã Ninh Hòa",
            level: "Xã"
          },
          {
            id: "31855",
            name: "Xã Lộc Ninh",
            level: "Xã"
          },
          {
            id: "31858",
            name: "Xã Vĩnh Lộc",
            level: "Xã"
          },
          {
            id: "31861",
            name: "Xã Vĩnh Lộc A",
            level: "Xã"
          },
          {
            id: "31863",
            name: "Xã Ninh Thạnh Lợi A",
            level: "Xã"
          },
          {
            id: "31864",
            name: "Xã Ninh Thạnh Lợi",
            level: "Xã"
          }
        ]
      },
      {
        id: "957",
        name: "Huyện Phước Long",
        wards: [
          {
            id: "31867",
            name: "Thị trấn Phước Long",
            level: "Thị trấn"
          },
          {
            id: "31870",
            name: "Xã Vĩnh Phú Đông",
            level: "Xã"
          },
          {
            id: "31873",
            name: "Xã Vĩnh Phú Tây",
            level: "Xã"
          },
          {
            id: "31876",
            name: "Xã Phước Long",
            level: "Xã"
          },
          {
            id: "31879",
            name: "Xã Hưng Phú",
            level: "Xã"
          },
          {
            id: "31882",
            name: "Xã Vĩnh Thanh",
            level: "Xã"
          },
          {
            id: "31885",
            name: "Xã Phong Thạnh Tây A",
            level: "Xã"
          },
          {
            id: "31888",
            name: "Xã Phong Thạnh Tây B",
            level: "Xã"
          }
        ]
      },
      {
        id: "958",
        name: "Huyện Vĩnh Lợi",
        wards: [
          {
            id: "31894",
            name: "Xã Vĩnh Hưng",
            level: "Xã"
          },
          {
            id: "31897",
            name: "Xã Vĩnh Hưng A",
            level: "Xã"
          },
          {
            id: "31900",
            name: "Thị trấn Châu Hưng",
            level: "Thị trấn"
          },
          {
            id: "31903",
            name: "Xã Châu Hưng A",
            level: "Xã"
          },
          {
            id: "31906",
            name: "Xã Hưng Thành",
            level: "Xã"
          },
          {
            id: "31909",
            name: "Xã Hưng Hội",
            level: "Xã"
          },
          {
            id: "31912",
            name: "Xã Châu Thới",
            level: "Xã"
          },
          {
            id: "31921",
            name: "Xã Long Thạnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "959",
        name: "Thị xã Giá Rai",
        wards: [
          {
            id: "31942",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "31945",
            name: "Phường Hộ Phòng",
            level: "Phường"
          },
          {
            id: "31948",
            name: "Xã Phong Thạnh Đông",
            level: "Xã"
          },
          {
            id: "31951",
            name: "Phường Láng Tròn",
            level: "Phường"
          },
          {
            id: "31954",
            name: "Xã Phong Tân",
            level: "Xã"
          },
          {
            id: "31957",
            name: "Xã Tân Phong",
            level: "Xã"
          },
          {
            id: "31960",
            name: "Xã Phong Thạnh",
            level: "Xã"
          },
          {
            id: "31963",
            name: "Xã Phong Thạnh A",
            level: "Xã"
          },
          {
            id: "31966",
            name: "Xã Phong Thạnh Tây",
            level: "Xã"
          },
          {
            id: "31969",
            name: "Xã Tân Thạnh",
            level: "Xã"
          }
        ]
      },
      {
        id: "960",
        name: "Huyện Đông Hải",
        wards: [
          {
            id: "31972",
            name: "Thị trấn Gành Hào",
            level: "Thị trấn"
          },
          {
            id: "31975",
            name: "Xã Long Điền Đông",
            level: "Xã"
          },
          {
            id: "31978",
            name: "Xã Long Điền Đông A",
            level: "Xã"
          },
          {
            id: "31981",
            name: "Xã Long Điền",
            level: "Xã"
          },
          {
            id: "31984",
            name: "Xã Long Điền Tây",
            level: "Xã"
          },
          {
            id: "31985",
            name: "Xã Điền Hải",
            level: "Xã"
          },
          {
            id: "31987",
            name: "Xã An Trạch",
            level: "Xã"
          },
          {
            id: "31988",
            name: "Xã An Trạch A",
            level: "Xã"
          },
          {
            id: "31990",
            name: "Xã An Phúc",
            level: "Xã"
          },
          {
            id: "31993",
            name: "Xã Định Thành",
            level: "Xã"
          },
          {
            id: "31996",
            name: "Xã Định Thành A",
            level: "Xã"
          }
        ]
      },
      {
        id: "961",
        name: "Huyện Hoà Bình",
        wards: [
          {
            id: "31891",
            name: "Thị trấn Hòa Bình",
            level: "Thị trấn"
          },
          {
            id: "31915",
            name: "Xã Minh Diệu",
            level: "Xã"
          },
          {
            id: "31918",
            name: "Xã Vĩnh Bình",
            level: "Xã"
          },
          {
            id: "31924",
            name: "Xã Vĩnh Mỹ B",
            level: "Xã"
          },
          {
            id: "31927",
            name: "Xã Vĩnh Hậu",
            level: "Xã"
          },
          {
            id: "31930",
            name: "Xã Vĩnh Hậu A",
            level: "Xã"
          },
          {
            id: "31933",
            name: "Xã Vĩnh Mỹ A",
            level: "Xã"
          },
          {
            id: "31936",
            name: "Xã Vĩnh Thịnh",
            level: "Xã"
          }
        ]
      }
    ]
  },
  {
    id: "96",
    name: "Tỉnh Cà Mau",
    districts: [
      {
        id: "964",
        name: "Thành phố Cà Mau",
        wards: [
          {
            id: "31999",
            name: "Phường 9",
            level: "Phường"
          },
          {
            id: "32002",
            name: "Phường 4",
            level: "Phường"
          },
          {
            id: "32005",
            name: "Phường 1",
            level: "Phường"
          },
          {
            id: "32008",
            name: "Phường 5",
            level: "Phường"
          },
          {
            id: "32011",
            name: "Phường 2",
            level: "Phường"
          },
          {
            id: "32014",
            name: "Phường 8",
            level: "Phường"
          },
          {
            id: "32017",
            name: "Phường 6",
            level: "Phường"
          },
          {
            id: "32020",
            name: "Phường 7",
            level: "Phường"
          },
          {
            id: "32022",
            name: "Phường Tân Xuyên",
            level: "Phường"
          },
          {
            id: "32023",
            name: "Xã An Xuyên",
            level: "Xã"
          },
          {
            id: "32025",
            name: "Phường Tân Thành",
            level: "Phường"
          },
          {
            id: "32026",
            name: "Xã Tân Thành",
            level: "Xã"
          },
          {
            id: "32029",
            name: "Xã Tắc Vân",
            level: "Xã"
          },
          {
            id: "32032",
            name: "Xã Lý Văn Lâm",
            level: "Xã"
          },
          {
            id: "32035",
            name: "Xã Định Bình",
            level: "Xã"
          },
          {
            id: "32038",
            name: "Xã Hòa Thành",
            level: "Xã"
          },
          {
            id: "32041",
            name: "Xã Hòa Tân",
            level: "Xã"
          }
        ]
      },
      {
        id: "966",
        name: "Huyện U Minh",
        wards: [
          {
            id: "32044",
            name: "Thị trấn U Minh",
            level: "Thị trấn"
          },
          {
            id: "32047",
            name: "Xã Khánh Hòa",
            level: "Xã"
          },
          {
            id: "32048",
            name: "Xã Khánh Thuận",
            level: "Xã"
          },
          {
            id: "32050",
            name: "Xã Khánh Tiến",
            level: "Xã"
          },
          {
            id: "32053",
            name: "Xã Nguyễn Phích",
            level: "Xã"
          },
          {
            id: "32056",
            name: "Xã Khánh Lâm",
            level: "Xã"
          },
          {
            id: "32059",
            name: "Xã Khánh An",
            level: "Xã"
          },
          {
            id: "32062",
            name: "Xã Khánh Hội",
            level: "Xã"
          }
        ]
      },
      {
        id: "967",
        name: "Huyện Thới Bình",
        wards: [
          {
            id: "32065",
            name: "Thị trấn Thới Bình",
            level: "Thị trấn"
          },
          {
            id: "32068",
            name: "Xã Biển Bạch",
            level: "Xã"
          },
          {
            id: "32069",
            name: "Xã Tân Bằng",
            level: "Xã"
          },
          {
            id: "32071",
            name: "Xã Trí Phải",
            level: "Xã"
          },
          {
            id: "32072",
            name: "Xã Trí Lực",
            level: "Xã"
          },
          {
            id: "32074",
            name: "Xã Biển Bạch Đông",
            level: "Xã"
          },
          {
            id: "32077",
            name: "Xã Thới Bình",
            level: "Xã"
          },
          {
            id: "32080",
            name: "Xã Tân Phú",
            level: "Xã"
          },
          {
            id: "32083",
            name: "Xã Tân Lộc Bắc",
            level: "Xã"
          },
          {
            id: "32086",
            name: "Xã Tân Lộc",
            level: "Xã"
          },
          {
            id: "32089",
            name: "Xã Tân Lộc Đông",
            level: "Xã"
          },
          {
            id: "32092",
            name: "Xã Hồ Thị Kỷ",
            level: "Xã"
          }
        ]
      },
      {
        id: "968",
        name: "Huyện Trần Văn Thời",
        wards: [
          {
            id: "32095",
            name: "Thị trấn Trần Văn Thời",
            level: "Thị trấn"
          },
          {
            id: "32098",
            name: "Thị trấn Sông Đốc",
            level: "Thị trấn"
          },
          {
            id: "32101",
            name: "Xã Khánh Bình Tây Bắc",
            level: "Xã"
          },
          {
            id: "32104",
            name: "Xã Khánh Bình Tây",
            level: "Xã"
          },
          {
            id: "32107",
            name: "Xã Trần Hợi",
            level: "Xã"
          },
          {
            id: "32108",
            name: "Xã Khánh Lộc",
            level: "Xã"
          },
          {
            id: "32110",
            name: "Xã Khánh Bình",
            level: "Xã"
          },
          {
            id: "32113",
            name: "Xã Khánh Hưng",
            level: "Xã"
          },
          {
            id: "32116",
            name: "Xã Khánh Bình Đông",
            level: "Xã"
          },
          {
            id: "32119",
            name: "Xã Khánh Hải",
            level: "Xã"
          },
          {
            id: "32122",
            name: "Xã Lợi An",
            level: "Xã"
          },
          {
            id: "32124",
            name: "Xã Phong Điền",
            level: "Xã"
          },
          {
            id: "32125",
            name: "Xã Phong Lạc",
            level: "Xã"
          }
        ]
      },
      {
        id: "969",
        name: "Huyện Cái Nước",
        wards: [
          {
            id: "32128",
            name: "Thị trấn Cái Nước",
            level: "Thị trấn"
          },
          {
            id: "32130",
            name: "Xã Thạnh Phú",
            level: "Xã"
          },
          {
            id: "32131",
            name: "Xã Lương Thế Trân",
            level: "Xã"
          },
          {
            id: "32134",
            name: "Xã Phú Hưng",
            level: "Xã"
          },
          {
            id: "32137",
            name: "Xã Tân Hưng",
            level: "Xã"
          },
          {
            id: "32140",
            name: "Xã Hưng Mỹ",
            level: "Xã"
          },
          {
            id: "32141",
            name: "Xã Hoà Mỹ",
            level: "Xã"
          },
          {
            id: "32142",
            name: "Xã Đông Hưng",
            level: "Xã"
          },
          {
            id: "32143",
            name: "Xã Đông Thới",
            level: "Xã"
          },
          {
            id: "32146",
            name: "Xã Tân Hưng Đông",
            level: "Xã"
          },
          {
            id: "32149",
            name: "Xã Trần Thới",
            level: "Xã"
          }
        ]
      },
      {
        id: "970",
        name: "Huyện Đầm Dơi",
        wards: [
          {
            id: "32152",
            name: "Thị trấn Đầm Dơi",
            level: "Thị trấn"
          },
          {
            id: "32155",
            name: "Xã Tạ An Khương",
            level: "Xã"
          },
          {
            id: "32158",
            name: "Xã Tạ An Khương  Đông",
            level: "Xã"
          },
          {
            id: "32161",
            name: "Xã Trần Phán",
            level: "Xã"
          },
          {
            id: "32162",
            name: "Xã Tân Trung",
            level: "Xã"
          },
          {
            id: "32164",
            name: "Xã Tân Đức",
            level: "Xã"
          },
          {
            id: "32167",
            name: "Xã Tân Thuận",
            level: "Xã"
          },
          {
            id: "32170",
            name: "Xã Tạ An Khương  Nam",
            level: "Xã"
          },
          {
            id: "32173",
            name: "Xã Tân Duyệt",
            level: "Xã"
          },
          {
            id: "32174",
            name: "Xã Tân Dân",
            level: "Xã"
          },
          {
            id: "32176",
            name: "Xã Tân Tiến",
            level: "Xã"
          },
          {
            id: "32179",
            name: "Xã Quách Phẩm Bắc",
            level: "Xã"
          },
          {
            id: "32182",
            name: "Xã Quách Phẩm",
            level: "Xã"
          },
          {
            id: "32185",
            name: "Xã Thanh Tùng",
            level: "Xã"
          },
          {
            id: "32186",
            name: "Xã Ngọc Chánh",
            level: "Xã"
          },
          {
            id: "32188",
            name: "Xã Nguyễn Huân",
            level: "Xã"
          }
        ]
      },
      {
        id: "971",
        name: "Huyện Năm Căn",
        wards: [
          {
            id: "32191",
            name: "Thị Trấn Năm Căn",
            level: "Thị trấn"
          },
          {
            id: "32194",
            name: "Xã Hàm Rồng",
            level: "Xã"
          },
          {
            id: "32197",
            name: "Xã Hiệp Tùng",
            level: "Xã"
          },
          {
            id: "32200",
            name: "Xã Đất Mới",
            level: "Xã"
          },
          {
            id: "32201",
            name: "Xã Lâm Hải",
            level: "Xã"
          },
          {
            id: "32203",
            name: "Xã Hàng Vịnh",
            level: "Xã"
          },
          {
            id: "32206",
            name: "Xã Tam Giang",
            level: "Xã"
          },
          {
            id: "32209",
            name: "Xã Tam Giang Đông",
            level: "Xã"
          }
        ]
      },
      {
        id: "972",
        name: "Huyện Phú Tân",
        wards: [
          {
            id: "32212",
            name: "Thị trấn Cái Đôi Vàm",
            level: "Thị trấn"
          },
          {
            id: "32214",
            name: "Xã Phú Thuận",
            level: "Xã"
          },
          {
            id: "32215",
            name: "Xã Phú Mỹ",
            level: "Xã"
          },
          {
            id: "32218",
            name: "Xã Phú Tân",
            level: "Xã"
          },
          {
            id: "32221",
            name: "Xã Tân Hải",
            level: "Xã"
          },
          {
            id: "32224",
            name: "Xã Việt Thắng",
            level: "Xã"
          },
          {
            id: "32227",
            name: "Xã Tân Hưng Tây",
            level: "Xã"
          },
          {
            id: "32228",
            name: "Xã Rạch Chèo",
            level: "Xã"
          },
          {
            id: "32230",
            name: "Xã Nguyễn Việt Khái",
            level: "Xã"
          }
        ]
      },
      {
        id: "973",
        name: "Huyện Ngọc Hiển",
        wards: [
          {
            id: "32233",
            name: "Xã Tam Giang Tây",
            level: "Xã"
          },
          {
            id: "32236",
            name: "Xã Tân Ân Tây",
            level: "Xã"
          },
          {
            id: "32239",
            name: "Xã Viên An Đông",
            level: "Xã"
          },
          {
            id: "32242",
            name: "Xã Viên An",
            level: "Xã"
          },
          {
            id: "32244",
            name: "Thị trấn Rạch Gốc",
            level: "Thị trấn"
          },
          {
            id: "32245",
            name: "Xã Tân Ân",
            level: "Xã"
          },
          {
            id: "32248",
            name: "Xã Đất Mũi",
            level: "Xã"
          }
        ]
      }
    ]
  }
]