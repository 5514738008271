/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
// import { Box, CssBaseline, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { ToastContainer } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import CongratulationAlertDialog from "../../../common/dialog/CongratulationAlertDialog";
import UserInfoDialog from "../../../common/dialog/UserInfoDialog";
import GoogleAd from "../../../common/components/GoogleAd";
import ScrollButton from "../../../common/components/ScrollButton";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import DefaultRightSide from "./DefaultRightSide";
import DefaultLeftSide from "./DefaultLeftSide";
import { getNonce, isEmpty, genSignature } from "../../../../utils/helpers/helpers";
import { commonActions } from "../../../common/redux/actions";
import ResponsiveHeader from "./ResponsiveHeader";
import { PUBLIC_LAYOUT } from "../../../../utils/configs/router";
import { WIDTH_LAYOUT, WIDTH_LAYOUT_TABLET } from "../../../../utils/constants/constants";
import ModalChangeAvatar from "../components/ModalChangeAvatar";
import { TAccountState } from "../../../account/utils/types";
import AlertDialog from "../../../common/dialog/AlertDialog";
import { TCommonState } from "../../../common/utils/type";
import {
  LIST_ROUTER_DIRECT_APP,
  LOCATION_PERMISSION_ACCEPTED,
  LOCATION_PERMISSION_DENIED,
  LOCATION_PERMISSION_UNDEFINE,
  TOPIC_OPENING_VIEW_TYPE_TAB
} from "../../../common/utils/constant";
import LoginDialog from "../../../common/dialog/LoginDialog";
import ModalConfirm from "../../../common/components/ModalConfirm";
import Breadcrumb from "../../../common/components/BreadCrumb";
import { TTheme } from "../../../../utils/configs/setupTheme";
import ModalChooseLanguage from "../components/ModalChooseLanguage";
import ModalChooseLevel from "../components/ModalChooseLevel";
import DailyReportDialog from "../../../common/dialog/DailyReportDialog";
import { CXCircularProgress } from "../../../common/components/CircularProgress";

function DefaultLayout({ children }: any) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const location = useRouter();
  const dataUserInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const mainContentRef = useRef<HTMLDivElement>(null);
  const theme: TTheme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const isHomePage = location?.pathname === "/";
  const matches1100 = useMediaQuery("(min-width:1100px)", { noSsr: true });
  const maxWidth768 = useMediaQuery("(max-width:768px)", { noSsr: true });
  const mobile = useMediaQuery("(max-width:480px)");
  const notifyAccount = useSelector((state: { account: TAccountState }) => state.account.notify);
  const {
    notify,
    openModalConfirmLogin,
    congratulation,
    openModalLogin,
    locationPermission,
    openModalConfirmPremium,
    openModalChangeImage,
    openModalChooseLanguage,
    openModalChooseLevel,
    openModalUpdateInfo,
    showDirect,
    userLanguage,
    isShowLoginFirstTime,
    isEmptyName,
    isNewMember,
    isUpdateInfo,
    isOpenDialogGradeFirstTime,
    isOpenDialogLanguageFirstTime
    // isShowAdsDialogFirstTime,
    // openDialogAdsInHouse
  } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const [openUserInfoDialog, setOpenUserInfoDialog] = useState(false);

  const stateDialogBeforeDailyReport = [
    isNewMember,
    isEmptyName,
    congratulation?.isOpenCongratulation,
    locationPermission === LOCATION_PERMISSION_UNDEFINE
    // openDialogAdsInHouse && isShowAdsDialogFirstTime && location.asPath === "/"
  ];

  const adWebBannerTopHeader = useSelector(
    (state: { commonNew: TCommonState }) => state.commonNew.ad_web_banner_top_header
  );

  const handleUpdateLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((locale) => {
        if (locale) {
          const dataUpdatePre = {
            timestamp: moment().unix(),
            nonce: getNonce(),
            long: locale.coords.longitude,
            lat: locale.coords.latitude
          };
          const dataUpdate = {
            ...dataUpdatePre,
            signature: genSignature(dataUpdatePre)
          };
          dispatch(commonActions.handleUpdateLocation(dataUpdate));
        }
      });
    }
  };

  const isQuizPage =
    location?.pathname.includes(TOPIC_OPENING_VIEW_TYPE_TAB.PRACTICE) ||
    location?.pathname.includes(TOPIC_OPENING_VIEW_TYPE_TAB.EXAMS);
  const isFriendPage = location?.pathname?.includes("/ban-be");
  const isAccountManagementPage = location?.pathname === "/tai-khoan";
  const isHideLeftSide = isQuizPage || isFriendPage || isAccountManagementPage;

  const isHiddenLeftRightSide = PUBLIC_LAYOUT.find((element: { path: string }) =>
    location?.pathname?.includes(element?.path)
  );
  const handleOpenModalImage = useCallback(() => {
    dispatch(commonActions.handleOpenModalChangeAvatar(true));
  }, []);
  const getUserInfo = () => {
    dispatch(commonActions.handleGetUserInfo());
  };
  const handleCloseCongratulation = () => {
    getUserInfo();
    dispatch(commonActions.handleSaveCongratulation({}));
  };

  React.useEffect(() => {
    if (notifyAccount.type === "UPDATE_INFO_ACCOUNT_SUCCESS" || notifyAccount.type === "GIFT_DIAMOND_SUCCESS") {
      getUserInfo();
    }
  }, [notifyAccount.type]);
  React.useEffect(() => {
    if (isEmptyName) {
      setOpenUserInfoDialog(true);
      dispatch(commonActions.handleSetIsEmptyName(false));
    }
  }, [isEmptyName]);

  React.useEffect(() => {
    // get timeserver
    dispatch(commonActions.getTimeServer());
    // set first time load page
    // dispatch(commonActions.handleSetRouterHistory([location.pathname]));
    if (!isOpenDialogLanguageFirstTime) {
      dispatch(commonActions.handleOpenModalChooseLanguage(true));
    }
    if (!isOpenDialogGradeFirstTime) {
      dispatch(commonActions.handleOpenModalChooseLevel(true));
      dispatch(commonActions.handleSetIsShowDialogGradeFirstTime(true));
    }
  }, []);

  React.useEffect(() => {
    // update lai userInfo
    if (isUpdateInfo && !!dataUserInfos?.member?.pub_id) {
      dispatch(commonActions.handleGetUserInfo());
    }
  }, [isUpdateInfo]);

  React.useEffect(() => {
    const showLoading = (url) => {
      if (url === "/tai-khoan") setIsLoading(true);
    };
    const hideLoading = () => {
      setIsLoading(false);
    };
    location.events.on("routeChangeStart", showLoading);
    location.events.on("routeChangeComplete", hideLoading);

    return () => {
      location.events.off("routeChangeStart", showLoading);
      location.events.off("routeChangeComplete", hideLoading);
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }} className="text-black">
      <CssBaseline />
      <Box
        className="overflow-x-hidden h-max"
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: theme.palette.primary.background,
          width: "100vw",
          marginTop: !maxWidth768
            ? "56px"
            : showDirect && LIST_ROUTER_DIRECT_APP.includes(location?.pathname)
            ? isHomePage
              ? "130px"
              : "154px"
            : isHomePage
            ? "82px"
            : "106px"
        }}
        display="flex"
        flexDirection="column"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {adWebBannerTopHeader === "true" && (
            <Box display="flex" justifyContent="center" className="sm:w-screen md:w-[672px] h-[96px]">
              <GoogleAd
                style={{
                  display: "inline-block",
                  height: 96,
                  width: 672
                }}
                slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_TITLE}
              />
            </Box>
          )}
          {!maxWidth768 ? (
            <div className="w-screen">
              <DefaultHeader />
            </div>
          ) : (
            <div className="bg-white w-screen">
              <ResponsiveHeader
                isHomePage={isHomePage}
                isNewMember={isNewMember}
                userInfo={dataUserInfos}
                handleOpenModalImage={handleOpenModalImage}
              />
            </div>
          )}
          <div
            className={`sm:mt-0 sm:pt-[5px] sm:bg-grayBackground md:bg-inherit md:py-0 sm:pr-0 h-auto relative ${
              isHomePage ? "md:mt-5" : isAccountManagementPage ? "md:mt-9" : "md:mt-10"
            }`}
            style={{
              display: "flex",
              width: "100vw",
              maxWidth: 2200,
              justifyContent: "center"
            }}
          >
            {isLoading ? (
              <CXCircularProgress height="80vh" />
            ) : (
              <>
                {!maxWidth768 && !isHideLeftSide && (
                  <Box
                    width={matches1100 ? WIDTH_LAYOUT : WIDTH_LAYOUT_TABLET}
                    visibility={isHiddenLeftRightSide && "hidden"}
                    className={`${matches1100 ? "ml-[40px]" : "ml-[24px]"}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      top: 20
                    }}
                  >
                    <Box width={matches1100 ? 330 : 282} className="absolute top-[-30px] truncate">
                      <Breadcrumb />
                    </Box>
                    <DefaultLeftSide
                      handleOpenModalImage={handleOpenModalImage}
                      mainContentHeight={mainContentRef?.current?.offsetHeight}
                    />
                  </Box>
                )}
                <Box
                  className="sm:px-0 sm:pt-4 md:pt-0 md:pr-6 md:pl-8 lg:px-12 sm:w-screen md:w-full h-auto min-h-[50vh] flex flex-col md:gap-4 md:pb-6"
                  minWidth={310}
                  ref={mainContentRef}
                >
                  {children}
                </Box>
                {matches1100 && !isQuizPage && !isAccountManagementPage && (
                  <Box width={WIDTH_LAYOUT} visibility={isHiddenLeftRightSide && "hidden"} className="flex mr-[40px]">
                    <DefaultRightSide mainContentHeight={mainContentRef?.current?.offsetHeight} />
                  </Box>
                )}
              </>
            )}
          </div>
        </Box>
        <DefaultFooter />
      </Box>
      <ScrollButton />

      {openModalChangeImage && (
        <ModalChangeAvatar
          open={openModalChangeImage}
          handleClose={() => dispatch(commonActions.handleOpenModalChangeAvatar(false))}
        />
      )}

      {/* {notify.isOpenDialog && (
        <AlertDialog
          open={notify.isOpenDialog}
          setOpen={() => {
            dispatch(commonActions.deleteNotify());
          }}
          title={intl.formatMessage({ id: "Setting.notification" })}
          content={`${notify?.message}-(${notify?.errorCode})`}
          triggerFunc={() => {
            dispatch(commonActions.deleteNotify());
            dispatch(commonActions.handleSaveIdToken(""));
            // dispatch(addNewFireBaseInfos({}));
            location.push("/");
          }}
          contentKey="common.note"
          buttonOkKey={undefined}
          isHideCancel
        />
      )} */}
      {notify?.isOpenDialog && (
        <AlertDialog
          open={notify?.isOpenDialog}
          setOpen={() => {}}
          title={intl.formatMessage({ id: "Setting.notification" })}
          content={`${notify?.message}(${notify?.errorCode})`}
          triggerFunc={() => {
            dispatch(commonActions.deleteNotify());
          }}
          contentKey="common.note"
          buttonOkKey="common.ok"
          isHideCancel
        />
      )}
      {openModalConfirmLogin && (
        <AlertDialog
          open={openModalConfirmLogin}
          setOpen={(value) => {
            dispatch(commonActions.handleOpenModalConfirmLogin(value));
          }}
          title={<FormattedMessage id="Setting.notification" />}
          content={<FormattedMessage id="common.content.modal.confirm.login" />}
          triggerFunc={() => {
            dispatch(commonActions.handleOpenModalLogin(true));
          }}
          buttonOkKey="common.ok"
          isHideCancel={false}
        />
      )}
      {openModalConfirmPremium && (
        <ModalConfirm
          title="common.bottombar.notification"
          content="common.content.modal.confirm.premium"
          onConfirm={undefined}
          onCancel={undefined}
          textConfirm="common.ok"
          handleClose={undefined}
          open={openModalConfirmPremium}
          styles={undefined}
        />
      )}
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
        className={`h-6 w-max whitespace-nowrap ${mobile && "w-[100%] right-0 left-0 text-[13px]"}`}
        limit={1}
      />
      {dataUserInfos?.member && stateDialogBeforeDailyReport.every((e) => !e) && <DailyReportDialog />}
      {openModalLogin && (
        <LoginDialog
          onClose={() => {
            dispatch(commonActions.handleOpenModalLogin(false));
            if (!isShowLoginFirstTime) {
              dispatch(commonActions.handleOpenModalChooseLanguage(true));
              dispatch(commonActions.handleOpenModalChooseLevel(true));
            }
          }}
          open={openModalLogin}
          onSuccess={() => {
            dispatch(commonActions.handleOpenModalLogin(false));
            dispatch(commonActions.handleSetIsShowModalLoginFirstTime(true));
          }}
        />
      )}
      {!openModalLogin && openModalUpdateInfo && (
        <UserInfoDialog
          open={openUserInfoDialog}
          onClose={setOpenUserInfoDialog}
          getDataUser={getUserInfo}
          setError={() => {}}
        />
      )}
      {!openModalLogin && !openModalUpdateInfo && openModalChooseLanguage && (
        <ModalChooseLanguage
          open={openModalChooseLanguage}
          handleClose={() => {
            if (userLanguage) {
              dispatch(commonActions.handleOpenModalChooseLanguage(false));
              dispatch(commonActions.handleSetIsShowDialogUserLanguageFirstTime(true));
            }
          }}
          required={!isOpenDialogLanguageFirstTime}
        />
      )}
      {!openModalLogin && !openModalUpdateInfo && !openModalChooseLanguage && openModalChooseLevel && (
        <ModalChooseLevel
          open={openModalChooseLevel}
          handleClose={() => {
            dispatch(commonActions.handleOpenModalChooseLevel(false));
            dispatch(commonActions.handleSetIsShowModalLoginFirstTime(true));
          }}
        />
      )}
      {!openModalLogin &&
        !openModalUpdateInfo &&
        !openModalChooseLanguage &&
        !openModalChooseLevel &&
        congratulation.isOpenCongratulation && (
          <CongratulationAlertDialog
            data={congratulation?.congratulation}
            handleCloseDialog={handleCloseCongratulation}
            openDialog={congratulation.isOpenCongratulation}
            triggerFunc={() => {}}
          />
        )}
      {!openModalLogin &&
        !openModalUpdateInfo &&
        !openModalChooseLanguage &&
        !openModalChooseLevel &&
        isEmpty(congratulation) &&
        dataUserInfos?.member?.pub_id && (
          <AlertDialog
            open={locationPermission === LOCATION_PERMISSION_UNDEFINE}
            setOpen={() => {}}
            title={intl.formatMessage({ id: "Setting.notification" })}
            content={intl.formatMessage({ id: "Profile.location.description" })}
            triggerFunc={() => {
              handleUpdateLocation();
              dispatch(commonActions.handleSaveLocationPermission(LOCATION_PERMISSION_ACCEPTED));
            }}
            cancelFunc={() => {
              dispatch(commonActions.handleSaveLocationPermission(LOCATION_PERMISSION_DENIED));
            }}
            contentKey="common.note"
            buttonOkKey="common.next"
          />
        )}
    </Box>
  );
}

export default DefaultLayout;
