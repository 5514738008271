import React from "react";

const UserCircleIcon = ({ stroke = "#666666" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0026 9.9974C12.3038 9.9974 14.1693 8.13192 14.1693 5.83073C14.1693 3.52954 12.3038 1.66406 10.0026 1.66406C7.70142 1.66406 5.83594 3.52954 5.83594 5.83073C5.83594 8.13192 7.70142 9.9974 10.0026 9.9974Z"
        stroke={stroke || "#666666"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1604 18.3333C17.1604 15.1083 13.9521 12.5 10.0021 12.5C6.05208 12.5 2.84375 15.1083 2.84375 18.3333"
        stroke={stroke || "#666666"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserCircleIcon;
