import React from "react";
import Image from "next/image";

export default function NextImage({ className, src, classImage = "" }: any) {
  return (
    <div
      style={{
        position: "relative"
      }}
      className={className}
    >
      <Image
        src={src}
        layout="fill"
        objectFit="cover"
        className={classImage} // Scale your image down to fit into the container
      />
    </div>
  );
}
