/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import { Avatar, Badge, Box, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { isEmpty } from "../../../utils/helpers/helpers";
import { TCommonState } from "../utils/type";

function Ranking({ listRanking }) {
  const paramsRoute = useRouter();
  const { arraySubject } = useSelector((state: { commonNew: TCommonState }) => state.commonNew);
  const subjectSlug = paramsRoute.query.subjectsId as string;
  const slug: string = subjectSlug.split("-lop-")[0];
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: 298,
        borderRadius: 6,
        padding: "0px 16px 12px",
        minHeight: 120
      }}
      className={`${isEmpty(listRanking) ? "hidden" : "flex"}`}
    >
      <Box
        style={{
          display: "flex",
          textAlign: "center"
        }}
        className="title-sub"
      >
        <FormattedMessage
          id="Arena.ranking"
          values={{ subject: arraySubject?.find((ele) => slug?.includes(ele.slug))?.title }}
        />
      </Box>
      <Box display="flex" flexDirection="column" mt={2}>
        {listRanking?.map((ele: any, idx: number) => (
          <Box
            key={idx}
            style={{
              marginTop: 12,
              marginBottom: 12,
              display: "flex",
              justifyContent: "space-between",
              color: "black",
              alignItems: "center"
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <Box
                    style={{
                      background: "white",
                      width: 16,
                      height: 16,
                      borderRadius: 16,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    {ele.ranking}
                  </Box>
                }
              >
                <button type="button" onClick={() => paramsRoute.push(`/tai-khoan/${ele?.member_hid}`)}>
                  <Avatar alt={ele.display_name} src={ele.photo_url} />
                </button>
              </Badge>
              <Typography
                style={{
                  marginLeft: 8,
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden"
                }}
                variant="body1"
                onClick={() => paramsRoute.push(`/tai-khoan/${ele?.member_hid}`)}
                className="hover:underline hover:cursor-pointer text-[15px]"
              >
                {ele.display_name}
              </Typography>
            </Box>
            <Typography style={{}} variant="body1">
              {ele.fame_point}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default React.memo(Ranking);
