import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FormattedMessage } from "react-intl";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: 560,
  p: 2
};

export default function ModalConfirm({
  title,
  content,
  onConfirm,
  onCancel,
  textConfirm,
  handleClose,
  open,
  classBtnConfirm = "",
  styles,
  value = {}
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="md:w-[560px] sm:w-[90vw] border-none rounded-[16px] text-black"
        >
          <div
            id="modal-modal-title"
            className="text-center w-full md:py-6 sm:py-2 text-lg font-semibold"
          >
            <FormattedMessage id={title} />
          </div>
          <div id="modal-modal-description" className="text-[15px]">
            {value ? (
              <FormattedMessage id={content} values={value} />
            ) : (
              <FormattedMessage id={content} />
            )}
          </div>
          <div className="flex justify-center w-full items-center gap-8 sm:my-2 md:my-6">
            <button
              type="button"
              onClick={onCancel}
              className="text-primary bg-white w-[73px] border-none font-medium text-[13px]"
            >
              <FormattedMessage id="cancel" />
            </button>
            <button
              type="button"
              onClick={onConfirm}
              className={
                classBtnConfirm ||
                "bg-primary text-white rounded-[8px] h-[32px] min-w-[97px] items-center font-medium text-[13px]"
              }
              style={styles}
            >
              <FormattedMessage id={textConfirm} />
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
