import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

function getAnimationSettings(angle, originX) {
  return {
    particleCount: 200,
    angle,
    spread: 55,
    origin: { x: originX, y: 0.9 },
    startVelocity: 35,
    gravity: 0.5,
    ticks: 300
  };
}

function Confetti() {
  const refAnimationInstance = useRef<confetti.CreateTypes | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const getInstance = useCallback((instance: confetti.CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(60, 0));
      refAnimationInstance.current(getAnimationSettings(120, 1));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      nextTickAnimation();
      setIntervalId(setInterval(nextTickAnimation, 3000));
    }
  }, [nextTickAnimation, intervalId]);

  useEffect(() => {
    startAnimation();
    return () => {
      clearInterval(intervalId as NodeJS.Timer);
    };
  }, []);

  return (
    <ReactCanvasConfetti
      refConfetti={getInstance}
      style={{
        position: "absolute",
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0
      }}
    />
  );
}

export default Confetti;
