/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { commonActions } from "../redux/actions";
import { TCommonState } from "../utils/type";
import { CXCircularProgress } from "./CircularProgress";
import { TTheme } from "../../../utils/configs/setupTheme";
import { subjectActions } from "../../subjects/redux/actions";

function ListSubjects() {
  const theme: TTheme = useTheme();
  const arraySubjects = useSelector((state: { commonNew: TCommonState }) => state.commonNew.arraySubject);
  const isLoading = useSelector((state: { commonNew: TCommonState }) => state.commonNew.isLoading);
  const userClass = useSelector((state: { commonNew: any }) => state.commonNew.grade);
  const dispatch = useDispatch();
  const router = useRouter();
  React.useEffect(() => {
    if (!!arraySubjects && arraySubjects?.some((e) => e.slug.includes(userClass))) return;
    dispatch(commonActions.handleGetListSubject(userClass));
  }, [userClass]);
  const subjectId = router.query.subjectsId as string;
  const checkSlug = (ele) => {
    if (subjectId?.includes("mon")) {
      return subjectId?.includes(
        ele.slug.replace("tieng", "mon") // do slug learningProgram server trả về lúc là tieng-anh, lúc là mon-anh
      );
    }
    if (ele?.slug === "dia-ly" || ele?.slug === "lich-su") {
      return subjectId?.replace("lich-su-va-dia-ly", "")?.includes(ele.slug);
    }
    return router.query.subjectsId?.includes(ele.slug);
  };

  return (
    <Box className="box-cover-left md:w-[250px] lg:w-[298px]">
      <Box
        style={{
          display: "flex"
        }}
      >
        <span className="font-bold text-[18px]">
          <FormattedMessage id="IDS_Subjects" />
        </span>
      </Box>
      {isLoading ? (
        <CXCircularProgress />
      ) : (
        <div className="flex flex-col">
          {arraySubjects?.map((ele, index) => (
            <button
              type="button"
              key={index}
              onClick={() => {
                dispatch(commonActions.handleChangeSubjects(ele));
                router.push(
                  `/${router?.query.grade ? router?.query.grade : userClass}/${ele.slug}-${
                    router?.query?.grade || userClass
                  }-sach-cu`
                );
                dispatch(subjectActions.handleChoosePage(0));
              }}
              className="hover:underline w-full py-2 flex justify-between items-center text-black"
            >
              <span
                style={{
                  textAlign: "left",
                  color: checkSlug(ele) ? theme.palette.primary.main : "black"
                }}
                className="capitalize text-[15px]"
              >
                {ele.title}
              </span>
              <ArrowForwardIosIcon
                sx={{
                  color: theme.palette.primary.gray400,
                  width: "16px",
                  height: "16px"
                }}
              />
            </button>
          ))}
        </div>
      )}
    </Box>
  );
}

export default React.memo(ListSubjects);
