import React from "react";

export default function XCircleBlack() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="3" fill="black" />
      <path
        d="M7.9974 13.3327C10.9429 13.3327 13.3307 10.9449 13.3307 7.99935C13.3307 5.05383 10.9429 2.66602 7.9974 2.66602C5.05188 2.66602 2.66406 5.05383 2.66406 7.99935C2.66406 10.9449 5.05188 13.3327 7.9974 13.3327Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6L6 10"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10L6 6"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
