import React from "react";

const IconSpeaker = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66797 8.33381V11.6671C1.66797 13.3338 2.5013 14.1671 4.16797 14.1671H5.35964C5.66797 14.1671 5.9763 14.2588 6.24297 14.4171L8.6763 15.9421C10.7763 17.2588 12.5013 16.3005 12.5013 13.8255V6.17548C12.5013 3.69214 10.7763 2.74214 8.6763 4.05881L6.24297 5.58381C5.9763 5.74214 5.66797 5.83381 5.35964 5.83381H4.16797C2.5013 5.83381 1.66797 6.66714 1.66797 8.33381Z"
        stroke="#666666"
        strokeWidth="1.5"
      />
      <path
        d="M15 6.66699C16.4833 8.64199 16.4833 11.3587 15 13.3337"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5234 4.58301C18.9318 7.79134 18.9318 12.208 16.5234 15.4163"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconSpeaker;
