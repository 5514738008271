import React from "react";

const IconCompat = ({ stroke = "#666666" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55078 9.18646V13.3281C3.55078 14.8448 3.55078 14.8448 4.98411 15.8115L8.92578 18.0865C9.51745 18.4281 10.4841 18.4281 11.0758 18.0865L15.0174 15.8115C16.4508 14.8448 16.4508 14.8448 16.4508 13.3281V9.18646C16.4508 7.66979 16.4508 7.66979 15.0174 6.70313L11.0758 4.42813C10.4841 4.08646 9.51745 4.08646 8.92578 4.42813L4.98411 6.70313C3.55078 7.66979 3.55078 7.66979 3.55078 9.18646Z"
        stroke={stroke || "#666666"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5846 6.35573V4.16406C14.5846 2.4974 13.7513 1.66406 12.0846 1.66406H7.91797C6.2513 1.66406 5.41797 2.4974 5.41797 4.16406V6.2974"
        stroke={stroke || "#666666"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5264 9.15781L11.0014 9.89948C11.0764 10.0161 11.2431 10.1328 11.3681 10.1661L12.2181 10.3828C12.7431 10.5161 12.8848 10.9661 12.5431 11.3828L11.9848 12.0578C11.9014 12.1661 11.8348 12.3578 11.8431 12.4911L11.8931 13.3661C11.9264 13.9078 11.5431 14.1828 11.0431 13.9828L10.2264 13.6578C10.1014 13.6078 9.89311 13.6078 9.76811 13.6578L8.95144 13.9828C8.45144 14.1828 8.06811 13.8995 8.10144 13.3661L8.15144 12.4911C8.15978 12.3578 8.09311 12.1578 8.00978 12.0578L7.45144 11.3828C7.10978 10.9661 7.25144 10.5161 7.77644 10.3828L8.62644 10.1661C8.75978 10.1328 8.92644 10.0078 8.99311 9.89948L9.46811 9.15781C9.76811 8.70781 10.2348 8.70781 10.5264 9.15781Z"
        stroke={stroke || "#666666"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconCompat;
