/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { Avatar, Divider, styled } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import IconDiamondAdminRoom from "../../../../public/assets/icons/IconDiamondAdminRoom";
import IconGift from "../../../../public/assets/icons/IconGift";
import IconKeyLock from "../../../../public/assets/icons/KeyLock";
import IconKeyUnLock from "../../../../public/assets/icons/KeyUnlock";
import LoadingSvg from "../../common/Image/LoadingSvg";
import IconDiamondRoomCard from "../icons/IconDiamondRoomCard";
import IconPinned from "../icons/IconPinned";
import { QUIZ_LEVEL } from "../utils/constant";
import { TCommonState } from "../utils/type";

const CustomAvatarOwner = styled(Avatar)(() => ({
  position: "absolute",
  width: 29,
  height: 29,
  right: -4,
  bottom: -10
}));

const roomStatus = [
  {
    messageId: "Arena.room_status.waiting",
    color: "#12B76A"
  },
  {
    messageId: "Arena.room_status.playing",
    color: "#B00020"
  },
  {
    messageId: "Arena.room_status.finished",
    color: "#666666"
  }
];

type TRoomCard = {
  room: any;
  roomsData: any;
  handleDirectDetail?: any;
  formatDate?: string;
  isPreview?: boolean;
  type?: string;
  hideStatus?: boolean;
};

export default function RoomCard({
  room,
  roomsData,
  handleDirectDetail,
  formatDate,
  isPreview = false,
  type = "",
  hideStatus = false
}: TRoomCard) {
  const matches1500 = useMediaQuery("(min-width:1500px)");
  const timeServer = useSelector((state: { commonNew: TCommonState }) => state.commonNew.timeServer);

  const getRoomSubject = (subjectId: any) => {
    return roomsData?.subjects?.find((e: { id: any }) => e.id === subjectId);
  };
  const getRoomStatus = () => {
    if (!room) return undefined;
    let messageId: string;
    if (room?.is_locked) {
      messageId = "Arena.room_status.finished";
    } else if (room?.start_time > timeServer?.timestamp) {
      messageId = "Arena.room_status.waiting";
    } else if (room?.start_time <= timeServer?.timestamp && timeServer?.timestamp <= room?.end_time) {
      messageId = "Arena.room_status.playing";
    } else {
      messageId = "Arena.room_status.finished";
    }
    return messageId;
  };

  return (
    <div
      id="roomCard"
      style={{
        borderRadius: 15,
        color: "black",
        display: "flex",
        flexDirection: "row",
        // margin: "8px",
        cursor: "pointer",
        width: isPreview ? "98%" : "100%"
      }}
      onClick={() => handleDirectDetail(room)}
      className={`sm:px-0 sm:h-auto lg:h-max max-h-[214px] py-2 ${
        room?.is_pinned && type === "listRoom" ? "bg-lightYellow border-gray border" : "border-gray border"
      }`}
    >
      <div className="flex flex-row justify-between sm:w-1/2 w-max shrink" id="roomCardHeader">
        <div className="grid grid-cols-1 grid-rows-2 items-center justify-items-center shrink mx-auto">
          <div className="mt-2">
            <div style={{ position: "relative", width: 65, height: 65 }}>
              <LoadingSvg
                fileName={
                  roomsData?.subjects?.length > 1
                    ? getRoomSubject(room?.subject_type_id)?.asset_photo
                    : roomsData?.subjects[0]?.asset_photo
                }
                netPhoto={
                  roomsData?.subjects?.length > 1
                    ? getRoomSubject(room?.subject_type_id)?.net_photo
                    : roomsData?.subjects[0]?.net_photo
                }
                styleImg={{
                  width: 104,
                  height: 104
                }}
              />
              {room?.owner ? (
                <CustomAvatarOwner src={room?.owner?.photo_url} alt={room?.owner?.name} />
              ) : (
                <CustomAvatarOwner src="/asset/jlptup.png" alt="jlpt up" />
              )}
            </div>
          </div>
          <div className="mt-2 text-center w-full">
            <span className="text-primary font-medium text-[13px] line-clamp-2 break-all px-2">{room?.title}</span>
            <div className="text-[10px] text-center">
              <span
                id="roomSubjectTitle"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
                className="text-black"
              >
                <FormattedMessage
                  id="Arena.room_subject"
                  values={{
                    title:
                      roomsData?.subjects?.length > 1
                        ? getRoomSubject(room?.subject_type_id)?.title
                        : roomsData?.subjects[0]?.title
                  }}
                />
              </span>
              {room?.fee_join > 0 && (
                <span className="flex items-center flex-nowrap text-[10px] gap-1 text-blackQuiz justify-center whitespace-nowrap">
                  <FormattedMessage id="arena.fee.join.room" values={{ fee: room?.fee_join }} />
                  <IconDiamondRoomCard />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="py-2">
          <Divider orientation="vertical" />
        </div>
      </div>
      <div
        className="flex relative flex-row justify-between p-2"
        style={{
          display: "flex",
          width: "100%",
          gap: matches1500 ? 20 : 10
        }}
      >
        <div className="flex flex-col gap-y-1 justify-start text-left ">
          <span className="text-[13px]">
            <FormattedMessage id="Arena.room_num_of_questions" values={{ num: room.num_of_questions }} />
          </span>
          <span className="text-[13px]">
            <FormattedMessage id="Arena.room_member" values={{ num: room?.num_of_members, max: room?.max_slot }} />
          </span>
          <span className="text-[13px]">
            <FormattedMessage id="Arena.room_type" values={{ type: room?.room_type?.title }} />
          </span>

          <span className="flex items-center flex-row flex-nowrap text-[13px]">
            <FormattedMessage id="room.exam.type" />:{" "}
            {room?.quiz_level === QUIZ_LEVEL.PREMIUM.level ? (
              <FormattedMessage id={QUIZ_LEVEL.PREMIUM.title} />
            ) : (
              <FormattedMessage id={QUIZ_LEVEL.NORMAL.title} />
            )}
          </span>
          <span className="text-[13px]">
            <FormattedMessage id="Arena.room_quiz_time" values={{ time: room?.time }} />
          </span>
          <span className="text-[13px] whitespace-nowrap" style={{ wordWrap: "normal" }}>
            {roomsData?.roomMember ? (
              <>
                {" "}
                <FormattedMessage id="ResultQuizzes.scoreQuiz" />:{" "}
                {room.roomMember?.score >= 0 ? room.roomMember?.score.toFixed(2) : null}
              </>
            ) : (
              <FormattedMessage
                id="Arena.room_start_time"
                values={{
                  time: moment
                    .unix(room?.start_time)
                    .format(formatDate ? formatDate : matches1500 ? "HH:mm DD/MM/YYYY" : "HH:mm DD/MM")
                }}
              />
            )}
          </span>
          <span className="text-[13px] whitespace-nowrap">
            {roomsData?.roomMember ? (
              <>
                {" "}
                <FormattedMessage id="ResultQuizzes.rank" />:{" "}
                {room.roomMember?.ranking > 0 ? room.roomMember?.ranking : null}
              </>
            ) : (
              <FormattedMessage
                id="Arena.room_end_time"
                values={{
                  time: moment
                    .unix(room.end_time)
                    .format(formatDate ? formatDate : matches1500 ? "HH:mm DD/MM/YYYY" : "HH:mm DD/MM")
                }}
              />
            )}
          </span>
        </div>
        <div id="roomStatus" className="absolute right-2 top-2 flex flex-col items-end w-[50px]">
          <div className="flex flex-row items-center gap-x-1.5 flex-nowrap">
            {room?.is_pinned && <IconPinned />}
            {(room?.star || room?.has_gift_item) && <IconGift width="16px" height="16px" />}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {room?.fee_join ? (
                <IconDiamondAdminRoom />
              ) : !room?.password || room?.joined ? (
                <IconKeyUnLock />
              ) : (
                <IconKeyLock />
              )}
            </div>
          </div>
          {!hideStatus && (
            <span
              style={{ color: roomStatus.find((e) => e.messageId === getRoomStatus())?.color }}
              className={`text-[10px] whitespace-nowrap text-[${
                roomStatus.find((e) => e.messageId === getRoomStatus())?.color
              }]`}
            >
              <FormattedMessage id={getRoomStatus()} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
