import React from "react";

export default function HumbuggerIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="12" height="12" rx="6" fill="#E5E5E5" />
      <path d="M3 3.75H9" stroke="#292D32" strokeLinecap="round" />
      <path d="M3 6H9" stroke="#292D32" strokeLinecap="round" />
      <path d="M3 8.25H9" stroke="#292D32" strokeLinecap="round" />
    </svg>
  );
}
