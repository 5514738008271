import React from "react";

export default function KeyUnlock() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.332 14.666H4.66536C1.9987 14.666 1.33203 13.9993 1.33203 11.3327V9.99935C1.33203 7.33268 1.9987 6.66602 4.66536 6.66602H11.332C13.9987 6.66602 14.6654 7.33268 14.6654 9.99935V11.3327C14.6654 13.9993 13.9987 14.666 11.332 14.666Z"
        stroke="#12B76A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 6.66732V5.33398C4 3.12732 4.66667 1.33398 8 1.33398C11 1.33398 12 2.66732 12 4.66732"
        stroke="#12B76A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9987 12.3333C8.91917 12.3333 9.66536 11.5871 9.66536 10.6667C9.66536 9.74619 8.91917 9 7.9987 9C7.07822 9 6.33203 9.74619 6.33203 10.6667C6.33203 11.5871 7.07822 12.3333 7.9987 12.3333Z"
        stroke="#12B76A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
