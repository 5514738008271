/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-filename-extension */
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CXImage from "./Image";
import useDynamicSVGImport from "./useDynamicSVGImport";

interface IProps {
  fileName?: string;
  netPhoto: any;
  onCompleted?: Function;
  onError?: Function;
  styleSvg?: any;
  [x: string]: any;
}
function LoadingSvg({
  fileName = "",
  netPhoto,
  onCompleted,
  onError,
  styleSvg,
  styleImg
}: IProps) {
  const { error, loading, SvgIcon } = useDynamicSVGImport(
    fileName?.split("/")[3]?.split("_cap_do_")[0]?.split(".png")[0],
    {
      onCompleted,
      onError
    }
  );
  if (error) {
    return (
      <CXImage srcImage={netPhoto} styleSvg={styleSvg} styleImg={styleImg} />
    );
  }
  if (loading) {
    return <Typography>...</Typography>;
  }
  if (SvgIcon) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center ">
        <CXImage
          srcImage={`/assets/icons/${SvgIcon}.svg`}
          styleSvg={styleSvg}
          styleImg={styleImg}
        />
      </Box>
    );
  }
  return null;
}
export default LoadingSvg;
