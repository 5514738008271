/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import { Box, Button, Divider, IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import Image from "next/image";
import { ROUTES } from "../../../utils/configs/router";
import IconCopy from "../../../../public/assets/icons/IconCopy";
import { IconCrownGray } from "../../../../public/assets/icons/IconCrown";
import IconDiamond from "../../../../public/assets/icons/IconDiamond";
import { isEmpty } from "../../../utils/helpers/helpers";
import { TCommonState } from "../utils/type";
import CameraIconCircle from "../icons/CameraIconCircle";
import { commonActions } from "../redux/actions";
import Star from "../../../../public/assets/icons/Star";
import { abbreviateNumberFormatter } from "../utils/constant";
import AnonymousAvatar from "../../../../public/assets/icons/AnonymousAvatar";
import { TTheme } from "../../../utils/configs/setupTheme";
import { IconPremiumSmall } from "../../account/assets/icons/IconPremium";
import AddCircleOutlinedIcon from "../../../../public/assets/icons/AddCircleOutlinedIcon";
import { toastSuccess } from "./reactToastify";

function AccountInfo({ handleOpenModalImage }: { handleOpenModalImage: () => void }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme: TTheme = useTheme();
  const matches1100 = useMediaQuery("(min-width:1100px)");
  const dataUserInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const isUpdateInfo = useSelector((state: { commonNew: TCommonState }) => state.commonNew.isUpdateInfo);
  const navigate = useRouter();
  const checkPremiumMember =
    !isEmpty(dataUserInfos?.member?.vip_to) && (dataUserInfos?.member?.vip_to as number) >= moment().unix();
  const handleOpenLoginDialog = () => {
    if (!dataUserInfos?.member) {
      dispatch(commonActions.handleOpenModalLogin(true));
    }
  };
  useEffect(() => {
    if (isUpdateInfo) {
      dispatch(commonActions.handleGetUserInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateInfo]);
  return (
    <Box className="box-cover-left md:w-[250px] lg:w-[298px]">
      <Box className="relative">
        <Image
          src="/assets/images/account_background_small.png"
          width={!matches1100 ? 250 : 298}
          height={172}
          className="rounded-[16px]"
        />
        {!isEmpty(dataUserInfos?.member) ? (
          <div className="absolute left-[13px] bottom-[-8px]">
            <Avatar
              alt={dataUserInfos?.member?.name}
              src={dataUserInfos?.member?.avatar}
              sx={{
                width: 72,
                height: 72,
                objectFit: "contain",
                border: "3px solid white"
              }}
            />
            <button type="button" className="absolute left-0 top-0">
              {checkPremiumMember ? <IconPremiumSmall /> : <IconCrownGray />}
            </button>
            <button type="button" className="absolute right-0 bottom-0" onClick={handleOpenModalImage}>
              <CameraIconCircle />
            </button>
          </div>
        ) : (
          <button type="button" className="absolute left-[13px] bottom-[-6px]" onClick={() => handleOpenLoginDialog()}>
            <AnonymousAvatar />
          </button>
        )}
      </Box>

      {!isEmpty(dataUserInfos?.member) ? (
        <div className="mt-1">
          <div className="flex flex-col  py-1">
            <div className="flex flex-row justify-between items-center">
              <div className="flex justify-start overflow-hidden line-clamp-1 w-[calc(100%-110px)]">
                <span className="text-[16px] line-clamp-1 font-semibold">
                  {dataUserInfos?.member?.use_nickname === 1 && dataUserInfos?.member?.nickname
                    ? dataUserInfos?.member?.nickname
                    : dataUserInfos?.member?.name}
                </span>
              </div>
              <Box>
                {!checkPremiumMember ? (
                  <Button
                    className="bg-primary rounded-[8px] w-[71px] h-[32px]"
                    onClick={() => {
                      navigate.push(`${ROUTES.kimcuong.nangcapvip.path}`);
                    }}
                  >
                    <span className="text-white text-[13px] font-semibold normal-case whitespace-nowrap">
                      <FormattedMessage id="Upgrade" />
                    </span>
                  </Button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      navigate.push(`${ROUTES.kimcuong.nangcapvip.path}`);
                    }}
                    className="rounded-[8px] w-[105px] h-[32px] border border-primary"
                  >
                    <span className="text-[13px] font-semibold text-primary">
                      <FormattedMessage id="Account.extension" />
                    </span>
                  </button>
                )}
              </Box>
            </div>
            <div className="flex justify-between items-center mt-1">
              <div className=" flex items-center sm:gap-1 lg:gap-2">
                <span className="text-gray1 sm:text-[10px] lg:text-[12px]">
                  <FormattedMessage
                    id="Profile.yourReferencesCodeIs"
                    values={{ code: dataUserInfos?.member?.pub_id }}
                  />
                </span>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(dataUserInfos?.member?.pub_id as string);
                    toastSuccess(intl.formatMessage({ id: "message.copy.success" }));
                  }}
                  style={{ padding: 0, marginTop: -2 }}
                >
                  <IconCopy />
                </IconButton>
              </div>
              {checkPremiumMember && (
                <span className="sm:text-[10px] lg:text-[12px] font-[400] text-gray1">
                  <FormattedMessage
                    id="Account.expiry"
                    values={{ number: moment.unix(dataUserInfos?.member?.vip_to as number).format("DD/MM/YYYY") }}
                  />
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-center pt-[10px] pb-3">
            <Divider sx={{ width: "89%" }} />
          </div>
          <div className="flex text-[12px] justify-between">
            <span className="flex justify-center items-center w-[70px] h-[24px] bg-gray rounded-[12px] text-primary">
              <FormattedMessage id="account.level" values={{ level: dataUserInfos?.member?.level }} />
            </span>
            <div className="flex justify-center items-center h-[24px] bg-gray rounded-[12px] py-1 px-2">
              <span className="flex gap-1 items-center text-primary">
                <span className="mb-[1px]">
                  <Star />
                </span>{" "}
                {dataUserInfos?.member?.star || 0}
              </span>
            </div>
            <div className="flex justify-center items-center h-[24px] bg-gray rounded-[12px] py-1 px-2">
              <IconDiamond fill={theme.palette.primary.main} />
              {matches1100 ? (
                <div>
                  <span className="px-1">{abbreviateNumberFormatter(dataUserInfos?.member?.diamond)}</span>
                  {(dataUserInfos?.member?.diamond as number) > 0 &&
                    moment((dataUserInfos?.member?.diamond_expiry as number) * 1000) >= moment() && (
                      <span className="text-[10px] text-gray400 px-[2px]">
                        (
                        <FormattedMessage
                          id="Account.expiry"
                          values={{
                            number: moment.unix(dataUserInfos?.member?.diamond_expiry as number).format("DD/MM/YY")
                          }}
                        />
                        )
                      </span>
                    )}
                </div>
              ) : (dataUserInfos?.member?.diamond as number) > 0 ? (
                <Tooltip
                  title={
                    <span>
                      <FormattedMessage
                        id="Account.expiry"
                        values={{
                          number: moment.unix(dataUserInfos?.member?.diamond_expiry as number).format("DD/MM/YY")
                        }}
                      />
                    </span>
                  }
                  placement="top-start"
                  enterTouchDelay={0}
                  arrow
                >
                  <div>
                    <span className="pl-1">{abbreviateNumberFormatter(dataUserInfos?.member?.diamond)}</span>
                    <span className="text-red pr-1">*</span>
                  </div>
                </Tooltip>
              ) : (
                <span className="px-1">{abbreviateNumberFormatter(dataUserInfos?.member?.diamond)}</span>
              )}
              <button
                type="button"
                className="mr-[-2px] w-[16px] h-[16px] items-center"
                onClick={() => {
                  navigate.push(`${ROUTES.kimcuong.napkimcuong.path}`);
                }}
              >
                <AddCircleOutlinedIcon />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-[6px]">
          <div className="flex justify-between items-center">
            <span className="text-[16px] font-semibold">
              <FormattedMessage id="account.anonymous" />
            </span>
            <Button
              className="bg-primary rounded-[8px] w-[105px] h-[32px] normal-case"
              onClick={() => {
                handleOpenLoginDialog();
              }}
            >
              <span className="text-white text-[13px] font-semibold">
                <FormattedMessage id="Dialog.SignInConfirmButtonText" />
              </span>
            </Button>
          </div>
        </div>
      )}
    </Box>
  );
}

export default React.memo(AccountInfo);
