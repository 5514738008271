/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
// import Image from "next/image";

type TProps = {
  className?: string;
  style?: {
    display: string;
    width: number;
    height: number;
  };
  slot: any;
  layout?: string;
  layoutKey?: string;
  format?: string;
  responsive?: string;
  pageLevelAds?: boolean;
  [x: string]: any;
};
declare global {
  interface Window {
    adsbygoogle: any
  }
}
function GoogleAd({
  style = { display: 'block', width: 0, height: 0 },
  slot,
  layout = '',
  layoutKey = '',
  format = 'auto',
  responsive = 'false',
  pageLevelAds = false,
  ...rest
}: TProps) {
  useEffect(() => {
    const p: any = {};
    if (pageLevelAds) {
      p.google_ad_client = process.env.NEXT_PUBLIC_GOOGLE_AD_ID;
      p.enable_page_level_ads = true;
    }
    const ads = document.getElementsByClassName("adsbygoogle").length;
      for (let i = 0; i < ads; i++) {
        try {
          if (typeof window === 'object') {
            (window.adsbygoogle = window.adsbygoogle || [])?.push(p);
          }
        } catch {
          // Pass
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdTestOn = !(
    process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_GOOGLE_AD_TEST_ON === '0'
  );
  if (isAdTestOn) {
    const configImgTest = (width:number, height: number) =>
      `https://dummyimage.com/${width}x${height}/000/fff&text=GoogleAdTest_${width}x${height}`;

    return (
      <img style={{ borderRadius: 16 }} src={configImgTest(style.width, style.height)} alt="" />
    );
  }
  return (
    <ins
      className="adsbygoogle"
      style={style}
      data-ad-client={process.env.NEXT_PUBLIC_GOOGLE_AD_ID}
      data-ad-slot={slot}
      data-ad-layout={layout}
      data-ad-layout-key={layoutKey}
      data-ad-format={format}
      data-full-width-responsive={responsive}
      {...rest}
    />
  );
}

export default GoogleAd;
